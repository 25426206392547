import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import { AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { clearMsgCreate, clearMsgDelete, clearMsgUpdate, deleteEntity, getEntities } from './club-officer.reducer';
import { hasAnyAuthority, isRole } from 'app/shared/auth/private-route';
import { toast } from 'react-toastify';
import { ClubOfficerListFilter } from './club-officer.type';
import { useFilter } from 'app/shared/hooks/useFilter';
import { Button, Col } from 'antd';
import Table from 'app/modules/table';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';
import ClubOfficerAddDialog from './club-officer-add-dialog';
import { PlusIcon } from 'app/shared/util/appIcon';
import FilterCustom from 'app/modules/filter-custom';
import MultiFilterCustom from 'app/modules/MultiSearchCustom';
import { ROLE_STAFF } from 'app/shared/util/constant/role-staffs.constant';

export const ClubOfficer = (props: any) => {
  const dispatch = useAppDispatch();
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
    type: 'leagueName',
  };
  const [idUpdate, setIdUpdate] = useState('');
  const clubOfficerList = useAppSelector(state => state.clubOfficerReducer?.entities);
  const totalItems = useAppSelector(state => state.clubOfficerReducer?.totalItems);
  const loading = useAppSelector(state => state.clubOfficerReducer?.loading);
  const deleteSuccess = useAppSelector(state => state.clubOfficerReducer?.deleteSuccess);
  const updateSuccess = useAppSelector(state => state.clubOfficerReducer?.updateSuccess);
  const createSuccess = useAppSelector(state => state.clubOfficerReducer?.createSuccess);
  const [reset, setReset] = useState(false);
  const account = useAppSelector(state => state.authentication.account);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);
  const isTeamManager = isRole(authorities, [AUTHORITIES.TEAM_MANAGER]);
  const league = useAppSelector(state => state.league?.entity);
  const currentDate = new Date().toISOString();
  const selectedLeague = league.expirationDate;
  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.clubOfficer.deleted.success'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (createSuccess) {
      toast.success(translate('leagueApp.clubOfficer.created.success'));
      dispatch(clearMsgCreate(createSuccess));
      setShowDialog(false);
      handleFetchData({ ...filter, page: 0 });
    }
  }, [createSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      toast.success(translate('leagueApp.clubOfficer.updated'));
      dispatch(clearMsgUpdate(updateSuccess));
      setShowDialog(false);
      handleFetchData({ ...filter, page: 0 });
    }
  }, [updateSuccess]);

  const handleDeleteModal = (user: any) => {
    setUserClicked(user);
    setModalDelete(true);
  };

  const handleDelete = () => {
    dispatch(deleteEntity(userClicked)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        updateFilter();
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const handleFetchData = React.useCallback((params: ClubOfficerListFilter) => {
    params.clubId = props.match.params.id;
    dispatch(getEntities(params));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  //   /** new **/
  const handleOpenModal = () => {
    setIdUpdate(null);
    setShowDialog(true);
  };

  //   /** update **/
  const handleUpdate = id => {
    setIdUpdate(id);
  };

  React.useEffect(() => {
    if (idUpdate) {
      setShowDialog(true);
    }
  }, [idUpdate]);

  const updateFilter = () => {
    onFilterToQueryString({
      page: 0,
      size: 10,
      sort: 'id,asc',
      // type: 'name',
    });
    setReset(!reset);
  };

  const ActionButton = ({ id, notEdit }: { id: number; notEdit: boolean }) => {
    return (
      <div className={(isAdmin || isTeamManager) && 'column-action-button'}>
        <Button onClick={() => handleUpdate(id)} className="rounded" disabled={selectedLeague < currentDate}>
          <Translate contentKey="entity.action.edit" />
        </Button>
        {(isAdmin || isTeamManager) && (
          <Button
            onClick={() => handleDeleteModal(id)}
            className="rounded"
            danger
            type="primary"
            disabled={!!notEdit || selectedLeague < currentDate === true}
          >
            <Translate contentKey="entity.action.delete2">Delete</Translate>
          </Button>
        )}
      </div>
    );
  };

  const roles = [
    { value: ROLE_STAFF.DEPUTY_DELEGATION + '', label: translate('leagueApp.staff.role.deputyDelegation') },
    { value: ROLE_STAFF.MANAING_DIRECTOR + '', label: translate('leagueApp.staff.role.managingDirector') },
    { value: ROLE_STAFF.TEAM_MANAGER + '', label: translate('leagueApp.staff.role.teamManager') },
  ];
  const columns = [
    {
      title: <Translate contentKey="leagueApp.clubOfficer.staffId"></Translate>,
      dataIndex: 'staffName',
      key: 'staffName',
    },
    {
      title: <Translate contentKey="leagueApp.clubOfficer.league"></Translate>,
      dataIndex: 'leagueName',
      key: 'leagueName',
    },
    {
      title: <Translate contentKey="leagueApp.clubOfficer.position" />,
      dataIndex: 'role',
      key: 'role',
      render(roleValue) {
        const roleLabel = roles.find(role => role.value === roleValue)?.label;
        return roleLabel || roleValue;
      },
    },
    {
      title: <Translate contentKey="leagueApp.coach.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 230,
      render: (value, record) => <ActionButton id={value} notEdit={record?.notEdit} />,
      align: 'center' as any,
    },
  ];

  const options = [
    {
      value: 'leagueName',
      label: translate('leagueApp.clubOfficer.league'),
    },
  ];

  // --------------------------------------

  return (
    <div className="management-layout">
      {showDialog && (
        <ClubOfficerAddDialog
          entityId={idUpdate}
          setEntityId={setIdUpdate}
          showDialog={showDialog}
          setShowDialog={b => setShowDialog(b)}
          updateFilter={updateFilter}
          idClub={props.match.params.id}
        />
      )}
      <div className="d-flex justify-content-between align-items-center management-layout-header" style={{ paddingTop: 30 }}>
        <div className="d-flex align-items-center">
          <h2 id="player-heading" data-cy="PlayerHeading" className="heading-20-bold neutral-1">
            <Translate contentKey="leagueApp.clubOfficer.home.title">Club Uniforms</Translate>
          </h2>
          <div className="management-layout-divider"></div>
          <Button
            className="rounded management-header-add-button"
            onClick={handleOpenModal}
            icon={<PlusIcon />}
            type="primary"
            disabled={selectedLeague < currentDate}
          >
            <Translate contentKey="clubmng.add" />
          </Button>
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <FilterCustom ignoreOption={true} filter={{ ...filter }} onFilter={onFilterToQueryString} options={options} multi reset={reset} />
        </Col>
      </div>
      <MultiFilterCustom filter={filter} onFilter={onFilterToQueryString} options={options} ignore={['clubId']} />
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={clubOfficerList}
          totalElements={totalItems}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('leagueApp.clubOfficer.delete.question')}</h6>}
          onClose={() => setModalDelete(false)}
          onConfirm={() => handleDelete()}
        />
      )}
    </div>
  );
};

export default ClubOfficer;
