import React from 'react';
import { translate, Translate } from 'react-jhipster';

interface Props {
  authoritiesValues: string;
}

export const AuthoritiesValuesTypeContainer = ({ authoritiesValues }: Props) => {
  if (authoritiesValues === null || authoritiesValues === '') {
    return <div></div>;
  }
  if (authoritiesValues === 'Admin') {
    return <div className="table-custom-status">{translate('sidebar.admin')}</div>;
  }
  if (authoritiesValues === 'User') {
    return <div className="table-custom-status">{translate('sidebar.user')}</div>;
  }
  if (authoritiesValues === 'League Master') {
    return <div className="table-custom-status">{translate('author.leaguemaster')}</div>;
  }
  if (authoritiesValues === 'Team Manager') {
    return <div className="table-custom-status">{translate('author.teammanager')}</div>;
  }
  if (authoritiesValues === 'Recorder') {
    return <div className="table-custom-status">{translate('author.recorder')}</div>;
  }
};
