import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import DraggableElement from './components/dragable-element';
import { IPlayerInMatch } from 'app/shared/model/player.model';
import StadiumImage from 'app/assets/stadium.png';
import { Item } from 'rc-menu';
import { first, get } from 'lodash';
import { IEventRecord } from 'app/shared/model/match-record.model';
import { actionRecord } from 'app/shared/util/constant';
import SubLineUp from './components/sub-line-up';
import './lineup.scss';

const removeFromList = (list: IPlayerInMatch[], index: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

const addToList = (list: IPlayerInMatch[], index: number, element: any) => {
  const result = Array.from(list);
  result.splice(index, 0, element);
  return result;
};

interface Props {
  ownerLineUp: IPlayerInMatch[];
  awayLineUp: IPlayerInMatch[];
  submit: boolean;
  defaultAwayLineUp?: IPlayerInMatch[];
  swap?: boolean;
  onClickPlayer?: (item: IPlayerInMatch) => void;
  onSubmit: (owner: IPlayerInMatch[], away: IPlayerInMatch[]) => void;
  onChange?: (owner: IPlayerInMatch[], away: IPlayerInMatch[], clubId: number) => void;
  event?: IEventRecord;
  haveSubPlayers?: boolean;
  awaySubPlayers?: IPlayerInMatch[];
  ownerSubPlayers?: IPlayerInMatch[];
  ownerAvatars?: Record<string, string>;
  awayAvatars?: Record<string, string>;
  canChange?: boolean;
  timeOfRound?: number;
  timeOfRoundExtra?: number;
  draggable?: boolean;
}
const listsSort = [1, 2, 3, 4];
const getInitValue = (listPlayer: IPlayerInMatch[]): Record<number, IPlayerInMatch[]> => {
  return listsSort.reduce(
    (acc, listKey) => ({
      ...acc,
      [listKey]: listPlayer.filter(item => item.positionType === listKey).sort((a, b) => a.positionOrder - b.positionOrder),
    }),
    {}
  );
};

const getChange = (data: Record<number, IPlayerInMatch[]>) => {
  const changes = Object.values(data).reduce((result, groupPlayer, index) => {
    const filter: any[] = groupPlayer.map((item, itemIndex) => ({
      ...item,
      positionType: Number(Object.keys(data)[index]),
      positionOrder: itemIndex + 1,
    }));
    return result.concat(filter);
  }, [] as IPlayerInMatch[]);

  return changes;
};

interface LineUpProps {
  main: Record<number, IPlayerInMatch[]>;
  sub: IPlayerInMatch[];
}

const LineUp = ({
  ownerLineUp,
  awayLineUp,
  submit,
  onSubmit,
  onChange,
  event,
  haveSubPlayers,
  awaySubPlayers,
  ownerSubPlayers,
  ownerAvatars,
  awayAvatars,
  canChange,
  swap,
  timeOfRound,
  timeOfRoundExtra,
  draggable,
}: Props) => {
  const lists = swap ? [1, 2, 3, 4].reverse() : [1, 2, 3, 4];
  const listsS = swap ? [1, 2, 3, 4] : [1, 2, 3, 4].reverse();
  const [elements, setElements] = React.useState<LineUpProps>({ main: { 1: [], 2: [], 3: [], 4: [] }, sub: [] });
  const [elementsS, setElementsS] = React.useState<LineUpProps>({
    main: { 1: [], 2: [], 3: [], 4: [] },
    sub: [],
  });

  React.useEffect(() => {
    setElementsS({ main: getInitValue(awayLineUp), sub: awaySubPlayers || [] });
  }, [awayLineUp, awaySubPlayers]);

  React.useEffect(() => {
    setElements({ main: getInitValue(ownerLineUp), sub: ownerSubPlayers || [] });
  }, [ownerLineUp, ownerSubPlayers]);

  React.useEffect(() => {
    if (submit) {
      handleSubmit();
    }
  }, [submit]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !canChange) {
      return;
    }
    const listCopy = { ...elements.main };

    const sourceList: IPlayerInMatch[] = listCopy[result.source.droppableId];
    const [removedElement, newSourceList] = removeFromList(sourceList, result.source.index);

    if (!removedElement) {
      return;
    }
    listCopy[result.source.droppableId] = newSourceList as IPlayerInMatch[];
    const destinationList = listCopy[result.destination.droppableId];
    listCopy[result.destination.droppableId] = addToList(destinationList, result.destination.index, removedElement);
    setElements(pre => ({ ...pre, main: listCopy }));
    onChange && onChange(getChange(listCopy), getChange(elementsS.main), first(ownerLineUp)?.matchPlayerFieldDTO.clubId);
  };
  const onDragEndS = (result: DropResult) => {
    if (!result.destination || !canChange) {
      return;
    }
    const listCopy = { ...elementsS.main };

    const sourceList = listCopy[result.source.droppableId];
    const [removedElement, newSourceList] = removeFromList(sourceList, result.source.index);

    if (!removedElement) {
      return;
    }
    listCopy[result.source.droppableId] = newSourceList as IPlayerInMatch[];
    const destinationList = listCopy[result.destination.droppableId];
    listCopy[result.destination.droppableId] = addToList(destinationList, result.destination.index, removedElement);
    setElementsS(pre => ({ ...pre, main: listCopy }));

    onChange && onChange(getChange(elements.main), getChange(listCopy), first(awayLineUp)?.matchPlayerFieldDTO.clubId);
  };

  const handleClickPlayer = React.useCallback(item => {}, []);
  const handleSubmit = () => {
    const ownerChange = getChange(elements.main);
    const awayChange = getChange(elementsS.main);
    onSubmit(ownerChange, awayChange);
  };

  return (
    <>
      <div className="container-lineup">
        <Row
          gutter={[12, 12]}
          align="middle"
          style={{
            background: `url(${StadiumImage}) no-repeat`,
            backgroundSize: '1200px auto',
            backgroundPosition: 'center',
            maxWidth: 1200,
            minHeight: '620px',
            margin: '0 auto',
          }}
        >
          <Col xs={12} style={{ order: 2 }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Row gutter={[6, 6]} justify="space-between">
                {lists.map(listKey => (
                  <Col xs={6} key={listKey}>
                    <DraggableElement
                      draggable={draggable}
                      onClickPlayer={item => handleClickPlayer(item)}
                      elements={elements.main[listKey]}
                      key={listKey.toString()}
                      prefix={listKey}
                      avatars={ownerAvatars}
                      timeOfRound={timeOfRound}
                      timeOfRoundExtra={timeOfRoundExtra}
                    />
                  </Col>
                ))}
              </Row>
            </DragDropContext>
          </Col>
          <Col xs={12} style={{ order: swap ? 1 : 3 }}>
            <DragDropContext onDragEnd={onDragEndS}>
              <Row gutter={[6, 6]} justify="space-between">
                {listsS.map(listKey => (
                  <Col xs={6} key={listKey}>
                    <DraggableElement
                      draggable={draggable}
                      onClickPlayer={item => handleClickPlayer(item)}
                      elements={elementsS.main[listKey]}
                      key={listKey}
                      prefix={listKey}
                      avatars={awayAvatars}
                      timeOfRound={timeOfRound}
                      timeOfRoundExtra={timeOfRoundExtra}
                    />
                  </Col>
                ))}
              </Row>
            </DragDropContext>
          </Col>
        </Row>
      </div>
      {haveSubPlayers && (
        <Row
          style={{
            // minWidth: 1200,
            margin: '0 auto',
            marginTop: 32,
          }}
        >
          <Col xs={12} className="left-sub-lineup" style={{ order: 2 }}>
            <SubLineUp subPlayerList={elements.sub || []} onClickSubPlayer={handleClickPlayer} />
          </Col>
          <Col xs={12} className="right-sub-lineup" style={{ order: swap ? 1 : 3 }}>
            <SubLineUp subPlayerList={elementsS.sub || []} onClickSubPlayer={handleClickPlayer} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default React.memo(LineUp);
