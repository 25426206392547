import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { Button, Col, ConfigProvider, Empty, Modal } from 'antd';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity, createEntity, clearMsgCreate } from './player-league.reducer';
import { getEntitiesFreePlayerLeague } from './player-league.reducer';
import axios from 'axios';
// import Table from 'app/modules/table';
import { Table } from 'antd';
import { toast } from 'react-toastify';
import { PlayerLeagueListFilter } from './player-league.type';
import { useFilter } from 'app/shared/hooks/useFilter';
import { FilterParams } from 'app/shared/model/filter.model';

export const PlayerLeagueCreateDialog = (props: any) => {
  const { createModal, setCreateModal, idClub } = props;
  const [loadModal, setLoadModal] = useState(false);
  const dispatch = useAppDispatch();
  const [dataPositions, setDataPositions] = useState([]);
  const [freePlayer, setfreePlayer] = useState([]);
  const [data, setData] = useState([{}]);

  const [formCreate, setFormCreate] = useState({
    position: '',
    player: '',
  });
  const clubUniformEntity = useAppSelector(state => state.clubUniform?.entity);
  const playerList = useAppSelector(state => state.player.entities);
  const loading = useAppSelector(state => state.PlayerClub?.loading) || false;
  const param = useParams<{ id: string; params: string; leagueId: string }>();
  const handleClose = () => {
    setCreateModal(false);
  };

  const defaultFilter: FilterParams = {
    page: 0,
    size: 1000,
    sort: 'id,asc',
  };
  const handleFetchData = React.useCallback((params: PlayerLeagueListFilter) => {
    dispatch(getEntitiesFreePlayerLeague({ ...params, clubId: +param.id, leagueId: +param.leagueId }));
  }, []);

  // const { filter, onFilterToQueryString } = useFilter({
  //   defaultFilter,
  //   onFetchData: handleFetchData,
  // });

  const [filter2, setFilter2] = React.useState(defaultFilter);

  useEffect(() => {
    handleFetchData(filter2);
  }, [filter2]);

  const options = [];

  const getFreePlayerLeague = useAppSelector(state => state.playerLeague?.entitiesFreePlayer);

  const handleCreatePlayer = (name: any) => {
    const body = data.map((item: any, index) => ({
      leagueId: +param.leagueId,
      clubId: +param.id,
      playerId: item.id,
      position: item.position,
      uniformNumber: item.uniformNumber,
      createdBy: item.createdBy,
      createdDate: item.createdDate,
      lastModifiedBy: item.lastModifiedBy,
      lastModifiedDate: item.lastModifiedDate,
      status: item.status,
    }));
    if (body[0]?.playerId === undefined || body?.length === 0) {
      toast.warn(translate('leagueApp.playerClub.requiredPlayer'));
      return;
    } else {
      dispatch(createEntity(body));
      setCreateModal(false);
    }
  };

  const rowSelection = {
    onChange(selectedPlayers: React.Key[], selectedRows) {
      setData(selectedRows);
    },
  };

  const columns = [
    {
      title: <Translate contentKey="leagueApp.playerClub.koName" />,
      dataIndex: 'koName',
      key: 'koName',
    },
    {
      title: <Translate contentKey="leagueApp.playerClub.position" />,
      dataIndex: 'position',
      key: 'position',
      align: 'center' as any,
    },
  ];
  return (
    <Modal
      visible={createModal}
      onCancel={handleClose}
      footer={null}
      width={900}
      wrapClassName="management-wrapper-modal"
      title={<Translate contentKey="leagueApp.playerClub.choose" />}
    >
      <Translate contentKey="leagueApp.playerClub.question" />
      <div className="table-responsive">
        <Table
          bordered
          pagination={false}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={getFreePlayerLeague?.content?.map((item, index) => ({ ...item, key: item.id }))}
          // totalElements={getFreePlayerClub.totalElements || 0}
          key="clubId"
          loading={loading}
          // filter={filter2}
          scroll={{
            x: 'calc(400px + 50%)',
            y: 240,
          }}
          // onChange={params => setFilter2({ ...filter2, ...params })}
        />
      </div>
      {/* <Col>
      <Filter filter={filter2} onFilter={filterParams => setFilter2(filterParams)} options={options} />
    </Col> */}
      <Col span={24}>
        <div className="management-modal-footer">
          <Button color="secondary" onClick={handleClose}>
            <Translate contentKey="entity.action.cancel"></Translate>
          </Button>
          <Button id="jhi-confirm-delete-clubUniform" type="primary" style={{ marginLeft: 12 }} onClick={handleCreatePlayer}>
            <Translate contentKey="entity.action.save"></Translate>
          </Button>
        </div>
      </Col>
    </Modal>
  );
};

export default PlayerLeagueCreateDialog;
