import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Col, Form, Row, Select, Space, Typography } from 'antd';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import { Translate, translate } from 'react-jhipster';
import TextField from 'app/modules/lm-input';

import { AUTHORITIES, FILE_TYPE, NOTICE_CATEGORY } from 'app/config/constants';
import { BsCaretLeft } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';
import { downloadFileFromRes } from 'app/shared/util/helper';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import { IAttachFile } from 'app/shared/model/notice.model';
import { IQuestionAnswer } from 'app/shared/model/question-answer.model';
import { deleteEntity, getEntity } from './question-answer.reducer';
import { downloadAttachFileEntity } from '../notice/notice.reducer';
import DetailAnswer from './answer-detail';
import { toast } from 'react-toastify';

const DetailQuestion = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const isAdmin = hasAnyAuthority(authorities, [AUTHORITIES.ADMIN]);
  const isLeagueMaster = hasAnyAuthority(authorities, [AUTHORITIES.LEAGUE_MASTER]);
  const history = useHistory();
  const [modalDelete, setModalDelete] = useState(false);
  const detail: IQuestionAnswer = useAppSelector(state => state.questionAnswerReducer.entity);
  const account = useAppSelector(state => state.authentication.account);
  const handleBack = e => {
    history.goBack();
  };
  const foundAdmin = account?.authorities.find(function (element) {
    return element === 'ROLE_ADMIN';
  });
  const foundLeagueMaster = account?.authorities.find(function (element) {
    return element === 'ROLE_LEAGUE_MASTER';
  });
  const downloadAttachFile = (item: IAttachFile) => {
    dispatch(downloadAttachFileEntity(item.id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        downloadFileFromRes({ blob: res.payload, fileName: `${item.name}.xlsx` });
      }
    });
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(getEntity(id)).then(res => {
      form.setFieldsValue(res.payload);
    });
  }, [id]);

  const confirmDelete = useCallback(() => {
    if (!detail?.id) return;
    dispatch(deleteEntity(detail?.id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        history.push('../../question-and-answer');
        toast.success(translate('leagueApp.questionAnswer.updateSuccess'));
      }
    });
  }, [detail?.id]);


  return (
    <>
      <Card className="mb-3">
        <div className="border-bottom d-flex justify-content-between align-items-center" style={{ paddingBottom: 30, marginBottom: 32 }}>
          <div className="d-flex align-items-center auto-wrap">
            <Button className="rounded management-header-add-button" icon={<BsCaretLeft />} onClick={handleBack} type="primary">
              <Translate contentKey="entity.action.back" />
            </Button>
            <div className="management-layout-divider"></div>
            <p className="h4 mb-0">
              {' '}
              {detail?.title && detail?.title.length > 50 ? detail?.title.substring(0, 50) + '...' : detail?.title}
            </p>
          </div>
          {(detail?.status === 0 && detail?.type === 7 && foundAdmin) ||
          (detail?.status === 0 && detail?.type !== 7 && foundLeagueMaster) ? (
            <div className="d-flex align-items-center auto-wrap">
              <Button
                onClick={e => {
                  history.push('./answer');
                  e.stopPropagation();
                }}
                className="rounded mx-2"
              >
                <Translate contentKey="leagueApp.questionAnswer.answer" />
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
        <Row gutter={[24, 24]}>
          <Col xs={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography.Text className="title-type">{translate(`leagueApp.questionAnswer.QAtype.${detail?.type}`)}</Typography.Text>
              <Typography.Text className="title-time">{convertDateTimeFromServer(detail?.createdDate)}</Typography.Text>
            </div>
            <div className="d-flex align-items-center auto-wrap">
              {account?.login === detail?.createdBy && detail?.status === 0 && (
                <>
                  <Button
                    onClick={e => {
                      history.push('./update');
                      e.stopPropagation();
                    }}
                    className="rounded mx-2"
                  >
                    <Translate contentKey="entity.action.edit" />
                  </Button>
                  <Button
                    onClick={e => {
                      setModalDelete(true);
                    }}
                    className="rounded"
                    type="primary"
                    danger
                  >
                    <Translate contentKey="entity.action.delete2">Delete</Translate>
                  </Button>
                </>
              )}
            </div>
          </Col>
          {/* <Col flex="none" xs={6}>
          <Typography.Text type="secondary" className="h5 opacity-5">
            {convertDateTimeFromServer(detail?.createdDate)}
          </Typography.Text>
        </Col> */}
          <Col xs={24} className="ql-container ql-editor">
            <div dangerouslySetInnerHTML={{ __html: detail.content }}></div>
          </Col>
          {(detail?.attachmentDTOS && detail?.attachmentDTOS?.length !== 0) && (
            <Col xs={24}>
              <p className="file-attached">
                {' '}
                <Translate contentKey="leagueApp.notice.fileAttach" />
              </p>
              <Space size={'middle'} direction="vertical">
                {detail?.attachmentDTOS?.map((item: IAttachFile) =>
                  item.typeFile === FILE_TYPE.DOCUMENT ? (
                    <span onClick={() => downloadAttachFile(item)} style={{ color: '#0062AE' }} className="hot-link-club" key={item.id}>
                      {item.name}
                    </span>
                  ) : (
                    <img
                      style={{ width: 90, height: 90, objectFit: 'contain' }}
                      className="image-attach"
                      key={item.id}
                      src={item.url}
                      title={item.name}
                      onClick={() => window.open(item.url)}
                    />
                  )
                )}
              </Space>
            </Col>
          )}

          <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
            {(detail?.status === 0 && detail?.type === 7 && foundAdmin) ||
            (detail?.status === 0 && detail?.type !== 7 && foundLeagueMaster) ? (
              <div className="d-flex align-items-center auto-wrap">
                <Button
                  type="primary"
                  style={{ width: 300 }}
                  onClick={e => {
                    history.push('./answer');
                    e.stopPropagation();
                  }}
                >
                  <Translate contentKey="leagueApp.questionAnswer.answer" />
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
        {modalDelete && (
          <ConfirmationDialog
            open={modalDelete}
            message={<h6>{translate('leagueApp.questionAnswer.deleteQuestion')}</h6>}
            onClose={() => setModalDelete(false)}
            onConfirm={() => confirmDelete()}
          />
        )}
      </Card>
      {detail?.status === 1 && (
        <Card className="mb-3">
          <DetailAnswer />
        </Card>
      )}
    </>
  );
};

export default DetailQuestion;
