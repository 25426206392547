export enum PositionInMatch {
  NEAR_CONNER_R_OWNER = 'NEARCONNER_R_OWNER',
  NEAR_CONNER_L_OWNER = 'NEARCONNER_L_OWNER',
  OUT_SIDE_PA_R_OWNER = 'OUTSIDEPA_R_OWNER',
  PA_R_OWNER = 'PA_R_OWNER',
  CENTER_PA_OWNER = 'CENTER_PA_OWNER',
  PA_L_OWNER = 'PA_L_OWNER',
  OUTSIDE_PA_L_OWNER = 'OUTSIDE_PA_L_OWNER',
  GA_R_OWNER = 'GA_R_OWNER',
  CENTER_GA_OWNER = 'CENTER_GA_OWNER',
  GA_L_OWNER = 'GA_L_OWNER',
  MF_R_OWNER = 'MF_R_OWNER',
  CENTER_MF_OWNER = 'CENTER_MF_OWNER',
  MF_L_OWNER = 'MF_L_OWNER',
  HALFLINE_R_OWNER = 'HALFLINE_R_OWNER',
  HALFLINE_L_OWNER = 'HALFLINE_L_OWNER',
  FPA_L_OWNER = 'FPA_L_OWNER',
  CENTER_FPA_OWNER = 'CENTER_FPA_OWNER',
  FPA_R_OWNER = 'FPA_R_OWNER',
  FPA_OWNER = 'FPA_OWNER',

  NEAR_CONNER_R_AWAY = 'NEARCONNER_R_AWAY',
  NEARCONNER_L_AWAY = 'NEARCONNER_L_AWAY',
  OUT_SIDE_PA_R_AWAY = 'OUTSIDEPA_R_AWAY',
  PA_R_AWAY = 'PA_R_AWAY',
  CENTER_PA_AWAY = 'CENTER_PA_AWAY',
  PA_L_AWAY = 'PA_L_AWAY',
  OUTSIDE_PA_L_AWAY = 'OUTSIDE_PA_L_AWAY',
  GA_R_AWAY = 'GA_R_AWAY',
  CENTER_GA_AWAY = 'CENTER_GA_AWAY',
  GA_L_AWAY = 'GA_L_AWAY',
  MF_R_AWAY = 'MF_R_AWAY',
  CENTER_MF_AWAY = 'CENTER_MF_AWAY',
  MF_L_AWAY = 'MF_L_AWAY',
  HALFLINE_R_AWAY = 'HALFLINE_R_AWAY',
  HALFLINE_L_AWAY = 'HALFLINE_L_AWAY',
  FPA_L_AWAY = 'FPA_L_AWAY',
  CENTER_FPA_AWAY = 'CENTER_FPA_AWAY',
  FPA_R_AWAY = 'FPA_R_AWAY',
  FPA_AWAY = 'FPA_AWAY',
  CENTER = 'CENTER',
}
