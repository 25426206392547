import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
  ISearchObjectParams,
  Pageable,
} from 'app/shared/reducers/reducer.utils';
import { IClubOfficer, defaultValue } from 'app/shared/model/club-officer.model';

interface ClubOfficerState extends EntityState<IClubOfficer> {
  uploading: boolean;
}

const initialState: ClubOfficerState = {
  loading: false,
  uploading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/officers';
const getApi = 'api/officers/search';

// Actions

export const getEntities = createAsyncThunk('clubOfficer/fetch_entity_list', async ({ page, size, sort, ...res }: IQueryParams) => {
  const requestUrl = `${getApi}${sort ? `?page=${page}&size=${size}&sort=${sort}` : '?'}`;
  return axios.post<Pageable<IClubOfficer>>(requestUrl, res);
});

export const getEntity = createAsyncThunk(
  'clubOfficer/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IClubOfficer>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'clubOfficer/create_entity',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<IClubOfficer>(apiUrl, entity);
    thunkAPI.dispatch(getEntities({ sort: 'asc', clubId: result?.data[0]?.clubId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'clubOfficer/update_entity',
  async (entity: IClubOfficer, thunkAPI) => {
    const result = await axios.put<IClubOfficer>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'clubOfficer/partial_update_entity',
  async (entity: IClubOfficer, thunkAPI) => {
    const result = await axios.patch<IClubOfficer>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'clubOfficer/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IClubOfficer>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const searchEntities = createAsyncThunk(
  'clubOfficer/search_entity_list',
  async ({ page, size, sort, payload }: ISearchObjectParams) => {
    const requestUrl = `${getApi}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}`;
    return axios.post<IClubOfficer[]>(requestUrl, payload);
  }
);

// slice

export const ClubOfficerSlice = createEntitySlice({
  name: 'clubOfficer',
  initialState,
  reducers: {
    clearMsgUpdate: (state, action) => void (state.updateSuccess = null),
    clearMsgDelete: (state, action) => void (state.deleteSuccess = null),
    clearMsgCreate: (state, action) => void (state.createSuccess = null),
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.deleteSuccess = true;
        state.entity = {};
      })

      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data?.content ?? [],
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(searchEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          entity: {},
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
        state.dataUploadFile = null;
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.createSuccess = true;
        state.entity = action.payload.data;
        state.dataUploadFile = null;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { clearMsgUpdate, clearMsgDelete, clearMsgCreate, reset } = ClubOfficerSlice.actions;
// Reducer
export default ClubOfficerSlice.reducer;
