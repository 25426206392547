import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  defaultValue,
  ICRUDWhitelist,
  IHistory,
  IParamsFilterHistories,
  IParamsFilterWhitelist,
  IUser,
  IWhiteList,
} from 'app/shared/model/user.model';
import { IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import { cleanEntity } from 'app/shared/util/entity-utils';

const initialState = {
  loading: false,
  errorMessage: null,
  users: [] as ReadonlyArray<IUser>,
  authorities: [] as any[],
  user: defaultValue,
  updating: false,
  updateSuccess: false,
  accepted: false,
  acceptSuccess: false,
  updateFail: false,
  totalItems: 0,
  login: null,
  userDetail: null,
  histories: [],
  allowedIps: [],
};

const apiUrl = 'api/users';
const adminUrl = 'api/admin/users';
// const urlLocal = 'http://192.168.2.68:8082/api'
const urlLocal = 'api';
const urlDetailUsser = 'api/admin/usersById';
const disableUserApi = 'api/admin/users/disable';
const urlWhitelist = 'api/ip-whitelists';
const urlHistory = 'api/login-histories';

// Async Actions

export const getUsersInformation = createAsyncThunk('admin', async () => {
  return axios.get<any>(`${urlLocal}/admin/getUsers`);
});
export const getUsers = createAsyncThunk('userManagement/fetch_users', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${urlLocal}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return axios.get<IUser[]>(requestUrl);
});

export const getUsersAsAdmin = createAsyncThunk('admin', async ({ page, size, sort, ...res }: IQueryParams) => {
  const requestUrl = `${urlLocal}/admin/getUsers${`?page=${page}&size=${size}`}`;
  return axios.post<IUser[]>(requestUrl, res);
});

export const getRoles = createAsyncThunk('userManagement/fetch_roles', async () => {
  return axios.get<any[]>(`api/authorities`);
});

export const createEntity = createAsyncThunk(
  'userManagement/create_entity',
  async (entity: IUser, thunkAPI) => {
    const result = await axios.post<IUser>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getUsers({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getUser = createAsyncThunk(
  'userManagement/fetch_user',
  async (id: string) => {
    const requestUrl = `${urlDetailUsser}/${id}`;
    return axios.get<IUser>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const ResViewDetail = createAsyncThunk(
  'admin/users',
  async (userData: any) => {
    return axios.get<any>(`api/admin/users/${userData.login}`);
  },
  { serializeError: serializeAxiosError }
);

export const createUser = createAsyncThunk(
  'userManagement/create_user',
  async (user: IUser, thunkAPI) => {
    const result = await axios.post<IUser>(adminUrl, user);
    thunkAPI.dispatch(getUsersAsAdmin({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateUser = createAsyncThunk(
  'userManagement/update_user',
  async (user: IUser, thunkAPI) => {
    const result = axios.put<IUser>(adminUrl, user);
    thunkAPI.dispatch(getUsersAsAdmin({}));
    result.catch(err => {
      if (err.response.data.title === 'Login name already used!') {
        toast.error(translate('userManagement.required.duplicateID'));
      }
    });
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deniedUser = createAsyncThunk(
  'admin/users/approve',
  async (deleteUsers: any, thunkAPI) => {
    const requestUrl = `${urlLocal}/admin/users/approve?userId=${deleteUsers?.userId}&status=${deleteUsers?.status}`;
    const result = axios.post<IUser>(requestUrl);
    // thunkAPI.dispatch(getUsersAsAdmin({}));
    result.then(res => {
      // if(res.data === 'Successfully approved') {
      //   toast.success(translate('userManagement.approve.success'))
      // } else if (res.data === 'The account has been denied') {
      //   toast.success(translate('userManagement.reject.success'))
      // }
    });
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const disableUser = createAsyncThunk(
  'admin/user/disable_user',
  async ({ id, status }: any) => {
    const requestUrl = `${disableUserApi}?userId=${id}&status=${status}`;
    const result = await axios.post<IUser>(requestUrl);
    // thunkAPI.dispatch(getUsersAsAdmin({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const resetPass = createAsyncThunk(
  'admin/user/reset_pass',
  async (id: string, thunkAPI) => {
    const requestUrl = `api/admin/resetPassword/${id}`;
    const result = await axios.get<IUser>(requestUrl);
    // thunkAPI.dispatch(getUsersAsAdmin({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getWhitelistEntities = createAsyncThunk('admin/user/get_whitelist', async (entity: IParamsFilterWhitelist) => {
  const requestUrl = urlWhitelist;
  return axios.get<IWhiteList[]>(requestUrl, {
    params: entity,
  });
});

export const getDetailWhitelistEntity = createAsyncThunk('admin/user/get_detail_whitelist', async (entity: ICRUDWhitelist, thunkAPI) => {
  const requestUrl = `${urlWhitelist}/${entity.whitelistId}`;
  return axios.get<IWhiteList>(requestUrl);
});

export const createWhitelistEntity = createAsyncThunk('admin/user/create_whitelist', async (entity: ICRUDWhitelist, thunkAPI) => {
  const requestUrl = urlWhitelist;
  return axios.post<IWhiteList>(requestUrl, entity.whitelist);
});
export const updateWhitelistEntity = createAsyncThunk('admin/user/update_whitelist', async (entity: ICRUDWhitelist, thunkAPI) => {
  const requestUrl = `${urlWhitelist}/${entity.whitelistId}`;
  return axios.put<IWhiteList>(requestUrl, entity.whitelist);
});
export const deleteWhitelistEntity = createAsyncThunk('admin/user/delete_whitelist', async (entity: ICRUDWhitelist, thunkAPI) => {
  const requestUrl = `${urlWhitelist}/${entity.whitelistId}`;
  return axios.delete<IWhiteList>(requestUrl);
});

export const getHistoriesEntities = createAsyncThunk('admin/user/get_histories', async (entity: IParamsFilterHistories) => {
  const requestUrl = urlHistory;
  return axios.get<IHistory[]>(requestUrl, {
    params: entity,
  });
});

export type UserManagementState = Readonly<typeof initialState>;

export const UserManagementSlice = createSlice({
  name: 'userManagement',
  initialState: initialState as UserManagementState,
  reducers: {
    reset() {
      return initialState;
    },
    clearMsgUpdate: (state, action) => void (state.updateSuccess = null),
  },
  extraReducers(builder) {
    builder
      .addCase(getRoles.fulfilled, (state, action) => {
        state.authorities = action.payload.data;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data;
      })
      .addCase(deniedUser.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.user = defaultValue;
      })

      .addMatcher(isFulfilled(getWhitelistEntities), (state, action) => {
        state.allowedIps = action.payload.data;
        state.loading = false;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isFulfilled(getHistoriesEntities), (state, action) => {
        state.histories = action.payload.data;
        state.loading = false;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isFulfilled(getUsersAsAdmin), (state, action) => {
        state.loading = false;
        state.users = action.payload.data;

        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isFulfilled(createUser, updateUser), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.user = action.payload.data;
      })
      .addMatcher(isFulfilled(createWhitelistEntity, updateWhitelistEntity, deleteWhitelistEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getUsersAsAdmin, getUser), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
        state.users = [];
      })
      .addMatcher(
        isPending(createUser, updateUser, deniedUser, createWhitelistEntity, updateWhitelistEntity, deleteWhitelistEntity),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
          state.updating = true;
        }
      )
      .addMatcher(isRejected(getUsersAsAdmin, getUser, getRoles, createUser, updateUser, deniedUser), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
        state.users = [];
      })
      .addMatcher(isFulfilled(ResViewDetail), (state, action) => {
        // state.loading = false;
        // state.users = action.payload.data;
        // // state.login = action.payload.data.login;
        state.userDetail = action.payload.data;
        // state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isPending(ResViewDetail), state => {
        state.userDetail = null;
      })
      .addMatcher(isRejected(ResViewDetail), (state, action) => {
        state.userDetail = null;
      });
  },
});

export const { reset, clearMsgUpdate } = UserManagementSlice.actions;

// Reducer
export default UserManagementSlice.reducer;
