import { Avatar, Col, Form, Input, Row } from 'antd';
import { APPROVAL_TYPE, AUTHORITIES, TYPE_INFORMATION } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import InputTime from 'app/modules/im-input-time';
import ModalChange from 'app/modules/modal-change';
import { IMatchTime } from 'app/shared/model/match-time.model';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import { regexInputTime } from 'app/shared/util/regex';
import React, { useEffect, useMemo, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import { createMatchRate, getDetailMatchRate, resetSave, updateMatchRate } from '../../match-record.reducer';
import './index.scss';

const MatchRate = ({ isOpen, setIsOpen, dataMatchInfo, id, ownerClubId, awayClubId }) => {
  const [form] = Form.useForm();
  const [disableExtra1, setDisableExtra1] = useState(true);
  const [disableExtra2, setDisableExtra2] = useState(true);
  const [valueInput, setValueInput] = useState('');
  const [disableSave, setDisabelSave] = useState(false)
  const [saveId, setSaveId] = useState([]);
  const firstHalfOwnerInput1: string = Form.useWatch('firstHalfOwnerInput1', form);
  const firstHalfAwayInput1: string = Form.useWatch('firstHalfAwayInput1', form);
  const firstHalfOwnerInput2 = Form.useWatch('firstHalfOwnerInput2', form);
  const firstHalfAwayInput2 = Form.useWatch('firstHalfAwayInput2', form);
  const firstHalfOwnerInput3 = Form.useWatch('firstHalfOwnerInput3', form);
  const firstHalfAwayInput3 = Form.useWatch('firstHalfAwayInput3', form);
  const secondHalfOwnerInput1 = Form.useWatch('secondHalfOwnerInput1', form);
  const secondHalfAwayInput1 = Form.useWatch('secondHalfAwayInput1', form);
  const secondHalfOwnerInput2 = Form.useWatch('secondHalfOwnerInput2', form);
  const secondHalfAwayInput2 = Form.useWatch('secondHalfAwayInput2', form);
  const secondHalfOwnerInput3 = Form.useWatch('secondHalfOwnerInput3', form);
  const secondHalfAwayInput3 = Form.useWatch('secondHalfAwayInput3', form);
  const Extra1OwnerInput1 = Form.useWatch('extra1OwnerInput1', form);
  const Extra1AwayInput1 = Form.useWatch('extra1AwayInput1', form);
  const Extra2OwnerInput1 = Form.useWatch('extra2OwnerInput1', form);
  const Extra2AwayInput1 = Form.useWatch('extra2AwayInput1', form);
  const dispatch = useAppDispatch();
  const matchRateDetail = useAppSelector(state => state.matchRecord.matchRateDetail);
  const saveSuccess = useAppSelector(state => state.matchRecord.saveSuccess);
  const matchTimes: IMatchTime = useAppSelector(state => state.matchTime?.entity);
  const matchBasicInfoEntity = useAppSelector(state => state.match.entity);
  const account = useAppSelector(state => state.authentication.account);
  const isAdmin = useMemo(() => {
    return account?.authorities.some(item => item === AUTHORITIES.ADMIN);
  }, [account]);

  const isRecorder = useMemo(() => {
    return account?.authorities.some(item => item === AUTHORITIES.RECORDER);
  }, [account]);

  const isLeagueMaster = useMemo(() => {
    return account?.authorities.some(item => item === AUTHORITIES.LEAGUE_MASTER);
  }, [account]);
  useEffect(() => {
    if (id) {
      dispatch(getDetailMatchRate(id));
    }
  }, [id]);

  useEffect(() => {
    if(matchBasicInfoEntity?.status === 3 && matchBasicInfoEntity?.approval === 3) {
      setDisabelSave(true)
    } else {
      setDisabelSave(false)
    }
  }, [matchBasicInfoEntity])
  useEffect(() => {
    if (matchRateDetail) {
      matchRateDetail.map(item => {
        if (item?.id) {
          setSaveId(state => [...state, item?.id]);
        }
        if (item.clubId === ownerClubId) {
          form.setFieldsValue({
            firstHalfOwnerInput1: item.r11 ? handleConvertData(item.r11) : undefined,
            firstHalfOwnerInput2: item.r12 ? handleConvertData(item.r12) : undefined,
            firstHalfOwnerInput3: item.r13 ? handleConvertData(item.r13) : undefined,
            secondHalfOwnerInput1: item.r21 ? handleConvertData(item.r21) : undefined,
            secondHalfOwnerInput2: item.r22 ? handleConvertData(item.r22) : undefined,
            secondHalfOwnerInput3: item.r23 ? handleConvertData(item.r23) : undefined,
            extra1OwnerInput1: item.r31 ? handleConvertData(item.r31) : undefined,
            extra2OwnerInput1: item.r32 ? handleConvertData(item.r32) : undefined,
          });
        }
        if (item.clubId === awayClubId) {
          form.setFieldsValue({
            firstHalfAwayInput1: item.r11 ? handleConvertData(item.r11) : undefined,
            firstHalfAwayInput2: item.r12 ? handleConvertData(item.r12) : undefined,
            firstHalfAwayInput3: item.r13 ? handleConvertData(item.r13) : undefined,
            secondHalfAwayInput1: item.r21 ? handleConvertData(item.r21) : undefined,
            secondHalfAwayInput2: item.r22 ? handleConvertData(item.r22) : undefined,
            secondHalfAwayInput3: item.r23 ? handleConvertData(item.r23) : undefined,
            extra1AwayInput1: item.r31 ? handleConvertData(item.r31) : undefined,
            extra2AwayInput1: item.r32 ? handleConvertData(item.r32) : undefined,
          });
        }
        return item;
      });
    }
  }, [matchRateDetail]);
  useEffect(() => {
    if (saveSuccess) {
      setIsOpen(false);
      dispatch(resetSave());
    }
  }, [saveSuccess]);
  const handleSubmit = data => {
    const body = [
      {
        matchId: +id,
        clubId: ownerClubId,
        r11: handleConvertValue(data.firstHalfOwnerInput1),
        r12: handleConvertValue(data.firstHalfOwnerInput2),
        r13: handleConvertValue(data.firstHalfOwnerInput3),
        r21: handleConvertValue(data.secondHalfOwnerInput1),
        r22: handleConvertValue(data.secondHalfOwnerInput2),
        r23: handleConvertValue(data.secondHalfOwnerInput3),
        r31: handleConvertValue(data.extra1OwnerInput1),
        r32: handleConvertValue(data.extra2OwnerInput1),
      },
      {
        matchId: +id,
        clubId: awayClubId,
        r11: handleConvertValue(data.firstHalfAwayInput1),
        r12: handleConvertValue(data.firstHalfAwayInput2),
        r13: handleConvertValue(data.firstHalfAwayInput3),
        r21: handleConvertValue(data.secondHalfAwayInput1),
        r22: handleConvertValue(data.secondHalfAwayInput2),
        r23: handleConvertValue(data.secondHalfAwayInput3),
        r31: handleConvertValue(data.extra1AwayInput1),
        r32: handleConvertValue(data.extra2AwayInput1),
      },
    ];
    if (!saveId.length) {
      dispatch(createMatchRate(body));
    } else {
      const newArr = body.map((item, index) => {
        return {
          ...item,
          id: matchRateDetail[index]?.id,
        };
      });
      dispatch(updateMatchRate(newArr));
    }
  };
  const validate = (_, value) => {
    if (!value) {
      return Promise.resolve();
    } else {
      if (regexInputTime.test(value)) {
        return Promise.resolve();
      } else {
        return Promise.reject(translate('leagueApp.matchRate.messageValidate'));
      }
    }
  };
  const validateMax = (value, minute) => {
    if (!value) {
      return Promise.resolve();
    } else {
      if (regexInputTime.test(value)) {
        if (handleConvertValue(value) > minute * 60) {
          return Promise.reject(translate(`leagueApp.matchRate.${minute}`));
        }
        return Promise.resolve();
      } else {
        return Promise.reject(translate('leagueApp.matchRate.messageValidate'));
      }
    }
  };
  const rulesMax15 = (_, value) => {
    return validateMax(value, 15);
  };
  const rulesMax30 = (_, value) => {
    return validateMax(value, 30);
  };
  const rulesMax45 = (_, value) => {
    return validateMax(value, 45);
  };
  const handleCalculateRate = (ownerInput: string, awayInput: string) => {
    if (ownerInput && ownerInput?.includes(':') && awayInput && awayInput?.includes(':')) {
      const a = Number(ownerInput?.split(':')[0]) * 60 + Number(ownerInput?.split(':')[1]);
      const b = Number(awayInput?.split(':')[0]) * 60 + Number(awayInput?.split(':')[1]);
      const firstRate = ((a / (a + b)) * 100).toFixed(2);
      const secondRate = ((b / (a + b)) * 100).toFixed(2);
      return { firstRate, secondRate };
    }
  };
  const handleConvertValue = (value: string) => {
    if (value && value?.includes(':')) {
      return Number(value.split(':')[0]) * 60 + Number(value.split(':')[1]);
    }
  };
  const rules = {
    validator: validate,
  };
  const rules15 = {
    validator: rulesMax15,
  };
  const rules30 = {
    validator: rulesMax30,
  };
  const rules45 = {
    validator: rulesMax45,
  };
  const handleShowRate = (ownerInput: string, awayInput: string) => {
    if (handleCalculateRate(ownerInput, awayInput)?.firstRate && handleCalculateRate(ownerInput, awayInput)?.secondRate) {
      return `${handleCalculateRate(ownerInput, awayInput)?.firstRate} : ${handleCalculateRate(ownerInput, awayInput)?.secondRate}`;
    } else {
      return '-';
    }
  };
  const handleConvertData = (value: number) => {
    let a = Math.floor(value / 60).toString();
    let b = Math.floor(value % 60).toString();
    if (a.length === 1) {
      a = '0' + a;
    }
    if (b.length === 1) {
      b = '0' + b;
    }
    return a + ':' + b;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, name: string, minute) => {
    if (e.target.value && e.target.value?.includes(':')) {
      const a = Number(e.target.value?.split(':')[0]) * 60 + Number(e.target.value?.split(':')[1]);
      const b = minute * 60 - a;
      form.setFieldsValue({
        [name]: handleConvertData(b),
      });
    }
    if (!e.target.value) {
      form.setFieldsValue({
        [name]: undefined,
      });
    }
  };

  function edValueKeyPress(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }
    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ firstHalfOwnerInput1: time });
  }

  function edValueKeyPress1(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }
    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ firstHalfAwayInput1: time });
  }

  function edValueKeyPress2(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }
    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ firstHalfOwnerInput2: time });
  }

  function edValueKeyPress3(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }
    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ firstHalfAwayInput2: time });
  }

  function edValueKeyPress4(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }

    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ firstHalfOwnerInput3: time });
  }

  function edValueKeyPress5(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }
    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ firstHalfAwayInput3: time });
  }

  function edValueKeyPress6(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }
    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ secondHalfOwnerInput1: time });
  }
  function edValueKeyPress7(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }
    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ secondHalfAwayInput1: time });
  }

  function edValueKeyPress8(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }
    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ secondHalfOwnerInput2: time });
  }

  function edValueKeyPress9(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }
    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ secondHalfAwayInput2: time });
  }

  function edValueKeyPress10(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }
    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ secondHalfOwnerInput3: time });
  }

  function edValueKeyPress11(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }

    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ secondHalfAwayInput3: time });
  }

  function edValueKeyPress12(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }

    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ extra1OwnerInput1: time });
  }

  function edValueKeyPress13(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }

    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ extra1AwayInput1: time });
  }

  function edValueKeyPress14(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }
    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ extra2OwnerInput1: time });
  }

  function edValueKeyPress15(e) {
    let time = e.target.value;
    time = time.replace(':', '');
    // time = '0000' + time;
    if (time.length < 4) {
      // time = '0000' + time;
      return false;
    }
    if (time.length >= 4) {
      time = time.substring(time.length - 4, time.length);
    }
    time = time.substring(0, 2) + ':' + time.substring(2, 4);
    const arr = time.split(':');
    if (arr && arr.length && arr.length === 2) {
      let min = arr[0] * 1;
      let seconds = arr[1] * 1;
      if (min === 99 && seconds >= 60) {
        time = min + ':' + 59;
      } else if (seconds >= 60) {
        const divided = Math.floor(seconds / 60);
        min += divided;
        seconds = seconds - 60 * divided;
        time = (min < 10 ? `0${min}` : min) + ':' + (seconds < 10 ? `0${seconds}` : seconds);
      }
    }
    form.setFieldsValue({ extra2AwayInput1: time });
  }
  const handleCheckTime = (value: string, time: number) => {
    if (!value) {
      return true;
    }
    const newValue = new Date(value).getTime();
    const timeNow = new Date(matchTimes?.now).getTime();
    const changeTime = time * 60 * 1000;
    // if (timeNow - newValue < changeTime) {
    //   return true;
    // } else false;
  };

  return (
    <ModalChange
      isOpen={isOpen}
      title={<Translate contentKey="leagueApp.matchRate.matchRate">Enter match rate</Translate>}
      handleClose={() => setIsOpen(false)}
      className="register-record"
      form="matchRate"
      width={1200}
      disable={disableSave}
    >
      <Form
        form={form}
        id="matchRate"
        onFinishFailed={values => handleFocusFieldFail(values)}
        scrollToFirstError
        name="control-hooks"
        onFinish={handleSubmit}
        className="custom-form-ratio"
        style={{ width: 1020, overflow: 'auto' }}
      >
        <Row justify="center" style={{ width: 1000 }}>
          <Col span={24}>
            <Row gutter={16} style={{ marginBottom: '24px' }} align="middle">
              <Col span={3} />
              <Col span={3} />
              <Col span={7}>
                <Avatar src={dataMatchInfo?.ownerBase64Avt} size={40} />
                <span className="club-name">{dataMatchInfo?.ownerClubName}</span>
              </Col>
              <Col span={7}>
                <Avatar src={dataMatchInfo?.awayBase64Avt} size={40} />
                <span className="club-name">{dataMatchInfo?.awayClubName}</span>
              </Col>
              <Col span={4}>
                <Translate contentKey="leagueApp.matchRate.rate">Rate</Translate>
              </Col>
            </Row>
            <Row gutter={16} align="middle" style={{ marginBottom: '10px' }}>
              <Col span={3}>
                <Translate contentKey="leagueApp.matchRate.firstHalf">First half</Translate>
              </Col>
              <Col span={3}>
                <span>15 &apos;</span>
              </Col>
              <Col span={7}>
                {/* <Form.Item name="firstHalfOwnerInput1" rules={[rules15]}> */}
                <Form.Item name="firstHalfOwnerInput1" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      handleCheckTime(matchTimes?.startTime1, 15) ||
                      (handleCheckTime(matchTimes?.startTime1, 15) &&
                        matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT &&
                        isRecorder) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'firstHalfAwayInput1', 15)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                {/* <Form.Item name="firstHalfAwayInput1" rules={[rules15]}> */}
                <Form.Item name="firstHalfAwayInput1" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      (handleCheckTime(matchTimes?.startTime1, 15) && matchBasicInfoEntity?.flag === TYPE_INFORMATION.TYPED) ||
                      (handleCheckTime(matchTimes?.startTime1, 15) &&
                        matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT &&
                        isRecorder) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'firstHalfOwnerInput1', 15)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress1(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress1(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <span>{handleShowRate(firstHalfOwnerInput1, firstHalfAwayInput1)}</span>
              </Col>
            </Row>
            <Row gutter={16} align="middle" style={{ marginBottom: '10px' }}>
              <Col span={3} />
              <Col span={3}>
                <span>30 &apos;</span>
              </Col>
              <Col span={7}>
                {/* <Form.Item name="firstHalfOwnerInput2" rules={[rules30]}> */}
                <Form.Item name="firstHalfOwnerInput2" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      handleCheckTime(matchTimes?.startTime1, 30) ||
                      (handleCheckTime(matchTimes?.startTime1, 30) &&
                        matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT &&
                        isRecorder) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'firstHalfAwayInput2', 30)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress2(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress2(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                {/* <Form.Item name="firstHalfAwayInput2" rules={[rules30]}> */}
                <Form.Item name="firstHalfAwayInput2" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      handleCheckTime(matchTimes?.startTime1, 30) ||
                      (handleCheckTime(matchTimes?.startTime1, 30) &&
                        matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT &&
                        isRecorder) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'firstHalfOwnerInput2', 30)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress3(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress3(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <span>{handleShowRate(firstHalfOwnerInput2, firstHalfAwayInput2)}</span>
              </Col>
            </Row>
            <Row gutter={16} align="middle" style={{ marginBottom: '10px' }}>
              <Col span={3} />
              <Col span={3}>
                <span>45 &apos;</span>
              </Col>
              <Col span={7}>
                {/* <Form.Item name="firstHalfOwnerInput3" rules={[rules45]}> */}
                <Form.Item name="firstHalfOwnerInput3" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      handleCheckTime(matchTimes?.startTime1, 45) ||
                      (handleCheckTime(matchTimes?.startTime1, 45) &&
                        matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT &&
                        isRecorder) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'firstHalfAwayInput3', 45)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress4(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress4(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                {/* <Form.Item name="firstHalfAwayInput3" rules={[rules45]}> */}
                <Form.Item name="firstHalfAwayInput3" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      handleCheckTime(matchTimes?.startTime1, 45) ||
                      (handleCheckTime(matchTimes?.startTime1, 45) &&
                        matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT &&
                        isRecorder) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'firstHalfOwnerInput3', 45)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress5(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress5(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <span>{handleShowRate(firstHalfOwnerInput3, firstHalfAwayInput3)}</span>
              </Col>
            </Row>
            <Row gutter={16} align="middle" style={{ marginBottom: '10px' }}>
              <Col span={3}>
                <Translate contentKey="leagueApp.matchRate.secondHalf">Second Half</Translate>
              </Col>
              <Col span={3}>
                <span>15 &apos;</span>
              </Col>
              <Col span={7}>
                {/* <Form.Item name="secondHalfOwnerInput1" rules={[rules15]}> */}
                <Form.Item name="secondHalfOwnerInput1" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      handleCheckTime(matchTimes?.startTime2, 15) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT &&
                        isRecorder &&
                        handleCheckTime(matchTimes?.startTime2, 15)) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'secondHalfAwayInput1', 15)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress6(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress6(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                {/* <Form.Item name="secondHalfAwayInput1" rules={[rules15]}> */}
                <Form.Item name="secondHalfAwayInput1" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      handleCheckTime(matchTimes?.startTime2, 15) ||
                      (handleCheckTime(matchTimes?.startTime2, 15) &&
                        matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT &&
                        isRecorder) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'secondHalfOwnerInput1', 15)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress7(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress7(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <span>{handleShowRate(secondHalfOwnerInput1, secondHalfAwayInput1)}</span>
              </Col>
            </Row>
            <Row gutter={16} align="middle" style={{ marginBottom: '10px' }}>
              <Col span={3} />
              <Col span={3}>
                <span>30 &apos;</span>
              </Col>
              <Col span={7}>
                {/* <Form.Item name="secondHalfOwnerInput2" rules={[rules30]}> */}
                <Form.Item name="secondHalfOwnerInput2" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      handleCheckTime(matchTimes?.startTime2, 30) ||
                      (handleCheckTime(matchTimes?.startTime2, 30) &&
                        matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT &&
                        isRecorder) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'secondHalfAwayInput2', 30)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress8(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress8(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                {/* <Form.Item name="secondHalfAwayInput2" rules={[rules30]}> */}
                <Form.Item name="secondHalfAwayInput2" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      handleCheckTime(matchTimes?.startTime2, 30) ||
                      (handleCheckTime(matchTimes?.startTime2, 30) &&
                        matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT &&
                        isRecorder) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'secondHalfOwnerInput2', 30)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress9(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress9(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <span>{handleShowRate(secondHalfOwnerInput2, secondHalfAwayInput2)}</span>
              </Col>
            </Row>
            <Row gutter={16} align="middle" style={{ marginBottom: '10px' }}>
              <Col span={3} />
              <Col span={3}>
                <span>45 &apos;</span>
              </Col>
              <Col span={7}>
                {/* <Form.Item name="secondHalfOwnerInput3" rules={[rules45]}> */}
                <Form.Item name="secondHalfOwnerInput3" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      handleCheckTime(matchTimes?.startTime2, 45) ||
                      (handleCheckTime(matchTimes?.startTime2, 45) &&
                        matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT &&
                        isRecorder) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'secondHalfAwayInput3', 45)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress10(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress10(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                {/* <Form.Item name="secondHalfAwayInput3" rules={[rules45]}> */}
                <Form.Item name="secondHalfAwayInput3" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      handleCheckTime(matchTimes?.startTime2, 45) ||
                      (handleCheckTime(matchTimes?.startTime2, 45) &&
                        matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT &&
                        isRecorder) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'secondHalfOwnerInput3', 45)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress11(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress11(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <span>{handleShowRate(secondHalfOwnerInput3, secondHalfAwayInput3)}</span>
              </Col>
            </Row>
            <Row gutter={16} align="middle" style={{ marginBottom: '10px' }}>
              <Col span={3}>
                <Translate contentKey="leagueApp.matchRate.extraTime1">Extra time 1</Translate>
              </Col>
              <Col span={3} />
              <Col span={7}>
                {/* <Form.Item name="extra1OwnerInput1" rules={[rules15]}> */}
                <Form.Item name="extra1OwnerInput1" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      !matchTimes.startSubTime1 ||
                      (!matchTimes.startSubTime1 && matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'extra1AwayInput1', 15)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress12(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress12(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                {/* <Form.Item name="extra1AwayInput1" rules={[rules15]}> */}
                <Form.Item name="extra1AwayInput1" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      !matchTimes.startSubTime1 ||
                      (!matchTimes.startSubTime1 && matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'extra1OwnerInput1', 15)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress13(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress13(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <span>{handleShowRate(Extra1OwnerInput1, Extra1AwayInput1)}</span>
              </Col>
            </Row>
            <Row gutter={16} align="middle" style={{ marginBottom: '10px' }}>
              <Col span={3}>
                <Translate contentKey="leagueApp.matchRate.extraTime2">Extra time 2</Translate>
              </Col>
              <Col span={3} />
              <Col span={7}>
                {/* <Form.Item name="extra2OwnerInput1" rules={[rules15]}> */}
                <Form.Item name="extra2OwnerInput1" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      !matchTimes.startSubTime2 ||
                      (!matchTimes.startSubTime2 && matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'extra2AwayInput1', 15)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress14(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress14(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                {/* <Form.Item name="extra2AwayInput1" rules={[rules15]}> */}
                <Form.Item name="extra2AwayInput1" rules={[{ min: 4, message: translate('leagueApp..match.required.maxRate') }]}>
                  <InputTime
                    maxLength={5}
                    disabled={
                      !matchTimes.startSubTime2 ||
                      (!matchTimes.startSubTime2 && matchBasicInfoEntity?.flag === TYPE_INFORMATION.TYPED) ||
                      (!matchTimes.startSubTime2 && matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) ||
                      matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED
                    }
                    placeholder="MM:SS"
                    // onChange={e => handleChange(e, 'extra2OwnerInput1', 15)}
                    onKeyPress={event =>
                      setTimeout(() => {
                        edValueKeyPress15(event);
                      }, 700)
                    }
                    onKeyUp={event =>
                      setTimeout(() => {
                        edValueKeyPress15(event);
                      }, 700)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <span>{handleShowRate(Extra2OwnerInput1, Extra2AwayInput1)}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </ModalChange>
  );
};

export default MatchRate;
