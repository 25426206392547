import React, { useState, useEffect } from 'react';
import { translate } from 'react-jhipster';

import { Card, Col, Form, Modal, Radio, Row, Space, Button } from 'antd';
import ListItem from 'app/modules/pick-player/components/list-item';
import { PickItem } from 'app/modules/pick-item';
import { EditOutlined } from '@ant-design/icons';
import PickPosition from 'app/modules/pick-position';
import PickPlayer from 'app/modules/pick-player';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IPlayerInMatch } from 'app/shared/model/player.model';
import { first, get } from 'lodash';
import { getPosition } from 'app/modules/line-up/components/list-item';
import MiniMapPosition from 'app/modules/mini-map-position';
import { sendWSActivity } from 'app/config/websocket-middleware';
import { renderTime, urlWs } from 'app/modules/event-list-record/constant.event-list';
import { actionRecord } from 'app/shared/util/constant';
import { IPlayerFoulRecord } from 'app/shared/model/player-foul-record.model';
import RecordPlayerInfo from 'app/modules/record-player-info';
import { toast } from 'react-toastify';
import { getEntity, getListCategoryValue } from './assist-goal-record.reducer';
import { useParams } from 'react-router-dom';
import { IPlayerGoalRecord } from 'app/shared/model/player-goal-record.model';
import { IAssistGoalRecord } from 'app/shared/model/assist-goal-record.model';
import './assist.scss';

interface Props {
  open: boolean;
  onCancel: () => void;
  value?: string | null;
  onEditPlayer: () => void;
  onEditPosition: () => void;
  id?: number;
  awayAvatars: any;
  ownerAvatars: any;
  processTime: any;
}
export const PlayerAssist = ({ processTime, onCancel, open, onEditPlayer, onEditPosition, id, awayAvatars, ownerAvatars }: Props) => {
  const form = Form.useFormInstance();
  const [defaultForm, setDefaultForm] = useState<IPlayerFoulRecord>();
  const playerInForm: IPlayerInMatch = Form.useWatch('player', form);

  const ownerLineUp: IPlayerInMatch[] = useAppSelector(state => state.matchRecord?.ownerLineUp) || [];
  const awayLineUp: IPlayerInMatch[] = useAppSelector(state => state.matchRecord?.awayLineUp) || [];
  const dispatch = useAppDispatch();

  const locale = useAppSelector(state => state.locale.currentLocale);

  const { goalList, supportTypes } = useAppSelector(state => state.assistGoalRecord?.list);
  const requiredRule = { required: true, message: '' };

  const params = useParams<{ id: string }>();

  React.useEffect(() => {
    if (!params?.id) {
      return;
    }
    dispatch(getListCategoryValue({ locale, id: params?.id }));
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(getEntity(id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        const data = get(res.payload, 'data');
        setDefaultForm(data);
        form.setFieldsValue({ ...data });
      }
    });
  }, [id]);

  const handleSubmit = data => {
    if (!data.detailLocation) {
      toast.error(translate('scoreRecord.message.supportForm'));
      return;
    }
    if (!id) {
      sendWSActivity(`/record/assist-goal-record/create/${data.player?.matchPlayerFieldDTO.matchId}`, {
        ...data,
        playerId: data.player.playerId,
      });
    } else {
      sendWSActivity(urlWs(actionRecord.ASSIST, 'edit', data.player.matchPlayerFieldDTO.matchId, id), {
        ...defaultForm,
        ...data,
        id,
        playerId: data.player.playerId,
        matchId: data.player.matchPlayerFieldDTO.matchId,
        player: undefined,
        type: Number(data.type),
        processDate: processTime?.process,
        roundType: processTime?.roundType,
      });
    }
    toast.success(translate('common.saveSuccess'));
    onCancel();
  };

  return (
    <Modal
      title={!id ? translate('leagueApp.assistGoalRecord.createAssist') : translate('leagueApp.assistGoalRecord.editAssist')}
      className="register-record"
      visible={open}
      width={850}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      zIndex={1}
      footer={[
        <Button key="close" onClick={() => onCancel()}>
          <span>{translate('freeCornerKick.close')}</span>
        </Button>,
        <Button key="save" type="primary" onClick={() => form.submit()}>
          <span>{translate('freeCornerKick.save')}</span>
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        style={{ width: 820, overflow: 'auto' }}
        onFinishFailed={() => {
          toast.error(translate('common.pleaseChooseEnoughData'));
        }}
      >
        <Row gutter={32} justify="center" style={{ width: 800 }} wrap={false}>
          <Col>
            <RecordPlayerInfo
              avatar={
                // playerInForm?.matchPlayerFieldDTO?.playerDTOS?.avatar
                get(awayAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  ? get(awayAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  : get(ownerAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  ? get(ownerAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  : undefined
              }
              prefix={playerInForm?.positionType}
              onEditPlayer={() => {
                onEditPlayer();
              }}
              onEditPosition={() => {
                onEditPosition();
              }}
              player={playerInForm}
              positionMap={form.getFieldValue('detailLocation')}
            />
          </Col>
          <Col>
            <Row wrap={false}>
              <Col style={{ width: 200 }}>
                <PickItem
                  name="goalId"
                  list={
                    !goalList
                      ? []
                      : goalList?.filter((item: IAssistGoalRecord) => item.clubId === playerInForm?.matchPlayerFieldDTO?.clubId)
                  }
                  getValue={item => item.id}
                  getLabel={(item: IPlayerGoalRecord) => `${renderTime(item.processDate, item.roundType)} ${item?.playerDTO?.koName}`}
                  title={'* ' + translate('leagueApp.assistGoalRecord.listGoal')}
                  rules={[requiredRule]}
                />
              </Col>
              <Col style={{ width: 200 }}>
                <PickItem
                  name="supportType"
                  list={supportTypes || []}
                  getValue={item => Number(item.code)}
                  getLabel={item => item.name}
                  title={translate('leagueApp.assistGoalRecord.supportType')}
                  // rules={[requiredRule]}
                />
              </Col>

              {/* <Col className="last-header">
                <PickItem
                  name="pk"
                  list={pkList}
                  getValue={item => item.value}
                  getLabel={item => item.label}
                  title={translate('leagueApp.playerFoulRecord.pk')}
                  rules={[requiredRule]}
                />
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PlayerAssist;
