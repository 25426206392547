import React, { useState, useEffect } from 'react';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount, translate } from 'react-jhipster';

import { Card, Col, Form, Modal, Radio, Row, Space, Button } from 'antd';
import { PickItem } from 'app/modules/pick-item';
import { fetchListScoreMethods, fetchListSupportKick } from './player-free-corner-kick.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const PlayerFreeCornerKickRecord = props => {
  const [listCountTouch, setListCountTouch] = React.useState([]);
  const dispatch = useAppDispatch();
  const listSupportKick = useAppSelector(state => state.PlayerFreeCornerKick.listSupportKick);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const listScoreMethod = useAppSelector(state => state.PlayerFreeCornerKick.listScoreMethod);
  const listTypeFailKick = useAppSelector(state => state.PlayerFreeCornerKick.listTypeFailKick);
  const listShootDirectionKick = useAppSelector(state => state.PlayerFreeCornerKick.listShootDirectionKick);
  const classifyList = [
    // { value: 1, label: translate('freeCornerKick.classify.freeKick') },
    // { value: 2, label: translate('freeCornerKick.classify.cornerKick') },
    { value: 1, label: translate('freeCornerKick.classify.directShoot') },
    { value: 2, label: translate('freeCornerKick.classify.pass') },
  ];

  const conFirmSubportList = [
    { value: 0, label: translate('freeCornerKick.supportConfirm.yes') },
    { value: 1, label: translate('freeCornerKick.supportConfirm.no') },
  ];

  const rangeList = [
    // distance
    { value: 1, label: translate('scoreRecord.range.distance') },
    { value: 2, label: translate('scoreRecord.range.near') },
  ];

  const shootType = [
    { value: 1, label: translate('freeCornerKick.directShootType.shootTarget') },
    { value: 2, label: translate('freeCornerKick.directShootType.normalShoot') },
  ];

  const goalType = [
    { value: 1, label: translate('freeCornerKick.score.success') },
    { value: 2, label: translate('freeCornerKick.score.fail') },
  ];

  const watchShootType = Form.useWatch('shootType');
  const watchGoalType = Form.useWatch('goalType');
  const watchType = Form.useWatch('type');

  const setListCount = () => {
    const listCount = [];
    let i = 1;
    for (i; i < 6; i++) {
      listCount.push({ value: i, label: i });
    }
    listCount.push({ value: i + 1, label: translate('scoreRecord.countTouch.over_6') });
    setListCountTouch(listCount);
  };

  useEffect(() => {
    setListCount();
    dispatch(fetchListSupportKick(locale));
    dispatch(fetchListScoreMethods(locale));
  }, []);

  const requiredRule = { required: true, message: '' };

  return (
    <Row {...props} wrap={false}>
      <Col className="first-header">
        <PickItem
          name="type"
          list={classifyList}
          getValue={item => item.value}
          getLabel={item => item.label}
          title={'* ' + translate('freeCornerKick.classify.title')}
          rules={[requiredRule]}
        />
      </Col>
      {Number(watchType) === 1 && (
        <Col>
          <PickItem
            name="shootType"
            list={shootType}
            getValue={item => item.value}
            getLabel={item => item.label}
            title={'* ' + translate('freeCornerKick.directShootType.shootType')}
            rules={Number(watchShootType) === 1 && [requiredRule]}
            // secondColumn={true}
          />
        </Col>
      )}
      {Number(watchType) === 1 && (
        <Col style={Number(watchShootType) === 1 ? {} : { display: 'none' }}>
          <PickItem
            name="goalType"
            list={goalType}
            getValue={item => item.value}
            getLabel={item => item.label}
            title={'* ' + translate('freeCornerKick.score.title')}
            rules={Number(watchShootType) === 1 && [requiredRule]}
            // secondColumn={true}
          />
        </Col>
      )}
      {Number(watchType) === 1 && (
        <Col style={Number(watchShootType) === 1 && Number(watchGoalType) === 1 ? {} : { display: 'none' }}>
          <PickItem
            name="goalForm"
            list={listScoreMethod}
            getValue={item => item.value}
            getLabel={item => item.label}
            title={translate('freeCornerKick.goalscorerForm')}
            // rules={Number(watchGoalType) === 1 && [requiredRule]}
            // secondColumn={true}
          />
        </Col>
      )}
      {Number(watchType) === 1 && (
        <Col style={Number(watchShootType) === 1 && Number(watchGoalType) === 2 ? {} : { display: 'none' }}>
          <PickItem
            name="reasonFail"
            list={listTypeFailKick}
            getValue={item => item.value}
            getLabel={item => item.label}
            title={translate('freeCornerKick.failureReason')}
            // rules={Number(watchGoalType) === 2 && [requiredRule]}
            // secondColumn={true}
          />
        </Col>
      )}
      {Number(watchType) === 1 && (
        <Col style={Number(watchShootType) === 1 && Number(watchGoalType) === 1 ? {} : { display: 'none' }}>
          <PickItem
            name="shootDirectionKick"
            list={listShootDirectionKick}
            getValue={item => item.value}
            getLabel={item => item.label}
            title={translate('freeCornerKick.shot')}
            // rules={Number(watchGoalType) === 1 && [requiredRule]}
          />
        </Col>
      )}
      {Number(watchType) === 1 && (
        <Col style={Number(watchShootType) === 1 && Number(watchGoalType) === 1 ? {} : { display: 'none' }}>
          <PickItem
            name="distance"
            list={rangeList}
            getValue={item => item.value}
            getLabel={item => item.label}
            title={translate('scoreRecord.range.title')}
            // rules={Number(watchGoalType) === 1 && [requiredRule]}
          />
        </Col>
      )}
      {/* <Col>
        <PickItem
          name="supportType"
          list={listSupportKick}
          getValue={item => item.value}
          getLabel={item => item.label}
          title={translate('freeCornerKick.supportTitle')}
          rules={[requiredRule]}
          secondColumn={true}
        />
      </Col> */}
      {/* <Col>
        <PickItem
          name="supportConfirm"
          list={conFirmSubportList}
          getValue={item => item.value}
          getLabel={item => item.label}
          title={translate('freeCornerKick.supportConfirm.title')}
          rules={[requiredRule]}
        />
      </Col>
      <Col className="last-header">
        <PickItem
          name="count"
          list={listCountTouch}
          getValue={item => item.value}
          getLabel={item => item.label}
          title={translate('scoreRecord.countTouch.title')}
          rules={[requiredRule]}
        />
      </Col> */}
    </Row>
  );
};

export default PlayerFreeCornerKickRecord;
