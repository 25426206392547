import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Checkbox, Col, Row, Tooltip } from 'antd';
import { Form, Input, Button } from 'antd';
import { useForm } from 'react-hook-form';
import { reset } from '../find-id.reducer';
import TextField from 'app/modules/lm-input';
import { useAppSelector, useAppDispatch } from 'app/config/store';
// import { dispatch } from 'rxjs/internal/observable/pairs';
import { toast } from 'react-toastify';
import { MailLoginIcon } from 'app/shared/util/authIcon';
import { ModalPrivacy } from 'app/entities/privacy-policy/privacy-modal';
import { ModalPolicy } from 'app/entities/privacy-policy/policy-model';

export interface ILoginModalProps {
  showModal: boolean;
  loginError: boolean;
  handleLogin: (username: string, password: string, rememberMe: boolean) => void;
  handleClose: () => void;
}

export const FindIDFinish = (props: ILoginModalProps) => {
  const account = useAppSelector(state => state.findIdReducer.login);
  const [accountState, setAccountState] = React.useState<string | null>(null);
  const [openPolicy, setOpenPolicy] = React.useState(false);
  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (account) {
      setAccountState(account);
    }
  }, [account]);
  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });

  const login = ({ username, password, rememberMe }) => {
    props.handleLogin(username, password, rememberMe);
  };

  // const successMessage = useAppSelector(state => state.findIdReducer.findSuccess);
  // const errorMesssage = useAppSelector(state => state.findIdReducer.findFailure)
  // useEffect(() => {
  //   if (successMessage) {
  //     toast.success(translate(successMessage));
  //   } else if (errorMesssage) {
  //     toast.error(translate(errorMesssage));
  //   }
  // }, [successMessage, errorMesssage]);

  const handleLoginSubmit = e => {
    handleSubmit(login)(e);
  };

  const handleReset = () => {
    dispatch(reset());
  };

  const { loginError, handleClose } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '120px 30px 30px 30px',
        minHeight: '100vh',
      }}
    >
      {/* {loginError ? (
        <Alert color="danger" data-cy="loginError">
          <Translate contentKey="login.messages.error.authentication">
            <strong>Failed to sign in!</strong> Please check your credentials and try again.
          </Translate>
        </Alert>
      ) : null} */}
      <div style={{ maxWidth: 770, width: '100%' }}>
        <Row style={{ width: '100%' }} align="middle" justify="center">
          <Col span={24}>
            <h1 style={{ fontWeight: 'bold', fontSize: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Translate contentKey="login.password.forgot"></Translate>
            </h1>
          </Col>
          <Col span={24}>
            <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="neutral-3">
              <Translate contentKey="findid.question"></Translate>
            </p>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <div>{translate('findid.result')}</div>
            <div className="account-find-id">
              <MailLoginIcon /> <span className="neutral-1">{accountState}</span>
            </div>
            <div style={{ color: '#0062AE' }}>
              <Translate contentKey="reset.finish.messages.privacy" />
            </div>
          </Col>
        </Row>
        <Row style={{ width: '100%', marginTop: 32 }}>
          <Button style={{ width: '100%' }} htmlType="submit" data-cy="submit" type="primary" className="auth-button">
            <Link to="/login">
              <Translate contentKey="reset.finish.messages.goback"></Translate>
            </Link>
          </Button>
        </Row>
      </div>
      <div style={{ marginTop: '20px', flexGrow: 1, display: 'flex', alignItems: 'end' }}>
        <Translate contentKey="footer.copyright" />
      </div>
      <div>
        <span
          onClick={() => {
            setOpenPolicy(true);
          }}
          className="privacy"
          style={{ marginRight: 40 }}
        >
          {translate('footer.privacyLogin')}
        </span>
        <span onClick={() => setOpenPrivacy(true)} className="privacy">
          {translate('footer.termLogin')}
        </span>
      </div>
      {openPrivacy && <ModalPrivacy openPrivacy={openPrivacy} setOpenPrivacy={setOpenPrivacy} />}
      {openPolicy && <ModalPolicy openPolicy={openPolicy} setOpenPolicy={setOpenPolicy} />}
    </div>
  );
};

export default FindIDFinish;
