import dayjs from 'dayjs';
import { DetailLocation } from 'app/shared/model/enumerations/detail-location.model';
import { IPlayer } from './player.model';

export interface IPlayerBanRecord {
  id?: number;
  playerId?: number | null;
  detailLocation?: DetailLocation | null;
  banType?: number | null;
  reasonBan?: number | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  playerDTO?: IPlayer;
}

export const defaultValue: Readonly<IPlayerBanRecord> = {};
