import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Radio, Row, Select } from 'antd';
import { Modal, Button } from 'antd';
import { translate } from 'react-jhipster';
import './style.scss';

export const ModalPolicy = ({ openPolicy, setOpenPolicy }) => {
  return (
    <Modal
      onCancel={() => setOpenPolicy(false)}
      className="policy-modal"
      visible={openPolicy}
      title={<b>{translate('footer.privacyLogin')}</b>}
      footer={[
        <Button key="close" className="rounded" onClick={() => setOpenPolicy(false)}>
          <span>{translate('freeCornerKick.close')}</span>
        </Button>,
      ]}
    >
      <div style={{ overflow: 'auto', height: 500 }}>
        <h5>
          <b>[스포탯 PRO 이용약관]</b>
        </h5>
        <h5>
          <b>시행 2023년 3월 1일</b>
        </h5>
        <br />
        <h6>
          <b>제1조 (목적)</b>
        </h6>
        <p>
          이 약관은 메타버스월드(주) 회사 (이하 &quot;회사&quot; 라 한다) 가 운영하는 기록관리 서비스(이하 &quot;서비스&quot;이라 한다)를 이용함에 있어 서비스
          이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
        </p>
        <br />
        <h6>
          <b>제2조 (약관의 효력 및 변경)</b>
        </h6>
        <p>이 약관은 서비스를 통하여 이를 공지함으로써 효력이 발생됩니다.</p>
        <p>
          회사는 사정상 중요한 사유가 발생될 경우 사전 고지 없이 이 약관의 내용을 변경할 수 있으며, 변경된 약관은 서비스를 통하여
          공지합니다.
        </p>
        <p>
          회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴를 요청할 수 있으며, 변경된 약관의 효력 발생일 이후 해지 요청을 하지 않을 경우
          약관의 변경 사항에 동의한 것으로 간주됩니다.
        </p>
        <br />
        <h6>
          <b>제3조 (약관 외 준칙)</b>
        </h6>
        <p>
          본 약관에 명시되지 아니한 사항에 대해서는 전기통신기본법, 전기통신사업법, 전자상거래 등에서의 소비자보호에 관한 법률, 정보통신망
          이용촉진 및 정보보호 등에 관한 법률 및 기타 관련 법령의 규정에 따릅니다.
        </p>
        <br />
        <h6>
          <b>제4조 (용어의 정의)</b>
        </h6>
        <p>이 약관에서 사용하는 주요한 용어의 정의는 다음과 같습니다.</p>
        <p>
          “회원” : 이 약관을 인지하고, 회사에 개인정보를 제공하여 회원으로서 등록하여 이용자 아이디(ID)를 부여 받은 자로서, 회사의 정보를
          지속적으로 제공 받으며, 회원혜택을 받는 분을 말합니다.
        </p>
        <p>
          “아이디” : 회원의 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 영문이나 숫자 혹은 그 조합을 말합니다.(이하
          “ID”라 합니다.)
        </p>
        <p>
          “비밀번호” : 회원이 부여 받은 ID와 일치된 회원임을 확인하고, 회원 자신의 비밀을 보호하기 위하여 회원이 정한 문자와 숫자의 조합을
          말합니다.
        </p>
        <p>“서비스” : 회사가 회원을 위하여 유료 또는 무료로 제공하는 행위를 말합니다.</p>
        <p>“이용중지” : 회사가 약관에 의거하여 회원의 서비스 이용을 제한하는 것을 말합니다.</p>
        <p>“탈퇴” : 회사 또는 회원이 서비스 사용 후 이용계약을 해약하는 것을 말합니다.</p>
        <br />
        <h6>
          <b>제5조 (이용 계약의 성립)</b>
        </h6>
        <p>관리자에게 계정 요청 시 “요청” 버튼을 클릭하면 본 약관을 읽고 동의하는 것으로 간주합니다.</p>
        <p>
          이용약관은 서비스 이용희망자의 이용약관 동의 후 이용 신청에 대하여 회사가 승낙함으로써 성립하며, 이용 신청 및 회사의 승낙에
          대해서는 본 약관 제6조 및 제7조에서 규정된 바에 따릅니다.
        </p>
        <br />
        <h6>
          <b>제6조 (이용신청)</b>
        </h6>
        <p>
          회원으로 가입하여 서비스를 이용하기를 희망하는 자는 회사가 정한 소정 양식에 따라 요청하는 개인인적사항을 제공하여 이용신청을
          합니다.
        </p>
        <br />
        <h6>
          <b>제7조 (이용 신청의 승낙)</b>
        </h6>
        <p>① 회사는 제6조에 따른 이용신청에 대하여 특별한 사정이 없는 한 접수 순서대로 이용 신청을 승낙합니다.</p>
        <p>② 회사는 다음 각 호에 해당하는 경우 이용신청에 대한 승낙을 제한할 수 있고, 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.</p>
        <ol>
          <li>기술상 지장이 있는 경우</li>
          <li>서비스 관련 설비에 여유가 없는 경우</li>
          <li>기타 회사의 사정상 필요하다고 인정되는 경우</li>
        </ol>
        <p>③ 회사는 다음 각 호에 해당하는 사항을 인지하는 경우 동 이용계약 신청을 승낙하지 않을 수 있습니다.</p>
        <ol>
          <li>본인의 실명으로 신청하지 않은 경우</li>
          <li>다른 사람의 명의를 사용하여 신청한 경우</li>
          <li>이용 신청 시 필요 사항을 허위로 기재하여 신청한 경우</li>
          <li>사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청한 경우</li>
          <li>기타 회사가 정한 이용 신청 요건이 미비 된 경우</li>
        </ol>
        <p>
          ④ 전항에 의하여 이용신청의 승낙을 유보하거나 승낙하지 아니하는 경우, 회사는 이를 이용신청자에게 알려야 합니다. 다만, 회사의
          귀책사유 없이 이용신청자에게 통지할 수 없는 경우는 예외로 합니다.
        </p>
        <br />
        <h6>
          <b>제8조(서비스 이용)</b>
        </h6>
        <p>① 회사는 회원의 이용신청을 승낙한 때부터 서비스를 개시합니다. 단, 일부 서비스의 경우에는 지정된 일자부터 서비스를 개시합니다.</p>
        <p>② 회사는 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는 경우에는 사이트에 공지하거나 이용자에게 이를 통지합니다.</p>
        <p>
          ③ 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 회사의의 업무상 또는 기술상의 이유로 서비스가 일시 중지될 수 있고,
          또한 정기점검 등 운영상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수 있습니다. 이러한 경우 회사는 사전 또는 사후에 이를
          공지합니다.
        </p>
        <p>④ 회원에 가입한 후라도 일부 서비스 이용 시 서비스 제공자의 요구에 따라 특정 회원에게만 서비스를 제공 할 수도 있습니다.</p>
        <p>
          ⑤ 회사는 서비스를 일정범위로 분할하여 각 범위별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전에 공지합니다.
        </p>
        <br />
        <h6>
          <b>제9조 (서비스의 변경, 중지 및 정보의 저장과 사용)</b>
        </h6>
        <p>
          ① 본 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 국가의 비상사태, 정전, 천재지변, 회사의 관리범위 외의
          서비스 설비 장애 및 기타 불가항력에 의하여 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실에 대해
          회사는 아무런 책임을 지지 않습니다.
        </p>
        <p>
          ② 회사가 정상적인 서비스 제공의 어려움으로 인하여 일시적으로 서비스를 중지하여야 할 경우에는 서비스 중지 1주일 전에 공지 후
          서비스를 중지할 수 있으며, 이 기간 동안 이용자가 공지내용을 인지하지 못한 경우 회사는 책임을 부담하지 않습니다. 상당한 이유가 있을
          경우 위 사전 고지기간은 감축되거나 생략될 수 있습니다. 또한 위 서비스 중지에 의하여 본 서비스에 보관되거나 전송된 메시지 및 기타
          통신 메시지 등의 내용이 보관 되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 대하여도
          회사는 책임을 부담하지 않습니다.
        </p>
        <p>③ 회사의 사정으로 서비스를 영구적으로 중단하여야 할 경우 제2항을 준용합니다. 다만, 이 경우 사전 고지기간은 1개월로 합니다.</p>
        <p>
          ④ 회사가 사전 고지 후 서비스를 일시적으로 수정, 변경 및 중단할 수 있으며, 이에 대하여 회원 또는 제3자에게 어떠한 책임도 부담하지
          않습니다.
        </p>
        <p>
          ⑤ 회사는 회원이 이 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 중지할 수 있다 이 경우 회사는 회원의 접속을 금지할
          수 있으며, 회원이 게시한 내용의 전부 또는 일부를 임의로 삭제할 수 있습니다.
        </p>
        <br />
        <h6>
          <b>제10조 (정보의 제공)</b>
        </h6>
        <p>
          회사는 다양한 정보를 이메일, SMS 등의 방법으로 회원에게 제공할 수 있으며, 만약 원치 않는 정보를 수신한 경우 회원은 이를 수신거부
          할 수 있습니다.
        </p>
        <br />
        <h6>
          <b>제11조 (게시물 또는 내용물의 삭제)</b>
        </h6>
        <p>
          ① 회사는 이용자가 게시하거나 등록하는 서비스 내의 모든 내용물이 다음 각 호의 경우에 해당된다고 판단되는 경우 사전 통지 없이 삭제할
          수 있으며, 이에 대해 회사는 어떠한 책임도 지지 않습니다.
        </p>
        <ol>
          <li>회사, 다른 이용자 또는 제3자를 비방하거나 명예를 손상시키는 내용인 경우</li>
          <li>공공질서 및 미풍양속에 위반되는 내용인 경우</li>
          <li>범죄적 행위에 결부된다고 인정되는 내용일 경우</li>
          <li>제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
          <li>서비스 성격에 부합하지 않는 정보의 경우</li>
          <li>기타 관계 법령 및 회사에서 정한 규정 등에 위배되는 경우</li>
        </ol>
        <p>
          ② 회사는 서비스에 게시된 내용을 사전 통지된 지 3일 이후 편집, 이동, 삭제할 수 있는 권리를 갖으며, 게시된 내용이 이 약관에
          위배되거나 상용 또는 비합법적, 불건전한 내용일 경우 사전통보 없이 삭제할 수 있습니다.
        </p>
        <p>
          ③ 회사는 게시된 내용이 일정기간 이상 경과되어, 게시물로서의 효력을 상실하여 그 존치 목적이 불분명한 경우 공지사항 발표 후
          1주일간의 통지기간을 거쳐 해당 게시물을 삭제할 수 있습니다.
        </p>
        <br />
        <h6>
          <b>제12조 (게시물의 저작권)</b>
        </h6>
        <p>
          ① 이용자가 서비스 내에 게시한 게시물의 저작권은 이용자에게 있으며, 회사는 다른 서비스에서의 게재 등으로 이를 활용할 수 있습니다.
        </p>
        <p>
          ② 이용자의 게시물이 타인의 저작권, 프로그램 저작권 등을 침해함으로써 발생하는 민, 형사상의 책임은 전적으로 이용자가 부담하여야
          합니다.
        </p>
        <p>③ 이용자는 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용 할 수 없습니다.</p>
        <br />
        <h6>
          <b>제13조 (회사의 소유권)</b>
        </h6>
        <p>
          ① 회사가 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스명칭, 정보 및 상표 등과 관련된 지적재산권 및
          기타권리는 회사가 소유합니다.
        </p>
        <p>
          ② 이용자는 회사가 명시적으로 승인한 경우를 제외하고는 제1항 소정의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포,
          제작, 양도, 재라이센스, 담보권 설정행위, 상업적 이용행위를 할 수 없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수
          없습니다.
        </p>
        <br />
        <h6>
          <b>제14조 (개인정보의 보호)</b>
        </h6>
        <p>
          ① 회사는 이용신청 시 회원이 제공하는 정보, 이벤트 참가를 위하여 회원이 제공하는 정보, 기타 서비스 이용 과정에서 수집되는 정보 등을
          통하여 회원에 관한 정보를 수집하며, 회원의 개인정보는 본 이용계약의 이행과 본 이용계약상의 서비스 제공을 목적으로 사용됩니다.
        </p>
        <p>
          ② 회사는 서비스 제공과 관련하여 취득한 회원의 신상정보를 본인의 승낙 없이 제3자에게 누설 또는 배포할 수 없으며 상업적 목적으로
          사용할 수 없습니다. 다만, 다음의 각 호에 해당하는 경우에는 그러하지 아니합니다.
        </p>
        <ol>
          <li>정보통신서비스의 제공에 따른 요금 정산을 위하여 필요한 경우</li>
          <li>통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우</li>
          <li>관계 법령에 의하여 수사상 목적으로 정해진 절차와 방법에 따라 관계기관의 요구가 있는 경우</li>
          <li>다른 법률에 특별한 규정이 있는 경우</li>
          <li>정보통신윤리위원회가 관계법령에 의하여 요청하는 경우</li>
        </ol>
        <p>
          ③ 제2항의 범위 내에서 회사는 업무와 관련하여 회원 전체 또는 일부의 개인정보에 관한 집합적인 통계 자료를 작성하여 이를 사용할 수
          있고, 서비스를 통하여 회원의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나 수신에 대하여 경고하도록
          사용하는 컴퓨터의 브라우저의 설정을 변경할 수 있습니다.
        </p>
        <p>
          ④ 회원이 계정생성 시 제공한 정보는 계정 생성 시점부터 해지 완료 후 30일까지 보관할 수 있습니다. 법령의 규정에 의하여 보존할
          필요성이 있는 경우에는 예외로 합니다.
        </p>
        <p>
          ⑤ 정보통신망 이용촉진 및 정보보호 등에 관한 법률에 따라 1년 이상 서비스를 이용하지 않아 휴면계정으로 전환 된 경우 회원 정보를 별도
          분리 보관하여 관리하며, 휴면 계정으로 전환된 후에도 2년 동안 서비스 미이용시 회원 계정 및 개인정보는 파기됩니다. 개인정보 파기
          시에는 보유중인 모든 정보 등이 삭제 처리 됩니다. 단, 사업장에 퇴사자 명단으로 회원의 정보가 보존되어야 하기 때문에 회원을 구분하고
          퇴사자 정
        </p>
        <p>⑥ 휴면계정으로 전환된 회원의 경우 휴면 기간 동안 로그인 및 서비스 이용 등 회사에서 제공하는 모든 서비스를 받을 수 없습니다.</p>
        <p>
          ⑦ 휴면 계정에서 활동 계정으로 변경은 별도의 본인 인증 절차가 필요 없으며, 로그인을 시도한 시점에 활동 계정으로 변경됩니다. 변경 시
          지속적인 서비스를 받으실 수 있습니다.
        </p>
        <br />
        <h6>
          <b>제15조 (계약사항의 변경)</b>
        </h6>
        <p>① 회원은 개인정보관리를 통해 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다.</p>
        <p>
          ② 회원은 이용신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 해야 하며 회원정보를 변경하지 아니하여 발생되는 문제의 책임은
          회원에게 있습니다.
        </p>
        <p>
          ③ 회원이 원하는 경우 이용 동의를 철회할 수 있으며, 이용 동의를 철회한 경우 서비스 이용이 제한됩니다. 이용 동의의 철회는 해지
          신청을 하는 것으로 이루어집니다.
        </p>
        <h6>
          <b>제16조 (회사의 의무)</b>
        </h6>
        <p>
          ① 회사는 이 약관에서 정한 바에 따라 계속적이고 안정적인 서비스의 제공을 위하여 지속적으로 노력하며, 설비에 장애가 생기거나 멸실 된
          때에는 지체 없이 이를 수리 복구하여야 합니다. 다만, 천재지변, 비상사태, 또는 그 밖에 부득이한 경우에는 그 서비스를 일시 중단하거나
          중지할 수 있습니다.
        </p>
        <p>
          ② 회사는 회원으로부터 소정의 절차에 의해 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 적절한 절차를 거처 처리하여야 합니다.
          처리 시 일정 기간이 소요될 경우 회원에게 그 사유와 처리 일정을 알려주어야 합니다.
        </p>
        <p>③ 회사는 회원의 개인정보 및 사생활 보호와 관련하여 제8조에 제시된 내용을 지키려고 노력합니다.</p>
        <p>
          ④ 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용고객과의 계약 관련 절차 및 내용 등에 있어 이용고객에게 편의를 제공하도록
          노력합니다.
        </p>
        <p>
          ⑤ 회사의 서비스를 이용하는 회원에게 손해가 발생한 경우, 회사는 그 손해가 회사의 고의 또는 중과실로 인해 발생한 경우에만
          손해배상책임을 부담하며, 그 범위는 통상 손해에 한합니다.
        </p>
        <p>
          ⑥ 회사는 “정보통신망 이용촉진 및 정보보호에 관한 법률”, “통신비밀보호법”, “전기통신사업법” 등 서비스의 운영, 유지와 관련 있는
          법규를 준수합니다.
        </p>
        <br />
        <h6>
          <b>제17조 (회원의 의무)</b>
        </h6>
        <p>
          ① 회원은 이 약관에서 규정하는 사항과 이용안내 또는 공지사항 등을 통하여 회사가 공지하는 사항을 준수하여야 하며, 기타 회사의 업무에
          방해되는 행위를 하여서는 안 됩니다.
        </p>
        <p>
          ② 회원은 ID와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다. 회원에게 부여된 ID와 비밀번호의 관리 소홀, 부정사용에 의하여
          발생하는 모든 결과에 대한 책임은 회원에게 있습니다.
        </p>
        <p>
          ③ 회원은 자신의 ID나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는 즉시 회사에 신고하여야 하며, 신고를 하지 않아
          발생하는 모든 결과에 대한 책임은 회원에게 있습니다.
        </p>
        <p>
          ④ 회원은 회사의 사전승낙 없이는 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과와 회원이 약관에 위반한 영업활동을
          하여 발생한 결과에 대하여 회사는 책임을 지지 않습니다. 회원은 이와 같은 영업활동으로 회사가 손해를 입은 경우 회사에 대하여
          손해배상의무를 집니다.
        </p>
        <p>
          ⑤ 회원은 회사의 명시적인 동의가 없는 한 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로
          제공할 수 없습니다.
        </p>
        <p>⑥ 회원은 서비스 이용과 관련하여 다음 각 호에 해당되는 행위를 하여서는 안 됩니다.</p>
        <ol>
          <li>다른 회원의 ID와 비밀번호, 휴대폰번호 등을 도용하는 행위</li>
          <li>
            본 서비스를 통하여 얻은 정보를 회사의 사전승낙 없이 회원의 이용 이외 목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나
            제3자에게 제공하는 행위
          </li>
          <li>
            본인의 특허, 상표, 영업비밀, 저작권 기타 지적재산권을 침해하는 내용을 게시, 전자메일 또는 기타의 방법으로 타인에게 유포하는 행위
          </li>
          <li>
            공공질서 및 미풍양속에 위반되는 저속, 음란한 내용의 정보, 문장, 도형 등을 전송, 게시, 전자메일 또는 기타의 방법으로 타인에게
            유포하는 행위
          </li>
          <li>
            모욕적이거나 위협적이어서 타인의 사생활을 침해할 수 있는 내용을 전송, 게시, 전자메일 또는 기타의 방법으로 타인에게 유포하는 행위
          </li>
          <li>범죄와 결부된다고 객관적으로 판단되는 행위</li>
          <li>회사의 승인을 받지 않고 다른 사용자의 개인정보를 수집 또는 저장하는 행위</li>
          <li>기타 관계법령에 위배되는 행위</li>
        </ol>
        <br />
        <h6>
          <b>제18조 (정보의 제공)</b>
        </h6>
        <p>
          ① 회사는 회원이 서비스 이용 중 필요가 있다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 회원에게 제공할 수
          있습니다.
        </p>
        <p>
          ② 회사는 보다 나은 서비스 혜택 제공을 위해 다양한 전달 방법(전화, 메시지, 안내문, 메일 등)을 통해 서비스 관련 정보를 제공할 수
          있습니다. 단, 회사는 회원이 서비스 혜택 정보 제공을 원치 않는다는 의사를 밝히는 경우 정보 제공 대상에서 해당 회원을 제외하여야
          하며, 대상에서 제외되어 서비스 정보를 제공 받지 못해 불이익이 발생하더라도 이에 대해서는 회사가 책임지지 않습니다.
        </p>
        <p>③ 회사는 불특정 다수 회원에 대한 통지의 경우 서비스 게시판 등에 게시함으로써 개별 통지를 대신할 수 있습니다.</p>
        <br />
        <h6>
          <b>제19조 (자격 상실 및 회원 서비스 혜택 제한)</b>
        </h6>
        <p>
          회원이 다음 각 호에 해당하는 경우 회원 자격이 상실되거나 서비스 혜택이 제한될 수 있습니다. 이 경우 회사는 그 사유를 회원에게
          등록된 전자 우편 또는 모바일 메시지를 통해
        </p>
        <ol>
          <li>가입신청서의 기재사항을 허위로 작성한 경우</li>
          <li>영리의 목적으로 다른 사람에게 대가를 받고 양도하는 경우</li>
          <li>타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우</li>
          <li>서비스 운영을 고의로 방해하는 행위를 하는 경우 (통보 당일 또는 3일의 사전 유예기간 부여)</li>
          <li>
            “휴면회원”에 대해 회원자격 상실에 대한 안내문(전자우편 또는 서면)을 통지하고, 안내문에서 정한 기한 내에 답변이 없을 경우
            회원자격을 상실시킬 수 있습니다. 이 경우, 회원의 개인정보 및 서비스 이용정보는 법률상 보관의무가 있는 항목을 제외하고 30일간
            보관 후 파기, 삭제합니다.
          </li>
        </ol>
        <br />
        <h6>
          <b>제20조 (계약 해지 및 이용 제한)</b>
        </h6>
        <p>① 회원이 이용 계약을 해지하고자 하는 경우에는 회원 본인이 서비스에서 회원탈퇴를 진행해야 합니다.</p>
        <p>
          ② 회사는 본 약관에서 정의한 기간 이후에는 탈퇴한 회원에 대한 개인정보는 사업장에 퇴사자 명단으로 회원의 정보가 보존되어야 하기
          때문에 회원을 구분하고 퇴사자 정보에 필요한 정보를 제외하고는 개인정보를 보관하지 않습니다.
        </p>
        <p>
          ③ 회사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우 사전통지 없이 이용계약을 해지하거나 또는 기간을 정하여 서비스 이용을
          중지할 수 있습니다.
        </p>
        <ol>
          <li>타인의 개인정보, ID 및 비밀번호를 도용한 경우</li>
          <li>가입한 이름이 실명이 아닌 경우</li>
          <li>타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우</li>
          <li>회사, 다른 회원 또는 제3자의 지적재산권을 침해하는 경우</li>
          <li>공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우</li>
          <li>회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행하는 경우</li>
          <li>서비스 운영을 고의로 방해한 경우</li>
          <li>서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송하는 경우</li>
          <li>정보통신설비의 오작동이나 정보의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우</li>
          <li>정보통신윤리위원회 등 외부기관의 시정요구가 있거나 불법선거운동과 관련하여 선거관리위원회의 유권해석을 받은 경우</li>
          <li>회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 경우</li>
          <li>회원이 게시판에 음란물을 게재하거나 음란 사이트를 링크하는 경우</li>
          <li>본 약관을 포함하여 기타 회사가 정한 이용 조건에 위반하는 경우</li>
        </ol>
        <br />
        <h6>
          <b>제21조 (손해배상)</b>
        </h6>
        <p>
          회사는 이용 요금이 무료인 서비스 이용과 관련하여 회원에게 발생한 어떠한 손해에 관하여도 책임을 지지 않습니다. 유료 서비스의 경우는
          서비스 별 이용약관에 따릅니다.
        </p>
        <br />
        <h6>
          <b>제22조 (면책조항)</b>
        </h6>
        <p>① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</p>
        <p>② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.</p>
        <p>
          ③ 회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지
          않습니다.
        </p>
        <p>④ 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.</p>
        <p>⑤ 회사는 서비스 이용과 관련하여 가입자에게 발생한 손해 가운데 가입자의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.</p>
        <br />
        <h6>
          <b>제23조(대리 행위 및 보증의 부인)</b>
        </h6>
        <p>
          ① 서비스를 회원에게 제공함에 있어 회사는 효율적인 서비스를 위한 시스템 운영 및 관리 책임만을 부담하고 회사가 제공하는 시스템을
          통하여 이루어지는 서비스 이용자 간의 대회운영, 구단운영, 선수관리, 기록관리에 대한 적법성 및 타인의 권리에 대한 비침해성 등 일체에
          대하여 보증하지 아니하며, 이와 관련한 일체의 위험과 책임은 해당 서비스를 이용하는 회원이 전적으로 부담합니다.
        </p>
        <p>
          ② 회사는 서비스를 기반으로 하는 경기기록의 관리에 관련하여 사용자를 대리하지 아니하고, 해당 업무를 수행하는 사용자가 서비스의
          사용에 대한 직접적인 책임을 부담합니다.
        </p>
        <br />
        <h6>
          <b>제24조(약관변경)</b>
        </h6>
        <p>회사는 이 약관을 변경할 경우 그 내용을 홈페이지 또는 공지사항을 통해 게시합니다.</p>
        <br />
        <h6>
          <p>제25조(관할 법원)</p>
        </h6>
        <p>서비스 이용과 관련하여 회사와 회원 간에 분쟁이 발생할 경우, 회사와 회원은 분쟁해결을 위해 성실히 협의합니다.</p>
        <p>서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 전속 관할법원으로 합니다.</p>
        <br />
        <h6>
          <b>제26조(준거법)</b>
        </h6>
        <p>이 계약의 성립, 효력, 해석 및 이행과 관련하여서는 대한민국법을 적용합니다.</p>
        <br />
        <h6>
          <b>부칙</b>
        </h6>
        <p>(시행일) 이 약관은 2023년 3월 1일부터 시행합니다.</p>
      </div>
    </Modal>
  );
};
