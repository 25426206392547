import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { Button, Col, Modal } from 'antd';
// import Table from 'app/modules/table';
import { Table } from 'antd';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from '../league-recorder/league-recorder.reducer';
import axios from 'axios';
import { LeagueRecorderFilter } from '../league-recorder/league-recorder.type';
import { useFilter } from 'app/shared/hooks/useFilter';
import Filter from 'app/modules/filter';
import { FilterParams } from 'app/shared/model/filter.model';
import { toast } from 'react-toastify';

export const LeagueRecorderCreateDialog = (props: any) => {
  const { modalRecorder, setModalRecorder, createRecorder, setCreateRecorder, viewDetail } = props;
  const [loadModal, setLoadModal] = useState(false);
  const dispatch = useAppDispatch();
  const [dataPositions, setDataPositions] = useState([]);
  const [freePlayer, setfreePlayer] = useState([]);
  const [data, setData] = useState([{}]);
  const loading = useAppSelector(state => state.leagueRecorder.loading);
  const [formCreate, setFormCreate] = useState({
    position: '',
    player: '',
  });
  const param = useParams<{ id: string; params: string }>();
  const handleClose = () => {
    setModalRecorder(false);
  };

  const defaultFilter: FilterParams = {
    page: 0,
    size: 1000,
    sort: 'id,asc',
    type: 'fullName',
  };
  const handleFetchData = React.useCallback((params: LeagueRecorderFilter) => {
    dispatch(getEntities({ ...params, leagueId: +param.id }));
  }, []);

  const [filter2, setFilter2] = React.useState(defaultFilter);

  useEffect(() => {
    handleFetchData(filter2);
  }, [filter2]);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });
  const options = [
    {
      value: 'fullName',
      label: translate('leagueApp.leagueRecorder.recorderId'),
    },
    {
      value: 'unitStandardName',
      label: translate('leagueApp.leagueRecorder.leagueId'),
    },
  ];
  const GetLeagueRecorderList = useAppSelector(state => state.leagueRecorder?.entities);
  const handleCreateClub = (name: any) => {
    setModalRecorder(false);
  };

  const rowSelection = {
    onChange(selectedPlayers: React.Key[], selectedRows) {
      setCreateRecorder(selectedRows);
    },
    defaultSelectedRowKeys: createRecorder?.map(i => i.id),
  };

  const columns = [
    {
      title: <Translate contentKey="leagueApp.leagueRecorder.recorderId" />,
      dataIndex: 'fullName',
      key: 'fullName',
      align: 'start' as any,
    },
    {
      title: <Translate contentKey="userManagement.email" />,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: <Translate contentKey="leagueApp.leagueRecorder.multiSelect.unitName" />,
      dataIndex: 'unitStandardName',
      key: 'unitStandardName',
      align: 'left' as any,
    },
  ];

  return (
    <Modal
      visible={modalRecorder}
      onCancel={handleClose}
      footer={null}
      width={900}
      wrapClassName="management-wrapper-modal"
      title={<Translate contentKey="leagueApp.match.leagueMatch.chooseRecorder.title" />}
    >
      <Translate contentKey="leagueApp.match.leagueMatch.chooseRecorder.question" />
      <div className="table-responsive">
        <Table
          bordered
          pagination={false}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={GetLeagueRecorderList?.content?.map((item, index) => ({ ...item, key: item.id }))}
          // totalElements={GetLeagueRecorderList?.totalElements || 0}
          key="id"
          loading={loading}
          // filter={filter2}
          scroll={{
            x: 'calc(400px + 50%)',
            y: 240,
          }}
          // onChange={params => setFilter2({ ...filter2, ...params })}
        />
      </div>
      <Col>
        <Filter filter={filter2} onFilter={filterParams => setFilter2(filterParams)} options={options} />
      </Col>
      <Col span={24} style={{ marginTop: 24 }}>
        <div className="management-modal-footer">
          <Button color="secondary" onClick={handleClose}>
            <Translate contentKey="entity.action.cancel"></Translate>
          </Button>
          <Button id="jhi-confirm-delete-clubUniform" type="primary" style={{ marginLeft: 12 }} onClick={handleCreateClub}>
            <Translate contentKey="entity.action.apply"></Translate>
          </Button>
        </div>
      </Col>
    </Modal>
  );
};

export default LeagueRecorderCreateDialog;
