import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { Button, Col, ConfigProvider, Empty, Modal } from 'antd';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import Transfer, { TransferItem } from 'antd/es/transfer';
// import Table from 'app/modules/table';
import { Table } from 'antd';
import { toast } from 'react-toastify';
import { useFilter } from 'app/shared/hooks/useFilter';
import Filter from 'app/modules/filter';
import { FilterParams } from 'app/shared/model/filter.model';

export const SearchModal = (props: any) => {
  const { open, onClose, onFetchData, list, columns, title, subTitle, options, multiple, onAccept, selected } = props;
  const defaultFilter: FilterParams = {
    page: 0,
    size: 1000,
    sort: 'id,asc',
    type: 'name',
  };

  const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

  useEffect(() => {
    onFetchData();
  }, []);

  const handleAccept = () => {
    onAccept(selectedItems);
  };

  const rowSelection = {
    onChange(selectedKeys: React.Key[], selectedRows) {
      setSelectedItems(selectedRows);
    },
    type: (multiple ? 'checkbox' : 'radio') as any,
    defaultSelectedRowKeys: selected,
  };

  return (
    <Modal visible={open} onCancel={onClose} footer={null} width={900} wrapClassName="management-wrapper-modal" title={title}>
      {subTitle}

      <div className="table-responsive">
        <ConfigProvider renderEmpty={() => <Empty description={translate('common.noDataTable')} />}>
          <Table
            bordered
            key="id"
            pagination={false}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={list.map(item => ({ ...item, key: item.id }))}
            scroll={{
              x: 'calc(400px + 50%)',
              y: 240,
            }}
          />
        </ConfigProvider>
      </div>
      <Col style={{ marginBottom: 24 }}>
        <Filter filter={defaultFilter} onFilter={filterParams => onFetchData(filterParams)} options={options} />
      </Col>
      <Col span={24}>
        <div className="management-modal-footer">
          <Button color="secondary" onClick={onClose}>
            <Translate contentKey="entity.action.cancel"></Translate>
          </Button>
          <Button
            disabled={!selectedItems.length}
            id="jhi-confirm-delete-clubUniform"
            type="primary"
            onClick={handleAccept}
            style={{ marginLeft: 16 }}
          >
            <Translate contentKey="entity.action.apply"></Translate>
          </Button>
        </div>
      </Col>
    </Modal>
  );
};

export default SearchModal;
