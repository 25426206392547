import React, { useState, useEffect } from 'react';
import { Translate, translate, getUrlParameter, ValidatedField, ValidatedForm } from 'react-jhipster';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Checkbox, Col, Row, Tooltip } from 'antd';
import { Form, Input, Button } from 'antd';
import { Redirect, useHistory } from 'react-router-dom';
import TextField from 'app/modules/lm-input';
import { handlePasswordResetFinish, reset } from '../password-reset.reducer';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LockLoginIcon } from 'app/shared/util/authIcon';
import ToastDialog from 'app/shared/util/toastDialog/ToastDialog';
import { ModalPrivacy } from 'app/entities/privacy-policy/privacy-modal';
import { ModalPolicy } from 'app/entities/privacy-policy/policy-model';

export const PasswordResetFinishPage = (props: RouteComponentProps<{ key: string }>) => {
  const [password, setPassword] = useState('');
  const [key] = useState(getUrlParameter('key', props.location.search));
  const [resetData, setResetData] = useState<any>({});

  const [confirmDialog, setConfirmDialog] = React.useState<boolean>(false);
  const [toastElement, setToastElement] = React.useState<React.ReactElement>(null);
  const [successElement, setSuccessElement] = React.useState<React.ReactElement>(null);
  const [openPolicy, setOpenPolicy] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const dispatch = useAppDispatch();

  // useEffect(
  //   () => () => {
  //     dispatch(reset());
  //   },
  //   []
  // );

  const successMessage = useAppSelector(state => state.passwordReset?.successMessage);
  const resetPasswordFailInit = useAppSelector(state => state.passwordReset?.resetPasswordFailInit);
  const history = useHistory();
  const handleBack = e => {
    history.push('/login');
  };

  useEffect(() => {
    if (successMessage) {
      setToastElement(translate(successMessage));
      setSuccessElement(translate('global.backToLogin'));
      setConfirmDialog(true);
    } else if (resetPasswordFailInit) {
      setToastElement(translate(resetPasswordFailInit));
      setSuccessElement(null);
      setConfirmDialog(true);
    }
  }, [successMessage, resetPasswordFailInit]);

  const changePassSuccess = () => {
    if (successMessage) {
      history.push('/login');
    }
  };
  const data = useAppSelector(state => state.passwordReset?.data);

  React.useEffect(() => {
    if (data) {
      setResetData(data);
    }
  }, [data]);

  const handleRegisters = ({ currentPassword, newPassword, idUser }) => {
    dispatch(handlePasswordResetFinish({ currentPassword: resetData?.codePublic, newPassword, idUser: resetData?.id }));
  };

  const handleValidSubmit = e => {
    if (!resetData) {
      setToastElement(<>Something went wrong</>);
      return;
    }
    handleRegisters(e);
  };
  // const updatePassword = event => setPassword(event.target.value);

  React.useEffect(() => {
    if (toastElement) setConfirmDialog(true);
    else setConfirmDialog(false);
  }, [toastElement]);

  // const getResetForm = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '120px 30px 30px 30px',
        minHeight: '100vh',
      }}
    >
      <Form
        className="login-form"
        layout="vertical"
        onFinish={value => handleValidSubmit(value)}
        // onFinishFailed={() => onFinishFail()}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
        requiredMark={'optional'}
      >
        <Row style={{ width: '100%' }} align="middle" justify="center">
          <Col span={24}>
            <h1 style={{ fontWeight: 'bold', fontSize: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Translate contentKey="reset.request.title"></Translate>
            </h1>
          </Col>
          <Col span={24}>
            <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="neutral-3">
              <Translate contentKey="reset.request.messages.info"></Translate>
            </p>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item
              name="currentPassword"
              label={translate('global.form.password.label')}
              rules={[
                { required: true, message: translate('global.messages.validate.newpassword.required') },
                { max: 16, message: translate('global.messages.validate.newpassword.maxlength') },
                { min: 8, message: translate('global.messages.validate.newpassword.minlength') },
                {
                  required: true,
                  // pattern: new RegExp(/^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-~`_+=:;"',.?/])/),
                  // pattern: new RegExp(/^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*~`:;"'/|+=,.<>(){}_-])/),
                  pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/),
                  message: translate('global.messages.validate.newpassword.specialCharacter'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (/^(?=.*[~`+=:;"',.?/|{}[\]<>])/.test(value) === true) {
                      return Promise.reject(new Error(translate('global.messages.validate.newpassword.allowCharacter')));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              style={{ marginBottom: 32 }}
              data-cy="currentPassword"
              hasFeedback
            >
              <Input.Password
                // disabled={disable}
                placeholder={translate('global.form.password.placeholder')}
                style={{ width: '100%' }}
                prefix={<LockLoginIcon />}
                // iconRender={visible => (visible ? <EyeShowLoginIcon /> : <EyeHideLoginIcon />)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="newPassword"
              label={translate('global.form.resetpassword.label')}
              dependencies={['currentPassword']}
              hasFeedback
              data-cy="newPassword"
              rules={[
                { required: true, message: translate('global.messages.validate.confirmpassword.required') },
                { max: 16, message: translate('global.messages.validate.newpassword.maxlength') },
                { min: 8, message: translate('global.messages.validate.newpassword.minlength') },
                // {
                //   required: true,
                //   // pattern: new RegExp(/^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-~`_+=:;"',.?/])/),
                //   // pattern: new RegExp(/^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*~`:;"'/|+=,.<>(){}_-])/),
                //   pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/),
                //   message: translate('global.messages.validate.newpassword.specialCharacter'),
                // },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('currentPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(translate('global.messages.error.dontmatch')));
                  },
                }),
              ]}
            >
              <Input.Password
                // disabled={disable}
                placeholder={translate('global.form.newpassword.placeholder')}
                style={{ width: '100%' }}
                prefix={<LockLoginIcon />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Button style={{ width: '100%' }} htmlType="submit" data-cy="submit" type="primary" className="auth-button">
            <Translate contentKey="reset.finish.form.button"></Translate>
          </Button>
        </Row>
      </Form>
      <ToastDialog
        open={confirmDialog}
        onOk={() => {
          setConfirmDialog(false);
          if (successElement) {
            history.push('/login');
            return;
          }
          history.push('/login');
        }}
        buttonText={translate('confirmDialog.confirmToast')}
        content={toastElement}
      />
      <div style={{ marginTop: '20px', flexGrow: 1, display: 'flex', alignItems: 'end' }}>
        <Translate contentKey="footer.copyright" />
      </div>
      <div>
        <span
          onClick={() => {
            setOpenPolicy(true);
          }}
          className="privacy"
          style={{ marginRight: 40 }}
        >
          {translate('footer.privacyLogin')}
        </span>
        <span onClick={() => setOpenPrivacy(true)} className="privacy">
          {translate('footer.termLogin')}
        </span>
      </div>
      {openPrivacy && <ModalPrivacy openPrivacy={openPrivacy} setOpenPrivacy={setOpenPrivacy} />}
      {openPolicy && <ModalPolicy openPolicy={openPolicy} setOpenPolicy={setOpenPolicy} />}
    </div>
  );
};

export default PasswordResetFinishPage;
