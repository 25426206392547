import './sidebar.scss';

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { offSidebar, toggleBar } from '../../reducers/application-profile';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Storage, translate, Translate, TranslatorContext } from 'react-jhipster';
import { Avatar, Menu, MenuProps } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { LogoutIcon, MainLogo, VFFLogo } from 'app/shared/util/sidebarIcon';
import { UserOutlined } from '@ant-design/icons';
import { LocaleMenu } from '../menus';
import { connect, disconnect } from 'app/config/websocket-middleware';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { logout, logoutSession } from 'app/shared/reducers/authentication';
import { MatchRecordSlice } from 'app/entities/match-record/match-record.reducer';
// import { resetCustomer, resetCustomerInfo, resetLoading } from 'app/modules/modal7/contact-info.reducer';

export interface SidebarProps {
  sidebar: boolean;
  handleToggleSidebar?: () => void;
}

const AUTH_TOKEN_KEY = 'jhi-authenticationToken';
const ROLES_KEY = 'roles';
const LOGIN_KEY = 'login';
const USER_ID = 'userId';
const LANGUAGE = 'locale';

const Sidebar = ({ routes, handleLocaleChange, currentLocale }) => {
  const dispatch = useAppDispatch();
  const [collapsed, setCollapsed] = React.useState(false);
  const account = useAppSelector(state => state.authentication.account);
  const found = account?.authorities.find(function (element) {
    return element === 'ROLE_ADMIN';
  });

  const foundRecorder = account?.authorities.find(function (element) {
    return element === 'ROLE_RECORDER';
  });

  const foundLeagueMaster = account?.authorities.find(function (element) {
    return element === 'ROLE_LEAGUE_MASTER';
  });

  const foundTeamManager = account?.authorities.find(function (element) {
    return element === 'ROLE_TEAM_MANAGER';
  });
  const items = React.useMemo(() => {
    const getItem = (navs: any[]) =>
      navs.reduce((result, item, index) => {
        if (item.roles.length === 0) {
          return result.concat([item]);
        }
        if (hasAnyAuthority(account.authorities, item.roles)) {
          if (item.children && item.children.length > 0) {
            return result.concat({ ...item, children: getItem(item.children) });
          }
          return result.concat([item]);
        }
        return result;
      }, []);
    return routes.reduce((result, item, index) => {
      if (item.roles.length === 0) {
        return result.concat([item]);
      }
      if (hasAnyAuthority(account.authorities, item.roles)) {
        if (item.children && item.children.length > 0) {
          return result.concat({ ...item, children: getItem(item.children) });
        }
        return result.concat([item]);
      }
      return result;
    }, []);
  }, [routes]);
  const history = useHistory();
  const location = useLocation();
  const [current, setCurrent] = useState('1');
  const { actions } = MatchRecordSlice;

  React.useEffect(() => {
    connect(wsStatus => dispatch(actions.updateWsStatus(wsStatus)));
  }, []);
  React.useEffect(() => {
    setCurrent(location.pathname);
  }, [location.pathname]);
  const handleLogout = () => {
    // dispatch(logout());
    if (Storage.local.get(AUTH_TOKEN_KEY)) {
      Storage.local.remove(AUTH_TOKEN_KEY);
    }
    if (Storage.session.get(AUTH_TOKEN_KEY)) {
      Storage.session.remove(AUTH_TOKEN_KEY);
    }
    if (Storage.session.get(ROLES_KEY)) {
      Storage.session.remove(ROLES_KEY);
    }
    if (Storage.session.get(LOGIN_KEY)) {
      Storage.session.remove(LOGIN_KEY);
    }
    if (Storage.session.get(USER_ID)) {
      Storage.session.remove(USER_ID);
    }

    window.location.reload();
    // history.push('/login');
  };

  const onClickMenu: MenuProps['onClick'] = e => {
    setCurrent(e.key);
    history.push(e.key);
  };

  return (
    <Sider trigger={null} collapsible collapsed={false} style={{ height: '100vh', width: '100px' }} width={104} className="custom-sidebar">
      <div
        onClick={() => {
          history.push('/main');
          setCurrent('1');
        }}
        className="logo-sidebar"
      >
        <VFFLogo fill={'#FDFDFE'} width={65} height={55} />
      </div>
      <Menu
        onClick={onClickMenu}
        selectedKeys={[current]}
        mode="vertical"
        className="menu-sidebar"
        items={items}
        expandIcon={() => <></>}
      />
      <div className="user-sidebar">
        <div className="user-sidebar-avatar">
          <Avatar size={40} icon={<UserOutlined />} src={account?.imageUrlBase64Avt} />
          <div className="user-sidebar-avatar-name">{account?.fullName || ''}</div>
          <Link to="/account-management" className="user-sidebar-avatar-manage">
            {found
              ? translate('sidebar.admin')
              : foundLeagueMaster
              ? translate('sidebar.leaguemanagement')
              : foundTeamManager
              ? translate('sidebar.teamManagement')
              : foundRecorder
              ? translate('sidebar.recorder')
              : ''}
          </Link>
          <LocaleMenu currentLocale={currentLocale} onClick={handleLocaleChange}></LocaleMenu>
        </div>
        <div
          className="user-sidebar-logout"
          onClick={() => {
            handleLogout();
          }}
        >
          <LogoutIcon />
        </div>
      </div>
    </Sider>
  );
};

export default Sidebar;
