import dayjs from 'dayjs';

export interface IMatchTime {
  id?: number;
  startTime1?: string | null;
  endTime1?: string | null;
  startTime2?: string | null;
  endTime2?: string | null;
  startSubTime1?: string | null;
  endSubTime1?: string | null;
  startSubTime2?: string | null;
  endSubTime2?: string | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  now?: string | null;
  localTime?: string;
}

export const defaultValue: Readonly<IMatchTime> = {};
