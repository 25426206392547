import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Avatar, Button, Card, Col, ConfigProvider, Empty, Form, Input, Radio, Row, Select, Tabs, TimePicker } from 'antd';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import { clearMsgUpdate, getEntity, updateEntity } from './league.reducer';
import { HomeOutlined } from '@ant-design/icons';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import NumberField from 'app/modules/lm-input-number';
import PlayerAcademic from '../player-academic/player-academic';
import Match from '../match/match';
import LeagueRecorder from '../league-recorder/league-recorder';
import LeagueMonitor from '../league-monitor/league-monitor';
import LeagueClub from '../league-club/league-club';
import LeagueMatch from '../league-match/league-match';
import { getBase64, getImage, upLoadImg, UploadType } from 'app/shared/reducers/reducer.utils';
import axios from 'axios';
import DatePicker from 'app/modules/date-picker';
import dayjs from 'dayjs';
import { handleFocusFieldFail, validateSizeImage } from 'app/shared/util/entity-utils';
import { toast } from 'react-toastify';
import { PlusIcon, SelectArrowDown } from 'app/shared/util/appIcon';
import TextField from 'app/modules/lm-input';
import { handleValidateMessageLanguageChanged } from 'app/shared/util/validate';
import { BsCaretLeft } from 'react-icons/bs';
import { MATCH_TIME, NUMBER_PLAYERS, OVERTIME } from 'app/config/constants';
import locale from 'antd/es/date-picker/locale/de_DE';

export const LeagueUpdate = ({ match }) => {
  const dispatch = useAppDispatch();
  const leagueEntity = useAppSelector(state => state.league.entity);
  const contentType = 'image/png';
  const loading = useAppSelector(state => state.league.loading);
  const totalItems = useAppSelector(state => state.match?.totalItems);
  const links = useAppSelector(state => state.club.links);
  const { push } = useHistory();
  const [file, setFile] = useState<File>(null);
  const [previewImg, setPreviewImg] = useState('');
  const [dataStandard, setDataStandard] = useState([]);
  const [dataUnitStandard, setDataUnitStandard] = useState([]);
  const updateSuccess = useAppSelector(state => state.league.updateSuccess);
  const history = useHistory();
  const localeWeb = useAppSelector(state => state.locale.currentLocale);

  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
  }, [localeWeb]);

  useEffect(() => {
    const section = document.querySelector('#main');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      toast.success(translate('leagueApp.league.updated.success'));
      dispatch(clearMsgUpdate(updateSuccess));
      dispatch(getEntity(match.params.id));
    }
  }, [updateSuccess]);
  const onChange = (path: string) => {
    push(path);
  };

  const options = [
    {
      label: translate('leagueApp.leagueStandard.league'),
      value: 1,
    },
    {
      label: translate('leagueApp.leagueStandard.cup'),
      value: 2,
    },
  ];

  useEffect(() => {
    if (leagueEntity && leagueEntity.id) {
      form.setFieldsValue({
        ...leagueEntity,
        startDate: leagueEntity.startDate ? dayjs(leagueEntity.startDate) : null,
        endDate: leagueEntity.endDate ? dayjs(leagueEntity.endDate) : null,
        expirationDate: leagueEntity.expirationDate ? dayjs(leagueEntity.expirationDate) : null,
        issueYear: leagueEntity.issueYear ? dayjs(leagueEntity.issueYear, 'YYYY') : null,
        leagueStandardName: leagueEntity?.leagueStandardDTO?.name,
        classify: leagueEntity?.leagueStandardDTO?.classify,
        managerName: leagueEntity?.leagueStandardDTO?.managerName,
        unitStandardName: leagueEntity?.leagueStandardDTO?.unitStandardName,
        chairman: leagueEntity?.leagueStandardDTO?.unitStandardName,
        timeOfRound: !leagueEntity?.timeOfRound ? 45 : leagueEntity?.timeOfRound,
        timeOfRoundExtra: !leagueEntity?.timeOfRoundExtra ? 1 : leagueEntity?.timeOfRoundExtra,
      });
      if (leagueEntity.avatar) {
        getImage(leagueEntity.leagueStandardDTO?.avatar, UploadType.AVATAR_LEAGUE_STANDARD).then(result => {
          setPreviewImg('data:image/png;base64,' + result.data);
        });
      } else {
        setPreviewImg(null);
        setFile(null);
      }
    }
  }, [leagueEntity]);

  const pathLink = {
    matches: 'match',
    leagueClub: 'league-club',
    monitor: 'monitor',
    recorder: 'recorder',
  };

  const { path, url } = useRouteMatch();
  const param = useParams<{ id: string; params: string }>();
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (!match.params.id) {
      return;
    }
    dispatch(getEntity(match.params.id));
  }, []);

  const container = [
    {
      label: <Translate contentKey="leagueApp.league.matchSchedule" />,
      path: match.path.replace(':id', param.id).replace(':params', pathLink.matches),
      param: pathLink.matches,
    },
    {
      label: <Translate contentKey="leagueApp.league.leagueTeam" />,
      path: match.path.replace(':id', param.id).replace(':params', pathLink.leagueClub),
      param: pathLink.leagueClub,
    },
    {
      label: <Translate contentKey="leagueApp.leagueMonitor.home.title" />,
      path: match.path.replace(':id', param.id).replace(':params', pathLink.monitor),
      param: pathLink.monitor,
    },
    {
      label: <Translate contentKey="leagueApp.league.recorder" />,
      path: match.path.replace(':id', param.id).replace(':params', pathLink.recorder),
      param: pathLink.recorder,
    },
  ];

  const handlePreviewAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileImg = e.target.files[0];
    if (validateSizeImage(fileImg)) {
      setFile(fileImg);
      getBase64(fileImg, result => {
        setPreviewImg(result);
      });
    }
  };

  function saveData(data: any, oldAvatar) {
    if (leagueEntity.id) {
      data = {
        ...leagueEntity,
      };
    }
    data.avatar = oldAvatar;
    data.name = form.getFieldValue('name');
    data.issueYear = dayjs(form.getFieldValue('issueYear')).format('YYYY');
    data.startDate = dayjs(form.getFieldValue('startDate')).format('YYYY-MM-DD');
    data.endDate = dayjs(form.getFieldValue('endDate')).format('YYYY-MM-DD');
    data.expirationDate = dayjs(form.getFieldValue('expirationDate'));
    data.owner = form.getFieldValue('owner');
    data.email = form.getFieldValue('email');
    data.numberOfPlayers = form.getFieldValue('numberOfPlayers');
    data.timeOfRound = form.getFieldValue('timeOfRound');
    data.timeOfRoundExtra = form.getFieldValue('timeOfRoundExtra');
    data.subPlayerMax = form.getFieldValue('subPlayerMax');
    if (data.id) {
      dispatch(updateEntity(data));
    }
  }

  /** save action **/
  const handleValidSubmit = e => {
    /** save image **/
    const data: any = {};
    let oldAvatar = leagueEntity.avatar;
    if (file) {
      /** neu co file thi upload **/
      const bodyImg = new FormData();
      bodyImg.append('file', file);
      bodyImg.append('uploadType', 'AVATAR_LEAGUE_STANDARD');
      upLoadImg(bodyImg).then(result => {
        oldAvatar = result.data;
        saveData(data, oldAvatar);
      });
    } else {
      saveData(data, oldAvatar);
    }
  };

  const handleBack = e => {
    history.goBack();
  };

  return (
    <div>
      <Card className="mb-3">
        <Form
          form={form}
          onFinishFailed={values => handleFocusFieldFail(values)}
          scrollToFirstError
          name="control-hooks"
          onFinish={handleValidSubmit}
          layout="vertical"
          requiredMark={'optional'}
        >
          <div className="border-bottom d-flex justify-content-between align-items-center" style={{ paddingBottom: 30, marginBottom: 32 }}>
            <div className="d-flex align-items-center auto-wrap">
              <Button className="rounded management-header-add-button" icon={<BsCaretLeft />} onClick={handleBack} type="primary">
                <Translate contentKey="entity.action.back" />
              </Button>
              <div className="management-layout-divider"></div>
              <p className="h4 mb-0">{leagueEntity?.name}</p>
            </div>

            <Button htmlType="submit" type="primary">
              <Translate contentKey="common.save" />
            </Button>
          </div>
          <Row gutter={[24, 12]}>
            <Col xl={12} lg={12} xs={24}>
              <Row gutter={[12, 12]}>
                <Col xl={12} lg={12} xs={24}>
                  <Form.Item
                    label={<h6>{translate('leagueApp.league.detail.name')}</h6>}
                    name="name"
                    rules={[{ required: true, message: translate('leagueApp.league.required.name') }]}
                  >
                    <TextField placeholder={translate('leagueApp.league.placeholder.name')}></TextField>
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} xs={24}>
                  <Form.Item
                    label={<h6>{translate('leagueApp.league.detail.email')}</h6>}
                    rules={[{ required: true, message: translate('leagueApp.league.required.email') }]}
                    name="email"
                  >
                    <TextField placeholder={translate('leagueApp.league.placeholder.email')}></TextField>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col xl={12} lg={12} xs={24}>
                  <Form.Item label={<h6>{translate('leagueApp.league.detail.host')}</h6>} name="chairman">
                    <TextField disabled placeholder={translate('leagueApp.league.placeholder.host')}></TextField>
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} xs={24}>
                  <Form.Item
                    label={<h6>{translate('leagueApp.league.detail.owner')}</h6>}
                    rules={[{ required: true, message: translate('leagueApp.league.required.owner') }]}
                    name="owner"
                  >
                    <TextField placeholder={translate('leagueApp.league.placeholder.owner')}></TextField>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col xl={12} lg={12} xs={24}>
                  <Form.Item
                    label={<h6>{translate('leagueApp.league.detail.issueYear')}</h6>}
                    rules={[{ required: true, message: translate('leagueApp.league.required.issueYear') }]}
                    name="issueYear"
                  >
                    <DatePicker
                      suffixIcon={null}
                      picker="year"
                      style={{ width: '100%' }}
                      placeholder={translate('leagueApp.league.placeholder.issueYear')}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} xs={24}>
                  <Form.Item
                    label={<h6>{translate('leagueApp.league.detail.startDate')}</h6>}
                    rules={[{ required: true, message: translate('leagueApp.league.required.startDate') }]}
                    name="startDate"
                  >
                    <DatePicker
                      locale={{
                        ...locale,
                        lang: {
                          ...locale.lang,
                          today: translate('entity.action.today'),
                        },
                      }}
                      style={{ width: '100%' }}
                      placeholder={translate('leagueApp.league.placeholder.matchDate')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col xl={12} lg={12} xs={24}>
                  <Form.Item
                    label={<h6>{translate('leagueApp.league.detail.endDate')}</h6>}
                    rules={[
                      { required: true, message: translate('leagueApp.league.required.endDate') },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('startDate') <= value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(translate('leagueApp.league.required.compileDate')));
                        },
                      }),
                    ]}
                    name="endDate"
                  >
                    <DatePicker
                      locale={{
                        ...locale,
                        lang: {
                          ...locale.lang,
                          today: translate('entity.action.today'),
                        },
                      }}
                      style={{ width: '100%' }}
                      placeholder={translate('leagueApp.league.placeholder.matchDate')}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} xs={24}>
                  <Form.Item
                    label={<h6>{translate('leagueApp.league.detail.expirationDate')}</h6>}
                    rules={[
                      { required: true, message: translate('leagueApp.league.required.expirationDate') },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('startDate') >= value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(translate('leagueApp.league.required.compileDate1')));
                        },
                      }),
                    ]}
                    name="expirationDate"
                  >
                    <DatePicker
                      locale={{
                        ...locale,
                        lang: {
                          ...locale.lang,
                          today: translate('entity.action.today'),
                        },
                      }}
                      style={{ width: '100%' }}
                      placeholder={translate('leagueApp.league.placeholder.matchDate')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col xl={12} lg={12} xs={24}>
                  <Form.Item name="numberOfPlayers" label={translate('leagueApp.league.detail.numberPlayer')}>
                    <Select
                      disabled={leagueEntity?.existMatch}
                      options={NUMBER_PLAYERS}
                      suffixIcon={<SelectArrowDown />}
                      allowClear
                      className="custom-prefix-select"
                      placeholder={translate('leagueApp.league.placeholder.numberPlayer')}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} xs={24}>
                  <Form.Item name="subPlayerMax" label={translate('leagueApp.league.numberReservePlayer')}>
                    <TextField disabled placeholder={translate('leagueApp.league.placeholder.numberReservePlayer')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col xl={12} lg={12} xs={24}>
                  <Form.Item name="timeOfRound" label={translate('leagueApp.league.detail.timeOneHalf')}>
                    <Select
                      disabled={leagueEntity?.existMatch}
                      options={MATCH_TIME}
                      suffixIcon={<SelectArrowDown />}
                      allowClear
                      className="custom-prefix-select"
                      placeholder={translate('leagueApp.league.placeholder.timeOneHalf')}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} xs={24}>
                  <Form.Item name="timeOfRoundExtra" label={translate('leagueApp.league.detail.timeExtraHalf')}>
                    <Select
                      disabled={leagueEntity?.existMatch}
                      suffixIcon={<SelectArrowDown />}
                      allowClear
                      className="custom-prefix-select"
                      placeholder={translate('leagueApp.league.placeholder.timeExtraHalf')}
                    >
                      <Select.Option value={1}>{translate('leagueApp.league.noOverTime')}</Select.Option>
                      {OVERTIME.map(item => {
                        return (
                          <Select.Option key={item.value} value={item.value}>
                            {item.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xl={6} lg={12} xs={24} className="lg-vertical-divider">
              <Form.Item label={<h6>{translate('leagueApp.league.detail.standardLeague')}</h6>} name="leagueStandardName">
                <TextField disabled placeholder={translate('leagueApp.league.placeholder.standardTourament')}></TextField>
              </Form.Item>
              <Form.Item label={<h6>{translate('leagueApp.league.leagueType')}</h6>} name="classify">
                <Radio.Group disabled options={options}></Radio.Group>
              </Form.Item>
              <Form.Item label={<h6>{translate('leagueApp.league.unitStandardId')}</h6>} name="unitStandardName">
                <TextField disabled placeholder={translate('leagueApp.league.placeholder.leagueHost')} />
              </Form.Item>
              <Form.Item label={<h6>{translate('leagueApp.league.manager')}</h6>} name="managerName">
                <TextField disabled placeholder={translate('leagueApp.league.placeholder.touramentManagement')} />
              </Form.Item>
            </Col>
            <Col xl={6} lg={12} xs={24}>
              <Form.Item name="avatar">
                <div style={{ width: '100%', objectFit: 'fill' }} className="d-flex justify-content-center">
                  <label htmlFor="uploadImage" className="upload-file" style={{ width: '100%', paddingTop: '100%' }}>
                    <img
                      src={leagueEntity?.leagueStandardDTO?.lsBase64Avt}
                      alt="logo"
                      style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: 0 }}
                    />
                  </label>
                  {/* <Avatar shape="square" size={200} src={leagueEntity?.leagueStandardDTO?.lsBase64Avt} /> */}
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Tabs onChange={onChange} type="card" activeKey={param.params}>
        {container.map((item, index) => (
          <Tabs.TabPane tab={item.label} key={item.param} />
        ))}
      </Tabs>
      <Card className="card-after-tabs">
        <Switch>
          <ErrorBoundaryRoute exact path={match.path.replace(':params', pathLink.matches)} component={LeagueMatch} />
          <ErrorBoundaryRoute exact path={match.path.replace(':params', pathLink.leagueClub)} component={LeagueClub} />
          <ErrorBoundaryRoute exact path={match.path.replace(':params', pathLink.monitor)} component={LeagueMonitor} />
          <ErrorBoundaryRoute exact path={match.path.replace(':params', pathLink.recorder)} component={LeagueRecorder} />
          <Redirect to={match.path.replace(':id', param.id).replace(':params', pathLink.matches)} />
        </Switch>
      </Card>
      {/* <LeagueAttend id={idClub.id} /> */}
    </div>
  );
};
