import { Button, Col, Modal, Radio, Row } from 'antd';
import { IPlayerInMatch } from 'app/shared/model/player.model';
import React from 'react';
import StadiumImage from 'app/assets/stadium.png';
import DraggableElement from './components/draggable-element';
import { translate } from 'react-jhipster';
import { actionRecord } from 'app/shared/util/constant';

interface Props {
  awayPlayers?: IPlayerInMatch[];
  ownerPlayers?: IPlayerInMatch[];
  ownerAvatars?: Record<string, string>;
  awayAvatars?: Record<string, string>;
  swap?: boolean;
  timeOfRound?: number;
  timeOfRoundExtra?: number;
}

const defaultLineup = { 1: [], 2: [], 3: [], 4: [] };

const LineUpEndMatch = ({ ownerPlayers, awayPlayers, ownerAvatars, awayAvatars, swap, timeOfRound, timeOfRoundExtra }: Props) => {
  const [elements, setElements] = React.useState<Record<number, IPlayerInMatch[]>>(defaultLineup);
  const [elementsS, setElementsS] = React.useState<Record<number, IPlayerInMatch[]>>(defaultLineup);
  const lists = swap ? [1, 2, 3, 4].reverse() : [1, 2, 3, 4];
  const listsS = swap ? [1, 2, 3, 4] : [1, 2, 3, 4].reverse();

  const getInitValue = (listPlayer: IPlayerInMatch[]): Record<number, IPlayerInMatch[]> => {
    return lists.reduce(
      (acc, listKey) => ({
        ...acc,
        [listKey]: listPlayer.filter(item => item.positionType === listKey).sort((a, b) => a.positionOrder - b.positionOrder),
      }),
      {}
    );
  };
  React.useEffect(() => {
    setElementsS(getInitValue(awayPlayers));
  }, [awayPlayers]);

  React.useEffect(() => {
    setElements(getInitValue(ownerPlayers));
  }, [ownerPlayers]);

  return (
    <div className="container-lineup" style={{ marginBottom: '32px' }}>
      <Row
        gutter={[12, 12]}
        align="middle"
        style={{
          background: `url(${StadiumImage}) no-repeat`,
          backgroundSize: '1200px auto',
          backgroundPosition: 'center',
          width: 1200,
          minHeight: '650px',
          margin: '0 auto',
        }}
      >
        <Col xs={12} style={{ order: 2 }}>
          <Row gutter={[6, 6]} justify="space-between">
            {lists.map(listKey => (
              <Col xs={6} key={listKey}>
                <DraggableElement
                  elements={elements[listKey]}
                  key={listKey.toString()}
                  prefix={listKey}
                  avatars={ownerAvatars}
                  timeOfRound={timeOfRound}
                  timeOfRoundExtra={timeOfRoundExtra}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col xs={12} style={{ order: swap ? 1 : 3 }}>
          <Row gutter={[6, 6]} justify="space-between">
            {listsS.map(listKey => (
              <Col xs={6} key={listKey}>
                <DraggableElement elements={elementsS[listKey]} key={listKey} prefix={listKey} avatars={awayAvatars} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default LineUpEndMatch;
