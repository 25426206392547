import React, { memo, useEffect, useMemo, useCallback } from 'react';
import { Avatar, Button, Card, Col, Form, Input, Row, Select, Tabs, Table, Tag, Checkbox } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Translate, translate } from 'react-jhipster';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { fetchListPlayerMom, postDataScoreAndMom, setIsEditValue, IDataPlayerMom } from './match-over.reducer';
import TableInput from './TableInput';
import { OWNER_RECORD, AWAY_RECORD } from './constant.match-over';
import { sortBy } from 'lodash';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import { APPROVAL_TYPE, AUTHORITIES } from 'app/config/constants';

const MatchOver = () => {
  const matchInfo = useAppSelector(state => state.match.entity);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);

  const matchBasicInfoEntity = useAppSelector(state => state.match.entity);

  const awayRecord = useAppSelector(state => state.MatchOver.listAwayData);
  const ownerRecord = useAppSelector(state => state.MatchOver.listOwnerData);

  const isAdmin = useMemo(() => {
    return account?.authorities.some(item => item === AUTHORITIES.ADMIN);
  }, [account]);

  const isRecorder = useMemo(() => {
    return account?.authorities.some(item => item === AUTHORITIES.RECORDER);
  }, [account]);

  const isLeagueMaster = useMemo(() => {
    return account?.authorities.some(item => item === AUTHORITIES.LEAGUE_MASTER);
  }, [account]);

  const permissionRecord = useMemo(() => {
    if (matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED) return false;
    if (isAdmin) return true;
    if (isLeagueMaster && !isAdmin && matchBasicInfoEntity.approval !== APPROVAL_TYPE.WAIT_ACCEPT) return false;
    if (isRecorder && !isAdmin && matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT) return false;
    return true;
  }, [matchBasicInfoEntity.approval, isAdmin, isLeagueMaster, isRecorder]);

  const onValueChange = useCallback(() => {
    dispatch(setIsEditValue(true));
  }, []);

  // In this Match, set only 1 player MTP
  const onMomChange = useCallback(
    (typeName, index) => {
      if (!permissionRecord) return;
      const clubRemainName = typeName === OWNER_RECORD ? AWAY_RECORD : OWNER_RECORD;

      const clubValue = form.getFieldValue([typeName]);
      const clubRemainValue = form.getFieldValue([clubRemainName]);
      const newClubValue = clubValue.map((item, indexOfNewArr) => {
        const newValue = item;
        if (index === indexOfNewArr) {
          newValue.mom = 1;
        } else {
          newValue.mom = 0;
        }
        return newValue;
      });
      const newClubRemainValue = clubRemainValue.map(item => ({ ...item, mom: 0 }));
      if (!permissionRecord) {
        form.setFieldsValue({ [typeName]: !newClubValue });
      }

      form.setFieldsValue({ [typeName]: newClubValue, [clubRemainName]: newClubRemainValue });
      onValueChange();
    },
    [permissionRecord]
  );
  const convertToNewBody = (listRecord: IDataPlayerMom[]) => {
    return listRecord.map(record => ({
      ...record,
      mom: record.mom ? 1 : 0,
      point: record.point || record.point === 0 ? `${record.point}` : null,
    }));
  };

  const onSubmitForm = data => {
    const newOwnerRecord = convertToNewBody(data?.ownerRecord);
    const newAwayRecord = convertToNewBody(data?.awayRecord);
    dispatch(postDataScoreAndMom({ newOwnerRecord, newAwayRecord }));
  };

  useEffect(() => {
    dispatch(fetchListPlayerMom({ matchId: matchInfo.id, awayClubId: matchInfo.awayClubId, ownerClubId: matchInfo.ownerClubId }));
  }, []);

  useEffect(() => {
    const arrOwnerSorted = sortBy(ownerRecord, 'type').reverse();
    const arrAwaySorted = sortBy(awayRecord, 'type').reverse();
    form.setFieldsValue({ ownerRecord: arrOwnerSorted, awayRecord: arrAwaySorted });
  }, [ownerRecord, awayRecord]);

  return (
    <Form
      form={form}
      onFinishFailed={values => handleFocusFieldFail(values)}
      scrollToFirstError
      name="control-hooks"
      onFinish={onSubmitForm}
    >
      <div className="d-flex justify-content-between align-items-center record-layout-header">
        <p className="heading-20-bold neutral-1 mb-0">
          <Translate contentKey="matchOver.scoreAndMom" />
        </p>
        {matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED ||
        (isLeagueMaster && !isAdmin && matchBasicInfoEntity.approval !== APPROVAL_TYPE.WAIT_ACCEPT) ||
        (isRecorder && !isAdmin && matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT) ? (
          ''
        ) : (
          <>
            <Button type="primary" onClick={form.submit}>
              {translate('freeCornerKick.save')}
            </Button>
          </>
        )}
      </div>
      <Row gutter={[24, 12]}>
        <Col span={12}>
          <p style={{ fontWeight: 'bold' }}>
            <Translate contentKey="matchOver.homeTeam">Home team</Translate>
            &nbsp; ({matchInfo.awayClubName})
          </p>
        </Col>
        <Col span={12}>
          <p style={{ fontWeight: 'bold' }}>
            <Translate contentKey="matchOver.guestTeam">Guest team</Translate>
            &nbsp; ({matchInfo.ownerClubName})
          </p>
        </Col>
        <Col span={12}>
          <Form.List name="ownerRecord">
            {record => (
              <TableInput
                canChange={permissionRecord}
                scroll={{ x: 600 }}
                onMomChange={onMomChange}
                onValueChange={onValueChange}
                form={form}
                record={record}
                typeName={OWNER_RECORD}
              />
            )}
          </Form.List>
        </Col>
        <Col span={12}>
          <Form.List name="awayRecord">
            {record => (
              <TableInput
                canChange={permissionRecord}
                scroll={{ x: 600 }}
                onMomChange={onMomChange}
                onValueChange={onValueChange}
                form={form}
                record={record}
                typeName={AWAY_RECORD}
              />
            )}
          </Form.List>
        </Col>
      </Row>
    </Form>
  );
};

export default memo(MatchOver);
