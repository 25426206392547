import React from 'react';
import { translate, Translate } from 'react-jhipster';

interface Props {
  type: number;
}

export const TypeContainer = ({ type }: Props) => {
  if (type === 0 || type === null || !type) {
    return <div></div>;
  }
  if (type === 1) {
    return (
      <div>
        <p>{translate('leagueApp.playerAcademic.render.tertiary')}</p>
      </div>
    );
  }
  if (type === 2) {
    return (
      <div>
        <p>{translate('leagueApp.playerAcademic.render.primary')}</p>
      </div>
    );
  }
  if (type === 3) {
    return (
      <div>
        <p>{translate('leagueApp.playerAcademic.render.primary')}</p>
      </div>
    );
  }
  if (type === 4) {
    return (
      <div>
        <p>{translate('leagueApp.playerAcademic.render.senior')}</p>
      </div>
    );
  }
};
