import React, { useState, useEffect } from 'react';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount, translate } from 'react-jhipster';
import { sendRecord } from 'app/config/websocket-middleware';

import { Card, Col, Form, Modal, Radio, Row, Space, Button } from 'antd';
import ListItem from 'app/modules/pick-player/components/list-item';
import { EditOutlined } from '@ant-design/icons';
import PickPosition from 'app/modules/pick-position';
import PickPlayer from 'app/modules/pick-player';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import MiniMapPosition from 'app/modules/mini-map-position';
import ScoreInfo from './ScoreInfo';
import { toast } from 'react-toastify';
import {
  fetchListShootDirectionKick,
  fetchDetailEventRecord,
} from 'app/entities/player-free-corner-kick-record/player-free-corner-kick.reducer';
import SuportScoreInfo from './SupportScoreInfo';
import { get } from 'lodash';
import { urlWs } from 'app/modules/event-list-record/constant.event-list';
import { actionRecord } from 'app/shared/util/constant';
import RecordPlayerInfo from 'app/modules/record-player-info';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';

interface Props {
  open: boolean;
  onCancel: () => void;
  onEditPosition: () => void;
  onEditPlayer: () => void;
  form?: any;
  id?: number;
  awayAvatars: any;
  ownerAvatars: any;
  processTime: any;
}

const SCORE_INFO = 'SCORE_INFO';
const SUPPORTSCORE_INFO = 'SUPPORTSCORE_INFO';

export const PlayerFreeCornerKickRecord = ({
  onCancel,
  open,
  onEditPlayer,
  onEditPosition,
  form,
  id,
  awayAvatars,
  ownerAvatars,
  processTime,
}: Props) => {
  const [pageChoose, setPageChoose] = React.useState(SCORE_INFO);
  const [listFooterModal, setListFooterModal] = React.useState([]);
  const dispatch = useAppDispatch();
  const [dataDefault, setDataDefault] = useState(null);

  React.useEffect(() => {
    if (pageChoose === SCORE_INFO) {
      setListFooterModal([
        <Button key="close" onClick={() => onCancel()}>
          <span>{translate('freeCornerKick.close')}</span>
        </Button>,
        <Button key="next" type="primary" onClick={() => form.submit()}>
          <span>{translate('freeCornerKick.next')}</span>
        </Button>,
      ]);
    }
    if (pageChoose === SUPPORTSCORE_INFO) {
      setListFooterModal([
        <Button key="close" onClick={() => onCancel()}>
          <span>{translate('freeCornerKick.close')}</span>
        </Button>,
        <Button key="back" onClick={() => setPageChoose(SCORE_INFO)}>
          <span>{translate('freeCornerKick.back')}</span>
        </Button>,
        <Button key="save" type="primary" onClick={() => form.submit()}>
          <span>{translate('freeCornerKick.save')}</span>
        </Button>,
      ]);
    }
  }, [pageChoose]);

  React.useEffect(() => {
    if (id) {
      dispatch(fetchDetailEventRecord(`api/player-goal-records/${id}`)).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          const data = get(res.payload, 'data');
          setDataDefault(data);
          form.setFieldsValue({ ...data });
        }
      });
    }
  }, []);

  const playerInForm = Form.useWatch('player', form);
  const onSubmitForm = data => {
    if (!data.detailLocation) {
      toast.error(translate('scoreRecord.message.supportForm'));
      return;
    }
    if (pageChoose === SCORE_INFO) {
      setPageChoose(SUPPORTSCORE_INFO);
      return;
    }
    if (!form.getFieldValue('detailLocationSupport')) {
      toast.error(translate('scoreRecord.message.supportForm'));
      return;
    }
    if (id) {
      sendRecord(urlWs(actionRecord.SCORE, 'edit', data.player?.matchPlayerFieldDTO.matchId, id), {
        ...dataDefault,
        ...data,
        id,
        playerId: data.player?.playerId,
        player: undefined,
        detailLocationSupport: form.getFieldValue('detailLocationSupport'),
        matchId: data.player?.matchPlayerFieldDTO.matchId,
      });
    } else {
      sendRecord(
        `/record/player-goal/create/${data.player?.matchPlayerFieldDTO.matchId}/${data.player?.matchPlayerFieldDTO.clubId}/${data.player?.matchPlayerFieldDTO.type}`,
        {
          ...data,
          playerId: data.player?.playerId,
          player: undefined,
          detailLocationSupport: form.getFieldValue('detailLocationSupport'),
          matchId: data.player?.matchPlayerFieldDTO.matchId,
          processDate: processTime?.process,
          roundType: processTime?.roundType,
        }
      );
    }
    form.resetFields();
    toast.success(translate('common.saveSuccess'));
    onCancel();
  };

  return (
    <Modal
      title={!id ? translate('freeCornerKick.registerScore') : translate('freeCornerKick.editScore')}
      className="register-record"
      visible={open}
      width={1400}
      onCancel={onCancel}
      onOk={() => form.submit()}
      footer={listFooterModal}
      zIndex={1}
    >
      <Form
        form={form}
        onFinish={onSubmitForm}
        onFinishFailed={values => {
          handleFocusFieldFail(values);
          toast.error(translate('scoreRecord.message.supportForm'));
        }}
        scrollToFirstError
        name="control-hooks"
        style={{ width: 1298, overflow: 'auto' }}
      >
        <Row gutter={32} justify="center" style={{ width: 1300 }} wrap={false}>
          <Col>
            <RecordPlayerInfo
              avatar={
                // playerInForm?.matchPlayerFieldDTO?.playerDTOS?.avatar
                get(awayAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  ? get(awayAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  : get(ownerAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  ? get(ownerAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  : undefined
              }
              prefix={playerInForm?.positionType}
              onEditPlayer={() => {
                onEditPlayer();
              }}
              onEditPosition={() => {
                onEditPosition();
              }}
              player={playerInForm}
              positionMap={form.getFieldValue('detailLocation')}
            />
          </Col>
          <Col>
            {<ScoreInfo style={pageChoose !== SCORE_INFO ? { display: 'none' } : ''} />}
            {<SuportScoreInfo pageChoose={pageChoose} style={pageChoose !== SUPPORTSCORE_INFO ? { display: 'none' } : ''} form={form} />}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PlayerFreeCornerKickRecord;
