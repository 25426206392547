import { Button, Col, Modal, Row } from 'antd';
import React from 'react';
import { Translate } from 'react-jhipster';

interface Iprops {
  children?: React.ReactNode | React.ReactNode[];
  open: boolean;
  title?: string;
  onConfirm?: () => void;
  onClose?: () => void;
  message?: React.ReactElement;
  confirmMessage?: React.ReactElement;
  customTextConfirm?: React.ReactElement;
  customTextReject?: React.ReactElement;
}
export const ConfirmationDialog: React.FC<Iprops> = ({
  customTextConfirm,
  customTextReject,
  message,
  confirmMessage,
  onClose,
  onConfirm,
  open,
  children,
  title,
  ...rest
}) => {
  const handleClose = () => {
    onClose();
  };

  const confirmDelete = () => {
    onConfirm();
  };

  return (
    <Modal visible={open} onCancel={handleClose} closeIcon={<></>} footer={null} wrapClassName="management-confirm-modal" width={400}>
      <Row align="middle" justify="center">
        <Col span={24}>{title && <h5>{title}</h5>}</Col>
        <Col span={24}>
          {message ? message : <Translate contentKey="leagueApp.stadium.delete.question">Are you sure you want to delete this ?</Translate>}
        </Col>
        <Col>{confirmMessage ? confirmMessage : ''}</Col>
      </Row>
      <Row align="middle" justify="center" style={{ marginTop: 40, width: '100%' }}>
        {onClose && (
          <Col span={12} style={{ paddingRight: 12 }}>
            <Button color="secondary" onClick={handleClose} style={{ width: '100%' }}>
              {customTextReject ? customTextReject : <Translate contentKey="entity.action.cancel">Cancel</Translate>}
            </Button>
          </Col>
        )}
        {onConfirm && (
          <Col span={12} style={{ paddingLeft: 12 }}>
            <Button
              id="jhi-confirm-delete-stadium"
              data-cy="entityConfirmDeleteButton"
              type="primary"
              onClick={confirmDelete}
              style={{ width: '100%' }}
            >
              {customTextConfirm ? customTextConfirm : <Translate contentKey="confirmDialog.defaultConfirm"></Translate>}
            </Button>
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default React.memo(ConfirmationDialog);
