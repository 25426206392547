import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useState, useEffect } from 'react';
import { translate, Translate } from 'react-jhipster';
import { Form, Select, Input, Row, Col, ConfigProvider, Empty } from 'antd';
import DatePicker from 'app/modules/date-picker';
import { Modal, Button } from 'antd';
import { createEntity, getEntity, updateEntity, clearMsgUpdate, getEntities, getOrganization, checkDuplicate } from './player.reducer';
import { getBase64, getImage, Pageable, upLoadImg, UploadType } from 'app/shared/reducers/reducer.utils';
import axios from 'axios';
import NumberField from 'app/modules/lm-input-number';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { handleFocusFieldFail, validateSizeImage } from 'app/shared/util/entity-utils';
import TextField from 'app/modules/lm-input';
import { handleValidateMessageLanguageChanged } from 'app/shared/util/validate';
import SearchModal from 'app/modules/seatch-modal';
import { first } from 'lodash';
import { IClub } from 'app/shared/model/club.model';
import dayjs from 'dayjs';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';
import { hasAnyAuthority, isRole } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import locale from 'antd/es/date-picker/locale/de_DE';
import Typography from 'antd/lib/typography/Typography';

import 'antd/dist/antd.css';
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { IPlayer } from 'app/shared/model/player.model';

const ModalCreatePlayer = (props: any) => {
  const { isOpen, setIsOpen, entityId, setEntityId, updateFilter } = props;
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File>(null);
  const [dataNation, setDataNation] = useState([]);
  const [dataPlayer, setDataPlayer] = useState([]);
  const [dataPositions, setDataPositions] = useState([]);
  const [previewImg, setPreviewImg] = useState('');
  const [disabled, setDisabled] = useState(false);
  const playerEntity = useAppSelector(state => state.player.entity);
  const createdSuccess = useAppSelector(state => state.player.createSuccess);
  const organizations = useAppSelector(state => state.player.organizations);

  const [form] = Form.useForm();
  const [searchClubModal, setSearchClubModal] = useState(false);
  const [clubs, setClubs] = React.useState<IClub[]>([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const { authorities, unitStandardId } = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(authorities, [AUTHORITIES.ADMIN]);
  const isLeagueMaster = isRole(authorities, [AUTHORITIES.LEAGUE_MASTER]);
  const getClubs = async (clubName?: string) => {
    try {
      const fetchClubs = await axios.get<any>(`api/assign-club-for-player?searchName=${clubName || ''}`);
      setClubs(fetchClubs.data);
    } catch (error) {
      setClubs([]);
    }
  };
  const handleCloseModal = () => {
    handleReset();
    setIsOpen(false);
  };
  const localeWeb = useAppSelector(state => state.locale.currentLocale);

  useEffect(() => {
    dispatch(getOrganization());
  }, []);

  useEffect(() => {
    if (createdSuccess) {
      handleReset();
    }
  }, [createdSuccess]);

  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
    handleNationally({ lang: localeWeb });
    handlePlayer({ lang: localeWeb });
    handlePositon({ code: 'POSITION_PLAYER', lang: localeWeb });
  }, [localeWeb]);

  const [entitySave, setEntitySave] = useState({
    koName: '',
    birthday: null,
    idNumber: null,
    height: null,
    weight: null,
    phoneNumber: null,
    uniformNumber: null,
    base64FileLicense: null,
    base64FileITC: null,
    base64FileContract: null,
    playerAgentId: null,
  } as IPlayer);

  useEffect(() => {
    if (!entityId) return;
    if (playerEntity && playerEntity.id) {
      form.setFieldsValue({
        ...playerEntity,
        birthday: playerEntity && playerEntity.birthday ? dayjs(playerEntity.birthday) : null,
      });
      if (playerEntity.fileContract) {
        setFileContract([{ ...fileContract[0], url: playerEntity.base64FileContract, name: playerEntity.fileContract, status: 'done' }]);
      }

      if (playerEntity.fileITC) {
        setFileITC([{ ...fileITC[0], url: playerEntity.base64FileITC, name: playerEntity.fileITC, status: 'done' }]);
      }

      if (playerEntity.fileLicense) {
        setFileLicense([{ ...fileLicense[0], url: playerEntity.base64FileLicense, name: playerEntity.fileLicense, status: 'done' }]);
      }

      setEntitySave({ ...playerEntity });
    }
  }, [playerEntity, entityId]);

  const handleNationally = async ({ lang }: any) => {
    const dataNationally = await axios.get<any>(`api/utils/countries?lang=${lang}`);
    const arrNation = [];
    dataNationally.data.map(a =>
      arrNation.push({
        value: a.name,
        label: a.name,
      })
    );
    setDataNation(arrNation);
  };

  const handlePlayer = async ({ lang }: any) => {
    const dataPlayerAgents = await axios.get<any>(`api/player-agents?lang=${lang}`);
    const arrPlayer = [];
    dataPlayerAgents.data.map(a =>
      arrPlayer.push({
        value: a.id,
        label: a.name,
      })
    );
    setDataPlayer(arrPlayer);
  };

  const handlePositon = async ({ code, lang }: any) => {
    const dataPosition = await axios.get<any>(`api/category-data-by-code?code=${code}&lang=${lang}`);
    const arrPosition = [];
    dataPosition.data.dataTrans.map(a =>
      arrPosition.push({
        value: a.id,
        label: a.name,
        code: a.code,
      })
    );

    setDataPositions(arrPosition);
  };

  const handleReset = () => {
    form.setFieldsValue({
      avatar: '',
      name: '',
      position: null,
      nationality: null,
      clubId: null,
      clubName: '',
      uniformNumber: '',
    });

    setPreviewImg('');
    setFile(null);
  };

  const handlePreviewAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileImg = e.target.files[0];
    if (validateSizeImage(fileImg, e)) {
      setFile(fileImg);
      getBase64(fileImg, result => {
        setPreviewImg(result);
      });
    }
  };

  const saveData = async (e, oldAvatar) => {
    const data: IPlayer = {};

    // if (!isNew && playerEntity.id) {
    //   data = {
    //     ...playerEntity,
    //   };
    // }

    if (fileContract.length > 0 && !!fileContract[0].size) {
      const bodyImg = new FormData();
      bodyImg.append('file', fileContract[0] as RcFile);
      bodyImg.append('uploadType', 'CONTRACT_PLAYER');
      const result = await upLoadImg(bodyImg);
      data.fileContract = result.data;
    }

    if (fileITC.length > 0 && !!fileITC[0].size) {
      const bodyImg = new FormData();
      bodyImg.append('file', fileITC[0] as RcFile);
      bodyImg.append('uploadType', 'ITC_PLAYER');
      const result = await upLoadImg(bodyImg);
      data.fileITC = result.data;
    }

    if (fileLicense.length > 0 && !!fileLicense[0].size) {
      const bodyImg = new FormData();
      bodyImg.append('file', fileLicense[0] as RcFile);
      bodyImg.append('uploadType', 'WORK_LICENSE_PLAYER');
      const result = await upLoadImg(bodyImg);
      data.fileLicense = result.data;
    }

    data.koName = form.getFieldValue('koName');
    data.position = form.getFieldValue('position');
    data.nationality = form.getFieldValue('nationality');
    data.uniformNumber = form.getFieldValue('uniformNumber');
    data.clubId = form.getFieldValue('clubId');
    data.avatar = oldAvatar;
    data.birthday = form.getFieldValue('birthday');
    data.organizationId = form.getFieldValue('organizationId');
    data.idNumber = form.getFieldValue('idNumber');
    data.nationality = form.getFieldValue('nationality');
    data.clubName = form.getFieldValue('clubName');
    data.homeAddress = form.getFieldValue('homeAddress');
    data.email = form.getFieldValue('email');
    data.fifaId = form.getFieldValue('fifaId');
    // data.fileContract = form.getFieldValue('fileContract');
    // data.fileITC = form.getFieldValue('fileITC');
    // data.fileLicense = form.getFieldValue('fileLicense');
    data.previousClub = form.getFieldValue('previousClub');
    data.height = form.getFieldValue('height');
    data.weight = form.getFieldValue('weight');
    data.phoneNumber = form.getFieldValue('phoneNumber');
    data.playerAgentId = form.getFieldValue('playerAgentId');
    data.birthplace = form.getFieldValue('birthplace');

    dispatch(createEntity(data)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        setConfirmModal(false);
        setIsOpen(false);
        handleReset();
      }
      setDisabled(false);
    });
  };

  const handleCreateClub = () => {
    setDisabled(true);
    const data: any = {};
    let oldAvatar = playerEntity.avatar;
    if (file) {
      const bodyImg = new FormData();
      bodyImg.append('file', file);
      bodyImg.append('uploadType', 'AVATAR_PLAYER');
      upLoadImg(bodyImg).then(result => {
        oldAvatar = result.data;
        saveData(data, oldAvatar);
        updateFilter();
      });
    } else {
      saveData(data, oldAvatar);
      updateFilter();
    }
  };

  const handleCreate = () => {
    dispatch(
      checkDuplicate({ name: form.getFieldValue('name'), birthDate: dayjs(form.getFieldValue('birthday')).format('YYYY/MM/DD') })
    ).then(res => {
      const newData: any = res.payload;
      if (newData?.data?.length !== 0) {
        setConfirmModal(true);
      } else {
        setConfirmModal(false);
        handleCreateClub();
      }
    });
  };

  const options = [
    {
      value: 'name',
      label: translate('leagueApp.leagueClub.detail.title1'),
    },
  ];

  const columns = [
    {
      title: <Translate contentKey="leagueApp.leagueClub.detail.title1" />,
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const clubId = Form.useWatch('clubId', form);

  const [fileLicense, setFileLicense] = useState<UploadFile[]>([]);
  const [fileContract, setFileContract] = useState<UploadFile[]>([]);
  const [fileITC, setFileITC] = useState<UploadFile[]>([]);

  const propsedFileITC: UploadProps = {
    onRemove(filed) {
      const index = fileITC.indexOf(filed);
      const newFileITC = fileITC.slice();
      newFileITC.splice(index, 1);
      setFileITC(newFileITC);
    },
    beforeUpload(filed) {
      if (filed.size / 1024 / 1024 > 10) {
        message.error(<>{translate('leagueApp.player.error.title')}</>);
      } else {
        let newFileITC = [...fileITC, filed];
        newFileITC = newFileITC.slice(-1);
        setFileITC(newFileITC);
      }
      return false;
    },
  };

  const propsedFileContract: UploadProps = {
    onRemove(filed) {
      const index = fileLicense.indexOf(filed);
      const newFileContract = fileLicense.slice();
      newFileContract.splice(index, 1);
      setFileContract(newFileContract);
    },
    beforeUpload(filed) {
      if (filed.size / 1024 / 1024 > 10) {
        message.error(<>{translate('leagueApp.player.error.title')}</>);
      } else {
        let newFileContract = [...fileLicense, filed];
        newFileContract = newFileContract.slice(-1);
        setFileContract(newFileContract);
      }

      return false;
    },
  };

  const propsedFileLicense: UploadProps = {
    onRemove(filed) {
      const index = fileLicense.indexOf(filed);
      const newFileLicense = fileLicense.slice();
      newFileLicense.splice(index, 1);
      setFileLicense(newFileLicense);
    },
    beforeUpload(filed) {
      if (filed.size / 1024 / 1024 > 10) {
        message.error(<>{translate('leagueApp.player.error.title')}</>);
      } else {
        let newFileLicense = [...fileLicense, filed];
        newFileLicense = newFileLicense.slice(-1);
        setFileLicense(newFileLicense);
      }

      return false;
    },
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={handleCloseModal}
      title={<Translate contentKey="leagueApp.player.home.createLabel" />}
      wrapClassName="management-wrapper-modal"
      footer={null}
      width={900}
    >
      <Form
        form={form}
        onFinishFailed={values => handleFocusFieldFail(values)}
        scrollToFirstError
        name="control-hooks"
        onFinish={handleCreate}
        initialValues={{
          ...entitySave,
          organizationId: isLeagueMaster ? unitStandardId : null,
        }}
        layout="vertical"
        requiredMark={'optional'}
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item
              name="avatar"
              label={<h6>{translate('leagueApp.player.avatar')}</h6>}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.required.avatar'),
                },
              ]}
            >
              <div>
                <div className="input-width">
                  <label htmlFor="uploadImage" className="upload-file">
                    {previewImg ? (
                      <img src={previewImg} alt="logo" style={{ width: '100%', height: '100%' }} />
                    ) : playerEntity && playerEntity.id && !!playerEntity.base64Avatar ? (
                      <img src={playerEntity.base64Avatar} alt="logo" style={{ width: '100%', height: '100%' }} />
                    ) : (
                      <span>+ {translate('leagueApp.player.image')}</span>
                    )}
                  </label>
                  <span style={{ fontSize: '12px' }} className="primary-color">
                    <Translate contentKey="leagueApp.player.home.uploadimg" />
                  </span>
                  <Input id="uploadImage" value={null} hidden type="file" accept="image/*" onChange={handlePreviewAvatar} />
                </div>
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="koName"
              label={<h6>* {translate('leagueApp.player.detail.label')}</h6>}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.required.name'),
                },
              ]}
            >
              <TextField placeholder={translate('leagueApp.placeholder.name')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<h6>* {translate('leagueApp.player.birthday')}</h6>}
              name="birthday"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || new Date() >= value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(translate('leagueApp.required.birthday')));
                  },
                }),
                {
                  required: true,
                  message: translate('leagueApp.required.birthdayRequired'),
                },
              ]}
            >
              <DatePicker
                locale={{
                  ...locale,
                  lang: {
                    ...locale.lang,
                    today: translate('entity.action.today'),
                  },
                }}
                style={{ width: '100%' }}
                placeholder={translate('leagueApp.placeholder.birthday')}
                format="YYYY-MM-DD"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="birthplace"
              label={<h6>* {translate('leagueApp.player.birthplace')}</h6>}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.required.birthPlace'),
                },
              ]}
            >
              <TextField placeholder={translate('leagueApp.placeholder.birthPlace')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="fifaId"
              label={<h6>* {translate('leagueApp.player.id_number')}</h6>}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.required.id_number'),

                  // validator(_, value) {
                  //   if (value.length === 0) {
                  //     return Promise.reject(<>{translate('leagueApp.required.id_number')}</>);
                  //   }
                  //   if (value.length > 0 && !(value.length === 9 || value.length === 12)) {
                  //     return Promise.reject(<>{translate('leagueApp.required.id_number_vali')}</>);
                  //   }
                  //   return Promise.resolve();
                  // },
                },
              ]}
            >
              <TextField placeholder={translate('leagueApp.placeholder.id_number')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="nationality"
              label={<h6>* {translate('leagueApp.player.detail.labelNation')}</h6>}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.required.nation'),
                },
              ]}
            >
              <Select
                placeholder={translate('global.choose.nation')}
                className="custom-prefix-select"
                allowClear
                suffixIcon={<SelectArrowDown />}
                showSearch
                options={dataNation}
                filterOption={(inputValue: string, option?: any) => {
                  return (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase());
                }}
              >
                {/* {dataNation.map((nations, index) => (
                  <Select.Option value={nations.label} key={index}>
                    {nations.label}
                  </Select.Option>
                ))} */}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={<h6>* {translate('leagueApp.player.club')}</h6>}
              name="clubName"
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.required.clb'),
                },
              ]}
            >
              <Input
                placeholder={translate('leagueApp.player.home.createTitle')}
                suffix={
                  <Button onClick={() => setSearchClubModal(true)} className="position-absolute" style={{ right: '8px' }}>
                    <Translate contentKey="leagueApp.league.choose" />
                  </Button>
                }
                readOnly
                className="position-relative"
              ></Input>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={<h6>* {translate('leagueApp.player.uniform_number')}</h6>}
              name="uniformNumber"
              rules={[{ required: !!clubId, message: translate('leagueApp.required.uniformNumber') }]}
            >
              <NumberField disabled={!clubId} negative={true} placeholder={translate('leagueApp.placeholder.uniformNumber')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="position"
              label={<h6>* {translate('leagueApp.player.detail.labelPosition')}</h6>}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.required.position'),
                },
              ]}
            >
              <Select
                placeholder={translate('global.choose.position')}
                className="custom-prefix-select"
                allowClear
                suffixIcon={<SelectArrowDown />}
              >
                {dataPositions.map((position, index) => (
                  <Select.Option value={position.label} key={index}>
                    {translate(`leagueApp.playerAgent.${position.code}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<h6>* {translate('leagueApp.notice.noticeCategoryArr.organization')}</h6>}
              name="organizationId"
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.notice.required.organization'),
                },
              ]}
            >
              <Select
                placeholder={translate('register.unit.placeholder')}
                className="custom-prefix-select"
                allowClear
                showSearch
                // disabled={!isAdmin}
                filterOption={(input, option) => (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                suffixIcon={<SelectArrowDown />}
              >
                {organizations.map(roles => (
                  <Select.Option value={roles.value} key={roles.value}>
                    {roles.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item name="previousClub" label={<h6>{translate('leagueApp.player.previous_club')}</h6>}>
              <TextField placeholder={translate('leagueApp.placeholder.previous_club')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="height"
              label={<h6>* {translate('leagueApp.player.height')}</h6>}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.required.heightRequired'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value === '0') {
                      return Promise.reject(new Error(translate('leagueApp.required.height')));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input placeholder={translate('leagueApp.placeholder.height')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="weight"
              label={<h6>* {translate('leagueApp.player.weight')}</h6>}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.required.weightRequired'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value === '0') {
                      return Promise.reject(new Error(translate('leagueApp.required.weight')));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input placeholder={translate('leagueApp.placeholder.weight')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phoneNumber"
              label={<h6>* {translate('leagueApp.player.phone')}</h6>}
              rules={[
                { required: true, message: translate('global.messages.validate.phonenumber.required') },
                {
                  pattern: /^[0-9]/,
                  message: translate('register.messages.validate.phonenumber.pattern'),
                },
              ]}
              style={{ marginBottom: 32 }}
              data-cy="password"
            >
              <NumberField
                negative={true}
                placeholder={translate('global.form.phonenumber.placeholder')}
                style={{ width: '100%' }}
                // iconRender={visible => (visible ? <EyeShowLoginIcon /> : <EyeHideLoginIcon />)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              label={<h6>* {translate('leagueApp.player.email')}</h6>}
              rules={[
                { required: true, message: translate('global.messages.validate.email.required') },
                {
                  type: 'email',
                  message: translate('global.messages.validate.email.invalid'),
                },
              ]}
            >
              <TextField placeholder={translate('leagueApp.placeholder.email')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="homeAddress"
              label={<h6>* {translate('leagueApp.player.home_address')}</h6>}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.required.address'),
                },
              ]}
            >
              <TextField placeholder={translate('leagueApp.placeholder.address')} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={<h6>* {translate('leagueApp.player.file_contract')}</h6>}
              name="file_contract"
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.required.file_contract'),
                },
              ]}
            >
              <Upload {...propsedFileContract} fileList={fileContract}>
                <div className="btn_file">
                  <Button
                    style={{
                      display: 'flex',
                      width: '100%',
                      padding: '14px',
                      height: '100%',
                      backgroundColor: 'transparent',
                      color: '#D9D9D9',
                      fontWeight: '300',
                      justifyContent: 'left',
                    }}
                    icon={<UploadOutlined />}
                  >
                    {translate('leagueApp.placeholder.file_contract')}
                  </Button>
                </div>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={<h6>{translate('leagueApp.player.file_ITC')}</h6>} name="fileITC">
              <Upload {...propsedFileITC} fileList={fileITC}>
                <div className="btn_file">
                  <Button
                    style={{
                      display: 'flex',
                      width: '100%',
                      padding: '14px',
                      height: '100%',
                      backgroundColor: 'transparent',
                      color: '#D9D9D9',
                      fontWeight: '300',
                      justifyContent: 'left',
                    }}
                    icon={<UploadOutlined />}
                  >
                    {translate('leagueApp.placeholder.file_ITC')}
                  </Button>
                </div>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={<h6>* {translate('leagueApp.player.file_license')}</h6>}
              name="fileLicense"
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.required.file_license'),
                },
              ]}
            >
              <Upload {...propsedFileLicense} fileList={fileLicense}>
                <div className="btn_file">
                  <Button
                    style={{
                      display: 'flex',
                      width: '100%',
                      padding: '14px',
                      height: '100%',
                      backgroundColor: 'transparent',
                      color: '#D9D9D9',
                      fontWeight: '300',
                      justifyContent: 'left',
                    }}
                    icon={<UploadOutlined />}
                  >
                    {translate('leagueApp.placeholder.file_license')}
                  </Button>
                </div>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Typography style={{ marginBottom: '20px' }}>
              <h6>{translate('leagueApp.player.home.titleManager')}</h6>
            </Typography>
          </Col>
          <Col span={12}>
            <Form.Item name="playerAgentId" label={<h6>{translate('leagueApp.player.name_manager')}</h6>}>
              <Select
                placeholder={translate('leagueApp.placeholder.nameManager')}
                className="custom-prefix-select"
                allowClear
                suffixIcon={<SelectArrowDown />}
                showSearch
                options={dataPlayer}
                filterOption={(inputValue: string, option?: any) => {
                  return (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase());
                }}
              >
                {dataNation.map((nations, index) => (
                  <Select.Option value={nations.label} key={index}>
                    {nations.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}

          <Col span={24}>
            <div className="management-modal-footer">
              <Button key="back" onClick={handleCloseModal}>
                <Translate contentKey="register.form.close" />
              </Button>
              <Button key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }} disabled={disabled}>
                <Translate contentKey="settings.form.button" />
              </Button>
            </div>
          </Col>
          <Form.Item name="clubId">
            <Input type={'hidden'}></Input>
          </Form.Item>
        </Row>
      </Form>
      {searchClubModal && (
        <SearchModal
          onClose={() => {
            setSearchClubModal(false);
          }}
          open={searchClubModal}
          list={clubs}
          columns={columns}
          options={options}
          onFetchData={(data: { name?: string }) => getClubs(data?.name)}
          title={translate('leagueApp.leagueClub.home.createTitle1')}
          subTitle={translate('leagueApp.leagueClub.home.createTitle1')}
          multiple={false}
          onAccept={(selected: any[]) => {
            form.setFieldsValue({ clubName: first(selected)?.name, clubId: first(selected)?.id, uniformNumber: null });
            setSearchClubModal(false);
          }}
          selected={[clubId]}
        />
      )}
      {confirmModal && (
        <ConfirmationDialog
          open={confirmModal}
          message={<h6>{translate('leagueApp.player.duplicate')}</h6>}
          onClose={() => setConfirmModal(false)}
          onConfirm={() => handleCreateClub()}
        />
      )}
    </Modal>
  );
};

export default ModalCreatePlayer;
