import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IPlayerAcademic, defaultValue } from 'app/shared/model/player-academic.model';
import { dispatch } from 'rxjs/internal/observable/pairs';

const initialState: EntityState<IPlayerAcademic> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  createPlayerSuccess: '',
  deleteSuccess: false,
  createSuccess: false,
};

const apiUrl = 'api';
const apiUrlSearch = 'api/player-academics/search';
const apiDetailUrl = 'api/player-academics';

// Actions
export const getEntities = createAsyncThunk('playerAcademic/fetch_entity_list', async ({ page, size, sort, playerId }: IQueryParams) => {
  const requestUrl = `${apiUrlSearch}${sort ? `?page=${page}&size=${size}&sort=${sort}` : '?'}`;
  return axios.post<IPlayerAcademic[]>(requestUrl, { playerId });
});

export const getEntity = createAsyncThunk(
  'playerAcademic/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiDetailUrl}/${id}`;
    return axios.get<IPlayerAcademic>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'playerAcademic/create_entity',
  async (entity: IPlayerAcademic, thunkAPI) => {
    const result = await axios.post<IPlayerAcademic>(`${apiUrl}/player-academics`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ sort: 'asc', playerId: result?.data?.playerId, page: 0, size: 10 }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'playerAcademic/update_entity',
  async (entity: IPlayerAcademic, thunkAPI) => {
    const result = await axios.put<IPlayerAcademic>(`${apiUrl}/player-academics/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ sort: 'asc', playerId: result?.data?.playerId, page: 0, size: 10 }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'playerAcademic/partial_update_entity',
  async (entity: IPlayerAcademic, thunkAPI) => {
    const result = await axios.patch<IPlayerAcademic>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'playerAcademic/delete_entity',
  async (academicId: any, thunkAPI) => {
    const requestUrl = `${apiUrl}/player-academics/${academicId}`;
    const result = await axios.delete<IPlayerAcademic>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const PlayerAcademicSlice = createEntitySlice({
  name: 'playerAcademic',
  initialState,
  reducers: {
    clearMsgUpdate: (state, action) => void (state.updateSuccess = null),
    clearMsgCreate: (state, action) => void (state.createSuccess = null),
    clearMsgDelete: (state, action) => void (state.deleteSuccess = null),
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.deleteSuccess = true;
        state.entity = {};
      })
      // .addCase(getEntities.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.entities = action.payload.data;
      //   state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      // })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.createSuccess = true;
        state.entity = action.payload.data;
        // thunkAPI.dispatch(getEntities({}));
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, clearMsgUpdate, clearMsgDelete, clearMsgCreate } = PlayerAcademicSlice.actions;

// Reducer
export default PlayerAcademicSlice.reducer;
