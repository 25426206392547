import { Col, ConfigProvider, Empty, Form, Input, Radio, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import NumberField from 'app/modules/lm-input-number';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { useParams } from 'react-router-dom';
import { Modal, Button } from 'antd';
import TextField from 'app/modules/lm-input';
// import { IMonitor } from 'app/shared/model/monitor.model';
import { toast } from 'react-toastify';
import '../monitor/monitor.scss';
import { clearMsgCreate, clearMsgUpdate, createEntity, getEntity, reset, updateEntity } from './player-academic.reducer';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { ICategoryData } from 'app/shared/model/category-data.model';
import { CATEGORY_DATA_CODE } from 'app/shared/util/category-data-code.constant';
import DatePicker from 'app/modules/date-picker';
import dayjs from 'dayjs';
import { handleFocusFieldFail, sliceLocale } from 'app/shared/util/entity-utils';
import { handleValidateMessageLanguageChanged } from 'app/shared/util/validate';
export const ModalCreateAcademic = (props: any) => {
  const { isOpen, setIsOpen, viewDetail, setViewDetail } = props;
  const dispatch = useAppDispatch();
  const param = useParams<{ id: string; params: string }>();
  const updateSuccess = useAppSelector(state => state.PlayerAcademic.updateSuccess);
  const createSuccess = useAppSelector(state => state.PlayerAcademic.createSuccess);
  const playerAcademicEntity = useAppSelector(state => state.PlayerAcademic?.entity);
  const [isNew, setIsNew] = useState(true);
  const [entitySave, setEntitySave] = useState({
    schoolName: '',
    graduationYear: '',
    issueYear: '',
    type: '',
    rank: null,
  });
  const [dataAcademic, setDataAcademic] = useState([]);
  const [dataGraduated, setDataGraduated] = useState([]);
  const locale = useAppSelector(state => state.locale.currentLocale);

  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
  }, [locale]);

  useEffect(() => {
    if (updateSuccess) {
      setIsOpen(false);
      setViewDetail(null);
      // sortEntities();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createSuccess) {
      setIsOpen(false);
      setViewDetail(null);
      // sortEntities();
    }
  }, [createSuccess]);

  useEffect(() => {
    handleTypeAcademic({ code: CATEGORY_DATA_CODE.ACADEMIC_PLAYER, lang: locale });
    handleTypeGraduate({ code: CATEGORY_DATA_CODE.GRADUATION_TYPE, lang: locale });
  }, []);

  const handleTypeAcademic = async ({ code, lang }: any) => {
    const dataNationally = await axios.get<any>(`api/category-data-by-code?code=${code}&lang=${lang}`);
    const arrTypeAcademic = [];
    dataNationally.data.dataTrans.map(a => {
      arrTypeAcademic.push({
        value: Number(a.code),
        label: a.name,
      });
    });
    setDataAcademic(arrTypeAcademic);
  };

  const handleTypeGraduate = async ({ code, lang }: any) => {
    const dataType = await axios.get<any>(`api/category-data-by-code?code=${code}&lang=${lang}`);
    const arrTypeGraduate = [];
    const newData = dataType.data.dataTrans.map(a => {
      return {
        value: Number(a.code),
        label: a.name,
      };
    });
    setDataGraduated(newData);
  };

  useEffect(() => {
    const c = !viewDetail;
    setIsNew(c);
    if (!c) {
      handleUpdate(viewDetail);
    }
  }, [viewDetail]);

  useEffect(() => {
    if (!viewDetail) return;
    if (playerAcademicEntity && playerAcademicEntity.id) {
      form.setFieldsValue({
        ...playerAcademicEntity,
        issueYear: playerAcademicEntity.issueYear ? dayjs(playerAcademicEntity.issueYear) : null,
        graduationYear: playerAcademicEntity.graduationYear ? dayjs(playerAcademicEntity.graduationYear) : null,
        type: playerAcademicEntity?.type,
        rank: playerAcademicEntity?.rank,
      });
      setEntitySave({ ...playerAcademicEntity });
      // setIsOpen(true);
    }
  }, [playerAcademicEntity, viewDetail]);

  /** save action **/
  const handleValidSubmit = e => {
    let data: any = {};
    if (!isNew && playerAcademicEntity.id) {
      data = {
        ...playerAcademicEntity,
        issueYear: playerAcademicEntity.issueYear ? dayjs(playerAcademicEntity.issueYear).format('YYYY') : null,
        graduationYear: playerAcademicEntity.graduationYear ? dayjs(playerAcademicEntity.graduationYear).format('YYYY') : null,
      };
    }
    data.schoolName = form.getFieldValue('schoolName');
    data.graduationYear = dayjs(form.getFieldValue('graduationYear')).format('YYYY');
    data.issueYear = dayjs(form.getFieldValue('issueYear')).format('YYYY');
    data.type = form.getFieldValue('type');
    data.rank = form.getFieldValue('rank');
    data.playerId = +param.id;
    if (!isNew && data.id) {
      dispatch(updateEntity(data));
      // setIsOpen(false);
    } else {
      dispatch(createEntity(data));
      // setIsOpen(false);
    }
    closeDialog();
  };

  const [form] = Form.useForm();

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };

  /** update **/
  const handleUpdate = id => {
    dispatch(getEntity(id));
  };

  function closeDialog() {
    setIsOpen(false);
    setViewDetail(null);
  }

  return (
    <React.Fragment>
      <Modal
        visible={isOpen}
        onCancel={() => closeDialog()}
        title={
          isNew ? (
            <Translate contentKey="leagueApp.playerAcademic.created.title" />
          ) : (
            <Translate contentKey="leagueApp.playerAcademic.home.updateLabel" />
          )
        }
        wrapClassName="management-wrapper-modal"
        footer={null}
        width={900}
      >
        <Form
          form={form}
          initialValues={{ ...entitySave }}
          onFinishFailed={values => handleFocusFieldFail(values)}
          scrollToFirstError
          name="control-hooks"
          onFinish={handleValidSubmit}
          layout="vertical"
          requiredMark={'optional'}
        >
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item
                name="type"
                label={<h6>{translate('leagueApp.playerAcademic.label.type')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.playerAcademic.required.type'),
                  },
                ]}
              >
                <Radio.Group options={dataAcademic}></Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col md={12} xs={24}>
              <Form.Item
                name="schoolName"
                label={<h6>{translate('leagueApp.playerAcademic.label.schoolName')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.playerAcademic.required.schoolName'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.playerAcademic.placeholder.schoolName')} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[12, 12]}>
            <Col md={12} xs={24}>
              <Form.Item
                name="issueYear"
                label={<h6>{translate('leagueApp.playerAcademic.label.issueYear')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.playerAcademic.required.issueYear'),
                  },
                ]}
              >
                <DatePicker
                  placeholder={translate('leagueApp.playerAcademic.placeholder.issueYear')}
                  picker="year"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[12, 12]}>
            <Col md={12} xs={24}>
              <Form.Item
                name="rank"
                label={<h6>{translate('leagueApp.playerAcademic.label.rank')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.playerAcademic.required.rank'),
                  },
                ]}
              >
                  <Select
                    placeholder={translate('leagueApp.playerAcademic.placeholder.rank')}
                    allowClear
                    className="custom-prefix-select"
                    suffixIcon={<SelectArrowDown />}
                  >
                    {dataGraduated?.map((nations, index) => (
                      <Select.Option value={nations.value} key={nations.value}>
                        {nations.label}
                      </Select.Option>
                    ))}
                  </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name="graduationYear"
                label={<h6>{translate('leagueApp.playerAcademic.label.graduationYear')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.playerAcademic.required.graduationYear'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('issueYear') < value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(translate('leagueApp.playerAcademic.required.compareYear')));
                    },
                  }),
                ]}
              >
                <DatePicker
                  placeholder={translate('leagueApp.playerAcademic.placeholder.graduationYear')}
                  picker="year"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="management-modal-footer">
            <Button key="back" onClick={closeDialog}>
              <Translate contentKey="register.form.close" />
            </Button>
            <Button key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }}>
              <Translate contentKey="settings.form.button" />
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ModalCreateAcademic;
