import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ILeagueRecorder, defaultValue } from 'app/shared/model/league-recorder.model';

const initialState: EntityState<ILeagueRecorder> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  deleteSuccess: false,
};

const apiGetRecorder = 'api/clubs/assign-club';

// Actions

export const getEntities = createAsyncThunk('leagueClubFree/fetch_entity_list', async ({page, size, sort, leagueId, ...res }: IQueryParams) => {
  const requestUrl = `${apiGetRecorder}${sort ? `?page=${page}&size=${size}&sort=${sort}` : '?'}`;
  return axios.post<ILeagueRecorder[]>(requestUrl, {...res, leagueId});
});

export const GetFreeClub = createEntitySlice({
    name: 'leagueClubFree',
    initialState,
    extraReducers(builder) {
      builder
        .addMatcher(isFulfilled(getEntities), (state, action) => {
          const { data, headers } = action.payload;
          return {
            ...state,
            loading: false,
            entities: data,
            totalItems: parseInt(headers['x-total-count'], 10),
          };
        })
        .addMatcher(isPending(getEntities), state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        })
    },
  });
  
  export const { reset } = GetFreeClub.actions;
  
  // Reducer
  const getFreeClubMultiSelect = GetFreeClub.reducer;
  export default getFreeClubMultiSelect
  