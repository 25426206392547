import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected, createSlice } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IPlayerBanRecord, defaultValue } from 'app/shared/model/player-ban-record.model';
import { ICategoryData } from 'app/shared/model/category-data.model';
import { CATEGORY_DATA_CODE } from 'app/shared/util/category-data-code.constant';
import { get } from 'lodash';

interface State extends EntityState<IPlayerBanRecord> {
  list: {
    reasons: ICategoryData[];
    classify: ICategoryData[];
  };
}

const initialState: State = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  list: {
    reasons: [],
    classify: [],
  },
};

const apiUrl = 'api/player-ban-records';

// Actions

export const getEntities = createAsyncThunk('playerBanRecord/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IPlayerBanRecord[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'playerBanRecord/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IPlayerBanRecord>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'playerBanRecord/create_entity',
  async (entity: IPlayerBanRecord, thunkAPI) => {
    const result = await axios.post<IPlayerBanRecord>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'playerBanRecord/update_entity',
  async (entity: IPlayerBanRecord, thunkAPI) => {
    const result = await axios.put<IPlayerBanRecord>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'playerBanRecord/partial_update_entity',
  async (entity: IPlayerBanRecord, thunkAPI) => {
    const result = await axios.patch<IPlayerBanRecord>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'playerBanRecord/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IPlayerBanRecord>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getListCategoryValue = createAsyncThunk('playerFoulRecord/getList', async (locale: string) => {
  const url = (cate: string) => `/api/category-data-by-code?code=${cate}&lang=${locale}`;
  const list = [CATEGORY_DATA_CODE.WARING_REASON, CATEGORY_DATA_CODE.WARING_TYPE];
  const result = await axios.all<ICategoryData>(list.map(item => axios.get(url(item))));
  return result;
});

// slice

export const PlayerBanRecordSlice = createSlice({
  name: 'playerBanRecord',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isFulfilled(getListCategoryValue), (state, action) => {

        state.list = {
          reasons: get(action.payload, '0.data.dataTrans'),
          classify: get(action.payload, '1.data.dataTrans'),
        };
      });
  },
});

// Reducer
export default PlayerBanRecordSlice.reducer;
