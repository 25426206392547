import './footer.scss';
import React from 'react';
import { Translate } from 'react-jhipster';
import { MainLogo, VFF, VFFLogo } from 'app/shared/util/sidebarIcon';
import { ModalPrivacy } from 'app/entities/privacy-policy/privacy-modal';
import { ModalPolicy } from 'app/entities/privacy-policy/policy-model';
const Footer = () => {
  const [openPolicy, setOpenPolicy] = React.useState(false);
  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  return (
    <div className="main-footer">
      <div className="main-footer-logo">
        <VFF fill={'#f0f2f5'} width={40} height={40} />
      </div>
      <div>
        <div>
          <div onClick={() => setOpenPrivacy(true)} className="primary-color main-footer-term">
            <Translate contentKey="footer.term"></Translate>
          </div>
          <div
            onClick={() => {
              setOpenPolicy(true);
            }}
            className="primary-color main-footer-term"
          >
            <Translate contentKey="footer.privacy"></Translate>
          </div>
          <div className="neutral-3 main-footer-copyright">
            <Translate contentKey="footer.copyright"></Translate>
          </div>
        </div>
      </div>
      {openPrivacy && <ModalPrivacy openPrivacy={openPrivacy} setOpenPrivacy={setOpenPrivacy} />}
      {openPolicy && <ModalPolicy openPolicy={openPolicy} setOpenPolicy={setOpenPolicy} />}
    </div>
  );
};

export default Footer;
