import axios from 'axios';

const apiUrl = 'api/dashboard';
const reportApi = 'api/report';
const reportPlayerApi = 'api/report-player';
const notificationApi = 'api/notification/get-all';
const nexMatchRequest = 'api/dashboard/next-match';
const matchShecduleRequest = 'api/dashboard/match-schedule';
const rankClubRequest = 'api/report/rank-club';
const rankGoalRequest = 'api/report-player/top-goal';
const rankAssistRequest = 'api/report-player/top-assist';
const apiListLeague = 'api/dashboard/list-league';
const apiFirstLogin = 'api/login-histories/login-first';
const apiGetNotice = 'api/notices';

export const nextMatchRequest = () => {
  const url = apiUrl + '/next-match';
  return axios.get(url);
};

export const nextMatchRequestV2 = payload => {
  return axios.get(nexMatchRequest, {
    params: payload,
  });
};
export const rankClub = () => {
  const url = reportApi + '/rank-club';
  return axios.get(url);
};

export const rankClubV2 = payload => {
  return axios.get(rankClubRequest, {
    params: payload,
  });
};

export const topGoal = () => {
  const url = reportPlayerApi + '/top-goal';
  return axios.get(url);
};

export const topGoalV2 = payload => {
  return axios.get(rankGoalRequest, {
    params: payload,
  });
};
export const topAssist = () => {
  const url = reportPlayerApi + '/top-assist';
  return axios.get(url);
};
export const topAssistV2 = payload => {
  return axios.get(rankAssistRequest, {
    params: payload,
  });
};

export const notificationRequest = payload => {
  return axios.get(notificationApi, {
    params: payload,
  });
};

export const matchSchedule = payload => {
  return axios.get(matchShecduleRequest, {
    params: payload,
  });
};

export const listLeague = payload => {
  return axios.get(apiListLeague, {
    params: payload,
  });
};

export const loginHistory = () => {
  return axios.get(apiFirstLogin);
};

export const listNotice = payload => {
  return axios.post(`api/notices/search?page=${payload?.page}&size=${payload?.size}`, { type: payload?.type });
};
