import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import LeagueStandard from './league-standard';
import LeagueStandardDetail from './league-standard-detail';
import LeagueStandardUpdate from './league-standard-update';
import LeagueStandardDeleteDialog from './league-standard-delete-dialog';
import LeagueStandardCreateDialog from './league-standard-create-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={LeagueStandardUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={LeagueStandardUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/detail`} component={LeagueStandardDetail} />
      <ErrorBoundaryRoute  exact path={`${match.url}/create`} component={LeagueStandardCreateDialog} />
      <ErrorBoundaryRoute path={match.url} component={LeagueStandard} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={LeagueStandardDeleteDialog} />
  </>
);

export default Routes;
