import React, { useState, useRef, useContext, useEffect, memo } from 'react';
import { translate } from 'react-jhipster';
import { Form, Input, Select, InputRef, Space, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { HALF_VALUE, checkMinTime, SECOND_OF_MINUTE, caculateProcessDate } from './constant.event-list';
import type { FormInstance } from 'antd/es/form';
import { EditableContext } from './index';

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

interface Item {
  clubId: number;
  createdBy: string;
  createdDate: string;
  detailLocation: string;
  id: number;
  lastModifiedBy: string;
  lastModifiedDate: string;
  matchId: number;
  playerDTO: any;
  playerId: number;
  processDate: number;
  roundType: number;
  time: string;
}

interface Props extends EditableCellProps {
  timeOfRound: number;
  timeOfRoundExtra: number;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  timeOfRound = 45,
  timeOfRoundExtra = 15,
  handleSave,
  ...restProps
}: Props) => {
  const { form, isEditHalf, setIsEditHalf, isEditTime, setIsEditTime, timeRef, roundTypeRef } = useContext(EditableContext);
  const { Option } = Select;
  const [listOptionHalf] = useState([
    <Option key={HALF_VALUE.FIRST} value={HALF_VALUE.FIRST}>
      {translate('roundType.1')}
    </Option>,
    <Option key={HALF_VALUE.SECOND} value={HALF_VALUE.SECOND}>
      {translate('roundType.2')}
    </Option>,
    <Option key={HALF_VALUE.EXTRA_FIRST} value={HALF_VALUE.EXTRA_FIRST}>
      {translate('roundType.3')}
    </Option>,
    <Option key={HALF_VALUE.EXTRA_SECOND} value={HALF_VALUE.EXTRA_SECOND}>
      {translate('roundType.4')}
    </Option>,
  ]);

  const errorTime = form.getFieldError('time');
  const setNewTime = value => {
    if (value === 1) {
      form.setFieldsValue({ time: '00:00' });
    }
    if (value === 2) {
      form.setFieldsValue({ time: `${timeOfRound}:00` });
    }
    if (value === 3) {
      form.setFieldsValue({ time: `${timeOfRound * 2}:00` });
    }
    if (value === 4) {
      form.setFieldsValue({ time: `${timeOfRound * 2 + timeOfRoundExtra}:00` });
    }
  };

  const save = async e => {
    if (e.relatedTarget === roundTypeRef.current) {
      return;
    }
    try {
      const values = await form.validateFields();
      setIsEditHalf(false);
      setIsEditTime(false);
      handleSave({
        ...record,
        ...values,
        processDate: caculateProcessDate(HALF_VALUE.FIRST, values.time || record.time),
      });
      form.resetFields();
    } catch (errInfo) {
      setIsEditHalf(false);
      setIsEditTime(false);
    }
  };

  let childNode = children;

  if (editable && dataIndex !== 'roundType') {
    childNode = isEditTime ? (
      <div className="d-flex align-items-center">
        <Form.Item
          help=""
          style={{ margin: 0, width: 80 }}
          name={dataIndex}
          rules={[formData => checkMinTime(formData, record, timeOfRound, timeOfRoundExtra)]}
        >
          <Input
            ref={timeRef}
            onBlur={e => {
              (async () => {
                await save(e);
              })();
            }}
          />
        </Form.Item>
        {errorTime?.length ? (
          <Tooltip color="red" title={errorTime}>
            <WarningOutlined style={{ color: 'red', cursor: 'pointer', marginLeft: 12 }} />
          </Tooltip>
        ) : (
          ''
        )}
      </div>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={() => {
          setIsEditTime(true);
          form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        }}
      >
        {children}
      </div>
    );
  }
  if (editable && dataIndex === 'roundType') {
    childNode = isEditHalf ? (
      <Form.Item style={{ margin: 'auto' }} name={dataIndex} rules={[{ required: true, message: `Please Input ${title}!` }]}>
        <Select
          onChange={value => {
            setIsEditTime(true);
            timeRef.current?.focus();
            // setNewTime(value);
          }}
          ref={roundTypeRef}
        >
          {listOptionHalf.map((item, i) => (
            <React.Fragment key={i}>{item}</React.Fragment>
          ))}
        </Select>
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={() => {
          form.setFieldsValue({ [dataIndex]: record[dataIndex], time: record.time });
          setIsEditHalf(true);
          setIsEditTime(true);
        }}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default memo(EditableCell);
