import { Avatar, Col, ConfigProvider, DatePicker, DatePickerProps, Empty, Form, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useFilter } from 'app/shared/hooks/useFilter';
import { IFormGetLeagueRecord, IFormLeagueName, IFormLeagueStand } from 'app/shared/model/record-room.model';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { log, translate, Translate } from 'react-jhipster';
import { getLeagueName, getListLeagueRecord, getStandard, getYear, resetValue } from '../recordRoom.reducer';
import { useRequest } from 'ahooks';
import { rankClub } from 'app/modules/dashboard/dashboard.service';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import Table from 'app/modules/table';
import { FilterParams } from 'app/shared/model/filter.model';
import { INTERMINABLE } from 'app/shared/util/constant/favorite.constant';
import { getLeague, resetValueRanking } from 'app/entities/match-center/match-center.reducer';
import { getClubRanking, getPlayerRanking } from 'app/entities/match-center/match-center.reducer';
import { handleConvert } from 'app/shared/util/convertData';

const LookupLeague = () => {
  const [form] = Form.useForm();
  const lsId = Form.useWatch('lsId', form);
  const year = Form.useWatch('year', form);
  const [sort, setSort] = useState<string>();
  const leagueId = Form.useWatch('leagueId', form);
  const sortOpt = Form.useWatch('sortOption', form);
  const dispatch = useAppDispatch();
  const dataStandard: IFormLeagueStand[] = useAppSelector(state => state.recordRoomReducer.dataStandard)?.content;
  const dataLeagueName: IFormLeagueName[] = useAppSelector(state => state.recordRoomReducer.dataLeagueName);
  const dataListLeagueRecord = useAppSelector(state => state.recordRoomReducer.dataListLeagueRecord);
  const dataYear = useAppSelector(state => state.recordRoomReducer.dataYear);
  const location: any = useLocation();
  const [disable, setDisable] = useState(false);
  const listLeague = useAppSelector(state => state.matchCenterReducer.listLeague);
  const [disableArray, setDisableArray] = useState(false);
  const dataListClubRanking = useAppSelector(state => state.matchCenterReducer.listClubRanking);
  const dataListPlayerRanking = useAppSelector(state => state.matchCenterReducer.listPlayerRanking);
  const sortOption = [
    { value: 1, label: translate('matchCenter.teamRanking') },
    { value: 2, label: translate('matchCenter.playerRanking') },
  ];

  useEffect(() => {
    if (location?.state?.items) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [location]);

  const defaultFilter = {
    page: 0,
    size: 10,
    type: 'name',
  };
  const columns = [
    {
      title: <Translate contentKey="recordRoom.lookupLeague.position">Position</Translate>,
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.teamName">Club Name</Translate>,
      dataIndex: 'clubName',
      key: 'clubName',
      render: (value, record) => (
        <>
          <Avatar src={record?.clubAvatar} size="large" style={{ marginRight: 8 }} />
          <span>{record?.clubName}</span>
        </>
      ),
      sorter: (a, b) => a.clubName - b.clubName,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.played">Played</Translate>,
      dataIndex: 'totalMatch',
      key: 'totalMatch',
      sorter: (a, b) => a.totalMatch - b.totalMatch,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.score">Score</Translate>,
      dataIndex: 'totalScores',
      key: 'totalScores',
      sorter: (a, b) => a.totalScores - b.totalScores,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.won">Won</Translate>,
      dataIndex: 'totalWin',
      key: 'totalWin',
      sorter: (a, b) => a.totalWin - b.totalWin,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.drawn">Drawn</Translate>,
      dataIndex: 'totalBalance',
      key: 'totalBalance',
      sorter: (a, b) => a.totalBalance - b.totalBalance,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.lost">Lost</Translate>,
      dataIndex: 'totalLost',
      key: 'totalLost',
      sorter: (a, b) => a.totalLost - b.totalLost,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.goalFor">Goal for</Translate>,
      dataIndex: 'totalGoal',
      key: 'totalGoal',
      sorter: (a, b) => a.totalGoal - b.totalGoal,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.goalAgainst">Goal against</Translate>,
      dataIndex: 'totalGoalConceded',
      key: 'totalGoalConceded',
      sorter: (a, b) => a.totalGoalConceded - b.totalGoalConceded,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.point">Point</Translate>,
      dataIndex: 'difference',
      key: 'difference',
      sorter: (a, b) => a.difference - b.difference,
    },
  ];

  const columnsClubRanking = [
    {
      title: <Translate contentKey="recordRoom.lookupLeague.position"></Translate>,
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.clubName">Club Name</Translate>,
      dataIndex: 'clubName',
      key: 'clubName',
      render: (value, record) => (
        <>
          <Avatar src={record?.clubAvatar} size="large" style={{ marginRight: 8 }} />
          <span>{record?.clubName}</span>
        </>
      ),
      sorter: (a, b) => a.clubName - b.clubName,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.played">Played</Translate>,
      dataIndex: 'totalMatch',
      key: 'totalMatch',
      sorter: (a, b) => a.totalMatch - b.totalMatch,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.score">Score</Translate>,
      dataIndex: 'totalScores',
      key: 'totalScores',
      sorter: (a, b) => a.totalScores - b.totalScores,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.won">Won</Translate>,
      dataIndex: 'totalWin',
      key: 'totalWin',
      sorter: (a, b) => a.totalWin - b.totalWin,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.drawn">Drawn</Translate>,
      dataIndex: 'totalBalance',
      key: 'totalBalance',
      sorter: (a, b) => a.totalBalance - b.totalBalance,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.lost">Lost</Translate>,
      dataIndex: 'totalLost',
      key: 'totalLost',
      sorter: (a, b) => a.totalLost - b.totalLost,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.goalFor">Goal for</Translate>,
      dataIndex: 'totalGoal',
      key: 'totalGoal',
      sorter: (a, b) => a.totalGoal - b.totalGoal,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.goalAgainst">Goal against</Translate>,
      dataIndex: 'totalGoalConceded',
      key: 'totalGoalConceded',
      sorter: (a, b) => a.totalGoalConceded - b.totalGoalConceded,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.point">Point</Translate>,
      dataIndex: 'difference',
      key: 'difference',
      sorter: (a, b) => a.difference - b.difference,
    },
  ];

  const columnsPlayerRanking = [
    {
      title: <Translate contentKey="recordRoom.lookupLeague.position"></Translate>,
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: <Translate contentKey="leagueApp.player.koName" />,
      dataIndex: 'koName',
      key: 'koName',
      render: (value, record) => (
        <>
          <Avatar src={record?.avatar} size="large" style={{ marginRight: 8 }} />
          <span>{value}</span>
        </>
      ),
      sorter: (a, b) => a.koName - b.koName,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.groundTime">groundTime</Translate>,
      dataIndex: 'countPlay',
      key: 'countPlay',
      sorter: (a, b) => a.countPlay - b.countPlay,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.duration">duration</Translate>,
      dataIndex: 'timePLay',
      sorter: (a, b) => a.timePLay - b.timePLay,
      key: 'timePLay',
      render: (value, record) => <span>{handleConvert(value)}</span>,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.score">score</Translate>,
      dataIndex: 'score',
      sorter: (a, b) => a.score - b.score,
      key: 'score',
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.assist">assist</Translate>,
      dataIndex: 'assist',
      sorter: (a, b) => a.assist - b.assist,
      key: 'assist',
    },
    {
      title: <Translate contentKey="leagueApp.matchRecord.shooting" />,
      dataIndex: 'shooting',
      sorter: (a, b) => a.shooting - b.shooting,
      key: 'shooting',
    },
    {
      title: <Translate contentKey="matchOver.recordTableData.shootOnTarget" />,
      dataIndex: 'effectiveStone',
      sorter: (a, b) => a.effectiveStone - b.effectiveStone,
      key: 'effectiveStone',
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.foul">foul</Translate>,
      dataIndex: 'foul',
      sorter: (a, b) => a.foul - b.foul,
      key: 'foul',
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.bloodFoul">bloodFoul</Translate>,
      dataIndex: 'beFoul',
      sorter: (a, b) => a.beFoul - b.beFoul,
      key: 'beFoul',
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.warning">warning</Translate>,
      dataIndex: 'waring',
      sorter: (a, b) => a.waring - b.waring,
      key: 'waring',
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.sendOff">sendOff</Translate>,
      dataIndex: 'chase',
      sorter: (a, b) => a.chase - b.chase,
      key: 'chase',
    },
  ];

  const handleSubmit = data => {
    // if (lsId) {
    //   // dispatch(getListLeagueRecord({ leagueId: data, sort }));
    //   dispatch(getClubRanking({ leagueId: data, sort }));
    // }
    if (data) {
      dispatch(resetValueRanking());
      form.setFieldsValue({ sortOption: null });
    }
  };

  useEffect(() => {
    dispatch(
      getStandard({
        page: 0,
        size: 1000,
        body: {},
      })
    );
    dispatch(getYear());
  }, []);
  useEffect(() => {
    if (location?.state?.items) {
      form.setFieldsValue({
        lsId: location?.state?.items[0]?.leagueStandartName,
        leagueId: location?.state?.items[0]?.leagueId,
      });
      dispatch(getLeague(location?.state?.items[0]?.leagueStandartId)).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          const newData: any = res.payload;
          form.setFieldsValue({
            // leagueName: location?.state?.items[0]?.leagueId,
            leagueId: location?.state?.items[0]?.leagueId,
          });
          // dispatch(getListLeagueRecord({ leagueId: location?.state?.items[0]?.leagueId }));
          dispatch(getClubRanking({ leagueId: location?.state?.items[0]?.leagueId }));
        }
      });
    } else {
      if (dataStandard && dataStandard[0]) {
        form.setFieldsValue({
          lsId: dataStandard[0].id,
        });
        dispatch(getLeague(dataStandard[0].id)).then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            const newData: any = res.payload;
            form.setFieldsValue({
              leagueId: newData.data[0]?.id,
            });
            if (!newData.data[0]?.id) {
              return;
            } else {
              // dispatch(getListLeagueRecord({ leagueId: newData.data[0]?.id }));
              dispatch(getClubRanking({ leagueId: newData.data[0]?.id }));
            }
          }
        });
      }
    }
  }, [dataStandard, location]);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
  });

  const handleChangeStandard = (value: number) => {
    if (value) {
      form.setFieldsValue({
        leagueId: undefined,
      });
    }
    dispatch(getLeague(value));
    dispatch(resetValue());
  };

  const handleSort = (params: FilterParams) => {
    if (leagueId) {
      if (sortOpt === 1) {
        dispatch(getClubRanking({ leagueId, sort: params.sort }));
      } else if (sortOpt === 2) {
        dispatch(getPlayerRanking({ leagueId, sort: params.sort }));
      }

      // else {
      //   dispatch(getListLeagueRecord({ leagueId, sort: params.sort }));
      // }
    }
    setSort(params.sort);
  };

  const handleSortOption = (value: number) => {
    if (value === 1) {
      dispatch(getClubRanking({ leagueId, sort }));
    } else if (value === 2) {
      dispatch(getPlayerRanking({ leagueId, sort, size: 9999 }));
    }
  };

  return (
    <div className="management-layout">
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading">
            <Translate contentKey="recordRoom.lookupLeague.title">Record League</Translate>
          </h2>
        </div>
      </div>
      <Form
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={values => handleFocusFieldFail(values)}
        scrollToFirstError
        name="control-hooks"
      >
        <div>
          <Row gutter={[32, 32]}>
            <Col xl={6} lg={12} xs={24}>
              <Form.Item name="lsId" label={<Translate contentKey="recordRoom.lookupLeague.standardPrize">Standard Prize</Translate>}>
                  <Select
                    placeholder={translate('recordRoom.placeholder.standardPrize')}
                    style={{ width: '100%' }}
                    onChange={handleChangeStandard}
                    className="custom-prefix-select"
                    suffixIcon={<SelectArrowDown />}
                    disabled={disable}
                  >
                    {dataStandard?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
              </Form.Item>
            </Col>
            <Col xl={6} lg={12} xs={24}>
              <Form.Item name="leagueId" label={<Translate contentKey="recordRoom.lookupLeague.leagueName">League Name</Translate>}>
                  <Select
                    placeholder={translate('recordRoom.placeholder.leagueName')}
                    disabled={!lsId || disable}
                    style={{ width: '100%' }}
                    onChange={handleSubmit}
                    className="custom-prefix-select"
                    suffixIcon={<SelectArrowDown />}
                  >
                    {listLeague?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
              </Form.Item>
            </Col>
            <Col xl={4} xs={0}></Col>
            {/* <Col xl={8} xs={24}>
              <Form.Item name="leagueId" label={<Translate contentKey="recordRoom.lookupLeague.leagueName">League Name</Translate>}>
                <Select
                  placeholder={translate('recordRoom.placeholder.leagueName')}
                  disabled={!year || disable}
                  style={{ width: '100%' }}
                  onChange={handleSubmit}
                  className="custom-prefix-select"
                  suffixIcon={<SelectArrowDown />}
                >
                  {dataLeagueName?.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
            <Col xl={8} xs={24}>
              <Form.Item name="sortOption" label={<Translate contentKey="recordRoom.lookupLeague.sort">Sort</Translate>}>
                  <Select onChange={handleSortOption} placeholder={translate('recordRoom.placeholder.sort')}>
                    {sortOption?.map(item => (
                      <Select.Option key={item?.value} value={item?.value}>
                        {item?.label}
                      </Select.Option>
                    ))}
                  </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ConfigProvider renderEmpty={() => <Empty description={translate('common.noDataTable')} />}>
                <Table
                  showSorterTooltip={false}
                  columns={sortOpt === 1 ? columnsClubRanking : sortOpt === 2 ? columnsPlayerRanking : columns}
                  loading={false}
                  dataSource={sortOpt === 1 ? dataListClubRanking : sortOpt === 2 ? dataListPlayerRanking : dataListLeagueRecord}
                  filter={{ sort, size: INTERMINABLE }}
                  pagination={false}
                  scroll={{
                    x: 'calc(700px + 50%)',
                  }}
                  onChange={handleSort}
                />
              </ConfigProvider>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default LookupLeague;
