import { IPlayerInMatch } from './player.model';

export interface IMatchRecord {
  id?: number;
  matchId?: number | null;
  recorderId?: number | null;
}

export interface IEventRecord {
  action: string;
  player?: IPlayerInMatch;
}

export const defaultValue: Readonly<IMatchRecord> = {};
