import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText, UncontrolledTooltip } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ILeagueStandard } from 'app/shared/model/league-standard.model';
import { getEntity, updateEntity, createEntity, reset } from './league-standard.reducer';

export const LeagueStandardUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const leagueStandardEntity = useAppSelector(state => state.leagueStandard.entity);
  const loading = useAppSelector(state => state.leagueStandard.loading);
  const updating = useAppSelector(state => state.leagueStandard.updating);
  const updateSuccess = useAppSelector(state => state.leagueStandard.updateSuccess);
  const handleClose = () => {
    props.history.push('/league-standard' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...leagueStandardEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...leagueStandardEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="leagueApp.leagueStandard.home.createOrEditLabel" data-cy="LeagueStandardCreateUpdateHeading">
            <Translate contentKey="leagueApp.leagueStandard.home.createOrEditLabel">Create or edit a LeagueStandard</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="league-standard-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('leagueApp.leagueStandard.code')}
                id="league-standard-code"
                name="code"
                data-cy="code"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <UncontrolledTooltip target="codeLabel">
                <Translate contentKey="leagueApp.leagueStandard.help.code" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('leagueApp.leagueStandard.name')}
                id="league-standard-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('leagueApp.leagueStandard.manager')}
                id="league-standard-manager"
                name="manager"
                data-cy="manager"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('leagueApp.leagueStandard.avatar')}
                id="league-standard-avatar"
                name="avatar"
                data-cy="avatar"
                type="text"
                validate={{
                  maxLength: { value: 2000, message: translate('entity.validation.maxlength', { max: 2000 }) },
                }}
              />
              <ValidatedField
                label={translate('leagueApp.leagueStandard.classify')}
                id="league-standard-classify"
                name="classify"
                data-cy="classify"
                type="text"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/league-standard" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LeagueStandardUpdate;
