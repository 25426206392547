import dayjs from 'dayjs';

export interface IMonitor {
  id?: number;
  name?: string | null;
  role?: string | null;
  rating?: string | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
}

export const defaultValue: Readonly<IMonitor> = {};
