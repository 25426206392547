import { Avatar, Col, ConfigProvider, Empty, Form, Row, Select, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IFormLeagueName, IFormLeagueStand, IFormListTeam } from 'app/shared/model/record-room.model';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { formatDate, formatDate2, formatHour } from 'app/shared/util/date-utils';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import React, { useEffect } from 'react';
import { translate, Translate } from 'react-jhipster';
import { getLeagueName, getListTeam, getListTeamRecord, getStandard, getYear, resetValue } from '../recordRoom.reducer';

const ConvertResult = ({ totalAwayGoal, totalOwnerGoal, type }: { totalAwayGoal: number; totalOwnerGoal: number; type: boolean }) => {
  if (totalAwayGoal === totalOwnerGoal) {
    return <span>{translate('recordRoom.lookupTeam.draw')}</span>;
  }
  if (totalOwnerGoal > totalAwayGoal && type === true) {
    return <span>{translate('recordRoom.lookupTeam.win')}</span>;
  }
  if (totalOwnerGoal < totalAwayGoal && type === true) {
    return <span>{translate('recordRoom.lookupTeam.lost')}</span>;
  }
  if (totalAwayGoal < totalOwnerGoal && type === false) {
    return <span>{translate('recordRoom.lookupTeam.lost')}</span>;
  }
  if (totalAwayGoal > totalOwnerGoal && type === false) {
    return <span>{translate('recordRoom.lookupTeam.win')}</span>;
  }
};
const ConvertHome = ({ type }: { type: boolean }) => {
  if (type) {
    return <span>{translate('recordRoom.lookupTeam.home')}</span>;
  } else {
    return <span>{translate('recordRoom.lookupTeam.guest')}</span>;
  }
};

const ConvertScore = ({ totalAwayGoal, totalOwnerGoal, type }: { totalAwayGoal: number; totalOwnerGoal: number; type: boolean }) => {
  if (totalOwnerGoal > totalAwayGoal && type === true) {
    return (
      <span>
        {totalOwnerGoal} : {totalAwayGoal}
      </span>
    );
  } else if (totalAwayGoal > totalOwnerGoal && type === false) {
    return (
      <span>
        {totalAwayGoal} : {totalOwnerGoal}
      </span>
    );
  } else if (totalAwayGoal === totalOwnerGoal) {
    return (
      <span>
        {totalOwnerGoal} : {totalAwayGoal}
      </span>
    );
  } else if (totalAwayGoal > totalOwnerGoal && type === true) {
    return (
      <span>
        {totalAwayGoal} : {totalOwnerGoal}
      </span>
    );
  } else if (totalOwnerGoal > totalAwayGoal && type === false) {
    return (
      <span>
        {totalOwnerGoal} : {totalAwayGoal}
      </span>
    );
  }
};
const LookupTeam = () => {
  const [form] = Form.useForm();
  const lsId = Form.useWatch('lsId', form);
  const year = Form.useWatch('year', form);
  const leagueName = Form.useWatch('leagueName', form);
  const dispatch = useAppDispatch();
  const dataStandard: IFormLeagueStand[] = useAppSelector(state => state.recordRoomReducer.dataStandard)?.content;
  const dataLeagueName: IFormLeagueName[] = useAppSelector(state => state.recordRoomReducer.dataLeagueName);
  const dataListTeam: IFormListTeam[] = useAppSelector(state => state.recordRoomReducer.dataListTeam);
  const dataListTeamRecord = useAppSelector(state => state.recordRoomReducer.dataListTeamRecord);
  const dataYear = useAppSelector(state => state.recordRoomReducer.dataYear);
  const columns = [
    {
      title: <Translate contentKey="leagueApp.match.round" />,
      dataIndex: 'matchRound',
      key: 'matchRound',
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupTeam.result">Result</Translate>,
      dataIndex: 'result',
      key: 'result',
      render: (value, record) => (
        <ConvertResult totalAwayGoal={record.totalAwayGoal} totalOwnerGoal={record.totalOwnerGoal} type={record.type} />
      ),
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupTeam.home.guest">Home</Translate>,
      dataIndex: 'home',
      key: 'home',
      render: (value, record) => <ConvertHome type={record.type} />,
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupTeam.team">Team</Translate>,
      dataIndex: 'clubName',
      key: 'clubName',
      width: 200,
      render: (value, record) => (
        <>
          <Avatar src={record?.clubAvatar} style={{ marginRight: 8 }} size="large" shape="circle" />
          <span>{value}</span>
        </>
      ),
    },
    {
      title: <Translate contentKey="recordRoom.lookupTeam.result">Result</Translate>,
      dataIndex: 'resultScores',
      key: 'resultScores',
      render: (value, record) => (
        <ConvertScore totalAwayGoal={record.totalAwayGoal} totalOwnerGoal={record.totalOwnerGoal} type={record.type} />
      ),
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupTeam.date">date</Translate>,
      dataIndex: 'date',
      key: 'date',
      render: (value, record) => formatDate2(record.startMatch),
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupTeam.time">time</Translate>,
      dataIndex: 'time',
      key: 'time',
      render: (value, record) => formatHour(record.startMatch),
      width: 200,
    },
  ];
  useEffect(() => {
    dispatch(
      getStandard({
        page: 0,
        size: 1000,
        body: {},
      })
    );
    dispatch(getYear());
    return () => {
      dispatch(resetValue());
    };
  }, []);
  useEffect(() => {
    if (dataStandard && dataStandard[0]) {
      form.setFieldsValue({
        lsId: dataStandard[0].id,
        year: new Date().getFullYear().toString(),
      });
      dispatch(
        getLeagueName({
          lsId: dataStandard[0].id,
          year: new Date().getFullYear().toString(),
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          const newData: any = res.payload;
          form.setFieldsValue({
            leagueName: newData.data[0]?.id,
          });
          dispatch(getListTeam(newData.data[0].id)).then(value => {
            if (value.meta.requestStatus === 'fulfilled') {
              const newValue: any = value.payload;
              form.setFieldsValue({
                team: newValue.data[0]?.id,
              });
              if (!newValue.data[0]?.id) {
                return;
              } else {
                dispatch(
                  getListTeamRecord({
                    leagueId: newData.data[0]?.id,
                    clubId: newValue.data[0]?.id,
                  })
                );
              }
            }
          });
        }
      });
    }
  }, [dataStandard]);

  const handleChangeYear = (value: string) => {
    if (lsId && value) {
      dispatch(
        getLeagueName({
          lsId,
          year: value,
        })
      );
    }
    form.setFieldsValue({
      leagueName: undefined,
      team: undefined,
    });
    dispatch(resetValue());
  };
  const handleChangeStandard = (value: number) => {
    if (value) {
      form.setFieldsValue({
        year: undefined,
        leagueName: undefined,
        team: undefined,
      });
    }
    dispatch(resetValue());
  };

  const handleChangeLeagueName = (value: string | number) => {
    if (value) {
      dispatch(getListTeam(value));
    }
    form.setFieldsValue({
      team: undefined,
    });
  };

  const handleChangeValue = (value: string | number) => {
    if (value) {
      dispatch(
        getListTeamRecord({
          leagueId: leagueName,
          clubId: value,
        })
      );
    }
  };

  return (
    <div className="management-layout">
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading">
            <Translate contentKey="recordRoom.lookupTeam.title">Record League</Translate>
          </h2>
        </div>
      </div>
      <div>
        <Form form={form} onFinishFailed={values => handleFocusFieldFail(values)} scrollToFirstError name="control-hooks">
          <Row gutter={[32, 32]}>
            <Col xl={6} lg={12} xs={24}>
              <Form.Item name="lsId" label={<Translate contentKey="recordRoom.lookupTeam.standardPrize">Standard Prize</Translate>}>
                  <Select
                    placeholder={translate('recordRoom.placeholder.standardPrize')}
                    style={{ width: '100%' }}
                    onChange={handleChangeStandard}
                    className="custom-prefix-select"
                    suffixIcon={<SelectArrowDown />}
                  >
                    {dataStandard?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
              </Form.Item>
            </Col>
            <Col xl={6} lg={12} xs={24}>
              <Form.Item name="year" label={<Translate contentKey="recordRoom.lookupTeam.year">Year</Translate>}>
                  <Select
                    placeholder={translate('recordRoom.placeholder.year')}
                    disabled={!lsId}
                    style={{ width: '100%' }}
                    onChange={handleChangeYear}
                    className="custom-prefix-select"
                    suffixIcon={<SelectArrowDown />}
                  >
                    {dataYear?.map(item => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
              </Form.Item>
            </Col>
            <Col xl={6} lg={12} xs={24}>
              <Form.Item name="leagueName" label={<Translate contentKey="recordRoom.lookupTeam.leagueName">League Name</Translate>}>
                  <Select
                    placeholder={translate('recordRoom.placeholder.leagueName')}
                    disabled={!year}
                    style={{ width: '100%' }}
                    onChange={handleChangeLeagueName}
                    className="custom-prefix-select"
                    suffixIcon={<SelectArrowDown />}
                  >
                    {dataLeagueName?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
              </Form.Item>
            </Col>
            <Col xl={6} lg={12} xs={24}>
              <Form.Item name="team" label={<Translate contentKey="recordRoom.lookupTeam.team">Team</Translate>}>
                  <Select
                    placeholder={translate('recordRoom.placeholder.team')}
                    disabled={!leagueName}
                    style={{ width: '100%%' }}
                    onChange={handleChangeValue}
                    className="custom-prefix-select"
                    suffixIcon={<SelectArrowDown />}
                  >
                    {dataListTeam?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col span={24}>
            <ConfigProvider renderEmpty={() => <Empty description={translate('common.noDataTable')} />}>
              <Table
                columns={columns}
                loading={false}
                dataSource={dataListTeamRecord}
                pagination={false}
                scroll={{
                  x: 'calc(700px + 50%)',
                }}
              />
            </ConfigProvider>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LookupTeam;
