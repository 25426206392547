import React, { useState, useEffect } from 'react';
import { Translate, translate } from 'react-jhipster';
import { Card, Col, Form, Modal, Radio, Row, Space, Avatar, Button } from 'antd';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import ListRecord from './list-record';
import { sendRecord, unsubscribe } from 'app/config/websocket-middleware';
import { subscribePenaltyShootOut } from 'app/config/websocket-middleware';
import { debounce, get, isNull } from 'lodash';
import { CHANGE_VALUE, SAVE_VALUE, IMatchPenaltyShootOut, OWNER, AWAY, newShootRecord } from './constant.penalty-shoot-out';
import { fetchListPenaltyShootOut } from './penalty-shoot-out.reducer';
import './index.scss';
import { toast } from 'react-toastify';
import { MatchStatus } from 'app/shared/util/constant';
import { APPROVAL_TYPE } from 'app/config/constants';

export const PenaltyShootOut = ({ processTime, onCancel, canChange, open, ...props }) => {
  const dataMatchInfo = useAppSelector(state => state.match.entity);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const defaultDataPenalty = useAppSelector(state => state.PenaltyShootOut);
  const connectSuccess = useAppSelector(state => state.matchRecord?.connectSuccess);
  const matchBasicInfoEntity = useAppSelector(state => state.match.entity);

  const onSubmitForm = data => {
    sendRecord(`/record/match-penalty/create/${dataMatchInfo.id}/${SAVE_VALUE}`, {
      ownerBall: data.clubShoot,
      matchId: dataMatchInfo.id,
      matchPenantyPlayerRecordDTOS: convertFormDataToPenaltyDto(data.countShoot),
      processDate: processTime?.process,
      roundType: processTime?.roundType,
    });
    toast.success(translate('common.saveSuccess'));
    onCancel();
  };

  const sendChangeRecord = () => {
    sendRecord(`/record/match-penalty/create/${dataMatchInfo.id}/${CHANGE_VALUE}`, {
      ownerBall: form.getFieldValue('clubShoot'),
      matchId: dataMatchInfo.id,
      matchPenantyPlayerRecordDTOS: convertFormDataToPenaltyDto(form.getFieldValue('countShoot')),
      processDate: processTime?.process,
      roundType: processTime?.roundType,
    });
  };

  const convertFormDataToPenaltyDto = (arrayShoot = []) => {
    const newArrDto = [];
    arrayShoot.forEach((turnShoot, index) => {
      const ownerData = {
        playerId: turnShoot.ownerPlayer,
        clubId: dataMatchInfo.ownerClubId,
        goal: turnShoot.ownerGoal === true ? 1 : turnShoot.ownerGoal === false ? 0 : null,
        turnNo: index + 1,
      };
      newArrDto.push(ownerData);
      const awayData = {
        playerId: turnShoot.awayPlayer,
        clubId: dataMatchInfo.awayClubId,
        goal: turnShoot.awayGoal === true ? 1 : turnShoot.awayGoal === false ? 0 : null,
        turnNo: index + 1,
      };
      newArrDto.push(awayData);
    });
    return newArrDto;
  };

  useEffect(() => {
    setNewData(defaultDataPenalty);
  }, [defaultDataPenalty]);

  const getMaxTurn = turnArr => {
    let maxTurn = 0;
    for (let i = 0; i < turnArr.length; i++) {
      if (turnArr[i].turnNo > maxTurn) {
        maxTurn = turnArr[i].turnNo;
      }
    }
    if (maxTurn < 5) {
      maxTurn = 5;
    }
    return maxTurn;
  };

  const setNewData = (data: IMatchPenaltyShootOut) => {
    form.setFieldsValue({ clubShoot: data.ownerBall });
    const maxTurn = getMaxTurn(data?.matchPenantyPlayerRecordDTOS || []);
    const countShoot = [];
    for (let index = 0; index < maxTurn; index++) {
      const newObj = { ...newShootRecord };
      if (data?.matchPenantyPlayerRecordDTOS?.length) {
        data.matchPenantyPlayerRecordDTOS.forEach(item => {
          if (index === item.turnNo - 1) {
            const playerClubName = item.clubId === dataMatchInfo.awayClubId ? AWAY : OWNER;
            const playerGoalName = item.clubId === dataMatchInfo.awayClubId ? 'awayGoal' : 'ownerGoal';
            const goalValue = item.goal === 0 ? false : item.goal === 1 ? true : null;
            newObj[playerGoalName] = goalValue;
            newObj[playerClubName] = item.playerId;
          }
        });
      }
      countShoot.push(newObj);
    }
    form.setFieldsValue({ countShoot });
  };
  useEffect(() => {
    dispatch(fetchListPenaltyShootOut(dataMatchInfo.id));
    const subscribe = () => {
      subscribePenaltyShootOut(dataMatchInfo.id, data => {
        setNewData(data);
      });
    };
    connectSuccess && subscribe();
    return unsubscribe;
  }, [connectSuccess]);

  return (
    <Modal
      title={translate('penaltyShootOutRecord.title')}
      className="register-record"
      visible={open}
      width={1000}
      onCancel={onCancel}
      onOk={() => form.submit()}
      footer={[
        <Button key="close" onClick={() => onCancel()}>
          <span>{translate('freeCornerKick.close')}</span>
        </Button>,
        <Button key="save" type="primary" onClick={() => form.submit()} disabled={!canChange}>
          <span>{translate('freeCornerKick.save')}</span>
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onSubmitForm}
        style={{ width: 900, overflow: 'auto' }}
        className="penalty-form"
        onFinishFailed={() => {
          toast.error(translate('penaltyShootOutRecord.pleaseFullFill'));
        }}
      >
        <Row justify="center" style={!canChange ? { pointerEvents: 'none', width: '900px' } : { width: '900px' }}>
          <Col span={24}>
            <Row style={{ alignItems: 'baseline', fontWeight: 500 }}>
              <Col span={4}>
                <Translate contentKey="penaltyShootOutRecord.ballHoldingTeam">Ball holding team</Translate>
              </Col>
              <Col>
                <Form.Item name="clubShoot">
                  <Radio.Group onChange={() => sendChangeRecord()}>
                    <Radio value={1}>
                      <Translate contentKey="penaltyShootOutRecord.homeTeam">Home team</Translate>
                    </Radio>
                    <Radio value={2}>
                      <Translate contentKey="penaltyShootOutRecord.guestTeam">Guest team</Translate>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: '16px' }} align="middle" justify="center" className="penalty-club">
              <Col span={4} />
              <Col span={6}>
                <Avatar src={dataMatchInfo?.ownerBase64Avt} size="large" />
                <span style={{ marginLeft: '12px', textAlign: 'center' }}>{dataMatchInfo?.ownerClubName}</span>
              </Col>
              <Col span={4} style={{ textAlign: 'center' }}>
                <Translate contentKey="penaltyShootOutRecord.score">Score</Translate>
              </Col>
              <Col span={6}>
                <Avatar src={dataMatchInfo?.awayBase64Avt} size="large" />
                <span style={{ marginLeft: '12px', textAlign: 'center' }}>{dataMatchInfo?.awayClubName}</span>
              </Col>
              <Col span={4} style={{ textAlign: 'center' }}>
                <Translate contentKey="penaltyShootOutRecord.score">Score</Translate>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <ListRecord form={form} sendChangeRecord={sendChangeRecord} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PenaltyShootOut;
