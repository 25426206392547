import React, { useState, useEffect } from 'react';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount, translate } from 'react-jhipster';

import { Card, Col, Form, Modal, Radio, Row, Space, Button } from 'antd';
import ListItem from 'app/modules/pick-player/components/list-item';
import { EditOutlined } from '@ant-design/icons';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import MiniMapPosition from 'app/modules/mini-map-position';
import { debounce, get, isNull } from 'lodash';
import { toast } from 'react-toastify';
import { PickItem } from 'app/modules/pick-item';
import { sendRecord } from 'app/config/websocket-middleware';
import { fetchCatePenaltyKick, fetchDetailEventRecord } from 'app/entities/player-free-corner-kick-record/player-free-corner-kick.reducer';
import { urlWs } from 'app/modules/event-list-record/constant.event-list';
import { actionRecord } from 'app/shared/util/constant';
import RecordPlayerInfo from 'app/modules/record-player-info';

interface Props {
  open: boolean;
  onCancel: () => void;
  onEditPosition: () => void;
  onEditPlayer: () => void;
  form?: any;
  id?: number;
  awayAvatars: any;
  ownerAvatars: any;
  processTime: any;
}

export const PlayerFreeCornerKickRecord = ({
  onCancel,
  open,
  onEditPlayer,
  onEditPosition,
  form,
  id,
  awayAvatars,
  ownerAvatars,
  processTime,
}: Props) => {
  const playerInForm = Form.useWatch('player', form);
  const scoreType = Form.useWatch('goalType');
  const watchShootType = Form.useWatch('shootType');
  const dispatch = useAppDispatch();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const [dataDefault, setDataDefault] = useState(null);
  const shootType = [
    { value: 1, label: translate('freeCornerKick.directShootType.shootTarget') },
    { value: 2, label: translate('freeCornerKick.directShootType.normalShoot') },
  ];

  const onSubmitForm = data => {
    if (id) {
      sendRecord(urlWs(actionRecord.PENALTY_KICK, 'edit', data.player?.matchPlayerFieldDTO.matchId, id), {
        ...dataDefault,
        ...data,
        id,
        playerId: data.player?.playerId,
        player: undefined,
        matchId: data.player?.matchPlayerFieldDTO.matchId,
      });
    } else {
      sendRecord(
        `/record/player-penalty/create/${data.player?.matchPlayerFieldDTO.matchId}/${data.player?.matchPlayerFieldDTO.clubId}/${data.player?.matchPlayerFieldDTO.type}`,
        {
          ...data,
          playerId: data.player?.playerId,
          player: undefined,
          matchId: data.player?.matchPlayerFieldDTO.matchId,
          processDate: processTime?.process,
          roundType: processTime?.roundType,
          reasonFail: data?.reasonFail,
          shootType: data?.shootType,
          playerGoalRecordDTO:
            data?.goalType === 1
              ? {
                  shootDirection: data?.shootDirection,
                }
              : {},
        }
      );
    }
    form.resetFields();
    toast.success(translate('common.saveSuccess'));
    onCancel();
  };

  const listShootDirectionKick = useAppSelector(state => state.PlayerFreeCornerKick.listShootDirectionKick);
  const listTypeFailKick = useAppSelector(state => state.PlayerFreeCornerKick.listTypeFailKick);

  const score = [
    { value: 1, label: translate('freeCornerKick.score.success') },
    { value: 0, label: translate('freeCornerKick.score.fail') },
  ];

  useEffect(() => {
    dispatch(fetchCatePenaltyKick(locale));
    if (id) {
      dispatch(fetchDetailEventRecord(`api/player-penanty-records/${id}`)).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          const data = get(res.payload, 'data');
          setDataDefault(data);
          form.setFieldsValue({ ...data });
        }
      });
    }
  }, []);

  const requiredRule = { required: true, message: '' };

  return (
    <Modal
      title={!id ? translate('playerPenaltyKick.title') : translate('playerPenaltyKick.titleEdit')}
      className="register-record"
      visible={open}
      width={1000}
      onCancel={onCancel}
      onOk={() => form.submit()}
      zIndex={1}
      footer={[
        <Button key="close" onClick={() => onCancel()}>
          <span>{translate('freeCornerKick.close')}</span>
        </Button>,
        <Button key="save" type="primary" onClick={() => form.submit()}>
          <span>{translate('freeCornerKick.save')}</span>
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onSubmitForm}
        onFinishFailed={() => {
          toast.error(translate('scoreRecord.message.supportForm'));
        }}
        style={{ width: 764, overflow: 'auto' }}
      >
        <Row gutter={32} justify="start" style={{ width: 780 }} wrap={false}>
          <Col>
            <RecordPlayerInfo
              avatar={
                // playerInForm?.matchPlayerFieldDTO?.playerDTOS?.avatar
                get(awayAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  ? get(awayAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  : get(ownerAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  ? get(ownerAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  : undefined
              }
              prefix={playerInForm?.positionType}
              onEditPlayer={() => {
                onEditPlayer();
              }}
              hideEditPosition
              onEditPosition={() => {
                onEditPosition();
              }}
              player={playerInForm}
              positionMap={form.getFieldValue('detailLocation')}
            />
          </Col>
          <Col>
            <Row wrap={false}>
              <Col className="first-header">
                <PickItem
                  name="shootType"
                  list={shootType}
                  getValue={item => item.value}
                  getLabel={item => item.label}
                  title={'* ' + translate('freeCornerKick.directShootType.shootType')}
                  rules={[requiredRule]}
                />
              </Col>
              <Col style={Number(watchShootType) === 1 ? {} : { display: 'none' }} className="last-header">
                <PickItem
                  name="goalType"
                  list={score}
                  getValue={item => item.value}
                  getLabel={item => item.label}
                  title={'* ' + translate('freeCornerKick.score.title')}
                  rules={Number(watchShootType) === 1 && [requiredRule]}
                />
              </Col>
              <Col style={Number(watchShootType) === 1 && Number(scoreType) === 1 ? {} : { display: 'none' }} className="last-header">
                <PickItem
                  name="shootDirection"
                  list={listShootDirectionKick}
                  getValue={item => item.value}
                  getLabel={item => item.label}
                  title={translate('freeCornerKick.shot')}
                  // rules={Number(watchShootType) === 1 && Number(scoreType) === 1  && [requiredRule]}
                />
              </Col>
              <Col style={Number(watchShootType) === 1 && Number(scoreType) === 0 ? {} : { display: 'none' }} className="last-header">
                <PickItem
                  name="reasonFail"
                  list={listTypeFailKick}
                  getValue={item => item.value}
                  getLabel={item => item.label}
                  title={translate('freeCornerKick.failureReason')}
                  // rules={Number(watchShootType) === 1 && Number(scoreType) === 0 && [requiredRule]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PlayerFreeCornerKickRecord;
