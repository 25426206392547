import { Button, Col, Form, Modal, Row, Input, message, Upload, UploadProps, UploadFile, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { createEntity, getEntity, updateEntity, clearMsgUpdate } from './club-officer.reducer';
import { handleFocusFieldFail, validateSizeImage } from 'app/shared/util/entity-utils';
import SearchModalCustom from 'app/modules/seatch-modal-custom';
import { IClub } from 'app/shared/model/club.model';
import axios from 'axios';
import { first } from 'lodash';
import { IClubOfficer } from 'app/shared/model/club-officer.model';
import dayjs from 'dayjs';
import { getBase64, upLoadImg } from 'app/shared/reducers/reducer.utils';
import { hasAnyAuthority, isRole } from 'app/shared/auth/private-route';
import 'antd/dist/antd.css';
import { AUTHORITIES } from 'app/config/constants';
import { UploadOutlined } from '@ant-design/icons';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { ROLE_STAFF } from 'app/shared/util/constant/role-staffs.constant';
import { ColumnsType } from 'antd/lib/table';
import { RcFile } from 'antd/lib/upload';

export const ClubOfficerAddDialog = (props: any) => {
  const { showDialog, setShowDialog, entityId, setEntityId, updateFilter, idClub } = props;
  const dispatch = useAppDispatch();

  const updateSuccess = useAppSelector(state => state?.clubOfficerReducer?.updateSuccess);
  const createSuccess = useAppSelector(state => state?.clubOfficerReducer?.createSuccess);
  const clubOfficerEntity = useAppSelector(state => state?.clubOfficerReducer?.entity);
  // const officerEntity = useAppSelector(state => state.officerReducer.entity);
  const loading = useAppSelector(state => state.clubOfficerReducer?.loading);
  const [searchClubModal, setSearchClubModal] = useState(false);
  const [previewImg, setPreviewImg] = useState('');
  const [clubs, setClubs] = React.useState<IClub[]>([]);
  const [dataNation, setDataNation] = useState([]);
  const [file, setFile] = useState<File>(null);

  const [fileListCommitment, setFileListCommitment] = useState<UploadFile[]>([]);
  const [isNew, setIsNew] = useState(true);

  const [entitySave, setEntitySave] = useState({
    name: '',
    phone: null,
    homeAddress: null,
    email: null,
    fax: null,
    clubName: '',
    base64FileCommitment: null,
  } as IClubOfficer);
  const { authorities } = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(authorities, [AUTHORITIES.ADMIN]);

  const optionClubManager = [
    {
      value: 'name',
      label: translate('leagueApp.unitStandard.name'),
    },
  ];
  const [teamManager, setTeamManager] = useState([]);
  const columnClubManager = [
    {
      title: <Translate contentKey="leagueApp.unitStandard.name" />,
      dataIndex: 'fullName',
      key: 'fullName',
    },
  ];
  const [form] = Form.useForm();
  const managementId = Form.useWatch('managementId', form);
  const [unitStandard, setUnitStandard] = useState([]);
  const [unitSearchStaffId, setUnitSearchStaffId] = useState([]);
  const [unitLeague, setUnitLeague] = useState([]);

  const propsedFileCommitment: UploadProps = {
    onRemove(filed) {
      const index = fileListCommitment.indexOf(filed);
      const newFileListCommitment = fileListCommitment.slice();
      newFileListCommitment.splice(index, 1);
      setFileListCommitment(newFileListCommitment);
    },
    beforeUpload(filed) {
      if (filed.size / 1024 / 1024 > 10) {
        message.error(<>{translate('leagueApp.player.error.title')}</>);
      } else {
        let newFileListCommitment = [...fileListCommitment, filed];
        newFileListCommitment = newFileListCommitment.slice(-1);
        setFileListCommitment(newFileListCommitment);
      }
      return false;
    },
  };

  const getClubManager = async (searchName?: string) => {
    try {
      const fetchClubs = await axios.get(`api/staffs/assign-club-for-staff?searchName=${searchName || ''}`);
      setTeamManager(fetchClubs?.data);
    } catch (error) {
      setTeamManager([]);
    }
  };
  useEffect(() => {
    const c = !entityId;
    setIsNew(c);
    if (!c) {
      handleUpdate(entityId);
    }
  }, [entityId]);

  useEffect(() => {
    if (updateSuccess) {
      setEntityId(null);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createSuccess) {
      setEntityId(null);
    }
  }, [createSuccess]);

  useEffect(() => {
    if (!entityId) return;
    if (clubOfficerEntity && clubOfficerEntity.id) {
      form.setFieldsValue({
        ...clubOfficerEntity,
        birthday: clubOfficerEntity && clubOfficerEntity.birthday ? dayjs(clubOfficerEntity.birthday) : null,
      });
      setEntitySave({ ...clubOfficerEntity, clubName: clubOfficerEntity.clubName });
    }
    if (clubOfficerEntity.fileCommitment) {
      setFileListCommitment([
        {
          ...fileListCommitment[0],
          url: clubOfficerEntity.base64FileCommitment,
          name: clubOfficerEntity.fileCommitment,
          status: 'done',
        },
      ]);
      setEntitySave({ ...clubOfficerEntity, fileCommitment: clubOfficerEntity.fileCommitment });
    }
  }, [clubOfficerEntity, entityId]);

  const roles = [
    { value: ROLE_STAFF.DEPUTY_DELEGATION + '', label: translate('leagueApp.staff.role.deputyDelegation') },
    { value: ROLE_STAFF.MANAING_DIRECTOR + '', label: translate('leagueApp.staff.role.managingDirector') },
    { value: ROLE_STAFF.TEAM_MANAGER + '', label: translate('leagueApp.staff.role.teamManager') },
  ];
  const unitStandardData = Form.useWatch('unitStandardId', form);
  const unitSearchStaffData = Form.useWatch('staffId', form);
  const unitSearchLeagueData = Form.useWatch('league', form);
  const [searchTeamManager, setSearchTeamManager] = useState(false);
  const columnsUnit: ColumnsType<any> = [
    {
      title: <Translate contentKey="leagueApp.unitStandard.name">Name</Translate>,
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const columnsSearchStaffId: ColumnsType<any> = [
    {
      title: <Translate contentKey="leagueApp.unitStandard.name">Name</Translate>,
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const columnsSearchLeague: ColumnsType<any> = [
    {
      title: <Translate contentKey="leagueApp.unitStandard.name">Name</Translate>,
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const optionsUnit = [
    {
      value: 'name',
      label: translate('leagueApp.unitStandard.name'),
    },
  ];

  const optionsSearchStaffId = [
    {
      value: 'name',
      label: translate('leagueApp.unitStandard.name'),
    },
  ];

  const optionsSearchLeague = [
    {
      value: 'name',
      label: translate('leagueApp.unitStandard.name'),
    },
  ];
  /** save action **/
  const handleValidSubmit = async () => {
    let data: IClubOfficer = {};

    if (!isNew && clubOfficerEntity.id) {
      data = {
        ...clubOfficerEntity,
      };
    }

    if (fileListCommitment.length > 0 && !!fileListCommitment[0].size) {
      const bodyImg = new FormData();
      bodyImg.append('file', fileListCommitment[0] as RcFile);
      bodyImg.append('uploadType', 'COMMITMENT_OFFICER');
      const result = await upLoadImg(bodyImg);
      data.fileCommitment = result.data;
    }

    data.clubId = idClub;
    data.staffId = form.getFieldValue('staffId');
    data.leagueId = form.getFieldValue('leagueId');
    data.role = form.getFieldValue('role');
    data.userId = form.getFieldValue('userId');
    if (!isNew && data.id) {
      dispatch(updateEntity(data));
      updateFilter();
    } else {
      dispatch(createEntity(data));
      updateFilter();
    }
  };

  const [searchUnitStandard, setSearchUnitStandard] = useState(false);
  const [searchStaffId, setSearchStaffId] = useState(false);
  const [searchLeague, setSearchLeague] = useState(false);
  const getUnitStandard = async (unitName?: string) => {
    try {
      const fetchUnitStandard = await axios.post(`api/search-unit-standards?page=0size=1000`, {
        name: unitName || '',
      });
      setUnitStandard(fetchUnitStandard?.data?.content);
    } catch (error) {
      setUnitStandard([]);
    }
  };
  const getSearchStaffId = async (name?: any) => {
    try {
      const fetchUnitSearchStaffId = await axios.post(`api/staffs/search?${name || ''}`, {
        name: name || '',
      });
      setUnitSearchStaffId(fetchUnitSearchStaffId?.data?.content);
    } catch (error) {
      setUnitSearchStaffId([]);
    }
  };

  const getLeague = async (name?: string) => {
    try {
      const fetchUnitLeague = await axios.get(`api/leagues/assign-officer-for-league?searchName=${name || ''}`);
      setUnitLeague(fetchUnitLeague?.data);
    } catch (error) {
      setUnitLeague([]);
    }
  };

  /** update **/
  const handleUpdate = id => {
    dispatch(getEntity(id));
  };

  function closeDialog() {
    setShowDialog(false);
    setEntityId(null);
  }
  const locale = useAppSelector(state => state.locale.currentLocale);
  const handleNationally = async () => {
    const dataNationally = await axios.get<any>(`api/utils/countries?lang=${locale}`);
    const arrNation = [];
    dataNationally.data.map(a =>
      arrNation.push({
        value: a.name,
        label: a.name,
      })
    );
    setDataNation(arrNation);
  };

  useEffect(() => {
    handleNationally();
  }, []);

  return (
    <React.Fragment>
      <Modal
        visible={showDialog}
        onCancel={() => closeDialog()}
        title={
          isNew ? (
            <Translate contentKey="leagueApp.clubOfficer.create.title" />
          ) : (
            <Translate contentKey="leagueApp.clubOfficer.update.dialog" />
          )
        }
        wrapClassName="management-wrapper-modal"
        footer={null}
        width={900}
      >
        <Form
          form={form}
          initialValues={{ ...entitySave }}
          onFinishFailed={values => handleFocusFieldFail(values)}
          scrollToFirstError
          name="control-hooks"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onFinish={handleValidSubmit}
          layout="vertical"
          requiredMark={'optional'}
        >
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item
                name="staffName"
                label={<h6>* {translate('leagueApp.clubOfficer.staffId')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.clubOfficer.required.staffId'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('leagueApp.clubOfficer.placeholder.staffId')}
                  suffix={
                    <Button onClick={() => setSearchStaffId(true)} className="position-absolute" style={{ right: '8px' }}>
                      <Translate contentKey="leagueApp.league.choose" />
                    </Button>
                  }
                ></Input>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                name="unitStandardName"
                label={<h6>* {translate('leagueApp.notice.noticeCategoryArr.organization')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.club.required.organization'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('leagueApp..club.placeholder.organization')}
                  suffix={
                    <Button onClick={() => setSearchUnitStandard(true)} className="position-absolute" style={{ right: '8px' }}>
                      <Translate contentKey="leagueApp.league.choose" />
                    </Button>
                  }
                  readOnly
                ></Input>
              </Form.Item>
            </Col>
            {isAdmin && (
              <Col span={12}>
                <Form.Item
                  name="clubName"
                  label={<h6>* {translate('leagueApp.clubOfficer.clubId')}</h6>}
                  rules={[
                    {
                      required: true,
                      message: translate('leagueApp.clubOfficer.required.clubId'),
                    },
                  ]}
                >
                  <Input
                    placeholder={translate('leagueApp.clubOfficer.placeholder.clubId')}
                    suffix={
                      <Button onClick={() => setSearchTeamManager(true)} className="position-absolute" style={{ right: '8px' }}>
                        <Translate contentKey="leagueApp.league.choose" />
                      </Button>
                    }
                    readOnly
                  ></Input>
                </Form.Item>
              </Col>
            )} */}
            {/* <Col span={12}>
              <Form.Item
                name="leagueName"
                label={<h6>* {translate('leagueApp.clubOfficer.league')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.clubOfficer.required.league'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('leagueApp.clubOfficer.placeholder.league')}
                  suffix={
                    <Button onClick={() => setSearchLeague(true)} className="position-absolute" style={{ right: '8px' }}>
                      <Translate contentKey="leagueApp.league.choose" />
                    </Button>
                  }
                  readOnly
                  // disabled={!!notEdit && isTeamManager}
                ></Input>
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                name="role"
                label={<h6>* {translate('leagueApp.staff.position')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.staff.required.position'),
                  },
                ]}
              >
                <Select
                  placeholder={translate('leagueApp.staff.placeholder.position')}
                  className="custom-prefix-select"
                  allowClear
                  suffixIcon={<SelectArrowDown />}
                >
                  {roles?.map((role, index) => (
                    <Select.Option value={role.value} key={index}>
                      {role.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.staff.fileCommitment')}</h6>}
                name="fileCommitment"
                rules={[{ required: true, message: translate('leagueApp.staff.required.fileCommitment') }]}
              >
                <Upload {...propsedFileCommitment} fileList={fileListCommitment}>
                  <div className="btn_file">
                    <Button
                      style={{
                        display: 'flex',
                        width: '100%',
                        padding: '14px',
                        height: '100%',
                        backgroundColor: 'transparent',
                        color: '#D9D9D9',
                        fontWeight: '300',
                        justifyContent: 'left',
                      }}
                      icon={<UploadOutlined />}
                    >
                      {' '}
                      {translate('leagueApp.clubOfficer.placeholder.fileCommitment')}
                    </Button>
                  </div>
                </Upload>
              </Form.Item>
            </Col>
            <Col style={{ display: 'none' }} span={24}>
              <Form.Item name="unitSearchStaffData">
                <Input type={'hidden'}></Input>
              </Form.Item>
              <Form.Item name="leagueId">
                <Input type={'hidden'}></Input>
              </Form.Item>
              <Form.Item name="userId">
                <Input type={'hidden'}></Input>
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="management-modal-footer">
                <Button
                  color="secondary"
                  onClick={() => {
                    closeDialog();
                  }}
                >
                  <Translate contentKey="register.form.close" />
                </Button>
                <Button
                  loading={loading}
                  key="submit"
                  htmlType="submit"
                  type="primary"
                  style={{ marginLeft: 12 }}
                  disabled={!!clubOfficerEntity.notEdit}
                >
                  <Translate contentKey="settings.form.button" />
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        {searchUnitStandard && (
          <SearchModalCustom
            onClose={() => {
              setSearchUnitStandard(false);
            }}
            options={optionsUnit}
            open={searchUnitStandard}
            list={unitStandard}
            columns={columnsUnit}
            onFetchData={(data: { name?: string }) => getUnitStandard(data?.name)}
            title={translate('leagueApp.unitStandard.home.title')}
            subTitle={translate('leagueApp.unitStandard.chooseUnit')}
            multiple={false}
            onAccept={(selected: any[]) => {
              form.setFieldsValue({ unitStandardName: first(selected)?.name, unitStandardId: first(selected)?.id });
              setSearchUnitStandard(false);
            }}
            selected={[unitStandardData]}
            ignoreOption={true}
          />
        )}
        {searchTeamManager && (
          <SearchModalCustom
            onClose={() => {
              setSearchTeamManager(false);
            }}
            options={optionClubManager}
            open={searchTeamManager}
            list={teamManager}
            columns={columnClubManager}
            onFetchData={(data: { name?: string }) => getClubManager(data?.name)}
            multiple={false}
            onAccept={(selected: any[]) => {
              form.setFieldsValue({
                managementId: selected.map(item => item?.id),
                userId: first(selected)?.id,
                clubName: first(selected)?.fullName,
              });
              setSearchTeamManager(false);
            }}
            title={translate('leagueApp.clubOfficer.clubId')}
            subTitle={translate('leagueApp.clubOfficer.placeholder.clubId')}
            selected={managementId}
            ignoreOption={true}
          />
        )}

        {searchStaffId && (
          <SearchModalCustom
            onClose={() => {
              setSearchStaffId(false);
            }}
            open={searchStaffId}
            list={unitSearchStaffId}
            options={optionsSearchStaffId}
            columns={columnsSearchStaffId}
            onFetchData={(data: { name?: any }) => getSearchStaffId(data?.name)}
            title={translate('leagueApp.clubOfficer.staffId')}
            subTitle={translate('leagueApp.clubOfficer.choose')}
            multiple={false}
            onAccept={(selected: any[]) => {
              form.setFieldsValue({ staffName: first(selected)?.name, staffId: first(selected)?.id });
              setSearchStaffId(false);
            }}
            selected={[unitSearchStaffData]}
            ignoreOption={true}
          />
        )}

        {searchLeague && (
          <SearchModalCustom
            onClose={() => {
              setSearchLeague(false);
            }}
            open={searchLeague}
            list={unitLeague}
            options={optionsSearchLeague}
            columns={columnsSearchLeague}
            onFetchData={(data: { name?: string }) => getLeague(data?.name)}
            title={translate('leagueApp.clubOfficer.league')}
            subTitle={translate('leagueApp.clubOfficer.league')}
            multiple={false}
            onAccept={(selected: any[]) => {
              form.setFieldsValue({ leagueName: first(selected)?.name, leagueId: first(selected)?.id });
              setSearchLeague(false);
            }}
            selected={[unitSearchLeagueData]}
            ignoreOption={true}
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

export default ClubOfficerAddDialog;
