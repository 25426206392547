import React from 'react';
import { translate, Translate } from 'react-jhipster';

interface Props {
  type: number;
}

export const ShirtTypeContainer = ({ type }: Props) => {
  if (type === null || type === 0) {
    return <div></div>;
  }
  if (type === 1) {
    return (
      <div>
        <p>{translate('leagueApp.clubUniform.detail.homeShirt')}</p>
      </div>
    );
  }
  if (type === 2) {
    return (
      <div>
        <p>{translate('leagueApp.clubUniform.detail.awayShirt')}</p>
      </div>
    );
  }
  if (type === 3) {
    return (
      <div>
        <p>{translate('leagueApp.clubUniform.detail.thirdShirt')}</p>
      </div>
    );
  }
  if (type === 4) {
    return (
      <div>
        <p>{translate('leagueApp.clubUniform.shirtType.event')}</p>
      </div>
    );
  }
};
