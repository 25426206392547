import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Col, Input, Select, Tag } from 'antd';
import { useFilter } from 'app/shared/hooks/useFilter';
import { ClubFavoriteListFilter } from './favourite-club.type';
import { getEntities, getEntity } from './favourite-club.reducer';
import dayjs from 'dayjs';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import Filter from 'app/modules/filter';
import { Link, useHistory, useParams } from 'react-router-dom';
import { path } from '../../../../../entities/routes';
import { formatDate2, formatYear } from 'app/shared/util/date-utils';
import '../style.scss';

export const FavoriteClub = () => {
  const dispatch = useAppDispatch();
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
    type: 'name',
  };

  const favClub = useAppSelector(state => state.favouriteClub.entities);
  const loading = useAppSelector(state => state.favouriteClub.loading);
  const { push } = useHistory();
  const handleFetchData = React.useCallback((params: ClubFavoriteListFilter) => {
    dispatch(getEntities({ ...params }));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const ActionButton = ({ id }: { id: number }) => {
    return (
      <div className="column-action-button">
        <Button onClick={() => push(path.clubDetail.replace(':id', id.toString()))} className="w-100 rounded match-table-button">
          <Translate contentKey="leagueApp.match.details" />
        </Button>
      </div>
    );
  };

  const columns = [
    {
      title: '',
      dataIndex: 'base64Avt',
      key: 'base64Avt',
      render: (value, record) => <Avatar shape="square" src={value} size="large" />,
    },
    {
      title: <Translate contentKey="leagueApp.club.name" />,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <Translate contentKey="leagueApp.club.shortName" />,
      dataIndex: 'shortName',
      key: 'shortName',
    },
    {
      title: <Translate contentKey="leagueApp.league.issueYear" />,
      dataIndex: 'issueYear',
      key: 'issueYear',
      render: (value, record) => formatYear(value),
    },
    {
      title: <Translate contentKey="leagueApp.club.managerId" />,
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (value, record) => <ActionButton id={value} />,
      align: 'center' as any,
    },
  ];

  return (
    <div className="table-layout">
      <div>
        <Table
          columns={columns}
          dataSource={favClub?.content}
          totalElements={favClub?.totalElements}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
    </div>
  );
};
