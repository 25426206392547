import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import EditTable from 'app/shared/components/edit-table/edit-table';
import { IErrorUploadTemplateMatch, IFormCreateMatchs, IItemUploadedMatchError } from 'app/shared/model/match.model';
import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { reUploadMatchsEntity } from './league-match.reducer';

type EdittableMatchModalProps = {
  isOpen: boolean;
  onClose: (showUploadList: boolean) => void;
  onChange: (newDate: IItemUploadedMatchError[]) => void;
  data: IItemUploadedMatchError[];
};

const LeagueMatchEdittableModal = (props: EdittableMatchModalProps) => {
  const { isOpen, onClose, data, onChange } = props;
  const paramsPath = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const { uploading } = useAppSelector(state => state.LeagueMatch);

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  const columnData = (field: string, fieldErrors: IErrorUploadTemplateMatch[], record: any) => {
    const errorColumn = fieldErrors?.find(fielItem => fielItem.field === field);

    if (errorColumn) {
      return (
        <div className="d-flex justify-content-start align-items-center">
          <span className="me-2">{record[field]}</span>
          <Tooltip placement="rightTop" title={translate(`error.${errorColumn.errorCodeImport}`)}>
            <ExclamationCircleOutlined className="text-danger" />
          </Tooltip>
        </div>
      );
    } else {
      return <>{record[field]}</>;
    }
  };

  const columns = [
    {
      title: translate('leagueApp.match.required.matchRound'),
      dataIndex: 'matchRound',
      key: 'matchRound',
      width: 200,
      editable: true,
      required: true,
      render(value, record) {
        return columnData('matchRound', record?.errorImport, record);
      },
    },
    {
      title: translate('leagueApp.match.required.matchDate'),
      dataIndex: 'matchDate',
      key: 'matchDate',
      width: 200,
      editable: true,
      required: true,
      render(value, record) {
        return columnData('matchDate', record?.errorImport, record);
      },
    },
    {
      title: translate('leagueApp.match.required.matchHours'),
      dataIndex: 'matchTime',
      key: 'matchTime',
      width: 200,
      required: true,
      editable: true,
      render(value, record) {
        return columnData('matchTime', record?.errorImport, record);
      },
    },
    {
      title: translate('leagueApp.match.homeClub'),
      dataIndex: 'ownerClubName',
      key: 'ownerClubName',
      width: 200,
      editable: true,
      render(value, record) {
        return columnData('ownerClubName', record?.errorImport, record);
      },
    },
    {
      title: translate('leagueApp.match.awayClub'),
      dataIndex: 'awayClubName',
      key: 'awayClubName',
      width: 200,
      editable: true,
      render(value, record) {
        return columnData('awayClubName', record?.errorImport, record);
      },
    },
    {
      title: translate('leagueApp.match.stadiumName'),
      dataIndex: 'stadiumName',
      key: 'stadiumName',
      width: 200,
      editable: true,
      render(value, record) {
        return columnData('stadiumName', record?.errorImport, record);
      },
    },
  ];

  const handleDataChange = newData => {
    onChange && onChange(newData);
  };

  const handleSaveData = () => {
    const params: IFormCreateMatchs = {
      leagueId: Number(paramsPath?.id),
      matchs: dataSource,
      zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      lang: locale,
    };
    dispatch(reUploadMatchsEntity(params)).then((res: any) => {
      if (res?.payload?.data?.length === 0) {
        onClose(true);
        toast.success(translate('leagueApp.match.upload.success'));
      } else if (res?.error) {
        toast.error(translate(`error.${res?.error?.response?.data?.errorKey ? res.error.response.data.errorKey : 'SYSTEM_ERROR'}`));
      }
    });
  };

  return (
    <Modal
      title={<Translate contentKey="leagueApp.match.upload.uploaded" />}
      wrapClassName="management-wrapper-modal pb-4"
      visible={isOpen}
      width={1500}
      onCancel={() => {
        onClose(false);
        setDataSource([]);
      }}
      footer={null}
    >
      <EditTable columns={columns} dataSource={dataSource || []} onChange={handleDataChange} pagination={false} rowKey={'id'} />

      <div className="management-modal-footer">
        <Button key="back" onClick={() => onClose(false)}>
          <Translate contentKey="register.form.close" />
        </Button>
        <Button key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }} onClick={handleSaveData} loading={uploading}>
          <Translate contentKey="settings.form.button" />
        </Button>
      </div>
    </Modal>
  );
};

export default LeagueMatchEdittableModal;
