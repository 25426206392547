import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity } from './league-standard.reducer';

export const LeagueStandardDeleteDialog = (props: any) => {
  const { modalDelete, userClicked, setModalDelete } = props;

  const [loadModal, setLoadModal] = useState(false);
  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(getEntity(props.match.params.id));
  //   setLoadModal(true);
  // }, []);

  const leagueStandardEntity = useAppSelector(state => state.leagueStandard.entity);
  const updateSuccess = useAppSelector(state => state.leagueStandard.updateSuccess);

  const handleClose = () => {
    // props.history.push('/league-standard' + props.location.search);
    setModalDelete(false);
  };

  // useEffect(() => {
  //   if (updateSuccess && loadModal) {
  //     handleClose();
  //     setLoadModal(false);
  //   }
  // }, [updateSuccess]);

  const confirmDelete = () => {
    const obj = {
      leagueStandardId: userClicked,
    };
    dispatch(deleteEntity(obj));
    setModalDelete(false);
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="leagueStandardDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="leagueApp.leagueStandard.delete.question">
        <Translate contentKey="leagueApp.leagueStandard.delete.question">Are you sure you want to delete this LeagueStandard?</Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-leagueStandard" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete2">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LeagueStandardDeleteDialog;
