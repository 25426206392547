import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Col, Input, Select, Tag } from 'antd';
import { useFilter } from 'app/shared/hooks/useFilter';
import { MatchFavoriteListFilter } from './match-favourite.type';
import { getEntities, getEntity } from './match-favourite.reducer';
import dayjs from 'dayjs';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import Filter from 'app/modules/filter';
import { Link, useHistory, useParams } from 'react-router-dom';
import { path } from '../../../../../entities/routes';
import { formatDate2, formatHour, formatYear } from 'app/shared/util/date-utils';
import '../style.scss';
import { MatchStatusContained } from 'app/modules/status-contained';
import { STATUS_MATCH, TYPE_INFORMATION } from 'app/config/constants';
import { resetEntity, saveType } from '../../../../../entities/match/match.reducer';
import { connect, disconnect, unsubscribe } from 'app/config/websocket-middleware';

export const FavoriteMatch = () => {
  const dispatch = useAppDispatch();
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
    type: 'name',
  };

  const favMatch = useAppSelector(state => state.favoriteMatch.entities);
  const loading = useAppSelector(state => state.favoriteMatch.loading);
  const { push } = useHistory();
  const handleFetchData = React.useCallback((params: MatchFavoriteListFilter) => {
    dispatch(getEntities({ ...params }));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const ActionButton = ({ status, startDate, id, flag }: { status: number; startDate: string; id: number; flag?: number }) => {
    if (status === STATUS_MATCH.WAIT_RECORD) {
      if (flag === TYPE_INFORMATION.TYPED) {
        return (
          <div className="column-action-button">
            <Button
              style={{ marginRight: 10 }}
              className="w-100 rounded match-table-button d-flex justify-content-center align-items-center"
              onClick={() => {
                push(`/real-time-recording/${id}/match-record`);
                unsubscribe();
              }}
            >
              <Translate contentKey="leagueApp.match.recordMatch" />
            </Button>
            <Button
              className="w-80 rounded match-table-button d-flex justify-content-center align-items-center"
              onClick={() => push(path.matchPreparation.replace(':id', id.toString()))}
            >
              <Translate contentKey="leagueApp.match.button.edit" />
            </Button>
          </div>
        );
      }
      if (flag === TYPE_INFORMATION.NOT_TYPE) {
        return (
          <Button
            className="w-100 rounded match-table-button d-flex justify-content-center align-items-center"
            onClick={() => push(path.matchPreparation.replace(':id', id.toString()))}
          >
            <Translate contentKey="leagueApp.match.typeInfoBefore" />
          </Button>
        );
      }
    }
    if (status === STATUS_MATCH.RECORDING) {
      return (
        <Button
          className="w-100 rounded match-table-button d-flex justify-content-center align-items-center"
          onClick={() => push(`/real-time-recording/${id}/match-record`)}
        >
          <Translate contentKey="leagueApp.match.recordMatch" />
        </Button>
      );
    }
    if (status === STATUS_MATCH.DETAIL) {
      return (
        <Button
          className="rounded w-100 match-table-button d-flex justify-content-center align-items-center"
          onClick={() => {
            push(`/real-time-recording/${id}/record-table`);
            dispatch(saveType('view'));
            dispatch(resetEntity());
          }}
        >
          <Translate contentKey="leagueApp.match.details" />
        </Button>
      );
    }
    return (
      <Button
        className="rounded w-100 match-table-button d-flex justify-content-center align-items-center"
        onClick={() => {
          push(`/real-time-recording/${id}/record-table`);
          dispatch(saveType('view'));
          dispatch(resetEntity());
        }}
      >
        <Translate contentKey="leagueApp.match.details" />
      </Button>
    );
  };

  const columns = [
    {
      title: <Translate contentKey="leagueApp.match.leagueName" />,
      dataIndex: 'leagueName',
      key: 'leagueName',
      render: (value, record) => (
        <>
          <Avatar src={record.lsBase64Avt} size="large" style={{ marginRight: '12px' }} />
          <span>{record.leagueName}</span>
        </>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.match.numberMatch" />,
      dataIndex: 'countMatch',
      key: 'countMatch',
    },
    {
      title: <Translate contentKey="leagueApp.match.round" />,
      dataIndex: 'matchRound',
      key: 'matchRound',
    },
    {
      title: <Translate contentKey="leagueApp.match.matchDate" />,
      dataIndex: 'startDate',
      key: 'matchDate',
      render: (value, record) => formatDate2(value),
    },
    {
      title: <Translate contentKey="leagueApp.match.matchHour" />,
      dataIndex: 'startDate',
      key: 'matchHour',
      render: (value, record) => formatHour(value),
    },
    {
      title: <Translate contentKey="leagueApp.match.ownerClub" />,
      dataIndex: 'ownerClubName',
      key: 'ownerClubName',
      render: (value, record) => (
        <>
          <Avatar src={record.ownerBase64Avt} size="large" style={{ marginRight: '12px' }} />
          <span>{record.ownerClubName}</span>
        </>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.match.awayClub" />,
      dataIndex: 'awayClubName',
      key: 'awayClubName',
      render: (value, record) => (
        <>
          <Avatar src={record.awayBase64Avt} size="large" style={{ marginRight: '12px' }} />
          <span>{record.awayClubName}</span>
        </>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.match.status" />,
      dataIndex: 'status',
      key: 'status',
      width: 220,
      render: (value, record) => (
        <MatchStatusContained status={value} startDate={record.startDate} flag={record.flag} approval={record?.approval} />
      ),
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (value, record) => <ActionButton status={record.status} startDate={record.startDate} id={value} flag={record.flag} />,
      align: 'center' as any,
    },
  ];

  return (
    <div className="table-layout">
      <div>
        <Table
          columns={columns}
          dataSource={favMatch?.content}
          loading={loading}
          totalElements={favMatch?.totalElements}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
    </div>
  );
};
