import React from 'react';
import { useAppSelector } from 'app/config/store';
import { useRequest } from 'ahooks';
import { matchSchedule } from 'app/modules/dashboard/dashboard.service';
import { Card, Col, Row, Skeleton } from 'antd';
import { translate } from 'react-jhipster';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { ChervonLeft } from 'app/shared/util/appIcon';
import './match-schedule.scss';
import { APP_DDMM_FORMAT, APP_LOCAL_DATETIME_FORMAT, APP_LOCAL_HOUR_FORMAT, STATUS_MATCH } from 'app/config/constants';

export const MatchSchedule = props => {
  const { idLeague } = props;
  const [data, setData] = React.useState(null);
  // const [status, setStatus] = React.useState(1);
  const account = useAppSelector(state => state.authentication.account);
  // const date = dayjs.utc(new Date()).startOf('date').format();
  const date = dayjs(new Date()).startOf('date').toISOString();

  const request = useRequest(matchSchedule, {
    manual: true,
    onSuccess(res) {
      setData(res.data);
    },
    onError(err) {},
  });

  React.useEffect(() => {
    if (idLeague) {
      request.run({
        leagueId: idLeague,
        now: date,
      });
    }
  }, [idLeague]);

  const MatchRate = ({
    status,
    startDate,
    ownerClubName,
    awayClubName,
    ownerGoal,
    awayGoal,
  }: {
    status: number;
    startDate: string;
    ownerClubName: string;
    awayClubName: string;
    ownerGoal: string;
    awayGoal: string;
  }) => {
    if (status === STATUS_MATCH.WAIT_RECORD) {
      return (
        <div style={{ width: 345, textAlign: 'right' }}>
          {ownerClubName} - {awayClubName}
        </div>
      );
    } else {
      return (
        <div style={{ width: 345, textAlign: 'right' }}>
          {ownerClubName} {ownerGoal}vs{awayGoal} {awayClubName}
        </div>
      );
    }
  };

  return (
    <Card
      title={translate('dashboard.matchSchedule')}
      extra={
        <div>
          <Link to={{ pathname: '/match', state: { idLeague } }} className="primary-color">
            <span>{translate('entity.action.detail')}</span> <ChervonLeft />
          </Link>
        </div>
      }
    >
      {request.loading && <Skeleton active />}

      {!request.loading && data && data.length && (
        <ul style={{ marginTop: '10px' }}>
          {data.map((team, index) => (
            <li key={index + 1} style={{ padding: '8px 0px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: 80 }}>
                  <span>{dayjs(team.startDate).format(APP_DDMM_FORMAT)}</span>
                </div>
                <p className="neutral-2" style={{ textAlign: 'center', justifyContent: 'center', fontSize: 14, marginBottom: 0 }}>
                  {dayjs(team.startDate).format(APP_LOCAL_HOUR_FORMAT)}
                </p>
                <MatchRate
                  status={team?.status}
                  startDate={team?.startDate}
                  ownerClubName={team?.ownerClubName}
                  awayClubName={team?.awayClubName}
                  ownerGoal={team?.ownerGoal}
                  awayGoal={team?.awayGoal}
                />
              </div>
            </li>
          ))}
        </ul>
      )}

      {!request.loading && (!data || !data.length) && (
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {translate('dashboard.noData')}
        </div>
        // <Skeleton active />
      )}
    </Card>
  );
};
