import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from 'app/entities/match-basic-info/match-basic-info.reducer';
import {
  getAVAR,
  getDetailReferee,
  getReferee,
  getReviewer,
  getTSG,
  getVAR,
  resetEntity,
  saveReferee,
  setSave,
  updateReferee,
} from 'app/entities/match/match.reducer';
import { IDefaultMatch, IFormDetailReferee } from 'app/shared/model/match.model';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import { handleValidateMessageLanguageChanged } from 'app/shared/util/validate';
import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { useHistory, useParams } from 'react-router-dom';
import './referee.scss';
import TextField from 'app/modules/lm-input';

interface IFormValue {
  label: string;
  value: string | number;
  email?: string;
}
interface IFormProps {
  mainMonitorId?: string | number;
  sub1MonitorId?: string | number;
  sub2MonitorId?: string | number;
  sub3MonitorId?: string | number;
  varId?: string | number;
  avarId?: string | number;
  tsgId?: string | number;
  recordName?: string | number;
  mainMatchMonitor?: string | number;
  evaluateId?: string | number;
  mainMonitorName?: string;
  sub1MonitorName?: string;
  sub2MonitorName?: string;
  sub3MonitorName?: string;
  varName?: string;
  avarName?: string;
  tsgName?: string;
  mainMatchMonitorName?: string;
  evaluateName?: string;
}

export const Referee = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<IFormProps>();
  const mainMonitorId = Form.useWatch('mainMonitorId', form);
  const sub1MonitorId = Form.useWatch('sub1MonitorId', form);
  const sub2MonitorId = Form.useWatch('sub2MonitorId', form);
  const sub3MonitorId = Form.useWatch('sub3MonitorId', form);
  const varId = Form.useWatch('varId', form);
  const avarId = Form.useWatch('avarId', form);
  const tsgId = Form.useWatch('tsgId', form);
  const mainMatchMonitor = Form.useWatch('mainMatchMonitor', form);
  const evaluateId = Form.useWatch('evaluateId', form);
  const arrForm = [mainMonitorId, sub1MonitorId, sub2MonitorId, sub3MonitorId];
  const arrForm2 = [mainMatchMonitor, evaluateId];
  const referee: IFormValue[] = useAppSelector(state => state.match.referee);
  const avar: IFormValue[] = useAppSelector(state => state.match.avar);
  const reviewer: IFormValue[] = useAppSelector(state => state.match.reviewer);
  const varList: IFormValue[] = useAppSelector(state => state.match.var);
  const tsg: IFormValue[] = useAppSelector(state => state.match.tsg);
  const refereeDetail: IFormDetailReferee = useAppSelector(state => state.match.refereeDetail);
  const matchBasicInfoEntity: IDefaultMatch = useAppSelector(state => state.matchBasicInfoReducer.entity);
  const locale = useAppSelector(state => state.locale.currentLocale);

  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
  }, [locale]);
  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(getEntity(id));
    return () => {
      dispatch(setSave(true));
    };
  }, []);

  useEffect(() => {
    if (matchBasicInfoEntity?.leagueId) {
      Promise.all([
        dispatch(getReferee({ leagueId: matchBasicInfoEntity?.leagueId, roles: 1 })),
        dispatch(getVAR({ leagueId: matchBasicInfoEntity?.leagueId, roles: 2 })),
        dispatch(getAVAR({ leagueId: matchBasicInfoEntity?.leagueId, roles: 3 })),
        dispatch(getTSG({ leagueId: matchBasicInfoEntity?.leagueId, roles: 4 })),
        dispatch(getReviewer({ leagueId: matchBasicInfoEntity?.leagueId, roles: 5 })),
      ]);
    }
    if (matchBasicInfoEntity?.matchId) {
      dispatch(getDetailReferee(matchBasicInfoEntity?.matchId));
    }
  }, [matchBasicInfoEntity?.leagueId, matchBasicInfoEntity?.matchId]);
  useEffect(() => {
    if (refereeDetail && Object.keys(refereeDetail).length) {
      form.setFieldsValue({
        ...refereeDetail,
      });
    }
  }, [refereeDetail]);
  const handleSubmit = (value: IFormProps) => {
    const body: IFormDetailReferee = {
      ...value,
      matchId: id,
    };
    if (!refereeDetail.id || refereeDetail.id === 0) {
      dispatch(saveReferee(body)).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(getDetailReferee(id));
        }
      });
    } else {
      body.id = refereeDetail.id;
      dispatch(updateReferee(body));
    }
  };

  return (
    <div className="referee">
      <Form
        form={form}
        layout="vertical"
        onFinishFailed={values => handleFocusFieldFail(values, 'referee')}
        scrollToFirstError
        name="referee"
        onFinish={handleSubmit}
        requiredMark={'optional'}
      >
        <div className="d-flex justify-content-between align-items-center record-layout-header">
          <p className="heading-20-bold neutral-1 mb-0">
            <Translate contentKey="common.referee" />
          </p>
          <Button htmlType="submit">
            <Translate contentKey="common.save" />
          </Button>
        </div>
        <Row gutter={12} style={{ marginBottom: '12px' }}>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            <Form.Item
              name="mainMonitorId"
              label={'* ' + translate('leagueApp.match.referee.referee1')}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.match.referee.mainMonitorId1'),
                },
              ]}
            >
              <Select
                placeholder={translate('leagueApp.match.referee.chooseReferee1')}
                style={{ width: '100%' }}
                className="custom-prefix-select"
                allowClear
                suffixIcon={<SelectArrowDown />}
              >
                <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                {referee
                  ?.filter(item => !arrForm.filter(value => value !== mainMonitorId).includes(item.value))
                  ?.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.match.referee.sub1MonitorId1'),
                },
              ]}
              name="sub1MonitorId"
              label={'* ' + translate('leagueApp.match.referee.assistant11')}
            >
              <Select
                placeholder={translate('leagueApp.match.referee.chooseAssistant11')}
                style={{ width: '100%' }}
                className="custom-prefix-select"
                allowClear
                suffixIcon={<SelectArrowDown />}
              >
                <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                {referee
                  ?.filter(item => !arrForm.filter(value => value !== sub1MonitorId).includes(item.value))
                  ?.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.match.referee.sub2MonitorId1'),
                },
              ]}
              name="sub2MonitorId"
              label={'* ' + translate('leagueApp.match.referee.assistant21')}
            >
              <Select
                placeholder={translate('leagueApp.match.referee.chooseAssistant21')}
                style={{ width: '100%' }}
                className="custom-prefix-select"
                allowClear
                suffixIcon={<SelectArrowDown />}
              >
                <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                {referee
                  ?.filter(item => !arrForm.filter(value => value !== sub2MonitorId).includes(item.value))
                  ?.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.match.referee.sub3MonitorId1'),
                },
              ]}
              name="sub3MonitorId"
              label={'* ' + translate('leagueApp.match.referee.refereeSubstitute1')}
            >
              <Select
                placeholder={translate('leagueApp.match.referee.chooseSubstitute1')}
                style={{ width: '100%' }}
                className="custom-prefix-select"
                allowClear
                suffixIcon={<SelectArrowDown />}
              >
                <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                {referee
                  ?.filter(item => !arrForm.filter(value => value !== sub3MonitorId).includes(item.value))
                  ?.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12} style={{ marginBottom: 12 }}>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            {mainMonitorId === -2 && (
              <Form.Item
                rules={[
                  {
                    required: mainMonitorId === -2 ? true : false,
                    message: translate('leagueApp.match.referee.mainMonitorId'),
                  },
                ]}
                name="mainMonitorName"
              >
                <TextField placeholder={translate('leagueApp.match.referee.refereePlaceholder')} />
              </Form.Item>
            )}
          </Col>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            {sub1MonitorId === -2 && (
              <Form.Item
                rules={[
                  {
                    required: sub1MonitorId === -2 ? true : false,
                    message: translate('leagueApp.match.referee.sub1MonitorId'),
                  },
                ]}
                name="sub1MonitorName"
              >
                <TextField placeholder={translate('leagueApp.match.referee.assistRef1Placeholder')} />
              </Form.Item>
            )}
          </Col>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            {sub2MonitorId === -2 && (
              <Form.Item
                rules={[
                  {
                    required: sub2MonitorId === -2 ? true : false,
                    message: translate('leagueApp.match.referee.sub2MonitorId'),
                  },
                ]}
                name="sub2MonitorName"
              >
                <TextField placeholder={translate('leagueApp.match.referee.assistRef2Placeholder')} />
              </Form.Item>
            )}
          </Col>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            {sub3MonitorId === -2 && (
              <Form.Item
                rules={[
                  {
                    required: sub3MonitorId === -2 ? true : false,
                    message: translate('leagueApp.match.referee.sub3MonitorId'),
                  },
                ]}
                name="sub3MonitorName"
              >
                <TextField placeholder={translate('leagueApp.match.referee.resRefPlaceholder')} />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={12} style={{ marginBottom: '12px' }}>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            <Form.Item
              name="varId"
              label={'* ' + 'VAR'}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.match.referee.varId'),
                },
              ]}
            >
              <Select
                placeholder={translate('leagueApp.match.referee.chooseVar')}
                style={{ width: '100%' }}
                filterOption={true}
                className="custom-prefix-select"
                allowClear
                suffixIcon={<SelectArrowDown />}
              >
                <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                {varList?.map(item => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            <Form.Item
              name="avarId"
              label={'* ' + 'AVAR'}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.match.referee.avarId'),
                },
              ]}
            >
              <Select
                placeholder={translate('leagueApp.match.referee.chooseAVR')}
                style={{ width: '100%' }}
                className="custom-prefix-select"
                allowClear
                suffixIcon={<SelectArrowDown />}
              >
                <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                {avar.map(item => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            <Form.Item
              name="tsgId"
              label={'* ' + translate('leagueApp.match.referee.tsg')}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.match.referee.tsgId1'),
                },
              ]}
            >
              <Select
                placeholder={translate('leagueApp.match.referee.chooseTSG1')}
                style={{ width: '100%' }}
                className="custom-prefix-select"
                allowClear
                suffixIcon={<SelectArrowDown />}
              >
                <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                {tsg.map(item => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            <Form.Item name="recordName" label={<Translate contentKey="leagueApp.match.referee.recorder" />}>
              <Input style={{ width: '100%' }} disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12} style={{ marginBottom: 12 }}>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            {varId === -2 && (
              <Form.Item
                rules={[
                  {
                    required: varId === -2 ? true : false,
                    message: translate('leagueApp.match.referee.varId'),
                  },
                ]}
                name="varName"
              >
                <TextField placeholder={translate('leagueApp.match.referee.varPlaceholder')} />
              </Form.Item>
            )}
          </Col>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            {avarId === -2 && (
              <Form.Item
                rules={[
                  {
                    required: avarId === -2 ? true : false,
                    message: translate('leagueApp.match.referee.avarId'),
                  },
                ]}
                name="avarName"
              >
                <TextField placeholder={translate('leagueApp.match.referee.avarPlaceholder')} />
              </Form.Item>
            )}
          </Col>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            {tsgId === -2 && (
              <Form.Item
                rules={[
                  {
                    required: tsgId === -2 ? true : false,
                    message: translate('leagueApp.match.referee.tsgId'),
                  },
                ]}
                name="tsgName"
              >
                <TextField placeholder={translate('leagueApp.match.referee.tsgPlaceholder')} />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={12} style={{ marginBottom: '12px' }}>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            <Form.Item
              name="mainMatchMonitor"
              label={'* ' + translate('leagueApp.match.referee.mainmonitor1')}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.match.referee.mainMatchMonitor1'),
                },
              ]}
            >
              <Select
                placeholder={translate('leagueApp.match.referee.chooseMonitor1')}
                style={{ width: '100%' }}
                filterOption={true}
                className="custom-prefix-select"
                allowClear
                suffixIcon={<SelectArrowDown />}
              >
                <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                {reviewer
                  ?.filter(item => !arrForm2.filter(value => value !== mainMatchMonitor).includes(item.value))
                  .map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            <Form.Item
              name="evaluateId"
              label={'* ' + translate('leagueApp.match.referee.refereerating1')}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.match.referee.evaluateId1'),
                },
              ]}
            >
              <Select
                placeholder={translate('leagueApp.match.referee.chooseRating1')}
                style={{ width: '100%' }}
                className="custom-prefix-select"
                allowClear
                suffixIcon={<SelectArrowDown />}
              >
                <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                {reviewer
                  ?.filter(item => !arrForm2.filter(value => value !== evaluateId).includes(item.value))
                  .map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12} style={{ marginBottom: 12 }}>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            {mainMatchMonitor === -2 && (
              <Form.Item
                rules={[
                  {
                    required: mainMatchMonitor === -2 ? true : false,
                    message: translate('leagueApp.match.referee.mainMatchMonitor'),
                  },
                ]}
                name="mainMatchMonitorName"
              >
                <TextField placeholder={translate('leagueApp.match.referee.mainMoniPlaceholder')} />
              </Form.Item>
            )}
          </Col>
          <Col xl={6} lg={8} md={12} xs={24} className="box">
            {evaluateId === -2 && (
              <Form.Item
                rules={[
                  {
                    required: evaluateId === -2 ? true : false,
                    message: translate('leagueApp.match.referee.evaluateId'),
                  },
                ]}
                name="evaluateName"
              >
                <TextField placeholder={translate('leagueApp.match.referee.refRatingPlaceholder')} />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};
