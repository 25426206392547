import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import TextField from 'app/modules/lm-input';
import { createEntity, getEntity, updateEntity } from './coach.reducer';
import { handleFocusFieldFail, validateSizeImage } from 'app/shared/util/entity-utils';
import SearchModal from 'app/modules/seatch-modal';
import { IClub } from 'app/shared/model/club.model';
import axios from 'axios';
import { first } from 'lodash';
import DatePicker from 'app/modules/date-picker';
import { ICoach } from 'app/shared/model/coach.model';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import dayjs from 'dayjs';
import { ROLE_COACH } from 'app/shared/util/constant/role.constant';
import { getBase64, upLoadImg } from 'app/shared/reducers/reducer.utils';
import './index.scss';

import 'antd/dist/antd.css';
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { isRole } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export const CoachAddDialog = (props: any) => {
  const { showDialog, setShowDialog, entityId, setEntityId, licenses, updateFilter } = props;
  const dispatch = useAppDispatch();

  const updateSuccess = useAppSelector(state => state.coachReducer.updateSuccess);
  const createSuccess = useAppSelector(state => state.coachReducer.createSuccess);
  const coachEntity = useAppSelector(state => state.coachReducer.entity);
  const loading = useAppSelector(state => state.coachReducer.loading);
  const [searchClubModal, setSearchClubModal] = useState(false);
  const [previewImg, setPreviewImg] = useState('');
  const [teams, setTeams] = React.useState<IClub[]>([]);
  const [dataNation, setDataNation] = useState([]);
  const [file, setFile] = useState<File>(null);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const isTeamManager = isRole(authorities, [AUTHORITIES.TEAM_MANAGER]);
  const options = [
    {
      value: 'name',
      label: translate('leagueApp.leagueClub.detail.title1'),
    },
  ];

  const roles = [
    { value: ROLE_COACH.HEAD_COACH + '', label: translate('leagueApp.coach.role.headCoach') },
    { value: ROLE_COACH.ASSISTANT_COACH + '', label: translate('leagueApp.coach.role.assistantCoach') },
  ];

  const columns = [
    {
      title: <Translate contentKey="leagueApp.leagueClub.detail.title1" />,
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const [isNew, setIsNew] = useState(true);

  const [entitySave, setEntitySave] = useState({
    coachName: '',
    birthday: null,
    license: null,
    nationality: null,
    clubId: null,
    role: null,
  } as ICoach);

  useEffect(() => {
    const c = !entityId;
    setIsNew(c);
    if (!c) {
      handleUpdate(entityId);
    }
  }, [entityId]);

  useEffect(() => {
    if (updateSuccess) {
      setEntityId(null);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createSuccess) {
      setEntityId(null);
    }
  }, [createSuccess]);

  useEffect(() => {
    if (!entityId) return;
    if (coachEntity && coachEntity.id) {
      form.setFieldsValue({
        ...coachEntity,
        birthday: coachEntity && coachEntity.birthday ? dayjs(coachEntity.birthday) : null,
      });
      if (coachEntity.base64FileDegree) {
        setFileList([{ ...fileList[0], url: coachEntity.base64FileDegree, name: coachEntity.fileDegree, status: 'done' }]);
      }

      if (coachEntity.base64FileCommitment) {
        setFileListCommitment([
          { ...fileListCommitment[0], url: coachEntity.base64FileCommitment, name: coachEntity.fileCommitment, status: 'done' },
        ]);
      }

      setEntitySave({ ...coachEntity });
    }
  }, [coachEntity, entityId]);

  const getTeams = async (teamName?: string) => {
    try {
      const fetchClubs = await axios.get<any>(`api/assign-club-for-player?searchName=${teamName || ''}`);
      setTeams(fetchClubs.data);
    } catch (error) {
      setTeams([]);
    }
  };

  /** save action **/

  const handleValidSubmit = async e => {
    let data: ICoach = {};

    if (file) {
      const bodyImg = new FormData();
      bodyImg.append('file', file);
      bodyImg.append('uploadType', 'AVATAR_COACH');
      const result = await upLoadImg(bodyImg);
      data.avatar = result.data;
    }

    if (!isNew && coachEntity.id) {
      data = {
        ...coachEntity,
      };
    }

    if (fileListCommitment.length > 0 && !!fileListCommitment[0].size) {
      const bodyImg = new FormData();
      bodyImg.append('file', fileListCommitment[0] as RcFile);
      bodyImg.append('uploadType', 'COMMITMENT_COACH');
      const result = await upLoadImg(bodyImg);
      data.fileCommitment = result.data;
    }

    if (fileList.length > 0 && !!fileList[0].size) {
      const bodyImg = new FormData();
      bodyImg.append('file', fileList[0] as RcFile);
      bodyImg.append('uploadType', 'DEGREE_COACH');
      const result = await upLoadImg(bodyImg);
      data.fileDegree = result.data;
    }
    data.coachName = form.getFieldValue('coachName');
    data.birthday = form.getFieldValue('birthday');
    data.license = form.getFieldValue('license');
    data.nationality = form.getFieldValue('nationality');
    data.role = form.getFieldValue('role');
    data.email = form.getFieldValue('email');
    data.phone = form.getFieldValue('phone');
    data.idNumber = form.getFieldValue('idNumber');
    data.birthPlace = form.getFieldValue('birthPlace');
    data.homeAddress = form.getFieldValue('homeAddress');
    data.previousClub = form.getFieldValue('previousClub');
    data.teamName = form.getFieldValue('teamName');
    data.clubId = form.getFieldValue('clubId');
    // data.fileDegree = form.getFieldValue('fileDegree');
    // data.fileCommitment = form.getFieldValue('fileCommitment');
    data.avatar = form.getFieldValue('avatar');
    // data.clubId = form.getFieldValue('clubId');
    if (!isNew && data.id) {
      dispatch(updateEntity(data));
      updateFilter();
    } else {
      dispatch(createEntity(data));
      updateFilter();
    }
  };

  const handlePreviewAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileImg = e.target.files[0];
    if (validateSizeImage(fileImg, e)) {
      setFile(fileImg);
      getBase64(fileImg, result => {
        setPreviewImg(result);
      });
    }
  };

  const [form] = Form.useForm<ICoach>();
  const clubId = Form.useWatch('clubId', form);

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };

  /** new **/
  const handleReset = () => {
    setEntitySave({
      coachName: '',
      birthday: null,
      license: null,
      nationality: '',
      clubId: null,
      role: null,
    });
    // form.resetFields();
    form.setFieldsValue({
      coachName: '',
      birthday: null,
      license: null,
      nationality: null,
      clubId: null,
      role: null,
    });
    // dispatch(reset);
    // setShowDialog(true);
    setEntityId(null);
  };

  /** update **/
  const handleUpdate = id => {
    dispatch(getEntity(id));
  };

  function closeDialog() {
    setShowDialog(false);
    setEntityId(null);
  }
  const locale = useAppSelector(state => state.locale.currentLocale);
  const handleNationally = async () => {
    const dataNationally = await axios.get<any>(`api/utils/countries?lang=${locale}`);
    const arrNation = [];
    dataNationally.data.map(a =>
      arrNation.push({
        value: a.name,
        label: a.name,
      })
    );
    setDataNation(arrNation);
  };

  useEffect(() => {
    handleNationally();
  }, []);

  const [fileListCommitment, setFileListCommitment] = useState<UploadFile[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const propsedCommitment: UploadProps = {
    onRemove(filed) {
      const index = fileListCommitment.indexOf(filed);
      const newFileListC = fileListCommitment.slice();
      newFileListC.splice(index, 1);
      setFileListCommitment(newFileListC);
    },
    beforeUpload(filed) {
      if (filed.size / 1024 / 1024 > 10) {
        message.error(<>{translate('leagueApp.coach.error.title')}</>);
      } else {
        let newFileListC = [...fileListCommitment, filed];
        newFileListC = newFileListC.slice(-1);
        setFileListCommitment(newFileListC);
      }
      return false;
    },
  };

  const propsed: UploadProps = {
    onRemove(filed) {
      const index = fileList.indexOf(filed);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload(filed) {
      if (filed.size / 1024 / 1024 > 10) {
        message.error(<>{translate('leagueApp.coach.error.title')}</>);
      } else {
        let newFileList = [...fileList, filed];
        newFileList = newFileList.slice(-1);
        setFileList(newFileList);
      }

      return false;
    },
  };

  return (
    <React.Fragment>
      <Modal
        visible={showDialog}
        onCancel={() => closeDialog()}
        title={
          isNew ? <Translate contentKey="leagueApp.coach.home.createLabel" /> : <Translate contentKey="leagueApp.coach.update.dialog" />
        }
        wrapClassName="management-wrapper-modal"
        footer={null}
        width={900}
      >
        <Form
          form={form}
          initialValues={{ ...entitySave }}
          onFinishFailed={values => handleFocusFieldFail(values)}
          scrollToFirstError
          name="control-hooks"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onFinish={handleValidSubmit}
          layout="vertical"
          requiredMark={'optional'}
        >
          <Col span={24}>
            <Form.Item
              name="avatar"
              label={<h6>* {translate('leagueApp.coach.avatar')}</h6>}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.coach.avatar.required'),
                },
              ]}
            >
              <div>
                <div className="input-width">
                  <label htmlFor="uploadImage" className="upload-file">
                    {previewImg ? (
                      <img src={previewImg} alt="logo" style={{ width: '100%', height: '100%' }} />
                    ) : entityId && coachEntity && coachEntity.id && !!coachEntity.base64Avatar ? (
                      <img src={coachEntity.base64Avatar} alt="logo" style={{ width: '100%', height: '100%' }} />
                    ) : (
                      <span>+ {translate('leagueApp.coach.image')}</span>
                    )}
                  </label>
                  <span style={{ fontSize: '12px' }} className="primary-color">
                    <Translate contentKey="leagueApp.coach.home.uploadimg" />
                  </span>

                  <Input id="uploadImage" value={null} type="file" hidden accept="image/*" onChange={handlePreviewAvatar} />
                </div>
              </div>
            </Form.Item>
          </Col>

          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.coach.name2')}</h6>}
                name="coachName"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.coach.name.required'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.coach.placeholder.coachName')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.coach.birthday')}</h6>}
                name="birthday"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.coach.birthday.required'),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder={translate('leagueApp.placeholder.birthday')}
                  format="YYYY-MM-DD"
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.coach.birthplace')}</h6>}
                name="birthPlace"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.coach.birthplace.required'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.coach.placeholder.birthplace')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.coach.id_number')}</h6>}
                name="idNumber"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.coach.id_number.required'),
                  },
                ]}
              >
                <TextField type="number" placeholder={translate('leagueApp.coach.placeholder.id_number')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.coach.label.clubLabel1')}</h6>}
                name="teamName"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.coach.club1.required'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('leagueApp.coach.placeholder.club1')}
                  suffix={
                    <Button onClick={() => setSearchClubModal(true)} className="position-absolute" style={{ right: '8px' }}>
                      <Translate contentKey="leagueApp.league.choose" />
                    </Button>
                  }
                  readOnly
                  className="position-relative"
                ></Input>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="nationality"
                label={<h6>* {translate('leagueApp.coach.nationality')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.coach.nationality.required'),
                  },
                ]}
              >
                <Select
                  placeholder={translate('global.choose.nation')}
                  className="custom-prefix-select"
                  allowClear
                  suffixIcon={<SelectArrowDown />}
                  showSearch
                  options={dataNation}
                  filterOption={(inputValue: string, option?: any) => {
                    return (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase());
                  }}
                ></Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="role"
                label={<h6>* {translate('leagueApp.coach.role')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.coach.role.required'),
                  },
                ]}
              >
                <Select
                  placeholder={translate('leagueApp.coach.role.choose')}
                  className="custom-prefix-select"
                  allowClear
                  suffixIcon={<SelectArrowDown />}
                >
                  {roles?.map((role, index) => (
                    <Select.Option value={role.value} key={index}>
                      {role.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="email"
                label={<h6>* {translate('leagueApp.coach.email')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.coach.email.required'),
                  },
                ]}
              >
                <TextField type="email" placeholder={translate('leagueApp.coach.placeholder.email')} />
              </Form.Item>
              {/* <Form.Item
                label={<h6>* {translate('leagueApp.coach.email')}</h6>}
                name="email"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.coach.email.required'),
                  },
                ]}
              >
                <TextField type="email" placeholder={translate('leagueApp.coach.placeholder.email')} />
              </Form.Item> */}
            </Col>

            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.coach.phone')}</h6>}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.coach.phone.required'),
                  },
                ]}
              >
                <TextField type="number" placeholder={translate('leagueApp.coach.placeholder.phone')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<h6>{translate('leagueApp.coach.homeAddress')}</h6>}
                name="homeAddress"
                // rules={[
                //   {
                //     required: true,
                //     message: translate('leagueApp.coach.homeAddress.required'),
                //   },
                // ]}
              >
                <TextField placeholder={translate('leagueApp.coach.placeholder.homeAddress')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label={<h6>{translate('leagueApp.coach.previousClub')}</h6>} name="previousClub">
                <TextField placeholder={translate('leagueApp.coach.placeholder.previousClub')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.coach.file_commitment')}</h6>}
                name="fileCommitment"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.coach.file_commitment.required'),
                  },
                ]}
              >
                <Upload {...propsedCommitment} fileList={fileListCommitment}>
                  <div className="btn_file">
                    <Button
                      style={{
                        display: 'flex',
                        width: '100%',
                        padding: '14px',
                        height: '100%',
                        backgroundColor: 'transparent',
                        color: '#D9D9D9',
                        fontWeight: '300',
                        justifyContent: 'left',
                      }}
                      icon={<UploadOutlined />}
                    >
                      {translate('leagueApp.coach.placeholder.file_commitment')}
                    </Button>
                  </div>
                </Upload>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.coach.file_degree')}</h6>}
                name="fileDegree"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.coach.file_degree.required'),
                  },
                ]}
              >
                <Upload {...propsed} fileList={fileList}>
                  <div className="btn_file">
                    <Button
                      style={{
                        display: 'flex',
                        width: '100%',
                        padding: '14px',
                        height: '100%',
                        backgroundColor: 'transparent',
                        color: '#D9D9D9',
                        fontWeight: '300',
                        overflow: 'hidden',
                        justifyContent: 'left',
                      }}
                      icon={<UploadOutlined />}
                    >
                      {translate('leagueApp.coach.placeholder.file_degree')}
                    </Button>
                  </div>
                </Upload>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="license"
                label={<h6>* {translate('leagueApp.coach.license')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.coach.license.required'),
                  },
                ]}
              >
                <Select
                  placeholder={translate('global.choose.license')}
                  className="custom-prefix-select"
                  allowClear
                  suffixIcon={<SelectArrowDown />}
                >
                  {licenses?.map((license, index) => (
                    <Select.Option value={license} key={index}>
                      {license}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <div className="management-modal-footer">
                <Button
                  color="secondary"
                  onClick={() => {
                    closeDialog();
                  }}
                >
                  <Translate contentKey="register.form.close" />
                </Button>
                <Button
                  loading={loading}
                  key="submit"
                  htmlType="submit"
                  type="primary"
                  style={{ marginLeft: 12 }}
                  disabled={entityId && coachEntity && coachEntity.id && !!coachEntity.notEdit && isTeamManager}
                >
                  <Translate contentKey="settings.form.button" />
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        {searchClubModal && (
          <SearchModal
            onClose={() => {
              setSearchClubModal(false);
            }}
            open={searchClubModal}
            list={teams}
            columns={columns}
            options={options}
            onFetchData={(data: { name?: string }) => getTeams(data?.name)}
            title={translate('leagueApp.leagueClub.home.createTitle1')}
            subTitle={translate('leagueApp.leagueClub.home.createTitle1')}
            multiple={false}
            onAccept={(selected: any[]) => {
              form.setFieldsValue({ teamName: first(selected)?.name, clubId: first(selected)?.id });
              setSearchClubModal(false);
            }}
            selected={[clubId]}
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

export default CoachAddDialog;
