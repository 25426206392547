export interface IClubUniform {
  id?: number;
  type?: number | null;
  playerType?: number | null;
  shirtColor?: number | null;
  shortColor?: number | null;
  sockColor?: number | null;
  clubId?: number | null;
  shirtColor2?: number | null;
  shortColor2?: number | null;
  sockColor2?: number | null;
  shirtColorText?: number | null;
  shortColorText?: number | null;
  sockColorText?: number | null;
  shirtColorText2?: number | null;
  shortColorText2?: number | null;
  sockColorText2?: number | null;
  playerId?: number | null;
}

export const defaultValue: Readonly<IClubUniform> = {};
