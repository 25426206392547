import { Form, Input, Button, Modal, Row, Col, Select, TimePicker, ConfigProvider, Empty } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import DatePicker from 'app/modules/date-picker';
import NumberField from 'app/modules/lm-input-number';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import TextField from 'app/modules/lm-input';
import { handleValidateMessageLanguageChanged } from 'app/shared/util/validate';

import { CallLoginIcon, LockLoginIcon, MailLoginIcon, PersonLoginIcon, UserGroupIcon, WorkLoginIcon } from 'app/shared/util/authIcon';
import ToastDialog from 'app/shared/util/toastDialog/ToastDialog';
import { Option } from 'antd/lib/mentions';
import { ModalPrivacy } from 'app/entities/privacy-policy/privacy-modal';
import { ModalPolicy } from 'app/entities/privacy-policy/policy-model';
import { handleRegister, reset } from 'app/modules/account/register/register.reducer';
import { useHistory } from 'react-router-dom';

export const UserManagementAddDialog = (props: any) => {
  const { isOpen, setIsOpen, updateFilter } = props;
  const [passwords, setPasswords] = useState('');
  const dispatch = useAppDispatch();
  const [dataUnit, setDataUnit] = useState([]);
  const [openPolicy, setOpenPolicy] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState<boolean>(false);
  const [toastElement, setToastElement] = React.useState<React.ReactElement>(null);
  const [successElement, setSuccessElement] = React.useState<React.ReactElement>(null);
  const [payload, setPayload] = useState({
    unitStandardId: '',
    authorities: '',
  });
  const history = useHistory();
  const successMessage = useAppSelector(state => state.register.successMessage);
  const errorMessage = useAppSelector(state => state.register.errorMessage);
  const [dataRole, useDataRole] = useState([]);
  const localeWeb = useAppSelector(state => state.locale.currentLocale);

  useEffect(() => {
    if (successMessage) {
      closeDialog();
      setConfirmDialog(false);
      toast.success(translate('userManagement.success'));
      updateFilter();

      // history.push('/login');
    } else if (errorMessage) {
      setToastElement(translate(errorMessage));
      setSuccessElement(null);
      setConfirmDialog(true);
    }
  }, [successMessage, errorMessage]);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );

  useEffect(() => {
    // handleRole(),
    handleUnit();
    setTimeout(() => {
      useDataRole([
        // { value: 'ROLE_LEAGUE_MASTER', label: translate('author.leaguemaster') },
        { value: 'ROLE_TEAM_MANAGER', label: translate('author.teammanager') },
        // { value: 'ROLE_RECORDER', label: translate('author.recorder') },
      ]);
    });
  }, []);

  const handleUnit = async () => {
    const dataUnits = await axios.get<any>(`api/unit-standards/search`);
    const arrUnit = [];
    dataUnits.data.content.map(a =>
      arrUnit.push({
        value: a.id,
        label: a.name,
        key: a.id.toString(),
      })
    );
    payload.unitStandardId = arrUnit[0].value + '';
    setPayload(payload);
    setDataUnit(arrUnit);
  };

  // useEffect(() => {
  //   if (successElement) {
  //     toast.success(translate('leagueApp.club.updated.success'));
  //   }
  // }, [successElement]);

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const handleRegisters = ({ firstPassword, login, fullName, phoneNumber, authorities, unitStandardId }) => {
    const body = {
      password: firstPassword,
      login,
      fullName,
      phoneNumber,
      authorities: [authorities],
      unitStandardId,
    };
    dispatch(handleRegister(body));
  };

  const handleValidSubmit = (data: any) => {
    handleRegisters(data);
    // updateFilter();
  };

  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
  }, [localeWeb]);

  const [form] = Form.useForm();

  function closeDialog() {
    setIsOpen(false);
    setConfirmDialog(false);
    reset();
  }

  return (
    <React.Fragment>
      <Modal
        visible={isOpen}
        wrapClassName="management-wrapper-modal"
        title={<Translate contentKey="register.title">Registration</Translate>}
        onCancel={closeDialog}
        width={900}
        footer={null}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Form
            className="login-form"
            layout="vertical"
            onFinish={handleValidSubmit}
            // onFinishFailed={() => onFinishFail()}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
            requiredMark={'optional'}
            form={form}
          >
            {/* <Row style={{ width: '100%' }} align="middle" justify="center">
              <Col span={24}>
                <h1 style={{ fontWeight: 'bold', fontSize: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Translate contentKey="register.title">Registration</Translate>
                </h1>
              </Col>
              <Col span={24}>
                <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="neutral-3">
                  <Translate contentKey="register.message"></Translate>
                </p>
              </Col>
            </Row> */}
            <Row gutter={[12, 12]} style={{ width: '100%' }}>
              <Col span={12}>
                <Form.Item
                  name="authorities"
                  label={translate('register.role')}
                  rules={[{ required: true, message: translate('global.messages.validate.role.required') }]}
                  style={{
                    marginBottom: 0,
                  }}
                >
                  <Select
                    placeholder={translate('global.form.role.placeholder')}
                    className="custom-prefix-select"
                    allowClear
                    suffixIcon={<WorkLoginIcon />}
                    // options={dataRole}
                  >
                    {dataRole.map(roles => (
                      <Select.Option value={roles.value} key={roles.value}>
                        {roles.label || ''}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <p className="primary-color">{translate('global.form.role.sublabel')}</p>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="fullName"
                  label={translate('userManagement.fullName')}
                  rules={[{ required: true, message: translate('global.messages.validate.name.required') }]}
                  data-cy="fullName"
                >
                  <TextField
                    placeholder={translate('userManagement.detail.allowIP.placeholder.nameClb')}
                    prefix={<PersonLoginIcon />}
                    // isTouched={touchedFields.username}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="unitStandardId"
                  label={translate('register.unit')}
                  rules={[{ required: true, message: translate('global.messages.validate.unit.required') }]}
                  data-cy="unitStandardId"
                >
                  <Select
                    placeholder={translate('register.unit.placeholder')}
                    className="custom-prefix-select"
                    allowClear
                    showSearch
                    filterOption={(input, option) => (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                    suffixIcon={
                      <>
                        <UserGroupIcon />
                      </>
                    }
                  >
                    {dataUnit.map(roles => (
                      <Select.Option value={roles.value} key={roles.value}>
                        {roles.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="login"
                  label={'* ' + translate('global.form.email.label')}
                  rules={[
                    { required: true, message: translate('global.messages.validate.email.required') },
                    {
                      type: 'email',
                      message: translate('global.messages.validate.email.invalid'),
                    },
                  ]}
                  data-cy="login"
                >
                  <TextField
                    placeholder={translate('global.form.email.placeholder')}
                    prefix={<MailLoginIcon />}
                    // isTouched={touchedFields.username}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="firstPassword"
                  label={translate('register.password')}
                  rules={[
                    { required: true, message: translate('global.messages.validate.newpassword.required') },
                    { max: 16, message: translate('global.messages.validate.newpassword.maxlength') },
                    { min: 8, message: translate('global.messages.validate.newpassword.minlength') },
                    {
                      required: true,
                      // pattern: new RegExp(/^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-~`_+=:;"',.?/])/),
                      // pattern: new RegExp(/^(?=.*?[A-Za-z])(?=.*?[0-9])([#?!@$%^&*,.<>()_-])/),
                      pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/),
                      message: translate('global.messages.validate.newpassword.specialCharacter'),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (/^(?=.*[~`+=:;"',.?/|{}[\]<>])/.test(value) === true) {
                          return Promise.reject(new Error(translate('global.messages.validate.newpassword.allowCharacter')));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  data-cy="firstPassword"
                >
                  <Input.Password
                    placeholder={translate('register.password.placeholder')}
                    prefix={<LockLoginIcon />}
                    // isTouched={touchedFields.username}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="secondPassword"
                  label={translate('register.confirmPassword')}
                  dependencies={['firstPassword']}
                  rules={[
                    { required: true, message: translate('global.messages.validate.confirmpassword.required') },
                    { max: 16, message: translate('global.messages.validate.newpassword.maxlength') },
                    { min: 8, message: translate('global.messages.validate.newpassword.minlength') },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('firstPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(translate('global.messages.error.dontmatch')));
                      },
                    }),
                  ]}
                  data-cy="secondPassword"
                >
                  <Input.Password
                    placeholder={translate('global.form.confirmpassword.placeholder')}
                    prefix={<LockLoginIcon />}
                    // isTouched={touchedFields.username}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="phoneNumber"
                  label={translate('register.phoneNumber')}
                  rules={[
                    { required: true, message: translate('global.messages.validate.phonenumber.required') },
                    {
                      pattern: /^[0-9]/,
                      message: translate('register.messages.validate.phonenumber.pattern'),
                    },
                  ]}
                  style={{ marginBottom: 32 }}
                  data-cy="password"
                >
                  <NumberField
                    negative={true}
                    placeholder={translate('global.form.phonenumber.placeholder')}
                    style={{ width: '100%' }}
                    prefix={<CallLoginIcon />}
                    // iconRender={visible => (visible ? <EyeShowLoginIcon /> : <EyeHideLoginIcon />)}
                  />
                </Form.Item>
              </Col>

              {/* <Col span={24}>
              <Button style={{ width: '100%', height: 56 }} disabled>
                <Translate contentKey="register.confirmmsg"></Translate>
              </Button>
            </Col> */}

              <Col span={24}>
                <div className="management-modal-footer">
                  <Button key="back" onClick={closeDialog}>
                    <Translate contentKey="register.form.close" />
                  </Button>
                  <Button key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }}>
                    <Translate contentKey="settings.form.button" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
          <ToastDialog
            open={confirmDialog}
            onOk={() => {
              setConfirmDialog(false);
              if (successElement) {
                history.push('/user-management');
                closeDialog();
              }
            }}
            content={toastElement}
            // buttonText={successElement}
            buttonText={translate('userManagement.error')}
          />

          {/* {confirmDialog && (
            <ConfirmationDialog
              open={confirmDialog}
              message={<h6>{translate('leagueApp.player.duplicate')}</h6>}
              onClose={() => setConfirmDialog(false)}
              // onConfirm={() => handleCreateClub()}
            />
          )} */}
          {openPrivacy && <ModalPrivacy openPrivacy={openPrivacy} setOpenPrivacy={setOpenPrivacy} />}
          {openPolicy && <ModalPolicy openPolicy={openPolicy} setOpenPolicy={setOpenPolicy} />}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default UserManagementAddDialog;
