import dayjs from 'dayjs';

export interface IPlayerAgent {
  id?: number;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
  status?: number | null;
  name?: string;
  phone?: string;
  email?: string;
  fax?: string | null;
  homeAddress?: string | null;
  userId?: number | null;
  clubName?: string | null;
}

export const defaultValue: Readonly<IPlayerAgent> = {};
