import React from 'react';
import { translate, Translate } from 'react-jhipster';

interface Props {
  role: string;
}

export const RoleTypeContainer = ({ role }: Props) => {
  if (  role === '' || role === null || role === undefined ) {
    return <div></div>;
  }
  if (role === "2") {
    return (
      <div>
        <p>{translate('leagueApp.monitor.monitorRole.var')}</p>
      </div>
    );
  }
  if (role === "3") {
    return (
      <div>
        <p>{translate('leagueApp.monitor.monitorRole.ava')}</p>
      </div>
    );
  }
  if (role === "4") {
    return (
      <div>
        <p>{translate('leagueApp.monitor.monitorRole.tsg')}</p>
      </div>
    );
  }
  if (role === "5") {
    return (
      <div>
        <p>{translate('leagueApp.monitor.monitorRole.reviewer')}</p>
      </div>
    );
  }
  if (role === "1") {
    return (
      <div>
        <p>{translate('leagueApp.monitor.monitorRole.referee')}</p>
      </div>
    );
  }
};
