import React, { useState, useEffect } from 'react';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount, translate } from 'react-jhipster';
import { fetchCateRegisterScore } from 'app/entities/player-free-corner-kick-record/player-free-corner-kick.reducer';
import { Card, Col, Form, Modal, Radio, Row, Space, Button } from 'antd';
import { PickItem } from 'app/modules/pick-item';
import { useAppSelector, useAppDispatch } from 'app/config/store';

export const PlayerFreeCornerKickRecord = props => {
  const [listCountTouch, setListCountTouch] = React.useState([]);
  const dispatch = useAppDispatch();
  const listScoreFormKick = useAppSelector(state => state.PlayerFreeCornerKick.listScoreFormKick);
  const nameScoreFormKick = useAppSelector(state => state.PlayerFreeCornerKick.nameScoreFormKick);
  const listShootDirectionKick = useAppSelector(state => state.PlayerFreeCornerKick.listShootDirectionKick);
  const nameShootDirectionKick = useAppSelector(state => state.PlayerFreeCornerKick.nameShootDirectionKick);
  const listTypeShootKick = useAppSelector(state => state.PlayerFreeCornerKick.listTypeShootKick);
  const nameTypeShootKick = useAppSelector(state => state.PlayerFreeCornerKick.nameTypeShootKick);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const rangeList = [
    { value: 1, label: translate('scoreRecord.range.distance') },
    { value: 2, label: translate('scoreRecord.range.near') },
  ];

  const setListCount = () => {
    const listCount = [];
    let i = 1;
    for (i; i < 6; i++) {
      listCount.push({ value: i, label: i });
    }
    listCount.push({ value: i + 1, label: translate('scoreRecord.countTouch.over_6') });
    setListCountTouch(listCount);
  };

  useEffect(() => {
    setListCount();
    dispatch(fetchCateRegisterScore(locale));
  }, []);

  const requiredRule = { required: true, message: '' };

  return (
    <Row {...props} wrap={false}>
      <Col className="first-header">
        <PickItem
          name="shootType"
          list={listTypeShootKick}
          getValue={item => item.value}
          getLabel={item => item.label}
          title={translate('freeCornerKick.shotType')}
          rules={[requiredRule]}
        />
      </Col>
      <Col>
        <PickItem
          name="goalForm"
          list={listScoreFormKick}
          getValue={item => item.value}
          getLabel={item => item.label}
          title={translate('freeCornerKick.scoreForm')}
          rules={[requiredRule]}
        />
      </Col>
      <Col>
        <PickItem
          name="shootDirection"
          list={listShootDirectionKick}
          getValue={item => item.value}
          getLabel={item => item.label}
          title={translate('freeCornerKick.shotDirection')}
          rules={[requiredRule]}
        />
      </Col>
      <Col>
        <PickItem
          name="distance"
          list={rangeList}
          getValue={item => item.value}
          getLabel={item => item.label}
          title={translate('scoreRecord.range.title')}
          rules={[requiredRule]}
        />
      </Col>
      <Col className="last-header">
        <PickItem
          name="count"
          list={listCountTouch}
          getValue={item => item.value}
          getLabel={item => item.label}
          title={translate('scoreRecord.countTouch.title')}
          rules={[requiredRule]}
        />
      </Col>
    </Row>
  );
};

export default PlayerFreeCornerKickRecord;
