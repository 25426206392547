import { Button, Form, Radio, Select, Modal, Row, Col, Input, ConfigProvider, Empty } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CloseIcon, SelectArrowDown } from 'app/shared/util/appIcon';
import { handleValidateMessageLanguageChanged } from 'app/shared/util/validate';
import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { clearMsgCreate, clearMsgUpdate, createEntity, getEntities, getEntity, reset, updateEntity } from './club-uniform.reducer';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import TextField from 'app/modules/lm-input';
import './style.scss';
const Container = styled.span`
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 56px;
  border: 1px solid #bfc9d9;
  border-radius: 10px;

  Input[type='color'] {
    margin-right: 1px;
    -webkit-appearance: none;
    border: none;
    width: auto;
    height: auto;
    cursor: pointer;
    background: none;
    &::-webkit-color-swatch-wrapper {
      padding: 0;
      width: 20px;
      height: 20px;
    }
    &::-webkit-color-swatch {
      border: 1px solid #bfc9d9;
      border-radius: 20px;
      padding: 0;
    }
  }

  Input[type='text'] {
    border: none;
    width: 100%;
    font-size: 16px;
    background-color: white;
    color: black;
  }
  button {
    background: transparent;
    border: none;
    margin-left: auto;
    opacity: '0.5';
    visibility: hidden;
  }
  &:hover {
    button {
      visibility: ${props => (props.value ? 'visible' : 'hidden')};
    }
  }
`;

const ColorPicker = props => {
  return (
    <label className="w-100">
      <Container value={props.value}>
        <Input type="color" {...props} />
        {props.value || <p className="placeholder-color-picker">{props.placeholder}</p>}
        <button
          type="button"
          onClick={e => {
            props.onChange('');
          }}
        >
          <CloseIcon />
        </button>
      </Container>
    </label>
  );
};

export const UniformCreateModal = (props: any) => {
  const { isOpen, setIsOpen, uniformUpdate, setUniformUpdate, updateFilter } = props;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isNew, setIsNew] = useState(true);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const [shirt1, setShirt1] = useState('#FFFFFF');
  const [shirt2, setShirt2] = useState('#FFFFFF');
  const [short1, setShort1] = useState('#FFFFFF');
  const [short2, setShort2] = useState('#FFFFFF');
  const [sock1, setSock1] = useState('#FFFFFF');
  const [sock2, setSock2] = useState('#FFFFFF');

  const [formPlayer, setFormPlayer] = useState({
    type: '',
    playerType: '',
    shirtColor: '',
    shirtColorText: '',
    shortColor: '',
    shortColorText: '',
    sockColor: '',
    sockColorText: '',
    shirtColor2: '',
    shirtColorText2: '',
    shortColor2: '',
    shprtColorText2: '',
    sockColor2: '',
    sockColorText2: '',
  });

  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
  }, [locale]);

  const handleClose = () => {
    setIsOpen(false);
    setUniformUpdate(null);
  };

  const handleChangeShirt1 = e => {
    setShirt1(e.target.value);
  };

  const handleChangeShirt2 = e => {
    setShirt2(e.target.value);
  };

  const handleChangeShort1 = e => {
    setShort1(e.target.value);
  };

  const handleChangeShort2 = e => {
    setShort2(e.target.value);
  };

  const handleChangeSock1 = e => {
    setSock1(e.target.value);
  };

  const handleChangeSock2 = e => {
    setSock2(e.target.value);
  };

  const playerUniformEntity = useAppSelector(state => state.PlayerUniform?.entity);
  const updateSuccess = useAppSelector(state => state.PlayerUniform.updateSuccess);
  const createSuccess = useAppSelector(state => state.PlayerUniform.createSuccess);

  useEffect(() => {
    if (updateSuccess) {
      setIsOpen(false);
      setUniformUpdate(null);
      // sortEntities();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createSuccess) {
      setIsOpen(false);
      setUniformUpdate(null);
      // sortEntities();
    }
  }, [createSuccess]);
  const param = useParams<{ id: string; params: string }>();
  const confirmAccept = () => {
    // handleSubmit()
    handleClose();
  };
  const handleUpdate = id => {
    dispatch(getEntity(id));
    // setIsOpen(false);
  };

  useEffect(() => {
    const c = !uniformUpdate;
    setIsNew(c);
    if (!c) {
      handleUpdate(uniformUpdate);
    }
  }, [uniformUpdate]);

  useEffect(() => {
    if (!uniformUpdate) return;
    if (playerUniformEntity && playerUniformEntity.id) {
      form.setFieldsValue({
        ...playerUniformEntity,
      });
      setFormPlayer({ ...playerUniformEntity });
      // setIsOpen(true);
    }
  }, [playerUniformEntity, uniformUpdate]);

  const handleCreateClub = e => {
    let data: any = {};
    if (!isNew && playerUniformEntity.id) {
      data = {
        ...playerUniformEntity,
      };
    }
    data.type = form.getFieldValue('type');
    data.playerType = form.getFieldValue('playerType');
    data.shirtColorText = form.getFieldValue('shirtColorText');
    data.shortColorText = form.getFieldValue('shortColorText');
    data.sockColorText = form.getFieldValue('sockColorText');
    data.shirtColorText2 = form.getFieldValue('shirtColorText2');
    data.shortColorText2 = form.getFieldValue('shortColorText2');
    data.sockColorText2 = form.getFieldValue('sockColorText2');
    data.clubId = +param.id;
    if (!isNew) {
      dispatch(updateEntity(data));
      updateFilter();
    } else {
      dispatch(createEntity(data));
      updateFilter();
    }
    handleClose();
  };

  // const handleCreateClub = e => {
  //   const data: any = {};
  //   saveData(data);
  //   setIsOpen(false);
  // };

  const shirtType = [
    {
      label: translate('leagueApp.clubUniform.shirtType.home'),
      value: 1,
    },
    {
      label: translate('leagueApp.clubUniform.shirtType.away'),
      value: 2,
    },
    {
      label: translate('leagueApp.clubUniform.shirtType.third'),
      value: 3,
    },
    {
      label: translate('leagueApp.clubUniform.shirtType.event'),
      value: 4,
    },
  ];
  const playerType = [
    {
      label: <span className="neutral-3">{translate('leagueApp.clubUniform.playerSelect.onTheField')}</span>,
      value: 0,
    },
    {
      label: <span className="neutral-3">{translate('leagueApp.clubUniform.playerSelect.GK')}</span>,
      value: 1,
    },
  ];

  const [form] = Form.useForm();

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };

  return (
    <React.Fragment>
      <Modal
        visible={isOpen}
        onCancel={handleClose}
        title={
          isNew ? (
            <Translate contentKey="leagueApp.clubUniform.home.createLabel" />
          ) : (
            <Translate contentKey="leagueApp.clubUniform.home.createOrEditLabel" />
          )
        }
        wrapClassName="management-wrapper-modal"
        footer={null}
        width={900}
      >
        <Form
          form={form}
          onFinishFailed={values => handleFocusFieldFail(values)}
          scrollToFirstError
          name="control-hooks"
          onFinish={handleCreateClub}
          layout="vertical"
          requiredMark={'optional'}
        >
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="type"
                label={<h6>{translate('leagueApp.clubUniform.multiSelect.type')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.clubUniform.required.type'),
                  },
                ]}
              >
                <Select
                  placeholder={translate('leagueApp.clubUniform.placeholder.type')}
                  allowClear
                  className="custom-prefix-select"
                  suffixIcon={<SelectArrowDown />}
                >
                  {shirtType.map((shirtTypes, index) => (
                    <Select.Option value={shirtTypes.value} key={index}>
                      {shirtTypes.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="playerType"
                label={<h6>{translate('leagueApp.clubUniform.multiSelect.playerType')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.clubUniform.required.playerType'),
                  },
                ]}
              >
                <Radio.Group options={playerType}></Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]} className="align-items-end">
            <Col xs={24} md={12}>
              <Form.Item
                name="shirtColorText"
                label={<h6>{translate('leagueApp.clubUniform.multiSelect.shirtColor')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.clubUniform.required.shirtColor'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.clubUniform.placeholder.color1')}></TextField>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="shirtColorText2">
                <TextField placeholder={translate('leagueApp.clubUniform.placeholder.color2')}></TextField>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]} className="align-items-end">
            <Col xs={24} md={12}>
              <Form.Item
                label={<h6>{translate('leagueApp.clubUniform.multiSelect.shortColor')}</h6>}
                name="shortColorText"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.clubUniform.required.shortColor'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.clubUniform.placeholder.color1')}></TextField>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="shortColorText2">
                <TextField placeholder={translate('leagueApp.clubUniform.placeholder.color2')}></TextField>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]} className="align-items-end">
            <Col xs={24} md={12}>
              <Form.Item
                label={<h6>{translate('leagueApp.clubUniform.multiSelect.sockColor')}</h6>}
                name="sockColorText"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.clubUniform.required.sockColor'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.clubUniform.placeholder.color1')}></TextField>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="sockColorText2">
                <TextField placeholder={translate('leagueApp.clubUniform.placeholder.color2')}></TextField>
              </Form.Item>
            </Col>
          </Row>
          <div className="management-modal-footer">
            <Button key="back" onClick={handleClose}>
              <Translate contentKey="register.form.close" />
            </Button>
            <Button key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }}>
              <Translate contentKey="settings.form.button" />
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default UniformCreateModal;
