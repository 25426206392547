import axios from 'axios';
import { createAsyncThunk, current, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IMatch, defaultValue, IFormPostMatch, IFormPlayerFields, IFormReferee, IFormDetailReferee } from 'app/shared/model/match.model';
import { first, get } from 'lodash';
import { toast } from 'react-toastify';
import { translate } from 'react-jhipster';
import { IFormSaveSquad, IPlayerDTOS, IPlayerSquad } from './match.type';
import { AppThunk } from 'app/config/store';
import { PLAYER_IN_MATCH } from 'app/shared/util/constant';

const initialState: EntityState<IMatch> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  type: '',
  createSuccess: false,
  saveSuccess: false,
  referee: [],
  var: [],
  avar: [],
  tsg: [],
  reviewer: [],
  playerFreeOwner: [],
  playerFreeAway: [],
  playerFieldsOwner: [] as IFormPlayerFields[],
  playerReserveOwner: [] as IFormPlayerFields[],
  playerFieldsAway: [] as IFormPlayerFields[],
  playerReserveAway: [] as IFormPlayerFields[],
  listWeather: {},
  listTV: [],
  save: true,
  refereeDetail: {} as IFormDetailReferee,
  saveRecord: true,
  matchBasic: {},
};

const apiUrl = 'api/matches';

// Actions

export const getEntities = createAsyncThunk('match/list-match', async ({ page, size, sort, ...res }: IQueryParams, thunkAPI) => {
  const requestUrl = `${apiUrl}/list-match${sort ? `?page=${page}&size=${size}&sort=${sort}` : '?'}`;
  const result = await axios.post<IMatch[]>(requestUrl, res);
  thunkAPI.dispatch(resetType());
  return result;
});

export const getList = createAsyncThunk('match/match-list/detail', async ({ id }: { id: string }) => {
  const requestUrl = `${apiUrl}/list-match/${id}`;
  return axios.get<IMatch>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'match/list-match',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/list-match${`?page=${0}&size=${20}`}`;

    return axios.post<IMatch[]>(requestUrl, { id });
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'match/create_entity',
  async (entity: IMatch, thunkAPI) => {
    const result = await axios.post<IMatch>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'match/update_entity',
  async (entity: IMatch, thunkAPI) => {
    const result = await axios.put<IMatch>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'match/partial_update_entity',
  async (entity: IMatch, thunkAPI) => {
    const result = await axios.patch<IMatch>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'match/delete_entity',
  async (deleteMatch: any, thunkAPI) => {
    const requestUrl = `${apiUrl}/${deleteMatch?.matchId}`;
    const result = await axios.delete<IMatch>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// get weather
export const getWeather = createAsyncThunk('match/getWeather', async (lang: string) => {
  const result = await axios.get(`/api/category-data-by-code?code=WEATHER&lang=${lang}`);
  return result;
});

// get status
export const getStatus = createAsyncThunk('match/getStatus', async (matchId: number) => {
  const result = await axios.get(`api/matches/reset-status?matchId=${matchId}`);
  return result;
});

// get TV list
export const getListTV = createAsyncThunk('match/getListTV', async (lang: string) => {
  const result = await axios.get(`/api/category-data-by-code?code=TV&lang=${lang}`);
  return result.data.dataTrans;
});

// Created match
export const createMatch = createAsyncThunk('match/createMatch', async (body: IFormPostMatch) => {
  const result = await axios.post('/api/match-basic-infos', body);
  toast.success(translate('leagueApp.match.addSuccessInfo'));
  return result;
});

// Update match

export const updateMatch = createAsyncThunk('match/updateMatch', async (body: IFormPostMatch) => {
  const result = await axios.put(`/api/match-basic-infos/${body.id}`, body);
  toast.success(translate('leagueApp.match.updateSuccess'));
  return result;
});

// Get player in squad
export const getPlayer = createAsyncThunk('match/getPlayer', async (params: number | string) => {
  const result = await axios.post<IPlayerSquad>(`api/player-clubs/getplayer?clubId=${params}`);
  return result;
});
interface IFormSquad {
  matchId: string;
  clubId: string | number;
  playerOrderDTOS: IPlayerDTOS[];
  type: number;
}
// save squad owner

export const saveSquad = createAsyncThunk('match/saveSquadOwner', async (body: IFormSaveSquad) => {
  const result = await axios.post('/api/save-match-player-fields', body);
  toast.success(translate('leagueApp.match.playerListSuccess'));
  return result;
});

// get player

interface IFormGetPlayerType {
  matchId: string | number;
  clubId: string | number;
  type?: number;
}

interface IFormGetPlayers {
  ownerClubId?: number | string;
  awayClubId?: number | string;
  id?: number | string;
}

export const getPlayers =
  (body: IFormGetPlayers): AppThunk =>
  async dispatch => {
    if (body.ownerClubId) {
      await dispatch(
        getPlayerFieldsOwner({
          matchId: body.id,
          clubId: body.ownerClubId,
          type: PLAYER_IN_MATCH.MAIN,
        })
      );
      await dispatch(
        getPlayerReserveOwner({
          matchId: body.id,
          clubId: body.ownerClubId,
          type: PLAYER_IN_MATCH.SUB,
        })
      );
      await dispatch(
        getPlayerFreeOwner({
          clubId: body.ownerClubId,
          matchId: body.id,
        })
      );
    }
    if (body.awayClubId) {
      await dispatch(
        getPlayerFieldsAway({
          matchId: body.id,
          clubId: body.awayClubId,
          type: PLAYER_IN_MATCH.MAIN,
        })
      );
      await dispatch(
        getPlayerReserveAway({
          matchId: body.id,
          clubId: body.awayClubId,
          type: PLAYER_IN_MATCH.SUB,
        })
      );
      await dispatch(
        getPlayerFreeAway({
          clubId: body.awayClubId,
          matchId: body.id,
        })
      );
    }
  };

const getPlayerFieldsOwner = createAsyncThunk('match/getPlayerFieldsOwner', async (body: IFormGetPlayerType) => {
  const result = axios.post<IFormPlayerFields[]>('/api/get-match-player-fields', body);
  return result;
});
const getPlayerReserveOwner = createAsyncThunk('match/getPlayerReserveOwner', async (body: IFormGetPlayerType) => {
  const result = axios.post<IFormPlayerFields[]>('/api/get-match-player-fields', body);
  return result;
});
const getPlayerFieldsAway = createAsyncThunk('match/getPlayerFieldsAway', async (body: IFormGetPlayerType) => {
  const result = axios.post<IFormPlayerFields[]>('/api/get-match-player-fields', body);
  return result;
});
const getPlayerReserveAway = createAsyncThunk('match/getPlayerReserveAway', async (body: IFormGetPlayerType) => {
  const result = axios.post<IFormPlayerFields[]>('/api/get-match-player-fields', body);
  return result;
});
interface IFormPlayerFree {
  matchId: number | string;
  clubId: number | string;
}

// get player free owner
export const getPlayerFreeOwner = createAsyncThunk('match/getPlayerFreeOwner', async (body: IFormPlayerFree) => {
  const result = await axios.post('/api/match-player-fields/get-player-free', body);
  const newData = result.data.map(item => {
    return {
      id: item.id,
      koName: item.koName,
      uniformNumber: item.uniformNumber,
      position: item.position,
    };
  });
  return newData;
});
// get player free away
export const getPlayerFreeAway = createAsyncThunk('match/getPlayerFreeAway', async (body: IFormPlayerFree) => {
  const result = await axios.post('/api/match-player-fields/get-player-free', body);
  const newData = result.data.map(item => {
    return {
      id: item.id,
      koName: item.koName,
      uniformNumber: item.uniformNumber,
      position: item.position,
    };
  });
  return newData;
});

// get player before match
export const getPlayerBeforeMatch =
  (body: IFormGetPlayers): AppThunk =>
  async dispatch => {
    if (body.ownerClubId) {
      await dispatch(
        getPlayerFieldsOwnerBeforeMatch({
          clubId: body.ownerClubId,
          matchId: body.id,
          type: PLAYER_IN_MATCH.MAIN,
        })
      );
      await dispatch(
        getPlayerReserveOwnerBeforeMatch({
          clubId: body.ownerClubId,
          matchId: body.id,
          type: PLAYER_IN_MATCH.SUB,
        })
      );
    }
    if (body.awayClubId) {
      await dispatch(
        getPlayerFieldsAwayBeforeMatch({
          clubId: body.awayClubId,
          matchId: body.id,
          type: PLAYER_IN_MATCH.MAIN,
        })
      );
      await dispatch(
        getPlayerReserveAwayBeforeMatch({
          clubId: body.awayClubId,
          matchId: body.id,
          type: PLAYER_IN_MATCH.SUB,
        })
      );
    }
  };

const getPlayerFieldsOwnerBeforeMatch = createAsyncThunk('match/getPlayerFieldsOwnerBeforeMatch', async (body: IFormGetPlayerType) => {
  const result = await axios.post(`/api/match-player-field-squads/get-before-match?matchId=${body.matchId}&clubId=${body.clubId}`);
  const newArr = result.data
    .map(item => {
      if (!item.banCard || item.banCard === 1) {
        return item;
      }
    })
    .filter(value => value !== undefined);
  return newArr;
});
const getPlayerFieldsAwayBeforeMatch = createAsyncThunk('match/getPlayerFieldsAwayBeforeMatch', async (body: IFormGetPlayerType) => {
  const result = await axios.post(`/api/match-player-field-squads/get-before-match?matchId=${body.matchId}&clubId=${body.clubId}`);
  const newArr = result.data
    .map(item => {
      if (!item.banCard || item.banCard === 1) {
        return item;
      }
    })
    .filter(value => value !== undefined);
  return newArr;
});
const getPlayerReserveOwnerBeforeMatch = createAsyncThunk('match/getPlayerReserveOwnerBeforeMatch', async (body: IFormGetPlayerType) => {
  const result = await axios.post(`/api/match-player-field-squads/get-reserve-player?matchId=${body.matchId}&clubId=${body.clubId}`);
  const newData = result.data
    .map(item => {
      if (item?.matchPlayerFieldDTO?.originType === 0) {
        return item;
      }
    })
    .filter(value => !!value);
  return newData;
});
const getPlayerReserveAwayBeforeMatch = createAsyncThunk('match/getPlayerReserveAwayBeforeMatch', async (body: IFormGetPlayerType) => {
  const result = await axios.post(`/api/match-player-field-squads/get-reserve-player?matchId=${body.matchId}&clubId=${body.clubId}`);
  const newData = result.data
    .map(item => {
      if (item?.matchPlayerFieldDTO?.originType === 0) {
        return item;
      }
    })
    .filter(value => !!value);
  return newData;
});

// get referee
interface IFormGetReferee {
  leagueId: number | string;
  roles: number;
}
export const getReferee = createAsyncThunk('match/getReferee', async ({ roles, leagueId }: IFormGetReferee) => {
  const result = await axios.get<IFormReferee[]>(`/api/monitors/find-monitor-by-role?role=${roles}&leagueId=${leagueId}`);
  const data = result.data.map(item => {
    return {
      label: `${item.name}`,
      value: item.id,
      email: item?.email,
    };
  });
  return data;
});

export const getVAR = createAsyncThunk('match/getVAR', async ({ roles, leagueId }: IFormGetReferee) => {
  const result = await axios.get<IFormReferee[]>(`/api/monitors/find-monitor-by-role?role=${roles}&leagueId=${leagueId}`);
  const data = result.data.map(item => {
    return {
      label: `${item.name}`,
      value: item.id,
      email: item?.email,
    };
  });
  return data;
});

export const getAVAR = createAsyncThunk('match/getAVAR', async ({ roles, leagueId }: IFormGetReferee) => {
  const result = await axios.get<IFormReferee[]>(`/api/monitors/find-monitor-by-role?role=${roles}&leagueId=${leagueId}`);
  const data = result.data.map(item => {
    return {
      label: `${item.name}`,
      value: item.id,
      email: item?.email,
    };
  });
  return data;
});

export const getTSG = createAsyncThunk('match/getTSG', async ({ roles, leagueId }: IFormGetReferee) => {
  const result = await axios.get<IFormReferee[]>(`/api/monitors/find-monitor-by-role?role=${roles}&leagueId=${leagueId}`);
  const data = result.data.map(item => {
    return {
      label: `${item.name}`,
      value: item.id,
      email: item?.email,
    };
  });
  return data;
});

export const getReviewer = createAsyncThunk('match/getReviewer', async ({ roles, leagueId }: IFormGetReferee) => {
  const result = await axios.get<IFormReferee[]>(`/api/monitors/find-monitor-by-role?role=${roles}&leagueId=${leagueId}`);
  const data = result.data.map(item => {
    return {
      label: `${item.name}`,
      value: item.id,
      email: item?.email,
    };
  });
  return data;
});

// get detail referee

export const getDetailReferee = createAsyncThunk('match/getDetailReferee', async (id: string | number) => {
  const result = await axios.get(`/api/match-monitorings/detail/${id}`);
  return result;
});

// save referee
export const saveReferee = createAsyncThunk('match/saveReferee', async (body: IFormDetailReferee) => {
  const result = await axios.post('/api/match-monitorings', body);
  toast.success(translate('leagueApp.match.createSuccess'));
  return result;
});

// update detail referee
export const updateReferee = createAsyncThunk('match/updateReferee', async (body: IFormDetailReferee) => {
  const result = await axios.put(`/api/match-monitorings/${body.id}`, body);
  toast.success(translate('leagueApp.match.updateSuccess'));
  return result;
});

export const addFavourite = createAsyncThunk(
  'match/add_favourite',
  async (id: any) => {
    const favouriteApi = await axios.post('api/user-match-favourites', id);
    return favouriteApi;
  },
  { serializeError: serializeAxiosError }
);

export const deleteFavourite = createAsyncThunk(
  'match/add_favourite',
  async (id: any) => {
    const favouriteApi = await axios.delete(`api/user-match-favourites/${id}`);
    return favouriteApi;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const MatchSlice = createEntitySlice({
  name: 'match',
  initialState,
  reducers: {
    saveType(state, action) {
      state.type = action.payload;
    },
    resetType(state) {
      state.type = '';
    },
    resetEntity(state) {
      state.entity = defaultValue;
    },
    resetStatus(state) {
      state.saveSuccess = false;
    },
    resetStatusRecord(state) {
      state.saveRecord = false;
    },
    setSave(state, action) {
      state.save = action.payload;
    },
    setMatchInfo(state, action) {
      state.entity = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createMatch.fulfilled, (state, action) => {
        state.createSuccess = true;
        state.save = true;
        state.matchBasic = action.payload;
      })
      .addCase(createMatch.rejected, state => {
        state.save = false;
      })
      .addCase(updateMatch.fulfilled, state => {
        state.save = true;
      })
      .addCase(saveReferee.fulfilled, state => {
        state.save = true;
      })
      .addCase(updateReferee.fulfilled, state => {
        state.save = true;
      })
      .addCase(getPlayerFreeOwner.fulfilled, (state, action) => {
        state.playerFreeOwner = action.payload;
      })
      .addCase(getPlayerFreeAway.fulfilled, (state, action) => {
        state.playerFreeAway = action.payload;
      })
      .addCase(saveSquad.fulfilled, state => {
        state.saveSuccess = true;
        state.saveRecord = true;
      })
      .addCase(getReferee.fulfilled, (state, action) => {
        state.referee = action.payload;
      })
      .addCase(getVAR.fulfilled, (state, action) => {
        state.var = action.payload;
      })
      .addCase(getAVAR.fulfilled, (state, action) => {
        state.avar = action.payload;
      })
      .addCase(getTSG.fulfilled, (state, action) => {
        state.tsg = action.payload;
      })
      .addCase(getReviewer.fulfilled, (state, action) => {
        state.reviewer = action.payload;
      })
      .addCase(getDetailReferee.fulfilled, (state, action) => {
        state.refereeDetail = action.payload.data;
      })
      .addCase(getPlayerFieldsOwner.fulfilled, (state, action) => {
        state.playerFieldsOwner = action.payload.data;
      })
      .addCase(getPlayerFieldsOwnerBeforeMatch.fulfilled, (state, action) => {
        state.playerFieldsOwner = action.payload;
      })
      .addCase(getPlayerReserveOwner.fulfilled, (state, action) => {
        state.playerReserveOwner = action.payload.data;
      })
      .addCase(getPlayerReserveOwnerBeforeMatch.fulfilled, (state, action) => {
        state.playerReserveOwner = action.payload;
      })
      .addCase(getPlayerFieldsAway.fulfilled, (state, action) => {
        state.playerFieldsAway = action.payload.data;
      })
      .addCase(getPlayerFieldsAwayBeforeMatch.fulfilled, (state, action) => {
        state.playerFieldsAway = action.payload;
      })
      .addCase(getPlayerReserveAway.fulfilled, (state, action) => {
        state.playerReserveAway = action.payload.data;
      })
      .addCase(getPlayerReserveAwayBeforeMatch.fulfilled, (state, action) => {
        state.playerReserveAway = action.payload;
      })
      .addCase(getWeather.fulfilled, (state, action) => {
        state.listWeather = action.payload.data;
      })
      .addCase(getStatus.fulfilled, (state, action) => {
        state.getStatus = action.payload.data;
      })
      .addCase(getListTV.fulfilled, (state, action) => {
        state.listTV = action.payload.concat([
          { categoryDataId: 16, id: 1, lang: 'en', name: translate('leagueApp.match.directInputValue'), status: 1 },
          // { categoryDataId: 16, id: 2, lang: 'en', name: translate('leagueApp.match.noBroadCastValue'), status: 1 },
        ]);
      })
      .addMatcher(isPending(getEntities), (state, action) => {
        return {
          ...state,
          entities: undefined,
        };
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntity), (state, action) => {
        const data: any = first(get(action.payload.data, 'content'));
        return {
          ...state,
          loading: false,
          entity: data,
        };
      });
  },
});

export const { reset, saveType, resetType, resetEntity, resetStatus, setSave, resetStatusRecord } = MatchSlice.actions;

// Reducer
export default MatchSlice.reducer;
