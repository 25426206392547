import { toast } from 'react-toastify';
import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { ITurnShoot, IMatchPenaltyShootOut } from './constant.penalty-shoot-out';

const initialState = {
  loading: false,
  errorMessage: null,
  matchId: null,
  ownerBall: null,
  matchPenantyPlayerRecordDTOS: [],
};

const API_GET_PENALTY_RECORD = 'api/match-penalty-records/get-penalty';

export const fetchListPenaltyShootOut = createAsyncThunk('penaltyShootOut/fetch_list_penalty_shoot_out', async (id: number) => {
  const requestUrl = `${API_GET_PENALTY_RECORD}?matchId=${id}`;
  return axios.get<IMatchPenaltyShootOut>(requestUrl);
});

export const PenaltyShootOut = createSlice({
  name: 'matchRecord',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(isPending(fetchListPenaltyShootOut), state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addMatcher(isFulfilled(fetchListPenaltyShootOut), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          ownerBall: data.ownerBall,
          matchId: data.matchId,
          matchPenantyPlayerRecordDTOS: data.matchPenantyPlayerRecordDTOS,
        };
      });
  },
});

// Reducer
export default PenaltyShootOut.reducer;
