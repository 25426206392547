import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
  ISearchObjectParams,
  Pageable,
} from 'app/shared/reducers/reducer.utils';
import { IFormCreateNewClub, IFormDataClub } from 'app/shared/model/manager-club.model';
import { IPlayer, defaultValue, IFileTemplatePlayer, IFormCreatePlayers, IErrorUploadTemplatePlayer } from 'app/shared/model/player.model';
import { toast } from 'react-toastify';
import { translate } from 'react-jhipster';
import { IUnitStandard } from 'app/shared/model/unit-standard.model';
import { downloadFileFromRes } from 'app/shared/util/helper';

interface PlayerState extends EntityState<IPlayer> {
  organizations: { value: number; label: string }[];
  uploading: boolean;
  exporting: boolean;
  downloading: boolean;
  playerUploadedErr?: IErrorUploadTemplatePlayer[];
}

const initialState: PlayerState = {
  loading: false,
  uploading: false,
  exporting: false,
  downloading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  links: null,
  dataUploadFile: null,
  deletePlayerSuccess: '',
  createPlayerSuccess: '',
  createPlayerFail: '',
  playerUploadedErr: [],
  organizations: [],
};

// const initState = {
//   dataClub: {} as IFormDataClub,
// };

const apiUrl = 'api/players';
const apiImageUrl = 'api/file';
const getApi = 'api/players/search';
const reImportPlayersUrl = '/api/players/re-import';
const importPlayersUrl = '/api/players/import';
const exportPlayersUrl = '/api/players/export';
const dowloadTemplatePlayersUrl = '/api/players/download-template';

// Actions

export const getEntities = createAsyncThunk('player/fetch_entity_list', async ({ page, size, sort, ...res }: IQueryParams) => {
  const requestUrl = `${getApi}${sort ? `?page=${page}&size=${size}&sort=${sort}` : '?'}`;
  return axios.post<IPlayer[]>(requestUrl, res);
});

export const getAvatarPlayer = createAsyncThunk('club/leagueAttend', async ({ fileName, uploadType }: any) => {
  const requestUrl = `${apiImageUrl}/get-file-minio${fileName ? `?fileName=${fileName}&uploadType=${uploadType}` : ''}`;
  return axios.get<any>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'player/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IPlayer>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const upLoadImg = createAsyncThunk('player/upLoadImg?uploadType=AVATAR_PLAYER', async (body: FormData) => {
  const uploadResult = await axios.post<any>(`api/file/upload-file?uploadType=AVATAR_PLAYER`, body);
  return uploadResult;
});

export const createEntity = createAsyncThunk(
  'player/create_entity',
  async (entity: IPlayer, thunkAPI) => {
    const result = await axios.post<IPlayer>(apiUrl, cleanEntity(entity)).catch(err => {
      if (err.response.data.title === 'Shirt number already exists in the club!') {
        toast.error(translate('leagueApp.required.shirtExist'));
      }
      throw Error(err.response);
    });
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const checkDuplicate = createAsyncThunk('player/check_duplicate', async ({ name, birthDate, playerId }: IQueryParams) => {
  const requestUrl = playerId
    ? `api/players/duplicate?name=${name}&birthDate=${birthDate}&playerId=${playerId}`
    : `api/players/duplicate?name=${name}&birthDate=${birthDate}`;
  return axios.get<IPlayer>(requestUrl);
});

export const getOrganization = createAsyncThunk(
  'player/get-organization',
  async () => {
    const result = await axios.get<Pageable<IUnitStandard>>(`api/unit-standards/search`);
    return result.data;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'player/update_entity',
  async (entity: IPlayer, thunkAPI) => {
    const result = axios.put<IPlayer>(`${apiUrl}/${entity.id}`, entity);
    thunkAPI.dispatch(getEntities({}));
    result.then().catch(err => {
      if (err.response.data.title === 'Shirt number already exists in the club!') {
        toast.error(translate('leagueApp.required.shirtExist'));
      }
    });
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'player/partial_update_entity',
  async (entity: IPlayer, thunkAPI) => {
    const result = await axios.patch<IPlayer>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'player/delete_entity',
  async (playerId: any, thunkAPI) => {
    const requestUrl = `${apiUrl}/${playerId}`;
    const result = await axios.delete<IPlayer>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const searchEntities = createAsyncThunk('monitor/search_entity_list', async ({ page, size, sort, payload }: ISearchObjectParams) => {
  const requestUrl = `${getApi}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}`;
  return axios.post<IPlayer[]>(requestUrl, payload);
});

export const addFavourite = createAsyncThunk(
  'player/add_favourite',
  async (id: any) => {
    const favouriteApi = await axios.post('api/user-player-favourites', id);
    return favouriteApi;
  },
  { serializeError: serializeAxiosError }
);

export const deleteFavourite = createAsyncThunk(
  'player/add_favourite',
  async (id: any) => {
    const favouriteApi = await axios.delete(`api/user-player-favourites/${id}`);
    return favouriteApi;
  },
  { serializeError: serializeAxiosError }
);

export const downloadAttachFileEntity = createAsyncThunk(
  'player/download_attach_file',
  async (entity: IFileTemplatePlayer) => {
    const requestUrl = `${dowloadTemplatePlayersUrl}?type=${entity.type}&lang=${entity.lang}&zoneId=${entity.zoneId}`;
    const result = (await axios.post<IFileTemplatePlayer>(requestUrl, entity, {
      responseType: 'blob',
    })) as any;

    downloadFileFromRes({ blob: result.data, fileName: entity.fileName });
  },
  {
    serializeError: serializeAxiosError,
  }
);

export const importAttachFileEntity = createAsyncThunk(
  'player/upload_attach_file',
  async (entity: IFileTemplatePlayer, thunkAPI) => {
    const formData = new FormData();
    formData.append('file', entity.file);
    formData.append('lang', entity.lang);
    formData.append('zoneId', entity.zoneId);

    const requestUrl = `${importPlayersUrl}`;
    const result = (await axios.post<IFileTemplatePlayer>(requestUrl, formData)) as any;
    return result;
  },
  {
    serializeError: serializeAxiosError,
  }
);

export const reImportAttachFileEntity = createAsyncThunk(
  'player/re_upload_attach_file',
  async (entity: IFormCreatePlayers, thunkAPI) => {
    const requestUrl = `${reImportPlayersUrl}?lang=${entity.lang}&zoneId=${entity.zoneId}`;
    const result = (await axios.post<IFileTemplatePlayer>(requestUrl, entity.players)) as any;
    return result;
  },
  {
    serializeError: serializeAxiosError,
  }
);

export const exportAttachFilePlayersEntity = createAsyncThunk(
  'player/export_file',
  async (entity: IFileTemplatePlayer, thunkAPI) => {
    const requestUrl = `${exportPlayersUrl}?type=${entity.type}&lang=${entity.lang}&zoneId=${entity.zoneId}`;
    const result = (await axios.post<IFileTemplatePlayer>(requestUrl, entity.filter, {
      responseType: 'blob',
    })) as any;

    downloadFileFromRes({ blob: result.data, fileName: entity.fileName });
  },
  {
    serializeError: serializeAxiosError,
  }
);

// slice

export const PlayerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    clearMsgUpdate: (state, action) => void (state.updateSuccess = null),
    clearMsgDelete: (state, action) => void (state.deleteSuccess = null),
    clearMsgCreate: (state, action) => void (state.createSuccess = null),
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.deleteSuccess = true;
        state.entity = {};
      })
      .addCase(upLoadImg.fulfilled, (state, action) => {
        state.dataUploadFile = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
          createPlayerSuccess: '',
          deletePlayerSuccess: '',
        };
      })
      .addMatcher(isFulfilled(searchEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          entity: {},
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(importAttachFileEntity, reImportAttachFileEntity), (state, action) => {
        const { data } = action.payload;
        const newData = data?.map((itemUploaded, index) => {
          return {
            ...itemUploaded,
            id: index,
          };
        });

        state.uploading = false;
        state.errorMessage = null;
        state.playerUploadedErr = newData;
      })
      .addMatcher(isPending(importAttachFileEntity, reImportAttachFileEntity), (state, action) => {
        state.uploading = true;
        state.errorMessage = null;
      })
      .addMatcher(isRejected(importAttachFileEntity, reImportAttachFileEntity), (state, action) => {
        state.uploading = false;
        state.errorMessage = null;
      })
      .addMatcher(isFulfilled(exportAttachFilePlayersEntity), (state, action) => {
        state.exporting = false;
        state.errorMessage = null;
      })
      .addMatcher(isPending(exportAttachFilePlayersEntity), (state, action) => {
        state.exporting = true;
        state.errorMessage = null;
      })
      .addMatcher(isFulfilled(downloadAttachFileEntity), (state, action) => {
        state.downloading = false;
        state.errorMessage = null;
      })
      .addMatcher(isPending(downloadAttachFileEntity), (state, action) => {
        state.downloading = true;
        state.errorMessage = null;
      })
      .addMatcher(isFulfilled(updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
        state.dataUploadFile = null;
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.createSuccess = true;
        state.entity = action.payload.data;
        state.dataUploadFile = null;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
        state.entity = {};
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isFulfilled(getAvatarPlayer), (state, action) => {
        state.links = 'data:image/jpeg;base64,' + action.payload.data;
        state.loading = false;
      })
      .addMatcher(isFulfilled(getOrganization), (state, action) => {
        state.organizations = action.payload?.content?.map(item => ({ value: item.id, label: item.name }));
      });
  },
});

export const { clearMsgUpdate, clearMsgDelete, clearMsgCreate } = PlayerSlice.actions;

// Reducer
export default PlayerSlice.reducer;
