import React, { memo, useCallback, useState, useEffect, useMemo } from 'react';
import { Avatar, Button, Card, Col, Form, Input, Row, Select, Tabs, Table, Tag, Collapse, Typography, Space } from 'antd';
import { CaretUpOutlined, CaretDownOutlined, CaretRightOutlined, RightOutlined } from '@ant-design/icons';
import { Translate, translate } from 'react-jhipster';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { downloadCSV, fetchRecordTable, getDetail, ISummaryReport, MatchOverSlice } from './match-over.reducer';
import { renderTime } from 'app/modules/event-list-record/constant.event-list';
import { ColumnsType } from 'antd/lib/table';
import { sendRecord, subscribeTopic } from 'app/config/websocket-middleware';
import { useParams } from 'react-router-dom';
import { IMatchBasicInfo } from 'app/shared/model/match-basic-info.model';
import { first, get } from 'lodash';
import { getEntity } from '../match/match.reducer';
import LineUp from 'app/modules/line-up';
import LineUpEndMatch from 'app/modules/lineup-endmatch';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import ExportFileMathDialog from '../match/export-file-math-dialog';
import { downloadCSVMatchOver } from '../match/MatchPreparation/container/squad/export-file.reducer';
const listScore = (list: ISummaryReport['scoreDto']) => {
  return list?.map((item, index) => (
    <Typography key={index}>
      {renderTime(item.processDate, item.roundType, "'")} {item.player?.koName}
      {item.playerSupport?.koName && ` (${translate('matchOver.assistBy')} ${item.playerSupport?.koName})`}
    </Typography>
  ));
};

const MatchOver = () => {
  // const listScore = useCallback((list: ISummaryReport['scoreDto']) => {
  //   return list?.map((item, index) => (
  //     <Typography key={index}>
  //       {item.player.koName}
  //       {renderTime(item.processDate, item.roundType)}( {translate('matchOver.assistBy')} {item.playerSupport.koName})
  //     </Typography>
  //   ));
  // }, []);

  const { id } = useParams<{ id: string }>();
  const matchInfo = useAppSelector(state => state.match.entity);
  const awayRecord = useAppSelector(state => state.MatchOver.listAwayData);
  const ownerRecord = useAppSelector(state => state.MatchOver.listOwnerData);
  const summaryReportAway: ISummaryReport = useAppSelector(state => state.MatchOver.summaryReportAway);
  const summaryReportOwner: ISummaryReport = useAppSelector(state => state.MatchOver.summaryReportOwner);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const loading = useAppSelector(state => state.MatchOver.loading);
  const pdfLoadng = useAppSelector(state => state.MatchOver.pdfLoadng);
  const { ownerLineUp, awayLineUp, subOwnerLineUp, subAwayLineUp, awayAvatars, ownerAvatars } = useAppSelector(state => state.matchRecord);
  const { actions } = MatchOverSlice;
  const [createModal, setCreateModal] = useState(false);
  const handleClickSubmit = async (payload: { matchId: number; lang: any; zoneId: string; futsal: any }) => {
    await dispatch(downloadCSVMatchOver(payload));
    setCreateModal(false);
  };

  const dispatch = useAppDispatch();
  let sameKey;
  const handleModalCreate = () => {
    setCreateModal(true);
  };

  const setRowSpan = (index: number, length: number, numberOfPlayers: number) => {
    if (index === 0) {
      return { children: <div>{translate('matchOver.recordTableData.main')}</div>, props: { rowSpan: numberOfPlayers } };
    }

    if (index === numberOfPlayers) {
      return { children: <div>{translate('matchOver.recordTableData.sub')}</div>, props: { rowSpan: length - numberOfPlayers } };
    }
    if (index > numberOfPlayers) {
      return { children: <div>{translate('matchOver.recordTableData.main')}</div>, props: { rowSpan: 0 } };
    }
    if (index > 0) {
      return { children: <div>{translate('matchOver.recordTableData.main')}</div>, props: { rowSpan: 0 } };
    }
  };

  const awayDevice: any = useMemo(() => {
    return {
      title: translate('matchOver.recordTableData.division'),
      dataIndex: 'playerFieldMatchType',
      key: 'playerFieldMatchType',
      width: 40,
      render(value, row, index) {
        return setRowSpan(index, awayRecord?.length, matchInfo?.numberOfPlayers);
      },
    };
  }, [awayRecord, locale]);

  const ownerDevice: any = useMemo(() => {
    return {
      title: translate('matchOver.recordTableData.division'),
      dataIndex: 'playerFieldMatchType',
      key: 'playerFieldMatchType',
      width: 40,
      render(value, row, index) {
        return setRowSpan(index, ownerRecord?.length, matchInfo?.numberOfPlayers);
      },
    };
  }, [ownerRecord, locale]);
  const columns = [
    {
      title: translate('matchOver.recordTableData.number'),
      width: 100,
      dataIndex: 'number',
      key: 'number',
      render: (_, record) => record.playerDTO?.uniformNumber,
    },
    {
      title: translate('matchOver.recordTableData.location'),
      width: 100,
      dataIndex: 'location',
      key: 'location',
      render: (_, record) => record.playerDTO?.position,
    },
    {
      title: translate('matchOver.recordTableData.playerName'),
      width: 200,
      dataIndex: 'playerName',
      key: 'playerName',
      render: (_, record) => record.playerDTO?.koName,
    },
    {
      title: translate('matchOver.recordTableData.gk'),
      width: 50,
      dataIndex: 'gk',
      key: 'gk',
      render: (_, record) => record.goalKick || '',
    },
    {
      title: translate('matchOver.recordTableData.ckfk'),
      width: 50,
      dataIndex: 'ckfk',
      key: 'ckfk',
      render: (_, record) => (record.cornerKick ? record.cornerKick : ''),
    },
    {
      title: translate('matchOver.recordTableData.fk'),
      width: 50,
      dataIndex: 'fk',
      key: 'fk',
      render: (_, record) => (record.freeKick ? record.freeKick : ''),
    },
    {
      title: translate('matchOver.recordTableData.pk'),
      width: 50,
      dataIndex: 'pk',
      key: 'pk',
      render: (_, record) => (record.penaltyKick ? record.penaltyKick : ''),
    },
    {
      title: translate('matchOver.recordTableData.st'),
      width: 67,
      dataIndex: 'st',
      key: 'st',
      render: (_, record) => (
        <>
          {!record?.shoot ? (
            ''
          ) : record?.shoot && record?.shootOnTarget === 0 ? (
            <p>{record?.shoot}</p>
          ) : record?.shoot && record?.shootOnTarget === 1 ? (
            <p>{record?.shoot}(1)</p>
          ) : (
            <p>
              {record?.shoot} {record?.shootOnTarget && `(${record?.shootOnTarget})`}
            </p>
          )}
        </>
      ),
    },

    {
      title: translate('matchOver.recordTableData.fc'),
      width: 50,
      dataIndex: 'fc',
      key: 'fc',
      render: (_, record) => (record.foul ? record.foul : ''),
    },
    {
      title: translate('matchOver.recordTableData.fs'),
      width: 50,
      dataIndex: 'fs',
      key: 'fs',
      render: (_, record) => (record.beFouled ? record.beFouled : ''),
    },
    {
      title: translate('matchOver.recordTableData.of'),
      width: 50,
      dataIndex: 'offSide',
      key: 'offSide',
      render: (_, record) => record.offSide || '',
    },
    {
      title: translate('matchOver.recordTableData.score'),
      width: 50,
      dataIndex: 'allScore',
      key: 'allScore',
      render: (_, record) => record.allScore || '',
    },
    {
      title: translate('matchOver.recordTableData.assist'),
      width: 50,
      dataIndex: 'supportScore',
      key: 'supportScore',
      render: (_, record) => record.supportScore || '',
    },

    {
      title: translate('matchOver.recordTableData.change'),
      width: 60,
      dataIndex: 'change',
      key: 'change',
      render: (_, record) => (
        <>
          {record?.inOut ? <CaretUpOutlined /> : ''}
          {record?.inOut === 0 ? <CaretDownOutlined /> : ''}
        </>
      ),
    },
    {
      title: translate('matchOver.recordTableData.kickOff'),
      width: 100,
      dataIndex: 'kickOff',
      key: 'kickOff',
      render: (text, record) => (
        <>
          {record.warning ? <Tag color="gold">{record.warning}</Tag> : ''}
          {record.ban ? <Tag color="error">{record.ban}</Tag> : ''}
        </>
      ),
    },
  ];

  const columnsSummerArea = [
    {
      title: matchInfo?.ownerClubName,
      width: '40%',
      dataIndex: 'home',
      key: 'home',
    },
    {
      title: `${summaryReportOwner?.scoreDto?.length || 0} - ${summaryReportAway?.scoreDto?.length || 0}`,
      width: '20%',
      dataIndex: 'title',
      key: 'title',
      align: 'center' as any,
    },
    {
      title: matchInfo?.awayClubName,
      width: '40%',
      dataIndex: 'away',
      key: 'away',
      align: 'right' as any,
    },
  ];

  const SummerAreaRows = [
    {
      title: translate('matchOver.recordTableData.score'),
      home: listScore(summaryReportOwner?.scoreDto),
      away: listScore(summaryReportAway?.scoreDto),
    },
    {
      title: translate('matchOver.recordTableData.prossession'),
      home: loading === false ? summaryReportOwner?.rate + '%' : '',
      away: loading === false ? summaryReportAway?.rate + '%' : '',
    },
    { title: translate('matchOver.recordTableData.shooting'), home: summaryReportOwner?.shoot, away: summaryReportAway?.shoot },
    {
      title: translate('matchOver.recordTableData.shootOnTarget'),
      home: summaryReportOwner?.effectiveShoot,
      away: summaryReportAway?.effectiveShoot,
    },
    { title: translate('matchOver.recordTableData.cornerKick'), home: summaryReportOwner?.cornerKick, away: summaryReportAway?.cornerKick },
    { title: translate('matchOver.recordTableData.freeKick'), home: summaryReportOwner?.kick, away: summaryReportAway?.kick },
    {
      title: translate('matchOver.recordTableData.penaltyKick'),
      home: summaryReportOwner?.penaltyKick,
      away: summaryReportAway?.penaltyKick,
    },
    { title: translate('matchOver.recordTableData.foul'), home: summaryReportOwner?.foul, away: summaryReportAway?.foul },
    { title: translate('matchOver.recordTableData.yellowCard'), home: summaryReportOwner?.warning, away: summaryReportAway?.warning },
    { title: translate('matchOver.recordTableData.redCard'), home: summaryReportOwner?.ban, away: summaryReportAway?.ban },
    { title: translate('matchOver.recordTableData.offside'), home: summaryReportOwner?.offSide, away: summaryReportAway?.offSide },
  ];

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(getEntity(id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        const result: IMatchBasicInfo = first(get(res.payload, 'data.content'));
        dispatch(fetchRecordTable({ matchId: id, awayClubId: result?.awayClubId, ownerClubId: result?.ownerClubId }));
      }
    });
  }, [id]);

  return (
    <Row gutter={[24, 12]} className="w-100">
      <Col span={24}>
        <div className="d-flex align-items-center record-layout-header">
          <p className="heading-20-bold neutral-1 mb-0">
            <Translate contentKey="matchOver.recordTable" />
          </p>
          {matchInfo?.approval === 1 && (
            <Button
              loading={pdfLoadng}
              className="rounded ms-4"
              icon={<SelectArrowDown />}
              onClick={handleModalCreate}
              style={{ marginLeft: 10 }}
            >
              {translate('matchOver.download')}
            </Button>
          )}
          {matchInfo?.approval === 3 && (
            <Button
              loading={pdfLoadng}
              className="rounded ms-4"
              icon={<SelectArrowDown />}
              onClick={handleModalCreate}
              style={{ marginLeft: 10 }}
            >
              {translate('matchOver.download')}
            </Button>
          )}
          {createModal ? (
            <ExportFileMathDialog
              createModal={createModal}
              setCreateModal={setCreateModal}
              matchId={id}
              handleClickSubmit={handleClickSubmit}
            />
          ) : (
            ''
          )}
        </div>
      </Col>
      <Col span={24}>
        <Collapse
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => <RightOutlined color="#292C38" rotate={isActive ? 90 : 0} />}
          className="site-collapse-custom-collapse"
          style={{ backgroundColor: 'white' }}
        >
          <Collapse.Panel header={translate('matchOver.fieldViewArea')} key="1" className="site-collapse-custom-panel">
            <LineUpEndMatch
              ownerPlayers={ownerLineUp}
              awayPlayers={awayLineUp}
              awayAvatars={awayAvatars}
              ownerAvatars={ownerAvatars}
              swap={false}
              timeOfRound={matchInfo?.timeOfRound}
              timeOfRoundExtra={matchInfo?.timeOfRoundExtra}
            />
          </Collapse.Panel>
          <Collapse.Panel header={translate('matchOver.recordSummaryArea')} key="2" className="site-collapse-custom-panel">
            <Row className="mb-2" justify="space-between" align="bottom">
              <Col flex="none">
                <div className="avatar">
                  <Avatar shape="square" size={80} src={matchInfo?.ownerBase64Avt} />
                </div>
              </Col>
              <Col flex="none">
                <div className="avatar ml-auto">
                  <Avatar shape="square" size={80} src={matchInfo.awayBase64Avt} />
                </div>
              </Col>
            </Row>
            <Table loading={loading} scroll={{ x: 1200 }} pagination={false} dataSource={SummerAreaRows} columns={columnsSummerArea} />
          </Collapse.Panel>
          <Collapse.Panel header={translate('matchOver.homeTeamRecord')} key="3" className="site-collapse-custom-panel">
            <Table
              loading={loading}
              scroll={{ x: 1200 }}
              pagination={false}
              dataSource={ownerRecord}
              columns={[ownerDevice].concat(columns)}
            />
          </Collapse.Panel>
          <Collapse.Panel header={translate('matchOver.awayTeamRecord')} key="4" className="site-collapse-custom-panel">
            <Table
              loading={loading}
              scroll={{ x: 1200 }}
              pagination={false}
              dataSource={awayRecord}
              columns={[awayDevice].concat(columns)}
            />
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default memo(MatchOver);
