import React from 'react';
import { Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { Translate } from 'react-jhipster';
import { StatusFinishIcon, StatusPrepareIcon, StatusUpcomingIcon, StatusWaitIcon } from 'app/shared/util/appIcon';
import { useHistory } from 'react-router-dom';
import { APPROVAL_TYPE, STATUS_MATCH, TYPE_INFORMATION } from 'app/config/constants';
import { QuestionCircleOutlined } from '@ant-design/icons';

// Actions trang thai gom 0 là k co , 1 nhập thông tin, 2 là đợi record , 3 là kết thúc trận đấu
// Status = 3: approval = 1 là chỉnh sửa, 2 là đợi approve, 3 là đã approve

interface Props {
  status: number;
  startDate: string;
  flag?: number;
  approval?: number;
}

export const MatchStatusContained = ({ status, startDate, flag, approval }: Props) => {
  const matchDate = dayjs(startDate).startOf('day');
  const { push } = useHistory();
  if (!status) {
    return <></>;
  }
  if (status === STATUS_MATCH.WAIT_RECORD) {
    if (flag === TYPE_INFORMATION.TYPED) {
      return (
        <div className="table-custom-status">
          <StatusPrepareIcon />
          <Translate contentKey="leagueApp.match.ready" />
          <span style={{ marginLeft: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip placement="rightBottom" title={<Translate contentKey="leagueApp.match.tooltipStatus.waitRecord" />}>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        </div>
      );
    }
    if (flag === TYPE_INFORMATION.NOT_TYPE) {
      return (
        <div className="table-custom-status">
          <StatusWaitIcon />
          <Translate contentKey="leagueApp.match.preparing" />
          <span style={{ marginLeft: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip placement="rightBottom" title={<Translate contentKey="leagueApp.match.tooltipStatus.prepare" />}>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        </div>
      );
    }
  }
  // if (status === STATUS_MATCH.WAIT_RECORD && matchDate.isAfter(dayjs().startOf('day'))) {
  //   return (
  //     <div className="table-custom-status">
  //       <StatusUpcomingIcon />
  //       <Translate contentKey="leagueApp.match.nextMatch" />
  //     </div>
  //   );
  // }
  if (status === STATUS_MATCH.RECORDING) {
    return (
      <div className="table-custom-status">
        <StatusWaitIcon />
        <Translate contentKey="leagueApp.match.recording" />
        <span style={{ marginLeft: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Tooltip placement="rightBottom" title={<Translate contentKey="leagueApp.match.tooltipStatus.recording" />}>
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      </div>
    );
  }
  if (status === STATUS_MATCH.DETAIL) {
    if (approval === APPROVAL_TYPE.WAIT_REQUEST) {
      return (
        <div className="table-custom-status">
          <StatusFinishIcon />
          <Translate contentKey="leagueApp.match.statusMatch.finish" />
          <span style={{ marginLeft: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip placement="rightBottom" title={<Translate contentKey="leagueApp.match.tooltipStatus.finish" />}>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        </div>
      );
    }
    if (approval === APPROVAL_TYPE.WAIT_ACCEPT) {
      return (
        <div className="table-custom-status">
          <StatusFinishIcon />
          <Translate contentKey="leagueApp.match.statusMatch.waitApprove" />
          <span style={{ marginLeft: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip placement="rightBottom" title={<Translate contentKey="leagueApp.match.tooltipStatus.waitApprove" />}>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        </div>
      );
    }
    if (approval === APPROVAL_TYPE.WAIT_ACCEPT) {
      return (
        <div className="table-custom-status">
          <StatusFinishIcon />
          <Translate contentKey="leagueApp.match.statusMatch.waitApprove" />
          <span style={{ marginLeft: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip placement="rightBottom" title={<Translate contentKey="leagueApp.match.tooltipStatus.approveRecord" />}>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        </div>
      );
    }
    if (approval === APPROVAL_TYPE.ACCEPTED) {
      return (
        <div className="table-custom-status">
          <StatusFinishIcon />
          <Translate contentKey="leagueApp.match.statusMatch.complete" />
          <span style={{ marginLeft: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip placement="rightBottom" title={<Translate contentKey="leagueApp.match.tooltipStatus.complete" />}>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        </div>
      );
    }
  }
  return <></>
};
