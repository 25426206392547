import dayjs from 'dayjs';

export interface ILeague {
  id?: number;
  leagueStandardName?: string | null;
  leagueStandardCode?: string | null;
  leagueStandardId?: number | null;
  name?: string | null;
  issueYear?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  manager?: string | null;
  owner?: string | null;
  expirationDate?: string | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
}

export const defaultValue: Readonly<ILeague> = {};
