import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CategoryData from './category-data';
import CategoryDataDetail from './category-data-detail';
import CategoryDataUpdate from './category-data-update';
import CategoryDataDeleteDialog from './category-data-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CategoryDataUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CategoryDataUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CategoryDataDetail} />
      <ErrorBoundaryRoute path={match.url} component={CategoryData} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={CategoryDataDeleteDialog} />
  </>
);

export default Routes;
