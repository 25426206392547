import { toast } from 'react-toastify';
import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

const language = localStorage.getItem('locale') || sessionStorage.getItem('locale') || 'en';
import { LIST_CATE, convertResponseCate } from './constant.free-corner-record';
import { CATEGORY_DATA_CODE } from 'app/shared/util/category-data-code.constant';

export interface IDataTrans {
  id: number;
  categoryDataId: number;
  code: string;
  name: string;
  lang: string;
  status: number;
}

export interface IDataCate {
  id: number;
  code: string;
  name: string;
  status: number;
  dataTrans: IDataTrans[];
}

const initialState = {
  loading: false,
  errorMessage: null,
  name: '',
  listSupportKick: [],
  nameSupportKick: '',
  listScoreFormKick: [],
  nameScoreFormKick: '',
  listShootDirectionKick: [],
  nameShootDirectionKick: '',
  listTypeShootKick: [],
  nameTypeShootKick: '',
  listTypeFailKick: [],
  nameTypeFailKick: '',
  listShooting: [],
  nameShooting: '',
  listScoreMethod: [],
  nameScoreMethod: '',
};

const API_CATEGORY_BY_CODE = 'api/category-data-by-code';

const LIST_API_LOAD_CATE = {
  SupportKick: locale => `${API_CATEGORY_BY_CODE}?code=${LIST_CATE.SUPPORT_KICK}&lang=${locale}`,
  ScoreFormKick: locale => `${API_CATEGORY_BY_CODE}?code=${LIST_CATE.SCORE_FORM_KICK}&lang=${locale}`,
  ShootDirectionKick: locale => `${API_CATEGORY_BY_CODE}?code=${LIST_CATE.SHOOT_DIRECTION_KICK}&lang=${locale}`,
  TypeShootKick: locale => `${API_CATEGORY_BY_CODE}?code=${LIST_CATE.TYPE_SHOOT_KICK}&lang=${locale}`,
  TypeFailKick: locale => `${API_CATEGORY_BY_CODE}?code=${LIST_CATE.TYPE_FAIL_KICK}&lang=${locale}`,
  TypeScoreResult: locale => `${API_CATEGORY_BY_CODE}?code=${LIST_CATE.SCORE_RESULT}&lang=${locale}`,
  TypeScoreMethod: locale => `${API_CATEGORY_BY_CODE}?code=${LIST_CATE.SCORE_METHOD}&lang=${locale}`,
};

export const fetchListSupportKick = createAsyncThunk('freeCornerKick/fetch_list_support_kick', async (locale: string) => {
  const requestUrl = `${API_CATEGORY_BY_CODE}?code=${LIST_CATE.SUPPORT_KICK}&lang=${locale}`;
  return axios.get<IDataCate>(requestUrl);
});

export const fetchListScoreMethods = createAsyncThunk('freeCornerKick/fetch_list_score_methods', async (locale: string) => {
  const requestUrl = `${API_CATEGORY_BY_CODE}?code=${CATEGORY_DATA_CODE.SCORE_METHOD}&lang=${locale}`;
  return axios.get<IDataCate>(requestUrl);
});

export const fetchListScoreFormKick = createAsyncThunk('freeCornerKick/fetch_list_score_form_kick', async (locale: string) => {
  const requestUrl = `${API_CATEGORY_BY_CODE}?code=${LIST_CATE.SCORE_FORM_KICK}&lang=${locale}`;
  return axios.get<IDataCate>(requestUrl);
});

export const fetchListShootDirectionKick = createAsyncThunk('freeCornerKick/fetch_list_shoot_direction_kick', async (locale: string) => {
  const requestUrl = `${API_CATEGORY_BY_CODE}?code=${LIST_CATE.SHOOTING}&lang=${locale}`;
  return axios.get<IDataCate>(requestUrl);
});

export const fetchListTypeShootKick = createAsyncThunk('freeCornerKick/fetch_list_type_shoot_kick', async (locale: string) => {
  const requestUrl = `${API_CATEGORY_BY_CODE}?code=${LIST_CATE.TYPE_SHOOT_KICK}&lang=${locale}`;
  return axios.get<IDataCate>(requestUrl);
});

export const fetchListTypeFailKick = createAsyncThunk('freeCornerKick/fetch_list_type_fail_kick', async (locale: string) => {
  const requestUrl = `${API_CATEGORY_BY_CODE}?code=${LIST_CATE.TYPE_FAIL_KICK}&lang=${locale}`;
  return axios.get<IDataCate>(requestUrl);
});

export const fetchCateFreeCornerKick = createAsyncThunk('freeCornerKick/fetch_list_cate_free_corner_kick', async (locale: string) => {
  const listUrl = [
    LIST_API_LOAD_CATE.ScoreFormKick(locale),
    LIST_API_LOAD_CATE.ShootDirectionKick(locale),
    LIST_API_LOAD_CATE.TypeShootKick(locale),
    LIST_API_LOAD_CATE.TypeFailKick(locale),
    LIST_API_LOAD_CATE.TypeScoreResult(locale),
  ];
  const results = await axios.all<IDataCate>(listUrl.map(item => axios.get(item).then(res => res.data || [])));
  return results;
});

export const fetchCatePenaltyKick = createAsyncThunk('freeCornerKick/fetch_list_cate_penalty_kick', async (locale: string) => {
  const listUrl = [LIST_API_LOAD_CATE.ShootDirectionKick(locale), LIST_API_LOAD_CATE.TypeFailKick(locale)];
  const results = await axios.all<IDataCate>(listUrl.map(item => axios.get(item).then(res => res.data || [])));
  return results;
});

export const fetchDetailEventRecord = createAsyncThunk('freeCornerKick/fetch_detail_record', async (url: string) => {
  return axios.get(url);
});

export const fetchCateRegisterScore = createAsyncThunk('freeCornerKick/fetch_list_cate_register_score', async (locale: string) => {
  const listUrl = [
    LIST_API_LOAD_CATE.SupportKick(locale),
    LIST_API_LOAD_CATE.ScoreFormKick(locale),
    LIST_API_LOAD_CATE.ShootDirectionKick(locale),
    LIST_API_LOAD_CATE.TypeShootKick(locale),
    LIST_API_LOAD_CATE.TypeFailKick(locale),
  ];
  const results = await axios.all<IDataCate>(listUrl.map(item => axios.get(item).then(res => res.data || [])));
  return results;
});

export const PlayerFreeCornerKick = createSlice({
  name: 'matchRecord',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(isPending(fetchListSupportKick), state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addMatcher(isFulfilled(fetchListSupportKick), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          listSupportKick: convertResponseCate(data.dataTrans),
          nameSupportKick: data.name,
        };
      })

      .addMatcher(isFulfilled(fetchListScoreMethods), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          listScoreMethod: convertResponseCate(data.dataTrans),
          nameScoreMethod: data?.name,
        };
      })
      .addMatcher(isFulfilled(fetchListScoreFormKick), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          listScoreFormKick: convertResponseCate(data.dataTrans),
          nameScoreFormKick: data.name,
        };
      })
      .addMatcher(isFulfilled(fetchListShootDirectionKick), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          listShootDirectionKick: convertResponseCate(data.dataTrans),
          nameShootDirectionKick: data.name,
        };
      })
      .addMatcher(isFulfilled(fetchListTypeShootKick), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          listTypeShootKick: convertResponseCate(data.dataTrans),
          nameTypeShootKick: data.name,
        };
      })
      .addMatcher(isFulfilled(fetchListTypeFailKick), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          listTypeFailKick: convertResponseCate(data.dataTrans),
          nameTypeFailKick: data.name,
        };
      })
      .addMatcher(isFulfilled(fetchCateFreeCornerKick), (state, action) => {
        state.listScoreFormKick = convertResponseCate(action.payload[0].dataTrans);
        state.nameScoreFormKick = action.payload[0].name;
        state.listShootDirectionKick = convertResponseCate(action.payload[1].dataTrans);
        state.nameShootDirectionKick = action.payload[1].name;
        state.listTypeShootKick = convertResponseCate(action.payload[2].dataTrans);
        state.nameTypeShootKick = action.payload[2].name;
        state.listTypeFailKick = convertResponseCate(action.payload[3].dataTrans);
        state.nameTypeFailKick = action.payload[3].name;
      })
      .addMatcher(isFulfilled(fetchCatePenaltyKick), (state, action) => {
        state.listShootDirectionKick = convertResponseCate(action.payload[0].dataTrans);
        state.nameShootDirectionKick = action.payload[0].name;
        state.listTypeFailKick = convertResponseCate(action.payload[1].dataTrans);
        state.nameTypeFailKick = action.payload[1].name;
      })
      .addMatcher(isFulfilled(fetchCateRegisterScore), (state, action) => {
        state.listSupportKick = convertResponseCate(action.payload[0].dataTrans);
        state.nameSupportKick = action.payload[0].name;
        state.listScoreFormKick = convertResponseCate(action.payload[1].dataTrans);
        state.nameScoreFormKick = action.payload[1].name;
        state.listShootDirectionKick = convertResponseCate(action.payload[2].dataTrans);
        state.nameShootDirectionKick = action.payload[2].name;
        state.listTypeShootKick = convertResponseCate(action.payload[3].dataTrans);
        state.nameTypeShootKick = action.payload[3].name;
        state.listTypeFailKick = convertResponseCate(action.payload[4].dataTrans);
        state.nameTypeFailKick = action.payload[4].name;
      });
  },
});

// Reducer
export default PlayerFreeCornerKick.reducer;
