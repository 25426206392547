import { ArrowLeftOutlined, ArrowRightOutlined, LeftOutlined, RightOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Radio, RadioChangeEvent } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useAppSelector } from 'app/config/store';
import { IPlayerSquad } from 'app/entities/match/match.type';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, DraggableProvided, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { translate, Translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import { Table } from 'reactstrap';
import './transfer.scss';
interface IProps {
  dataLeftTable: IPlayerSquad[];
  dataRightTable?: IPlayerSquad[];
  handleGetDataLeftTable?: (value: IPlayerSquad[], otherValue: IPlayerSquad) => void;
  handleGetDataRightTable?: (value: IPlayerSquad[], otherValue: IPlayerSquad) => void;
  type?: string;
  customLabel?: boolean;
  defaultPlayerId?: number;
}

const TransferWithDnd = ({
  dataLeftTable,
  handleGetDataLeftTable,
  handleGetDataRightTable,
  dataRightTable,
  type,
  customLabel,
  defaultPlayerId,
}: IProps) => {
  const [disabled, setDisabled] = useState(false);
  const [disable2, setDisable2] = useState(false);
  const [data, setData] = useState<IPlayerSquad[]>([]);
  const [data2, setData2] = useState<IPlayerSquad[]>([]);
  const [arrTransfer, setArrTransfer] = useState<IPlayerSquad[]>([]);
  const [toRight, setToRight] = useState<IPlayerSquad[]>([]);
  const [valueRadio, setValueRadio] = useState(defaultPlayerId);
  const [valueRadioRightTable, setValueRadioRightTable] = useState(null);
  const [valueIn, setValueIn] = useState<IPlayerSquad>();
  const [valueOut, setValueOut] = useState<IPlayerSquad>();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const matchInfoEntity = useAppSelector(state => state.match.entity);
  const onDragEnd = results => {
    if (!results.destination) return;
    const tempData = [...data2];
    const [selectedRow] = tempData.splice(results.source.index, 1);
    tempData.splice(results.destination.index, 0, selectedRow);
    setData2(tempData);
  };
  useEffect(() => {
    if (type === 'change_player') {
      setData(dataLeftTable);
      setData2(dataRightTable);
    } else {
      if (dataLeftTable && dataLeftTable.length) {
        const newArr = [...dataLeftTable];
        setData(newArr);
      }
      if (dataLeftTable && dataRightTable.length) {
        const arr = [...dataRightTable];
        setData2(arr);
      }
    }
  }, [dataLeftTable, dataRightTable]);
  // handle change text box all table left
  const handleCheckAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const newArr = data?.map(item => {
        return {
          ...item,
          checked: true,
        };
      });
      setToRight(newArr);
      setDisabled(true);
      setData(newArr);
    } else {
      const newArr = data?.map(item => {
        return {
          ...item,
          checked: false,
        };
      });
      setToRight([]);
      setDisabled(false);
      setData(newArr);
    }
  };

  // handle change text box all table right
  const handleCheckAll2 = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const newArr = data2.map(item => {
        return {
          ...item,
          checked: true,
        };
      });
      setArrTransfer(newArr);
      setDisable2(true);
      setData2(newArr);
    } else {
      const newArr = data2.map(item => {
        return {
          ...item,
          checked: false,
        };
      });
      setArrTransfer([]);
      setDisable2(false);
      setData2(newArr);
    }
  };

  // handle change text box table left
  const handleChangeData = (e, key: number, value: IPlayerSquad) => {
    const newList = data.map(item => {
      if (item?.id === key && !item.checked) {
        item.checked = true;
        setToRight([...toRight, item]);
      } else if (item?.id === key && item.checked) {
        item.checked = false;
        const newArr = toRight.filter(a => a.id !== key);
        setToRight(newArr);
      }
      return item;
    });
    setData(newList);
  };
  // handle change text box table right
  const handleChangeData2 = (e, key: number, value: IPlayerSquad, index) => {
    const newList = data2.map(item => {
      if (item?.id === key && !item.checked) {
        item.checked = true;
        setArrTransfer([...arrTransfer, item]);
      } else if (item?.id === key && item.checked) {
        item.checked = false;
        const newArr = arrTransfer.filter(a => a.id !== key);
        setArrTransfer(newArr);
      }
      return item;
    });
    setData2(newList);
  };

  // Button Register
  const handleAddNewItemToRight = () => {
    if (type === 'editPlayer') {
      if (data2.length + toRight.length > matchInfoEntity?.numberOfPlayers) {
        toast.error(
          translate('leagueApp.match.squad.maximum') + `${matchInfoEntity?.numberOfPlayers}` + translate('leagueApp.match.squad.player')
        );
        return;
      }
      // if (data2.length + toRight.length > 11) {
      //   toast.error(translate('leagueApp.match.squad.max11'));
      //   return;
      // }
    }
    if (type === 'editSub') {
      if (data2.length + toRight.length > 9) {
        toast.error(translate('leagueApp.match.squad.max9'));
        return;
      }
    }
    // if (!type) {
    //   if (data2.length + toRight.length > 16) {
    //     toast.error(translate('leagueApp.match.squad.max16'));
    //     return;
    //   }
    // }
    const arr = toRight.map(item => {
      return {
        ...item,
        checked: false,
      };
    });
    setData2([...data2, ...arr]);
    const newArr = data.filter(item => !toRight.find(ele => item?.id === ele?.id));
    setData(newArr);
    setToRight([]);
    setDisabled(false);
  };
  // Button Cancel
  const handleAddNewItemToLeft = () => {
    const arr = arrTransfer.map(item => {
      return {
        ...item,
        checked: false,
      };
    });
    setData([...data, ...arr]);
    const newArr = data2.filter(item => !arrTransfer.find(ele => item?.id === ele?.id));
    setData2(newArr);
    setArrTransfer([]);
    setDisable2(false);
  };

  const handleChangeRadio = e => {
    setValueRadio(e.target.value);
  };
  const handleChangeRadioRightTable = (e: RadioChangeEvent) => {
    setValueRadioRightTable(e.target.value);
  };
  const handleChangeValue = (e, id: number) => {
    setValueRadio(id);
  };
  const handleChangeValueRightTable = (e, id: number) => setValueRadioRightTable(id);
  const handleChangePlayer = () => {
    const newArrLeft = data.filter(item => item?.id !== valueRadio);
    const newArrRight = data2.filter(item => item?.id !== valueRadioRightTable);
    setValueIn(dataRightTable?.find(item => item?.id === valueRadioRightTable));
    setValueOut(dataLeftTable?.find(item => item?.id === valueRadio));
    setData(newArrLeft);
    setData2(newArrRight);
    setValueRadio(null);
    setValueRadioRightTable(null);
  };
  handleGetDataLeftTable(data, valueIn);
  handleGetDataRightTable(data2, valueOut);
  return (
    <div>
      <div className="modal-transfer">
        <div style={{ flexGrow: 1 }}>
          <p>
            {!customLabel ? (
              <span className="custom-label-pick-player">
                {locale === 'ko' ? (
                  <>
                    <Translate contentKey="leagueApp.match.squad.total1" /> {data?.length}
                    <Translate contentKey="leagueApp.match.squad.total2" /> | {toRight?.length}{' '}
                    <Translate contentKey="leagueApp.match.squad.itemChoose"></Translate>
                  </>
                ) : (
                  <>
                    <Translate contentKey="leagueApp.match.squad.total1" />
                    {data?.length} <Translate contentKey="leagueApp.match.squad.total2" /> | {toRight?.length}{' '}
                    <Translate contentKey="leagueApp.match.squad.itemChoose"></Translate>
                  </>
                )}
              </span>
            ) : (
              ''
            )}
            {customLabel ? (
              <div className="custom-label-l">
                <Translate contentKey="leagueApp.matchRecord.out" /> <ArrowRightOutlined />
              </div>
            ) : (
              ''
            )}
          </p>
          <div className="table-data">
            <Table bordered>
              <thead className="thead">
                <tr>
                  <th style={{ width: '39px' }}>
                    {type !== 'change_player' ? <Checkbox checked={disabled} onChange={handleCheckAll} /> : ''}
                  </th>
                  <th style={{ width: '71px' }}>
                    <Translate contentKey="leagueApp.match.number">Số áo</Translate>
                  </th>
                  <th style={{ width: '100px' }}>
                    <Translate contentKey="leagueApp.match.position">Vị trí</Translate>
                  </th>
                  <th style={{ width: '202px' }}>
                    <Translate contentKey="leagueApp.match.name">Tên</Translate>
                  </th>
                </tr>
              </thead>
              <tbody className="tbody">
                {data?.map((item, index) =>
                  type !== 'change_player' ? (
                    <tr key={item?.id} className={item.checked ? 'hight-light' : null}>
                      <td style={{ width: '39px' }}>
                        <Checkbox
                          name={`input${index}`}
                          type="checkbox"
                          checked={item.checked}
                          onChange={e => handleChangeData(e, item?.id, item)}
                        />
                      </td>
                      <td
                        style={{ width: '50px', cursor: 'pointer' }}
                        onClick={e => handleChangeData(e, item?.id, item)}
                        itemID={item?.id.toString()}
                      >
                        {item.uniformNumber}
                      </td>
                      <td
                        style={{ width: '100px', cursor: 'pointer' }}
                        onClick={e => handleChangeData(e, item?.id, item)}
                        itemID={item?.id.toString()}
                      >
                        {translate(`leagueApp.playerAgent.${item.position}`)}
                      </td>
                      <td
                        style={{ width: '202px', cursor: 'pointer' }}
                        onClick={e => handleChangeData(e, item?.id, item)}
                        itemID={item?.id.toString()}
                      >
                        {item?.koName}
                      </td>
                    </tr>
                  ) : (
                    <tr key={item?.id} className={item?.id === valueRadio ? 'hight-light' : null}>
                      <td style={{ width: '39px' }}>
                        <Radio.Group onChange={handleChangeRadio} value={valueRadio}>
                          <Radio value={item?.id} />
                        </Radio.Group>
                      </td>
                      <td style={{ width: '50px', cursor: 'pointer' }} onClick={e => handleChangeValue(e, item?.id)}>
                        {item?.uniformNumber}
                      </td>
                      <td style={{ width: '100px', cursor: 'pointer' }} onClick={e => handleChangeValue(e, item?.id)}>
                        {item?.position}
                      </td>
                      <td style={{ width: '202px', cursor: 'pointer' }} onClick={e => handleChangeValue(e, item?.id)}>
                        {item?.koName}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {type !== 'change_player' ? (
          <div style={{ marginLeft: 65, marginRight: 65, marginTop: 200 }} className="transfer-button">
            <Button
              onClick={handleAddNewItemToRight}
              disabled={toRight.length ? false : true}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <RightOutlined /> <Translate contentKey="leagueApp.match.squad.register">Register</Translate>
            </Button>
            <Button
              color="danger"
              onClick={handleAddNewItemToLeft}
              disabled={arrTransfer.length ? false : true}
              style={{ marginTop: 12, display: 'flex', alignItems: 'center' }}
            >
              <LeftOutlined /> <Translate contentKey="leagueApp.match.squad.cancel">Register</Translate>
            </Button>
          </div>
        ) : (
          <div style={{ marginLeft: 65, marginRight: 65, marginTop: 200 }} className="transfer-button">
            <Button
              onClick={handleChangePlayer}
              disabled={valueRadio && valueRadioRightTable ? false : true}
              style={{ display: 'flex', alignItems: 'center', width: 120 }}
            >
              <SwapOutlined /> <Translate contentKey="leagueApp.matchRecord.change">Change</Translate>
            </Button>
          </div>
        )}
        <div style={{ flexGrow: 1 }}>
          <p>
            {!customLabel ? (
              <span className="custom-label-pick-player">
                {locale === 'ko' ? (
                  <>
                    <Translate contentKey="leagueApp.match.squad.total1" /> {data2?.length}
                    <Translate contentKey="leagueApp.match.squad.total2" /> | {arrTransfer?.length}{' '}
                    <Translate contentKey="leagueApp.match.squad.itemChoose"></Translate>
                  </>
                ) : (
                  <>
                    <Translate contentKey="leagueApp.match.squad.total1" />
                    {data2?.length} <Translate contentKey="leagueApp.match.squad.total2" /> | {arrTransfer?.length}{' '}
                    <Translate contentKey="leagueApp.match.squad.itemChoose"></Translate>
                  </>
                )}
              </span>
            ) : (
              ''
            )}
            {customLabel ? (
              <div className="custom-label-r">
                <Translate contentKey="leagueApp.matchRecord.in" />
                <ArrowLeftOutlined />
              </div>
            ) : (
              ''
            )}
          </p>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="table-data">
              <Table bordered>
                <thead>
                  <tr>
                    <th style={{ width: '39px' }}>
                      {type !== 'change_player' ? <Checkbox type="checkbox" checked={disable2} onChange={handleCheckAll2} /> : ''}
                    </th>
                    <th style={{ width: '71px' }}>
                      <Translate contentKey="leagueApp.match.number">Số áo</Translate>
                    </th>
                    <th style={{ width: '100px' }}>
                      <Translate contentKey="leagueApp.match.position">Vị trí</Translate>
                    </th>
                    <th style={{ width: '202px' }}>
                      <Translate contentKey="leagueApp.match.name">Tên</Translate>
                    </th>
                  </tr>
                </thead>
                <Droppable droppableId="tbody">
                  {(provided: DroppableProvided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {(!data2 || data2?.length === 0) && type !== 'change_player' ? (
                        <div className="dnd-empty">{translate('leagueApp.match.empty_table')}</div>
                      ) : (
                        data2?.map((item, index) => (
                          <Draggable draggableId={item?.koName} index={index} key={item?.id}>
                            {(process: DraggableProvided, snapshot) =>
                              type !== 'change_player' ? (
                                <tr
                                  ref={process.innerRef}
                                  {...process.draggableProps}
                                  className={`${item.checked ? 'hight-light' : null} item ${snapshot.isDragging ? 'dragging' : ''}`}
                                >
                                  <td style={{ width: '39px' }}>
                                    <Checkbox
                                      type="checkbox"
                                      checked={item.checked}
                                      onChange={e => handleChangeData2(e, item?.id, item, index)}
                                    />
                                  </td>
                                  <td
                                    style={{ width: '50px', cursor: 'pointer' }}
                                    {...process.dragHandleProps}
                                    onClick={e => handleChangeData2(e, item?.id, item, index)}
                                  >
                                    {item.uniformNumber}
                                  </td>
                                  <td
                                    style={{ width: '100px', cursor: 'pointer' }}
                                    {...process.dragHandleProps}
                                    onClick={e => handleChangeData2(e, item?.id, item, index)}
                                  >
                                    {translate(`leagueApp.playerAgent.${item.position}`)}
                                  </td>
                                  <td
                                    style={{ width: '202px', cursor: 'pointer' }}
                                    {...process.dragHandleProps}
                                    onClick={e => handleChangeData2(e, item?.id, item, index)}
                                  >
                                    {item?.koName}
                                  </td>
                                </tr>
                              ) : (
                                <tr
                                  ref={process.innerRef}
                                  {...process.draggableProps}
                                  className={`${item?.id === valueRadioRightTable ? 'hight-light' : null} item ${
                                    snapshot.isDragging ? 'dragging' : ''
                                  }`}
                                >
                                  <td style={{ width: '39px' }}>
                                    <Radio.Group onChange={handleChangeRadioRightTable} value={valueRadioRightTable}>
                                      <Radio value={item?.id} />
                                    </Radio.Group>
                                  </td>
                                  <td
                                    style={{ width: '50px', cursor: 'pointer' }}
                                    {...process.dragHandleProps}
                                    onClick={e => handleChangeValueRightTable(e, item?.id)}
                                  >
                                    {item.uniformNumber}
                                  </td>
                                  <td
                                    style={{ width: '100px', cursor: 'pointer' }}
                                    {...process.dragHandleProps}
                                    onClick={e => handleChangeValueRightTable(e, item?.id)}
                                  >
                                    {item.position}
                                  </td>
                                  <td
                                    style={{ width: '202px', cursor: 'pointer' }}
                                    {...process.dragHandleProps}
                                    onClick={e => handleChangeValueRightTable(e, item?.id)}
                                  >
                                    {item?.koName}
                                  </td>
                                </tr>
                              )
                            }
                          </Draggable>
                        ))
                      )}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </Table>
            </div>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

export default TransferWithDnd;
