import { ICoachBanRecord } from './coach-ban-record.model';

export interface ICoach {
  id?: number;
  coachName?: string;
  birthday?: string | null;
  license?: string | null;
  nationality?: string | null;
  clubId?: number | null;
  clubName?: string | null;
  role?: string | null;
  avatar?: string | null;
  email?: string | null;
  phone?: string | null;
  birthPlace?: string | null;
  idNumber?: string | null;
  homeAddress?: string | null;
  previousClub?: string | null;
  fileDegree?: string | null;
  fileCommitment?: string | null;
  base64Avatar?: string | null;
  base64FileDegree?: string | null;
  base64FileCommitment?: string | null;
  coachBanRecordDTOS?: ICoachBanRecord[];
  notEdit?: boolean | null | false;
  teamName?: string | null;
}

export const defaultValue: Readonly<ICoach> = {};
