import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { IFormPlayerDTO } from 'app/shared/model/match.model';
import { toast } from 'react-toastify';
import { IMatchBasicInfo } from 'app/shared/model/match-basic-info.model';
import { translate } from 'react-jhipster';
import { IPlayer } from 'app/shared/model/player.model';
import { downloadFileFromRes } from 'app/shared/util/helper';
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
  ISearchObjectParams,
  Pageable,
} from 'app/shared/reducers/reducer.utils';

export interface IDataPlayerMom {
  id: number;
  matchId: number;
  clubId: number;
  playerId: number;
  type: number;
  orderNumber: number;
  playerDTOS: IFormPlayerDTO;
  originType: number;
  point: number | string;
  mom: number;
}

export interface ISummaryReport {
  shoot: string;
  effectiveShoot: string;
  cornerKick: string;
  kick: string;
  warning: string;
  penaltyKick: string;
  foul: string;
  offSide: string;
  ban: string;
  ownerGoal: string;
  awayGoal: string;
  rate: string;
  scoreDto: {
    processDate: number;
    roundType: number;
    player: IPlayer;
    playerSupport: IPlayer;
  }[];
}
const initialState = {
  pdfLoadng: false,
  loading: false,
  errorMessage: null,
  listAwayData: [],
  listOwnerData: [],
  isEditValue: false,
  dataDetail: null,
  listWeather: [],
  listTV: [],
};

interface IParam {
  matchId: string;
  awayClubId: number;
  ownerClubId: number;
}

interface IFormData {
  newOwnerRecord: IDataPlayerMom[];
  newAwayRecord: IDataPlayerMom[];
}

const API_LOAD_LIST_MOM = 'api/get-list-match-player-fields';
const API_LOAD_RECORD_TABLE = 'api/report/player-report-after-match';
const API_SAVE_EVALUATE = 'api/match-player-fields/saveEvaluate';

export const fetchListPlayerMom = createAsyncThunk(
  'matchOver/get_list_match_player_fields',
  async ({ matchId, awayClubId, ownerClubId }: IParam) => {
    const listUrl = [
      `${API_LOAD_LIST_MOM}?matchId=${matchId}&clubId=${awayClubId}`,
      `${API_LOAD_LIST_MOM}?matchId=${matchId}&clubId=${ownerClubId}`,
    ];
    const results = await axios.all<IDataPlayerMom[]>(listUrl.map(item => axios.get(item).then(res => res.data || [])));
    return results;
  }
);

export const fetchRecordTable = createAsyncThunk(
  'matchOver/get_list_record_table',
  async ({ matchId, awayClubId, ownerClubId }: IParam) => {
    const listUrl = [
      `${API_LOAD_RECORD_TABLE}?matchId=${matchId}&clubId=${awayClubId}`,
      `${API_LOAD_RECORD_TABLE}?matchId=${matchId}&clubId=${ownerClubId}`,
      `/api/report-player/summary-report?matchId=${matchId}&clubId=${awayClubId}`,
      `/api/report-player/summary-report?matchId=${matchId}&clubId=${ownerClubId}`,
    ];
    const results = await axios.all<IDataPlayerMom[]>(listUrl.map(item => axios.get(item).then(res => res.data || [])));
    return results;
  }
);

export const postDataScoreAndMom = createAsyncThunk(
  'matchOver/save_match_player_fields',
  async ({ newOwnerRecord, newAwayRecord }: IFormData) => {
    return axios.all<IDataPlayerMom[]>([axios.post(API_SAVE_EVALUATE, newOwnerRecord), axios.post(API_SAVE_EVALUATE, newAwayRecord)]);
  }
);

// get info
const apiUrl = 'api/match-basic-infos/detail';
export const getDetail = createAsyncThunk('matchBasicInfo/fetch_entity', async (id: string | number) => {
  const requestUrl = `${apiUrl}/${id}`;
  return axios.get<IMatchBasicInfo>(requestUrl);
});

// get weather
export const getWeather = createAsyncThunk('match/getWeather', async (lang: string) => {
  const result = await axios.get(`/api/category-data-by-code?code=WEATHER&lang=${lang}`);
  return result;
});

// get TV list
export const getListTV = createAsyncThunk('match/getListTV', async (lang: string) => {
  const result = await axios.get(`/api/category-data-by-code?code=TV&lang=${lang}`);
  return result.data.dataTrans;
});

// download
export const downloadCSV = createAsyncThunk(
  'matchOver/downloadCSV',
  async ({ matchId, lang, zoneId }: any) => {
    const requestUrl = `/api/report/download-match-report?matchId=${matchId}&language=${lang}&zoneId=${zoneId}`;
    const res = (await axios.get(requestUrl, {
      responseType: 'blob',
    })) as any;

    downloadFileFromRes({ blob: res.data, fileName: `${translate('dashboard.matchReport')}.pdf` });
  },
  {
    serializeError: serializeAxiosError,
  }
);

export const MatchOverSlice = createSlice({
  name: 'matchOver',
  initialState,
  reducers: {
    setIsEditValue: (state, action) => ({ ...state, isEditValue: action.payload }),
    subcribeRecordTable: (state, action) => ({
      ...state,
      listAwayData: action.payload.playerReportMatchAwayClubList,
      listOwnerData: action.payload.playerReportMatchOwnerClubList,
      summaryReportAway: action.payload.matchSummaryAwayClub,
      summaryReportOwner: action.payload.matchSummaryOwnerClub,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(getDetail.fulfilled, (state, action) => {
        state.dataDetail = action.payload.data;
      })
      .addCase(getWeather.fulfilled, (state, action) => {
        state.listWeather = action.payload.data;
      })
      .addCase(getListTV.fulfilled, (state, action) => {
        state.listTV = action.payload;
      })

      .addMatcher(isPending(fetchListPlayerMom, postDataScoreAndMom, fetchRecordTable), state => {
        return {
          ...state,
          loading: true,
          listAwayData: [],
          listOwnerData: [],
          summaryReportAway: [],
          summaryReportOwner: [],
        };
      })
      .addMatcher(isPending(downloadCSV), (state, action) => {
        return {
          ...state,
          pdfLoadng: true,
        };
      })
      .addMatcher(isFulfilled(downloadCSV), (state, action) => {
        return {
          ...state,
          pdfLoadng: false,
        };
      })
      .addMatcher(isRejected(downloadCSV), (state, action) => {
        return {
          ...state,
          pdfLoadng: false,
        };
      })
      .addMatcher(isFulfilled(fetchListPlayerMom, fetchRecordTable), (state, action) => {
        const data = action.payload;
        return {
          ...state,
          loading: false,
          listAwayData: data[0],
          listOwnerData: data[1],
          summaryReportAway: data[2],
          summaryReportOwner: data[3],
        };
      })

      .addMatcher(isFulfilled(postDataScoreAndMom), (state, action) => {
        toast.success(translate('common.saveSuccess'));
        return {
          ...state,
          loading: false,
          isEditValue: false,
        };
      });
  },
});

export const { setIsEditValue } = MatchOverSlice.actions;
// Reducer
export default MatchOverSlice.reducer;
