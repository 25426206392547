import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, ConfigProvider, Empty, Form, Input, Row, Select, Tooltip } from 'antd';
import ToastDialog from 'app/shared/util/toastDialog/ToastDialog';
import './index.scss';
import { useAppSelector } from 'app/config/store';
import { Translate, translate } from 'react-jhipster';
import { ChervonLeft, ChervonRightGray } from 'app/shared/util/appIcon';
import { validateSizeImage } from 'app/shared/util/entity-utils';
import { getBase64 } from 'app/shared/reducers/reducer.utils';
import { CallLoginIcon, PersonLoginIcon, UserGroupIcon } from 'app/shared/util/authIcon';
import { useRequest } from 'ahooks';
import { accuracyOwnerRequest, getCurrentUser, uploadImage } from './account.service';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import NumberField from 'app/modules/lm-input-number';

type Props = any;

const AccountChangeInfo = (props: Props) => {
  const account = useAppSelector(state => state.authentication.account);
  const [confirmDialog, setConfirmDialog] = React.useState<boolean>(false);
  const [dataUnit, setDataUnit] = useState([]);
  const [toastElement, setToastElement] = React.useState<React.ReactElement>(null);
  const [file, setFile] = useState<File>(null);
  const [previewImg, setPreviewImg] = useState('');
  const [image, setImage] = useState(null);

  const history = useHistory();
  const [form] = Form.useForm();

  const handleUnit = async () => {
    const dataUnits = await axios.get<any>(`api/unit-standards/search`);
    const arrUnit = [];
    dataUnits.data.content.map(a =>
      arrUnit.push({
        value: a.id,
        label: a.name,
        key: a.id.toString(),
      })
    );
    setDataUnit(arrUnit);
  };

  const requestGetAccount = useRequest(getCurrentUser, {
    manual: true,
    onSuccess(res) {
      form.setFieldsValue({
        ...res.data,
        imageUrl: res.data.imageUrl,
      });
      setImage(res.data.imageUrl);
      setPreviewImg(res.data.imageUrlBase64Avt);
    },
    onError() {
      toast.error(translate('error.internalServerError'));
    },
  });
  React.useEffect(() => {
    handleUnit();
  }, []);

  React.useEffect(() => {
    requestGetAccount.run();
  }, []);

  const requestOwner = useRequest(accuracyOwnerRequest, {
    manual: true,
    onSuccess(res: any) {
      toast.success(translate('common.saveSuccess'));
      history.push('/account-management');
    },
    onError(rej: any) {
      toast.error(translate('error.internalServerError'));
    },
  });

  const requestFile = useRequest(uploadImage, {
    manual: true,
    onSuccess(res) {
      getBase64(file, result => {
        setPreviewImg(result);
      });
      setImage(res.data);
    },
    onError(err) {
      toast.error(translate('error.internalServerError'));
    },
  });

  const handlePreviewAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileImg = e.target.files[0];
    const formdata = new FormData();
    formdata.append('file', fileImg);
    setFile(fileImg);
    requestFile.run(formdata);
    // if (validateSizeImage(fileImg)) {
    //     getBase64(fileImg, result => {
    //         setPreviewImg(result);
    //     });
    // }
  };

  const handleSubmit = value => {
    const submitValue = {
      ...value,
      imageUrl: image,
    };
    requestOwner.run(submitValue);
  };

  return (
    <Row align="middle" justify="center" style={{ flexGrow: 1 }}>
      <Col xl={8} lg={12} md={16} xs={24}>
        <div>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <div className="account-card">
                <div className="account-card-header header-has-border">
                  <p className="header-title heading-24-bold neutral-1">{translate('account.title.change_info')}</p>
                  <p className="header-subtitle heading-16-medium neutral-3">{translate('account.subtitle.change_info')}</p>
                </div>
                <div className="account-card-body">
                  <Form
                    form={form}
                    name="control-hooks"
                    layout="vertical"
                    requiredMark={'optional'}
                    onFinish={value => {
                      handleSubmit(value);
                    }}
                  >
                    <Row gutter={[12, 12]}>
                      <Col span={24}>
                        <Form.Item
                          name="imageUrl"
                          label={translate('account.form.image')}
                          rules={[
                            {
                              required: true,
                              message: translate('account.required.image'),
                            },
                          ]}
                        >
                          <div>
                            <div className="input-width">
                              <label htmlFor="uploadImage" className="upload-file">
                                {previewImg ? (
                                  <img src={previewImg} alt="logo" style={{ width: '100%', height: '100%' }} />
                                ) : (
                                  <span>+ TẢI ẢNH</span>
                                )}
                              </label>
                              <span style={{ fontSize: '12px' }} className="primary-color">
                                <Translate contentKey="leagueApp.club.home.uploadimg" />
                              </span>
                              <Input id="uploadImage" hidden type="file" accept="image/*" onChange={handlePreviewAvatar} />
                            </div>
                          </div>
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          name="fullName"
                          label={translate('account.form.name1')}
                          rules={[
                            {
                              required: true,
                              message: translate('account.required.name1'),
                            },
                          ]}
                        >
                          <Input prefix={<PersonLoginIcon />} placeholder={translate('global.form.username.placeholder1')} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="phoneNumber"
                          label={translate('account.form.phone')}
                          rules={[
                            {
                              required: true,
                              message: translate('account.required.phone'),
                            },
                            {
                              pattern: /^[0-9]/,
                              message: translate('register.messages.validate.phonenumber.pattern'),
                            },
                          ]}
                        >
                          <NumberField
                            prefix={<CallLoginIcon />}
                            negative={true}
                            placeholder={translate('global.form.phonenumber.placeholder')}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="unitStandardId"
                          label={translate('account.form.unit')}
                          rules={[{ required: true, message: translate('account.required.unit') }]}
                          data-cy="unitStandardId"
                        >
                            <Select
                              disabled={true}
                              className="custom-prefix-select"
                              placeholder={translate('register.unit.placeholder')}
                              allowClear
                              showSearch
                              filterOption={(input, option) =>
                                (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                              }
                              suffixIcon={
                                <>
                                  <UserGroupIcon />
                                </>
                              }
                            >
                              {dataUnit.map(roles => (
                                <Select.Option value={roles.value} key={roles.value}>
                                  {roles.label}
                                </Select.Option>
                              ))}
                            </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Button type="primary" htmlType="submit" className="w-100" style={{ height: 56 }} loading={requestOwner.loading}>
                          {translate('account.button.save')}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
          <ToastDialog
            open={confirmDialog}
            onOk={() => {
              setConfirmDialog(false);
            }}
            content={toastElement}
          />
        </div>
      </Col>
    </Row>
  );
};

export default AccountChangeInfo;
