import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Player from './player';
import PlayerDetail from './player-detail';
import { PlayerUpdate } from './player-update';
import PlayerDeleteDialog from './player-delete-dialog';
import CreateNewPlayer from './player-create-dialog';
import PlayerAcademic from '../player-academic/player-academic';
import PlayingTime from './playing-time';
const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CreateNewPlayer} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={PlayerUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:playerId`} component={PlayerDetail} />
      <ErrorBoundaryRoute exact path={`${match.url}/player-academic`} component={PlayerAcademic} />
      <ErrorBoundaryRoute exact path={`${match.url}/time-playing`} component={PlayingTime} />
      <ErrorBoundaryRoute path={match.url} component={Player} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={PlayerDeleteDialog} />
  </>
);

export default Routes;
