import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  defaultValue,
  IDownloadTemplateMatch,
  IExportTemplateMatch,
  IFormCreateMatchs,
  IItemUploadedMatchError,
  IMatch,
  IUploadTemplateMatch,
} from 'app/shared/model/match.model';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { downloadFileFromRes } from 'app/shared/util/helper';

interface MatchState extends EntityState<IMatch> {
  uploading: boolean;
  exporting: boolean;
  downloading: boolean;
  matchsUploadedError?: IItemUploadedMatchError[];
}

const initialState: MatchState = {
  loading: false,
  uploading: false,
  exporting: false,
  downloading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  type: '',
  createSuccess: false,
  deleteSuccess: false,
  saveSuccess: false,
  matchsUploadedError: [],
};

const apiUrl = 'api/matches';
const apiUrlSearch = 'api/matches/search';
const apiUrlGetEntity = '/api/matches/list-match';
const apiUrlGetDetail = 'api/matches/detail-match';
const apiUrlDownloadFile = 'api/download-template';
const apiUrlImportFile = 'api/import/msexcel';
const apiReImportMatchs = 'api/matches-all';
const apiExportMatchs = 'api/export-match';

// Actions

export const getEntities = createAsyncThunk(
  'match/league-match',
  async ({ page, size, sort, leagueId, ...res }: IQueryParams, thunkAPI) => {
    const requestUrl = `${apiUrlSearch}${sort ? `?page=${page}&size=${size}&sort=${sort}` : '?'}`;
    const result = await axios.post<IMatch[]>(requestUrl, { ...res, leagueId });
    return result;
  }
);

export const getEntity = createAsyncThunk(
  'match/league-match-entity',
  async (id: IQueryParams, thunkAPI) => {
    const requestUrl = `${apiUrlGetDetail}/${id}`;
    return axios.get<IMatch>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'match/create_entity',
  async (entity: IMatch, thunkAPI) => {
    const result = await axios.post<IMatch>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ leagueId: result?.data?.leagueId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'match/update_entity',
  async (entity: IMatch, thunkAPI) => {
    const result = await axios.put<IMatch>(`${apiUrl}/${entity.id}`, entity);
    thunkAPI.dispatch(getEntities({ leagueId: result?.data?.leagueId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'match/partial_update_entity',
  async (entity: IMatch, thunkAPI) => {
    const result = await axios.patch<IMatch>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'match/delete_entity',
  async (deleteMatch: any, thunkAPI) => {
    const requestUrl = `${apiUrl}/${deleteMatch}`;
    const result = await axios.delete<IMatch>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const downloadAttachFileEntity = createAsyncThunk(
  'match/download_attach_file',
  async (entity: IDownloadTemplateMatch) => {
    const requestUrl = `${apiUrlDownloadFile}?templateType=${entity.templateType}&language=${entity.lang}&zoneId=${entity.zoneId}`;
    const result = (await axios.post<IDownloadTemplateMatch>(requestUrl, entity, {
      responseType: 'blob',
    })) as any;

    downloadFileFromRes({ blob: result.data, fileName: entity.fileName });
  },
  {
    serializeError: serializeAxiosError,
  }
);

export const exportMatchsEntity = createAsyncThunk(
  'match/export_attach_file',
  async (entity: IExportTemplateMatch) => {
    const requestUrl = `${apiExportMatchs}?type=${entity.type}&language=${entity.lang}&zoneId=${entity.zoneId}`;
    const result = (await axios.post<IExportTemplateMatch>(requestUrl, entity.filter, {
      responseType: 'blob',
    })) as any;

    downloadFileFromRes({ blob: result.data, fileName: entity.fileName });
  },
  {
    serializeError: serializeAxiosError,
  }
);

export const uploadAttachFileEntity = createAsyncThunk(
  'match/upload_attach_file',
  async (entity: IUploadTemplateMatch, thunkAPI) => {
    const formData = new FormData();
    formData.append('file', entity.file);

    const requestUrl = `${apiUrlImportFile}?leagueId=${entity.leagueId}&language=${entity.lang}&zoneId=${entity.zoneId}`;
    const result = (await axios.post<IUploadTemplateMatch>(requestUrl, formData)) as any;

    return result;
  },
  {
    serializeError: serializeAxiosError,
  }
);

export const reUploadMatchsEntity = createAsyncThunk(
  'match/re_import_matchs',
  async (entity: IFormCreateMatchs, thunkAPI) => {
    const requestUrl = `${apiReImportMatchs}?zoneId=${entity.zoneId}`;
    const result = (await axios.post<IFormCreateMatchs>(requestUrl, entity.matchs)) as any;

    return result;
  },
  {
    serializeError: serializeAxiosError,
  }
);

// slice
export const LeagueMatchSlice = createSlice({
  name: 'match',
  initialState,
  reducers: {
    clearMsgUpdate: (state, action) => void (state.updateSuccess = null),
    clearMsgDelete: (state, action) => void (state.deleteSuccess = null),
    clearMsgCreate: (state, action) => void (state.createSuccess = null),
  },
  extraReducers(builder) {
    builder
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.deleteSuccess = true;
        state.entity = {};
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(downloadAttachFileEntity.fulfilled, state => {
        state.errorMessage = null;
        state.loading = false;
      })
      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
        state.entities = undefined;
      })
      .addMatcher(isPending(getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      // upload file
      .addMatcher(isPending(uploadAttachFileEntity, reUploadMatchsEntity), (state, action) => {
        state.uploading = true;
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isFulfilled(reUploadMatchsEntity, uploadAttachFileEntity), (state, action) => {
        const { data } = action.payload;
        const newData = data?.map((itemUploaded, index) => {
          return {
            ...itemUploaded,
            id: index,
          };
        });

        state.uploading = false;
        state.loading = false;
        state.errorMessage = null;
        state.matchsUploadedError = newData;
      })
      .addMatcher(isRejected(uploadAttachFileEntity, reUploadMatchsEntity), (state, action) => {
        state.uploading = false;
        state.errorMessage = null;
        state.loading = false;
      })
      // export file
      .addMatcher(isPending(exportMatchsEntity), (state, action) => {
        state.exporting = true;
        state.errorMessage = null;
      })
      .addMatcher(isFulfilled(exportMatchsEntity), (state, action) => {
        state.exporting = false;
        state.errorMessage = null;
      })
      .addMatcher(isRejected(exportMatchsEntity), (state, action) => {
        state.exporting = false;
        state.errorMessage = null;
      })
      // download file
      .addMatcher(isPending(downloadAttachFileEntity), (state, action) => {
        state.downloading = true;
        state.errorMessage = null;
      })
      .addMatcher(isFulfilled(downloadAttachFileEntity), (state, action) => {
        state.downloading = false;
        state.errorMessage = null;
      })
      .addMatcher(isRejected(downloadAttachFileEntity), (state, action) => {
        state.downloading = false;
        state.errorMessage = null;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
        state.dataUploadFile = null;
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.createSuccess = true;
        state.entity = action.payload.data;
        state.dataUploadFile = null;
      });
  },
});

export const { clearMsgUpdate, clearMsgCreate, clearMsgDelete } = LeagueMatchSlice.actions;

// Reducer
export default LeagueMatchSlice.reducer;
