import { ConfigProvider, Empty, Table } from 'antd';
import type { FormInstance } from 'antd/es/form';
import React from 'react';
import { translate } from 'react-jhipster';
import EditableCell from './edit-table-cell';
import EditTableRow from './edit-table-row';

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditTable = ({ dataSource, columns, onChange, ...rest }: any) => {
  const handleSave = row => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => {
      return row.id === item.id;
    });

    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    onChange && onChange(newData);
  };

  const components = {
    body: {
      row: EditTableRow,
      cell: EditableCell,
    },
  };

  const currentColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        required: col.required,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <>
      <ConfigProvider renderEmpty={() => <Empty description={translate('common.noDataTable')} />}>
        <Table
          {...rest}
          components={components}
          rowClassName={() => 'editable-row'}
          bordered={false}
          dataSource={dataSource}
          columns={currentColumns as ColumnTypes}
        />
      </ConfigProvider>
    </>
  );
};

export default EditTable;
