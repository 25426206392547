import { Button, Col, Modal, Radio, Row } from 'antd';
import { PositionInMatch } from 'app/shared/util/constant';
import React, { useEffect } from 'react';
import StadiumImage from 'app/assets/pick-position.png';
import { Translate, translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import { actionRecord } from 'app/shared/util/constant';

interface Props {
  open: boolean;
  setOpen: () => void;
  value?: string | null;
  onSubmit: (position: string) => void;
  type?: string;
  corner?: boolean;
}

const PickPosition = ({ open, setOpen, value, onSubmit, type, corner }: Props) => {
  const [position, setPosition] = React.useState(value);
  useEffect(() => {
    setPosition(value);
  }, [value]);
  const handleSubmitSubmit = () => {
    if (type === actionRecord.PENALTY_KICK) {
      if (position === PositionInMatch.CENTER_PA_AWAY || position === PositionInMatch.CENTER_PA_OWNER) {
        onSubmit(position);
        return;
      } else {
        toast.error('Choose only penalty location');
        return;
      }
    }
    if (!position) {
      toast.error('Please choose location');
      return;
    }
    onSubmit(position);
  };
  React.useEffect(() => {
    setPosition(value);
  }, [value]);

  return (
    <Modal
      className="pick-position"
      visible={open}
      width={1200}
      onCancel={setOpen}
      onOk={handleSubmitSubmit}
      // closable={false}
      // zIndex={2}
      footer={null}
    >
      <div style={{ overflow: 'auto' }}>
        <div
          className="stadium"
          style={{
            background: `url(${StadiumImage}) no-repeat`,
            backgroundSize: '910px auto',
            backgroundPosition: 'center',
          }}
        >
          <Radio.Group onChange={e => setPosition(e.target.value)} className="full-size" value={position}>
            <div className="full-size" style={{ display: 'flex' }}>
              <div style={{ width: 157, display: 'flex', flexDirection: 'column', padding: '5px 5px' }}>
                <div className="quarter-box-top">
                  <Radio.Button className="box-md" value={PositionInMatch.NEAR_CONNER_R_OWNER}>
                    {translate('pickPosition.NEAR_CONNER_R')}
                  </Radio.Button>
                  <Radio.Button disabled={corner} className="box-md" value={PositionInMatch.OUT_SIDE_PA_R_OWNER}>
                    {translate('pickPosition.OUT_SIDE_PA_R')}
                  </Radio.Button>
                  <Radio.Button disabled={corner} className="box-md" value={PositionInMatch.PA_R_OWNER}>
                    {translate('pickPosition.PA_R')}
                  </Radio.Button>
                </div>
                <Row className="w-100 quarter-box-middle box-horizontal">
                  <Col style={{ width: 37 }}>
                    <Row className="h-100" style={{ rowGap: 5 }}>
                      <Col xs={24}>
                        <Radio.Button disabled={corner} className="full-size flex-text-center" value={PositionInMatch.GA_R_OWNER}>
                          {translate('pickPosition.GA_R')}
                        </Radio.Button>
                      </Col>
                      <Col xs={24}>
                        <Radio.Button disabled={corner} className="full-size flex-text-center" value={PositionInMatch.CENTER_GA_OWNER}>
                          {translate('pickPosition.CENTER_GA')}
                        </Radio.Button>
                      </Col>
                      <Col xs={24}>
                        <Radio.Button disabled={corner} className="full-size flex-text-center" value={PositionInMatch.GA_L_OWNER}>
                          {translate('pickPosition.GA_L')}
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col style={{ marginLeft: 10, flexGrow: 1 }}> */}
                  <Col>
                    <Radio.Button
                      disabled={corner}
                      className="full-size d-flex align-items-center span-58px"
                      value={PositionInMatch.CENTER_PA_OWNER}
                    >
                      {translate('pickPosition.CENTER_PA')}
                    </Radio.Button>
                  </Col>
                </Row>
                <div className="quarter-box-bottom">
                  <Radio.Button disabled={corner} className="box-md" value={PositionInMatch.PA_L_OWNER}>
                    {translate('pickPosition.PA_L')}
                  </Radio.Button>
                  <Radio.Button disabled={corner} className="box-md" value={PositionInMatch.OUTSIDE_PA_L_OWNER}>
                    {translate('pickPosition.OUTSIDE_PA_L')}
                  </Radio.Button>
                  <Radio.Button className="box-md" value={PositionInMatch.NEAR_CONNER_L_OWNER}>
                    {translate('pickPosition.NEAR_CONNER_L')}
                  </Radio.Button>
                </div>
              </div>
              <div style={{ flexGrow: 1 }}>
                <Row className="h-100" style={{ rowGap: 5, padding: 5 }}>
                  <Col xs={24}>
                    <Row className="position-relative" style={{ height: 145 }}>
                      <Col xs={12}>
                        <Radio.Button
                          disabled={corner}
                          className="flex-text-center text-GA position-top-right"
                          value={PositionInMatch.HALFLINE_R_OWNER}
                        >
                          {translate('pickPosition.HALFLINE_R')}
                        </Radio.Button>
                        <Radio.Button
                          disabled={corner}
                          className="flex-text-center full-size transparent"
                          value={PositionInMatch.MF_R_OWNER}
                        >
                          {translate('pickPosition.MF_R')}
                        </Radio.Button>
                      </Col>
                      <Col xs={12}>
                        <Radio.Button
                          disabled={corner}
                          className="flex-text-center text-GA position-top-left"
                          value={PositionInMatch.HALFLINE_L_AWAY}
                        >
                          {translate('pickPosition.HALFLINE_L')}
                        </Radio.Button>
                        <Radio.Button
                          disabled={corner}
                          className="flex-text-center full-size transparent"
                          value={PositionInMatch.MF_L_AWAY}
                        >
                          {translate('pickPosition.MF_L')}
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24}>
                    <Row className="position-relative" style={{ height: 285 }}>
                      <div className="position-center">
                        <Row>
                          <Col xs={12}>
                            <Radio.Button
                              disabled={corner}
                              className="flex-text-center text-GA position-center"
                              value={PositionInMatch.CENTER}
                            >
                              {translate('pickPosition.CENTER')}
                            </Radio.Button>
                          </Col>
                        </Row>
                      </div>

                      <Col xs={12} className="position-relative">
                        <div className="left-penalty-area">
                          <Row className="h-100">
                            <Col xs={24}>
                              <Radio.Button
                                disabled={corner}
                                className="flex-text-end text-GA full-size"
                                value={PositionInMatch.FPA_OWNER}
                                style={{ justifyContent: 'flex-end' }}
                              >
                                <div style={{ width: 35 }}>
                                  <span>{translate('pickPosition.FPA')}</span>
                                </div>
                              </Radio.Button>
                            </Col>
                          </Row>
                          <Row className="h-100 ban-area-left">
                            <Col xs={24}>
                              <Radio.Button
                                disabled={corner}
                                className="flex-text-center text-GA full-size"
                                value={PositionInMatch.FPA_R_OWNER}
                              >
                                {translate('pickPosition.FPA_R')}
                              </Radio.Button>
                            </Col>
                            <Col xs={24}>
                              <Radio.Button
                                disabled={corner}
                                className="flex-text-center text-GA full-size"
                                value={PositionInMatch.CENTER_FPA_OWNER}
                              >
                                {translate('pickPosition.CENTER_FPA')}
                              </Radio.Button>
                            </Col>
                            <Col xs={24}>
                              <Radio.Button
                                disabled={corner}
                                className="flex-text-center text-GA full-size"
                                value={PositionInMatch.FPA_L_OWNER}
                              >
                                {translate('pickPosition.FPA_L')}
                              </Radio.Button>
                            </Col>
                          </Row>
                        </div>

                        <Radio.Button
                          disabled={corner}
                          className="flex-text-center full-size transparent"
                          value={PositionInMatch.CENTER_MF_OWNER}
                        >
                          {translate('pickPosition.CENTER_MF')}
                        </Radio.Button>
                      </Col>
                      <Col xs={12} className="position-relative">
                        <div className="right-penalty-area">
                          <Row className="h-100">
                            <Col xs={24}>
                              <Radio.Button
                                disabled={corner}
                                className="flex-text-start text-GA full-size "
                                value={PositionInMatch.FPA_AWAY}
                                style={{ justifyContent: 'flex-start' }}
                              >
                                <div style={{ width: 35 }}>
                                  <span>{translate('pickPosition.FPA')}</span>
                                </div>
                              </Radio.Button>
                            </Col>
                          </Row>
                          <Row className="h-100 ban-area-right">
                            <Col xs={24}>
                              <Radio.Button
                                disabled={corner}
                                className="flex-text-center text-GA full-size"
                                value={PositionInMatch.FPA_R_AWAY}
                              >
                                {translate('pickPosition.FPA_R')}
                              </Radio.Button>
                            </Col>
                            <Col xs={24}>
                              <Radio.Button
                                disabled={corner}
                                className="flex-text-center text-GA full-size"
                                value={PositionInMatch.CENTER_FPA_AWAY}
                              >
                                {translate('pickPosition.CENTER_FPA')}
                              </Radio.Button>
                            </Col>
                            <Col xs={24}>
                              <Radio.Button
                                disabled={corner}
                                className="flex-text-center text-GA full-size"
                                value={PositionInMatch.FPA_L_AWAY}
                              >
                                {translate('pickPosition.FPA_L')}
                              </Radio.Button>
                            </Col>
                          </Row>
                        </div>
                        <Radio.Button
                          disabled={corner}
                          className="flex-text-center full-size transparent"
                          value={PositionInMatch.CENTER_MF_AWAY}
                        >
                          {translate('pickPosition.CENTER_MF')}
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24}>
                    <Row style={{ height: 145 }}>
                      <Col xs={12}>
                        <Radio.Button
                          disabled={corner}
                          className="flex-text-center text-GA position-bottom-right"
                          value={PositionInMatch.HALFLINE_L_OWNER}
                        >
                          {translate('pickPosition.HALFLINE_L')}
                        </Radio.Button>
                        <Radio.Button
                          disabled={corner}
                          className="flex-text-center full-size transparent"
                          value={PositionInMatch.MF_L_OWNER}
                        >
                          {translate('pickPosition.MF_L')}
                        </Radio.Button>
                      </Col>
                      <Col xs={12}>
                        <Radio.Button
                          disabled={corner}
                          className="flex-text-center text-GA position-bottom-left"
                          value={PositionInMatch.HALFLINE_R_AWAY}
                        >
                          {translate('pickPosition.HALFLINE_R')}
                        </Radio.Button>
                        <Radio.Button
                          disabled={corner}
                          className="flex-text-center full-size transparent"
                          value={PositionInMatch.MF_R_AWAY}
                        >
                          {translate('pickPosition.MF_R')}
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="text-right" style={{ width: 157, display: 'flex', flexDirection: 'column', padding: '5px 5px' }}>
                <div className="quarter-box-top">
                  <Radio.Button className="box-md" value={PositionInMatch.NEARCONNER_L_AWAY}>
                    {translate('pickPosition.NEAR_CONNER_L')}
                  </Radio.Button>
                  <Radio.Button disabled={corner} className="box-md" value={PositionInMatch.OUTSIDE_PA_L_AWAY}>
                    {translate('pickPosition.OUTSIDE_PA_L')}
                  </Radio.Button>
                  <Radio.Button disabled={corner} className="box-md" value={PositionInMatch.PA_L_AWAY}>
                    {translate('pickPosition.PA_L')}
                  </Radio.Button>
                </div>
                <Row className="w-100 quarter-box-middle position-relative">
                  {/* <Col style={{ marginRight: 10, flexGrow: 1 }}> */}
                  <Col>
                    <Radio.Button
                      disabled={corner}
                      className="full-size d-flex align-items-center span-58px"
                      value={PositionInMatch.CENTER_PA_AWAY}
                    >
                      {translate('pickPosition.CENTER_PA')}
                    </Radio.Button>
                  </Col>
                  <Col style={{ width: 37 }}>
                    <Row className="h-100" style={{ rowGap: 5 }}>
                      <Col xs={24}>
                        <Radio.Button disabled={corner} className="full-size flex-text-center" value={PositionInMatch.GA_L_AWAY}>
                          {translate('pickPosition.GA_L')}
                        </Radio.Button>
                      </Col>
                      <Col xs={24}>
                        <Radio.Button disabled={corner} className="full-size flex-text-center" value={PositionInMatch.CENTER_GA_AWAY}>
                          {translate('pickPosition.CENTER_GA')}
                        </Radio.Button>
                      </Col>
                      <Col xs={24}>
                        <Radio.Button disabled={corner} className="full-size flex-text-center" value={PositionInMatch.GA_R_AWAY}>
                          {translate('pickPosition.GA_R')}
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="quarter-box-bottom">
                  <Radio.Button disabled={corner} className="box-md" value={PositionInMatch.PA_R_AWAY}>
                    {translate('pickPosition.PA_R')}
                  </Radio.Button>
                  <Radio.Button disabled={corner} className="box-md" value={PositionInMatch.OUT_SIDE_PA_R_AWAY}>
                    {translate('pickPosition.OUT_SIDE_PA_R')}
                  </Radio.Button>
                  <Radio.Button className="box-md" value={PositionInMatch.NEAR_CONNER_R_AWAY}>
                    {translate('pickPosition.NEAR_CONNER_R')}
                  </Radio.Button>
                </div>
              </div>
            </div>
          </Radio.Group>
        </div>
      </div>
      <div className={`management-modal-footer ${position ? 'hasvalue' : ''}`}>
        {!position ? (
          <Button>
            <Translate contentKey="leagueApp.matchRecord.pick_position" />
          </Button>
        ) : (
          <Button key="submit" type="primary" htmlType="submit" onClick={() => handleSubmitSubmit()}>
            <Translate contentKey="leagueApp.matchRecord.save" />
          </Button>
        )}
      </div>
    </Modal>
  );
};
export default React.memo(PickPosition);
