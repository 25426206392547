import { omit, pick } from 'lodash';

export const modules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ script: 'sub' }, { script: 'super' }],
      // [{ align: [] }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ['image'],
    ],
  },
};

export const getBodyAndParams = data => {
  const body = omit(data, ['page', 'size', 'sort']);
  const params = pick(data, ['page', 'size', 'sort']);
  return { body, params };
};

export const downloadFileFromRes = ({ blob, fileName }) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
