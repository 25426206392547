import axios from 'axios';
import { createAsyncThunk, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { translate } from 'react-jhipster';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { LoadingBar } from 'react-redux-loading-bar';

const initialState = {
  loading: false,
  resetPasswordSuccess: false,
  resetPasswordSuccessInit: null,
  resetPasswordFailure: false,
  successMessageInit: null,
  resetPasswordFailInit: null,
  successMessage: null,
  data: null,
};

export type PasswordResetState = Readonly<typeof initialState>;

interface IPasswordResetInit {
  login: string;
  phoneNumber: string;
  fullName: string;
  codePublic: string;
}

interface IPasswordResetFinish {
  currentPassword: string;
  newPassword: string;
  idUser: string;
}
const apiUrl = 'api/account';
// Actions

export const handlePasswordResetInit = createAsyncThunk(
  'accuracyOwner',
  async (init: IPasswordResetInit) => {
    const data = axios.post(`${apiUrl}/accuracyOwner`, init);
    data
      .then(res => res)
      .catch(err => {
        if (err.response.status === 403) {
          toast.error(translate('error.http.403'));
        }
        return err
      });
    return data;
  },
  { serializeError: serializeAxiosError }
);

export const handlePasswordResetFinish = createAsyncThunk(
  'change-password',
  async (finish: IPasswordResetFinish) => {
    const dataFinish = axios.post(`${apiUrl}/change-password`, finish);
    dataFinish
      .then(res => res)
      .catch(err => {
        if (err.response.status === 403) {
          toast.error(translate('error.http.403'));
        }
        return err
      });
    return dataFinish;
  },
  { serializeError: serializeAxiosError }
);

export const PasswordResetSlice = createSlice({
  name: 'change-password',
  initialState: initialState as PasswordResetState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(handlePasswordResetInit.fulfilled, (state, action) => ({
        ...initialState,
        loading: false,
        resetPasswordSuccess: true,
        successMessageInit: 'reset.request.messages.success',
        data: action.payload.data,

      }))
      .addCase(handlePasswordResetFinish.fulfilled, (state, action) => ({
        ...initialState,
        loading: false,
        resetPasswordSuccess: true,
        successMessage: 'reset.finish.messages.success',
        currentPass: action.payload.data.codePublic,
        idUser: action.payload.data.id,
      }))
      .addMatcher(isPending(handlePasswordResetInit, handlePasswordResetFinish), state => {
        state.loading = true;
        state.resetPasswordFailInit= null
      })
      .addMatcher(isRejected(handlePasswordResetInit, handlePasswordResetFinish), () => ({
        ...initialState,
        loading: false,
        resetPasswordFailure: true,
        resetPasswordFailInit: 'reset.request.messages.fail'
      }));
  },
});

export const { reset } = PasswordResetSlice.actions;

// Reducer
export default PasswordResetSlice.reducer;
