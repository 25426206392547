import React, { useEffect, useState } from 'react';
import { log, translate, Translate } from 'react-jhipster';

import { Avatar, Button, Col, ConfigProvider, Dropdown, Empty, Form, Menu, Row, Select, Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Filter from 'app/modules/filter';
import MultiFilter from 'app/modules/MultiSearch';
import { useFilter } from 'app/shared/hooks/useFilter';
import { useLocation, useParams } from 'react-router-dom';
import { IFormLeagueName, IFormLeagueStand } from 'app/shared/model/record-room.model';
import { getLeagueName, getStandard, getYear } from '../record-room/recordRoom.reducer';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import { getLeague, resetValueRanking } from './match-center.reducer';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { getClubRanking } from './match-center.reducer';
import { FilterParams } from 'app/shared/model/filter.model';
import Table from 'app/modules/table';
import { INTERMINABLE } from 'app/shared/util/constant/favorite.constant';

export const ClubRanking = () => {
  const [sort, setSort] = useState<string>();
  const param = useParams<{ id: string; params: string }>();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const dataStandard: IFormLeagueStand[] = useAppSelector(state => state.recordRoomReducer.dataStandard)?.content;
  const dataListClubRanking = useAppSelector(state => state.matchCenterReducer.listClubRanking);
  const loading = useAppSelector(state => state.matchCenterReducer.loading);
  const listLeague = useAppSelector(state => state.matchCenterReducer.listLeague);
  const [disable, setDisable] = useState(false);
  const lsId = Form.useWatch('lsId', form);
  const lId = Form.useWatch('leagueId', form);

  const defaultFilter = {
    page: 0,
    size: 10,
    type: 'name',
  };
  const columns = [
    {
      title: <Translate contentKey="recordRoom.lookupLeague.position"></Translate>,
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.teamName">Club Name</Translate>,
      dataIndex: 'clubName',
      key: 'clubName',
      render: (value, record) => (
        <>
          <Avatar src={record?.clubAvatar} size="large" style={{ marginRight: 8 }} />
          <span>{record?.clubName}</span>
        </>
      ),
      sorter: (a, b) => a.clubName - b.clubName,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.played">Played</Translate>,
      dataIndex: 'totalMatch',
      key: 'totalMatch',
      sorter: (a, b) => a.totalMatch - b.totalMatch,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.score1">Score</Translate>,
      dataIndex: 'totalScores',
      key: 'totalScores',
      sorter: (a, b) => a.totalScores - b.totalScores,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.won">Won</Translate>,
      dataIndex: 'totalWin',
      key: 'totalWin',
      sorter: (a, b) => a.totalWin - b.totalWin,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.drawn">Drawn</Translate>,
      dataIndex: 'totalBalance',
      key: 'totalBalance',
      sorter: (a, b) => a.totalBalance - b.totalBalance,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.lost">Lost</Translate>,
      dataIndex: 'totalLost',
      key: 'totalLost',
      sorter: (a, b) => a.totalLost - b.totalLost,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.goalFor">Goal for</Translate>,
      dataIndex: 'totalGoal',
      key: 'totalGoal',
      sorter: (a, b) => a.totalGoal - b.totalGoal,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.goalAgainst">Goal against</Translate>,
      dataIndex: 'totalGoalConceded',
      key: 'totalGoalConceded',
      sorter: (a, b) => a.totalGoalConceded - b.totalGoalConceded,
    },
    {
      title: <Translate contentKey="recordRoom.lookupLeague.point1">Point</Translate>,
      dataIndex: 'difference',
      key: 'difference',
      sorter: (a, b) => a.difference - b.difference,
    },
  ];
  useEffect(() => {
    dispatch(
      getStandard({
        page: 0,
        size: 1000,
        body: {},
      })
    );
    dispatch(getYear());
    return () => {
      resetValueRanking();
    };
  }, []);
  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
  });
  const handleChangeStandard = (value: number) => {
    if (value) {
      form.setFieldsValue({
        leagueId: undefined,
      });
    }
    dispatch(getLeague(value));
    dispatch(resetValueRanking());
  };

  const handleSubmit = data => {
    dispatch(getClubRanking({ leagueId: data.leagueId, sort }));
  };

  const handleSort = (params: FilterParams) => {
    if (lId) {
      dispatch(getClubRanking({ leagueId: lId, sort: params.sort }));
      setSort(params.sort);
    }
  };

  return (
    <div className="management-layout">
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <h2 id="player-heading" data-cy="PlayerHeading" className="heading-20-bold neutral-1">
          <Translate contentKey="matchCenter.teamRanking" />
        </h2>
      </div>
      <Form
        form={form}
        scrollToFirstError
        name="control-hooks"
        onFinishFailed={values => handleFocusFieldFail(values)}
        onFinish={handleSubmit}
      >
        <div>
          <Row gutter={[32, 32]}>
            <Col xl={6} lg={12} xs={24}>
              <Form.Item name="lsId" label={<Translate contentKey="recordRoom.lookupLeague.standardPrize">Standard Prize</Translate>}>
                  <Select
                    placeholder={translate('recordRoom.placeholder.standardPrize')}
                    style={{ width: '100%' }}
                    onChange={handleChangeStandard}
                    className="custom-prefix-select"
                    suffixIcon={<SelectArrowDown />}
                    disabled={disable}
                  >
                    {dataStandard?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item name="leagueId" label={<Translate contentKey="recordRoom.lookupLeague.leagueName">League Name</Translate>}>
                  <Select
                    placeholder={translate('recordRoom.placeholder.leagueName')}
                    disabled={!lsId}
                    style={{ width: '100%' }}
                    onChange={() => form.submit()}
                    className="custom-prefix-select"
                    suffixIcon={<SelectArrowDown />}
                  >
                    {listLeague?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
      <Row>
        <Col span={24}>
          <Table
            showSorterTooltip={false}
            columns={columns}
            loading={loading}
            dataSource={dataListClubRanking}
            pagination={false}
            filter={{ sort, size: INTERMINABLE }}
            scroll={{
              x: 'calc(700px + 50%)',
            }}
            onChange={handleSort}
          />
        </Col>
      </Row>
    </div>
  );
};
