import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Radio, Row } from 'antd';
import { FILE_TYPE } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IDownloadTemplateMatch, IUploadTemplateMatch } from 'app/shared/model/match.model';
import { useEffect, useRef, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LeagueMatchEdittableModal from './league-match-edittable-modal';
import { downloadAttachFileEntity, uploadAttachFileEntity } from './league-match.reducer';

const LeagueMatchUploadDialog = (props: any) => {
  const fileType = [
    {
      label: <span className="neutral-3">{translate('leagueApp.match.upload.typeExcel')}</span>,
      value: FILE_TYPE.EXCEL,
    },
    {
      label: <span className="neutral-3">{translate('leagueApp.match.upload.typeCSV')}</span>,
      value: FILE_TYPE.CSV,
    },
  ];

  const { isOpen, ids, onClose } = props;
  const dispatch = useAppDispatch();
  const { matchsUploadedError, uploading, exporting, downloading } = useAppSelector(state => state.LeagueMatch);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const [form] = Form.useForm();
  const params = useParams<{ id: string }>();
  const inputRef = useRef<HTMLInputElement>();

  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [dataMatchUploaded, setDataMatchUploaded] = useState([]);
  const [fileSelected, setFileSelected] = useState<File>(undefined);

  const uiEvents = {
    closeUploadModal(showUploadList) {
      form.resetFields();
      setFileSelected(undefined);
      onClose && onClose(showUploadList);
    },
    openPreview() {
      setShowPreview(true);
    },
    closePreview() {
      setShowPreview(false);
    },
    removeFileSelected() {
      setFileSelected(undefined);
    },
  };

  const onFileChange = (event: any) => {
    const file = event.target.files[0] as File;
    setFileSelected(file);
  };

  const onMatchUploadedChange = newUploadData => {
    setDataMatchUploaded(newUploadData);
  };

  const handleDownloadFile = values => {
    const requestParams: IDownloadTemplateMatch = {
      templateType: values.fileType,
      matchIds: ids,
      fileName: values.fileType === FILE_TYPE.EXCEL ? 'match_template.xlsx' : 'match_template.csv',
      lang: locale,
      zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    dispatch(downloadAttachFileEntity(requestParams));
  };

  const handleImportFile = () => {
    const requestParams: IUploadTemplateMatch = {
      leagueId: params.id,
      file: fileSelected,
      lang: locale,
      zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    dispatch(uploadAttachFileEntity(requestParams)).then((res: any) => {
      if (res?.payload?.data?.length > 0) {
        uiEvents.openPreview();
      } else if (res?.error) {
        toast.error(translate(`error.${res?.error?.response?.data?.errorKey ? res.error.response.data.errorKey : 'SYSTEM_ERROR'}`));
      } else {
        toast.success(translate('leagueApp.match.upload.success'));
        uiEvents.closeUploadModal(true);
      }
    });
  };

  useEffect(() => {
    if (!matchsUploadedError) return;
    setDataMatchUploaded(matchsUploadedError);
  }, [matchsUploadedError]);

  return (
    <>
      <Modal
        visible={isOpen}
        onCancel={() => uiEvents.closeUploadModal(false)}
        width={900}
        title={<Translate contentKey="leagueApp.league.leagueMatch.uploadTitle" />}
        wrapClassName="management-wrapper-modal pb-4"
        footer={null}
      >
        <div className="upload-match-body mb-4">
          <Form
            form={form}
            name="control-hooks"
            layout="vertical"
            onFinish={handleDownloadFile}
            initialValues={{
              fileType: FILE_TYPE.EXCEL,
            }}
          >
            <Row gutter={[24, 24]} className="w-100">
              <Col xs={24}>
                <Form.Item name="fileType" label={translate('leagueApp.match.upload.fileType')}>
                  <Radio.Group className="w-100">
                    <Row gutter={[24, 24]}>
                      {fileType.map((typeItem, idx) => {
                        return (
                          <Col xs={24} md={4} key={idx}>
                            <Radio value={typeItem.value}>{typeItem.label}</Radio>
                          </Col>
                        );
                      })}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Row gutter={[24, 12]}>
            <Col xs={24} md={12}>
              <Button
                loading={downloading}
                className="w-100"
                onClick={() => {
                  form.submit();
                }}
              >
                <Translate contentKey="clubmng.download" />
              </Button>
            </Col>
            <Col xs={24} md={12}>
              {fileSelected ? (
                <>
                  <Button loading={uploading} className="w-100" onClick={handleImportFile} type="primary">
                    <Translate contentKey="clubmng.import" />
                  </Button>
                </>
              ) : (
                <>
                  <input type={'file'} hidden onChange={onFileChange} ref={inputRef} />
                  <Button
                    className="w-100"
                    type="primary"
                    onClick={() => {
                      inputRef.current.value = '';
                      inputRef.current && inputRef.current.click();
                    }}
                  >
                    <Translate contentKey="clubmng.upload" />
                  </Button>
                </>
              )}
            </Col>
            {fileSelected && (
              <>
                <Col xs={24} md={12}></Col>
                <Col xs={24} md={12}>
                  <div className="w-100 d-flex justify-content-between align-items-center ">
                    <span>{fileSelected.name}</span>
                    <CloseCircleOutlined className="ms-2 cursor-pointer" onClick={uiEvents.removeFileSelected} />
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>
      </Modal>

      <LeagueMatchEdittableModal
        isOpen={showPreview}
        onClose={showUploadList => {
          uiEvents.closePreview();
          showUploadList && onClose && onClose(showUploadList);
          setFileSelected(undefined);
        }}
        data={dataMatchUploaded}
        onChange={onMatchUploadedChange}
      />
    </>
  );
};

export default LeagueMatchUploadDialog;
