import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ILeagueStandard, defaultValue } from 'app/shared/model/league-standard.model';

const initialState: EntityState<ILeagueStandard> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  createSuccess: false,
  deleteSuccess: false,
  dataUploadFile: null,
};

const apiUrl = 'api/league-standards';
const apiUrlSearch = 'api/league-standards/search';

// Actions

export const getEntities = createAsyncThunk('leagueStandard/fetch_entity_list', async ({ page, size, sort, ...res }: IQueryParams) => {
  const requestUrl = `${apiUrlSearch}${sort ? `?page=${page}&size=${size}&sort=${sort}` : '?'}`;
  return axios.post<ILeagueStandard[]>(requestUrl, res);
});

export const getEntity = createAsyncThunk(
  'leagueStandard/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ILeagueStandard>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const upLoadImg = createAsyncThunk('player/upLoadImg?uploadType=AVATAR_PLAYER', async (body: FormData) => {
  const uploadResult = await axios.post<any>(`api/file/upload-file?uploadType=AVATAR_LEAGUE_STANDARD`, body);
  return uploadResult;
});

export const createEntity = createAsyncThunk(
  'leagueStandard/create_entity',
  async (entity: ILeagueStandard, thunkAPI) => {
    const result = await axios.post<ILeagueStandard>(apiUrl, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'leagueStandard/update_entity',
  async (entity: ILeagueStandard, thunkAPI) => {
    const result = await axios.put<ILeagueStandard>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'leagueStandard/partial_update_entity',
  async (entity: ILeagueStandard, thunkAPI) => {
    const result = await axios.patch<ILeagueStandard>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'leagueStandard/delete_entity',
  async (deleteLeagueStandard: any, thunkAPI) => {
    const requestUrl = `${apiUrl}/${deleteLeagueStandard}`;
    const result = await axios.delete<ILeagueStandard>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const LeagueStandardSlice = createEntitySlice({
  name: 'leagueStandard',
  initialState,
  reducers: {
    clearMsgUpdate: (state, action) => void (state.updateSuccess = null),
    clearMsgDelete: (state, action) => void (state.deleteSuccess = null),
    clearMsgCreate: (state, action) => void (state.createSuccess = null),
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(upLoadImg.fulfilled, (state, action) => {
        state.dataUploadFile = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.deleteSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
        state.dataUploadFile = null;
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.createSuccess = true;
        state.entity = action.payload.data;
        state.dataUploadFile = null;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, clearMsgUpdate, clearMsgCreate, clearMsgDelete } = LeagueStandardSlice.actions;

// Reducer
export default LeagueStandardSlice.reducer;
