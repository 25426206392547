import { Avatar, Col, Row, Tag } from 'antd';
import { IPlayerInMatch } from 'app/shared/model/player.model';
import { first } from 'lodash';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { getColor } from './list-item';

interface Prop {
  subPlayerList: IPlayerInMatch[];
  onClickSubPlayer: (player: IPlayerInMatch) => void;
}

const SubLineUp = ({ subPlayerList, onClickSubPlayer }: Prop) => {
  return (
    <div className="sub-lineup-container h-100">
      <div className="sub-lineup-header">
        <span className="title-18-bold neutral-1">
          <Translate contentKey="sub_player" />
        </span>
        <span className="title-18-medium neutral-2">{first(subPlayerList)?.matchPlayerFieldDTO.playerDTOS.clubName}</span>
      </div>
      <div className="sub-lineup-content">
        <Row>
          {subPlayerList.map((item, index) => (
            <Col xl={6} lg={8} xs={2} className="sub-lineup-item" key={item.matchPlayerFieldDTO.playerDTOS?.id || index}>
              <Tag color={getColor(item.matchPlayerFieldDTO.playerDTOS?.position)}>{item.matchPlayerFieldDTO.playerDTOS?.position}</Tag>
              <p className="text-small font-weight-bold mb-0">
                {item.matchPlayerFieldDTO.playerDTOS?.uniformNumber}.{item.matchPlayerFieldDTO.playerDTOS?.koName}
              </p>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default SubLineUp;
