import React from 'react';
import { Card, Col, Row, Tabs } from 'antd';
import CardButton from 'app/shared/components/card-button/card-button';
import {
  ClubManagementIcon,
  MonitorManagementIcon,
  PlayerManagementIcon,
  StadiumManagement,
  TournamentManagerIcon,
} from 'app/shared/util/appIcon';
import { Redirect, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
// import './match-master-data.scss';
import { translate, Storage, Translate } from 'react-jhipster';
import { useAppSelector } from 'app/config/store';
import './style.scss';
import { FavoriteTournament } from './tourament-favorite';
import { FavoriteClub } from './club-favorite';
import { FavoritePlayer } from './player-favorite';
import { FavoriteMatch } from './match-favorite';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

export const FavoriteTable = () => {
  const location = useLocation();
  const account = useAppSelector(state => state.authentication.account);
  const foundRecorder = account?.authorities.find(function (element) {
    return element === 'ROLE_RECORDER';
  });
  const foundTeamManager = account?.authorities.find(function (element) {
    return element === 'ROLE_TEAM_MANAGER';
  });
  const foundLeagueMaster = account?.authorities.find(function (element) {
    return element === 'ROLE_LEAGUE_MASTER';
  });
  const foundAdmin = account?.authorities.find(function (element) {
    return element === 'ROLE_ADMIN';
  });
  const { push } = useHistory();

  const path = {
    tournament: '/main/tournament',
    club: '/main/club',
    player: '/main/player',
    match: '/main/match',
  };

  return (
    <Card title={<Translate contentKey="home.fav" />} className="dashboard-info-card">
      <Row>
        <Tabs type="card" activeKey={location.pathname} onChange={activeKey => push({ pathname: activeKey, search: '' })}>
          {(foundAdmin || foundLeagueMaster) && (
            <Tabs.TabPane
              tab={
                <div>
                  <Translate contentKey="home.tourament" />
                </div>
              }
              key={path.tournament}
            ></Tabs.TabPane>
          )}
          {(foundAdmin || foundTeamManager) && (
            <Tabs.TabPane
              tab={
                <div>
                  <Translate contentKey="home.club" />
                </div>
              }
              key={path.club}
            ></Tabs.TabPane>
          )}
          {(foundAdmin || foundLeagueMaster || foundTeamManager) && (
            <Tabs.TabPane
              tab={
                <div>
                  <Translate contentKey="home.player" />
                </div>
              }
              key={path.player}
            ></Tabs.TabPane>
          )}
          {(foundAdmin || foundRecorder || foundLeagueMaster) && (
            <Tabs.TabPane
              tab={
                <div>
                  <Translate contentKey="home.matchRecord" />
                </div>
              }
              key={path.match}
            ></Tabs.TabPane>
          )}
        </Tabs>
        <Switch>
          <ErrorBoundaryRoute exact path={path.tournament} component={FavoriteTournament} />
          <ErrorBoundaryRoute exact path={path.club} component={FavoriteClub} />
          <ErrorBoundaryRoute exact path={path.player} component={FavoritePlayer} />
          <ErrorBoundaryRoute exact path={path.match} component={FavoriteMatch} />
          {foundRecorder ? (
            <Redirect to={path.match} />
          ) : foundTeamManager ? (
            <Redirect to={path.club} />
          ) : (
            <Redirect to={path.tournament} />
          )}
        </Switch>
      </Row>
    </Card>
  );
};
