import React from 'react';
import { Avatar, Button, Card, Col, Row, Skeleton } from 'antd';
import './goal-ranking-card.scss';
import Tag from 'antd/es/tag';
import { EditOutlined, EllipsisOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { ChervonLeft } from 'app/shared/util/appIcon';
import { useRequest } from 'ahooks';
import { topGoal, topGoalV2 } from '../../dashboard.service';
import { useAppSelector } from 'app/config/store';
import { translate } from 'react-jhipster';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

export const GoalRankingCard = props => {
  const { idLeague } = props;
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const [itemsGoal, setItemsGoal] = React.useState(null);
  // const date = dayjs.utc(new Date()).startOf('date').format();
  const date = dayjs(new Date()).startOf('date').toISOString();
  const request = useRequest(topGoalV2, {
    manual: true,
    onSuccess(res) {
      setItemsGoal(res.data);
    },
    onError(err) {},
  });

  React.useEffect(() => {
    if (idLeague) {
      request.run({
        leagueId: idLeague,
        now: date,
      });
    }
  }, [idLeague]);

  const topOneRankNode = team => {
    return (
      <li key={0} style={{ borderBottom: '1px solid #fafafa', paddingBottom: '6px' }}>
        <Row>
          <Col span={12}>
            <div>
              <b>
                {1}. {currentLocale === 'ko' ? team.koName : team.enName}
              </b>
              <div>
                <ul>
                  <li>
                    <b className="primary-color">{team.scored}</b> {translate('dashboard.scores')}
                  </li>
                  <li style={{ marginTop: 12 }}>{team.clubName}</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Avatar size={90} src={team.playerBase64Avt} shape="circle" />
          </Col>
        </Row>
      </li>
    );
  };
  const rankNode = (team, index) => {
    return (
      <li key={index + 1} style={{ padding: '8px 0px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: 195 }}>
            <Avatar size={40} src={team.playerBase64Avt} shape="circle"></Avatar>
            <span style={{ marginLeft: 12 }}>
              {index + 1}. {currentLocale === 'ko' ? team.koName : team.enName}
            </span>
          </div>
          <p className="neutral-2" style={{ textAlign: 'center', justifyContent: 'center', fontSize: 14 }}>
            {team.clubName}
          </p>
          <div style={{ width: 70, textAlign: 'right' }}>
            {team.scored} {translate('dashboard.scores')}
          </div>
        </div>
      </li>
    );
  };

  return (
    <Card
      title={translate('dashboard.topScore')}
      extra={
        request.loading || !itemsGoal || itemsGoal.length === 0 ? (
          ''
        ) : (
          <Link to={{ pathname: '/lookup-player', state: { itemsGoal } }} className="primary-color">
            <span>{translate('entity.action.detail')}</span> <ChervonLeft />
          </Link>
        )
      }
    >
      {request.loading || !itemsGoal || itemsGoal.length === 0 ? (
        // <Skeleton active />
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {translate('dashboard.noData')}
        </div>
      ) : (
        <ul>
          {itemsGoal.map((team, index) => {
            return index === 0 ? topOneRankNode(team) : rankNode(team, index);
          })}
        </ul>
      )}
    </Card>
  );
};

export default GoalRankingCard;
