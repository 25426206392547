import dayjs from 'dayjs';

export interface IAttachFile {
  id?: number;
  name?: string;
  nameFileServer?: string;
  url?: string;
  file?: File;
  typeFile?: string;
}

export interface INotice {
  id?: number;
  noticeCategory?: string | null;
  title?: string | null;
  unitStandardId?: number | null;
  content?: string | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  attachmentDTOS?: IAttachFile[];
  nextNoticeDTO?: INotice;
  previousNoticeDTO?: INotice;
}

export const defaultValue: Readonly<INotice> = {};
