import React, { useState, useEffect } from 'react';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount, translate } from 'react-jhipster';

import { Card, Col, Form, Modal, Radio, Row, Space, Button } from 'antd';
import { PickItem } from 'app/modules/pick-item';
import * as FetchData from './player-free-corner-kick.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { SCORE_INFO } from './constant.free-corner-record';

export const PlayerFreeCornerKickRecord = ({ pageChoose, ...props }) => {
  const scoreType = Form.useWatch('goalType');
  const listScoreFormKick = useAppSelector(state => state.PlayerFreeCornerKick.listScoreFormKick);
  const nameScoreFormKick = useAppSelector(state => state.PlayerFreeCornerKick.nameScoreFormKick);
  const listShootDirectionKick = useAppSelector(state => state.PlayerFreeCornerKick.listShootDirectionKick);
  const nameShootDirectionKick = useAppSelector(state => state.PlayerFreeCornerKick.nameShootDirectionKick);
  const listTypeShootKick = useAppSelector(state => state.PlayerFreeCornerKick.listTypeShootKick);
  const nameTypeShootKick = useAppSelector(state => state.PlayerFreeCornerKick.nameTypeShootKick);
  const listTypeFailKick = useAppSelector(state => state.PlayerFreeCornerKick.listTypeFailKick);
  const nameTypeFailKick = useAppSelector(state => state.PlayerFreeCornerKick.nameTypeFailKick);
  const dispatch = useAppDispatch();
  const locale = useAppSelector(state => state.locale.currentLocale);

  const score = [
    { value: 1, label: translate('freeCornerKick.score.success') },
    { value: 0, label: translate('freeCornerKick.score.fail') },
  ];

  useEffect(() => {
    dispatch(FetchData.fetchCateFreeCornerKick(locale));
  }, []);

  const rules = { required: pageChoose === SCORE_INFO, message: '' };
  const requiredSuccess = { required: Number(scoreType) === 1 && pageChoose === SCORE_INFO, message: '' };

  return (
    <Row {...props} wrap={false}>
      <Col className={`first-header ${Number(scoreType) !== 1 && Number(scoreType) !== 0 ? 'last-header' : ''}`}>
        <PickItem
          name="goalType"
          list={score}
          getValue={item => item.value}
          getLabel={item => item.label}
          title={translate('freeCornerKick.score.title')}
          rules={[rules]}
        />
      </Col>
      <Row style={Number(scoreType) === 1 ? {} : { display: 'none' }} wrap={false}>
        <Col>
          <PickItem
            name="goalForm"
            list={listScoreFormKick}
            getValue={item => item.value}
            getLabel={item => item.label}
            title={translate('freeCornerKick.goalscorerForm')}
            rules={[requiredSuccess]}
          />
        </Col>
        <Col>
          <PickItem
            name="shootDirection"
            list={listShootDirectionKick}
            getValue={item => item.value}
            getLabel={item => item.label}
            title={translate('freeCornerKick.shot')}
            rules={[requiredSuccess]}
          />
        </Col>
        <Col className="last-header">
          <PickItem
            name="shootType"
            list={listTypeShootKick}
            getValue={item => item.value}
            getLabel={item => item.label}
            title={translate('freeCornerKick.shotType')}
            rules={[requiredSuccess]}
          />
        </Col>
      </Row>
      <Col style={Number(scoreType) === 0 ? {} : { display: 'none' }} className="last-header">
        <PickItem
          name="reasonFail"
          list={listTypeFailKick}
          getValue={item => item.value}
          getLabel={item => item.label}
          title={translate('freeCornerKick.failureReason')}
          rules={Number(scoreType) === 0 && [rules]}
        />
      </Col>
    </Row>
  );
};

export default PlayerFreeCornerKickRecord;
