import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';

import { AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { clearMsgCreate, clearMsgDelete, clearMsgUpdate, deleteEntity, getEntities } from './staff.reducer';
import { hasAnyAuthority, isRole } from 'app/shared/auth/private-route';
import { toast } from 'react-toastify';
import { StaffListFilter } from './staff.type';
import { useFilter } from 'app/shared/hooks/useFilter';
import { Button, Col } from 'antd';
import Filter from 'app/modules/filter';
import MultiSearch from 'app/modules/MultiSearch';
import Table from 'app/modules/table';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';
import StaffAddDialog from './staff-add-dialog';
import { PlusIcon } from 'app/shared/util/appIcon';

export const Staff = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
    // type: 'name',
  };
  const [idUpdate, setIdUpdate] = useState('');
  const staffList = useAppSelector(state => state.staffReducer.entities);
  const totalItems = useAppSelector(state => state.staffReducer.totalItems);
  const loading = useAppSelector(state => state.staffReducer.loading);
  const deleteSuccess = useAppSelector(state => state.staffReducer.deleteSuccess);
  const updateSuccess = useAppSelector(state => state.staffReducer.updateSuccess);
  const createSuccess = useAppSelector(state => state.staffReducer.createSuccess);
  const [reset, setReset] = useState(false);
  const account = useAppSelector(state => state.authentication.account);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);
  const isTeamManager = isRole(authorities, [AUTHORITIES.TEAM_MANAGER]);
  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.staff.delete.deleteSuccess'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (createSuccess) {
      toast.success(translate('leagueApp.staff.created.success'));
      dispatch(clearMsgCreate(createSuccess));
      setShowDialog(false);
      handleFetchData({ ...filter, page: 0 });
    }
  }, [createSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      toast.success(translate('leagueApp.staff.updated.success'));
      dispatch(clearMsgUpdate(updateSuccess));
      setShowDialog(false);
      handleFetchData({ ...filter, page: 0 });
    }
  }, [updateSuccess]);

  const handleDeleteModal = (user: any) => {
    setUserClicked(user);
    setModalDelete(true);
  };

  const handleDelete = () => {
    dispatch(deleteEntity(userClicked)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        updateFilter();
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const handleFetchData = React.useCallback((params: StaffListFilter) => {
    dispatch(getEntities(params));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  //   /** new **/
  const handleOpenModal = () => {
    setIdUpdate(null);
    setShowDialog(true);
  };

  //   /** update **/
  const handleUpdate = id => {
    setIdUpdate(id);
  };

  React.useEffect(() => {
    if (idUpdate) {
      setShowDialog(true);
    }
  }, [idUpdate]);

  const updateFilter = () => {
    onFilterToQueryString({
      page: 0,
      size: 10,
      sort: 'id,asc',
      type: 'name',
    });
    setReset(!reset);
  };

  const ActionButton = ({ id }: { id: number }) => {
    return (
      <div className={(isAdmin || isTeamManager) && 'column-action-button'}>
        <Button onClick={() => handleUpdate(id)} className="rounded">
          <Translate contentKey="entity.action.edit" />
        </Button>
        {(isAdmin || isTeamManager) && (
          <Button onClick={() => handleDeleteModal(id)} className="rounded" danger type="primary">
            <Translate contentKey="entity.action.delete2">Delete</Translate>
          </Button>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: <Translate contentKey="leagueApp.staff.name"></Translate>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <Translate contentKey="leagueApp.staff.phone" />,
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: <Translate contentKey="leagueApp.staff.email" />,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: <Translate contentKey="leagueApp.coach.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 230,
      render: (value, record) => <ActionButton id={value} />,
      align: 'center' as any,
    },
  ];

  const options = [
    {
      value: 'name',
      label: translate('leagueApp.staff.name'),
    },
    {
      value: 'phone',
      label: translate('leagueApp.staff.phone'),
    },
    {
      value: 'email',
      label: translate('leagueApp.staff.email'),
    },
  ];

  // --------------------------------------

  return (
    <div className="management-layout">
      {showDialog && (
        <StaffAddDialog
          entityId={idUpdate}
          setEntityId={setIdUpdate}
          showDialog={showDialog}
          setShowDialog={b => setShowDialog(b)}
          updateFilter={updateFilter}
        />
      )}
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading">
            <Translate contentKey="leagueApp.staff.home.title"></Translate>
          </h2>
          <div className="management-layout-divider"></div>
          <Button className="rounded management-header-add-button" onClick={handleOpenModal} icon={<PlusIcon />} type="primary">
            <Translate contentKey="clubmng.add" />
          </Button>
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <Filter filter={filter} onFilter={onFilterToQueryString} options={options} multi reset={reset} />
        </Col>
      </div>
      <MultiSearch filter={filter} onFilter={onFilterToQueryString} options={options} />
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={staffList}
          totalElements={totalItems}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('leagueApp.staff.delete.question')}</h6>}
          onClose={() => setModalDelete(false)}
          onConfirm={() => handleDelete()}
        />
      )}
    </div>
  );
};

export default Staff;
