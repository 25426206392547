import axios, { AxiosResponse } from 'axios';
import { Storage, translate } from 'react-jhipster';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from './reducer.utils';

import { AppThunk } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import { get } from 'lodash';
import { IUser } from '../model/user.model';
import { toast } from 'react-toastify';
import ToastDialog from '../util/toastDialog/ToastDialog';
import React from 'react';

const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

export const initialState = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: null,
  loginError: null, // Errors returned from server side
  showModalLogin: false,
  account: {} as any,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  logoutUrl: null as unknown as string,
};

export type AuthenticationState = Readonly<typeof initialState>;

// Actions

export const getSession = (): AppThunk => async (dispatch, getState) => {
  await dispatch(getAccount());
  const { account } = getState().authentication;
  if (account && account.langKey) {
    const langKey = await Storage.session.get('locale');
    Storage.session.set('roles', JSON.stringify(account.authorities));
    Storage.session.set('login', JSON.stringify(account.login));
    Storage.session.set('userId', JSON.stringify(account.id));
    langKey && dispatch(setLocale(langKey));
  }
};

export const getAccount = createAsyncThunk('authentication/get_account', async () => axios.get<any>('api/account'), {
  serializeError: serializeAxiosError,
});

interface IAuthParams {
  username: string;
  password: string;
  rememberMe?: boolean;
}

//

export const authenticate = createAsyncThunk(
  'authentication/login',
  // async (auth: IAuthParams) =>
  //   axios
  //     .post<any>('api/authenticate', auth)
  //     .then(res => res)
  //     .catch(err => toast.error(`${err.response.data.title}`)),
  async (auth: IAuthParams) => axios.post<any>('api/authenticate', auth),
  {
    serializeError: serializeAxiosError,
  }
);

export const login: (username: string, password: string, onSuccess: () => void, onFail: () => void, rememberMe?: boolean) => AppThunk =
  (username, password, onSuccess, onFail, rememberMe = false) =>
  async dispatch => {
    await dispatch(authenticate({ username, password, rememberMe })).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        const response = res.payload as AxiosResponse;
        const bearerToken = response?.headers?.authorization;
        if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
          const jwt = bearerToken.slice(7, bearerToken.length);
          if (rememberMe) {
            Storage.local.set(AUTH_TOKEN_KEY, jwt);
          } else {
            Storage.session.set(AUTH_TOKEN_KEY, jwt);
          }
        }
        dispatch(getAccount()).then(result => {
          if (result.meta.requestStatus === 'fulfilled') {
            const account: IUser = get(result.payload, 'data');
            if (account && account.langKey) {
              const langKey = Storage.local.get('locale');
              Storage.session.set('roles', JSON.stringify(account.authorities));
              Storage.session.set('login', JSON.stringify(account.login));
              Storage.session.set('userId', JSON.stringify(account.id));
              langKey && dispatch(setLocale(langKey));
            }
            onSuccess();
          }
          if (result.meta.requestStatus === 'rejected') {
            onFail();
          }
        });
      }
      if (res.meta.requestStatus === 'rejected') {
        onFail();
      }
    });
  };

export const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }
};

export const logout: () => AppThunk = () => dispatch => {
  clearAuthToken();
  dispatch(logoutSession());
};

export const clearAuthentication = messageKey => dispatch => {
  clearAuthToken();
  dispatch(authError(messageKey));
  dispatch(clearAuth());
};

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState as AuthenticationState,
  reducers: {
    logoutSession() {
      return {
        ...initialState,
        showModalLogin: true,
      };
    },
    authError(state, action) {
      return {
        ...state,
        showModalLogin: true,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
      };
    },
    changeAuthenticate(state) {
      return {
        ...state,
        isAuthenticated: true,
      };
    },
    clearMsg: (state, action) => void (state.loginError = null),
  },

  extraReducers(builder) {
    builder
      .addCase(authenticate.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
        showModalLogin: true,
        loginError: 'login.messages.error.authentication',
      }))
      .addCase(authenticate.fulfilled, state => ({
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: 'login.messages.success',
      }))
      .addCase(getAccount.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          sessionHasBeenFetched: true,
          showModalLogin: true,
          errorMessage: action.error.message,
        };
      })
      .addCase(getAccount.fulfilled, (state, action) => {
        const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
        return {
          ...state,
          isAuthenticated,
          loading: false,
          sessionHasBeenFetched: true,
          account: action.payload.data,
        };
      })
      .addCase(authenticate.pending, state => {
        state.loading = true;
      })
      .addCase(getAccount.pending, state => {
        state.loading = true;
      });
  },
});

export const { logoutSession, authError, clearAuth, clearMsg } = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
