import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { useFilter } from 'app/shared/hooks/useFilter';
import { IParamsFilterHistories } from 'app/shared/model/user.model';
import { formatDateHour } from 'app/shared/util/date-utils';
import { Translate } from 'react-jhipster';
import { useParams } from 'react-router-dom';
import { getHistoriesEntities } from './user-management.reducer';

function ManagerHistory() {
  const INIT_FILTER = {
    page: 0,
    size: 10,
    sort: 'date,desc',
  };

  const dispatch = useAppDispatch();
  const { id: userId } = useParams<{ id: string; params: string }>();
  const { histories, loading } = useAppSelector(state => state.userManagement);

  const columns = [
    {
      title: <Translate contentKey="userManagement.detail.history.time" />,
      dataIndex: 'date',
      key: 'date',
      width: 300,
      render: (value, record) => <span>{formatDateHour(value)}</span>,
    },
    {
      title: <Translate contentKey="userManagement.detail.history.connectionIp" />,
      dataIndex: 'ip',
      key: 'ip',
      width: 300,
    },
    {
      title: <Translate contentKey="userManagement.detail.history.country" />,
      dataIndex: 'nation',
      key: 'nation',
      width: 300,
    },
    {
      title: <Translate contentKey="userManagement.detail.history.device" />,
      dataIndex: 'device',
      key: 'device',
      width: 50,
    },
  ];

  const handleFetchData = React.useCallback(
    params => {
      dispatch(getHistoriesEntities({ ...params, userId }));
    },
    [userId]
  );

  const {
    filter,
    onFilterToQueryString,
  }: { filter: IParamsFilterHistories; onFilterToQueryString: (values: IParamsFilterHistories) => void } = useFilter({
    defaultFilter: INIT_FILTER,
    onFetchData: handleFetchData,
  });

  return (
    <>
      <div className="d-flex justify-content-between align-items-center management-layout-header" style={{ paddingTop: 30 }}>
        <div className="d-flex align-items-center">
          <h2 id="player-heading" data-cy="PlayerHeading" className="heading-20-bold neutral-1">
            <Translate contentKey="userManagement.detail.history.title" />
          </h2>
        </div>
      </div>

      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={histories?.content || []}
          totalElements={histories?.totalElements || 0}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
    </>
  );
}

export default ManagerHistory;
