import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, EntityState, serializeAxiosError, ISearchObjectParams, Pageable } from 'app/shared/reducers/reducer.utils';
import { IPlayerAgent, defaultValue } from 'app/shared/model/player-agent.model';
import { IUnitStandard } from 'app/shared/model/unit-standard.model';

interface PlayerAgentState extends EntityState<IPlayerAgent> {
  playerAgent: { value: number; label: string }[];
  loading: boolean;
  errorMessage: null;
  entities: any;
  entity: any;
  updating: boolean;
  totalItems: number;
  updateSuccess: boolean;
}

const initialState: PlayerAgentState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  playerAgent: [],
};

const apiUrl = 'api/player-agents';
const getApi = 'api/player-agents/search';

// Actions

export const getPlayerAgent = createAsyncThunk(
  'playerAgent/get-playerAgent',
  async () => {
    const result = await axios.get<Pageable<IUnitStandard>>(`api/player-agents/search`);
    return result.data;
  },
  { serializeError: serializeAxiosError }
);

export const getEntities = createAsyncThunk('coach/fetch_entity_list', async ({ page, size, sort, ...res }: IQueryParams) => {
  const requestUrl = `${getApi}${sort ? `?page=${page}&size=${size}&sort=${sort}` : '?'}`;
  return axios.post<Pageable<IPlayerAgent>>(requestUrl, res);
});

export const getEntity = createAsyncThunk(
  'playerAgent/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IPlayerAgent>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'playerAgent/create_entity',
  async (entity: IPlayerAgent, thunkAPI) => {
    const result = await axios.post<IPlayerAgent>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'playerAgent/update_entity',
  async (entity: IPlayerAgent, thunkAPI) => {
    const result = await axios.put<IPlayerAgent>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'playerAgent/partial_update_entity',
  async (entity: IPlayerAgent, thunkAPI) => {
    const result = await axios.patch<IPlayerAgent>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'playerAgent/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IPlayerAgent>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const PlayerAgentSlice = createSlice({
  name: 'playerAgent',
  initialState,
  reducers: {
    clearMsgUpdate: (state, action) => void (state.updateSuccess = null),
    clearMsgDelete: (state, action) => void (state.deleteSuccess = null),
    clearMsgCreate: (state, action) => void (state.createSuccess = null),
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data?.content ?? [],
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isFulfilled(getPlayerAgent), (state, action) => {
        state.playerAgent = action.payload?.content?.map(item => ({ value: item.id, label: item.name }));
      });
  },
});

export const { clearMsgUpdate, clearMsgDelete, clearMsgCreate } = PlayerAgentSlice.actions;

// Reducer
export default PlayerAgentSlice.reducer;
