import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Col, Form, Row, Select, Space, Typography } from 'antd';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import { Translate, translate } from 'react-jhipster';
import TextField from 'app/modules/lm-input';

import { AUTHORITIES, FILE_TYPE, NOTICE_CATEGORY } from 'app/config/constants';
import { BsCaretLeft } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { answerEntity } from './answer.reducer';
import { IQuestionAnswer } from 'app/shared/model/question-answer.model';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import { IAttachFile } from 'app/shared/model/notice.model';
import { downloadAttachFileEntity } from '../notice/notice.reducer';
import { downloadFileFromRes } from 'app/shared/util/helper';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';
import { deleteEntity, getEntity } from './question-answer.reducer';
import { toast } from 'react-toastify';

const DetailAnswer = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [modalDelete, setModalDelete] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const account = useAppSelector(state => state.authentication.account);
  const foundAdmin = account?.authorities.find(function (element) {
    return element === 'ROLE_ADMIN';
  });
  const foundLeagueMaster = account?.authorities.find(function (element) {
    return element === 'ROLE_LEAGUE_MASTER';
  });
  const handleBack = e => {
    history.goBack();
  };
  const detailAnswer: IQuestionAnswer = useAppSelector(state => state.answerReducer.entity);
  const detail: IQuestionAnswer = useAppSelector(state => state.questionAnswerReducer.entity);
  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(getEntity(id)).then(resData => {
      const newData: any = resData.payload;
      if (newData?.status === 0) {
        return;
      }
      dispatch(answerEntity(id)).then(res => {
        form.setFieldsValue(res.payload);
      });
    });
  }, [id, detail?.status]);
  const downloadAttachFile = (item: IAttachFile) => {
    dispatch(downloadAttachFileEntity(item.id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        downloadFileFromRes({ blob: res.payload, fileName: `${item.name}.xlsx` });
      }
    });
  };

  const confirmDelete = useCallback(() => {
    if (!detailAnswer?.id) return;
    dispatch(deleteEntity(detailAnswer?.id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        history.push('../../question-and-answer');
        toast.success(translate('leagueApp.questionAnswer.deleteAnswerSuccess'));
      }
    });
  }, [detailAnswer?.id]);

  return (
    <>
      <div className="border-bottom d-flex justify-content-between align-items-center" style={{ paddingBottom: 30, marginBottom: 32 }}>
        <div className="d-flex align-items-center auto-wrap">
          <p className="h4 mb-0">
            <Translate contentKey="leagueApp.questionAnswer.detailAnswer" />
          </p>
        </div>
        <Col xl={10} lg={16} md={20} sm={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <div className="d-flex align-items-center auto-wrap">
            {(detail?.type === 7 && foundAdmin && account?.login === detailAnswer?.createdBy) ||
            (detail?.type !== 7 && foundLeagueMaster && account?.login === detailAnswer?.createdBy) ? (
              <>
                <Button
                  onClick={e => {
                    history.push('./update-answer');
                    e.stopPropagation();
                  }}
                  className="rounded mx-2"
                >
                  <Translate contentKey="entity.action.edit" />
                </Button>
                <Button
                  onClick={e => {
                    setModalDelete(true);
                  }}
                  className="rounded"
                  type="primary"
                  danger
                >
                  <Translate contentKey="entity.action.delete2">Delete</Translate>
                </Button>
              </>
            ) : (
              ''
            )}
          </div>
        </Col>
      </div>
      <Row gutter={[24, 24]}>
        <Col xs={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Typography.Text className="title-time">{convertDateTimeFromServer(detailAnswer?.createdDate)}</Typography.Text>
          </div>
        </Col>
        <Col xs={24} className="ql-container ql-editor">
          <div dangerouslySetInnerHTML={{ __html: detailAnswer?.content }}></div>
        </Col>
        {(detailAnswer?.attachmentDTOS && detailAnswer?.attachmentDTOS?.length !== 0) && (
          <Col xs={24}>
            <p className="file-attached">
              {' '}
              <Translate contentKey="leagueApp.notice.fileAttach" />
            </p>
            <Space size={'middle'} direction="vertical">
              {detailAnswer?.attachmentDTOS?.map((item: IAttachFile) =>
                item.typeFile === FILE_TYPE.DOCUMENT ? (
                  <span onClick={() => downloadAttachFile(item)} className="hot-link-club" key={item.url}>
                    {item.name}
                  </span>
                ) : (
                  <img
                    style={{ width: 90, height: 90, objectFit: 'contain' }}
                    className="image-attach"
                    src={item.url}
                    title={item.name}
                    onClick={() => window.open(item.url)}
                  />
                )
              )}
            </Space>
          </Col>
        )}
      </Row>
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('leagueApp.questionAnswer.deleteAnswer')}</h6>}
          onClose={() => setModalDelete(false)}
          onConfirm={() => confirmDelete()}
        />
      )}
    </>
  );
};

export default DetailAnswer;
