import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { translate } from 'react-jhipster';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

const initialState = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  findSuccess: false,
  findFailure: false,
  login: null,
};

export type findIDState = Readonly<typeof initialState>;

const apiUrl = ' api/findUserByPhoneAndName';

interface IPfindID {
  phone: string;
  fullName: string;
}

// Actions

export const findID = createAsyncThunk(
  'findId',
  async (findId: IPfindID) => {
    const data = axios.get(`${apiUrl}?phone=${findId.phone}&&fullName=${findId.fullName}`);
    data
    .then(res => res)
    .catch(err => {
      if (err.response.status === 403) {
        toast.error(translate('error.http.403'));
      }
    });
  return data;
  },
  { serializeError: serializeAxiosError }
);

export const findIdSlice = createSlice({
  name: 'findId',
  initialState: initialState as findIDState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(findID.pending, state => {
        state.errorMessage = null;
        state.findSuccess = false;
        state.loading = true;
      })
      .addCase(findID.rejected, state => {
        state.loading = false;
        state.findSuccess = false;
        state.findFailure = true;
        state.errorMessage = 'findid.messages.error';
      })
      .addCase(findID.fulfilled, (state, action) => {
        state.loading = false;
        state.findSuccess = true;
        state.findFailure = false;
        state.successMessage = 'findid.messages.success';
        state.login = action.payload.data.login
      });
  },
});

export const { reset } = findIdSlice.actions;

// Reducer
export default findIdSlice.reducer;
