import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Checkbox, Col, Row, Tooltip } from 'antd';
import { Form, Input, Button } from 'antd';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { findID, reset } from '../find-id.reducer';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { CallLoginIcon, PersonLoginIcon } from 'app/shared/util/authIcon';
import ToastDialog from 'app/shared/util/toastDialog/ToastDialog';
import NumberField from 'app/modules/lm-input-number';
import TextField from 'app/modules/lm-input';
import { ModalPrivacy } from 'app/entities/privacy-policy/privacy-modal';
import { ModalPolicy } from 'app/entities/privacy-policy/policy-model';

export const FindIDPage = () => {
  const [confirmDialog, setConfirmDialog] = React.useState<boolean>(false);
  const [toastElement, setToastElement] = React.useState<React.ReactElement>(null);
  const [successElement, setSuccessElement] = React.useState<React.ReactElement>(null);
  const [openPolicy, setOpenPolicy] = React.useState(false);
  const [openPrivacy, setOpenPrivacy] = React.useState(false);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );
  const dispatch = useAppDispatch();

  const history = useHistory();

  const handlefindID = ({ fullName, phone }) => {
    dispatch(findID({ fullName, phone }));
  };

  const handleFindSubmit = e => {
    handlefindID(e);
  };

  const handleBack = e => {
    history.goBack();
  };

  const successMessage = useAppSelector(state => state.findIdReducer.successMessage);
  const errorMesssage = useAppSelector(state => state.findIdReducer.errorMessage);
  useEffect(() => {
    if (successMessage) {
      setToastElement(translate(successMessage));
      setSuccessElement(translate('global.common.continue'));
    } else if (errorMesssage) {
      setToastElement(translate(errorMesssage));
      setSuccessElement(null);
      setConfirmDialog(true);
    }
  }, [successMessage, errorMesssage]);

  const onFinishFail = () => {
    setToastElement(
      <>
        <Translate contentKey="login.messages.error.validate"></Translate>
      </>
    );
  };

  React.useEffect(() => {
    if (toastElement) setConfirmDialog(true);
    else setConfirmDialog(false);
  }, [toastElement]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '120px 30px 30px 30px',
        minHeight: '100vh',
      }}
    >
      <Form
        className="login-form"
        layout="vertical"
        onFinish={value => handleFindSubmit(value)}
        // onFinishFailed={() => onFinishFail()}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
        requiredMark={'optional'}
      >
        {/* {loginError ? (
        <Alert color="danger" data-cy="loginError">
          <Translate contentKey="login.messages.error.authentication">
            <strong>Failed to sign in!</strong> Please check your credentials and try again.
          </Translate>
        </Alert>
      ) : null} */}

        <Row style={{ width: '100%' }} align="middle" justify="center">
          <Col span={24}>
            <h1 style={{ fontWeight: 'bold', fontSize: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Translate contentKey="login.password.forgot"></Translate>
            </h1>
          </Col>
          <Col span={24}>
            <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="neutral-3">
              <Translate contentKey="findid.question"></Translate>
            </p>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item
              name="fullName"
              label={translate('reset.finish.name')}
              rules={[{ required: true, message: translate('global.messages.validate.name.required') }]}
              data-cy="nameResetPassword"
            >
              <TextField
                placeholder={translate('global.form.name.placeholder')}
                prefix={<PersonLoginIcon />}
                // isTouched={touchedFields.username}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="phone"
              label={translate('reset.finish.phoneNumber')}
              rules={[
                { required: true, message: translate('global.messages.validate.phonenumber.required') },
                {
                  pattern: /^[0-9]/,
                  message: translate('register.messages.validate.phonenumber.pattern'),
                },
              ]}
              style={{ marginBottom: 32 }}
              data-cy="password"
            >
              <NumberField
                negative={true}
                placeholder={translate('global.form.phonenumber.placeholder')}
                style={{ width: '100%' }}
                prefix={<CallLoginIcon />}
                // iconRender={visible => (visible ? <EyeShowLoginIcon /> : <EyeHideLoginIcon />)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Button style={{ width: '100%' }} htmlType="submit" data-cy="submit" type="primary" className="auth-button">
            <Translate contentKey="login.password.forgot"></Translate>
          </Button>
        </Row>
      </Form>
      <ToastDialog
        open={confirmDialog}
        onOk={() => {
          setConfirmDialog(false);
          if (successElement) {
            history.push('/account/findid/finish');
          }
        }}
        content={toastElement}
        buttonText={translate('confirmDialog.confirmToast')}
      />
      <div style={{ marginTop: '20px', flexGrow: 1, display: 'flex', alignItems: 'end' }}>
        <Translate contentKey="footer.copyright" />
      </div>
      <div>
        <span
          onClick={() => {
            setOpenPolicy(true);
          }}
          className="privacy"
          style={{ marginRight: 40 }}
        >
          {translate('footer.privacyLogin')}
        </span>
        <span onClick={() => setOpenPrivacy(true)} className="privacy">
          {translate('footer.termLogin')}
        </span>
      </div>
      {openPrivacy && <ModalPrivacy openPrivacy={openPrivacy} setOpenPrivacy={setOpenPrivacy} />}
      {openPolicy && <ModalPolicy openPolicy={openPolicy} setOpenPolicy={setOpenPolicy} />}
    </div>
  );
};

export default FindIDPage;
