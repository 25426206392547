export enum MatchType {
  START_FIRST = 'START_FIRST',
  END_FIRST = 'END_FIRST',
  START_SECOND = 'START_SECOND',
  END_SECOND = 'END_SECOND',
  START_SUB_FIRST = 'START_SUB_FIRST',
  END_SUB_FIRST = 'END_SUB_FIRST',
  START_SUB_SECOND = 'START_SUB_SECOND',
  END_SUB_SECOND = 'END_SUB_SECOND',
  INFO = 'GET_INFO',
}
