import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Form, Button, Modal, Row, Col, Input } from 'antd';
import TextField from 'app/modules/lm-input';
import './unit-standard.scss';
import { toast } from 'react-toastify';
import { clearMsgUpdate, createEntity, getEntity, reset, updateEntity } from './unit-standard.reducer';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getBase64, getImage, upLoadImg, UploadType } from 'app/shared/reducers/reducer.utils';
import { handleFocusFieldFail, validateSizeImage } from 'app/shared/util/entity-utils';

export const UnitStandardAddDialog = (props: any) => {
  const { showDialog, setShowDialog, entityId, setEntityId, updateFilter } = props;
  const dispatch = useAppDispatch();

  const updateSuccess = useAppSelector(state => state.unitStandard.updateSuccess);
  const createSuccess = useAppSelector(state => state.unitStandard.createSuccess);
  const unitStandardEntity = useAppSelector(state => state.unitStandard.entity);
  const [previewImg, setPreviewImg] = useState('');
  const [file, setFile] = useState<File>(null);
  const [disabled, setDisabled] = useState(false);
  const [isNew, setIsNew] = useState(true);

  useEffect(() => {
    if (updateSuccess) {
      setShowDialog(false);
      setEntityId(null);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createSuccess) {
      setShowDialog(false);
      setEntityId(null);
    }
  }, [createSuccess]);

  const [entitySave, setEntitySave] = useState({
    avatar: '',
    name: '',
    description: '',
  });

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const c = !entityId;
    setIsNew(c);
    if (!c) {
      handleUpdate(entityId);
    }
  }, [entityId]);

  useEffect(() => {
    if (!entityId) return;
    if (unitStandardEntity && unitStandardEntity.id) {
      form.setFieldsValue({
        ...unitStandardEntity,
      });
      setEntitySave({ ...unitStandardEntity });
      if (unitStandardEntity.avatar) {
        getImage(unitStandardEntity.avatar, UploadType.AVATAR_UNIT_STANDARD).then(result => {
          setPreviewImg('data:image/png;base64,' + result.data);
        });
      } else {
        setPreviewImg(null);
        setFile(null);
      }
    }
  }, [unitStandardEntity, entityId]);

  function saveData(data: any, oldAvatar) {
    if (!isNew && unitStandardEntity.id) {
      data = {
        ...unitStandardEntity,
      };
    }
    data.name = form.getFieldValue('name');
    data.description = form.getFieldValue('description');
    data.avatar = oldAvatar;

    if (!isNew && data.id) {
      dispatch(updateEntity(data));
      setDisabled(false);
    } else {
      dispatch(createEntity(data));
      setDisabled(false);
    }
  }

  /** save action **/
  const handleValidSubmit = e => {
    setDisabled(true);
    /** save image **/
    const data: any = {};
    let oldAvatar = unitStandardEntity.avatar;
    if (file) {
      /** neu co file thi upload **/
      const bodyImg = new FormData();
      bodyImg.append('file', file);
      bodyImg.append('uploadType', 'AVATAR_UNIT_STANDARD');
      upLoadImg(bodyImg).then(result => {
        oldAvatar = result.data;
        saveData(data, oldAvatar);
        updateFilter();
        setShowDialog(false);
      });
    } else {
      saveData(data, oldAvatar);
      updateFilter();
      setShowDialog(false);
    }
  };

  const [form] = Form.useForm();

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };

  /** new **/
  const handleReset = () => {
    setEntitySave({
      avatar: '',
      name: '',
      description: '',
    });
    // form.resetFields();
    form.setFieldsValue({
      avatar: '',
      name: '',
      description: '',
    });
    // dispatch(reset);
    // setShowDialog(true);
    setPreviewImg(null);
    setFile(null);
    dispatch(reset);
  };

  /** update **/
  const handleUpdate = id => {
    dispatch(getEntity(id));
  };

  function closeDialog() {
    setEntityId(null);
    setShowDialog(false);
    handleReset();
  }

  const handlePreviewAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileImg = e.target.files[0];
    if (validateSizeImage(fileImg, e)) {
      setFile(fileImg);
      getBase64(fileImg, result => {
        setPreviewImg(result);
      });
    }
  };

  return (
    <React.Fragment>
      <Modal
        visible={showDialog}
        onCancel={closeDialog}
        title={
          isNew ? (
            <Translate contentKey="leagueApp.unitStandard.create.dialog" />
          ) : (
            <Translate contentKey="leagueApp.unitStandard.update.dialog" />
          )
        }
        wrapClassName="management-wrapper-modal"
        footer={null}
        width={900}
      >
        <Form
          form={form}
          onFinishFailed={values => {
            handleFocusFieldFail(values);
          }}
          scrollToFirstError
          name="control-hooks"
          onFinish={handleValidSubmit}
          layout="vertical"
          requiredMark={'optional'}
        >
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item
                name="avatar"
                label={<h6>{translate('leagueApp.unitStandard.logo')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.unitStandard.logo.required'),
                  },
                ]}
              >
                <div>
                  <div className="input-width">
                    <label htmlFor="uploadImage" className="upload-file">
                      {previewImg ? <img src={previewImg} alt="logo" style={{ width: '100%', height: '100%' }} /> : <span>+ TẢI ẢNH</span>}
                    </label>
                    <span style={{ fontSize: '12px' }} className="primary-color">
                      <Translate contentKey="leagueApp.club.home.uploadimg" />
                    </span>
                    <Input value={null} id="uploadImage" hidden type="file" accept="image/*" onChange={handlePreviewAvatar} />
                  </div>
                </div>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="name"
                label={<h6>{translate('leagueApp.unitStandard.popup.name')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.unitStandard.name.required'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.unitStandard.popup.placeholderName')} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="description"
                label={<h6>{translate('leagueApp.unitStandard.popup.description')}</h6>}
                // rules={[
                //   {
                //     required: true,
                //     message: translate('leagueApp.unitStandard.description.required'),
                //   },
                // ]}
              >
                <Input.TextArea placeholder={translate('leagueApp.unitStandard.popup.placeholderDescription')} maxLength={499} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="management-modal-footer">
                <Button key="back" onClick={closeDialog}>
                  <Translate contentKey="register.form.close" />
                </Button>
                <Button key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }} disabled={disabled}>
                  <Translate contentKey="settings.form.button" />
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default UnitStandardAddDialog;
