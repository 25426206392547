import { Button, Col, Modal, Row } from 'antd';
import React from 'react';
import { Translate } from 'react-jhipster';
import './index.scss';
type Props = {
    buttonText?: React.ReactElement | null;
    buttonRender?: React.ReactElement | null;
    content?: React.ReactElement | null;
    onOk?: () => void;
    open: boolean | null;
    children?: React.ReactNode | React.ReactNode[]
}

const ToastDialog: React.FC<Props> = ({ open, children, buttonRender, content, onOk, buttonText }) => {
  return (
    <Modal
      className="toast-dialog"
      wrapClassName="toast-wrapper-dialog"
      visible={open}
      onOk={onOk}
      closeIcon={<></>}
      footer={<></>}
      width={400}
    >
      <Row align="middle" justify="center">
        <Col>{content}</Col>
      </Row>
      <Row align="middle" justify="center" style={{ marginTop: 40, width: '100%' }}>
        <Col style={{ width: '100%' }}>
          <Button
            type="primary"
            style={{ width: '100%' }}
            onClick={() => {
              onOk();
            }}
          >
            {buttonText ? buttonText : <Translate contentKey="confirmDialog.defaultConfirm" />}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
export default ToastDialog;
