import React from 'react';
import { Timeline } from 'antd';
import { useAppSelector } from 'app/config/store';
import { IMatchTime } from 'app/shared/model/match-time.model';
import './time-line.scss';
import { ITimeLine } from '../../match-record.reducer';
import { TimelineAction } from 'app/shared/util/constant';
import { ArrowDownOutlined, ArrowUpOutlined, RetweetOutlined } from '@ant-design/icons';
import { translate } from 'react-jhipster';
import { BlueBall, ChangeIcon } from 'app/shared/util/appIcon';

const labelHalf = (index: number) => {
  switch (index) {
    case 0:
      return translate('leagueApp.matchRecord.timeLine.firstHalf');
    case 1:
      return translate('leagueApp.matchRecord.timeLine.secondHalf');
    case 2:
      return translate('leagueApp.matchRecord.timeLine.extraTime1');
    case 3:
      return translate('leagueApp.matchRecord.timeLine.extraTime2');
    default:
      return translate('leagueApp.matchRecord.timeLine.firstHalf');
  }
};

const action = (type: string, roundType: number, processDate: number, isOwner: boolean, timeOfRound = 45, timeOfRoundExtra = 15) => {
  const label = process => {
    return `${' '}${Math.ceil(process / 60)}'`;
  };
  const time = () => {
    switch (roundType) {
      case 1:
        return label(processDate);
      // case 2:
      //   return label(timeOfRound * 60 + processDate);
      // case 3:
      //   return label(timeOfRound * 2 * 60 + processDate);
      // case 4:
      //   return label((timeOfRound * 2 + timeOfRoundExtra) * 60 + processDate);

      default:
        return label(processDate);
    }
  };
  switch (type) {
    case TimelineAction.BAN_CARD_RED:
      return (
        <div className={'d-flex align-items-center justify-content-end title-timeline-item ' + (!isOwner ? ' flex-row-reverse' : '')}>
          <span className="mx-1">
            {translate('leagueApp.matchRecord.timeLine.redCard')} {time()}
          </span>
          <div className="red-card" />
        </div>
      );
    case TimelineAction.BAN_CARD_YELLOW:
      return (
        <div className={'d-flex align-items-center justify-content-end title-timeline-item ' + (!isOwner ? ' flex-row-reverse' : '')}>
          <span className="mx-1">
            {translate('leagueApp.matchRecord.timeLine.yellowCard')} {time()}
          </span>{' '}
          <div className="yellow-card" />
        </div>
      );
    case TimelineAction.SCORE:
      return (
        <div className={'d-flex align-items-center justify-content-end title-timeline-item ' + (!isOwner ? ' flex-row-reverse' : '')}>
          <span className="mx-1">
            {translate('leagueApp.matchRecord.timeLine.score')} {time()}
          </span>
          <BlueBall />
        </div>
      );
    case TimelineAction.CHANGE_PLAYER_IN:
      return (
        <div className={'d-flex align-items-center justify-content-end title-timeline-item ' + (!isOwner ? ' flex-row-reverse' : '')}>
          <span className="mx-1">
            {translate('leagueApp.matchRecord.timeLine.changePlayer')} {time()}
          </span>
          <div>
            <ChangeIcon />
          </div>
        </div>
      );

    default:
      return null;
  }
};

const renderTimelineItem = (timeline?: ITimeLine, ownerClubId?: number, timeOfRound = 45, timeOfRoundExtra = 15) => {
  if (!timeline) {
    return null;
  }
  return timeline.timeLineDTO.map((timelineItem, timelineIndex, arr) => {
    if (timelineItem.timeLineType === TimelineAction.CHANGE_PLAYER_OUT) {
      return <div />;
    }

    const isOwner = timelineItem.playerDTO.clubId === ownerClubId ? true : false;

    return (
      <Timeline.Item key={`${timelineItem.timeLineId}-${timelineIndex}`} className="py-3" position={isOwner ? 'right' : 'left'}>
        {action(timelineItem.timeLineType, timelineItem.roundType, timelineItem.processDate, isOwner, timeOfRound, timeOfRoundExtra)}
        <span className="sub-title-timeline-item">
          {timelineItem.playerDTO.uniformNumber} {timelineItem.playerDTO.koName}{' '}
          {timelineItem.timeLineType === TimelineAction.CHANGE_PLAYER_IN && <ArrowUpOutlined />}
        </span>
        {timelineItem.timeLineType === TimelineAction.CHANGE_PLAYER_IN && (
          <p className="mb-0 sub-title-timeline-item">
            {arr[timelineIndex + 1]?.playerDTO.uniformNumber} {arr[timelineIndex + 1]?.playerDTO.koName}
            {<ArrowDownOutlined />}
          </p>
        )}
      </Timeline.Item>
    );
  });
};

export const TimeLineMatch = () => {
  const matchTimes: IMatchTime = useAppSelector(state => state.matchTime?.entity);
  const timeline: ITimeLine[] = useAppSelector(state => state.matchRecord?.timeline);
  const matchInfo = useAppSelector(state => state.match.entity);

  return (
    <div className="time-line-box">
      <div className="time-line">
        {timeline
          .filter(
            (item, index) => !![matchTimes.startTime1, matchTimes.startTime2, matchTimes.startSubTime1, matchTimes.startSubTime2][index]
          )
          .map((item, index) => {
            return (
              <Timeline
                key={index}
                mode="alternate"
                className="pt-5 px-3"
                pending={
                  [matchTimes.endTime1, matchTimes.endTime2, matchTimes.endSubTime1, matchTimes.endSubTime2][index]
                    ? null
                    : translate('leagueApp.matchRecord.recording') + '...'
                }
                reverse={false}
              >
                <Timeline.Item
                  className="header-timeline"
                  dot={
                    <div className="custom-dot">
                      <p className="mb-0" style={{fontSize: 13}}>
                        {translate('leagueApp.matchRecord.timeLine.start')} {labelHalf(index)}
                      </p>
                    </div>
                  }
                  label={
                    <p style={{marginRight: 5}} className={`mb-0 text-center ${index && 'text-white'}`}> {translate('leagueApp.matchRecord.timeLine.owner')}</p>
                  }
                >
                  {<p style={{marginLeft: 11}} className={`mb-0 text-center ${index && 'text-white'}`}> {translate('leagueApp.matchRecord.timeLine.away')}</p>}
                </Timeline.Item>
                {renderTimelineItem(item, matchInfo.ownerClubId, matchInfo.timeOfRound, matchInfo.timeOfRoundExtra)}
                {[matchTimes.endTime1, matchTimes.endTime2, matchTimes.endSubTime1, matchTimes.endSubTime2][index] && (
                  <Timeline.Item
                    className="footer-timeline"
                    position="right"
                    dot={
                      <div className="custom-dot">
                        <p className="mb-0" style={{fontSize: 13}}>
                          {' '}
                          {translate('leagueApp.matchRecord.timeLine.end')} {labelHalf(index)}
                        </p>
                      </div>
                    }
                  />
                )}
              </Timeline>
            );
          })}
      </div>
    </div>
  );
};
