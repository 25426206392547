import { Form, Input, Button, Modal, Row, Col, Select, TimePicker } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import DatePicker from 'app/modules/date-picker';
import NumberField from 'app/modules/lm-input-number';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { createEntity, getEntity, updateEntity } from './league-match.reducer';
import GetClubModal from './get-club-modal';
import LeagueRecorderCreateDialog from './get-recorder-modal';
import StadiumDialog from './get-stadium-modal';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import TextField from 'app/modules/lm-input';
import locale from 'antd/es/date-picker/locale/de_DE';

export const LeagueMatchAddDialog = (props: any) => {
  const { isOpen, setIsOpen, viewDetail, setViewDetail } = props;
  const dispatch = useAppDispatch();
  const param = useParams<{ id: string; params: string }>();
  const [isNew, setIsNew] = useState(true);
  const [changeClub, setChangeClub] = useState('');
  const [getClubModal, setGetClubModal] = useState(false);
  const [modalRecorder, setModalRecorder] = useState(false);
  const [modalStadium, setModalStadium] = useState(false);
  const [createStadium, setCreateStadium] = useState(null);
  const [createRecorder, setCreateRecorder] = useState([]);
  const [createOwnerClub, setCreateOwnerClub] = useState(null);
  const [createAwayClub, setCreateAwayClub] = useState(null);
  const [entitySave, setEntitySave] = useState({
    stadiumId: '',
    stadiumName: '',
    ownerClubId: '',
    ownerClubName: '',
    awayClubId: '',
    awayClubName: '',
    recorderName: '',
    listRecorderId: '',
    matchRound: '',
    matchDate: '',
    matchHour: '',
  });
  const leagueMatchEntity = useAppSelector(state => state.LeagueMatch?.entity);
  const createSuccess = useAppSelector(state => state.LeagueMatch?.createSuccess);
  const updateSuccess = useAppSelector(state => state.LeagueMatch?.updateSuccess);
  const [form] = Form.useForm();

  useEffect(() => {
    if (createSuccess) {
      setIsOpen(false);
      setViewDetail(null);
    }
  }, [createSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      setIsOpen(false);
      setViewDetail(null);
    }
  }, [updateSuccess]);

  useEffect(() => {
    const c = !viewDetail;
    setIsNew(c);
    if (!c) {
      handleUpdate(viewDetail);
    }
  }, [viewDetail]);

  useEffect(() => {
    if (!viewDetail) return;
    if (leagueMatchEntity) {
      const listRecorder1 = leagueMatchEntity?.listRecordId?.map((item: any, index: number) => {
        return {
          id: item,
          fullName: leagueMatchEntity?.listRecordName[index],
        };
      });
      setCreateRecorder(listRecorder1);
      setCreateStadium([
        {
          id: leagueMatchEntity?.stadiumId,
          name: leagueMatchEntity?.stadiumName,
        },
      ]);
      setCreateOwnerClub([
        {
          id: leagueMatchEntity?.ownerClubId,
          name: leagueMatchEntity?.ownerClubName,
        },
      ]);
      setCreateAwayClub([
        {
          id: leagueMatchEntity?.awayClubId,
          name: leagueMatchEntity?.awayClubName,
        },
      ]);

      form.setFieldsValue({
        ...leagueMatchEntity,
        matchDate: leagueMatchEntity.startDate ? dayjs(leagueMatchEntity.startDate) : null,
        matchHour: leagueMatchEntity.startDate ? dayjs(leagueMatchEntity.startDate) : null,
        matchRound:
          leagueMatchEntity?.matchRound === 1
            ? '' + 0 + leagueMatchEntity?.matchRound
            : leagueMatchEntity?.matchRound === 2
            ? '' + 0 + leagueMatchEntity?.matchRound
            : leagueMatchEntity?.matchRound === 3
            ? '' + 0 + leagueMatchEntity?.matchRound
            : leagueMatchEntity?.matchRound === 4
            ? '' + 0 + leagueMatchEntity?.matchRound
            : leagueMatchEntity?.matchRound === 5
            ? '' + 0 + leagueMatchEntity?.matchRound
            : leagueMatchEntity?.matchRound === 6
            ? '' + 0 + leagueMatchEntity?.matchRound
            : leagueMatchEntity?.matchRound === 7
            ? '' + 0 + leagueMatchEntity?.matchRound
            : leagueMatchEntity?.matchRound === 8
            ? '' + 0 + leagueMatchEntity?.matchRound
            : leagueMatchEntity?.matchRound === 9
            ? '' + 0 + leagueMatchEntity?.matchRound
            : '' + leagueMatchEntity?.matchRound,
      });
      setEntitySave({ ...leagueMatchEntity });
    }
  }, [leagueMatchEntity, viewDetail]);

  const handleUpdate = id => {
    dispatch(getEntity(id));
  };

  /** save action **/
  const handleValidSubmit = e => {
    let data: any = {};
    if (!isNew && leagueMatchEntity.id) {
      data = {
        ...leagueMatchEntity,
      };
    }
    data.leagueId = +param.id;
    data.ownerClubId = form.getFieldValue('ownerClubId');
    data.awayClubId = form.getFieldValue('awayClubId');
    data.listRecordId = form.getFieldValue('listRecordId');
    data.stadiumId = form.getFieldValue('stadiumId');
    data.matchRound = form.getFieldValue('matchRound');
    const stringStartDate = dayjs(e.matchDate).hour(dayjs(e.matchHour).hour()).minute(dayjs(e.matchHour).minute());
    data.startDate = stringStartDate;
    if (!isNew && data.id) {
      dispatch(updateEntity(data));
    } else {
      dispatch(createEntity(data));
    }
  };
  const formatTimePicker = 'HH:mm';

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };

  function closeDialog() {
    setIsOpen(false);
    setViewDetail(false);
  }

  const handleOpenGetOwnerClubModal = () => {
    setChangeClub('OWNERCLUB');
    setGetClubModal(true);
    // setCreateOwnerClub('');
  };

  const handleOpenGetAwayClubModal = () => {
    setChangeClub('AWAYCLUB');
    setGetClubModal(true);
    // setCreateAwayClub('');
  };

  const handleOpenGetRecorderModal = () => {
    setModalRecorder(true);
    // setCreateRecorder([]);
  };

  const handleOpenStadiumModal = () => {
    setModalStadium(true);
    // setCreateStadium('');
  };
  React.useEffect(() => {
    if (createRecorder) {
      const objChoosingRecorder: any = [];
      const arrayRecorderId: any = [];
      const unique = createRecorder.map(a => objChoosingRecorder.push(a.fullName));
      const uniqueId = createRecorder.map(a =>
        arrayRecorderId.push({
          recorderId: a.id,
        })
      );
      const finalArrayId = arrayRecorderId.map(function (obj) {
        return obj.recorderId;
      });
      form.setFieldsValue({ listRecordId: finalArrayId });
      form.setFieldsValue({ recorderName: createRecorder?.length === 0 ? [] : objChoosingRecorder.join(`, `) });
    }
    if (createStadium && createStadium.length > 0) {
      const objChoosingClub: any = {
        stadiumId: Number,
        stadiumName: '',
      };
      const unique = createStadium.map(a => {
        (objChoosingClub.stadiumName = a.name), (objChoosingClub.stadiumId = a.id);
      });
      form.setFieldsValue({
        stadiumId: objChoosingClub.stadiumId,
        stadiumName: objChoosingClub.stadiumName,
      });
    }

    if (createOwnerClub && createOwnerClub.length > 0) {
      const objChoosingStadium: any = {
        ownerClubId: Number,
        ownerClubName: '',
      };
      createOwnerClub.map(a => {
        (objChoosingStadium.ownerClubName = a.name), (objChoosingStadium.ownerClubId = a.id);
      });
      form.setFieldsValue({
        ownerClubId: objChoosingStadium.ownerClubId,
        ownerClubName: objChoosingStadium.ownerClubName,
      });
    }

    if (createAwayClub && createAwayClub.length > 0) {
      const objChoosingStadium: any = {
        awayClubId: Number,
        awayClubName: '',
      };
      const unique = createAwayClub.map(a => {
        (objChoosingStadium.awayClubName = a.name), (objChoosingStadium.awayClubId = a.id);
      });
      form.setFieldsValue({
        awayClubId: objChoosingStadium.awayClubId,
        awayClubName: objChoosingStadium.awayClubName,
      });
    }
  }, [createRecorder, createStadium, createOwnerClub, createAwayClub]);

  return (
    <React.Fragment>
      {modalStadium && (
        <StadiumDialog
          modalStadium={modalStadium}
          setModalStadium={setModalStadium}
          createStadium={createStadium}
          setCreateStadium={setCreateStadium}
        />
      )}
      {modalRecorder && (
        <LeagueRecorderCreateDialog
          modalRecorder={modalRecorder}
          setModalRecorder={setModalRecorder}
          createRecorder={createRecorder}
          setCreateRecorder={setCreateRecorder}
        />
      )}
      {getClubModal && (
        <GetClubModal
          getClubModal={getClubModal}
          setGetClubModal={setGetClubModal}
          createOwnerClub={createOwnerClub}
          setCreateOwnerClub={setCreateOwnerClub}
          createAwayClub={createAwayClub}
          setCreateAwayClub={setCreateAwayClub}
          changeClub={changeClub}
          setChangeClub={setChangeClub}
        />
      )}
      <Modal
        visible={isOpen}
        onCancel={closeDialog}
        width={900}
        title={
          isNew ? (
            <Translate contentKey="leagueApp.league.leagueMatch.createTitle" />
          ) : (
            <Translate contentKey="leagueApp.league.leagueMatch.updateTitle" />
          )
        }
        wrapClassName="management-wrapper-modal"
        footer={null}
      >
        <Form
          form={form}
          name="control-hooks"
          onFinishFailed={values => handleFocusFieldFail(values)}
          scrollToFirstError
          onFinish={handleValidSubmit}
          layout="vertical"
          requiredMark={'optional'}
        >
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="matchRound"
                label={<h6>* {translate('leagueApp.match.matchRound1')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.league.required.matchRound1'),
                  },
                  // { max: 2, message: translate('leagueApp.league.required.maxMatchRound') },
                  // { min: 2, message: translate('leagueApp.league.required.maxMatchRound') },
                ]}
              >
                <TextField maxLength={50} placeholder={translate('leagueApp.league.placeholder.matchRound1')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="matchDate"
                label={<h6>{translate('leagueApp.match.required.matchDate')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.league.required.matchDate'),
                  },
                ]}
              >
                <DatePicker
                  locale={{
                    ...locale,
                    lang: {
                      ...locale.lang,
                      today: translate('entity.action.today'),
                    },
                  }}
                  placeholder={translate('leagueApp.league.placeholder.matchDate')}
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD"
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="matchHour"
                label={<h6>{translate('leagueApp.match.required.matchHours')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.league.required.matchHour'),
                  },
                ]}
              >
                <TimePicker
                  locale={{
                    ...locale,
                    lang: {
                      ...locale.lang,
                      now: translate('entity.action.now'),
                      ok: 'OK',
                    },
                  }}
                  placeholder={translate('leagueApp.league.placeholder.matchHour')}
                  format={formatTimePicker}
                  style={{ width: '100%' }}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Form.Item name="ownerClubName" label={<h6>{translate('leagueApp.match.ownerClub')}</h6>} style={{ marginBottom: 14 }}>
                <Input
                  suffix={
                    <Button className="position-absolute" style={{ right: '8px' }} onClick={handleOpenGetOwnerClubModal}>
                      <Translate contentKey="leagueApp.league.choose" />
                    </Button>
                  }
                  placeholder={translate('leagueApp.league.placeholder.ownerClub')}
                  disabled
                ></Input>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="awayClubName" label={<h6>{translate('leagueApp.match.awayClub')}</h6>} style={{ marginBottom: 14 }}>
                <Input
                  suffix={
                    <Button className="position-absolute" style={{ right: '8px' }} onClick={handleOpenGetAwayClubModal}>
                      <Translate contentKey="leagueApp.league.choose" />
                    </Button>
                  }
                  disabled
                  placeholder={translate('leagueApp.league.placeholder.awayClub')}
                ></Input>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} style={{ marginBottom: 24 }}>
              <Form.Item name="stadiumName" label={<h6>{translate('leagueApp.match.stadiumName')}</h6>} style={{ marginBottom: 14 }}>
                <Input
                  suffix={
                    <Button className="position-absolute" style={{ right: '8px' }} onClick={handleOpenStadiumModal}>
                      <Translate contentKey="leagueApp.league.choose" />
                    </Button>
                  }
                  disabled
                  placeholder={translate('leagueApp.league.placeholder.stadium')}
                ></Input>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="recorderName"
                label={<h6>{translate('leagueApp.match.recorder')}</h6>}
                validateTrigger={'onSubmit'}
                style={{ marginBottom: 14 }}
                rules={[
                  {
                    message: translate('leagueApp.league.required.lessRecorder'),

                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }
                      const arr = value.split(`, `);

                      if (arr && arr.length <= 3) {
                        return Promise.resolve();
                      }
                      return Promise.reject(translate('leagueApp.league.required.lessRecorder'));
                    },
                  },
                ]}
              >
                <Input
                  suffix={
                    <Button className="position-absolute" style={{ right: '8px' }} onClick={handleOpenGetRecorderModal}>
                      <Translate contentKey="leagueApp.league.choose" />
                    </Button>
                  }
                  disabled
                  placeholder={translate('leagueApp.league.placeholder.recorder')}
                ></Input>
              </Form.Item>
            </Col>
          </Row>
          <div className="management-modal-footer">
            <Button key="back" onClick={closeDialog}>
              <Translate contentKey="register.form.close" />
            </Button>
            <Button key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }}>
              <Translate contentKey="settings.form.button" />
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default LeagueMatchAddDialog;
