import { toast } from 'react-toastify';
import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';

import { IMatchListEventRecord } from './constant.event-list';

const initialState = {
  loading: false,
  errorMessage: null,
  matchId: null,
  ownerBall: null,
  matchPenantyPlayerRecordDTOS: [],
};

interface IParam {
  apiEndPoint: string;
  page: number;
}

export const fetchListEventRecord = createAsyncThunk('listEventRecord/fetch_list_event_record', async (url: string) => {
  return axios.get<IMatchListEventRecord>(url);
});

export const ListEventRecord = createSlice({
  name: 'matchRecord',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(isPending(fetchListEventRecord), state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addMatcher(isFulfilled(fetchListEventRecord), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
        };
      });
  },
});

// Reducer
export default ListEventRecord.reducer;
