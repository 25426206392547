import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './club.reducer';

export const ClubDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const clubEntity = useAppSelector(state => state.club.entity);
  
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="clubDetailsHeading">
          <Translate contentKey="leagueApp.club.detail.title">Club</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="leagueApp.club.id">ID</Translate>
            </span>
          </dt>
          <dd>{clubEntity.id}</dd>
          <dt>
            <span id="avatar">
              <Translate contentKey="leagueApp.club.avatar">Avatar</Translate>
            </span>
          </dt>
          <dd>{clubEntity.avatar}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="leagueApp.club.name">Name</Translate>
            </span>
          </dt>
          <dd>{clubEntity.name}</dd>
          <dt>
            <span id="shortName">
              <Translate contentKey="leagueApp.club.shortName">Short Name</Translate>
            </span>
          </dt>
          <dd>{clubEntity.shortName}</dd>
          <dt>
            <span id="issueYear">
              <Translate contentKey="leagueApp.club.issueYear">Issue Year</Translate>
            </span>
          </dt>
          <dd>{clubEntity.issueYear}</dd>
          <dt>
            <span id="managerId">
              <Translate contentKey="leagueApp.club.managerId">Manager Id</Translate>
            </span>
          </dt>
          <dd>{clubEntity.managerId}</dd>
          <dt>
            <span id="website">
              <Translate contentKey="leagueApp.club.website">Website</Translate>
            </span>
          </dt>
          <dd>{clubEntity.website}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="leagueApp.club.description">Description</Translate>
            </span>
          </dt>
          <dd>{clubEntity.description}</dd>
          <dt>
            <span id="address">
              <Translate contentKey="leagueApp.club.address">Address</Translate>
            </span>
          </dt>
          <dd>{clubEntity.address}</dd>
          <dt>
            <span id="stadiumId">
              <Translate contentKey="leagueApp.club.stadiumId">Stadium Id</Translate>
            </span>
            <UncontrolledTooltip target="stadiumId">
              <Translate contentKey="leagueApp.club.help.stadiumId" />
            </UncontrolledTooltip>
          </dt>
          <dd>{clubEntity.stadiumId}</dd>
        </dl>
        <Button tag={Link} to="/club" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/club/${clubEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ClubDetail;
