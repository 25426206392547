import { Col, ConfigProvider, Empty, InputRef, Row } from 'antd';
import { Button, Form, Input, Popconfirm, Table, Modal, Space, Spin } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { actionRecord } from 'app/shared/util/constant';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import EditableCell from './EditTableCell';
import { translate } from 'react-jhipster';
import { renderTime, urlWs, convertToNewDataTable, caculateProcessDate } from './constant.event-list';
import { useParams } from 'react-router-dom';
import { debounce, get } from 'lodash';
import { sendWSActivity, subscribeList, unsubscribe, sendRecord } from 'app/config/websocket-middleware';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { fetchListEventRecord } from './event-list-record.reducer';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';
import './index.scss';
import { APPROVAL_TYPE, AUTHORITIES } from 'app/config/constants';
export const EditableContext = React.createContext(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [isEditHalf, setIsEditHalf] = useState(false);
  const [isEditTime, setIsEditTime] = useState(false);
  const timeRef = useRef(null);
  const roundTypeRef = useRef(null);
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider
        value={{
          form,
          isEditHalf,
          setIsEditHalf,
          isEditTime,
          setIsEditTime,
          timeRef,
          roundTypeRef,
        }}
      >
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

type EditableTableProps = Parameters<typeof Table>[0];

interface Props {
  canEdit?: boolean;
  open: boolean;
  onCancel: () => void;
  modalEventList: string;
  onUpdate: (id: number, player: any) => void;
  isEndMatch: any;
}

export const urlSubscribe = (mode: string) => {
  switch (mode) {
    case actionRecord.SUBSTITUTION:
      return '/topic/player-inout/';
    case actionRecord.GOAL_KICK:
      return '/topic/goal-kick/';
    case actionRecord.FREE_KICK:
      return '/topic/player-kick/';
    case actionRecord.PENALTY_KICK:
      return '/topic/player-penalty/';
    case actionRecord.SHOOTING:
      return '/topic/player-shoot/';
    case actionRecord.SCORE:
      return '/topic/player-goal/';
    case actionRecord.FOUL:
      return '/topic/player-foul/';
    case actionRecord.OFFSIDE:
      return '/topic/player-offside/';
    case actionRecord.WARNING:
      return '/topic/player-ban/';
    case actionRecord.PENALTY_SHOOT_OUT:
      return '/topic/penalty-shoot-out/';
    case actionRecord.CORNER_KICK:
      return '/topic/connerKick/';
    case actionRecord.ASSIST:
      return '/topic/assist/';
    default:
      return '';
  }
};

const urlGetList = (mode: string) => {
  switch (mode) {
    case actionRecord.SUBSTITUTION:
      return '/api/player-in-outs/get-list?matchId=';
    case actionRecord.GOAL_KICK:
      return '/api/player-goal-kick-records/get-list?matchId=';
    case actionRecord.FREE_KICK:
      return '/api/player-kick-records/get-list?matchId=';
    case actionRecord.PENALTY_KICK:
      return '/api/player-penanty-records/get-list?matchId=';
    case actionRecord.SHOOTING:
      return 'api/player-shoot-records/get-list?matchId=';
    case actionRecord.SCORE:
      return 'api/player-goal-records/get-list?matchId=';
    case actionRecord.FOUL:
      return '/api/player-foul-records/get-list?matchId=';
    case actionRecord.OFFSIDE:
      return 'api/player-offsides/get-list?matchId=';
    case actionRecord.WARNING:
      return 'api/player-ban-records/get-list?matchId=';
    case actionRecord.PENALTY_SHOOT_OUT:
      return '/topic/player-inout?matchId=';
    case actionRecord.CORNER_KICK:
      return '/api/player-corner-kick-records/get-list?matchId=';
    case actionRecord.ASSIST:
      return '/api/assist-goal-records/get-list/';
    default:
      return '';
  }
};

const App = (props: Props) => {
  const { onCancel, open, modalEventList, onUpdate, isEndMatch, canEdit } = props;
  const [itemSelected, setItemSelected] = useState(null);
  const dispatch = useAppDispatch();
  const [dataTable, setDataTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const matchBasicInfoEntity = useAppSelector(state => state.match.entity);
  const account = useAppSelector(state => state.authentication.account);
  const onLoading = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  const isAdmin = useMemo(() => {
    return account?.authorities.some(item => item === AUTHORITIES.ADMIN);
  }, [account]);

  const isRecorder = useMemo(() => {
    return account?.authorities.some(item => item === AUTHORITIES.RECORDER);
  }, [account]);

  const isLeagueMaster = useMemo(() => {
    return account?.authorities.some(item => item === AUTHORITIES.LEAGUE_MASTER);
  }, [account]);

  const [defaultColumns, setDefaultColumn] = useState([
    {
      title: translate('eventList.serial'),
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: translate('eventList.half'),
      dataIndex: 'roundType',
      key: 'roundType',
      render: (text, record, index) => translate(`roundType.${text || 1}`),
      editable: true,
    },
    {
      title: translate('eventList.time'),
      dataIndex: 'time',
      key: 'time',
      render: (text, record, index) => renderTime(record.processDate, record.roundType),
      editable: true,
    },
    {
      title: translate('eventList.team'),
      dataIndex: 'team',
      key: 'team',
      render: (text, record, index) => record?.playerDTO?.clubName || record?.playerOut?.clubName,
    },
    {
      title: translate('eventList.no'),
      dataIndex: 'no',
      key: 'no',
      render: (text, record, index) => record?.playerDTO?.uniformNumber || record?.playerOut?.uniformNumber,
    },
    {
      title: translate('eventList.player'),
      dataIndex: 'player',
      key: 'player',
      render: (text, record, index) => record?.playerDTO?.koName || record?.playerOut?.koName,
    },
    {
      title: translate('eventList.edit'),
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <>
          {/* {modalEventList !== actionRecord.GOAL_KICK && canEdit && (
            <a style={{marginRight: 10}} onClick={() => onUpdate(record.id, record)}>{translate('eventList.edit')}</a>
          )}
          {canEdit && <a onClick={() => onDeleteRecord(record)}>{translate('eventList.delete')}</a>} */}
          {modalEventList !== actionRecord.GOAL_KICK && (
            <a style={{ marginRight: 10 }} onClick={() => onUpdate(record.id, record)}>
              {translate('eventList.edit')}
            </a>
          )}
          <a onClick={() => onDeleteRecord(record)}>{translate('eventList.delete')}</a>
        </>
      ),
    },
  ]);

  React.useEffect(() => {
    if (
      matchBasicInfoEntity?.approval === APPROVAL_TYPE.ACCEPTED ||
      (isLeagueMaster && !isAdmin && matchBasicInfoEntity?.approval !== APPROVAL_TYPE.WAIT_ACCEPT) ||
      (isRecorder && !isAdmin && matchBasicInfoEntity?.approval === APPROVAL_TYPE.WAIT_ACCEPT)
    ) {
      setDefaultColumn([
        {
          title: translate('eventList.serial'),
          dataIndex: 'index',
          key: 'index',
          render: (text, record, index) => index + 1,
        },
        {
          title: translate('eventList.half'),
          dataIndex: 'roundType',
          key: 'roundType',
          render: (text, record, index) => translate(`roundType.${text || 1}`),
          editable: false,
        },
        {
          title: translate('eventList.time'),
          dataIndex: 'time',
          key: 'time',
          render: (text, record, index) => renderTime(record.processDate, record.roundType),
          editable: false,
        },
        {
          title: translate('eventList.team'),
          dataIndex: 'team',
          key: 'team',
          render: (text, record, index) => record?.playerDTO?.clubName || record?.playerOut?.clubName,
        },
        {
          title: translate('eventList.no'),
          dataIndex: 'no',
          key: 'no',
          render: (text, record, index) => record?.playerDTO?.uniformNumber || record?.playerOut?.uniformNumber,
        },
        {
          title: translate('eventList.player'),
          dataIndex: 'player',
          key: 'player',
          render: (text, record, index) => record?.playerDTO?.koName || record?.playerOut?.koName,
        },
        {
          title: translate('freeCornerKick.position'),
          dataIndex: 'detailLocation',
          key: 'detailLocation',
          render: (text, record, index) => record?.detailLocation && translate(`positionInMatch.${record?.detailLocation}`),
        },
      ]);
    }
  }, [matchBasicInfoEntity?.approval]);

  React.useEffect(() => {
    if (
      (matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED || matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT) &&
      (isRecorder || isAdmin || isLeagueMaster)
    ) {
      setDefaultColumn([
        {
          title: translate('eventList.serial'),
          dataIndex: 'index',
          key: 'index',
          render: (text, record, index) => index + 1,
        },
        {
          title: translate('eventList.half'),
          dataIndex: 'roundType',
          key: 'roundType',
          render: (text, record, index) => translate(`roundType.${text || 1}`),
          editable: false,
        },
        {
          title: translate('eventList.time'),
          dataIndex: 'time',
          key: 'time',
          render: (text, record, index) => renderTime(record.processDate, record.roundType),
          editable: false,
        },
        {
          title: translate('eventList.team'),
          dataIndex: 'team',
          key: 'team',
          render: (text, record, index) => record?.playerDTO?.clubName || record?.playerOut?.clubName,
        },
        {
          title: translate('eventList.no'),
          dataIndex: 'no',
          key: 'no',
          render: (text, record, index) => record?.playerDTO?.uniformNumber || record?.playerOut?.uniformNumber,
        },
        {
          title: translate('eventList.player'),
          dataIndex: 'player',
          key: 'player',
          render: (text, record, index) => record?.playerDTO?.koName || record?.playerOut?.koName,
        },
      ]);
    } else {
      setDefaultColumn([
        {
          title: translate('eventList.serial'),
          dataIndex: 'index',
          key: 'index',
          render: (text, record, index) => index + 1,
        },
        {
          title: translate('eventList.half'),
          dataIndex: 'roundType',
          key: 'roundType',
          render: (text, record, index) => translate(`roundType.${text || 1}`),
          editable: true,
        },
        {
          title: translate('eventList.time'),
          dataIndex: 'time',
          key: 'time',
          render: (text, record, index) => renderTime(record.processDate, record.roundType),
          editable: true,
        },
        {
          title: translate('eventList.team'),
          dataIndex: 'team',
          key: 'team',
          render: (text, record, index) => record?.playerDTO?.clubName || record?.playerOut?.clubName,
        },
        {
          title: translate('eventList.no'),
          dataIndex: 'no',
          key: 'no',
          render: (text, record, index) => record?.playerDTO?.uniformNumber || record?.playerOut?.uniformNumber,
        },
        {
          title: translate('eventList.player'),
          dataIndex: 'player',
          key: 'player',
          render: (text, record, index) => record?.playerDTO?.koName || record?.playerOut?.koName,
        },
        {
          title: translate('eventList.edit'),
          dataIndex: 'edit',
          key: 'edit',
          render: (text, record) => (
            <>
              {/* {modalEventList !== actionRecord.GOAL_KICK && canEdit && (
                <a style={{marginRight: 10}} onClick={() => onUpdate(record.id, record)}>{translate('eventList.edit')}</a>
              )}
              {canEdit && <a onClick={() => onDeleteRecord(record)}>{translate('eventList.delete')}</a>} */}
              {modalEventList !== actionRecord.GOAL_KICK && (
                <a style={{ marginRight: 10 }} onClick={() => onUpdate(record.id, record)}>
                  {translate('eventList.edit')}
                </a>
              )}
              <a onClick={() => onDeleteRecord(record)}>{translate('eventList.delete')}</a>
            </>
          ),
        },
      ]);
    }
  }, [modalEventList]);

  const params = useParams<{ id: string }>();
  const connectSuccess = useAppSelector(state => state.matchRecord?.connectSuccess);

  React.useEffect(() => {
    if (!params.id || !connectSuccess) {
      return;
    }

    const subscribe = () => {
      subscribeList(urlSubscribe(props.modalEventList) + params.id, listPlayer => {
        setDataTable(convertToNewDataTable(listPlayer));
        onLoading();
      });
    };
    subscribe();
    dispatch(fetchListEventRecord(urlGetList(props.modalEventList) + params.id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        setDataTable(convertToNewDataTable(get(res.payload, 'data')));
        onLoading();
      }
    });

    // if (modalEventList === actionRecord.SUBSTITUTION) {
    //   setDefaultColumn([
    //     {
    //       title: translate('eventList.serial'),
    //       dataIndex: 'index',
    //       key: 'index',
    //       render: (text, record, index) => index + 1,
    //     },
    //     {
    //       title: translate('eventList.half'),
    //       dataIndex: 'roundType',
    //       key: 'roundType',
    //       render: (text, record, index) => translate(`roundType.${text || 1}`),
    //       editable: true,
    //     },
    //     {
    //       title: translate('eventList.time'),
    //       dataIndex: 'time',
    //       key: 'time',
    //       render: (text, record, index) => renderTime(record.processDate, record.roundType),
    //       editable: true,
    //     },
    //     {
    //       title: translate('eventList.team'),
    //       dataIndex: 'team',
    //       key: 'team',
    //       render: (text, record, index) => record?.playerDTO?.clubName || record?.playerOut?.clubName,
    //     },
    //     {
    //       title: translate('eventList.no'),
    //       dataIndex: 'no',
    //       key: 'no',
    //       render: (text, record, index) => record?.playerDTO?.uniformNumber || record?.playerOut?.uniformNumber,
    //     },
    //     {
    //       title: translate('eventList.player'),
    //       dataIndex: 'player',
    //       key: 'player',
    //       render: (text, record, index) => record?.playerDTO?.koName || record?.playerOut?.koName,
    //     },
    //   ]);
    // }
  }, [props.modalEventList, connectSuccess]);

  const onDeleteRecord = record => {
    setItemSelected(record);
  };

  const handleSave = row => {
    // if (modalEventList === actionRecord.SUBSTITUTION) {
    sendWSActivity(urlWs(props.modalEventList, 'edit', row.matchId, row.id), row);
    // sendRecord(`/record/player-inout/edit/${row.matchId}/${row.id}`, {
    //   ...row,
    // });
    // }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: data => (
        <EditableCell {...data} timeOfRound={matchBasicInfoEntity?.timeOfRound} timeOfRoundExtra={matchBasicInfoEntity?.timeOfRoundExtra} />
      ),
    },
  };

  const title = React.useMemo(() => {
    switch (modalEventList) {
      case actionRecord.SUBSTITUTION:
        return translate('eventList.changePlayer');
      case actionRecord.GOAL_KICK:
        return translate('eventList.goalKick');
      case actionRecord.FREE_KICK:
        return translate('eventList.freeKick');
      case actionRecord.PENALTY_KICK:
        return translate('eventList.penaltyKick');
      case actionRecord.SHOOTING:
        return translate('eventList.shootingKick');
      case actionRecord.SCORE:
        return translate('eventList.scoreRecord');
      case actionRecord.FOUL:
        return translate('eventList.foul');
      case actionRecord.OFFSIDE:
        return translate('eventList.offside');
      case actionRecord.PENALTY_SHOOT_OUT:
        return translate('eventList.penaltyShootOut');
      case actionRecord.WARNING:
        return translate('eventList.yellowCard');
      case actionRecord.CORNER_KICK:
        return translate('eventList.corner');
      case actionRecord.ASSIST:
        return translate('eventList.assist');
      default:
        return translate('eventList.goalKick');
    }
  }, [modalEventList]);

  const handleDelete = React.useCallback(item => {
    sendWSActivity(urlWs(props.modalEventList, 'delete', item.matchId, item.id), {});
    setItemSelected(null);
  }, []);

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <>
      <Modal
        title={
          <Space>
            {title}
            {isLoading && <Spin />}
          </Space>
        }
        className="register-record-dialog"
        visible={open}
        width={1400}
        zIndex={1}
        onCancel={() => onCancel()}
        footer={[
          <Button key="close" onClick={() => onCancel()}>
            <span>{translate('freeCornerKick.close')}</span>
          </Button>,
        ]}
      >
        <ConfigProvider renderEmpty={() => <Empty description={translate('common.noDataTable')} />}>
          <Table
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={dataTable}
            columns={columns}
            pagination={false}
            className="record-table"
            scroll={{
              x: 'calc(700px)',
            }}
          />
        </ConfigProvider>
      </Modal>
      <ConfirmationDialog
        open={!!itemSelected}
        title={translate('eventList.deleteTitle')}
        message={translate('eventList.deleteMessage')}
        onConfirm={() => handleDelete(itemSelected)}
        onClose={() => setItemSelected(null)}
      />
    </>
  );
};

export default App;
