import { Avatar, Col, ConfigProvider, Empty, Form, Row, Select, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { useLocation } from 'react-router-dom';
import { getListPlayer, getListPlayerRecord, getListTeamPlayer } from '../recordRoom.reducer';

const LookupPlayer = () => {
  const [form] = Form.useForm();
  const team = Form.useWatch('team', form);
  const dataListTeamPlayerRecord = useAppSelector(state => state.recordRoomReducer.dataListTeamPlayerRecord);
  const dataListPlayer = useAppSelector(state => state.recordRoomReducer.dataListPlayer);
  const dataListPlayerRecord = useAppSelector(state => state.recordRoomReducer.dataListPlayerRecord);
  const arrayplayerId: any = [];
  const arrplayerName: any = [];
  const arrItems: any = [];
  const dispatch = useAppDispatch();
  const [disable, setDisable] = useState(false);
  const location: any = useLocation();

  useEffect(() => {
    if (location?.state?.items) {
      setDisable(true);
      location?.state?.items.map(a => arrayplayerId.push(a.playerId));
      location?.state?.items.map(a => arrplayerName.push(a.koName));
    } else if (location?.state?.itemsGoal) {
      setDisable(true);
      location?.state?.itemsGoal.map(a => arrayplayerId.push(a.playerId));
      location?.state?.itemsGoal.map(a => arrplayerName.push(a.koName));
    } else {
      setDisable(false);
    }
  }, [location]);

  const callAPI = () => {
    if (location?.state?.itemsGoal?.length === 0 || location?.state?.items?.length === 0) {
      return;
    } else if (location?.state?.items) {
      dispatch(
        getListPlayerRecord({
          players: arrayplayerId,
          leagueId: location?.state?.items[0]?.leagueId,
          clubId: null,
          orderDetail: 2,
        })
      );
    } else if (location?.state?.itemsGoal) {
      dispatch(
        getListPlayerRecord({
          players: arrayplayerId,
          leagueId: location?.state?.itemsGoal[0]?.leagueId,
          clubId: null,
          orderDetail: 1,
        })
      );
    } else {
      dispatch(getListTeamPlayer()).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          const newPayload: any = res.payload;
          dispatch(getListPlayer(newPayload.data[0].id)).then(result => {
            if (result.meta.requestStatus === 'fulfilled') {
              const payload: any = result.payload;
              form.setFieldsValue({
                team: newPayload.data[0]?.id,
                player: payload.data?.content[0]?.id,
              });
              dispatch(
                getListPlayerRecord({
                  clubId: newPayload.data[0]?.id,
                  players: payload.data.content[0]?.id,
                  leagueId: null,
                  orderDetail: null,
                })
              );
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    callAPI();
  }, [location]);

  const handleConvertData = (value: number) => {
    // const a = Math.floor(value / 1000 / 60).toString();
    const a = Math.floor(value / 60).toString();
    return a;
  };
  const columns = [
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.league">league</Translate>,
      dataIndex: 'leagueName',
      key: 'leagueName',
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.groundTime">groundTime</Translate>,
      dataIndex: 'totalMatch',
      key: 'totalMatch',
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.duration">duration</Translate>,
      dataIndex: 'processDate',
      key: 'processDate',
      width: 200,
      render: (value, record) => <span>{handleConvertData(value)}</span>,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.score">score</Translate>,
      dataIndex: 'allScore',
      key: 'allScore',
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.assist">assist</Translate>,
      dataIndex: 'supportScore',
      key: 'supportScore',
      render: (value, record) => <span>{!value ? <span>0</span> : value}</span>,
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.foul">foul</Translate>,
      dataIndex: 'foul',
      key: 'foul',
      render: (value, record) => <span>{!value ? <span>0</span> : value}</span>,
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.bloodFoul">bloodFoul</Translate>,
      dataIndex: 'beFouled',
      key: 'beFouled',
      render: (value, record) => <span>{!value ? <span>0</span> : value}</span>,
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.warning">warning</Translate>,
      dataIndex: 'warning',
      key: 'warning',
      render: (value, record) => <span>{record.warning + record.ban}</span>,
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.sendOff">sendOff</Translate>,
      dataIndex: 'chased',
      key: 'chased',
      render: (value, record) => <span>{!value ? <span>0</span> : value}</span>,
      width: 200,
    },
  ];

  const columnsTop = [
    {
      title: <Translate contentKey="leagueApp.playerClub.koName">koName</Translate>,
      dataIndex: 'koName',
      key: 'koName',
      width: 200,
    },
    {
      title: <Translate contentKey="leagueApp.playerClub.enName">league</Translate>,
      dataIndex: 'enName',
      key: 'enName',
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.league">league</Translate>,
      dataIndex: 'leagueName',
      key: 'leagueName',
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.groundTime">groundTime</Translate>,
      dataIndex: 'totalMatch',
      key: 'totalMatch',
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.duration">duration</Translate>,
      dataIndex: 'processDate',
      key: 'processDate',
      render: (value, record) => <span>{handleConvertData(value)}</span>,
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.score">score</Translate>,
      dataIndex: 'allScore',
      key: 'allScore',
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.assist">assist</Translate>,
      dataIndex: 'supportScore',
      key: 'supportScore',
      render: (value, record) => <span>{!value ? <span>0</span> : value}</span>,
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.foul">foul</Translate>,
      dataIndex: 'foul',
      key: 'foul',
      render: (value, record) => <span>{!value ? <span>0</span> : value}</span>,
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.bloodFoul">bloodFoul</Translate>,
      dataIndex: 'beFouled',
      key: 'beFouled',
      render: (value, record) => <span>{!value ? <span>0</span> : value}</span>,
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.warning">warning</Translate>,
      dataIndex: 'warning',
      key: 'warning',
      render: (value, record) => <span>{record.warning + record.ban}</span>,
      width: 200,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.sendOff">sendOff</Translate>,
      dataIndex: 'chased',
      key: 'chased',
      render: (value, record) => <span>{!value ? <span>0</span> : value}</span>,
      width: 200,
    },
  ];
  const handleChangeTeam = (value: number) => {
    form.setFieldsValue({
      player: undefined,
    });
    dispatch(getListPlayer(value));
  };
  const handleChangePlayer = (value: number) => {
    dispatch(
      getListPlayerRecord({
        clubId: team,
        players: [value],
        leagueId: null,
        orderDetail: null,
      })
    );
  };

  useEffect(() => {
    if (dataListPlayerRecord[0]?.leagueName) {
      form.setFieldsValue({
        leagueName: dataListPlayerRecord[0]?.leagueName,
      });
    }
  }, [dataListPlayerRecord]);
  return (
    <div className="management-layout">
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading">
            <Translate contentKey="recordRoom.lookupPlayer.title">Player record</Translate>
          </h2>
        </div>
      </div>
      <div>
        {location?.state?.itemsGoal || location?.state?.items ? (
          <Col lg={6} md={12} xs={24}>
            <Form form={form} onFinishFailed={values => handleFocusFieldFail(values)} scrollToFirstError name="control-hooks">
              <Form.Item
                name="leagueName"
                style={{ width: '100%' }}
                label={<Translate contentKey="recordRoom.lookupTeam.leagueName">Team</Translate>}
              >
                  <Select
                    disabled
                    placeholder={translate('recordRoom.placeholder.leagueName')}
                    style={{ width: '100%' }}
                    className="custom-prefix-select"
                    suffixIcon={<SelectArrowDown />}
                  ></Select>
              </Form.Item>
            </Form>
          </Col>
        ) : (
          <Form form={form}>
            <Row gutter={[32, 0]}>
              <Col lg={6} md={12} xs={24}>
                <Form.Item
                  name="team"
                  style={{ width: '100%' }}
                  label={<Translate contentKey="recordRoom.lookupPlayer.team">Team</Translate>}
                >
                    <Select
                      placeholder={translate('recordRoom.placeholder.team')}
                      style={{ width: '100%' }}
                      onChange={handleChangeTeam}
                      className="custom-prefix-select"
                      suffixIcon={<SelectArrowDown />}
                    >
                      {dataListTeamPlayerRecord?.map(item => (
                        <Select.Option key={item?.id} value={item?.id}>
                          {item?.name}
                        </Select.Option>
                      ))}
                    </Select>
                </Form.Item>
              </Col>
              <Col lg={6} md={12} xs={24}>
                <Form.Item
                  name="player"
                  style={{ width: '100%' }}
                  label={<Translate contentKey="recordRoom.lookupPlayer.player">Player</Translate>}
                >
                    <Select
                      placeholder={translate('recordRoom.placeholder.player')}
                      style={{ width: '100%' }}
                      onChange={handleChangePlayer}
                      className="custom-prefix-select"
                      suffixIcon={<SelectArrowDown />}
                    >
                      {dataListPlayer?.content?.map(item => (
                        <Select.Option key={item?.id} value={item?.id}>
                          {item?.koName}
                        </Select.Option>
                      ))}
                    </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}

        <Row>
          <Col span={24}>
            {location?.state?.itemsGoal || location?.state?.items ? (
              <ConfigProvider renderEmpty={() => <Empty description={translate('common.noDataTable')} />}>
                <Table
                  columns={columnsTop}
                  loading={false}
                  dataSource={dataListPlayerRecord}
                  pagination={false}
                  scroll={{
                    x: 'calc(700px + 50%)',
                  }}
                />
              </ConfigProvider>
            ) : (
              <ConfigProvider renderEmpty={() => <Empty description={translate('common.noDataTable')} />}>
                <Table
                  columns={columns}
                  loading={false}
                  dataSource={dataListPlayerRecord}
                  pagination={false}
                  scroll={{
                    x: 'calc(700px + 50%)',
                  }}
                />
              </ConfigProvider>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LookupPlayer;
