import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlayerClubCreateDialog from './player-club-update';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Col, Grid, Input, Row, Select, Tag } from 'antd';
import { formatDate, formatHour, formatDate2, formatYear } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { PlayerClubListFilter } from './player-club.type';
import { clearMsgCreate, clearMsgDelete, deleteEntity, getEntities, getEntity } from './player-club.reducer';
import dayjs from 'dayjs';
import PlayerClubDeleteDialog from './player-club-delete-dialog';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Filter from 'app/modules/filter';
import { PlusIcon } from 'app/shared/util/appIcon';
import { ConfirmationDialog } from 'app/shared/util/confirmationDialog';
import { width } from '@fortawesome/free-solid-svg-icons/faCogs';
import { path } from '../routes';
import './style.scss';
import MultiFilter from 'app/modules/MultiSearch';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
export const PlayerClub = (props: any) => {
  const { idPlayerClub } = props;
  const dispatch = useAppDispatch();
  const param = useParams<{ id: string; params: string }>();

  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
    type: 'koName',
  };
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const handleDenied = (user: any) => {
    setModalDelete(true);
    setUserClicked(user);
  };

  const handleDelete = () => {
    const obj = {
      playerId: userClicked,
      clubId: +param.id,
    };
    dispatch(deleteEntity(obj)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const { push } = useHistory();
  const playerClubList = useAppSelector(state => state.PlayerClub?.entities);
  const loading = useAppSelector(state => state.playerClub?.loading) || false;
  const [createModal, setCreateModal] = useState(false);
  const handleFetchData = React.useCallback((params: PlayerClubListFilter) => {
    dispatch(getEntities({ ...params, clubId: +param.id }));
  }, []);
  const deleteSuccess = useAppSelector(state => state.PlayerClub.deleteSuccess);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const account = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);

  const foundTeamManager = account?.authorities.find(function (element) {
    return element === 'ROLE_TEAM_MANAGER';
  });

  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.playerClub.deleted.success'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const ActionButton = ({ id, onClickDetail }: { id: number; onClickDetail: () => void }) => {
    return (
      <div className={isAdmin && 'column-action-button'}>
        <Button onClick={() => onClickDetail()} className="rounded">
          <Translate contentKey="leagueApp.playerClub.detail.detail" />
        </Button>

        {isAdmin && (
          <Button onClick={() => handleDenied(id)} type="primary" danger className="rounded">
            <Translate contentKey="entity.action.delete2" />
          </Button>
        )}
      </div>
    );
  };

  const createSuccess = useAppSelector(state => state.PlayerClub.createSuccess);
  useEffect(() => {
    if (createSuccess) {
      toast.success(translate('leagueApp.playerClub.created.success'));
      dispatch(clearMsgCreate(createSuccess));
    }
  }, [createSuccess]);

  const columns = [
    {
      title: '',
      dataIndex: 'base64Avt',
      key: 'base64Avt',
      render: (value, record) => <Avatar shape="square" src={value} size="large" />,
    },
    {
      title: <Translate contentKey="leagueApp.playerClub.koName" />,
      dataIndex: 'koName',
      key: 'koName',
      render: (value, record) => (
        <span className="hot-link-club" onClick={() => push(path.playerDetail.replace(':id', record?.id))}>
          {value}
        </span>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.playerClub.shirtNumber" />,
      dataIndex: 'uniformNumber',
      key: 'uniformNumber',
    },
    {
      title: <Translate contentKey="leagueApp.playerClub.position" />,
      dataIndex: 'position',
      key: 'position',
      render: (_, record) => <span>{translate(`leagueApp.playerAgent.${record.position}`)}</span>,
    },
    {
      title: <Translate contentKey="leagueApp.playerClub.enName" />,
      dataIndex: 'enName',
      key: 'enName',
    },
    {
      title: <Translate contentKey="leagueApp.playerClub.national" />,
      dataIndex: 'nationality',
      key: 'nationality',
      render: (_, record) => <>{locale === 'ko' ? <span>{record?.nationalityKorea}</span> : <span>{record?.nationality}</span>}</>,
    },
    {
      title: <Translate contentKey="leagueApp.playerClub.birthDay" />,
      dataIndex: 'birthday',
      key: 'birthday',
      render: (value, record) => formatDate(value),
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 260,
      render: (value, record) => <ActionButton id={value} onClickDetail={() => push(path.playerDetail.replace(':id', value))} />,
      align: 'center' as any,
    },
  ];
  const handleModalCreate = () => {
    setCreateModal(true);
  };

  const handleDeleteItem = () => {
    const obj = {
      playerId: userClicked,
      clubId: +param.id,
    };
    dispatch(deleteEntity(obj));
    dispatch(getEntities({ sort: 'id,asc', id: +param.id, page: 0, size: 10 }));
    setModalDelete(false);
  };

  const options = [
    {
      value: 'koName',
      label: translate('leagueApp.playerClub.koName'),
    },
    {
      value: 'uniformNumber',
      label: translate('leagueApp.playerClub.shirtNumber'),
    },
    {
      value: 'position',
      label: translate('leagueApp.playerClub.position'),
    },
    {
      value: 'enName',
      label: translate('leagueApp.playerClub.enName'),
    },
    {
      value: 'nationality',
      label: translate('leagueApp.playerClub.national'),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center management-layout-header" style={{ paddingTop: 30 }}>
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading" className="heading-20-bold neutral-1">
            <Translate contentKey="leagueApp.playerClub.home.title"></Translate>
          </h2>
          <>
            <div className="management-layout-divider"></div>
            <Button className="rounded management-header-add-button" onClick={handleModalCreate} icon={<PlusIcon />} type="primary">
              <Translate contentKey="leagueApp.playerClub.home.createLabel"></Translate>
            </Button>
          </>
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <Filter filter={filter} onFilter={onFilterToQueryString} multi options={options} />
        </Col>
      </div>
      <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
      <div className="table-responsive">
        <Table
          totalElements={playerClubList.totalElements}
          columns={columns}
          dataSource={playerClubList.content}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('leagueApp.playerClub.deleted.question')}</h6>}
          onClose={() => setModalDelete(false)}
          onConfirm={() => handleDelete()}
        />
      )}
      {createModal ? <PlayerClubCreateDialog createModal={createModal} setCreateModal={setCreateModal} idClub={idPlayerClub} /> : ''}
    </div>
  );
};

export default PlayerClub;
