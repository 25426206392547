import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';

import { Avatar, Button, Col, Dropdown, Menu, Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Filter from 'app/modules/filter';
import MultiFilter from 'app/modules/MultiSearch';
import Table from 'app/modules/table';
import { useFilter } from 'app/shared/hooks/useFilter';
import { PlusIcon, SelectArrowDown, SelectArrowUp } from 'app/shared/util/appIcon';
import { ConfirmationDialog } from 'app/shared/util/confirmationDialog';
import { TYPE_OF_OPTION } from 'app/shared/util/constant';
import { formatDate2, formatHour } from 'app/shared/util/date-utils';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { path } from '../routes';
import LeagueMatchAddDialog from './league-match-create-modal';
import LeagueMatchUploadDialog from './league-match-upload-modal';
import { clearMsgCreate, clearMsgDelete, clearMsgUpdate, deleteEntity, exportMatchsEntity, getEntities } from './league-match.reducer';
import { LeagueMatchFilter } from './league-match.type';
import './style.scss';
import { IExportTemplateMatch, IMatch, IParamsFilterMatch } from 'app/shared/model/match.model';
import { AUTHORITIES, FILE_TYPE } from 'app/config/constants';
import { pick } from 'lodash';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const LeagueMatch = (props: any) => {
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'startDate,desc',
    type: 'ownerClubName',
  };

  const param = useParams<{ id: string; params: string }>();
  const dispatch = useAppDispatch();

  const matchList = useAppSelector(state => state.LeagueMatch?.entities);
  const createSuccess = useAppSelector(state => state.LeagueMatch?.createSuccess);
  const updateSuccess = useAppSelector(state => state.LeagueMatch?.updateSuccess);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const loading = useAppSelector(state => state.match?.loading);
  const exporting = useAppSelector(state => state.match?.exporting);
  const account = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);

  const [isOpen, setIsOpen] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [viewDetail, setViewDetail] = useState('');
  const [ids, setIds] = useState<React.Key[]>([]);

  const onSelectChange = (newIds: React.Key[]) => {
    setIds(newIds);
  };

  const rowSelection = {
    selectedRowKeys: ids,
    onChange: onSelectChange,
  };

  const handleRowClick = (selectedId: number) => {
    let newIds = [...ids];
    if (newIds.includes(selectedId)) {
      newIds = newIds.filter((idItem: number) => idItem !== selectedId);
    } else {
      newIds.push(selectedId);
    }

    setIds(newIds);
  };

  const handleFetchData = React.useCallback((params: LeagueMatchFilter) => {
    dispatch(getEntities({ ...params, leagueId: +param.id }));
  }, []);

  const deleteSuccess = useAppSelector(state => state.LeagueMatch?.deleteSuccess);
  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.league.leagueMatch.deleteSuccess'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);

  const { filter, onFilterToQueryString }: { filter: IParamsFilterMatch; onFilterToQueryString: (values: IParamsFilterMatch) => void } =
    useFilter({
      defaultFilter,
      onFetchData: handleFetchData,
    });

  const handleDenied = (user: any) => {
    setModalDelete(true);
    setUserClicked(user);
  };

  const handleDelete = () => {
    dispatch(deleteEntity(userClicked)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const handleViewDetail = (user: any) => {
    setViewDetail(user);
    setIsOpen(true);
  };

  const { push } = useHistory();

  const ActionButton = ({ id, status, flag }: { id: number; status: number; flag: number }) => {
    return (
      <div className={isAdmin && 'column-action-button'}>
        <Button
          disabled={status !== 1 && flag !== 0 && !isAdmin ? true : false}
          onClick={e => {
            handleViewDetail(id);
            e.stopPropagation();
          }}
          className="rounded"
        >
          <Translate contentKey="entity.action.edit" />
        </Button>
        {isAdmin && (
          <Button
            onClick={e => {
              handleDenied(id);
              e.stopPropagation();
            }}
            type="primary"
            danger
            className="rounded"
          >
            <Translate contentKey="entity.action.delete2" />
          </Button>
        )}
      </div>
    );
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleOpenUpload = () => {
    setShowUpload(true);
  };

  const handleClickDetail = data => {
    push(path.clubDetail.replace(':id', data));
  };

  useEffect(() => {
    if (createSuccess) {
      toast.success(translate('leagueApp.league.leagueMatch.createSuccess'));
      dispatch(clearMsgCreate(createSuccess));
    }
  }, [createSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      toast.success(translate('leagueApp.league.leagueMatch.updateSuccess'));
      dispatch(clearMsgUpdate(updateSuccess));
    }
  }, [updateSuccess]);
  const columns = [
    {
      title: <Translate contentKey="leagueApp.match.round" />,
      dataIndex: 'matchRound',
      key: 'matchRound',
    },
    {
      title: <Translate contentKey="leagueApp.match.matchDate" />,
      dataIndex: 'startDate',
      key: 'startDate',
      render: (value, record) => formatDate2(value),
    },
    {
      title: <Translate contentKey="leagueApp.match.time" />,
      dataIndex: 'startDate',
      key: 'startDate',
      render: (value, record) => formatHour(value),
    },
    {
      title: <Translate contentKey="leagueApp.match.homeClub" />,
      dataIndex: 'ownerClubName',
      key: 'ownerClubName',
      ellipsis: { showTitle: false },
      render: (value, record) => (
        <>
          <Avatar src={record?.ownerBase64Avt} size="large" style={{ marginRight: '12px' }} />
          <Tooltip placement="bottomRight" title={value}>
            <span onClick={() => handleClickDetail(record?.ownerClubId)} className="hot-link-club">
              {value}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.match.awayClub" />,
      dataIndex: 'awayClubName',
      key: 'awayClubName',
      ellipsis: { showTitle: false },
      render: (value, record) => (
        <>
          <Avatar src={record?.awayBase64Avt} size="large" style={{ marginRight: '12px' }} />
          <Tooltip placement="bottomRight" title={value}>
            <span onClick={() => handleClickDetail(record?.awayClubId)} className="hot-link-club">
              {value}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.match.stadiumName" />,
      dataIndex: 'stadiumName',
      key: 'stadiumName',
    },
    {
      title: <Translate contentKey="leagueApp.match.recorder" />,
      dataIndex: 'recorderName',
      key: 'recorderName',
      ellipsis: { showTitle: false },
      render: (value, record) => (
        <>
          <Tooltip placement="bottomRight" title={value}>
            {value}
          </Tooltip>
        </>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 230,
      render: (value, record) => <ActionButton id={value} flag={record?.flag} status={record?.status} />,
      align: 'center' as any,
    },
  ];

  const options = [
    {
      value: 'matchRound',
      label: translate('leagueApp.match.round'),
    },
    {
      value: 'startDate',
      label: translate('leagueApp.match.matchDate'),
      type: TYPE_OF_OPTION.DATEGMT,
    },
    {
      value: 'ownerClubName',
      label: translate('leagueApp.match.homeClub'),
    },
    {
      value: 'awayClubName',
      label: translate('leagueApp.match.awayClub'),
    },
    {
      value: 'stadiumName',
      label: translate('leagueApp.match.stadiumName'),
    },
    {
      value: 'recorderName',
      label: translate('leagueApp.match.recorder'),
    },
  ];

  const handleExportFile = value => {
    const currentFilter: IParamsFilterMatch = pick(filter, [
      'awayClubName',
      'matchRound',
      'ownerClubName',
      'startDate',
      'recorderName',
      'stadiumName',
      'type',
      'leagueId',
    ]);

    const requestParams: IExportTemplateMatch = {
      type: value,
      fileName: value === FILE_TYPE.EXCEL ? 'matchs.xlsx' : 'matchs.csv',
      lang: locale,
      zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      filter: {
        leagueId: Number(param.id),
        ...currentFilter,
      },
    };
    dispatch(exportMatchsEntity(requestParams));
  };

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <p
              className="w-100 h-100 d-flex justify-content-center align-items-center mb-0"
              onClick={() => handleExportFile(FILE_TYPE.EXCEL)}
            >
              {translate('leagueApp.match.upload.typeExcel')}
            </p>
          ),
        },
        {
          key: '2',
          label: (
            <p
              className="w-100 h-100 d-flex justify-content-center align-items-center mb-0"
              onClick={() => handleExportFile(FILE_TYPE.CSV)}
            >
              {translate('leagueApp.match.upload.typeCSV')}
            </p>
          ),
        },
      ]}
    />
  );

  return (
    <div>
      {isOpen && (
        <LeagueMatchAddDialog viewDetail={viewDetail} setViewDetail={setViewDetail} isOpen={isOpen} setIsOpen={b => setIsOpen(b)} />
      )}

      <LeagueMatchUploadDialog
        isOpen={showUpload}
        ids={ids}
        onClose={showUploadList => {
          showUploadList && handleFetchData(filter);
          setShowUpload(false);
        }}
      />

      <div className="d-flex justify-content-between align-items-center management-layout-header" style={{ paddingTop: 30 }}>
        <div className="d-flex align-items-center">
          <h2 id="player-heading" data-cy="PlayerHeading" className="heading-20-bold neutral-1">
            <Translate contentKey="leagueApp.league.matchSchedule" />
          </h2>
          <div className="management-layout-divider"></div>
          <Button className="rounded management-header-add-button" onClick={handleOpenModal} icon={<PlusIcon />} type="primary">
            <Translate contentKey="clubmng.add" />
          </Button>
          <Button className="rounded ms-4" onClick={handleOpenUpload} icon={<SelectArrowUp />}>
            <Translate contentKey="clubmng.upload" />
          </Button>
          <Dropdown overlay={menu}>
            <Button className="rounded ms-4" icon={<SelectArrowDown />} loading={exporting}>
              <Translate contentKey="clubmng.export" />
            </Button>
          </Dropdown>
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <Filter filter={filter} onFilter={onFilterToQueryString} multi options={options} />
        </Col>
      </div>
      <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
      <div className="table-responsive">
        <Table
          onRow={(record: IMatch, rowIndex) => ({
            onClick() {
              handleRowClick(record.id);
            },
          })}
          columns={columns}
          rowSelection={rowSelection}
          dataSource={matchList?.content || []}
          totalElements={matchList?.totalElements || 0}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('leagueApp.league.leagueMatch.deleteQuestion')}</h6>}
          onClose={() => setModalDelete(false)}
          onConfirm={() => handleDelete()}
        />
      )}
    </div>
  );
};

export default LeagueMatch;
