import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IPlayer, defaultValue } from 'app/shared/model/player.model';

const initialState: EntityState<IPlayer> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  deleteSuccess: false,
  createSuccess: false,
};


const apiSearchUrl = 'api/free-players';
// Actions

export const getEntities = createAsyncThunk('freePlayerClub/fetch_entity_list', async ({ page, size, sort, clubId }: IQueryParams) => {
  const requestUrl = `${apiSearchUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : '?'}`;
  return axios.post<IPlayer[]>(requestUrl, { clubId });
});

// slice

export const FreePlayerClub = createEntitySlice({
  name: 'playerClub',
  initialState,
  reducers: {
    clearMsgUpdate: (state, action) => void (state.updateSuccess = null),
    clearMsgDelete: (state, action) => void (state.deleteSuccess = null),
    clearMsgCreate: (state, action) => void (state.createSuccess = null),
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset, clearMsgCreate, clearMsgDelete, clearMsgUpdate } = FreePlayerClub.actions;

// Reducer
export default FreePlayerClub.reducer;
