import React from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import EditTable from 'app/shared/components/edit-table/edit-table';
import { IErrorUploadTemplateMatch } from 'app/shared/model/match.model';
import { IFormCreatePlayers, IItemUploadPlayer } from 'app/shared/model/player.model';
import { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { useParams } from 'react-router-dom';
import { reImportAttachFileEntity } from './player.reducer';
import { toast } from 'react-toastify';

type EdittableMatchModalProps = {
  isOpen: boolean;
  onClose: (showUploadList: boolean) => void;
  onChange: (newDate: IItemUploadPlayer[]) => void;
  data: IItemUploadPlayer[];
};

const PlayerEdittableModal = (props: EdittableMatchModalProps) => {
  const { isOpen, onClose, data, onChange } = props;
  const dispatch = useAppDispatch();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const { uploading } = useAppSelector(state => state.player);

  const paramsPath = useParams<{ id: string }>();
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  const columnData = (field: string, fieldErrors: IErrorUploadTemplateMatch[], record: any) => {
    const errorColumn = fieldErrors?.find(fielItem => fielItem.field === field);

    if (errorColumn) {
      return (
        <div className="d-flex justify-content-start align-items-center">
          <span className="me-2">{record[field]}</span>
          <Tooltip placement="rightTop" title={translate(`error.${errorColumn.errorCodeImport}`)}>
            <ExclamationCircleOutlined className="text-danger" />
          </Tooltip>
        </div>
      );
    } else {
      return <>{record[field]}</>;
    }
  };

  const columns = [
    {
      title: `* ${translate('leagueApp.player.koName')}`,
      dataIndex: 'koName',
      key: 'koName',
      width: 150,
      editable: true,
      required: true,
      render(value, record) {
        return columnData('koName', record?.errorImport, record);
      },
    },
    {
      title: translate('leagueApp.player.birthday'),
      dataIndex: 'birthday',
      key: 'birthday',
      width: 150,
      editable: true,
      required: true,
      render(value, record) {
        return columnData('birthday', record?.errorImport, record);
      },
    },
    {
      title: `*${translate('leagueApp.notice.noticeCategoryArr.organization')}`,
      dataIndex: 'organizationName',
      key: 'organizationName',
      width: 150,
      editable: true,
      required: true,
      render(value, record) {
        return columnData('organizationName', record?.errorImport, record);
      },
    },
    {
      title: translate('leagueApp.player.detail.labelPosition'),
      dataIndex: 'position',
      key: 'position',
      width: 150,
      required: true,
      editable: true,
      render(value, record) {
        return columnData('position', record?.errorImport, record);
      },
    },
    {
      title: translate('leagueApp.player.detail.labelNation'),
      dataIndex: 'nationality',
      key: 'nationality',
      width: 150,
      editable: true,
      required: true,
      render(value, record) {
        return columnData('nationality', record?.errorImport, record);
      },
    },
    {
      title: translate('leagueApp.player.label.clubLabel'),
      dataIndex: 'clubName',
      key: 'clubName',
      width: 150,
      editable: true,
      render(value, record) {
        return columnData('clubName', record?.errorImport, record);
      },
    },
    {
      title: translate('leagueApp.player.label.uniformLabel'),
      dataIndex: 'uniformNumber',
      key: 'uniformNumber',
      width: 150,
      editable: true,
      render(value, record) {
        return columnData('uniformNumber', record?.errorImport, record);
      },
    },
  ];

  const handleDataChange = newData => {
    onChange && onChange(newData);
  };

  const handleSaveData = () => {
    const params: IFormCreatePlayers = {
      lang: locale,
      players: dataSource,
      zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    dispatch(reImportAttachFileEntity(params)).then((res: any) => {
      if (res.payload.data.length === 0) {
        onClose && onClose(true);
        toast.success(translate('leagueApp.player.upload.success'));
      } else if (res?.error) {
        toast.error(translate(`error.${res?.error?.response?.data?.errorKey ? res.error.response.data.errorKey : 'SYSTEM_ERROR'}`));
      }
    });
  };

  return (
    <Modal
      title={<Translate contentKey="leagueApp.player.upload.uploaded" />}
      wrapClassName="management-wrapper-modal pb-4"
      visible={isOpen}
      width={1500}
      onCancel={() => {
        onClose(false);
        setDataSource([]);
      }}
      footer={null}
    >
      <EditTable columns={columns} dataSource={dataSource || []} onChange={handleDataChange} pagination={false} rowKey={'id'} />

      <div className="management-modal-footer">
        <Button key="back" onClick={() => onClose(false)}>
          <Translate contentKey="register.form.close" />
        </Button>
        <Button key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }} onClick={handleSaveData} loading={uploading}>
          <Translate contentKey="settings.form.button" />
        </Button>
      </div>
    </Modal>
  );
};

export default PlayerEdittableModal;
