import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Club from './club';
import ClubDetail from './club-detail';
// import ClubUpdate from './club-update';
import ClubDeleteDialog from './club-delete-dialog';
import CreateNewClub from './club-add-dialog';
import ClubUniform from '../club-uniform/club-uniform';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CreateNewClub} />

      <ErrorBoundaryRoute exact path={`${match.url}/new/uniform`} component={ClubUniform} />
      {/* <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ClubUpdate} /> */}
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ClubDetail} />
      <ErrorBoundaryRoute path={match.url} component={Club} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ClubDeleteDialog} />
  </>
);

export default Routes;
