import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Stadium from './stadium';
import StadiumDetail from './stadium-detail';
import StadiumUpdate from './stadium-update';
import StadiumDeleteDialog from './stadium-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={StadiumUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={StadiumUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={StadiumDetail} />
      <ErrorBoundaryRoute path={match.url} component={Stadium} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={StadiumDeleteDialog} />
  </>
);

export default Routes;
