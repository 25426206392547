import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import { getUser, deniedUser } from './user-management.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const UserManagementDeleteDialog = (props: any) => {
  const { modalDelete, setModalDelete, userClicked } = props;
  const dispatch = useAppDispatch();
  const history = useHistory();
  // useEffect(() => {
  //   dispatch(getUser(props.match.params.login));
  // }, []);

  const handleClose = event => {
    event.stopPropagation();
    setModalDelete(false);
    // props.history.push('/admin/user-management');
    history.push('/user-management');
  };

  const onCloseModal = () => {
    // setModalDelete(false)
  };
  const user = useAppSelector(state => state.userManagement.user);

  const confirmDenied = () => {
    const obj = {
      userId: userClicked,
      status: 0,
    };
    dispatch(deniedUser(obj));
    handleClose(event);
  };

  return (
    <Modal isOpen={modalDelete} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody>
        <Translate contentKey="userManagement.delete.question" interpolate={{ login: user.id }}>
          Are you sure you want to delete this User?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button color="danger" onClick={confirmDenied}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete2">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UserManagementDeleteDialog;
