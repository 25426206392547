import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
  ISearchObjectParams,
  Pageable,
} from 'app/shared/reducers/reducer.utils';
import { ICoach, defaultValue } from 'app/shared/model/coach.model';

interface CoachState extends EntityState<ICoach> {
  uploading: boolean;
}

const initialState: CoachState = {
  loading: false,
  uploading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/coaches';
const getApi = 'api/coaches/search';
const apiImageUrl = 'api/file';

// Actions

export const getEntities = createAsyncThunk('coach/fetch_entity_list', async ({ page, size, sort, ...res }: IQueryParams) => {
  const requestUrl = `${getApi}${sort ? `?page=${page}&size=${size}&sort=${sort}` : '?'}`;
  return axios.post<Pageable<ICoach>>(requestUrl, res);
});

export const getEntity = createAsyncThunk(
  'coach/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ICoach>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'coach/create_entity',
  async (entity: ICoach, thunkAPI) => {
    const result = await axios.post<ICoach>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'coach/update_entity',
  async (entity: ICoach, thunkAPI) => {
    const result = await axios.put<ICoach>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'coach/partial_update_entity',
  async (entity: ICoach, thunkAPI) => {
    const result = await axios.patch<ICoach>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'coach/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ICoach>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const upLoadImg = createAsyncThunk('coach/upLoadImg?uploadType=AVATAR_COACH', async (body: FormData) => {
  const uploadResult = await axios.post<any>(`api/file/upload-file?uploadType=AVATAR_COACH`, body);
  return uploadResult;
});

export const searchEntities = createAsyncThunk('coach/search_entity_list', async ({ page, size, sort, payload }: ISearchObjectParams) => {
  const requestUrl = `${getApi}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}`;
  return axios.post<ICoach[]>(requestUrl, payload);
});

export const getAvatarCoach = createAsyncThunk('club/leagueAttend', async ({ fileName, uploadType }: any) => {
  const requestUrl = `${apiImageUrl}/get-file-minio${fileName ? `?fileName=${fileName}&uploadType=${uploadType}` : ''}`;
  return axios.get<any>(requestUrl);
});

// slice

export const CoachSlice = createEntitySlice({
  name: 'coach',
  initialState,
  reducers: {
    clearMsgUpdate: (state, action) => void (state.updateSuccess = null),
    clearMsgDelete: (state, action) => void (state.deleteSuccess = null),
    clearMsgCreate: (state, action) => void (state.createSuccess = null),
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.deleteSuccess = true;
        state.entity = {};
      })
      .addCase(upLoadImg.fulfilled, (state, action) => {
        state.dataUploadFile = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data?.content ?? [],
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(searchEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          entity: {},
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
        state.dataUploadFile = null;
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.createSuccess = true;
        state.entity = action.payload.data;
        state.dataUploadFile = null;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isFulfilled(getAvatarCoach), (state, action) => {
        state.links = 'data:image/jpeg;base64,' + action.payload.data;
        state.loading = false;
      });
  },
});

export const { clearMsgUpdate, clearMsgDelete, clearMsgCreate, reset } = CoachSlice.actions;
// Reducer
export default CoachSlice.reducer;
