import { translate } from 'react-jhipster';

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  LEAGUE_MASTER: 'ROLE_LEAGUE_MASTER',
  TEAM_MANAGER: 'ROLE_TEAM_MANAGER',
  RECORDER: 'ROLE_RECORDER',
};

export const ROLE_NAME = [
  {
    value: 'ROLE_ADMIN',
    label: 'settings.role.admin',
  },
  {
    value: 'ROLE_USER',
    label: 'settings.role.user',
  },
  {
    value: 'ROLE_LEAGUE_MASTER',
    label: 'settings.role.league_master',
  },
  {
    value: 'ROLE_TEAM_MANAGER',
    label: 'settings.role.team_manager',
  },
  {
    value: 'ROLE_RECORDER',
    label: 'settings.role.recorder',
  },
];

export const NOTICE_CATEGORY = {
  SYSTEM: 'system',
  ORGANIZATION: 'organization',
};

export const QUESTION_CATEGORY_TABLE = {
  SPORTAT: 1,
  COMPETITION: 2,
  CLUB: 3,
  PLAYER: 4,
  SCHEDULE: 5,
  USER: 6,
  OTHER: 7,
};

export const QUESTION_CATEGORY = {
  SPORTAT: 1,
};

export const QUESTION_CATEGORY_SYSTEM = {
  COMPETITION: 2,
  CLUB: 3,
  PLAYER: 4,
  SCHEDULE: 5,
  USER: 6,
  OTHER: 7,
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const STATUS_MATCH = {
  WAIT_RECORD: 1,
  RECORDING: 2,
  DETAIL: 3,
};

export const APPROVAL_TYPE = {
  WAIT_REQUEST: 1,
  WAIT_ACCEPT: 2,
  ACCEPTED: 3,
};

export const TYPE_INFORMATION = {
  NOT_TYPE: 0,
  TYPED: 1,
};

export const CHANGE_DIRECTION = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const FILE_TYPE = {
  IMAGE: 'IMAGE',
  DOCUMENT: 'DOCUMENT',
  EXCEL: 'EXCEL',
  CSV: 'CSV',
};
export const ACCEPT_TYPE_IMAGE = ['image/png', 'image/jpeg', 'image/bpm', 'image/gif', 'image/jpe', 'image/jpg'];

export const MATCH_TIME = [
  {
    value: 45,
    label: `45'`,
  },
  {
    value: 40,
    label: `40'`,
  },
  {
    value: 35,
    label: `35'`,
  },
  {
    value: 30,
    label: `30'`,
  },
  {
    value: 25,
    label: `25'`,
  },
  {
    value: 20,
    label: `20'`,
  },
];

export const OVERTIME = [
  {
    value: 15,
    label: `15'`,
  },
  {
    value: 10,
    label: `10'`,
  },
  {
    value: 5,
    label: `5'`,
  },
];

export const NUMBER_PLAYERS = [
  {
    value: 5,
    label: '5',
  },
  {
    value: 11,
    label: '11',
  },
];

export const APP_DATE_FORMAT = 'YYYY.MM.DD HH:mm';
export const APP_YEAR_FORMAT = 'YYYY';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'YYYY/MM/DD';
export const APP_LOCAL_DATE2_FORMAT = 'YYYY.MM.DD';
export const APP_LOCAL_HOUR_FORMAT = 'HH:mm';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY.MM.DD HH:mm';
export const APP_LOCAL_MINUTE_FORMAT = 'mm:ss';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
// export const urlLocal = 'http://192.168.2.68:8084/api';
export const urlLocal = 'http://localhost:9000/api';
export const APP_DDMM_FORMAT = 'MM.DD(ddd)';
