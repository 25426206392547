export const CATEGORY_DATA_CODE = {
  POSITION_PLAYER: 'POSITION_PLAYER',
  UNIT_CODE: 'UNIT_CODE',
  MONITOR_ROLE: 'MONITOR_ROLE',
  MONITOR_RATING: 'MONITOR_RATING',
  ACADEMIC_PLAYER: 'ACADEMIC_PLAYER',
  GRADUATION_TYPE: 'GRADUATION_TYPE',
  FAULT_TYPE: 'FAULT_TYPE',
  WARING_TYPE: 'WARING_TYPE',
  WARING_REASON: 'WARING_REASON',
  SUPPORT_TYPE: 'SUPPORT_TYPE',
  SCORE_METHOD: 'SCORE_METHOD',
  QA_TYPE: 'QA_TYPE',
};
