import React from 'react';
import { Avatar, Button, Card, Col, Row, Skeleton } from 'antd';
import './team-ranking-card.scss';
import Tag from 'antd/es/tag';
import { EditOutlined, EllipsisOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { ChervonLeft } from 'app/shared/util/appIcon';
import { useRequest } from 'ahooks';
import { rankClub, rankClubV2 } from '../../dashboard.service';
import { translate } from 'react-jhipster';
import { Link } from 'react-router-dom';
import LookupLeague from 'app/entities/record-room/lookup-league';
import dayjs from 'dayjs';

export const TeamRankingCard = props => {
  const { idLeague } = props;
  const [items, setItems] = React.useState(null);
  // const date = dayjs.utc(new Date()).startOf('date').format();
  const date = dayjs(new Date()).startOf('date').toISOString();
  const request = useRequest(rankClubV2, {
    manual: true,
    onSuccess(res) {
      setItems(res.data);
    },
    onError(err) {},
  });

  React.useEffect(() => {
    if (idLeague) {
      request.run({
        leagueId: idLeague,
        now: date,
      });
    }
  }, [idLeague]);

  const topOneRankNode = team => {
    return (
      <li key={1} style={{ padding: '8px 0px' }}>
        <Row>
          <Col span={12}>
            <div>
              <b>
                {1}. {team.clubName}
              </b>
              <div>
                <ul>
                  <li>
                    <b className="primary-color">{team.totalScores}</b> {translate('dashboard.points')}
                  </li>
                  <li style={{ marginTop: 12 }}>
                    <b className="primary-color">{team.totalWin}</b>
                    &nbsp;{translate('dashboard.won')}&nbsp;
                    <b className="primary-color">{team.totalMatch - team.totalWin - team.totalLost}</b>
                    &nbsp;{translate('dashboard.draw')}&nbsp;
                    <b className="primary-color">{team.totalLost}</b>
                    &nbsp;{translate('dashboard.lost')}&nbsp;
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            {/* <img height={90} src={team.clubAvatar} /> */}
            <Avatar size={90} src={team.clubAvatar} shape="circle" />
          </Col>
        </Row>
      </li>
    );
  };
  const rankNode = (team, index) => {
    return (
      <li key={index} style={{ padding: '8px 0px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Avatar size={40} src={team.clubAvatar}></Avatar>
            <span style={{ marginLeft: 12 }}>
              {index + 1}. {team.clubName}
            </span>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            {team.totalScores} {translate('dashboard.points')}
          </div>
        </div>
      </li>
    );
  };

  return (
    <Card
      title={translate('dashboard.teamRanking')}
      extra={
        request.loading || !items || items.length === 0 ? (
          ''
        ) : (
          <Link to={{ pathname: '/lookup-league', state: { items } }} className="primary-color">
            <span>{translate('entity.action.detail')}</span> <ChervonLeft />
          </Link>
        )
      }
    >
      <ul className="list-notifications">
        {request.loading || !items || items.length === 0 ? (
          // <Skeleton active />
          <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {translate('dashboard.noData')}
          </div>
        ) : (
          <ul>
            {items.map((team, index) => {
              return index === 0 ? topOneRankNode(team) : rankNode(team, index);
            })}
          </ul>
        )}
      </ul>
    </Card>
  );
};

export default TeamRankingCard;
