import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity, clearMsgDelete } from './monitor.reducer';
import { toast } from 'react-toastify';

export const MonitorDeleteDialog = (props: any) => {
  const { modalDelete, setModalDelete, userClicked } = props;
  const [loadModal, setLoadModal] = useState(false);
  const dispatch = useAppDispatch();

  // const id = props.match.params.id;
  // useEffect(() => {
  //   dispatch(getEntity(props.match.params.id));
  //   setLoadModal(true);
  // }, []);

  // const monitorEntity = useAppSelector(state => state.monitor.entity);
  const deleteSuccess = useAppSelector(state => state.monitor.deleteSuccess);

  const handleClose = () => {
    // props.history.push('/monitor' + props.location.search);
    setModalDelete(false);
  };

  // useEffect(() => {
  //   if (deleteSuccess && loadModal) {
  //     handleClose();
  //     toast.success(translate('leagueApp.monitor.delete.deleteSuccess'))
  //     dispatch(clearMsgDelete(deleteSuccess))
  //   }
  // }, [deleteSuccess]);

  const confirmDelete = () => {
    const obj = {
      monitorId: userClicked,
    };
    dispatch(deleteEntity(obj));
    setModalDelete(false);
  };

  return (
    <Modal visible onCancel={handleClose} closeIcon={<></>} footer={null} wrapClassName="management-confirm-modal" width={400}>
      <Row align="middle" justify="center">
        <Col>
          {' '}
          <h6>{translate('leagueApp.monitor.delete.question')}</h6>
        </Col>
      </Row>
      <Row align="middle" justify="center" style={{ marginTop: 40, width: '100%' }}>
        <Col span={12} style={{ paddingRight: 12 }}>
          <Button color="secondary" onClick={handleClose} style={{ width: '100%' }}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
        </Col>
        <Col span={12} style={{ paddingLeft: 12 }}>
          <Button
            id="jhi-confirm-delete-stadium"
            data-cy="entityConfirmDeleteButton"
            type="primary"
            onClick={confirmDelete}
            style={{ width: '100%' }}
          >
            <Translate contentKey="confirmDialog.defaultConfirm"></Translate>
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default MonitorDeleteDialog;
