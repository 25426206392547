import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError, Pageable } from 'app/shared/reducers/reducer.utils';
import { IPlayerLeague, defaultValue } from 'app/shared/model/player-league.model';
import { IPlayer } from 'app/shared/model/player.model';
interface PlayerLeagueState extends EntityState<IPlayerLeague> {
  entitiesFreePlayer?: Pageable<IPlayer>;
}
const initialState: PlayerLeagueState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/player-leagues';
const apiSearchUrl = 'api/player-leagues/search';
const apiSearchPlayerUrl = 'api/free-player-leagues';

// Actions

export const getEntities = createAsyncThunk(
  'playerLeague/fetch_entity_list',
  async ({ page, size, sort, clubId, leagueId, ...res }: IQueryParams) => {
    const requestUrl = `${apiSearchUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : '?'}`;
    return axios.post<IPlayerLeague[]>(requestUrl, { ...res, clubId, leagueId });
  }
);

export const getEntitiesFreePlayerLeague = createAsyncThunk(
  'playerLeague/fetch_free_player_leagues',
  async ({ page, size, sort, clubId, leagueId, ...res }: IQueryParams) => {
    const requestUrl = `${apiSearchPlayerUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : '?'}`;
    const result = await axios.post<Pageable<IPlayer>>(requestUrl, { ...res, clubId, leagueId });
    return result.data;
  }
);

export const getEntity = createAsyncThunk(
  'playerLeague/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IPlayerLeague>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'playerLeague/create_entity',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<IPlayerLeague>(apiUrl, entity);
    thunkAPI.dispatch(getEntities({ leagueId: result?.data[0]?.leagueId, clubId: result?.data[0]?.clubId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'playerLeague/update_entity',
  async (entity: IPlayerLeague, thunkAPI) => {
    const result = await axios.put<IPlayerLeague>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'playerLeague/partial_update_entity',
  async (entity: IPlayerLeague, thunkAPI) => {
    const result = await axios.patch<IPlayerLeague>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'playerLeague/delete_entity',
  async ({ playerId, clubId }: any, thunkAPI) => {
    const requestUrl = `${apiUrl}/${playerId}`;
    const result = await axios.delete<IPlayerLeague>(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const PlayerLeagueSlice = createSlice({
  name: 'playerLeague',
  initialState,
  reducers: {
    clearMsgUpdate: (state, action) => void (state.updateSuccess = null),
    clearMsgDelete: (state, action) => void (state.deleteSuccess = null),
    clearMsgCreate: (state, action) => void (state.createSuccess = null),
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      // .addCase(getEntitiesFreePlayerLeague.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.entitiesFreePlayer = action.payload.data;
      // })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.deleteSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntitiesFreePlayerLeague), (state, action) => {
        state.entitiesFreePlayer = action.payload;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.createSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.createSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, clearMsgCreate, clearMsgDelete, clearMsgUpdate } = PlayerLeagueSlice.actions;

// Reducer
export default PlayerLeagueSlice.reducer;
