import React, { memo, useCallback, useState } from 'react';
import { Storage, translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Row, Col, Tag, Select, Form, Button, ConfigProvider, Empty } from 'antd';
import Input from 'antd/lib/input/Input';
import { SearchOutlined } from '@ant-design/icons';
import { get, isNumber } from 'lodash';
import { DefaultOptionType } from 'antd/lib/select';
import { FilterParams } from 'app/shared/model/filter.model';
import axios from 'axios';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import LmInput from '../lm-input';
import { TYPE_OF_OPTION } from 'app/shared/util/constant';
import DatePicker from '../date-picker';
import dayjs from 'dayjs';
import { getFilterOptions } from '../components/dashboar/main.reducer';
import locale from 'antd/es/date-picker/locale/de_DE';

interface Props {
  filter: FilterParams;
  onFilter: (filter: FilterParams) => void;
  options: { value: string; label: string; type?: string }[];
  reset?: boolean;
  multi?: boolean;
  ignoreOption?: boolean | false;
  ignoreClubManagement?: boolean | false;
}

const CustomDatePickerYear = (props: any) => {
  return (
    <DatePicker
      {...props}
      picker="year"
      size="large"
      style={{ width: '100%' }}
      format="YYYY"
      allowClear
      value={props.value ? dayjs(props.value, 'YYYY') : null}
      onChange={e => props.onChange(e.format('YYYY'))}
    />
  );
};

const CustomDatePicker = (props: any) => {
  return (
    <DatePicker
      locale={{
        ...locale,
        lang: {
          ...locale.lang,
          today: translate('entity.action.today'),
        },
      }}
      {...props}
      style={{ width: '100%' }}
      format="YYYY-MM-DD"
      size="large"
      allowClear
      value={props.value ? dayjs(props.value) : null}
      onChange={e => props.onChange(e.toISOString())}
    />
  );
};

const CustomGMTPicker = (props: any) => {
  return (
    <DatePicker
      locale={{
        ...locale,
        lang: {
          ...locale.lang,
          today: translate('entity.action.today'),
        },
      }}
      {...props}
      style={{ width: '100%' }}
      format="YYYY-MM-DD"
      size="large"
      allowClear
      value={props.value ? dayjs(props.value) : null}
      onChange={e => props.onChange(e.startOf('date').toISOString())}
    />
  );
};

const FilterCustom = ({ filter, onFilter, options = [], reset, multi, ignoreOption, ignoreClubManagement }: Props) => {
  const [form] = Form.useForm();

  const list = useAppSelector(state => state.dashboard.filterList);

  const { role, rating, roleSearch, position, noticeCategory, category } = list;

  const dataClassify = [
    { id: 1, title: translate('leagueApp.leagueStandard.league') },
    { id: 2, title: translate('leagueApp.leagueStandard.cup') },
  ];

  const dataStatus = [
    { id: 1, title: translate('leagueApp.match.preparing') },
    { id: 2, title: translate('leagueApp.match.ready') },
    { id: 3, title: translate('leagueApp.match.recording') },
    { id: 4, title: translate('leagueApp.match.statusMatch.finish') },
    { id: 5, title: translate('leagueApp.match.statusMatch.waitApprove') },
    { id: 6, title: translate('leagueApp.match.statusMatch.complete') },
  ];

  const dataTypeQuestion = [
    { id: 1, title: translate('leagueApp.questionAnswer.waitingAnswer') },
    { id: 0, title: translate('leagueApp.questionAnswer.answerComplete') },
  ];

  const dataQACategory = [
    { id: 1, title: translate('leagueApp.questionAnswer.QAtype.1') },
    { id: 2, title: translate('leagueApp.questionAnswer.QAtype.2') },
    { id: 3, title: translate('leagueApp.questionAnswer.QAtype.3') },
    { id: 4, title: translate('leagueApp.questionAnswer.QAtype.4') },
    { id: 5, title: translate('leagueApp.questionAnswer.QAtype.5') },
    { id: 6, title: translate('leagueApp.questionAnswer.QAtype.6') },
    { id: 7, title: translate('leagueApp.questionAnswer.QAtype.7') },
  ];
  React.useEffect(() => {
    const data = {
      ...filter,
      type: filter.type,
      classify: get(filter, 'classify') ? Number(get(filter, 'classify')) : undefined,
      sort: undefined,
      page: undefined,
      size: undefined,
      status: get(filter, 'status') ? Number(get(filter, 'status')) : undefined,
    };
    form.setFieldsValue(data);
  }, [filter]);
  React.useEffect(() => {
    const data = {
      ...filter,
      type: filter.type,
      status: get(filter, 'status') ? Number(get(filter, 'status')) : undefined,
      classify: get(filter, 'classify') ? Number(get(filter, 'classify')) : undefined,
      sort: undefined,
      page: undefined,
      size: undefined,
      [type]: '',
    };
    form.setFieldsValue(data);
  }, [reset]);

  const localeWeb = useAppSelector(state => state.locale.currentLocale);
  const type = Form.useWatch('type', form);
  const placeholder = Form.useWatch('placeholder', form);
  const typeOfOption = options?.find(item => item.value === type)?.type;

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (!ignoreOption) dispatch(getFilterOptions({ keys: options.map(item => item.value), language: localeWeb }));
  }, []);

  const renderSearch = (typeOfParam: string) => {
    if (typeOfOption === TYPE_OF_OPTION.YEAR) {
      return <CustomDatePickerYear allowClear placeholder={translate('global.selectYear')} />;
    }
    if (typeOfOption === TYPE_OF_OPTION.DATE) {
      return <CustomDatePicker style={{ width: '100%' }} allowClear placeholder={translate('global.selectDate')} />;
    }
    if (typeOfOption === TYPE_OF_OPTION.DATEGMT) {
      return <CustomGMTPicker style={{ width: '100%' }} allowClear placeholder={translate('global.selectDate')} />;
    }
    if (typeOfParam === 'position') {
      return (
        <Select className="w-100" size="large">
          {position.map(item => {
            return (
              <Select.Option key={item.value} value={item.label}>
                {item.label}
              </Select.Option>
            );
          })}
        </Select>
      );
    }
    if (['noticeCategory', 'role', 'rating'].includes(typeOfParam)) {
      return (
        <Select className="w-100" size="large">
          {get(list, typeOfParam).map(item => {
            return (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            );
          })}
        </Select>
      );
    }

    if (typeOfParam === 'roleSearch') {
      return (
        <Select className="w-100" size="large">
          {ignoreClubManagement
            ? roleSearch
                .filter(x => x.name !== 'ROLE_TEAM_MANAGER')
                .map(item => {
                  return (
                    <Select.Option key={item.value} value={item.name}>
                      {translate(`author.${item.name}`)}
                    </Select.Option>
                  );
                })
            : roleSearch.map(item => {
                return (
                  <Select.Option key={item.value} value={item.name}>
                    {translate(`author.${item.name}`)}
                  </Select.Option>
                );
              })}
        </Select>
      );
    }
    if (typeOfParam === 'classify') {
      return (
        <Select className="w-100" size="large">
          {dataClassify.map(item => {
            return (
              <Select.Option key={item.id} value={item.id}>
                {item.title}
              </Select.Option>
            );
          })}
        </Select>
      );
    }
    if (typeOfParam === 'status') {
      return (
        <Select className="w-100" size="large">
          {dataStatus.map(item => {
            return (
              <Select.Option key={item.id} value={item.id}>
                {item.title}
              </Select.Option>
            );
          })}
        </Select>
      );
    }
    if (typeOfParam === 'category') {
      return (
        <Select className="w-100" size="large">
          {category.map(item => {
            return (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            );
          })}
        </Select>
      );
    }
    return (
      <LmInput
        placeholder={translate('common.search')}
        size="large"
        disabled={!type}
        prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
      />
    );
  };
  const onSubmit = data => {
    if (multi) onFilter({ ...filter, ...data, page: 0 });
    else onFilter({ ...data, page: 0 });
  };

  return (
    <Form name="nest-messages" form={form} onFinish={onSubmit}>
      <Row gutter={[12, 12]} align="middle" className="auto-wrap">
        <Col xs={24} md={6} sm={24}>
          <Form.Item name="type" className="mb-0">
            <Select
              className="custom-prefix-select"
              suffixIcon={<SelectArrowDown />}
              // className="w-100"
              size="large"
              onChange={(value, option: DefaultOptionType) =>
                form.setFieldsValue({ placeholder: translate('leagueApp.match.search') + ' ' + option.label, type: value })
              }
            >
              {options.map(item => {
                return (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} sm={24}>
          <Form.Item name={type} className="mb-0">
            {renderSearch(type)}
          </Form.Item>
        </Col>
        <Col xs={20} md={6} sm={20}>
          <Button style={{ fontSize: '16px' }} type="primary" className="rounded" size="large" htmlType="submit" block>
            <Translate contentKey="leagueApp.match.search" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterCustom;
