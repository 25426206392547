import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Col, Input, Select, Tag, Tooltip } from 'antd';
import { formatDate, formatDateHour, formatHour } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { UnitStandardListFilter } from './unit-standard.type';
import { clearMsgCreate, clearMsgDelete, clearMsgUpdate, deleteEntity, getEntities, getEntity } from './unit-standard.reducer';
import { MatchStatusContained } from 'app/modules/status-contained';
import dayjs from 'dayjs';
import UnitStandardDeleteDialog from './unit-standard-delete-dialog';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import UnitStandardAddDialog from './unit-standard-add-dialog';
import { toast } from 'react-toastify';
import Filter from 'app/modules/filter';
import { PlusIcon } from 'app/shared/util/appIcon';
import { ConfirmationDialog } from 'app/shared/util/confirmationDialog';
import { ColumnsType } from 'antd/es/table/interface';
import MultiFilter from 'app/modules/MultiSearch';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
export const UnitStandard = (props: any) => {
  const dispatch = useAppDispatch();
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [idUpdate, setIdUpdate] = useState('');
  const [reset, setReset] = useState(false);
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
    type: 'name',
  };
  const deleteSuccess = useAppSelector(state => state.unitStandard.deleteSuccess);
  const updateSuccess = useAppSelector(state => state.unitStandard.updateSuccess);
  const createSuccess = useAppSelector(state => state.unitStandard.createSuccess);
  const links = useAppSelector(state => state.player.links);
  const unitStandardList = useAppSelector(state => state.unitStandard.entities);
  const loading = useAppSelector(state => state.unitStandard.loading);
  const account = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);
  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.unitStandard.delete.deletesuccess'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (createSuccess) {
      toast.success(translate('leagueApp.unitStandard.created.success'));
      dispatch(clearMsgCreate(createSuccess));
      setShowDialog(false);
      handleFetchData({ ...filter, page: 0 });
    }
  }, [createSuccess]);
  useEffect(() => {
    if (updateSuccess) {
      toast.success(translate('leagueApp.unitStandard.updated.success'));
      dispatch(clearMsgUpdate(updateSuccess));
      setShowDialog(false);
      setIdUpdate(null);
      handleFetchData({ ...filter, page: 0 });
    }
  }, [updateSuccess]);

  const handleDeleteModal = (user: any) => {
    setUserClicked(user);
    setModalDelete(true);
  };

  const handleFetchData = React.useCallback((params: UnitStandardListFilter) => {
    dispatch(getEntities(params));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const ActionButton = ({ id }: { id: number }) => {
    return (
      <div className={isAdmin && 'column-action-button'}>
        <Button onClick={() => handleUpdate(id)} className="rounded">
          <Translate contentKey="entity.action.edit" />
        </Button>
        {isAdmin && (
          <Button onClick={() => handleDeleteModal(id)} className="rounded" danger type="primary">
            <Translate contentKey="entity.action.delete2">Delete</Translate>
          </Button>
        )}
      </div>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: <Translate contentKey="leagueApp.unitStandard.name">Name</Translate>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <Translate contentKey="leagueApp.unitStandard.description" />,
      dataIndex: 'description',
      key: 'description',
      align: 'left' as any,
      ellipsis: { showTitle: false },
      render: (value, record) => (
        <Tooltip title={value} placement="bottomRight">
          {value}
        </Tooltip>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.unitStandard.createdBy">Created By</Translate>,
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (value, record) => value?.split('@')[0],
      align: 'center' as any,
    },
    {
      title: <Translate contentKey="leagueApp.unitStandard.createdDate"></Translate>,
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'left' as any,
      render: (value, record) => formatDateHour(value),
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 230,
      render: (value, record) => <ActionButton id={value} />,
      align: 'center' as any,
    },
  ];

  const options = [
    {
      value: 'name',
      label: translate('leagueApp.unitStandard.name'),
    },
    // {
    //   value: 'description',
    //   label: translate('leagueApp.unitStandard.description'),
    // },
  ];

  const handleUpdate = id => {
    setIdUpdate(id);
  };

  React.useEffect(() => {
    if (idUpdate) {
      setShowDialog(true);
    }
  }, [idUpdate]);

  const handleOpenModal = () => {
    setShowDialog(true);
  };

  const handleDelete = () => {
    const obj = {
      unitStandardId: userClicked,
    };
    dispatch(deleteEntity(obj)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        updateFilter();
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const updateFilter = () => {
    onFilterToQueryString({
      page: 0,
      size: 10,
      sort: 'id,asc',
      type: 'name',
    });
    setReset(!reset);
  };
  return (
    <div className="management-layout">
      {showDialog && (
        <UnitStandardAddDialog
          showDialog={showDialog}
          setShowDialog={b => setShowDialog(b)}
          entityId={idUpdate}
          setEntityId={setIdUpdate}
          updateFilter={updateFilter}
        />
      )}
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading">
            <Translate contentKey="leagueApp.unitStandard.home.title">Unit Standards</Translate>
          </h2>
          <div className="management-layout-divider"></div>
          <Button className="rounded management-header-add-button" onClick={handleOpenModal} icon={<PlusIcon />} type="primary">
            <Translate contentKey="clubmng.add" />
          </Button>
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <Filter filter={filter} onFilter={onFilterToQueryString} multi options={options} reset={reset} />
        </Col>
      </div>
      <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={unitStandardList.content}
          totalElements={unitStandardList.totalElements}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={
            <h5>
              <Translate contentKey="leagueApp.unitStandard.delete.question"></Translate>
            </h5>
          }
          confirmMessage={<Translate contentKey="leagueApp.unitStandard.delete.confirm" />}
          customTextConfirm={<Translate contentKey="entity.action.confirm" />}
          onClose={() => setModalDelete(false)}
          onConfirm={() => handleDelete()}
        />
      )}
    </div>
  );
};

export default UnitStandard;
