import React, { useState, useEffect } from 'react';
import { Button, Card, Col, ConfigProvider, Empty, Form, Row, Select } from 'antd';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import { Translate, translate } from 'react-jhipster';
import TextField from 'app/modules/lm-input';
import AttachFile from 'app/entities/attach-file';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { AUTHORITIES, NOTICE_CATEGORY, QUESTION_CATEGORY, QUESTION_CATEGORY_SYSTEM } from 'app/config/constants';
import axios from 'axios';
import RichTextEditor from 'app/modules/rich-text-editor';
import { BsCaretLeft } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Pageable } from 'app/shared/reducers/reducer.utils';
import { IAttachFile, INotice } from 'app/shared/model/notice.model';
import { IQuestionAnswer } from 'app/shared/model/question-answer.model';
import { createEntity, uploadAttachFile } from './question-answer.reducer';
import { handleValidateMessageLanguageChanged } from 'app/shared/util/validate';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { CATEGORY_DATA_CODE } from 'app/shared/util/category-data-code.constant';
import { toast } from 'react-toastify';

const CreateQA = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const files = Form.useWatch('attachmentDTOS', form);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const isAdmin = hasAnyAuthority(authorities, [AUTHORITIES.ADMIN]);
  const isLeagueMaster = hasAnyAuthority(authorities, [AUTHORITIES.LEAGUE_MASTER]);
  const isRecorder = hasAnyAuthority(authorities, [AUTHORITIES.RECORDER]);
  const isClub = hasAnyAuthority(authorities, [AUTHORITIES.TEAM_MANAGER]);
  const loading = useAppSelector(state => state.questionAnswerReducer.loading);
  const [qaType, setQaType] = useState([]);
  const handleBack = e => {
    history.goBack();
  };
  const handleSubmit = (data: IQuestionAnswer) => {
    dispatch(uploadAttachFile(data.attachmentDTOS)).then(res => {
      res.meta.requestStatus === 'fulfilled' &&
        dispatch(createEntity({ ...data, parentId: null, attachmentDTOS: res.payload as IAttachFile[] })).then(response => {
          if (response.meta.requestStatus === 'fulfilled') {
            history.push('../question-and-answer');
            toast.success(translate('leagueApp.questionAnswer.createQuestionSuccess'));
          }
        });
    });
  };
  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
    handleQAType({ code: CATEGORY_DATA_CODE.QA_TYPE, lang: locale });
  }, [locale]);
  const handleQAType = async ({ code, lang }: any) => {
    const res = await axios.get<any>(`api/category-data-by-code?code=${code}&lang=${lang}`);
    const arr = [];
    res.data.dataTrans.map((item, index) => {
      arr.push({
        value: item.code,
        label: item.name,
      });
    });
    setQaType(arr);
  };

  return (
    <Form
      form={form}
      onFinishFailed={values => handleFocusFieldFail(values)}
      initialValues={{ attachmentDTOS: [], type: isLeagueMaster ? '7' : null, title: '' }}
      scrollToFirstError
      name="control-hooks"
      layout="vertical"
      requiredMark={'optional'}
      onFinish={handleSubmit}
    >
      <Card className="mb-3">
        <div className="border-bottom d-flex justify-content-between align-items-center" style={{ paddingBottom: 30, marginBottom: 32 }}>
          <div className="d-flex align-items-center auto-wrap">
            <Button className="rounded management-header-add-button" icon={<BsCaretLeft />} onClick={handleBack} type="primary">
              <Translate contentKey="entity.action.back" />
            </Button>
            <div className="management-layout-divider"></div>
            <p className="h4 mb-0">
              {' '}
              <Translate contentKey="leagueApp.questionAnswer.createQuestion" />
            </p>
          </div>

          <Button loading={loading} htmlType="submit" type="primary">
            <Translate contentKey="common.save" />
          </Button>
        </div>
        <Row gutter={[24, 12]}>
          <Col xl={6} lg={12} xs={24}>
            <Form.Item
              label={<h6>{translate('leagueApp.questionAnswer.QAcategory')}</h6>}
              name="type"
              rules={[{ required: true, message: translate('leagueApp.questionAnswer.required.QAcategory') }]}
            >
                <Select
                  disabled={isLeagueMaster}
                  placeholder={translate('leagueApp.questionAnswer.typePlaceholder')}
                  allowClear
                  className="custom-prefix-select"
                  suffixIcon={<SelectArrowDown />}
                  options={qaType}
                ></Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={<h6>{translate('leagueApp.notice.title')}</h6>}
              name="title"
              rules={[{ required: true, message: translate('leagueApp.notice.required.title') }]}
            >
              <TextField placeholder={translate('leagueApp.questionAnswer.titlePlaceholder')} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={<h6>{translate('leagueApp.notice.content')}</h6>}
              name="content"
              rules={[{ required: true, message: translate('leagueApp.notice.required.content') }]}
            >
              <RichTextEditor />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Form.Item name="attachmentDTOS">
        <AttachFile
          files={files}
          onChange={fileList => {
            form.setFieldsValue(pre => ({ ...pre, attachmentDTOS: fileList }));
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default CreateQA;
