import React, { useEffect } from 'react';
import { Checkbox, Form, InputNumber, Table } from 'antd';
import { memo } from 'react';
import { translate } from 'react-jhipster';
import { useAppSelector } from './../../config/store';
import { handleValidateMessageLanguageChanged } from 'app/shared/util/validate';

const TableInput = ({ record, form, typeName, onValueChange, onMomChange, canChange, ...rest }) => {
  const { Column } = Table;
  const locale = useAppSelector(state => state.locale.currentLocale);
  const getRequiredRule = (index: number) => {
    const originType = form.getFieldValue([typeName, index])?.originType;
    return originType ? [{ required: true, message: translate('matchOver.scoreAndMomData.pointRequired') }] : [];
  };

  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
  }, [locale]);

  return (
    <Table dataSource={record} pagination={false} {...rest}>
      <Column
        dataIndex={'number'}
        title={translate('matchOver.recordTableData.number')}
        render={(value, row, index) => <p>{form.getFieldValue([typeName, index, 'playerDTOS'])?.uniformNumber}</p>}
      />
      <Column
        dataIndex={'location'}
        title={translate('matchOver.recordTableData.location')}
        render={(value, row, index) => (
          <p>
            {form.getFieldValue([typeName, index])?.type
              ? translate(`leagueApp.playerAgent.${form.getFieldValue([typeName, index, 'playerDTOS'])?.position}`)
              : translate('matchOver.scoreAndMomData.reservePlayer')}
          </p>
        )}
      />
      <Column
        dataIndex={'playerName'}
        title={translate('matchOver.recordTableData.playerName')}
        render={(value, row, index) => <p>{form.getFieldValue([typeName, index, 'playerDTOS'])?.koName}</p>}
        width={200}
      />
      <Column
        dataIndex={'number'}
        title={translate('matchOver.scoreAndMomData.score')}
        width={100}
        render={(value, row, index) => (
          <Form.Item rules={getRequiredRule(index)} name={[index, 'point']} style={{ marginBottom: 0 }}>
            <InputNumber
              readOnly={!canChange}
              max={10}
              className="input-44"
              step={0.1}
              precision={1}
              min={0}
              controls={false}
              onChange={onValueChange}
            />
          </Form.Item>
        )}
      />
      <Column
        dataIndex={'number'}
        title={translate('matchOver.scoreAndMomData.mom')}
        render={(value, row, index) => (
          <Form.Item valuePropName="checked" name={[index, 'mom']} style={{ marginBottom: 0 }}>
            <Checkbox
              className="custom-checkbox-disabled"
              disabled={!canChange}
              onChange={e => {
                onMomChange(typeName, index);
              }}
            />
          </Form.Item>
        )}
      />
    </Table>
  );
};

export default memo(TableInput);
