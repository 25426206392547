import React from 'react';

import { Avatar, Col, Row, Tag } from 'antd';
import { EditIcon } from 'app/shared/util/appIcon';
import { translate } from 'react-jhipster';
import { getColor, getPosition } from '../line-up/components/list-item';
import MiniMapPosition from '../mini-map-position';
import './index.scss';
type Props = {
  children?: React.ReactNode | React.ReactNode[];
  onEditPlayer: () => void;
  onEditPosition: () => void;
  positionMap: any;
  player: any;
  avatar: any;
  prefix: any;
  hideEditPlayer?: boolean;
  hideEditPosition?: boolean;
};

const RecordPlayerInfo = ({
  onEditPosition,
  onEditPlayer,
  positionMap,
  player,
  avatar,
  prefix,
  hideEditPlayer,
  hideEditPosition,
}: Props) => {
  return (
    <div className="record-player-info">
      <div className="record-player-info-actions">
        <p className="neutral-2">{translate('freeCornerKick.player')}</p>
        {!hideEditPlayer && (
          <div onClick={onEditPlayer}>
            <EditIcon />
            <p className="primary-color" style={{ marginLeft: 8 }}>
              {translate('freeCornerKick.select')}
            </p>
          </div>
        )}
      </div>
      <div className="record-player-info-player">
        {player && (
          <Row justify="space-between">
            <Col xs={24} className="d-flex justify-content-center mb-3">
              <Avatar size={172} shape="square" className="mx-auto" src={avatar} />
            </Col>
            <Col xs={24} flex="none" className="detail-player-item">
              <Tag color={getColor(prefix ? prefix.toString() : '')}>{getPosition(prefix)}</Tag>
              <p className="text-small  font-weight-bolder mb-0" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {player.matchPlayerFieldDTO?.playerDTOS?.uniformNumber}.{player.matchPlayerFieldDTO?.playerDTOS?.koName}
              </p>
            </Col>
          </Row>
        )}
      </div>
      <div className="record-player-info-actions">
        <p className="neutral-2">{translate('freeCornerKick.position')}</p>
        {!hideEditPosition && (
          <div onClick={onEditPosition}>
            <EditIcon />
            <p className="primary-color" style={{ marginLeft: 8 }}>
              {translate('freeCornerKick.select')}
            </p>
          </div>
        )}
      </div>
      <div className="record-player-info-map">
        <MiniMapPosition positionValue={positionMap} />
      </div>
    </div>
  );
};
export default RecordPlayerInfo;
