import React, { useState, useEffect } from 'react';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount, translate } from 'react-jhipster';

import { Card, Col, Form, Modal, Radio, Row, Space, Button } from 'antd';
import ListItem from 'app/modules/pick-player/components/list-item';
import { PickItem } from 'app/modules/pick-item';
import { EditOutlined } from '@ant-design/icons';
import PickPosition from 'app/modules/pick-position';
import PickPlayer from 'app/modules/pick-player';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import MiniMapPosition from 'app/modules/mini-map-position';
import { getEntity, getListCategoryValue } from './player-ban-record.reducer';
import { get } from 'lodash';
import { actionRecord } from 'app/shared/util/constant';
import { urlWs } from 'app/modules/event-list-record/constant.event-list';
import { sendWSActivity } from 'app/config/websocket-middleware';
import { IPlayerBanRecord } from 'app/shared/model/player-ban-record.model';
import RecordPlayerInfo from 'app/modules/record-player-info';
import { toast } from 'react-toastify';

interface Props {
  open: boolean;
  onCancel: () => void;
  value?: string | null;
  onEditPlayer: () => void;
  onEditPosition: () => void;
  id?: number;
  awayAvatars: any;
  ownerAvatars: any;
  processTime: any;
}

export const PlayerBanRecord = ({ processTime, onCancel, open, onEditPlayer, onEditPosition, id, awayAvatars, ownerAvatars }: Props) => {
  const form = Form.useFormInstance();

  const playerInForm = Form.useWatch('player', form);

  const [defaultForm, setDefaultForm] = useState<IPlayerBanRecord>();
  const requiredRule = { required: true, message: '' };

  const dispatch = useAppDispatch();

  const locale = useAppSelector(state => state.locale.currentLocale);

  const { classify, reasons } = useAppSelector(state => state.playerBan?.list);

  useEffect(() => {
    dispatch(getListCategoryValue(locale));
    if (!id) {
      return;
    }
    dispatch(getEntity(id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        const data = get(res.payload, 'data');
        setDefaultForm(data);
        form.setFieldsValue({ ...data });
      }
    });
  }, [id]);
  const handleSubmit = data => {
    if (!data.detailLocation) {
      toast.error(translate('scoreRecord.message.supportForm'));
      return;
    }
    if (!id) {
      sendWSActivity(
        `/record/player-ban/create/${data.player?.matchPlayerFieldDTO.matchId}/${data.player?.matchPlayerFieldDTO.clubId}/${data.player?.matchPlayerFieldDTO.type}`,
        {
          ...data,
          playerId: data.player.playerId,
          matchId: data.player.matchPlayerFieldDTO.matchId,
          player: undefined,
          banType: Number(data.banType),
          reasonBan: Number(data.reasonBan),
          processDate: processTime?.process,
          roundType: processTime?.roundType,
        }
      );
    } else {
      sendWSActivity(urlWs(actionRecord.WARNING, 'edit', data.player.matchPlayerFieldDTO.matchId, id), {
        ...defaultForm,
        ...data,
        playerDTO: { ...defaultForm.playerDTO, base64Avt: null },
        id,
        playerId: data.player.playerId,
        matchId: data.player.matchPlayerFieldDTO.matchId,
        player: undefined,
        banType: Number(data.banType),
        reasonBan: Number(data.reasonBan),
      });
    }
    onCancel();
  };
  return (
    <Modal
      title={translate('leagueApp.playerBanRecord.ban')}
      className="register-record"
      visible={open}
      width={900}
      onCancel={onCancel}
      onOk={() => form.submit()}
      zIndex={1}
      footer={[
        <Button key="close" onClick={() => onCancel()}>
          <span>{translate('freeCornerKick.close')}</span>
        </Button>,
        <Button key="save" type="primary" onClick={() => form.submit()}>
          <span>{translate('freeCornerKick.save')}</span>
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        style={{ width: 797, overflow: 'auto' }}
        onFinishFailed={() => {
          toast.error(translate('common.pleaseChooseEnoughData'));
        }}
      >
        <Row gutter={32} justify="center" style={{ width: 800 }} wrap={false}>
          <Col>
            <RecordPlayerInfo
              avatar={
                // playerInForm?.matchPlayerFieldDTO?.playerDTOS?.avatar
                get(awayAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  ? get(awayAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  : get(ownerAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  ? get(ownerAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  : undefined
              }
              prefix={playerInForm?.positionType}
              onEditPlayer={() => {
                onEditPlayer();
              }}
              onEditPosition={() => {
                onEditPosition();
              }}
              hideEditPlayer={!!id}
              player={playerInForm}
              positionMap={form.getFieldValue('detailLocation')}
            />
          </Col>
          <Col>
            <Row wrap={false}>
              <Col className="first-header">
                <PickItem
                  name="banType"
                  list={classify}
                  getValue={item => +item.code}
                  getLabel={item => item.name}
                  title={'*' + translate('leagueApp.playerBanRecord.classify.classify')}
                  rules={[requiredRule]}
                />
              </Col>
              <Col className="last-header">
                <PickItem
                  name="reasonBan"
                  list={reasons}
                  getValue={item => +item.code}
                  getLabel={item => item.name}
                  title={translate('leagueApp.playerBanRecord.reason.reason')}
                  // rules={[requiredRule]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PlayerBanRecord;
