import React, { useReducer, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Tag, Row, Col, Tooltip, Select } from 'antd';
import { formatDate2, formatHour } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { MatchListFilter } from './match.type';
import {
  getEntities,
  getEntity,
  resetEntity,
  resetType,
  saveType,
  getStatus,
  updateEntity,
  deleteFavourite,
  addFavourite,
} from './match.reducer';
import { MatchStatusContained } from 'app/modules/status-contained';
import dayjs from 'dayjs';
import { Link, useHistory } from 'react-router-dom';
import { path } from '../routes';
import Filter from 'app/modules/filter';
import { TableRowSelection } from 'antd/lib/table/interface';
import { AUTHORITIES, STATUS_MATCH, TYPE_INFORMATION } from 'app/config/constants';
import './style.scss';
import {
  DownloadOutlined,
  EditOutlined,
  FileDoneOutlined,
  FormOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
  SyncOutlined,
  VideoCameraAddOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { StarActive, StarInactive } from 'app/shared/util/appIcon';
import { IsFavorite } from 'app/shared/util/constant/favorite.constant';
import MultiFilter from 'app/modules/MultiSearch';
import { TYPE_OF_OPTION } from 'app/shared/util/constant';
import { connect, disconnect, unsubscribe } from 'app/config/websocket-middleware';
import { MatchRecordSlice } from '../match-record/match-record.reducer';
import { debounce } from 'lodash';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ExportFileMathDialog from './export-file-math-dialog';
import { downloadCSV } from './MatchPreparation/container/squad/export-file.reducer';

interface IState {
  matchId: number | null;
}

const reducer = (state: IState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case 'CHANGE_MATCH_ID':
      state.matchId = payload;
      return { ...state };
    default:
      return { ...state };
  }
};
export const Match = (props: any) => {
  const [state, dispatch] = useReducer(reducer, {
    matchId: null,
  });
  const [createModal, setCreateModal] = useState(false);
  const dispatchTookit = useAppDispatch();
  const account = useAppSelector(_state => _state.authentication.account);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);
  const handleModalCreate = (id: number) => {
    setCreateModal(true);
    dispatch({ type: 'CHANGE_MATCH_ID', payload: id });
  };
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'leagueName,desc',
    type: 'leagueName',
  };

  const handleFetchData = React.useCallback((params: MatchListFilter) => {
    dispatchTookit(getEntities(params));
    dispatchTookit(resetType());
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const { push } = useHistory();
  const handleClickSubmit = async (payload: { matchId: number; lang: any; zoneId: string; futsal: any }) => {
    await dispatchTookit(downloadCSV(payload));
    setCreateModal(false);
  };

  const ActionButton = ({
    status,
    startDate,
    id,
    flag,
    ownerClubName,
    awayClubName,
  }: {
    status: number;
    startDate: string;
    id: number;
    flag?: number;
    ownerClubName?: string;
    awayClubName?: string;
  }) => {
    if (status === STATUS_MATCH.WAIT_RECORD) {
      if (flag === TYPE_INFORMATION.TYPED) {
        return (
          <div className={'column-action-button d-flex justify-content-center'}>
            <div>
              <Tooltip placement="bottomRight" title={<Translate contentKey="leagueApp.match.recordMatch" />}>
                <VideoCameraAddOutlined
                  onClick={() => {
                    push(`/real-time-recording/${id}/match-record`);
                    unsubscribe();
                  }}
                  style={{ fontSize: '32px', marginRight: '18px', color: '#0062ae' }}
                />
              </Tooltip>
              <Tooltip placement="bottomLeft" title={<Translate contentKey="leagueApp.match.button.edit" />}>
                <EditOutlined
                  onClick={() => push(path.matchPreparation.replace(':id', id.toString()))}
                  style={{ fontSize: '32px', marginRight: '18px', color: '#0062ae' }}
                />
              </Tooltip>

              <Tooltip placement="bottomLeft" title={<Translate contentKey="leagueApp.match.file" />}>
                <DownloadOutlined onClick={() => handleModalCreate(id)} style={{ fontSize: '32px', color: '#0062ae' }} />
              </Tooltip>
              {/* {isAdmin && ( */}
              {/* )} */}
            </div>
          </div>
        );
      }
      if (flag === TYPE_INFORMATION.NOT_TYPE) {
        return (
          <div className={'column-action-button'}>
            <Tooltip placement="bottomRight" title={<Translate contentKey="leagueApp.match.typeInfoBefore" />}>
              <FormOutlined
                disabled={!ownerClubName || !awayClubName ? true : false}
                className="w-100 d-flex justify-content-end"
                onClick={() => push(path.matchPreparation.replace(':id', id.toString()))}
                style={{ fontSize: '32px', color: '#0062ae', marginRight: '18px' }}
              />
            </Tooltip>

            <Tooltip placement="bottomLeft" title={<Translate contentKey="leagueApp.match.file" />}>
              <DownloadOutlined
                onClick={() => handleModalCreate(id)}
                className=" w-100 d-flex justify-content-start"
                style={{ fontSize: '32px', color: '#0062ae', marginRight: '90px' }}
              />
            </Tooltip>
          </div>
        );
      }
    }
    if (status === STATUS_MATCH.RECORDING) {
      return (
        <div className="w-100 d-flex justify-content-center">
          <Tooltip placement="bottom" title={<Translate contentKey="leagueApp.match.recordMatchContinue" />}>
            <VideoCameraOutlined
              onClick={() => push(`/real-time-recording/${id}/match-record`)}
              style={{ fontSize: '32px', marginRight: '18px', color: '#0062ae' }}
            />
          </Tooltip>

          <Tooltip placement="bottomLeft" title={<Translate contentKey="leagueApp.match.button.edit" />}>
            <EditOutlined
              onClick={() => push(path.matchPreparation.replace(':id', id.toString()))}
              style={{ fontSize: '32px', marginRight: '18px', color: '#0062ae' }}
            />
          </Tooltip>

          <Tooltip placement="bottomLeft" title={<Translate contentKey="leagueApp.match.file" />}>
            <DownloadOutlined onClick={() => handleModalCreate(id)} style={{ fontSize: '32px', color: '#0062ae' }} />
          </Tooltip>
        </div>
      );
    }
    if (status === STATUS_MATCH.DETAIL) {
      return (
        <div className="w-100 d-flex justify-content-center">
          <Tooltip placement="bottom" title={<Translate contentKey="leagueApp.match.details" />}>
            <InfoCircleOutlined
              onClick={() => {
                push(`/real-time-recording/${id}/record-table`);
                dispatchTookit(saveType('view'));
                dispatchTookit(resetEntity());
              }}
              style={{ fontSize: '32px', marginRight: '18px', color: '#0062ae' }}
            />
          </Tooltip>
          <Tooltip placement="bottomLeft" title={<Translate contentKey="leagueApp.match.button.edit" />}>
            <EditOutlined
              onClick={() => push(path.matchPreparation.replace(':id', id.toString()))}
              style={{ fontSize: '32px', marginRight: '18px', color: '#0062ae' }}
            />
          </Tooltip>

          <Tooltip placement="bottomLeft" title={<Translate contentKey="leagueApp.match.file" />}>
            <DownloadOutlined onClick={() => handleModalCreate(id)} style={{ fontSize: '32px', color: '#0062ae' }} />
          </Tooltip>
        </div>
      );
    }
    return (
      <Tooltip placement="bottom" title={<Translate contentKey="leagueApp.match.details" />}>
        <InfoCircleOutlined
          className=" w-100 d-flex justify-content-end"
          onClick={() => {
            push(`/real-time-recording/${id}/record-table`);
            dispatchTookit(saveType('view'));
            dispatchTookit(resetEntity());
          }}
          style={{ fontSize: '32px', color: '#0062ae' }}
        />
      </Tooltip>
    );
  };

  const { actions } = MatchRecordSlice;
  const connectSuccess = useAppSelector(_state => _state.matchRecord?.connectSuccess);

  React.useEffect(() => {
    if (connectSuccess) {
      const connectWs = debounce(() => connect(wsStatus => dispatchTookit(actions.updateWsStatus(wsStatus))), 30);
      connectWs();
      return disconnect();
    }
  }, []);

  const matchList = useAppSelector(_state => _state.match?.entities);
  const loading = useAppSelector(_state => _state.match?.loading);

  const columns = [
    {
      dataIndex: 'favourite',
      key: 'favourite',
      width: 60,
      render: (value, record) => (
        <button
          style={{ background: 'transparent', width: '40px', border: 'none' }}
          onClick={() => {
            value === IsFavorite.TRUE
              ? dispatchTookit(deleteFavourite(record?.id)).then(res => {
                  if (res.meta.requestStatus === 'fulfilled') {
                    dispatchTookit(getEntities(filter));
                  }
                })
              : dispatchTookit(addFavourite({ matchId: record?.id })).then(res => {
                  if (res.meta.requestStatus === 'fulfilled') {
                    dispatchTookit(getEntities(filter));
                  }
                });
          }}
        >
          {value === IsFavorite.TRUE ? <StarActive /> : <StarInactive />}
        </button>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.match.leagueName" />,
      dataIndex: 'leagueName',
      key: 'leagueName',
      ellipsis: { showTitle: false },
      render: (value, record) => (
        <>
          <Avatar src={record.lsBase64Avt} size="large" style={{ marginRight: '12px' }} shape="square" />
          <Tooltip placement="bottomRight" title={record.leagueName}>
            <Link to={`league-detail/${record?.leagueId}/match`}>
              <span className="hot-link-club font-weight-light">{record.leagueName}</span>
            </Link>
          </Tooltip>
        </>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.match.numberMatch1" />,
      dataIndex: 'countMatch',
      key: 'countMatch',
      align: 'center' as any,
    },
    {
      title: <Translate contentKey="leagueApp.match.round1" />,
      dataIndex: 'matchRound',
      key: 'matchRound',
    },
    {
      title: <Translate contentKey="leagueApp.match.matchDate" />,
      dataIndex: 'startDate',
      key: 'matchDate',
      render: (value, record) => formatDate2(value),
    },
    {
      title: <Translate contentKey="leagueApp.match.matchHour" />,
      dataIndex: 'startDate',
      key: 'matchHour',
      render: (value, record) => formatHour(value),
    },
    {
      title: <Translate contentKey="leagueApp.match.ownerClub" />,
      dataIndex: 'ownerClubName',
      key: 'ownerClubName',
      width: 220,
      ellipsis: { showTitle: false },
      render: (value, record) => (
        <>
          <Avatar src={record.ownerBase64Avt} size="large" style={{ marginRight: '12px' }} />
          <Tooltip placement="bottomRight" title={record.ownerClubName}>
            <Link to={`club-detail/${record?.ownerClubId}/league-attend`}>
              <span className="hot-link-club">{record.ownerClubName}</span>
            </Link>
          </Tooltip>
        </>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.match.awayClub" />,
      dataIndex: 'awayClubName',
      key: 'awayClubName',
      width: 220,
      ellipsis: { showTitle: false },
      render: (value, record) => (
        <>
          <Avatar src={record.awayBase64Avt} size="large" style={{ marginRight: '12px' }} />
          <Tooltip placement="bottomRight" title={record.awayClubName}>
            <Link to={`club-detail/${record?.awayClubId}/league-attend`}>
              <span className="hot-link-club">{record.awayClubName}</span>
            </Link>
          </Tooltip>
        </>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.match.status" />,
      dataIndex: 'status',
      key: 'status',
      width: 220,
      render: (value, record) => (
        <MatchStatusContained status={value} startDate={record.startDate} flag={record.flag} approval={record?.approval} />
      ),
    },
    {
      title: <Translate contentKey="leagueApp.match.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 280,
      render: (value, record) => (
        <>
          <ActionButton
            ownerClubName={record?.ownerClubName}
            awayClubName={record?.awayClubName}
            status={record.status}
            startDate={record.startDate}
            id={record.id}
            flag={record.flag}
          />
        </>
      ),
      align: 'center' as any,
    },
  ];

  const options = [
    {
      value: 'leagueName',
      label: translate('leagueApp.match.leagueName'),
    },
    {
      value: 'ownerClubName',
      label: translate('leagueApp.match.homeClub'),
    },
    {
      value: 'awayClubName',
      label: translate('leagueApp.match.awayClub'),
    },
    {
      value: 'startDate',
      label: translate('leagueApp.match.matchDate'),
      type: TYPE_OF_OPTION.DATEGMT,
    },
    {
      value: 'status',
      label: translate('leagueApp.match.status'),
    },
  ];

  return (
    <>
      {createModal ? (
        <ExportFileMathDialog
          createModal={createModal}
          setCreateModal={setCreateModal}
          matchId={state.matchId}
          handleClickSubmit={handleClickSubmit}
        />
      ) : (
        ''
      )}
      <div className="management-layout">
        <div className="d-flex justify-content-between align-items-center management-layout-header">
          <div className="d-flex align-items-center auto-wrap">
            <h2 id="player-heading" data-cy="PlayerHeading">
              <Translate contentKey="sidebar.matchList" />
            </h2>
          </div>
          <Col xl={10} lg={16} md={20} sm={24}>
            <Filter filter={filter} onFilter={onFilterToQueryString} multi options={options} />
          </Col>
        </div>
        <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={matchList?.content}
            totalElements={matchList?.totalElements}
            loading={loading}
            filter={filter}
            onChange={params => onFilterToQueryString({ ...filter, ...params })}
          />
        </div>
      </div>
    </>
  );
};

export default Match;
