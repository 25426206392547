import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import axios from 'axios';
import { downloadFileFromRes } from 'app/shared/util/helper';
import { translate } from 'react-jhipster';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
export const downloadCSV = createAsyncThunk(
  'matchOver/downloadCSV',
  async ({ matchId, lang, zoneId, futsal }: any) => {
    const requestUrl = `/api/report/download-selection-list-match-report?matchId=${matchId}&language=${lang}&zoneId=${zoneId}&futsal=${futsal}`;
    const res = (await axios.get(requestUrl, {
      responseType: 'blob',
    })) as any;

    downloadFileFromRes({ blob: res.data, fileName: `${translate('dashboard.matchReport')}.pdf` });
  },
  {
    serializeError: serializeAxiosError,
  }
);

export const downloadCSVSquad = createAsyncThunk(
  'matchOver/downloadCSVSquad',
  async ({ matchId, lang, zoneId, futsal }: any) => {
    const requestUrl = `/api/report/download-start-list-match-report?matchId=${matchId}&language=${lang}&zoneId=${zoneId}&futsal=${futsal}`;
    const res = (await axios.get(requestUrl, {
      responseType: 'blob',
    })) as any;

    downloadFileFromRes({ blob: res.data, fileName: `${translate('dashboard.matchReport')}.pdf` });
  },
  {
    serializeError: serializeAxiosError,
  }
);

export const downloadCSVMatchOver = createAsyncThunk(
  'matchOver/downloadCSVSquad',
  async ({ matchId, lang, zoneId, futsal }: any) => {
    const requestUrl = `/api/report/download-summary-match-report?matchId=${matchId}&language=${lang}&zoneId=${zoneId}&futsal=${futsal}`;
    const res = (await axios.get(requestUrl, {
      responseType: 'blob',
    })) as any;

    downloadFileFromRes({ blob: res.data, fileName: `${translate('dashboard.matchReport')}.pdf` });
  },
  {
    serializeError: serializeAxiosError,
  }
);
