import React, { useState } from 'react';
import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { useFilter } from 'app/shared/hooks/useFilter';
import { ICRUDWhitelist, IParamsFilterWhitelist, IWhiteList } from 'app/shared/model/user.model';
import { PlusIcon } from 'app/shared/util/appIcon';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';
import { formatDateHour } from 'app/shared/util/date-utils';
import { translate, Translate } from 'react-jhipster';
import { useParams } from 'react-router-dom';
import { deleteWhitelistEntity, getWhitelistEntities } from './user-management.reducer';
import AllowIPEditModal from './user-manager-allowed-ip-edit';

function ManagerAllowedIp() {
  const INIT_FILTER = {
    page: 0,
    size: 10,
    sort: 'asc',
  };
  const dispatch = useAppDispatch();
  const { id: userId } = useParams<{ id: string; params: string }>();
  const { allowedIps, loading } = useAppSelector(state => state.userManagement);

  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [allowedIPSelected, setAllowedIPSelected] = useState<IWhiteList>(undefined);

  const uiEvents = {
    onShowEditAllowIP(allowedIP) {
      setShowEdit(true);
      setAllowedIPSelected(allowedIP);
    },
    onHideEditAllowIP() {
      setShowEdit(false);
    },
    onShowCreateAllowIP() {
      setShowCreate(true);
    },
    onHideCreateAllowIP() {
      setShowCreate(false);
    },
    onShowDeleteAllowIP(allowedIPSelect) {
      setShowDelete(true);
      setAllowedIPSelected(allowedIPSelect);
    },
    onHideDeleteAllowIP() {
      setShowDelete(false);
      setAllowedIPSelected(undefined);
    },
  };

  const handleFetchData = React.useCallback(
    params => {
      dispatch(getWhitelistEntities({ ...params, userId }));
    },
    [userId]
  );

  const {
    filter,
    onFilterToQueryString,
  }: { filter: IParamsFilterWhitelist; onFilterToQueryString: (values: IParamsFilterWhitelist) => void } = useFilter({
    defaultFilter: INIT_FILTER,
    onFetchData: handleFetchData,
  });

  const ActionButton = ({ record }) => {
    return (
      <div className="column-action-button">
        <Button
          onClick={e => {
            e.stopPropagation();
            uiEvents.onShowEditAllowIP(record);
          }}
          className="rounded me-3"
        >
          <Translate contentKey="entity.action.update" />
        </Button>
        <Button
          onClick={e => {
            e.stopPropagation();
            uiEvents.onShowDeleteAllowIP(record);
          }}
          className="rounded"
          type="primary"
          danger
        >
          <Translate contentKey="entity.action.delete2">Delete</Translate>
        </Button>
      </div>
    );
  };

  const IPRange = ({ record }) => {
    if (record.ipFrom && record.ipTo) {
      return <span>{`${record.ipFrom} ~ ${record.ipTo.split('.').splice(-1).join('.')}`}</span>;
    } else {
      return <span>{record.ipFrom}</span>;
    }
  };

  const columns = [
    {
      title: <Translate contentKey="userManagement.detail.allowIP.allowedName" />,
      dataIndex: 'name',
      key: 'name',
      width: 300,
    },
    {
      title: <Translate contentKey="userManagement.detail.allowIP.allowed" />,
      dataIndex: 'connectionIp',
      key: 'connectionIp',
      width: 800,
      render: (value, record) => <IPRange record={record} />,
    },
    {
      title: <Translate contentKey="userManagement.detail.allowIP.registrationDate" />,
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: 300,
      render: (value, record) => <span>{formatDateHour(value)}</span>,
    },
    {
      title: <Translate contentKey="userManagement.detail.allowIP.actions" />,
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (value, record) => <ActionButton record={record} />,
      align: 'center' as any,
    },
  ];

  const handleDelete = () => {
    const params: ICRUDWhitelist = {
      whitelistId: allowedIPSelected.id,
    };
    dispatch(deleteWhitelistEntity(params)).then((res: any) => {
      if (!res.payload.data) {
        handleFetchData(filter);
        uiEvents.onHideDeleteAllowIP();
      }
    });
  };

  return (
    <>
      <AllowIPEditModal
        title={translate('userManagement.detail.allowIP.create')}
        isOpen={showCreate}
        closeDialog={(updateList: boolean) => {
          uiEvents.onHideCreateAllowIP();
          updateList && handleFetchData(filter);
        }}
      />

      <AllowIPEditModal
        title={translate('userManagement.detail.allowIP.edit')}
        isOpen={showEdit}
        allowedIP={allowedIPSelected}
        closeDialog={(updateList: boolean) => {
          uiEvents.onHideEditAllowIP();
          updateList && handleFetchData(filter);
        }}
      />

      <div className="d-flex justify-content-between align-items-center management-layout-header" style={{ paddingTop: 30 }}>
        <div className="d-flex align-items-center">
          <h2 id="player-heading" data-cy="PlayerHeading" className="heading-20-bold neutral-1">
            <Translate contentKey="userManagement.detail.allowIP.title" />
          </h2>
          <div className="management-layout-divider"></div>
          <Button
            className="rounded management-header-add-button"
            onClick={uiEvents.onShowCreateAllowIP}
            icon={<PlusIcon />}
            type="primary"
          >
            <Translate contentKey="clubmng.add" />
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={allowedIps?.content || []}
        totalElements={allowedIps?.totalElements || 0}
        loading={loading}
        filter={filter}
        onChange={params => onFilterToQueryString({ ...filter, ...params })}
      />

      <ConfirmationDialog
        open={showDelete}
        message={<h6>{translate('userManagement.detail.allowIP.delete.question')}</h6>}
        onClose={() => setShowDelete(false)}
        onConfirm={handleDelete}
      />
    </>
  );
}

export default ManagerAllowedIp;
