export interface IClub {
  id?: number;
  avatar?: string | null;
  name?: string | null;
  shortName?: string | null;
  issueYear?: string | null;
  managerId?: number | null;
  managerName?: string | null;
  website?: string | null;
  description?: string | null;
  address?: string | null;
  stadiumId?: number | null;
  dataUploadFile?: string | null;
}

export const defaultValue: Readonly<IClub> = {};
