import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { modules } from 'app/shared/util/helper';
import './style.scss';

interface Props {
  value?: string;
  onChange?: (value?: string) => void;
  maxLength?: number;
}

const RichTextEditor = ({ value, onChange, maxLength }: Props) => {
  const [quillRef, setQuillRef] = useState(null);
  const [reactQuillRef, setReactQuillRef] = useState(null);
  useEffect(() => {
    if (typeof reactQuillRef?.getEditor !== 'function') return;
    setQuillRef(reactQuillRef.getEditor());
  }, [reactQuillRef]);

  return (
    <ReactQuill
      ref={el => {
        setReactQuillRef(el);
      }}
      theme="snow"
      modules={modules}
      className="text-editor"
      value={value}
      onChange={(content, delta, source, editor) => {
        const limit = maxLength;
        quillRef.on('text-change', function (deltas, old, sources) {
          if (quillRef.getLength() > limit) {
            quillRef.deleteText(limit, quillRef.getLength());
          }
        });
        onChange(content);
      }}
    />
  );
};

export default RichTextEditor;
