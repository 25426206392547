import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Col, Tag } from 'antd';
import { formatDate, formatHour, formatYear } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { PlayerAcademicFilter } from './player-academic.type';
import { clearMsgCreate, clearMsgDelete, clearMsgUpdate, deleteEntity, getEntities, getEntity } from './player-academic.reducer';
import { MatchStatusContained } from 'app/modules/status-contained';
import ModalDetailAcademic from './player-academic-detail';
import dayjs from 'dayjs';
import ModalCreateAcademic from './modal-create-academic';
import PlayerAcademicDeleteDialog from './player-academic-delete-dialog';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PlusIcon } from 'app/shared/util/appIcon';
import { ConfirmationDialog } from 'app/shared/util/confirmationDialog';
import { TypeContainer } from './academic-type';
import { RankContainer } from './status-type';
import Filter from 'app/modules/filter';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
export const PlayerAcademic = (props: any) => {
  const { idPlayer } = props;
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [viewDetail, setViewDetail] = useState(null);
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
  };
  const updateSuccess = useAppSelector(state => state.PlayerAcademic.updateSuccess);
  const createSuccess = useAppSelector(state => state.PlayerAcademic.createSuccess);
  const deleteSuccess = useAppSelector(state => state.PlayerAcademic.deleteSuccess);
  const account = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);

  const foundTeamManager = account?.authorities.find(function (element) {
    return element === 'ROLE_TEAM_MANAGER';
  });
  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.playerAcademic.delete.success'));
      dispatch(clearMsgDelete(deleteSuccess));
      // sortEntities();
    }
  }, [deleteSuccess]);
  useEffect(() => {
    if (updateSuccess) {
      toast.success(translate('leagueApp.playerAcademic.updated.success'));
      dispatch(clearMsgUpdate(updateSuccess));
      setIsOpen(false);
      setViewDetail(null);
      // sortEntities();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createSuccess) {
      toast.success(translate('leagueApp.playerAcademic.created.success'));
      dispatch(clearMsgCreate(createSuccess));
      setIsOpen(false);
      setViewDetail(null);
      // sortEntities();
    }
  }, [createSuccess]);
  const param = useParams<{ id: string; params: string }>();
  const handleFetchData = React.useCallback((params: PlayerAcademicFilter) => {
    dispatch(getEntities({ ...params, playerId: +param.id }));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const handleDenied = (user: any) => {
    setModalDelete(true);
    setUserClicked(user);
  };

  const handleDelete = () => {
    dispatch(deleteEntity(userClicked)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const handleViewDetail = id => {
    setViewDetail(id);
    setIsOpen(true);
  };

  const handleOpenModal = () => {
    // setViewDetail(null);
    setIsOpen(true);
  };

  const ActionButton = ({ id }: { id: number }) => {
    return (
      <div className={isAdmin && 'column-action-button'}>
        <Button onClick={() => handleViewDetail(id)} className="rounded">
          <Translate contentKey="entity.action.edit" />
        </Button>
        {isAdmin && (
          <Button onClick={() => handleDenied(id)} danger type="primary" className="rounded">
            <Translate contentKey="entity.action.delete2" />
          </Button>
        )}
      </div>
    );
  };

  const playerAcademicList = useAppSelector(state => state.PlayerAcademic?.entities) || [];
  const loading = useAppSelector(state => state.playerAcademic?.loading) || false;

  const columns = [
    {
      title: <Translate contentKey="leagueApp.playerAcademic.type" />,
      dataIndex: 'type',
      key: 'type',
      render: (value, record) => <TypeContainer type={value} />,
    },
    {
      title: <Translate contentKey="leagueApp.playerAcademic.schoolName" />,
      dataIndex: 'schoolName',
      key: 'schoolName',
      align: 'center' as any,
    },
    {
      title: <Translate contentKey="leagueApp.playerAcademic.issueYear" />,
      dataIndex: 'issueYear',
      key: 'issueYear',
      render: (value, record) => formatYear(value),
      align: 'center' as any,
    },
    {
      title: <Translate contentKey="leagueApp.playerAcademic.graduationYear" />,
      dataIndex: 'graduationYear',
      key: 'graduationYear',
      align: 'center' as any,
      render: (value, record) => formatYear(value),
    },
    {
      title: <Translate contentKey="leagueApp.playerAcademic.rank" />,
      dataIndex: 'rank',
      key: 'rank',
      align: 'center' as any,
      render: (value, record) => <RankContainer rank={value} />,
    },
    {
      title: <Translate contentKey="leagueApp.match.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 230,
      render: (value, record) => <ActionButton id={value} />,
      align: 'center' as any,
    },
  ];

  return (
    <div>
      {isOpen && (
        <ModalCreateAcademic isOpen={isOpen} setIsOpen={b => setIsOpen(b)} setViewDetail={setViewDetail} viewDetail={viewDetail} />
      )}
      <div className="d-flex justify-content-between align-items-center management-layout-header" style={{ paddingTop: 30 }}>
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="MatchHeading" className="heading-20-bold neutral-1">
            <Translate contentKey="leagueApp.playerAcademic.home.title"></Translate>
          </h2>
          {!foundTeamManager && (
            <>
              <div className="management-layout-divider"></div>
              <Button className="rounded management-header-add-button" onClick={handleOpenModal} icon={<PlusIcon />} type="primary">
                <Translate contentKey="leagueApp.playerAcademic.home.createLabel"></Translate>
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={playerAcademicList.content}
          loading={loading}
          filter={filter}
          totalElements={playerAcademicList.totalElements || 0}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('leagueApp.playerAcademic.delete.question')}</h6>}
          onClose={() => setModalDelete(false)}
          onConfirm={() => handleDelete()}
        />
      )}
    </div>
  );
};

export default PlayerAcademic;
