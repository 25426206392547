import { Button, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getPlayers, resetStatus, saveSquad } from 'app/entities/match/match.reducer';
import { IFormSaveSquad, IPlayerDTOS, IPlayerSquad } from 'app/entities/match/match.type';
import TransferWithDnd from 'app/modules/transfer-dnd';
import { IDefaultMatch, IFormPlayerFields } from 'app/shared/model/match.model';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import './squad.scss';

interface IPropsSquad {
  isOpen?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  clubId?: number | string;
  rightData?: any;
  leftData?: any;
  isOwner?: boolean;
  typeEdit?: string;
  type?: number;
  matchId?: number | string;
  onSubmit?: (body: any) => void;
  customLabel?: boolean;
  processTime: any;
  defaultPlayerId?: number;
}

export const ModalSquad = ({
  isOpen,
  setIsOpen,
  clubId,
  rightData,
  isOwner,
  leftData,
  type,
  typeEdit,
  matchId,
  onSubmit,
  customLabel,
  processTime,
  defaultPlayerId,
}: IPropsSquad) => {
  const [dataLeft, setDataLeft] = useState<IPlayerSquad[]>([]);
  const [dataRight, setDataRight] = useState<IPlayerSquad[]>([]);
  const [dataLeftTable, setDataLeftTable] = useState<any>([]);
  const [dataRightTable, setDataRightTable] = useState<any>([]);
  const [valueIn, setValueIn] = useState<IPlayerSquad>();
  const [valueOut, setValueOut] = useState<IPlayerSquad>();
  const [valueBody, setValueBody] = useState([]);
  const saveSuccess = useAppSelector(state => state.match.saveSuccess);
  const matchInfoEntity = useAppSelector(state => state.match.entity);
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const handleConvertData = (data: any) => {
    const arr = [...data];
    const newArr = arr.map((item: any) => {
      if (item?.playerDTOS) {
        return {
          koName: item?.playerDTOS?.koName,
          position: item?.playerDTOS?.position,
          uniformNumber: item?.playerDTOS?.uniformNumber,
          checked: false,
          id: item?.playerDTOS?.id,
        };
      }
      if (item.matchPlayerFieldDTO?.playerDTOS) {
        return {
          koName: item?.matchPlayerFieldDTO?.playerDTOS?.koName,
          position: item?.matchPlayerFieldDTO?.playerDTOS?.position,
          uniformNumber: item?.matchPlayerFieldDTO?.playerDTOS?.uniformNumber,
          checked: false,
          id: item?.matchPlayerFieldDTO?.playerDTOS?.id,
        };
      }
      return {
        ...item,
        checked: false,
      };
    });
    return newArr;
  };

  useEffect(() => {
    if (typeEdit === 'change_player') {
      const arr = handleConvertData(leftData);
      setDataLeftTable(arr);
      const newArr = handleConvertData(rightData);
      setDataRightTable(newArr);
    } else {
      if (leftData && leftData.length) {
        const arr = handleConvertData(leftData);
        setDataLeftTable(arr);
      }
      if (rightData && rightData.length) {
        const newArr = handleConvertData(rightData);
        setDataRightTable(newArr);
      }
    }
  }, [leftData, rightData]);
  useEffect(() => {
    if (saveSuccess) {
      setIsOpen(false);
      dispatch(resetStatus());
      if (isOwner) {
        dispatch(
          getPlayers({
            ownerClubId: clubId,
            id: id || matchId,
          })
        );
      } else {
        dispatch(
          getPlayers({
            awayClubId: clubId,
            id: id || matchId,
          })
        );
      }
    }
  }, [saveSuccess]);
  const handleGetDataRightTable = (value: IPlayerSquad[], otherValue: IPlayerSquad) => {
    setDataRight(value);
    setValueOut(otherValue);
  };

  const handleGetDataLeftTable = (value: IPlayerSquad[], otherValue: IPlayerSquad) => {
    setDataLeft(value);
    setValueIn(otherValue);
  };
  useEffect(() => {
    if (valueIn && valueOut) {
      setValueBody(state => {
        return [
          ...state,
          {
            playerInId: valueIn.id,
            playerOutId: valueOut.id,
            matchId: id,
            clubId,
            processDate: processTime?.process,
            roundType: processTime?.roundType,
          },
        ];
      });
    }
  }, [valueIn, valueOut]);
  const handleOnOk = () => {
    // const mapPositions = dataRight?.map(item => item?.position);
    // const checkGK = mapPositions?.filter(item => item === 'GK');
    if (typeEdit === 'editPlayer') {
      if (dataRight.length < matchInfoEntity?.numberOfPlayers) {
        toast.error(
          translate('leagueApp.match.squad.maximum') + `${matchInfoEntity?.numberOfPlayers}` + translate('leagueApp.match.squad.player')
        );
        return;
      }
      // if (dataRight.length < 11) {
      //   toast.error(translate('leagueApp.match.squad.max11'));
      //   return;
      // }
      // if (checkGK?.length > 1 || checkGK?.length === 0) {
      //   toast.error(translate('leagueApp.matchRecord.errorGKMessage'));
      //   return;
      // }
    }
    // if (typeEdit === 'editSub') {
    //   if (dataRight.length < 5) {
    //     toast.error(translate('leagueApp.match.squad.5player'));
    //     return;
    //   }
    // }
    if (!dataRight.length && typeEdit !== 'change_player') {
      toast.error(translate('leagueApp.match.squad.playerRegister'));
      return;
    }
    if (typeEdit !== 'change_player') {
      const arr = dataRight.map((a, index) => {
        if (typeEdit === 'editPlayer') {
          return {
            playerId: a.id,
            type: 1,
            orderNumber: index + 1,
          };
        }
        if (typeEdit === 'editSub') {
          return {
            playerId: a.id,
            type: 0,
            orderNumber: index + 1,
          };
        }
      });
      const body: IFormSaveSquad = {
        matchId: Number(id) || Number(matchId),
        clubId,
        playerOrderDTOS: arr,
        type,
        processDate: processTime?.process,
        roundType: processTime?.roundType,
      };
      onSubmit(body);
      setLoading(true);
    } else {
      const body = valueBody;
      if (!body.length) {
        toast.error(translate('leagueApp.matchRecord.changePlayerMess'));
        return;
      }
      onSubmit(body);
      setLoading(true);
    }
  };
  return (
    <div className="squad-modal">
      <Modal
        visible={isOpen}
        width={1100}
        wrapClassName="management-wrapper-modal"
        title={
          typeEdit === 'change_player' ? (
            <Translate contentKey="leagueApp.changePlayerMatch.title" />
          ) : typeEdit === 'editPlayer' ? (
            <Translate contentKey="leagueApp.matchRecord.register" />
          ) : (
            <Translate contentKey="leagueApp.matchRecord.registerSub" />
          )
        }
        onCancel={() => setIsOpen(false)}
        footer={null}
      >
        <TransferWithDnd
          dataLeftTable={dataLeftTable}
          dataRightTable={dataRightTable}
          handleGetDataRightTable={handleGetDataRightTable}
          handleGetDataLeftTable={handleGetDataLeftTable}
          type={typeEdit}
          customLabel={customLabel}
          defaultPlayerId={defaultPlayerId}
        />
        <div className="management-modal-footer">
          <Button key="back" onClick={() => setIsOpen(false)}>
            <Translate contentKey="leagueApp.matchRecord.close" />
          </Button>
          <Button loading={loading} key="submit" type="primary" htmlType="submit" onClick={() => handleOnOk()} style={{ marginLeft: 12 }}>
            <Translate contentKey="leagueApp.matchRecord.save" />
          </Button>
        </div>
      </Modal>
    </div>
  );
};
