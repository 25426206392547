import { PlayerGoalRecord } from './../player-goal-record/player-goal-record';
import { toast } from 'react-toastify';
import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError, Pageable } from 'app/shared/reducers/reducer.utils';
import { IMatchRecord, defaultValue } from 'app/shared/model/match-record.model';
import { IPlayer, IPlayerInMatch } from 'app/shared/model/player.model';
import { first, get } from 'lodash';
import { MessageType, PLAYER_IN_MATCH } from 'app/shared/util/constant';
import dayjs from 'dayjs';
import { translate } from 'react-jhipster';
import { IPlayerGoalRecord } from 'app/shared/model/player-goal-record.model';

interface IParam {
  matchId: string;
  awayClubId: number;
  ownerClubId: number;
}

export interface ITimeLine {
  roundType: number;
  timeLineDTO: {
    createDate: string | null;
    playerDTO: IPlayer;
    playerId: number;
    processDate: number;
    roundType: number;
    timeLineId: number;
    timeLineType: string;
  }[];
}

export interface ISheetRecord {
  id: number;
  playerId: number;
  matchId: number;
  clubId: number;
  leagueId: number;
  kick: null | number;
  penaltyKick: null | number;
  shoot: null | number;
  goal: null | number;
  foul: null | number;
  penaltyShoot: null;
  offSide: null | number;
  totalMatch: null | number;
  leagueName: null | number;
  issueYear: null | number;
  koName: null | number;
  enName: null | number;
  inOut: null | number;
  goalType: null | number;
  clubName: null | number;
  ban: null | number;
  goalKick: null | number;
  processDate: 16988;
  supportScore: null | number;
  allScore: null | number;
  beFouled: null | number;
  warning: null | number;
  penaltyGoalType: null | number;
  penaltySuccess: null | number;
  penaltyFail: null | number;
  banType: null | number;
  playerIn: null | number;
  playerOut: null | number;
  players: null | number;
  chased: null | number;
  orderDetail: null | number;
  cornerKick: null | number;
  freeKick: null | number;
  playerFieldMatchType: 1;
  shootOnTarget: null | number;
  playerDTO: IPlayer;
  scoreDto: {
    processDate: number;
    roundType: number;
    player: IPlayer;
    playerSupport: IPlayer | null;
  }[];
}

export interface MatchRecordState extends EntityState<IMatchRecord> {
  startTime: number;
  goalList: IPlayerGoalRecord[];
  sheetRecordHomeTeam: any[];
  sheetRecordAwayTeam: any[];
  avatar: IMatchRecord[];
  pageable?: Pageable<IMatchRecord>;
  awayLineUp: IPlayerInMatch[];
  ownerLineUp: IPlayerInMatch[];
  subAwayLineUp: IPlayerInMatch[];
  subOwnerLineUp: IPlayerInMatch[];
  ownerAvatars: Record<string, string>;
  awayAvatars: Record<string, string>;
  timeline: ITimeLine[];
  connectSuccess: boolean;
}

const initialState: MatchRecordState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  avatar: [],
  awayLineUp: [],
  ownerLineUp: [],
  subAwayLineUp: [],
  subOwnerLineUp: [],
  ownerAvatars: {},
  awayAvatars: {},
  startTime: 0,
  matchRateDetail: [],
  matchStatusDetail: [],
  timeline: [],
  connectSuccess: false,
  goalList: [],
  sheetRecordHomeTeam: [],
  sheetRecordAwayTeam: [],
};

const apiUrl = 'api/match-records';
const API_LOAD_RECORD_TABLE = 'api/report/player-report-after-match';

// Actions

export const getGoalList = createAsyncThunk('matchRecord/fetch_entity_list', async (id: string) => {
  const requestUrl = `/api/player-goal-records/get-list-include-assist?matchId=${id}`;
  return axios.get<IPlayerGoalRecord[]>(requestUrl);
});

export const getEntities = createAsyncThunk('matchRecord/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IMatchRecord[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'matchRecord/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IMatchRecord>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'matchRecord/create_entity',
  async (entity: IMatchRecord, thunkAPI) => {
    const result = await axios.post<IMatchRecord>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'matchRecord/update_entity',
  async (entity: IMatchRecord, thunkAPI) => {
    const result = await axios.put<IMatchRecord>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getPlayerFieldSquad = createAsyncThunk(
  'matchRecord/get',
  async ({ matchId, ownerClubId, awayClubId }: { matchId: number; ownerClubId: number; awayClubId: number }) => {
    const type = [
      `/api/match-player-field-squads/get-before-match?matchId=${matchId}&clubId=${ownerClubId}`,
      `/api/match-player-field-squads/get-before-match?matchId=${matchId}&clubId=${awayClubId}`,
      `/api/match-player-field-squads/get-reserve-player?matchId=${matchId}&clubId=${ownerClubId}`,
      `/api/match-player-field-squads/get-reserve-player?matchId=${matchId}&clubId=${awayClubId}`,
    ];

    const result = await axios.all<IPlayerInMatch[]>(
      type.map(item =>
        axios.post(item).then(res => {
          return res.data || [];
        })
      )
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getMainPlayerFieldSquad = createAsyncThunk(
  'matchRecord/get-main-player',
  async ({ matchId, ownerClubId, awayClubId }: { matchId: number; ownerClubId: number; awayClubId: number }) => {
    const type = [
      `/api/match-player-field-squads/get-before-match?matchId=${matchId}&clubId=${ownerClubId}`,
      `/api/match-player-field-squads/get-before-match?matchId=${matchId}&clubId=${awayClubId}`,
    ];

    const result = await axios.all<IPlayerInMatch[]>(
      type.map(item =>
        axios.post(item).then(res => {
          return res.data || [];
        })
      )
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const saveMatch = createAsyncThunk(
  'matchRecord/save',
  async (data: IPlayerInMatch[]) => {
    if (data && data.length > 0) {
      const url = `/api/match-player-field-squads/save-before-match?matchId=${first(data).matchPlayerFieldDTO.matchId}`;

      const result = await axios.post<IPlayerInMatch[]>(url, data);
      return result;
    }
    return [];
  },
  { serializeError: serializeAxiosError }
);

export const getPlayerAvatar = createAsyncThunk(
  'matchRecord/getAvatar',
  async ({ matchId, ownerClubId, awayClubId }: { matchId: number; ownerClubId: number; awayClubId: number }) => {
    const type = [
      `/api/match-player-field-squads/get-avatar-player?matchId=${matchId}&clubId=${ownerClubId}`,
      `/api/match-player-field-squads/get-avatar-player?matchId=${matchId}&clubId=${awayClubId}`,
    ];

    const result = await axios.all<Record<string, string>>(
      type.map(item =>
        axios.post(item).then(res => {
          return res.data;
        })
      )
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getListAvatar = createAsyncThunk(
  'matchRecord/update_entity',
  async (entity: IMatchRecord, thunkAPI) => {
    const result = await axios.post<Pageable<IMatchRecord>>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'matchRecord/partial_update_entity',
  async (entity: IMatchRecord, thunkAPI) => {
    const result = await axios.patch<IMatchRecord>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'matchRecord/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IMatchRecord>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const resetLineUp = createAsyncThunk(
  'matchRecord/reset',
  async ({ matchId, ownerClubId, awayClubId }: { matchId: number; ownerClubId: number; awayClubId: number }) => {
    const type = [
      `/api/match-player-field-squads/reset?matchId=${matchId}&clubId=${ownerClubId}`,
      `/api/match-player-field-squads/reset?matchId=${matchId}&clubId=${awayClubId}`,
    ];

    const result = await axios.all<IPlayerInMatch[]>(
      type.map(item =>
        axios.post(item).then(res => {
          return res.data || [];
        })
      )
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

const getDefaultLineUp = (lineup: IPlayerInMatch[]) => {
  const goalKeepers = lineup.filter((item: IPlayerInMatch) => item.positionType === 1);
  if (goalKeepers.length === 0) {
    return lineup.map((item, index) => (index === 0 ? { ...item, positionType: 1 } : item));
  }
  if (goalKeepers.length > 1) {
    return lineup.map((item, index) => (item.positionOrder > 1 && item.positionType === 1 ? { ...item, positionType: 2 } : item));
  }
  return lineup;
};

// get detail match rate
export const getDetailMatchRate = createAsyncThunk('matchRecord/getDetailMatchRate', async (id: string | number) => {
  const result = axios.get(`/api/match-rates/get-detail/${id}`);
  return result;
});

// create match rate

export const createMatchRate = createAsyncThunk('matchRecord/createMatchRate', async (body: any) => {
  const result = await axios.post('/api/match-rates/create-list', body);
  toast.success(translate('leagueApp.match.createSuccess'));
  return result;
});

// update match

export const updateMatchRate = createAsyncThunk('matchRecord/updateMatch', async (body: any) => {
  const result = await axios.put(`/api/match-rates/update-list/${body[0].matchId}`, body);
  toast.success(translate('leagueApp.match.updateSuccess'));
  return result;
});

// approval match
export const approvalMatch = createAsyncThunk('matchRecord/approvalmatch', async (id: any) => {
  const result = await axios.get(`/api/record/match-time/request-approval-match/${id}`);
  toast.success(translate('leagueApp.matchStatus.approvalSuccess'));
  return result.data;
});

// get detail match status

export const getDetailMatchStatus = createAsyncThunk('matchRecord/getDetailMatchStatus', async (id: string) => {
  const result = await axios.get(`/api/match-times/detail-match/${id}`);
  const newData = {
    ...result.data,
    startTime1: result.data.startTime1 ? dayjs(result.data.startTime1).format('HH:mm:ss') : undefined,
    endTime1: result.data.endTime1 ? dayjs(result.data.endTime1).format('HH:mm:ss') : undefined,
    startTime2: result.data.startTime2 ? dayjs(result.data.startTime2).format('HH:mm:ss') : undefined,
    endTime2: result.data.endTime2 ? dayjs(result.data.endTime2).format('HH:mm:ss') : undefined,
    startSubTime1: result.data.startSubTime1 ? dayjs(result.data.startSubTime1).format('HH:mm:ss') : undefined,
    endSubTime1: result.data.endSubTime1 ? dayjs(result.data.endSubTime1).format('HH:mm:ss') : undefined,
    startSubTime2: result.data.startSubTime2 ? dayjs(result.data.startSubTime2).format('HH:mm:ss') : undefined,
    endSubTime2: result.data.endSubTime2 ? dayjs(result.data.endSubTime2).format('HH:mm:ss') : undefined,
  };
  return newData;
});
// create change status match
export const changeStatusMatch = createAsyncThunk('matchRecord/changeStatusMatch', async (body: any) => {
  const result = await axios.post('/api/match-times', body);
  toast.success(translate('leagueApp.match.createSuccess'));
  return result;
});

// update status match

export const updateStatusMatch = createAsyncThunk('matchRecord/updateStatusMatch', async (body: any) => {
  const result = await axios.put(`/api/match-times/${body.id}`, body);
  toast.success(translate('leagueApp.match.updateSuccess'));
  return result;
});

export const getDetailGoalKickRecord = createAsyncThunk('playerGoalKick/getDetailGoalKickRecord', async (id: number) => {
  return axios.get(`api/player-goal-kick-records/${id}`);
});

export const getDetailOffsideRecord = createAsyncThunk('playerGoalKick/getDetailGoalKickRecord', async (id: number) => {
  return axios.get(`api/player-offsides/${id}`);
});

export const getTimeLine = createAsyncThunk('timeline', async (id: number) => {
  return axios.get<ITimeLine[]>(`/api/match-records/time-line?matchID=${id}`);
});

export const getTime = createAsyncThunk('getTime', async (id: string) => {
  return axios.get(`api/match-times/get-time/${id}`);
});

export const uploadGoalSituation = createAsyncThunk('update-goal-situation', async (data: IPlayerGoalRecord[]) => {
  return axios.put(`/api/player-goal-records`, data);
});

// change player
interface IFormBodyChangePlayer {
  playerInId: number;
  playerOutId: number;
  matchId: number | string;
  clubId: number;
}
export const changePlayer = createAsyncThunk('matchRecord/changePlayer', async (body: IFormBodyChangePlayer[]) => {
  const result = await axios.post('/api/player-in-outs/change-player-in-match', body);
  toast.success(translate('leagueApp.changePlayerMatch.changeSuccess'));
  return result;
});

// change start match
interface IFormBodyChangeStartMatch {
  matchId: string | number;
  direction: string | null;
}
export const changeStartMatch = createAsyncThunk('matchRecord/changeStartMatch', async (body: IFormBodyChangeStartMatch) => {
  const result = await axios.post(`/api/record/match-record/list-match/${body.matchId}/${body.direction}`, body);
  return result;
});

export const fetchSheetRecord = createAsyncThunk(
  'match-record/get_list_record_sheet',
  async ({ matchId, awayClubId, ownerClubId }: IParam) => {
    const listUrl = [
      `${API_LOAD_RECORD_TABLE}?matchId=${matchId}&clubId=${awayClubId}`,
      `${API_LOAD_RECORD_TABLE}?matchId=${matchId}&clubId=${ownerClubId}`,
    ];
    const results = await axios.all<ISheetRecord[]>(listUrl.map(item => axios.get(item).then(res => res.data || [])));
    return results;
  }
);

export const MatchRecordSlice = createSlice({
  name: 'matchRecord',
  initialState,
  reducers: {
    resetSave(state) {
      state.saveSuccess = false;
    },

    updateRecord(state, action: PayloadAction<IPlayerInMatch[]>) {
      if (action.payload?.length === 0) {
        return;
      }
      if (
        first(state.awayLineUp) &&
        first(state.awayLineUp).matchPlayerFieldDTO.clubId === first(action.payload).matchPlayerFieldDTO.clubId
      ) {
        if (first(action.payload).matchPlayerFieldDTO.type === PLAYER_IN_MATCH.MAIN) {
          // dung map la de reset doi hinh khi user khong save
          state.awayLineUp = action.payload.map(item => item);
        } else {
          state.subAwayLineUp = action.payload;
        }
      } else {
        if (first(action.payload).matchPlayerFieldDTO.type === PLAYER_IN_MATCH.MAIN) {
          state.ownerLineUp = action.payload.map(item => item);
        } else {
          state.subOwnerLineUp = action.payload;
        }
      }
    },
    updateTimeLine(state, action) {
      state.timeline = action.payload;
    },
    notification(state, action: PayloadAction<{ type: string; message: string }>) {
      if (action.payload.type === MessageType.ERROR) {
        toast.error(translate(`leagueApp.${action.payload.message}`));
      }
      if (action.payload.type === MessageType.WARNING) {
        toast.warning(action.payload.message);
      }
      if (action.payload.type === MessageType.SUCCESS) {
        toast.success(translate('common.saveSuccess'));
      }
      if (action.payload.type === MessageType.TYPE_CARD) {
        toast.error(translate(`leagueApp.${action.payload.message}`));
      }
    },
    updateWsStatus(state, action: PayloadAction<boolean>) {
      state.connectSuccess = action.payload;
    },

    updateSheetRecord(state, action: PayloadAction<ISheetRecord[]>) {
      if (action.payload?.length === 0) {
        return;
      }
      if (first(state.awayLineUp) && first(state.awayLineUp).matchPlayerFieldDTO.clubId === first(action.payload).clubId) {
        state.sheetRecordAwayTeam = action.payload;
        return;
      }
      if (first(state.ownerLineUp) && first(state.ownerLineUp).matchPlayerFieldDTO.clubId === first(action.payload).clubId) {
        state.sheetRecordHomeTeam = action.payload;
        return;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSheetRecord.fulfilled, (state, action: PayloadAction<ISheetRecord[][]>) => {
        state.sheetRecordAwayTeam = action.payload[0];
        state.sheetRecordHomeTeam = action.payload[1];
      })
      .addCase(fetchSheetRecord.pending, state => {
        state.sheetRecordAwayTeam = [];
        state.sheetRecordHomeTeam = [];
      })
      .addCase(createMatchRate.fulfilled, state => {
        state.saveSuccess = true;
      })
      .addCase(getDetailMatchRate.fulfilled, (state, action) => {
        state.matchRateDetail = action.payload.data;
      })
      .addCase(updateMatchRate.fulfilled, state => {
        state.updateSuccess = true;
        state.saveSuccess = true;
      })
      .addCase(getDetailMatchStatus.fulfilled, (state, action) => {
        state.matchStatusDetail = action.payload;
      })
      .addMatcher(isFulfilled(getEntity), (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })

      .addMatcher(isFulfilled(getPlayerAvatar), (state, action) => {
        state.awayAvatars = get(action.payload, '1');
        state.ownerAvatars = get(action.payload, '0');
      })
      .addMatcher(isPending(getPlayerFieldSquad), (state, action) => {
        state.ownerLineUp = [];
        state.awayLineUp = [];
        state.subOwnerLineUp = [];
        state.subAwayLineUp = [];
      })
      .addMatcher(isPending(getMainPlayerFieldSquad), (state, action) => {
        state.ownerLineUp = [];
        state.awayLineUp = [];
      })
      .addMatcher(isFulfilled(getPlayerFieldSquad), (state, action) => {
        state.ownerLineUp = get(action.payload, '0');
        state.awayLineUp = get(action.payload, '1');
        state.subOwnerLineUp = get(action.payload, '2');
        state.subAwayLineUp = get(action.payload, '3');
      })
      .addMatcher(isFulfilled(getMainPlayerFieldSquad), (state, action) => {
        state.ownerLineUp = get(action.payload, '0');
        state.awayLineUp = get(action.payload, '1');
      })
      .addMatcher(isFulfilled(resetLineUp), (state, action) => {
        state.ownerLineUp = get(action.payload, '0');
        state.awayLineUp = get(action.payload, '1');
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isFulfilled(getListAvatar), (state, action) => {
        state.pageable = action.payload.data;
      })
      .addMatcher(isFulfilled(getTimeLine), (state, action) => {
        state.timeline = action.payload.data;
      })
      .addMatcher(isPending(getTimeLine), (state, action) => {
        state.timeline = [];
      })
      .addMatcher(isFulfilled(getGoalList), (state, action) => {
        state.goalList = action.payload?.data.map(item => ({ ...item, key: item.id })) || [];
      })
      .addMatcher(isFulfilled(uploadGoalSituation), (state, action) => {
        toast.success(translate('leagueApp.matchRecord.updateGoalSituationSuccess'));
      })
      .addMatcher(isRejected(uploadGoalSituation), (state, action) => {
        toast.error(action.error.message);
      });
  },
});

export const { resetSave } = MatchRecordSlice.actions;

// Reducer
export default MatchRecordSlice.reducer;
