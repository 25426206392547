import React, { useState, useEffect } from 'react';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount, translate } from 'react-jhipster';

import { Card, Col, Form, Modal, Radio, Row, Space, Button } from 'antd';
import ListItem from 'app/modules/pick-player/components/list-item';
import { EditOutlined } from '@ant-design/icons';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import MiniMapPosition from 'app/modules/mini-map-position';
import {
  fetchListShootDirectionKick,
  fetchDetailEventRecord,
  fetchListTypeFailKick,
  fetchListTypeShootKick,
  fetchCatePenaltyKick,
  fetchListScoreMethods,
} from 'app/entities/player-free-corner-kick-record/player-free-corner-kick.reducer';
import { sendRecord } from 'app/config/websocket-middleware';
import { PickItem } from 'app/modules/pick-item';
import { get } from 'lodash';
import { urlWs } from 'app/modules/event-list-record/constant.event-list';
import { actionRecord, SHOOT_RESULT, SHOOT_TYPE } from 'app/shared/util/constant';
import RecordPlayerInfo from 'app/modules/record-player-info';
import { toast } from 'react-toastify';

interface Props {
  open: boolean;
  onCancel: () => void;
  onEditPosition: () => void;
  onEditPlayer: () => void;
  form?: any;
  playerAvatar: string;
  id?: number;
  awayAvatars: any;
  ownerAvatars: any;
  processTime: any;
}

export const PlayerShootingKickRecord = ({
  onCancel,
  open,
  onEditPlayer,
  onEditPosition,
  form,
  id,
  awayAvatars,
  ownerAvatars,
  processTime,
}: Props) => {
  const playerInForm = Form.useWatch('player', form);
  const dispatch = useAppDispatch();

  const locale = useAppSelector(state => state.locale.currentLocale);
  const listShootDirectionKick = useAppSelector(state => state.PlayerFreeCornerKick.listShootDirectionKick);
  const listTypeShootKick = useAppSelector(state => state.PlayerFreeCornerKick.listTypeShootKick);
  const listTypeFailKick = useAppSelector(state => state.PlayerFreeCornerKick.listTypeFailKick);
  const listScoreMethod = useAppSelector(state => state.PlayerFreeCornerKick.listScoreMethod);
  const watchShootType = Form.useWatch('shootType');
  const watchGoalForm = Form.useWatch('goalForm');
  const watchGoalType = Form.useWatch('goalType');
  const [dataDefault, setDataDefault] = useState(null);
  const shootType = [
    { value: SHOOT_TYPE.ON_TARGET, label: translate('freeCornerKick.directShootType.shootTarget') },
    { value: SHOOT_TYPE.NORMAL, label: translate('freeCornerKick.directShootType.normalShoot') },
  ];

  const score = [
    { value: SHOOT_RESULT.SUCCESS, label: translate('freeCornerKick.score.success') },
    { value: SHOOT_RESULT.FAIL, label: translate('freeCornerKick.score.fail') },
  ];

  const rangeList = [
    // distance
    { value: 1, label: translate('scoreRecord.range.distance') },
    { value: 2, label: translate('scoreRecord.range.near') },
  ];

  useEffect(() => {
    if (id) {
      dispatch(fetchDetailEventRecord(`api/player-shoot-records/${id}`)).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          const data = get(res.payload, 'data');
          setDataDefault(data);
          form.setFieldsValue({
            ...data,
            goalForm: data?.playerGoalRecordDTO?.goalForm,
            shootDirectionKick: data?.playerGoalRecordDTO?.shootDirection,
            distance: data?.playerGoalRecordDTO?.distance,
          });
        }
      });
    }
  }, []);

  const onSubmitForm = data => {
    if (!data.detailLocation) {
      toast.error(translate('scoreRecord.message.supportForm'));
      return;
    }
    if (id) {
      sendRecord(urlWs(actionRecord.SHOOTING, 'edit', data.player?.matchPlayerFieldDTO.matchId, id), {
        ...dataDefault,
        ...data,
        id,
        playerId: data.player?.playerId,
        player: undefined,
        matchId: data.player?.matchPlayerFieldDTO.matchId,

        playerGoalRecordDTO:
          data?.goalType === 1
            ? {
                ...dataDefault.playerGoalRecordDTO,
                shootDirection: data?.shootDirectionKick,
                goalForm: data?.goalForm,
                distance: data?.distance,
              }
            : {},
        shootType: data?.shootType,
        goalType: data?.goalType,
      });
    } else {
      sendRecord(
        `/record/player-shoot/create/${data.player?.matchPlayerFieldDTO.matchId}/${data.player?.matchPlayerFieldDTO.clubId}/${data.player?.matchPlayerFieldDTO.type}`,
        {
          ...data,
          playerId: data.player.playerId,
          matchId: data.player.matchPlayerFieldDTO.matchId,
          player: undefined,
          detailLocation: data.detailLocation,
          processDate: processTime?.process,
          roundType: processTime?.roundType,
          reasonFail: data?.reasonFail,
          playerGoalRecordDTO:
            data?.goalType === 1
              ? {
                  shootDirection: data?.shootDirectionKick,
                  goalForm: data?.goalForm,
                  distance: data?.distance,
                }
              : {},
          shootType: data?.shootType,
          goalType: data?.goalType,
        }
      );
    }
    form.resetFields();
    toast.success(translate('common.saveSuccess'));
    onCancel();
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchDetailEventRecord(`api/player-shoot-records/${id}`)).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          const data = get(res.payload, 'data');
          setDataDefault(data);
          form.setFieldsValue({ ...data });
        }
      });
    }
    dispatch(fetchListTypeFailKick(locale));
    dispatch(fetchListTypeShootKick(locale));
    dispatch(fetchCatePenaltyKick(locale));
    dispatch(fetchListScoreMethods(locale));
  }, []);

  const requiredRule = { required: true, message: '' };

  return (
    <Modal
      title={!id ? translate('freeCornerKick.registerShooting') : translate('freeCornerKick.editShooting')}
      className="register-record"
      visible={open}
      width={locale === 'ko' ? 820 : 1200}
      onCancel={onCancel}
      onOk={() => form.submit()}
      zIndex={1}
      footer={[
        <Button key="close" onClick={() => onCancel()}>
          <span>{translate('freeCornerKick.close')}</span>
        </Button>,
        <Button key="save" type="primary" onClick={() => form.submit()}>
          <span>{translate('freeCornerKick.save')}</span>
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onSubmitForm}
        onFinishFailed={() => {
          toast.error(translate('scoreRecord.message.supportForm'));
        }}
        style={{ width: locale === 'ko' ? 756 : 949, overflow: 'auto' }}
      >
        <Row gutter={32} justify="start" style={{ width: locale === 'ko' ? 772 : 965 }} wrap={false}>
          <Col>
            <RecordPlayerInfo
              avatar={
                // playerInForm?.matchPlayerFieldDTO?.playerDTOS?.avatar
                get(awayAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  ? get(awayAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  : get(ownerAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  ? get(ownerAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  : undefined
              }
              prefix={playerInForm?.positionType}
              onEditPlayer={() => {
                onEditPlayer();
              }}
              onEditPosition={() => {
                onEditPosition();
              }}
              player={playerInForm}
              positionMap={form.getFieldValue('detailLocation')}
            />
          </Col>
          <Row wrap={false}>
            <Col className="first-header">
              <PickItem
                name="shootType"
                list={shootType}
                getValue={item => item.value}
                getLabel={item => item.label}
                title={'* ' + translate('freeCornerKick.directShootType.shootType')}
                rules={[requiredRule]}
              />
            </Col>
            <Col style={Number(watchShootType) === 1 ? {} : { display: 'none' }} className="last-header">
              <PickItem
                name="goalType"
                list={score}
                getValue={item => item.value}
                getLabel={item => item.label}
                title={'* ' + translate('freeCornerKick.score.title')}
                rules={Number(watchShootType) === 1 && [requiredRule]}
              />
            </Col>
            <Col style={Number(watchShootType) === 1 && Number(watchGoalType) === 1 ? {} : { display: 'none' }} className="last-header">
              <PickItem
                name="goalForm"
                list={listScoreMethod}
                getValue={item => item.value}
                getLabel={item => item.label}
                title={translate('freeCornerKick.goalscorerForm')}
                // rules={Number(watchShootType) === 1 && Number(watchGoalType) === 1 && [requiredRule]}
                // secondColumn={true}
              />
            </Col>
            <Col style={Number(watchShootType) === 1 && Number(watchGoalType) === 1 ? {} : { display: 'none' }} className="last-header">
              <PickItem
                name="shootDirectionKick"
                list={listShootDirectionKick}
                getValue={item => item.value}
                getLabel={item => item.label}
                title={translate('freeCornerKick.shot')}
                // rules={Number(watchShootType) === 1 && Number(watchGoalType) === 1 && [requiredRule]}
              />
            </Col>
            <Col style={Number(watchShootType) === 1 && Number(watchGoalType) === 1 ? {} : { display: 'none' }} className="last-header">
              <PickItem
                name="distance"
                list={rangeList}
                getValue={item => item.value}
                getLabel={item => item.label}
                title={translate('scoreRecord.range.title')}
                // rules={Number(watchShootType) === 1 && Number(watchGoalType) === 1 && [requiredRule]}
              />
            </Col>
            <Col style={Number(watchShootType) === 1 && Number(watchGoalType) === 0 ? {} : { display: 'none' }} className="last-header">
              <PickItem
                name="reasonFail"
                list={listTypeFailKick}
                getValue={item => item.value}
                getLabel={item => item.label}
                title={translate('freeCornerKick.failureReason')}
                // rules={Number(watchShootType) === 1 && Number(watchGoalType) === 0 && [requiredRule]}
                // secondColumn={true}
              />
            </Col>
          </Row>
        </Row>
      </Form>
    </Modal>
  );
};

export default PlayerShootingKickRecord;
