import React, { useEffect, useState } from 'react';
import { Button, Card, Col, ConfigProvider, Empty, Form, Row, Select, Tabs } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import TextField from 'app/modules/lm-input';
import NumberField from 'app/modules/lm-input-number';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { handleValidateMessageLanguageChanged } from 'app/shared/util/validate';
import axios from 'axios';
import { BsCaretLeft } from 'react-icons/bs';
import { translate, Translate } from 'react-jhipster';
import { Redirect, Switch, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearMsgUpdate, getUser, resetPass, updateUser } from './user-management.reducer';
import ManagerAllowedIp from './user-manager-allowed-ip';
import ManagerHistory from './user-manager-history';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';

export const UserManagementDetailDialog = ({ match }) => {
  const param = useParams<{ id: string; params: string }>();

  const pathLink = {
    history: 'history',
    allowIp: 'allow-ip',
  };

  const container = [
    {
      label: <Translate contentKey="userManagement.detail.history.title" />,
      path: match.path.replace(':id', param.id).replace(':params', pathLink.history),
      param: pathLink.history,
    },
    {
      label: <Translate contentKey="userManagement.detail.allowIP.title" />,
      path: match.path.replace(':id', param.id).replace(':params', pathLink.allowIp),
      param: pathLink.allowIp,
    },
  ];

  const dispatch = useAppDispatch();
  const history = useHistory();
  const [disable, setDisable] = useState(false);

  const userEntity = useAppSelector(state => state.userManagement.user);
  const [form] = Form.useForm();
  const [dataRole, useDataRole] = useState([]);
  const [dataUnit, setDataUnit] = useState([]);
  const updateSuccess = useAppSelector(state => state.userManagement?.updateSuccess);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const authorities = useAppSelector(state => state.authentication.account);
  const [resetPassModal, setResetPassModal] = useState(false);
  // const isAdmin = hasAnyAuthority(authorities, [AUTHORITIES.ADMIN]);

  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
  }, [locale]);
  const found = userEntity?.authorities.find(function (element) {
    return element === 'ROLE_ADMIN';
  });

  const active = [
    {
      value: true,
      label: translate('userManagement.active'),
    },
    { value: false, label: translate('userManagement.deactivate') },
  ];

  useEffect(() => {
    if (updateSuccess) {
      toast.success(translate('userManagement.updated'));
      dispatch(clearMsgUpdate(updateSuccess));
      dispatch(getUser(match.params.id));
      // dispatch(reset());
    }
  }, [updateSuccess]);
  const [entitySave, setEntitySave] = useState({
    fullName: '',
    phoneNumber: '',
    login: '',
  });

  useEffect(() => {
    if (userEntity && userEntity.id) {
      form.setFieldsValue({
        ...userEntity,
        unitStandardName: userEntity?.unitStandardDTO?.name,
        unitStandardId: userEntity?.unitStandardDTO?.id,
        authorities: userEntity?.authorities[0],
      });
      setEntitySave({ ...userEntity });
    }
  }, [userEntity]);

  React.useEffect(() => {
    if (!match.params.id) {
      return;
    }
    dispatch(getUser(match.params.id));
  }, []);

  useEffect(() => {
    // handleRole(),
    setTimeout(() => {
      useDataRole([
        { value: 'ROLE_LEAGUE_MASTER', label: translate('author.leaguemaster') },
        { value: 'ROLE_TEAM_MANAGER', label: translate('author.teammanager') },
        { value: 'ROLE_RECORDER', label: translate('author.recorder') },
      ]);
    });
    handleUnit();
  }, []);

  useEffect(() => {
    if (found) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  });

  const handleUnit = async () => {
    const dataUnits = await axios.get<any>(`api/unit-standards/search`);
    const arrUnit = [];
    dataUnits.data.content.map(a =>
      arrUnit.push({
        value: a.id,
        label: a.name,
      })
    );
    setDataUnit(arrUnit);
  };

  function saveData(data: any, e) {
    if (userEntity.id) {
      data = {
        ...userEntity,
      };
    }
    data.fullName = form.getFieldValue('fullName');
    data.authorities = [form.getFieldValue('authorities')];
    data.unitStandardId = form.getFieldValue('unitStandardId');
    data.login = form.getFieldValue('login');
    data.phoneNumber = form.getFieldValue('phoneNumber');
    data.activated = form.getFieldValue('activated');
    if (data.id) {
      dispatch(updateUser(data));
    }
  }

  const handleValidSubmit = e => {
    /** save image **/
    const data: any = {};
    saveData(data, e);
  };

  const handleBack = e => {
    history.goBack();
  };

  const onChange = (path: string) => {
    history.push(path);
  };

  return (
    <div>
      <>
        {resetPassModal && (
          <ConfirmationDialog
            message={<h6>{translate('userManagement.resetPassConf')}</h6>}
            open={resetPassModal}
            onConfirm={() => setResetPassModal(false)}
          />
        )}
        <Card className="mb-3">
          <Form form={form} name="control-hooks" onFinish={handleValidSubmit} layout="vertical" requiredMark={'optional'}>
            <div className="border-bottom d-flex justify-content-between align-items-center mb-2" style={{ paddingBottom: 30 }}>
              <div className="d-flex align-items-center auto-wrap">
                <Button className="rounded management-header-add-button" icon={<BsCaretLeft />} onClick={handleBack} type="primary">
                  <Translate contentKey="entity.action.back" />
                </Button>
                <div className="management-layout-divider"></div>
                <p id="player-heading" data-cy="PlayerHeading" className="h4 mb-0">
                  {userEntity?.fullName}
                </p>
              </div>
              <div>
                {!found && (
                  <Button
                    onClick={() => {
                      dispatch(resetPass(userEntity.id))
                        .then(res => {
                          setResetPassModal(true);
                        })
                        .catch(err => {
                          toast.error(translate('userManagement.resetPassFail'));
                        });
                    }}
                    style={{ marginRight: 10 }}
                  >
                    {translate('account.button.reset_pass')}
                  </Button>
                )}
                <Button htmlType="submit" type="primary">
                  <Translate contentKey="common.save" />
                </Button>
              </div>
            </div>
            <Row gutter={[12, 12]} className="mb-5">
              <Col xl={6} lg={12} xs={24}>
                <Form.Item
                  label={<h6>{translate('global.field.name')}</h6>}
                  name="fullName"
                  rules={[{ required: true, message: translate('userManagement.required.name') }]}
                >
                  <TextField placeholder={translate('global.form.username.placeholder')}></TextField>
                </Form.Item>
                <Form.Item
                  label={<h6>{translate('userManagement.role')}</h6>}
                  name="authorities"
                  rules={[{ required: true, message: translate('userManagement.required.role') }]}
                >
                    <Select
                      placeholder={translate('global.form.role.placeholder')}
                      className="custom-prefix-select"
                      allowClear
                      suffixIcon={<SelectArrowDown />}
                      disabled={disable}
                    >
                      {dataRole.map(roles => (
                        <Select.Option value={roles.value} key={roles.value}>
                          {roles.label}
                        </Select.Option>
                      ))}
                    </Select>
                </Form.Item>
              </Col>
              <Col xl={6} lg={12} xs={24}>
                <Form.Item
                  label={<h6>{translate('userManagement.unitDetail')}</h6>}
                  name="unitStandardId"
                  rules={[{ required: true, message: translate('userManagement.required.unit') }]}
                >
                    <Select
                      placeholder={translate('register.unit.placeholder')}
                      className="custom-prefix-select"
                      allowClear
                      showSearch
                      filterOption={(input, option) => (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                      suffixIcon={<SelectArrowDown />}
                    >
                      {dataUnit.map(roles => (
                        <Select.Option value={roles.value} key={roles.value}>
                          {roles.label}
                        </Select.Option>
                      ))}
                    </Select>
                </Form.Item>
                <Form.Item
                  label={<h6>{translate('userManagement.status')}</h6>}
                  name="activated"
                  rules={[{ required: true, message: translate('userManagement.required.status') }]}
                >
                    <Select
                      disabled={userEntity?.authorities[0] === 'ROLE_ADMIN' ? true : false}
                      placeholder={translate('userManagement.status.placeholder')}
                      className="custom-prefix-select"
                      allowClear
                      showSearch={false}
                      suffixIcon={<SelectArrowDown />}
                    >
                      {active.map((item, index) => (
                        <Select.Option value={item.value} key={index}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                </Form.Item>
              </Col>
              <Col xl={6} lg={12} xs={24}>
                <Form.Item
                  rules={[
                    {
                      type: 'email',
                      message: translate('userManagement.required.emailInvalid'),
                    },
                    { required: true, message: translate('userManagement.required.email') },
                  ]}
                  label={<h6>{translate('userManagement.email')}</h6>}
                  name="login"
                >
                  <TextField placeholder={translate('global.form.email.label')}></TextField>
                </Form.Item>
              </Col>
              <Col xl={6} lg={12} xs={24}>
                <Form.Item
                  label={<h6>{translate('userManagement.phoneNumber')}</h6>}
                  name="phoneNumber"
                  rules={[{ required: true, message: translate('userManagement.required.phoneNumber') }]}
                >
                  <NumberField negative={true} placeholder={translate('userManagement.placeholder.phoneNumber')} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>

        <Tabs onChange={onChange} type="card" activeKey={param.params}>
          {container.map((item, index) => (
            <Tabs.TabPane tab={item.label} key={item.param} />
          ))}
        </Tabs>
        <Card>
          <Switch>
            <ErrorBoundaryRoute exact path={match.path.replace(':params', pathLink.history)} component={ManagerHistory} />
            <ErrorBoundaryRoute exact path={match.path.replace(':params', pathLink.allowIp)} component={ManagerAllowedIp} />
            <Redirect to={match.path.replace(':id', param.id).replace(':params', pathLink.history)} />
          </Switch>
        </Card>
      </>
    </div>
  );
};

export default UserManagementDetailDialog;
