import { Avatar, Card, Col, Row, Tag, Tooltip } from 'antd';
import { IPlayerInMatch } from 'app/shared/model/player.model';
import React from 'react';

import { Draggable, DraggableChildrenFn } from 'react-beautiful-dnd';

import styled from 'styled-components';

const CardHeader = styled.div`
  font-family: var(--primary-font-family-medium);
  font-weight: 500;
`;

const Author = styled.div`
  display: flex;
  align-items: center;
`;
const CardFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const matchTime = (time: number) => `${Math.floor(time / 60)}'${time % 60}"`;

const getHalfTime = roundType => {
  switch (roundType) {
    case 2:
      return '2nd';
    case 3:
      return 'Et 1';
    case 4:
      return 'Et 2';
    case 1:
      return '1st';
    default:
      return '1st';
  }
};

const labelGoal = (items: IPlayerInMatch['playerGoalInMatchDTOS'], timeOfRound = 45, timeOfRoundExtra = 15) => {
  const result = items.map((item, index) => {
    const extraSecond = item.timeProcess - timeOfRound * 60;
    const extraSubTimeSecond = item.timeProcess - timeOfRoundExtra * 60;
    return getHalfTime(item.roundType) + ' ' + matchTime(item.timeProcess);
    if (item.roundType < 3) {
      return extraSecond >= 0
        ? `${timeOfRound * item.roundType}'00" +` + matchTime(extraSecond)
        : matchTime(item.timeProcess + timeOfRound * 60 * (item.roundType - 1));
    }
    return extraSubTimeSecond >= 0
      ? `${2 * timeOfRound + (item.roundType - 2) * timeOfRoundExtra}'00" +` + matchTime(extraSubTimeSecond)
      : matchTime(item.timeProcess + 2 * timeOfRound * 60 + (item.roundType - 3) * 60 * timeOfRoundExtra);
  });
  return result.join(', ');
};

interface DragItemProps extends DraggableChildrenFn {
  banType: number;
}

const DragItem = styled.div<DragItemProps>`
  position: relative;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: white;
  margin: 0 0 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 20px;
  align-items: 'space-between';
  flex-direction: column;
  cursor: ${props => (props.banType > 1 || props.notAllow ? 'not-allowed' : 'pointer')};
  .banCard {
    visibility: ${props => (props.banType ? 'visible' : 'hidden')};
    position: absolute;
    top: -15px;
    right: 5%;
    height: 24px;
    background-color: ${props => (props.banType > 2 ? `red` : `yellow`)};
    border: 1px solid black;
    width: 16px;
    box-shadow: ${props => props.banType > 1 && props.banType < 4 && `yellow -5px -8px, black -5px -8px 0px 0.5px`};
  }
  .goal {
    top: -15px;
    left: 5%;
    position: absolute;
  }
  .text-right {
    text-align: right;
  }
  p {
    font-family: var(--primary-font-family-medium);
    font-weight: 600;
  }
`;

export const getColor = (position: string) => {
  switch (position) {
    case '1':
    case 'TM':
      return 'yellow';
    case '2':
    case 'HV':
      return 'blue';
    case '3':
    case 'TV':
      return 'green';
    case '4':
    case 'TD':
      return 'red';
    default:
      return 'blue';
  }
};

const getPosition = (position: number) => {
  switch (position) {
    case 1:
      return 'TM';
    case 2:
      return 'HV';
    case 3:
      return 'TV';
    case 4:
      return 'TD';
    default:
      return 'HV';
  }
};

const ListItem = ({
  item,
  index,
  prefix,
  onClickPlayer,
  avatar,
  timeOfRound,
  timeOfRoundExtra,
  notAllow,
}: {
  item: IPlayerInMatch;
  index: number;
  prefix: number;
  avatar: string;
  onClickPlayer?: (item: IPlayerInMatch) => void;
  timeOfRoundExtra?: number;
  timeOfRound?: number;
  notAllow?: boolean;
}) => {
  return (
    <div
      onClick={() => {
        !(item.banCard > 1) &&
          !notAllow &&
          onClickPlayer({
            ...item,
            matchPlayerFieldDTO: { ...item.matchPlayerFieldDTO, playerDTOS: { ...item.matchPlayerFieldDTO.playerDTOS, avatar } },
          });
      }}
    >
      <DragItem banType={item.banCard} notAllow={notAllow} className="player-item">
        {item.playerGoalInMatchDTOS?.length > 0 && (
          <div className="goal">
            {/* <Tooltip title={labelGoal(item.playerGoalInMatchDTOS, timeOfRound, timeOfRoundExtra)} color={'#0062ae'} key={'#0062ae'}> */}
            <img src="content/images/goal.svg" />
            {/* </Tooltip> */}
          </div>
        )}
        <div className="banCard" />
        <Row justify="space-between">
          <Col xs={24} className="d-flex justify-content-center mb-3">
            <Avatar size={64} shape="square" className="mx-auto" src={avatar} />
          </Col>
          <Col flex="none" className="detail-player-item">
            <Tag color={getColor(prefix.toString())}>{getPosition(prefix)}</Tag>
            <p className="text-small caption-12-medium font-weight-bolder mb-0">
              {item.matchPlayerFieldDTO.playerDTOS.uniformNumber}.{item.matchPlayerFieldDTO.playerDTOS.koName}
            </p>
          </Col>
        </Row>
      </DragItem>
    </div>
  );
};

export default ListItem;
