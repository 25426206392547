import dayjs from 'dayjs';

export interface IPlayerAcademic {
  id?: number;
  type?: number | null;
  schoolName?: string | null;
  issueYear?: string | null;
  rank?: number | null;
  graduationYear?: string | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  playerId?: number | null;
}

export const defaultValue: Readonly<IPlayerAcademic> = {};
