import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Avatar, Card, Col, Form, Input, Row, Select, Tabs, Button } from 'antd';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import { checkDuplicate, getEntity, getOrganization, updateEntity } from './player.reducer';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import NumberField from 'app/modules/lm-input-number';
import PlayerAcademic from '../player-academic/player-academic';
import PlayerOnMatch from '../player-on-match/player-on-match';
import PlayerTalent from '../player-talent/player-talent';
import PlayerTourament from '../player-tourament/player-tourament';
import axios from 'axios';
import TextField from 'app/modules/lm-input';
import LeagueAttend from '../league-attend/league-attend';
import { getBase64, getImage, upLoadImg, UploadType } from 'app/shared/reducers/reducer.utils';
import { toast } from 'react-toastify';
import DatePicker from 'app/modules/date-picker';
import dayjs from 'dayjs';
import { CATEGORY_DATA_CODE } from 'app/shared/util/category-data-code.constant';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { handleFocusFieldFail, validateSizeImage } from 'app/shared/util/entity-utils';
import { HomeOutlined, CaretLeftOutlined } from '@ant-design/icons';
import { sliceLocale } from 'app/shared/util/entity-utils';
import { handleValidateMessageLanguageChanged } from 'app/shared/util/validate';
import { IClub } from 'app/shared/model/club.model';
import { SearchModal } from 'app/modules/seatch-modal';
import { first } from 'lodash';
import { BsCaretLeft } from 'react-icons/bs';
import { hasAnyAuthority, isRole } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';
// import { locale } from 'app/shared/util/entity-utils';
export const PlayerUpdate = ({ match }) => {
  const [file, setFile] = useState<File>(null);
  const dispatch = useAppDispatch();
  const playerEntity = useAppSelector(state => state.player.entity);
  const updateSuccess = useAppSelector(state => state.player.updateSuccess);
  const { push } = useHistory();
  const [dataNation, setDataNation] = useState([]);
  const [dataPlayer, setDataPlayer] = useState([]);
  const [dataPositions, setDataPositions] = useState([]);
  const [previewImg, setPreviewImg] = useState('');
  const [searchClubModal, setSearchClubModal] = useState(false);
  const history = useHistory();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const [clubs, setClubs] = React.useState<IClub[]>([]);
  const [form] = Form.useForm();
  const [confirmModal, setConfirmModal] = useState(false);
  const isTeamManager = isRole(
    useAppSelector(state => state.authentication.account.authorities),
    [AUTHORITIES.TEAM_MANAGER]
  );

  const clubId = Form.useWatch('clubId', form);
  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
    handlePlayer({ lang: locale });
  }, [locale]);

  useEffect(() => {
    const section = document.querySelector('#main');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    window.scrollTo(0, 0);
    dispatch(getOrganization());
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      toast.success(translate('leagueApp.player.updated'));
      dispatch(getEntity(match.params.id));
    }
  }, [updateSuccess]);
  const onChange = (path: string) => {
    push(path);
  };

  const [entitySave, setEntitySave] = useState({
    avatar: '',
    koName: '',
    enName: '',
    uniformName: '',
    position: '',
    nationality: '',
    clubName: '',
    uniformNumber: '',
    birthday: '',
    height: '',
    weight: '',
  });

  useEffect(() => {
    if (playerEntity && playerEntity.id) {
      form.setFieldsValue({
        ...playerEntity,
        birthday: playerEntity.birthday ? dayjs(playerEntity.birthday) : null,
        nationality: locale === 'ko' ? playerEntity?.nationalityKorea : playerEntity?.nationality,
      });

      if (playerEntity.avatar) {
        getImage(playerEntity.avatar, UploadType.AVATAR_PLAYER).then(result => {
          setPreviewImg('data:image/png;base64,' + result.data);
        });
      } else {
        setPreviewImg(null);
        setFile(null);
      }
    }
  }, [playerEntity, locale]);

  const pathLink = {
    onMatch: 'on-match',
    league: 'player-league-attend',
    talent: 'player-talent',
    playerAcademic: 'player-academic',
  };

  function saveData(data: any, oldAvatar) {
    if (playerEntity.id) {
      data = {
        ...playerEntity,
      };
    }
    data.avatar = oldAvatar;
    data.koName = form.getFieldValue('koName');
    data.enName = form.getFieldValue('enName');
    data.uniformName = form.getFieldValue('uniformName');
    data.position = form.getFieldValue('position');
    data.nationality = form.getFieldValue('nationality');
    data.clubName = form.getFieldValue('clubName');
    data.uniformNumber = form.getFieldValue('uniformNumber');
    data.birthday = form.getFieldValue('birthday');
    data.height = form.getFieldValue('height');
    data.weight = form.getFieldValue('weight');
    data.clubId = form.getFieldValue('clubId');
    data.playerAgentId = form.getFieldValue('playerAgentId');
    data.organizationId = form.getFieldValue('organizationId');
    data.fifaId = form.getFieldValue('fifaId');
    if (data.id) {
      dispatch(updateEntity(data));
    }
  }

  const handlePlayer = async ({ lang, code }: any) => {
    const dataPlayerAgents = await axios.get<any>(`api/player-agents?code=${code}&lang=${lang}`);
    const arrPlayer = [];
    dataPlayerAgents.data.map(a =>
      arrPlayer.push({
        value: a.id,
        label: a.name,
      })
    );
    setDataPlayer(arrPlayer);
  };

  /** save action **/
  const handleValidSubmit = () => {
    /** save image **/
    const data: any = {};
    let oldAvatar = playerEntity.avatar;
    if (file) {
      /** neu co file thi upload **/
      const bodyImg = new FormData();
      bodyImg.append('file', file);
      bodyImg.append('uploadType', 'AVATAR_PLAYER');
      upLoadImg(bodyImg).then(result => {
        oldAvatar = result.data;
        saveData(data, oldAvatar);
        setConfirmModal(false);
      });
    } else {
      setConfirmModal(false);
      saveData(data, oldAvatar);
    }
  };

  const handleCreate = () => {
    dispatch(
      checkDuplicate({
        name: form.getFieldValue('koName'),
        birthDate: dayjs(form.getFieldValue('birthday')).format('YYYY/MM/DD'),
        playerId: Number(param?.id),
      })
    ).then(res => {
      const newData: any = res.payload;
      if (newData?.data?.length !== 0) {
        setConfirmModal(true);
      } else {
        setConfirmModal(false);
        handleValidSubmit();
      }
    });
  };

  const handlePreviewAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileImg = e.target.files[0];
    if (validateSizeImage(fileImg, e)) {
      setFile(fileImg);
      getBase64(fileImg, result => {
        setPreviewImg(result);
      });
    }
  };
  const organizations = useAppSelector(state => state.player.organizations);
  // const playerAgent = useAppSelector(state => state.playerAgent.playerAgent);

  const { path, url } = useRouteMatch();
  const param = useParams<{ id: string; params: string }>();
  const { authorities, unitStandardId } = useAppSelector(state => state.authentication.account);
  const isAdmin = isRole(
    useAppSelector(state => state.authentication.account.authorities),
    [AUTHORITIES.ADMIN]
  );
  React.useEffect(() => {
    if (!match.params.id) {
      return;
    }
    dispatch(getEntity(match.params.id));
  }, []);

  const getClubs = async (clubName?: string) => {
    try {
      const fetchClubs = await axios.get<any>(`api/assign-club-for-player?searchName=${clubName || ''}`);
      setClubs(fetchClubs.data);
    } catch (error) {
      setClubs([]);
    }
  };

  React.useEffect(() => {
    handleNationally({ lang: locale });
    handlePositon({ code: CATEGORY_DATA_CODE.POSITION_PLAYER, lang: locale });
  }, [locale]);
  const handleNationally = async ({ lang }: any) => {
    const dataNationally = await axios.get<any>(`api/utils/countries?lang=${lang}`);
    const arrNation = [];
    dataNationally.data.map((a, index) =>
      arrNation.push({
        value: a.name,
        label: a.name,
      })
    );
    setDataNation(arrNation);
  };

  const handlePositon = async ({ code, lang }: any) => {
    const dataPosition = await axios.get<any>(`api/category-data-by-code?code=${code}&lang=${lang}`);
    const arrPosition = [];
    dataPosition.data.dataTrans.map(
      (
        a //
      ) =>
        arrPosition.push({
          value: a.id,
          label: a.name,
          code: a.code,
        })
    );
    setDataPositions(arrPosition);
  };

  const container = [
    {
      label: <Translate contentKey="leagueApp.tab.onMatch" />,
      path: match.path.replace(':id', param.id).replace(':params', pathLink.onMatch),
      param: pathLink.onMatch,
    },
    {
      label: <Translate contentKey="leagueApp.tab.league" />,
      path: match.path.replace(':id', param.id).replace(':params', pathLink.league),
      param: pathLink.league,
    },
    {
      label: <Translate contentKey="leagueApp.tab.talent" />,
      path: match.path.replace(':id', param.id).replace(':params', pathLink.talent),
      param: pathLink.talent,
    },

    {
      label: <Translate contentKey="leagueApp.tab.academic" />,
      path: match.path.replace(':id', param.id).replace(':params', pathLink.playerAcademic),
      param: pathLink.playerAcademic,
    },
  ];
  const toDate = new Date();
  const handleBack = e => {
    history.goBack();
  };

  const options = [
    {
      value: 'name',
      label: translate('leagueApp.leagueClub.detail.title1'),
    },
  ];

  const columns = [
    {
      title: <Translate contentKey="leagueApp.leagueClub.detail.title1" />,
      dataIndex: 'name',
      key: 'name',
    },
  ];
  return (
    <div>
      <Card className="mb-3">
        <Form
          form={form}
          onFinishFailed={values => handleFocusFieldFail(values)}
          scrollToFirstError
          name="control-hooks"
          onFinish={handleCreate}
          layout="vertical"
          requiredMark={'optional'}
        >
          <div className="border-bottom d-flex justify-content-between align-items-center mb-2" style={{ paddingBottom: 30 }}>
            <div className="d-flex align-items-center auto-wrap">
              <Button className="rounded management-header-add-button" icon={<BsCaretLeft />} onClick={handleBack} type="primary">
                <Translate contentKey="entity.action.back" />
              </Button>
              <div className="management-layout-divider"></div>
              <p className="h4 mb-0">{playerEntity.koName}</p>
            </div>
            <Button htmlType="submit" type="primary" disabled={!!playerEntity.notEdit && isTeamManager}>
              <Translate contentKey="common.save" />
            </Button>
          </div>

          <Row gutter={[12, 12]}>
            <Col xl={6} lg={12} xs={24}>
              <Form.Item
                label={<h6>{translate('leagueApp.player.detail.label')}</h6>}
                name="koName"
                rules={[{ required: true, message: translate('leagueApp.required.koName') }]}
              >
                <TextField placeholder={translate('leagueApp.placeholder.koName')}></TextField>
              </Form.Item>
              <Form.Item
                label={<h6>{translate('leagueApp.player.detail.labelPosition')}</h6>}
                rules={[{ required: true, message: translate('leagueApp.required.position') }]}
                name="position"
              >
                <Select
                  placeholder={translate('global.choose.position')}
                  allowClear
                  className="custom-prefix-select"
                  suffixIcon={<SelectArrowDown />}
                >
                  {dataPositions.map((position, index) => (
                    <Select.Option value={position.label} key={index}>
                      {translate(`leagueApp.playerAgent.${position.code}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={<h6>* {translate('leagueApp.notice.noticeCategoryArr.organization')}</h6>}
                name="organizationId"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.notice.required.organization'),
                  },
                ]}
              >
                <Select
                  placeholder={translate('register.unit.placeholder')}
                  className="custom-prefix-select"
                  allowClear
                  showSearch
                  disabled={!isAdmin}
                  filterOption={(input, option) => (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                  suffixIcon={<SelectArrowDown />}
                >
                  {organizations.map(roles => (
                    <Select.Option value={roles.value} key={roles.value}>
                      {roles.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={<h6>{translate('leagueApp.player.birthday')}</h6>}
                name="birthday"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (toDate >= value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(translate('leagueApp.required.birthday')));
                    },
                  }),
                  {
                    required: true,
                    message: translate('leagueApp.required.birthdayRequired'),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder={translate('leagueApp.placeholder.birthday')}
                  format="YYYY-MM-DD"
                  allowClear
                />
              </Form.Item>

              {/* <Form.Item name="playerAgentId" label={<h6>{translate('leagueApp.player.name_manager')}</h6>}>
                <Select
                  placeholder={translate('leagueApp.placeholder.nameManager')}
                  className="custom-prefix-select"
                  allowClear
                  suffixIcon={<SelectArrowDown />}
                  showSearch
                  options={dataPlayer}
                  filterOption={(inputValue: string, option?: any) => {
                    return (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase());
                  }}
                >
                  {dataPlayer.map(roles => (
                    <Select.Option value={roles.value} key={roles.value}>
                      {roles.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item> */}
            </Col>
            <Col xl={6} lg={12} xs={24}>
              <Form.Item label={<h6>{translate('leagueApp.player.detail.labelEname')}</h6>} name="enName">
                <TextField placeholder={translate('leagueApp.placeholder.enName')}></TextField>
              </Form.Item>
              <Form.Item
                label={<h6>{translate('leagueApp.player.detail.labelNation')}</h6>}
                rules={[{ required: true, message: translate('leagueApp.required.nation') }]}
                name="nationality"
              >
                <Select
                  placeholder={translate('global.choose.nation')}
                  allowClear
                  className="custom-prefix-select"
                  showSearch
                  suffixIcon={<SelectArrowDown />}
                  options={dataNation}
                  filterOption={(inputValue: string, option?: any) => {
                    return (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase());
                  }}
                >
                  {/* {dataNation.map((nations, index) => (
                    <Select.Option value={nations.label} key={index}>
                      {nations.label}
                    </Select.Option>
                  ))} */}
                </Select>
              </Form.Item>
              <Form.Item label={<h6>{translate('leagueApp.player.label.teamLabel')}</h6>} name="clubName">
                <Input
                  placeholder={translate('leagueApp.leagueClub.home.createTitle1')}
                  suffix={
                    <Button onClick={() => setSearchClubModal(true)} className="position-absolute" style={{ right: '8px' }}>
                      <Translate contentKey="leagueApp.league.choose" />
                    </Button>
                  }
                  readOnly
                  className="position-relative"
                ></Input>
              </Form.Item>

              {/* <Form.Item
                label={<h6>{translate('leagueApp.player.height')}</h6>}
                name="height"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || 0 < value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(translate('leagueApp.required.height')));
                    },
                  }),
                ]}
              >
                <NumberField negative={true} placeholder={translate('leagueApp.placeholder.height')} />
              </Form.Item> */}
            </Col>
            <Col xl={6} lg={12} xs={24} style={{ display: 'flex', flexDirection: 'column' }}>
              <Form.Item label={<h6>{translate('leagueApp.player.uniformName')}</h6>} name="uniformName">
                <TextField placeholder={translate('leagueApp.placeholder.uniformName')}></TextField>
              </Form.Item>
              <Form.Item label={<h6>{translate('leagueApp.player.id_number')}</h6>} name="fifaId">
                <NumberField placeholder={translate('leagueApp.placeholder.id_number')} />
              </Form.Item>
              <Form.Item
                label={<h6>{translate('leagueApp.player.label.uniformLabel')}</h6>}
                name="uniformNumber"
                rules={[{ required: !!clubId, message: translate('leagueApp.required.uniformNumber') }]}
              >
                <NumberField disabled={!clubId} negative={true} placeholder={translate('leagueApp.placeholder.uniformNumber')} />
              </Form.Item>
              {/* <Form.Item
                label={<h6>{translate('leagueApp.player.weight')}</h6>}
                name="weight"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || 0 < value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(translate('leagueApp.required.weight')));
                    },
                  }),
                ]}
              >
                <NumberField negative={true} placeholder={translate('leagueApp.placeholder.weight')} />
              </Form.Item> */}
            </Col>
            <Col xl={6} lg={12} xs={24} className="h-100">
              <Form.Item name="avatar">
                <div style={{ width: '100%', objectFit: 'fill' }} className="d-flex justify-content-center">
                  <label htmlFor="uploadImage" className="upload-file" style={{ width: '100%', padding: '50% 0' }}>
                    {previewImg ? (
                      <img
                        src={previewImg}
                        alt="logo"
                        style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: 0 }}
                      />
                    ) : (
                      <span>+ UPLOAD</span>
                    )}
                  </label>
                  <Input id="uploadImage" value={null} hidden type="file" accept="image/*" onChange={handlePreviewAvatar} />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="clubId" className="m-auto">
            <Input type={'hidden'}></Input>
          </Form.Item>
        </Form>
      </Card>
      <Tabs onChange={onChange} type="card" activeKey={param.params}>
        {container.map((item, index) => (
          <Tabs.TabPane tab={item.label} key={item.param} />
        ))}
      </Tabs>
      <Card className="card-after-tabs">
        <Switch>
          <ErrorBoundaryRoute exact path={match.path.replace(':params', pathLink.playerAcademic)} component={PlayerAcademic} />
          <ErrorBoundaryRoute exact path={match.path.replace(':params', pathLink.onMatch)} component={PlayerOnMatch} />
          <ErrorBoundaryRoute exact path={match.path.replace(':params', pathLink.talent)} component={PlayerTalent} />
          <ErrorBoundaryRoute exact path={match.path.replace(':params', pathLink.league)} component={PlayerTourament} />
          <Redirect to={match.path.replace(':id', param.id).replace(':params', pathLink.onMatch)} />
        </Switch>
      </Card>
      {searchClubModal && (
        <SearchModal
          onClose={() => {
            setSearchClubModal(false);
          }}
          open={searchClubModal}
          list={clubs}
          columns={columns}
          options={options}
          onFetchData={(data: { name: string }) => getClubs(data?.name)}
          title={translate('leagueApp.leagueClub.home.createTitle1')}
          subTitle={translate('leagueApp.leagueClub.home.createTitle1')}
          multiple={false}
          onAccept={(selected: any[]) => {
            form.setFieldsValue({ clubName: first(selected)?.name, clubId: first(selected)?.id, uniformNumber: null });
            setSearchClubModal(false);
          }}
          selected={[clubId]}
        />
      )}
      {confirmModal && (
        <ConfirmationDialog
          open={confirmModal}
          message={<h6>{translate('leagueApp.player.duplicate')}</h6>}
          onClose={() => setConfirmModal(false)}
          onConfirm={() => handleValidSubmit()}
        />
      )}
      {/* <LeagueAttend id={idClub.id} /> */}
    </div>
  );
};
