import { Button, Checkbox, Col, Form, Input, message, Modal, Row } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import InputTime from 'app/modules/im-input-time';
import ModalChange from 'app/modules/modal-change';
import { regexHHMMSS } from 'app/shared/util/regex';
import { validateMin } from 'app/shared/util/validate';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { changeStatusMatch, createMatchRate, getDetailMatchStatus, updateStatusMatch } from '../../match-record.reducer';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { IMatchTime } from 'app/shared/model/match-time.model';
import { sendRecord } from 'app/config/websocket-middleware';
import { toast } from 'react-toastify';
import TimePicker from 'app/modules/time-picker';
import DatePicker from 'app/modules/date-picker';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import locale from 'antd/es/date-picker/locale/de_DE';

interface IFormBody {
  endSubTime1?: string;
  endSubTime2?: string;
  endTime1?: string;
  endTime2?: string;
  startSubTime1?: string;
  startSubTime2?: string;
  startTime1?: string;
  startTime2?: string;
  matchId?: string | number;
  id?: string | number;
}
dayjs.extend(utc);
dayjs.extend(timezone);

const getDefaultTime = (time?: string) => {
  return time && dayjs(time).isValid() ? dayjs(time) : undefined;
};

const ChangeStatusMatch = ({ isOpen, setIsOpen, id, canChange }) => {
  const [disabled, setDisable] = useState(true);
  const [form] = Form.useForm();
  const startTime1 = Form.useWatch('startTime1', form);
  const startTime2 = Form.useWatch('startTime2', form);
  const endTime1 = Form.useWatch('endTime1', form);
  const endTime2 = Form.useWatch('endTime2', form);
  const startSubTime1 = Form.useWatch('startSubTime1', form);
  const endSubTime1 = Form.useWatch('endSubTime1', form);
  const startSubTime2 = Form.useWatch('startSubTime2', form);
  const endSubTime2 = Form.useWatch('endSubTime2', form);
  const dispatch = useAppDispatch();
  const matchTimes: IMatchTime = useAppSelector(state => state.matchTime?.entity);
  const matchBasicInfoEntity = useAppSelector(state => state.match.entity);

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(getDetailMatchStatus(id));
  }, [id]);
  useEffect(() => {
    if (matchTimes) {
      form.setFieldsValue({
        ...matchTimes,
        startTime1: getDefaultTime(matchTimes.startTime1),
        endTime1: getDefaultTime(matchTimes.endTime1),
        startTime2: getDefaultTime(matchTimes.startTime2),
        endTime2: getDefaultTime(matchTimes.endTime2),
        startSubTime1: getDefaultTime(matchTimes.startSubTime1),
        endSubTime1: getDefaultTime(matchTimes.endSubTime1),
        startSubTime2: getDefaultTime(matchTimes.startSubTime2),
        endSubTime2: getDefaultTime(matchTimes.endSubTime2),
      });
    }
    // }, [matchTimes]);
  }, []);

  const handleSubmit = data => {
    const body: IFormBody = {
      endSubTime1: handleConvertBody(data.endSubTime1),
      endSubTime2: handleConvertBody(data.endSubTime2),
      endTime1: handleConvertBody(data.endTime1),
      endTime2: handleConvertBody(data.endTime2),
      startSubTime1: handleConvertBody(data.startSubTime1),
      startSubTime2: handleConvertBody(data.startSubTime2),
      startTime1: handleConvertBody(data.startTime1),
      startTime2: handleConvertBody(data.startTime2),
      matchId: id,
    };

    sendRecord(`/record/match-time/change-time/${id}`, {
      ...body,
    });
    toast.success(translate('leagueApp.match.updateSuccess'));
    setIsOpen(false);
    // if (!matchTimes?.id) {
    //   dispatch(changeStatusMatch(body)).then(res => {
    //     if (res.meta.requestStatus === 'fulfilled') {
    //       setIsOpen(false);
    //     }
    //   });
    // } else {
    //   body.id = matchTimes?.id;
    //   dispatch(updateStatusMatch(body)).then(res => {
    //     if (res.meta.requestStatus === 'fulfilled') {
    //       setIsOpen(false);
    //     }
    //   });
    // }
  };
  const handleConvertBody = value => {
    if (!value) {
      return undefined;
    } else {
      const newDate = dayjs(value).toISOString();
      return newDate;
    }
  };

  const handleChangeTextBox = e => {
    if (e.target.checked) {
      setDisable(false);
    } else {
      setDisable(true);
      form.setFieldsValue({
        startSubTime1: undefined,
        endSubTime1: undefined,
        startSubTime2: undefined,
        endSubTime2: undefined,
      });
    }
  };

  return (
    <Modal
      visible={isOpen}
      className="register-record"
      onCancel={() => setIsOpen(false)}
      onOk={() => form.submit()}
      title={<Translate contentKey="leagueApp.matchStatus.matchStatus" />}
      width={800}
      footer={[
        <Button key="back" onClick={() => setIsOpen(false)}>
          {translate('entity.action.close')}
        </Button>,
        <Button type="primary" htmlType="submit" disabled={!canChange} key="submit" onClick={() => form.submit()}>
          {translate('clubmng.save')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        id="matchStatus"
        onFinish={handleSubmit}
        onFinishFailed={values => handleFocusFieldFail(values)}
        scrollToFirstError
        name="control-hooks"
        style={{ pointerEvents: canChange ? 'auto' : 'none', width: 720 }}
      >
        <Row gutter={16} align="middle" style={{ marginBottom: 12 }}>
          <Col span={4} />
          <Col span={10} className="heading-16-medium">
            <Translate contentKey="leagueApp.matchStatus.start">Start</Translate>
          </Col>
          <Col span={10} className="heading-16-medium">
            <Translate contentKey="leagueApp.matchStatus.end">Finish</Translate>
          </Col>
        </Row>
        <Row gutter={16} align="middle" style={{ marginBottom: 12 }}>
          <Col span={4} className="body-14-medium">
            <Translate contentKey="leagueApp.matchStatus.firstHalf">First half</Translate>
          </Col>
          <Col span={10}>
            <Form.Item
              name="startTime1"
              className="mb-0"
              rules={[{ required: !!endTime1, message: translate('entity.validation.required') }]}
            >
              <DatePicker
                locale={{
                  ...locale,
                  lang: {
                    ...locale.lang,
                    now: translate('entity.action.now'),
                    ok: 'OK',
                  },
                }}
                className="w-100"
                inputReadOnly
                showTime
                format={'HH:mm:ss'}
                placeholder={translate('leagueApp.matchRecord.selectTime')}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="endTime1"
              rules={[
                { required: !!startTime2, message: translate('entity.validation.required') },
                {
                  validator(_, value) {
                    return validateMin(
                      value,
                      startTime1,
                      translate('leagueApp.matchStatus.endTime'),
                      translate('leagueApp.matchStatus.startTime'),
                      matchBasicInfoEntity?.timeOfRound
                    );
                  },
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                locale={{
                  ...locale,
                  lang: {
                    ...locale.lang,
                    now: translate('entity.action.now'),
                    ok: 'OK',
                  },
                }}
                className="w-100"
                inputReadOnly
                showTime
                format={'HH:mm:ss'}
                placeholder={translate('leagueApp.matchRecord.selectTime')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} align="middle" style={{ marginBottom: 34 }}>
          <Col span={4} className="body-14-medium">
            <Translate contentKey="leagueApp.matchStatus.secondHalf">Second half</Translate>
          </Col>
          <Col span={10}>
            <Form.Item
              name="startTime2"
              rules={[
                { required: !!endTime2, message: translate('entity.validation.required') },
                {
                  validator(_, value) {
                    return validateMin(
                      value,
                      endTime1,
                      translate('leagueApp.matchStatus.startOfSecond'),
                      translate('leagueApp.matchStatus.endFirstHalf')
                    );
                  },
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                locale={{
                  ...locale,
                  lang: {
                    ...locale.lang,
                    now: translate('entity.action.now'),
                    ok: 'OK',
                  },
                }}
                className="w-100"
                inputReadOnly
                showTime
                format={'HH:mm:ss'}
                placeholder={translate('leagueApp.matchRecord.selectTime')}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="endTime2"
              rules={[
                { required: !!startSubTime1, message: translate('entity.validation.required') },
                {
                  validator(_, value) {
                    return validateMin(
                      value,
                      startTime2,
                      translate('leagueApp.matchStatus.endTime'),
                      translate('leagueApp.matchStatus.startTime'),
                      matchBasicInfoEntity?.timeOfRound
                    );
                  },
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                locale={{
                  ...locale,
                  lang: {
                    ...locale.lang,
                    now: translate('entity.action.now'),
                    ok: 'OK',
                  },
                }}
                className="w-100"
                inputReadOnly
                showTime
                format={'HH:mm:ss'}
                placeholder={translate('leagueApp.matchRecord.selectTime')}
              />
            </Form.Item>
          </Col>
        </Row>
        {matchTimes.startSubTime1 && (
          <>
            <Row align="middle" style={{ marginBottom: 12 }}>
              <Col span={4} />
              <Col span={10} style={{ display: 'flex' }} className="heading-16-medium">
                <div style={{ width: '20px', marginRight: '12px' }} className="square-checkbox">
                  <Checkbox style={{ width: '100%', height: '100%' }} type="checkbox" checked={!!startSubTime1} />
                </div>
                <Translate contentKey="leagueApp.matchStatus.startExtratime">Start extra time</Translate>
              </Col>
              <Col span={10} />
            </Row>
            <Row gutter={16} align="middle" style={{ marginBottom: 12 }}>
              <Col span={4} className="body-14-medium">
                <Translate contentKey="leagueApp.matchStatus.extraTime1">Extra time 1</Translate>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="startSubTime1"
                  rules={[
                    { required: !!endSubTime1, message: translate('entity.validation.required') },
                    {
                      validator(_, value) {
                        return validateMin(
                          value,
                          endTime2,
                          translate('leagueApp.matchStatus.startFirstExtra'),
                          translate('leagueApp.matchStatus.endOfSecond')
                        );
                      },
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <DatePicker
                    locale={{
                      ...locale,
                      lang: {
                        ...locale.lang,
                        now: translate('entity.action.now'),
                        ok: 'OK',
                      },
                    }}
                    disabled={!startSubTime1}
                    className="w-100"
                    inputReadOnly
                    showTime
                    format={'HH:mm:ss'}
                    placeholder={translate('leagueApp.matchRecord.selectTime')}
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="endSubTime1"
                  rules={[
                    { required: !!startSubTime2, message: translate('entity.validation.required') },
                    {
                      validator(_, value) {
                        return validateMin(
                          value,
                          startSubTime1,
                          translate('leagueApp.matchStatus.endTime'),
                          translate('leagueApp.matchStatus.startTime'),
                          matchBasicInfoEntity?.timeOfRoundExtra
                        );
                      },
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <DatePicker
                    locale={{
                      ...locale,
                      lang: {
                        ...locale.lang,
                        now: translate('entity.action.now'),
                        ok: 'OK',
                      },
                    }}
                    disabled={!startSubTime1}
                    className="w-100"
                    inputReadOnly
                    showTime
                    format={'HH:mm:ss'}
                    placeholder={translate('leagueApp.matchRecord.selectTime')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} align="middle" style={{ marginBottom: 12 }}>
              <Col span={4} className="body-14-medium">
                <Translate contentKey="leagueApp.matchStatus.extraTime2">Extra time 2</Translate>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="startSubTime2"
                  rules={[
                    { required: !!endSubTime2, message: translate('entity.validation.required') },
                    {
                      validator(_, value) {
                        return validateMin(
                          value,
                          endSubTime1,
                          translate('leagueApp.matchStatus.startSecondExtra'),
                          translate('leagueApp.matchStatus.endFirstExtra')
                        );
                      },
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <DatePicker
                    locale={{
                      ...locale,
                      lang: {
                        ...locale.lang,
                        now: translate('entity.action.now'),
                        ok: 'OK',
                      },
                    }}
                    disabled={!startSubTime1}
                    className="w-100"
                    inputReadOnly
                    showTime
                    format={'HH:mm:ss'}
                    placeholder={translate('leagueApp.matchRecord.selectTime')}
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="endSubTime2"
                  rules={[
                    {
                      validator(_, value) {
                        return validateMin(
                          value,
                          startSubTime2,
                          translate('leagueApp.matchStatus.endTime'),
                          translate('leagueApp.matchStatus.startTime'),
                          matchBasicInfoEntity?.timeOfRoundExtra
                        );
                      },
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <DatePicker
                    locale={{
                      ...locale,
                      lang: {
                        ...locale.lang,
                        now: translate('entity.action.now'),
                        ok: 'OK',
                      },
                    }}
                    disabled={!startSubTime1}
                    className="w-100"
                    inputReadOnly
                    showTime
                    format={'HH:mm:ss'}
                    placeholder={translate('leagueApp.matchRecord.selectTime')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default React.memo(ChangeStatusMatch);
