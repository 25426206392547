import { FilterParams } from './filter.model';
import { IMatchPlayerField } from './match-player-field.model';

export interface IPlayer {
  id?: number;
  name?: string;
  organizationId?: number | null;
  homeAddress?: string | null;
  email?: string | null;
  idNumber?: number | null;
  fifaId?: number | null;
  avatar?: string | null;
  koName?: string | null;
  enName?: string | null;
  uniformName?: string | null;
  position?: string | null;
  nationality?: string | null;
  clubId?: number | null;
  clubName?: string | null;
  uniformNumber?: string | null;
  birthday?: string | null;
  height?: number | null;
  weight?: number | null;
  fileContract?: string | null;
  fileITC?: string | null;
  fileLicense?: string | null;
  previousClub?: string | null;
  phoneNumber?: number | null;
  playerAgentId?: number | null;
  birthplace?: string | null;
  dataUploadFile?: string | null;
  notEdit?: boolean | null | false;
  base64Avatar?: string | null;
  base64FileLicense?: string | null;
  base64FileITC?: string | null;
  base64FileContract?: string | null;
}

export const defaultValue: Readonly<IPlayer> = {};

export interface IMatchPlayerFieldDTO extends IMatchPlayerField {
  playerDTOS: IPlayer;
}

interface IPlayerGoalInMatch {
  playerId: number | null;
  timeProcess: number;
  roundType: number;
}
export interface IPlayerInMatch {
  id: number;
  banCard: number | null;
  matchPlayerFieldId: number;
  positionOrder: number;
  positionType: number;
  startTime: number;
  endTime: number;
  playerId: number;
  playerGoalInMatchDTOS: IPlayerGoalInMatch[];
  matchPlayerFieldDTO: IMatchPlayerFieldDTO;
}

export interface IParamsFilterPlayer extends FilterParams {
  koName?: string;
  clubName?: string;
  position?: string;
  nationality?: string;
}
export interface IFileTemplatePlayer {
  type?: string;
  lang?: string;
  fileName?: string;
  leagueId?: string;
  file?: File;
  listPlayerId?: number[];
  zoneId: string;
  filter?: IParamsFilterPlayer;
}

export interface IErrorUploadTemplatePlayer {
  field: string;
  errorCodeImport: string;
}

export interface IItemUploadPlayer {
  rowId: number;
  errorImport: IErrorUploadTemplatePlayer[];
  koName: string;
  birthday: string;
  position: string;
  nationality: string;
  organizationName: string;
  clubName: string;
  uniformNumber: string;
  clubId: number;
  organizationId: number;
  numberUniformNumber: number;
  error: boolean;
  notError: boolean;
}
export interface IFormCreatePlayers {
  players: IItemUploadPlayer[];
  lang?: string;
  zoneId: string;
}
