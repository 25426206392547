import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useState, useEffect } from 'react';
import { translate, Translate } from 'react-jhipster';
import { Form, Input, Select, Modal, Button, Row, Col } from 'antd';
import NumberField from 'app/modules/lm-input-number';
import TextField from 'app/modules/lm-input';
import { clearMsgCreate, clearMsgUpdate, createEntity, getEntity, reset, updateEntity } from './club.reducer';
import axios from 'axios';
import { getBase64, upLoadImg } from 'app/shared/reducers/reducer.utils';
import { toast } from 'react-toastify';
import { handleFocusFieldFail, validateSizeImage } from 'app/shared/util/entity-utils';
import DatePicker from 'app/modules/date-picker';
import dayjs from 'dayjs';
import { handleValidateMessageLanguageChanged, validateImg } from 'app/shared/util/validate';
import { SearchIcon } from 'app/shared/util/appIcon';
import SearchModal from 'app/modules/seatch-modal';
import { ColumnsType } from 'antd/lib/table';
import { first } from 'lodash';
import { hasAnyAuthority, isRole } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

const ModalCreateClub = (props: any) => {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File>(null);
  const { isOpen, setIsOpen, updateFilter } = props;
  const [previewImg, setPreviewImg] = useState('');
  const [image, setImage] = useState(null);
  const [isNew, setIsNew] = useState(true);
  const [searchUnitStandard, setSearchUnitStandard] = useState(false);
  const [searchTeamManager, setSearchTeamManager] = useState(false);
  const [teamManager, setTeamManager] = useState([]);
  const [unitStandard, setUnitStandard] = useState([]);
  const clubEntity = useAppSelector(state => state.club.entity);
  const createSuccess = useAppSelector(state => state.club.createSuccess);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const [chooseUnit, setChooseUnit] = useState(null);
  const account = useAppSelector(state => state.authentication.account);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const isLeagueMaster = isRole(authorities, [AUTHORITIES.LEAGUE_MASTER]);
  const loading = useAppSelector(state => state.club.loading);

  const getClubManager = async (searchName?: string) => {
    try {
      const fetchClubs = await axios.get(
        `api/clubs/get-list-assign-club-management?unitStandardId=${unitStandardData}&searchName=${searchName || ''}`
      );
      setTeamManager(fetchClubs?.data);
    } catch (error) {
      setTeamManager([]);
    }
  };

  const getUnitStandard = async (unitName?: string) => {
    try {
      const fetchUnitStandard = await axios.post(`api/search-unit-standards?page=0size=1000`, {
        name: unitName || '',
      });
      setUnitStandard(fetchUnitStandard?.data?.content);
    } catch (error) {
      setUnitStandard([]);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    handleReset();
  };

  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
  }, [locale]);

  const handleUpdate = id => {
    dispatch(getEntity(id));
    setIsOpen(false);
  };

  useEffect(() => {
    if (createSuccess) {
      handleReset();
    }
  }, [createSuccess]);

  function saveData(data: any, oldAvatar) {
    if (!isNew && clubEntity.id) {
      data = {
        ...clubEntity,
        clubManagementDTOList: {
          clubId: null,
          managementId: form.getFieldValue('managementId'),
        },
      };
    }
    const submitData = {
      clubManagementDTOList: form.getFieldValue('managementId')
        ? form.getFieldValue('managementId').map(item => {
            return {
              clubId: null,
              managementId: item,
            };
          })
        : [],
      name: form.getFieldValue('name'),
      shortName: form.getFieldValue('shortName'),
      issueYear: dayjs(form.getFieldValue('issueYear')).format('YYYY'),
      avatar: oldAvatar,
      unitStandardId: form.getFieldValue('unitStandardId'),
    };

    dispatch(createEntity(submitData));
    setIsOpen(false);
  }

  const [formCreate, setFormCreate] = useState({
    avatar: '',
    name: '',
    shortName: '',
    issueYear: '',
  });

  const handleReset = () => {
    setFormCreate({
      avatar: '',
      name: '',
      shortName: '',
      issueYear: '',
    });
    // form.resetFields();
    form.setFieldsValue({
      avatar: '',
      name: '',
      shortName: '',
      issueYear: '',
      // unitStandardName: '',
      // unitStandardId: '',
      managementName: '',
      managementId: '',
    });
    // dispatch(reset);
    // setShowDialog(true);
    setPreviewImg(null);
    setPreviewImg('');
    setFile(null);
    dispatch(reset);
  };

  const handlePreviewAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileImg = e.target.files[0];
    if (validateSizeImage(fileImg, e)) {
      setFile(fileImg);
      getBase64(fileImg, result => {
        setPreviewImg(result);
      });
    }
  };

  const handleCreateClub = e => {
    const data: any = {};
    let oldAvatar = clubEntity.avatar;
    if (file) {
      const bodyImg = new FormData();
      bodyImg.append('file', file);
      bodyImg.append('uploadType', 'AVATAR_CLUB');
      upLoadImg(bodyImg).then(result => {
        oldAvatar = result.data;
        saveData(data, oldAvatar);
        updateFilter();
        setIsOpen(false);
      });
    } else {
      saveData(data, oldAvatar);
      updateFilter();
      setIsOpen(false);
    }
  };
  const [form] = Form.useForm();

  const unitStandardData = Form.useWatch('unitStandardId', form);
  const managementId = Form.useWatch('managementId', form);

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };

  const optionsUnit = [
    {
      value: 'name',
      label: translate('leagueApp.unitStandard.name'),
    },
  ];

  const optionClubManager = [
    {
      value: 'name',
      label: translate('leagueApp.unitStandard.name'),
    },
  ];

  const columnClubManager = [
    {
      title: <Translate contentKey="leagueApp.unitStandard.name" />,
      dataIndex: 'fullName',
      key: 'fullName',
    },
  ];

  const columnsUnit: ColumnsType<any> = [
    {
      title: <Translate contentKey="leagueApp.unitStandard.name">Name</Translate>,
      dataIndex: 'name',
      key: 'name',
    },
  ];

  return (
    <React.Fragment>
      <Modal
        visible={isOpen}
        onCancel={handleCloseModal}
        title={<Translate contentKey="leagueApp.club.home.createLabel1" />}
        wrapClassName="management-wrapper-modal"
        footer={null}
        width={900}
      >
        <Form
          initialValues={{
            unitStandardId: isLeagueMaster ? account?.unitStandardId : null,
            unitStandardName: isLeagueMaster ? account?.unitStandardName : '',
          }}
          form={form}
          onFinishFailed={values => handleFocusFieldFail(values)}
          scrollToFirstError
          name="control-hooks"
          onFinish={handleCreateClub}
          layout="vertical"
          requiredMark={'optional'}
        >
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item
                name="avatar"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.club.required.clubLogo1'),
                  },
                ]}
                label={<h6>*{translate('leagueApp.club.avatar1')}</h6>}
              >
                <div>
                  <div className="input-width">
                    <label htmlFor="uploadImage" className="upload-file">
                      {previewImg ? <img src={previewImg} alt="logo" style={{ width: '100%', height: '100%' }} /> : <span>+ TẢI ẢNH</span>}
                    </label>
                    <span style={{ fontSize: '12px' }} className="primary-color">
                      <Translate contentKey="leagueApp.club.home.uploadimg1" />
                    </span>
                    <Input id="uploadImage" value={null} hidden type="file" accept="image/*" onChange={handlePreviewAvatar} />
                  </div>
                </div>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="name"
                label={<h6>{translate('leagueApp.club.table.clubName1')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.club.required.name1'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.club.placeholder.clubNamePlaceholder1')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="shortName"
                label={<h6>{translate('leagueApp.club.table.shortName')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.club.required.shortName'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.club.placeholder.shortNamePlaceholder')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="issueYear"
                label={<h6>{translate('leagueApp.club.table.issueYear')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.club.required.issueYear'),
                  },
                ]}
              >
                <DatePicker
                  picker="year"
                  placeholder={translate('leagueApp.club.placeholder.issueYear')}
                  style={{ width: '100%' }}
                  format="YYYY"
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="unitStandardName"
                label={<h6>* {translate('leagueApp.notice.noticeCategoryArr.organization')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.club.required.organization'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('leagueApp..club.placeholder.organization')}
                  suffix={
                    <Button
                      disabled={isLeagueMaster ? true : false}
                      onClick={() => setSearchUnitStandard(true)}
                      className="position-absolute"
                      style={{ right: '8px' }}
                    >
                      <Translate contentKey="leagueApp.league.choose" />
                    </Button>
                  }
                  readOnly
                  // disabled
                ></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="managementName" label={<h6>{translate('sidebar.teamManagement2')}</h6>}>
                <Input
                  placeholder={translate('leagueApp.club.placeholder.club')}
                  suffix={
                    <Button
                      disabled={!unitStandardData ? true : false}
                      onClick={() => {
                        setSearchTeamManager(true);
                      }}
                      className="position-absolute"
                      style={{ right: '8px' }}
                    >
                      <Translate contentKey="leagueApp.league.choose" />
                    </Button>
                  }
                  readOnly={unitStandardData ? true : false}
                  disabled={!unitStandardData ? true : false}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="management-modal-footer">
                <Button key="back" onClick={handleCloseModal}>
                  <Translate contentKey="register.form.close" />
                </Button>
                <Button key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }} loading={loading}>
                  <Translate contentKey="settings.form.button" />
                </Button>
              </div>
            </Col>
            <Form.Item name="unitStandardId">
              <Input type={'hidden'}></Input>
            </Form.Item>
            <Form.Item name="managementId">
              <Input type={'hidden'}></Input>
            </Form.Item>
          </Row>
        </Form>
        {searchUnitStandard && (
          <SearchModal
            onClose={() => {
              setSearchUnitStandard(false);
            }}
            options={optionsUnit}
            open={searchUnitStandard}
            list={unitStandard}
            columns={columnsUnit}
            onFetchData={(data: { name?: string }) => getUnitStandard(data?.name)}
            title={translate('leagueApp.unitStandard.home.title')}
            subTitle={translate('leagueApp.unitStandard.chooseUnit')}
            multiple={false}
            onAccept={(selected: any[]) => {
              form.setFieldsValue({ unitStandardName: first(selected)?.name, unitStandardId: first(selected)?.id });
              setSearchUnitStandard(false);
            }}
            selected={[unitStandardData]}
          />
        )}
        {searchTeamManager && (
          <SearchModal
            onClose={() => {
              setSearchTeamManager(false);
            }}
            options={optionClubManager}
            open={searchTeamManager}
            list={teamManager}
            columns={columnClubManager}
            onFetchData={(data: { name?: string }) => getClubManager(data?.name)}
            multiple={false}
            onAccept={(selected: any[]) => {
              form.setFieldsValue({ managementId: selected.map(item => item?.id), managementName: selected.map(item => item?.fullName) });
              setSearchTeamManager(false);
            }}
            title={translate('sidebar.teamManagement')}
            subTitle={translate('leagueApp.player.chooseTeamManager')}
            selected={managementId}
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

export default ModalCreateClub;
