import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useRequest} from "ahooks";
import {matchSchedule} from "app/modules/dashboard/dashboard.service";
import {translate, Translate} from "react-jhipster";
import {Col, Row, Table} from "antd";
import dayjs from "dayjs";
import {APP_DDMM_FORMAT, APP_LOCAL_HOUR_FORMAT, APP_TIMESTAMP_FORMAT} from "app/config/constants";
import './match-schedule-detail.scss'

export const MatchScheduleDetail = () => {
  const [data, setData] = React.useState(null);
  const [idLeague, setIdLeague] = React.useState(null);
  const location: any = useLocation();
  const request = useRequest(matchSchedule, {
    manual: true,
    onSuccess(res) {
      setData(res.data);
    },
    onError(err) {
    },
  });
  const columns = [
    {
      title: <Translate contentKey="dashboard.matchScheduleDetail.date">date</Translate>,
      dataIndex: 'startDate',
      key: 'startDate',
      width: 200,
      render: (value, record) => <span>{dayjs(value).format(APP_TIMESTAMP_FORMAT)}</span>,
    },
    {
      title: <Translate contentKey="dashboard.matchScheduleDetail.date">time</Translate>,
      dataIndex: 'startDate',
      key: 'startDate',
      width: 200,
      render: (value, record) => <span>{dayjs(value).format(APP_LOCAL_HOUR_FORMAT)}</span>,
    },
    {
      title: <Translate contentKey="dashboard.matchScheduleDetail.homeTeam">homeTeam</Translate>,
      dataIndex: 'ownerClubName',
      key: 'ownerClubName',
      width: 200,
    },
    {
      title: <Translate contentKey="dashboard.matchScheduleDetail.awayTeam">awayTeam</Translate>,
      dataIndex: 'awayClubName',
      key: 'awayClubName',
      width: 200,
    },
    {
      title: <Translate contentKey="dashboard.matchScheduleDetail.homeTeamGold">homeTeamGold</Translate>,
      dataIndex: 'ownerGoal',
      key: 'ownerGoal',
      width: 100,
    },
    {
      title: <Translate contentKey="dashboard.matchScheduleDetail.awayTeamGold">awayTeamGold</Translate>,
      dataIndex: 'awayGoal',
      key: 'awayGoal',
      width: 100,
    },
  ];
  useEffect(() => {
    if (location?.state && location?.state.status && location?.state.idLeague) {
      setIdLeague(location?.state.idLeague);
      request.run(location?.state.idLeague);
    }
  }, [location]);

  useEffect(() => {
    if (idLeague) {
      request.run(idLeague);
    }
  }, [idLeague])


  return (
    <div className="management-layout">
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading">
            <Translate contentKey="dashboard.matchScheduleDetail.title">Match schedule detail</Translate>
          </h2>
        </div>
      </div>
      <div style={{marginTop: '15px'}}>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              loading={false}
              dataSource={data}
              pagination={false}
              scroll={{
                x: 'calc(700px + 50%)',
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default MatchScheduleDetail;
