import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Coach from './coach';
import CoachDetail from './coach-detail';
import CoachUpdate from './coach-update';
import CoachDeleteDialog from './coach-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CoachUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CoachUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CoachDetail} />
      <ErrorBoundaryRoute path={match.url} component={Coach} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={CoachDeleteDialog} />
  </>
);

export default Routes;
