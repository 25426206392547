import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { Button, Col, ConfigProvider, Empty, Modal } from 'antd';
// import Table from 'app/modules/table';
import { Table } from 'antd';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity, createEntity, getEntities } from '../stadium/stadium.reducer';
import axios from 'axios';
import { StadiumListFilter } from '../stadium/stadiumd.type';
import { useFilter } from 'app/shared/hooks/useFilter';
import Filter from 'app/modules/filter';
import { FilterParams } from 'app/shared/model/filter.model';

export const StadiumDialog = (props: any) => {
  const { modalStadium, setModalStadium, createStadium, setCreateStadium } = props;
  const [loadModal, setLoadModal] = useState(false);
  const dispatch = useAppDispatch();
  const [dataPositions, setDataPositions] = useState([]);
  const [freePlayer, setfreePlayer] = useState([]);
  const [data, setData] = useState([{}]);
  const loading = useAppSelector(state => state.stadium?.loading);
  const [formCreate, setFormCreate] = useState({
    position: '',
    player: '',
  });

  const param = useParams<{ id: string; params: string }>();
  const handleClose = () => {
    setModalStadium(false);
  };

  const defaultFilter: FilterParams = {
    page: 0,
    size: 1000,
    sort: 'id,asc',
    type: 'name',
  };
  const handleFetchData = React.useCallback((params: StadiumListFilter) => {
    dispatch(getEntities(params));
  }, []);

  const [filter2, setFilter2] = React.useState(defaultFilter);

  useEffect(() => {
    handleFetchData(filter2);
  }, [filter2]);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });
  const options = [
    {
      value: 'name',
      label: translate('leagueApp.stadium.name'),
    },
  ];
  const stadiumList = useAppSelector(state => state.stadium?.entities);
  const handleCreateClub = (name: any) => {
    setModalStadium(false);
  };

  const rowSelection = {
    onChange(selectedPlayers: React.Key[], selectedRows) {
      setCreateStadium(selectedRows);
    },
    defaultSelectedRowKeys: createStadium?.map(i => i.id),
  };

  const columns = [
    {
      title: <Translate contentKey="leagueApp.stadium.name" />,
      dataIndex: 'name',
      key: 'name',
      align: 'start' as any,
    },
  ];

  return (
    <Modal
      visible={modalStadium}
      onCancel={handleClose}
      footer={null}
      width={900}
      wrapClassName="management-wrapper-modal"
      title={<Translate contentKey="leagueApp.match.leagueMatch.chooseStadium.title" />}
    >
      <Translate contentKey="leagueApp.match.leagueMatch.chooseStadium.question" />
      <div className="table-responsive">
        <ConfigProvider renderEmpty={() => <Empty description={translate('common.noDataTable')} />}>
          <Table
            bordered
            pagination={false}
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
            columns={columns}
            dataSource={stadiumList.content?.map((item, index) => ({ ...item, leagueId: +param.id, key: item.id }))}
            // totalElements={stadiumList?.totalElements || 0}
            key="id"
            loading={loading}
            // filter={filter2}
            scroll={{
              x: 'calc(400px + 50%)',
              y: 240,
            }}
            // onChange={params => setFilter2({ ...filter2, ...params })}
          />
        </ConfigProvider>
      </div>
      <Col>
        <Filter filter={filter2} onFilter={filterParams => setFilter2(filterParams)} options={options} />
      </Col>
      <Col span={24} style={{ marginTop: 24 }}>
        <div className="management-modal-footer">
          <Button color="secondary" onClick={handleClose}>
            <Translate contentKey="entity.action.cancel"></Translate>
          </Button>
          <Button id="jhi-confirm-delete-clubUniform" type="primary" style={{ marginLeft: 12 }} onClick={handleCreateClub}>
            <Translate contentKey="entity.action.apply"></Translate>
          </Button>
        </div>
      </Col>
    </Modal>
  );
};

export default StadiumDialog;
