import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, InputRef } from 'antd';
import { EditableContext } from './edit-table';
import { translate } from 'react-jhipster';

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  required: boolean;
  record: any;
  handleSave: (record: any) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  required,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();

      const newErrorImportDTOS = record.errorImport.filter(item => item.field !== dataIndex);

      const newRecord = { ...record, ...values, errorImport: newErrorImportDTOS };

      handleSave(newRecord);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: !!required,
            message: translate('entity.validation.required'),
          },
        ]}
      >
        <Input
          ref={inputRef}
          onPressEnter={() => {
            void save();
          }}
          onBlur={() => {
            void save();
          }}
        />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return (
    <td
      {...restProps}
      onClick={e => {
        if (editable) {
          setEditing(true);
          form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        }
      }}
      onBlur={e => {
        if (editable) {
          setEditing(false);
        }
      }}
    >
      {childNode}
    </td>
  );
};

export default EditableCell;
