import { translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import { getBodyAndParams } from './../../shared/util/helper';
import axios from 'axios';
import { AsyncThunkPayloadCreator, createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError, Pageable, UploadType } from 'app/shared/reducers/reducer.utils';
import { INotice, defaultValue, IAttachFile } from 'app/shared/model/notice.model';
import { NoticeFilterParams } from 'app/shared/model/filter.model';

interface NoticeState extends EntityState<INotice> {
  pageable?: Pageable<INotice>;
}

const initialState: NoticeState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/notices';

// Actions

export const getEntities = createAsyncThunk('notice/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<INotice[]>(requestUrl);
});

export const getEntity = createAsyncThunk<
  INotice,
  string | number,
  {
    rejectValue: PaymentValidationErrors;
  }
>(
  'notice/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/detail?id=${id}`;
    const result = await axios.get<INotice>(requestUrl);
    return result.data;
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'notice/create_entity',
  async (entity: INotice) => {
    return axios.post<INotice>(apiUrl, entity);
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'notice/update_entity',
  async (entity: INotice, thunkAPI) => {
    return axios.put<INotice>(`${apiUrl}/${entity.id}`, entity);
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'notice/partial_update_entity',
  async (entity: INotice, thunkAPI) => {
    const result = await axios.patch<INotice>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'notice/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<INotice>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const searchEntities = createAsyncThunk(
  'notice/search_entity',
  async (filter: NoticeFilterParams) => {
    const { body, params } = getBodyAndParams(filter);
    const result = await axios.post<Pageable<INotice>>(`${apiUrl}/search?page=${params?.page}&size=${params?.size}&sort=${params?.sort},desc`, body);
    return result.data;
  },
  { serializeError: serializeAxiosError }
);

export const uploadAttachFile = createAsyncThunk(
  'notice/upload_attach_files',
  async (files: IAttachFile[]) => {
    const result = await axios.all<IAttachFile>(
      files?.map(item => {
        if (item.file) {
          const data = new FormData();
          data.append('file', item.file);
          data.append('uploadType', UploadType.ATTACHMENT);
          return axios
            .post('/api/file/upload-file', data, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(res => ({ ...item, nameFileServer: res.data, url: undefined }));
        }
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(item);
          }, 300);
        });
      })
    );
    return result || [];
  },
  { serializeError: serializeAxiosError }
);

export const downloadAttachFileEntity = createAsyncThunk(
  'notice/upload_attach_files',
  async (id: number) => {
    const result = await axios.get(`/api/attachments/downloadFile?idAttachments=${id}&folder=ATTACHMENT`, {
      responseType: 'blob',
    });
    return result.data;
  },
  {
    serializeError: serializeAxiosError,
  }
);
// slice

export const NoticeSlice = createSlice({
  name: 'notice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
      })
      .addCase(uploadAttachFile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createEntity.fulfilled, (state, action) => {
        toast.success(translate('leagueApp.notice.created'));
        state.loading = false;
      })
      .addCase(updateEntity.fulfilled, (state, action) => {
        toast.success(translate('leagueApp.notice.updated'));
        state.loading = false;
      })
      .addCase(deleteEntity.fulfilled, state => {
        toast.success(translate('leagueApp.notice.deleted'));
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(searchEntities), (state, action) => {
        state.pageable = action.payload;
        state.loading = false;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
        state.loading = true;
      })
      .addMatcher(isRejected(createEntity, updateEntity), (state, action) => {
        toast.error(action.error.message);
        state.loading = false;
      });
  },
});

// Reducer
export default NoticeSlice.reducer;
