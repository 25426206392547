import React, { useState, useEffect } from 'react';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getEntities,
  getEntity,
  resetEntity,
  resetType,
  saveType,
  getStatus,
  updateEntity,
  deleteFavourite,
  addFavourite,
} from '../match/match.reducer';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Tag, Row, Col, Tooltip } from 'antd';
import { formatDate2, formatHour, formatDateHour } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { TYPE_OF_OPTION } from 'app/shared/util/constant';
import { PlusIcon } from 'app/shared/util/appIcon';
import { useHistory } from 'react-router-dom';
import { path } from '../routes';
import { searchEntities } from './question-answer.reducer';
import { MatchListFilter } from '../match/match.type';
import { AUTHORITIES, QUESTION_CATEGORY, QUESTION_CATEGORY_TABLE } from 'app/config/constants';
import Filter from 'app/modules/filter';
import MultiFilter from 'app/modules/MultiSearch';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import axios from 'axios';
import { CATEGORY_DATA_CODE } from 'app/shared/util/category-data-code.constant';

export const QuestionAndAnswer = () => {
  const dispatch = useAppDispatch();
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: '',
    type: 'category',
  };
  const { push } = useHistory();
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const isAdmin = hasAnyAuthority(authorities, [AUTHORITIES.ADMIN]);
  const pageable = useAppSelector(state => state.questionAnswerReducer?.pageable);
  const loading = useAppSelector(state => state.questionAnswerReducer?.loading);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const [qaType, setQaType] = useState([]);
  const handleQAType = async ({ code, lang }: any) => {
    const res = await axios.get<any>(`api/category-data-by-code?code=${code}&lang=${lang}`);
    const arr = [];
    res.data.dataTrans.map((item, index) => {
      arr.push({
        value: item.id,
        label: item.name,
      });
    });
    setQaType(arr);
  };

  useEffect(() => {
    handleQAType({ code: CATEGORY_DATA_CODE.QA_TYPE, lang: locale });
  }, [locale]);

  const handleFetchData = React.useCallback((params: MatchListFilter) => {
    dispatch(searchEntities(params));
    dispatch(resetType());
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const column = [
    {
      title: <Translate contentKey="leagueApp.questionAnswer.QAcategory" />,
      dataIndex: 'type',
      key: 'type',
      render: (value, record) => translate(`leagueApp.questionAnswer.QAtype.${value}`)
        
    },
    {
      title: <Translate contentKey="leagueApp.questionAnswer.title" />,
      dataIndex: 'title',
      key: 'title',
      width: '60%',
    },
    {
      title: <Translate contentKey="leagueApp.questionAnswer.status" />,
      dataIndex: 'status',
      key: 'status',
      render: (value, record) =>
        value === 0 ? translate('leagueApp.questionAnswer.waitingAnswer') : translate('leagueApp.questionAnswer.answerComplete'),
    },
    {
      title: <Translate contentKey="leagueApp.questionAnswer.createdDate" />,
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (value, record) => formatDateHour(value),
    },
  ];

  const options = [
    {
      value: 'category',
      label: translate('leagueApp.questionAnswer.QAcategory'),
    },
    {
      value: 'title',
      label: translate('leagueApp.notice.title'),
    },
    {
      value: 'createdDate',
      label: translate('leagueApp.notice.startDate'),
      type: TYPE_OF_OPTION.DATEGMT,
    },
  ];

  return (
    <div className="management-layout">
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading">
            <Translate contentKey="leagueApp.questionAnswer.home"></Translate>
          </h2>
          {!isAdmin && (
            <>
              <div className="management-layout-divider"></div>
              <Button
                className="rounded management-header-add-button"
                onClick={() => push(path.createQuestion)}
                icon={<PlusIcon />}
                type="primary"
              >
                <Translate contentKey="clubmng.add" />
              </Button>
            </>
          )}
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <Filter filter={filter} onFilter={onFilterToQueryString} multi options={options} />
        </Col>
      </div>
      <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
      <div className="table-responsive">
        <Table
          columns={column}
          dataSource={pageable?.content}
          onRow={item => ({
            onClick: () => push(`./question-and-answer/${item.id}/detail`),
          })}
          totalElements={pageable?.totalElements}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
    </div>
  );
};
