import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
// import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { Modal } from 'antd';
import { Button, Col } from 'antd';
// import Table from 'app/modules/table';
import { Table } from 'antd';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity, createEntity } from './league-recorder.reducer';
import { getEntities } from './get-free-recoder.reducer';
import axios from 'axios';
import { LeagueRecorderFilter } from './league-recorder.type';
import { useFilter } from 'app/shared/hooks/useFilter';
import Filter from 'app/modules/filter';
import { FilterParams } from 'app/shared/model/filter.model';
import { width } from '@fortawesome/free-solid-svg-icons/faCogs';
import { toast } from 'react-toastify';

export const LeagueRecorderCreateDialog = (props: any) => {
  const { modalUpdate, setModalUpdate, viewDetail } = props;
  const [loadModal, setLoadModal] = useState(false);
  const dispatch = useAppDispatch();
  const [dataPositions, setDataPositions] = useState([]);
  const [freePlayer, setfreePlayer] = useState([]);
  const [data, setData] = useState([{}]);
  const loading = useAppSelector(state => state.leagueRecorder.loading);
  const leagueEntity = useAppSelector(state => state.league.entity);
  const [formCreate, setFormCreate] = useState({
    position: '',
    player: '',
  });
  const updateSuccess = useAppSelector(state => state.clubUniform?.updateSuccess);
  const param = useParams<{ id: string; params: string }>();
  const handleClose = () => {
    setModalUpdate(false);
  };

  const defaultFilter: FilterParams = {
    page: 0,
    size: 1000,
    sort: 'id,asc',
    type: 'fullName',
  };
  const [filter2, setFilter2] = React.useState(defaultFilter);
  const handleFetchData = React.useCallback((params: LeagueRecorderFilter) => {
    dispatch(getEntities({ ...params, leagueId: +param.id, unitStandardId: leagueEntity?.leagueStandardDTO?.unitStandardId }));
  }, []);

  // useEffect(() => {
  //   setFilter2({ ...filter2, leagueId: +param.id, unitStandardId: leagueEntity?.unitStandardId });
  // }, [leagueEntity]);

  useEffect(() => {
    handleFetchData(filter2);
  }, [filter2]);

  const options = [
    {
      value: 'fullName',
      label: translate('leagueApp.leagueRecorder.recorderId'),
    },
  ];
  const GetLeagueRecorderList = useAppSelector(state => state.assignRecorderMultiSelect?.entities);
  const handleCreateClub = (name: any) => {
    const body = data.map((item: any, index) => ({ recorderId: item.id, leagueId: item.leagueId }));
    if (body[0]?.recorderId === undefined || body?.length === 0) {
      toast.warn(translate('leagueApp.leagueRecorder.required'));
      return;
    } else {
      dispatch(createEntity(body));
      setModalUpdate(false);
    }
    // body
  };

  const rowSelection = {
    onChange(selectedPlayers: React.Key[], selectedRows) {
      setData(selectedRows);
    },
  };

  const columns = [
    {
      title: <Translate contentKey="leagueApp.leagueRecorder.recorderId" />,
      dataIndex: 'fullName',
      key: 'fullName',
      align: 'start' as any,
    },
    {
      title: <Translate contentKey="userManagement.email" />,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: <Translate contentKey="leagueApp.leagueRecorder.multiSelect.unitName" />,
      dataIndex: 'unitStandardName',
      key: 'unitStandardName',
      align: 'left' as any,
    },
  ];

  return (
    <Modal
      visible={modalUpdate}
      onCancel={handleClose}
      footer={null}
      width={900}
      wrapClassName="management-wrapper-modal"
      title={<Translate contentKey="leagueApp.match.leagueMatch.chooseRecorder.title" />}
    >
      <Translate contentKey="leagueApp.match.leagueMatch.chooseRecorder.question" />
      <div className="table-responsive">
        <Table
          bordered
          pagination={false}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={GetLeagueRecorderList.map((item, index) => ({ ...item, leagueId: +param.id, recorderId: item.id, key: item.id }))}
          // totalElements={GetLeagueRecorderList.totalElements || 0}
          key="id"
          loading={loading}
          // filter={filter2}
          scroll={{
            x: '600px',
            y: 240,
          }}
          // onChange={params => setFilter2({ ...filter2, ...params })}
        />
      </div>
      <Col style={{ marginBottom: 24 }}>
        <Filter filter={filter2} onFilter={filterParams => setFilter2(filterParams)} options={options} />
      </Col>
      <Col span={24}>
        <div className="management-modal-footer">
          <Button color="secondary" onClick={handleClose}>
            <Translate contentKey="entity.action.cancel"></Translate>
          </Button>
          <Button id="jhi-confirm-delete-clubUniform" type="primary" onClick={handleCreateClub} style={{ marginLeft: 16 }}>
            <Translate contentKey="entity.action.apply"></Translate>
          </Button>
        </div>
      </Col>
    </Modal>
  );
};

export default LeagueRecorderCreateDialog;
