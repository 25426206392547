import React, { useState } from 'react';
import { translate, Translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Button, Col } from 'antd';
import { formatDate2, formatHour } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { resetType } from '../match/match.reducer';
import { MatchStatusContained } from 'app/modules/status-contained';
import dayjs from 'dayjs';
import { Link, useHistory } from 'react-router-dom';
import { path } from '../routes';
import Filter from 'app/modules/filter';
import { AUTHORITIES, NOTICE_CATEGORY } from 'app/config/constants';

import { PlusIcon, StarActive, StarInactive } from 'app/shared/util/appIcon';
import { IsFavorite } from 'app/shared/util/constant/favorite.constant';
import MultiFilter from 'app/modules/MultiSearch';
import { TYPE_OF_OPTION } from 'app/shared/util/constant';

import { MatchListFilter } from '../match/match.type';
import { searchEntities } from './notice.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const Notice = (props: any) => {
  const { idMatch } = props;
  const dispatch = useAppDispatch();

  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'createdDate',
    type: 'noticeCategory',
  };

  const handleFetchData = React.useCallback((params: MatchListFilter) => {
    dispatch(searchEntities(params));
    dispatch(resetType());
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const { push } = useHistory();

  const pageable = useAppSelector(state => state.notice?.pageable);
  const loading = useAppSelector(state => state.notice?.loading);
  const { authorities, unitStandardId } = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(authorities, [AUTHORITIES.ADMIN]);
  const isLeagueMaster = hasAnyAuthority(authorities, [AUTHORITIES.LEAGUE_MASTER]);

  const columns = [
    {
      title: <Translate contentKey="leagueApp.notice.noticeCategory" />,
      dataIndex: 'noticeCategory',
      key: 'noticeCategory',
      render: (value, record) => Object.values(NOTICE_CATEGORY).includes(value) && translate(`leagueApp.notice.noticeCategoryArr.${value}`),
    },
    {
      title: <Translate contentKey="leagueApp.notice.title" />,
      dataIndex: 'title',
      key: 'title',
      width: '80%',
    },
    {
      title: <Translate contentKey="leagueApp.notice.startDate" />,
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (value, record) => formatDate2(value),
    },
  ];

  const options = [
    {
      value: 'noticeCategory',
      label: translate('leagueApp.notice.noticeCategory'),
    },
    {
      value: 'title',
      label: translate('leagueApp.notice.title'),
    },

    {
      value: 'startDate',
      label: translate('leagueApp.notice.startDate'),
      type: TYPE_OF_OPTION.DATEGMT,
    },
  ];

  return (
    <div className="management-layout">
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading">
            <Translate contentKey="sidebar.notice">Leagues</Translate>
          </h2>
          {(isAdmin || isLeagueMaster) && (
            <>
              <div className="management-layout-divider"></div>
              <Button
                className="rounded management-header-add-button"
                onClick={() => push('./notice/create')}
                icon={<PlusIcon />}
                type="primary"
              >
                <Translate contentKey="clubmng.add" />
              </Button>
            </>
          )}
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <Filter filter={filter} onFilter={onFilterToQueryString} multi options={options} />
        </Col>
      </div>
      <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
      <div className="table-responsive">
        <Table
          columns={columns}
          onRow={item => ({
            onClick: () => push(`./notice/${item.id}/detail`),
          })}
          dataSource={pageable?.content}
          totalElements={pageable?.totalElements}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
    </div>
  );
};

export default Notice;
