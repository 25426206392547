import { Avatar, Col, Form, Input, List, Row, Select, Space } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import NumberField from 'app/modules/lm-input-number';
import { translate, Translate } from 'react-jhipster';
import querystring from 'query-string';
import { IClubUniform } from 'app/shared/model/club-uniform.model';
import { Link, useParams } from 'react-router-dom';
import { getDetail, getListTV, getWeather } from './match-over.reducer';
import { handleFocusFieldFail, uniformColor } from 'app/shared/util/entity-utils';
import Color from 'app/modules/color';
import { Referee } from '../match/MatchPreparation/container/referee';
import { useHistory } from 'react-router-dom';
import { getAVAR, getDetailReferee, getReferee, getReviewer, getTSG, getVAR } from '../match/match.reducer';
import './index.scss';
import TextField from 'app/modules/lm-input';

interface IFormValue {
  label: string;
  value: string | number;
  email?: string;
}

const MatchOver = () => {
  const [ownerUniforms, setOwnerUniforms] = useState<IClubUniform[]>([]);
  const [awayUniforms, setAwayUniforms] = useState<IClubUniform[]>([]);
  const [ownerGKUniforms, setOwnerGKUniforms] = useState<IClubUniform[]>([]);
  const [awayGKUniforms, setAwayGKUniforms] = useState<IClubUniform[]>([]);
  const disable = true;
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const referee: IFormValue[] = useAppSelector(state => state.match.referee);
  const avar: IFormValue[] = useAppSelector(state => state.match.avar);
  const reviewer: IFormValue[] = useAppSelector(state => state.match.reviewer);
  const varList: IFormValue[] = useAppSelector(state => state.match.var);
  const tsg: IFormValue[] = useAppSelector(state => state.match.tsg);
  const dataDetail = useAppSelector(state => state.MatchOver.dataDetail);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const listWeather = useAppSelector(state => state.match.listWeather);
  const mainMonitorId = Form.useWatch('mainMonitorId', form);
  const sub1MonitorId = Form.useWatch('sub1MonitorId', form);
  const sub2MonitorId = Form.useWatch('sub2MonitorId', form);
  const sub3MonitorId = Form.useWatch('sub3MonitorId', form);
  const varId = Form.useWatch('varId', form);
  const avarId = Form.useWatch('avarId', form);
  const tsgId = Form.useWatch('tsgId', form);
  const mainMatchMonitor = Form.useWatch('mainMatchMonitor', form);
  const evaluateId = Form.useWatch('evaluateId', form);
  const getUniforms = async (clubId, playerType, isOwner) => {
    const params = querystring.stringify({ clubId, playerType });
    const result = await axios.get(`/api/club-uniforms/find-club-uniforms-by-player-type?` + params);
    if (playerType === 0 && isOwner) {
      setOwnerUniforms(
        result.data
        // .map(item => {
        //   if (item.type === 1) {
        //     return item;
        //   }
        // })
        // .filter(ele => !!ele)
      );
    }
    if (playerType === 1 && isOwner) {
      setOwnerGKUniforms(
        result.data
        // .map(item => {
        //   if (item.type === 1) {
        //     return item;
        //   }
        // })
        // .filter(ele => !!ele)
      );
    }
    if (playerType === 0 && !isOwner) {
      setAwayUniforms(
        result.data
        // .map(item => {
        //   if (item.type === 2) {
        //     return item;
        //   }
        // })
        // .filter(ele => !!ele)
      );
    }
    if (playerType === 1 && !isOwner) {
      setAwayGKUniforms(
        result.data
        // .map(item => {
        //   if (item.type === 2) {
        //     return item;
        //   }
        // })
        // .filter(ele => !!ele)
      );
    }
  };
  useEffect(() => {
    dispatch(getDetail(id));
  }, []);
  useEffect(() => {
    Promise.all([dispatch(getWeather(locale)), dispatch(getListTV(locale))]);
  }, [locale]);
  useEffect(() => {
    if (!dataDetail || !dataDetail.id) {
      return;
    }
    Promise.all([
      getUniforms(dataDetail.ownerClubId, 0, true),
      getUniforms(dataDetail.ownerClubId, 1, true),
      getUniforms(dataDetail.awayClubId, 0, false),
      getUniforms(dataDetail.awayClubId, 1, false),
    ]).then(res => {
      form.setFieldsValue({
        ...dataDetail,
      });
    });
    if (dataDetail?.leagueId) {
      Promise.all([
        dispatch(getReferee({ leagueId: dataDetail?.leagueId, roles: 1 })),
        dispatch(getVAR({ leagueId: dataDetail?.leagueId, roles: 2 })),
        dispatch(getAVAR({ leagueId: dataDetail?.leagueId, roles: 3 })),
        dispatch(getTSG({ leagueId: dataDetail?.leagueId, roles: 4 })),
        dispatch(getReviewer({ leagueId: dataDetail?.leagueId, roles: 5 })),
      ]);
    }
    if (dataDetail?.matchId) {
      dispatch(getDetailReferee(dataDetail?.matchId)).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          const payload: any = res.payload;
          form.setFieldsValue({
            ...payload.data,
          });
        }
      });
    }
  }, [dataDetail]);

  const uniformList = (data: IClubUniform) => {
    return [
      {
        label: translate('leagueApp.match.shirt'),
        value: data?.shirtColorText2 ? ` ${data?.shirtColorText} / ${data?.shirtColorText2} ` : data?.shirtColorText,
      },
      {
        label: translate('leagueApp.match.short'),
        value: data?.shortColorText2 ? ` ${data?.shortColorText} / ${data?.shortColorText2} ` : data?.shortColorText,
      },
      {
        label: translate('leagueApp.match.sock'),
        value: data?.sockColorText2 ? ` ${data?.sockColorText} / ${data?.sockColorText2} ` : data?.sockColorText,
      },
    ];
  };
  return (
    <Row>
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          onFinishFailed={values => handleFocusFieldFail(values, 'general')}
          scrollToFirstError
          name="general"
          requiredMark={'optional'}
        >
          <div className="d-flex justify-content-between align-items-center record-layout-header">
            <p className="heading-20-bold neutral-1 mb-0">
              <Translate contentKey="common.general" />
            </p>
          </div>
          <p className="title-18-bold neutral-1 mb-3">
            <Translate contentKey="leagueApp.match.matchInformation" />
          </p>
          <Row gutter={[32, 32]} className="mb-3">
            <Col xl={6} lg={12} xs={24}>
              <Form.Item
                label={translate('leagueApp.match.spectator')}
                name="audienceTotal"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.match.missingValue'),
                  },
                ]}
              >
                <NumberField disabled={disable} placeholder={translate('leagueApp.match.placeholderSpectator')} negative={true} min={-1} />
              </Form.Item>
            </Col>
            <Col xl={6} lg={12} xs={24}>
              <Form.Item
                label={translate('leagueApp.match.weather')}
                name="weather"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.match.missingValue'),
                  },
                ]}
              >
                <Select placeholder={translate('leagueApp.match.selectWeather')} disabled={disable} className="custom-prefix-select">
                  {listWeather?.dataTrans?.map(item => (
                    <Select.Option value={item.code} key={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={6} lg={12} xs={24}>
              <Form.Item
                label={translate('leagueApp.match.temperature')}
                name="temperature"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.match.missingValue'),
                  },
                ]}
              >
                <NumberField
                  disabled={disable}
                  placeholder={translate('leagueApp.match.enterTemperature')}
                  negative={false}
                  min={-9999999}
                />
              </Form.Item>
            </Col>
            <Col xl={6} lg={12} xs={24}>
              <Form.Item
                label={translate('leagueApp.match.humidity')}
                name="humidity"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.match.missingValue'),
                  },
                ]}
              >
                <NumberField disabled={disable} placeholder={translate('leagueApp.match.enterhumidity')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col span={24}>
              <p className=" mb-3 title-18-bold neutral-1">
                <Translate contentKey="leagueApp.match.clubInformation" />
              </p>
            </Col>
            <Col lg={12} xs={24} style={{ borderRight: '1px solid #c8cfdb' }}>
              <Row className="py-2 py-4" gutter={[32, 32]}>
                <Col xs={12} className="d-flex">
                  <div className="title-info">
                    <div className="avatar">
                      <Avatar size={80} src={dataDetail?.ownerAvatar} />
                    </div>
                    <div className="title">
                      <div>
                        <Translate contentKey="leagueApp.match.squad.owner">Owner</Translate>
                        <Link to={`/club-detail/${dataDetail?.ownerClubId}/league-attend`}>
                          <div className="hot-link">{dataDetail?.ownerClub}</div>
                        </Link>
                        <span style={{ fontWeight: 500, fontSize: 16, color: '#292C38' }}>{dataDetail?.coachOwnerName}</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }} />
                <Col lg={12} xs={24}>
                  <Form.Item
                    label={translate('leagueApp.match.uniform')}
                    name="ownerClubUniformId"
                    rules={[
                      {
                        required: true,
                        message: translate('leagueApp.match.missingValue'),
                      },
                    ]}
                  >
                    <Select disabled={disable} className="custom-prefix-select" placeholder={translate('common.chooseUniform')}>
                      {ownerUniforms.map((item, index) => (
                        <Select.Option value={item?.id} key={item.id}>
                          {/* <Space direction="horizontal"> */}
                          <span>{translate('leagueApp.match.shirt')}:</span>
                          {item?.shirtColorText}
                          {'/'}
                          {item?.shirtColorText2}
                          {' , '}
                          <span>{translate('leagueApp.match.short')}:</span>
                          {item?.shortColorText} {'/'}
                          {item?.shortColorText2}
                          {' , '}
                          <span>{translate('leagueApp.match.sock')}:</span>
                          {item?.sockColorText} {'/'}
                          {item?.sockColorText2}
                          {/* </Space> */}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <List
                    itemLayout="horizontal"
                    dataSource={uniformList(ownerUniforms.filter(a => a.id === dataDetail?.ownerClubUniformId)[0])}
                    renderItem={item => (
                      <List.Item>
                        <Row gutter={[12, 12]} className="w-100" align="middle">
                          <Col xs={6}>{item.label} :</Col>
                          <Col xs={18}> {item.value}</Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </Col>
                <Col lg={12} xs={24}>
                  <Form.Item
                    label={translate('leagueApp.match.uniformGK')}
                    name="ownerClubUniformGkId"
                    rules={[
                      {
                        required: true,
                        message: translate('leagueApp.match.missingValue'),
                      },
                    ]}
                  >
                    <Select disabled={disable} className="custom-prefix-select" placeholder={translate('common.chooseUniformGK')}>
                      {ownerGKUniforms.map((item, index) => (
                        <Select.Option value={item?.id} key={item.id}>
                          {/* <Space direction="horizontal"> */}
                          <span>{translate('leagueApp.match.shirt')}:</span>
                          {item?.shirtColorText}
                          {'/'}
                          {item?.shirtColorText2}
                          {' , '}
                          <span>{translate('leagueApp.match.short')}:</span>
                          {item?.shortColorText} {'/'}
                          {item?.shortColorText2}
                          {' , '}
                          <span>{translate('leagueApp.match.sock')}:</span>
                          {item?.sockColorText} {'/'}
                          {item?.sockColorText2}
                          {/* </Space> */}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <List
                    itemLayout="horizontal"
                    dataSource={uniformList(ownerGKUniforms.filter(a => a.id === dataDetail?.ownerClubUniformGkId)[0])}
                    renderItem={item => (
                      <List.Item>
                        <Row gutter={[12, 12]} className="w-100" align="middle">
                          <Col xs={6}>{item.label} :</Col>

                          <Col xs={18}> {item.value}</Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={12} xs={24}>
              <Row className="py-2 py-4" gutter={[32, 32]}>
                <Col xs={12} className="d-flex">
                  <div className="title-info">
                    <div className="avatar">
                      <Avatar size={80} src={dataDetail?.awayAvatar} />
                    </div>
                    <div className="title">
                      <div>
                        <Translate contentKey="leagueApp.match.squad.away">Guest</Translate>
                        <Link to={`/club-detail/${dataDetail?.awayClubId}/league-attend`}>
                          <div className="hot-link">{dataDetail?.awayClub}</div>
                        </Link>
                        <span style={{ fontWeight: 500, fontSize: 16, color: '#292C38' }}>{dataDetail?.coachAwayName}</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}></Col>
                <Col lg={12} xs={24}>
                  <Form.Item
                    label={translate('leagueApp.match.awayUniform')}
                    name="awayClubUniformId"
                    rules={[
                      {
                        required: true,
                        message: translate('leagueApp.match.missingValue'),
                      },
                    ]}
                  >
                    <Select disabled={disable} className="custom-prefix-select" placeholder={translate('common.chooseUniform')}>
                      {awayUniforms.map((item, index) => (
                        <Select.Option value={item?.id} key={item.id}>
                          {/* <Space direction="horizontal"> */}
                          <span>{translate('leagueApp.match.shirt')}:</span>
                          {item?.shirtColorText}
                          {'/'}
                          {item?.shirtColorText2}
                          {' , '}
                          <span>{translate('leagueApp.match.short')}:</span>
                          {item?.shortColorText} {'/'}
                          {item?.shortColorText2}
                          {' , '}
                          <span>{translate('leagueApp.match.sock')}:</span>
                          {item?.sockColorText} {'/'}
                          {item?.sockColorText2}
                          {/* </Space> */}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <List
                    itemLayout="horizontal"
                    dataSource={uniformList(awayUniforms.filter(a => a.id === dataDetail?.awayClubUniformId)[0])}
                    renderItem={item => (
                      <List.Item>
                        <Row gutter={[12, 12]} className="w-100" align="middle">
                          <Col xs={6}>{item.label} :</Col>

                          <Col xs={18}> {item.value}</Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </Col>
                <Col lg={12} xs={24}>
                  <Form.Item
                    label={translate('leagueApp.match.awayUniformGK')}
                    name="awayClubUniformGkId"
                    rules={[
                      {
                        required: true,
                        message: translate('leagueApp.match.missingValue'),
                      },
                    ]}
                  >
                    <Select disabled={disable} className="custom-prefix-select" placeholder={translate('common.chooseUniformGK')}>
                      {awayGKUniforms.map((item, index) => (
                        <Select.Option value={item?.id} key={item.id}>
                          {/* <Space direction="horizontal"> */}
                          <span>{translate('leagueApp.match.shirt')}:</span>
                          {item?.shirtColorText}
                          {'/'}
                          {item?.shirtColorText2}
                          {' , '}
                          <span>{translate('leagueApp.match.short')}:</span>
                          {item?.shortColorText} {'/'}
                          {item?.shortColorText2}
                          {' , '}
                          <span>{translate('leagueApp.match.sock')}:</span>
                          {item?.sockColorText} {'/'}
                          {item?.sockColorText2}
                          {/* </Space> */}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <List
                    itemLayout="horizontal"
                    dataSource={uniformList(awayGKUniforms.filter(a => a.id === dataDetail?.awayClubUniformGkId)[0])}
                    renderItem={item => (
                      <List.Item>
                        <Row gutter={[12, 12]} className="w-100" align="middle">
                          <Col xs={6}>{item.label} :</Col>
                          <Col xs={18}> {item.value}</Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div>
            <Row>
              <Translate contentKey="leagueApp.match.station" />
            </Row>
            <Form.Item
              name="lstBoarCast"
              style={{ marginTop: '32px' }}
              label={translate('leagueApp.match.stationList')}
              rules={[
                {
                  required: true,
                  message: translate('leagueApp.match.missingValue'),
                },
              ]}
            >
              <Select disabled={disable} mode="multiple" className="special-select" open={false} showSearch={false} />
            </Form.Item>
          </div>
          <div className="referee">
            <Row gutter={12} style={{ marginBottom: '12px' }}>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                <Form.Item name="mainMonitorId" label={translate('leagueApp.match.referee.referee1')}>
                  <Select
                    disabled={disable}
                    placeholder={translate('leagueApp.match.referee.chooseReferee1')}
                    style={{ width: '100%' }}
                    className="custom-prefix-select"
                    allowClear
                    suffixIcon={<SelectArrowDown />}
                  >
                    <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                    <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                    {referee?.map(item => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                <Form.Item name="sub1MonitorId" label={translate('leagueApp.match.referee.assistant11')}>
                  <Select
                    placeholder={translate('leagueApp.match.referee.chooseAssistant11')}
                    style={{ width: '100%' }}
                    className="custom-prefix-select"
                    allowClear
                    suffixIcon={<SelectArrowDown />}
                    disabled={disable}
                  >
                    <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                    <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                    {referee?.map(item => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                <Form.Item name="sub2MonitorId" label={translate('leagueApp.match.referee.assistant21')}>
                  <Select
                    placeholder={translate('leagueApp.match.referee.chooseAssistant21')}
                    style={{ width: '100%' }}
                    className="custom-prefix-select"
                    allowClear
                    suffixIcon={<SelectArrowDown />}
                    disabled={disable}
                  >
                    <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                    <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                    {referee?.map(item => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                <Form.Item name="sub3MonitorId" label={translate('leagueApp.match.referee.refereeSubstitute1')}>
                  <Select
                    placeholder={translate('leagueApp.match.referee.chooseSubstitute1')}
                    style={{ width: '100%' }}
                    className="custom-prefix-select"
                    allowClear
                    suffixIcon={<SelectArrowDown />}
                    disabled={disable}
                  >
                    <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                    <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                    {referee?.map(item => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12} style={{ marginBottom: 12 }}>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                {mainMonitorId === -2 && (
                  <Form.Item name="mainMonitorName">
                    <TextField disabled={disable} placeholder={translate('leagueApp.match.referee.refereePlaceholder')} />
                  </Form.Item>
                )}
              </Col>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                {sub1MonitorId === -2 && (
                  <Form.Item name="sub1MonitorName">
                    <TextField disabled={disable} placeholder={translate('leagueApp.match.referee.assistRef1Placeholder')} />
                  </Form.Item>
                )}
              </Col>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                {sub2MonitorId === -2 && (
                  <Form.Item name="sub2MonitorName">
                    <TextField disabled={disable} placeholder={translate('leagueApp.match.referee.assistRef2Placeholder')} />
                  </Form.Item>
                )}
              </Col>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                {sub3MonitorId === -2 && (
                  <Form.Item name="sub3MonitorName">
                    <TextField disabled={disable} placeholder={translate('leagueApp.match.referee.resRefPlaceholder')} />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row gutter={12} style={{ marginBottom: '12px' }}>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                <Form.Item name="varId" label={'VAR'}>
                  <Select
                    placeholder={translate('leagueApp.match.referee.chooseVar')}
                    style={{ width: '100%' }}
                    filterOption={true}
                    className="custom-prefix-select"
                    allowClear
                    suffixIcon={<SelectArrowDown />}
                    disabled={disable}
                  >
                    <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                    <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                    {varList?.map(item => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                <Form.Item name="avarId" label={'AVAR'}>
                  <Select
                    placeholder={translate('leagueApp.match.referee.chooseAVR')}
                    style={{ width: '100%' }}
                    className="custom-prefix-select"
                    allowClear
                    suffixIcon={<SelectArrowDown />}
                    disabled={disable}
                  >
                    <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                    <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                    {avar.map(item => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                <Form.Item name="tsgId" label={'TSG'}>
                  <Select
                    placeholder={translate('leagueApp.match.referee.chooseTSG')}
                    style={{ width: '100%' }}
                    className="custom-prefix-select"
                    allowClear
                    suffixIcon={<SelectArrowDown />}
                    disabled={disable}
                  >
                    <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                    <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                    {tsg.map(item => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                <Form.Item name="recordName" label={<Translate contentKey="leagueApp.match.referee.recorder" />}>
                  <Input style={{ width: '100%' }} disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12} style={{ marginBottom: 12 }}>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                {varId === -2 && (
                  <Form.Item name="varName">
                    <TextField disabled={disable} placeholder={translate('leagueApp.match.referee.varPlaceholder')} />
                  </Form.Item>
                )}
              </Col>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                {avarId === -2 && (
                  <Form.Item name="avarName">
                    <TextField disabled={disable} placeholder={translate('leagueApp.match.referee.avarPlaceholder')} />
                  </Form.Item>
                )}
              </Col>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                {tsgId === -2 && (
                  <Form.Item name="tsgName">
                    <Input disabled={disable} />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row gutter={12} style={{ marginBottom: '12px' }}>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                <Form.Item name="mainMatchMonitor" label={translate('leagueApp.match.referee.mainmonitor')}>
                  <Select
                    placeholder={translate('leagueApp.match.referee.chooseMonitor')}
                    style={{ width: '100%' }}
                    filterOption={true}
                    className="custom-prefix-select"
                    allowClear
                    suffixIcon={<SelectArrowDown />}
                    disabled={disable}
                  >
                    <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                    <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                    {reviewer.map(item => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                <Form.Item name="evaluateId" label={translate('leagueApp.match.referee.refereerating')}>
                  <Select
                    placeholder={translate('leagueApp.match.referee.chooseRating')}
                    style={{ width: '100%' }}
                    className="custom-prefix-select"
                    suffixIcon={<SelectArrowDown />}
                    disabled={disable}
                  >
                    <Select.Option value={-2}>{translate('leagueApp.match.typeYourSelf')}</Select.Option>
                    <Select.Option value={-1}>{translate('leagueApp.match.none')}</Select.Option>
                    {reviewer.map(item => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12} style={{ marginBottom: 12 }}>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                {mainMatchMonitor === -2 && (
                  <Form.Item name="mainMatchMonitorName">
                    <TextField disabled={disable} placeholder={translate('leagueApp.match.referee.mainMoniPlaceholder')} />
                  </Form.Item>
                )}
              </Col>
              <Col xl={6} lg={8} md={12} xs={24} className="box">
                {evaluateId === -2 && (
                  <Form.Item name="evaluateName">
                    <TextField disabled={disable} placeholder={translate('leagueApp.match.referee.refRatingPlaceholder')} />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default MatchOver;
