import React from 'react';
import { translate, Translate } from 'react-jhipster';

interface Props {
  classify: number;
}

export const LeagueTypeContainer = ({ classify }: Props) => {
  if (classify === 0 || classify === null) {
    return <div></div>;
  }
  if (classify === 1) {
    return <div className="table-custom-status">{translate('leagueApp.leagueStandard.league')}</div>;
  }
  if (classify === 2) {
    return <div className="table-custom-status">{translate('leagueApp.leagueStandard.cup')}</div>;
  }
};
