import { PositionInMatch } from 'app/shared/util/constant';

export const LIST_POSITION = [
  // Sân trái
  { value: PositionInMatch.NEAR_CONNER_R_OWNER, className: 'gk-r' }, // Gần góc R
  { value: PositionInMatch.OUT_SIDE_PA_R_OWNER, className: 'gk-out-par' }, // Ngoài PA R
  { value: PositionInMatch.PA_R_OWNER, className: 'gk-par' }, // PA R
  { value: PositionInMatch.GA_R_OWNER, className: 'gk-gar' }, // GAR
  { value: PositionInMatch.CENTER_GA_OWNER, className: 'gk-ga-center' }, // GA giữa
  { value: PositionInMatch.GA_L_OWNER, className: 'gk-gal' }, // GA L
  { value: PositionInMatch.PA_L_OWNER, className: 'gk-pal' }, // PA L
  { value: PositionInMatch.OUTSIDE_PA_L_OWNER, className: 'gk-out-pal' }, // Ngoài PA L
  { value: PositionInMatch.NEAR_CONNER_L_OWNER, className: 'gk-l' }, // Gần góc L
  { value: PositionInMatch.CENTER_PA_OWNER, className: 'gk-pag' }, // PA giữa
  { value: PositionInMatch.FPA_R_OWNER, className: 'lb-out-r' }, // NGoài R
  { value: PositionInMatch.CENTER_FPA_OWNER, className: 'lb-center' }, // Giữa vòng cấm địa
  { value: PositionInMatch.FPA_L_OWNER, className: 'lb-out-l' }, // Ngoài L
  { value: PositionInMatch.MF_R_OWNER, className: 'amr-mfl' }, // MF R
  { value: PositionInMatch.CENTER_MF_OWNER, className: 'amr-mfg' }, // MF Giữa
  { value: PositionInMatch.MF_L_OWNER, className: 'amr-mfr' }, // MF L
  { value: PositionInMatch.HALFLINE_R_OWNER, className: 'amr-mfr-top' }, // R Biên Haftline
  { value: PositionInMatch.HALFLINE_L_OWNER, className: 'amr-mfl-top' }, // L Biên Haftline
  {value: PositionInMatch.FPA_OWNER, className: 'fpa_owner'},  // vòng cấm điạ
  // Trung tâm
  { value: PositionInMatch.CENTER, className: 'center' }, // Trung Tâm
  // Sân phải
  { value: PositionInMatch.NEAR_CONNER_R_AWAY, className: 'st-r' }, // Gần góc R
  { value: PositionInMatch.OUT_SIDE_PA_R_AWAY, className: 'st-out-pa-r' }, // Ngoài PA R
  { value: PositionInMatch.PA_R_AWAY, className: 'st-pa-r' }, // PA R
  { value: PositionInMatch.GA_R_AWAY, className: 'st-ga-r' }, // GAR
  { value: PositionInMatch.CENTER_GA_AWAY, className: 'st-ga-center' }, // GA giữa
  { value: PositionInMatch.GA_L_AWAY, className: 'st-ga-l' }, // GA L
  { value: PositionInMatch.PA_L_AWAY, className: 'st-pa-l' }, // PA L
  { value: PositionInMatch.OUTSIDE_PA_L_AWAY, className: 'st-out-pa-l' }, // Ngoài PA L
  { value: PositionInMatch.NEARCONNER_L_AWAY, className: 'st-l' }, // Gần góc L
  { value: PositionInMatch.CENTER_PA_AWAY, className: 'st-top-center' }, // PA giữa
  { value: PositionInMatch.FPA_R_AWAY, className: 'wl-out-r' }, // NGoài R
  { value: PositionInMatch.CENTER_FPA_AWAY, className: 'wl-center' }, // Giữa vòng cấm địa
  { value: PositionInMatch.FPA_L_AWAY, className: 'wl-out-l' }, // Ngoài L
  { value: PositionInMatch.MF_R_AWAY, className: 'am-r-r' }, // MF R
  { value: PositionInMatch.CENTER_MF_AWAY, className: 'am-r-center' }, // MF Giữa
  { value: PositionInMatch.MF_L_AWAY, className: 'am-r-l' }, // MF L
  { value: PositionInMatch.HALFLINE_R_AWAY, className: 'am-r-hl' }, // R Biên Haftline
  { value: PositionInMatch.HALFLINE_L_AWAY, className: 'am-l-hl' }, // L Biên Haftline
  {value: PositionInMatch.FPA_AWAY, className: 'fpa_away'}, // vòng cấm điạ
];
