import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Monitor from './monitor';
import MonitorDetail from './monitor-detail';
import MonitorUpdate from './monitor-update';
import MonitorDeleteDialog from './monitor-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MonitorUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MonitorUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MonitorDetail} />
      <ErrorBoundaryRoute path={match.url} component={Monitor} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={MonitorDeleteDialog} />
  </>
);

export default Routes;
