import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Form, Input, Modal, Button } from 'antd';
import NumberField from 'app/modules/lm-input-number';
import TextField from 'app/modules/lm-input';
import { toast } from 'react-toastify';
import { createEntity, getEntity, updateEntity, clearMsgUpdate } from './stadium.reducer';
import './stadium.scss';
import { CapacityIcon, LocationIcon } from 'app/shared/util/appIcon';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';

export const StadiumAddDialog = (props: any) => {
  const { showDialog, setShowDialog, entityId, setEntityId, updateFilter } = props;
  const dispatch = useAppDispatch();

  const updateSuccess = useAppSelector(state => state.stadium.updateSuccess);
  const createSuccess = useAppSelector(state => state.stadium.createSuccess);
  const stadiumEntity = useAppSelector(state => state.stadium.entity);

  // useEffect(() => {
  //   if (createSuccess) {
  //     setShowDialog(false);
  //   }
  // }, [createSuccess]);

  // useEffect(() => {
  //   if (updateSuccess) {
  //     setShowDialog(false);
  //     setEntityId(null);
  //   }
  // }, [updateSuccess]);

  const [isNew, setIsNew] = useState(true);

  const [entitySave, setEntitySave] = useState({
    name: '',
    address: '',
    total: null,
  });

  useEffect(() => {
    const c = !entityId;
    setIsNew(c);
    if (!c) {
      handleUpdate(entityId);
    }
  }, [entityId]);

  // useEffect(() => {
  //   if (updateSuccess) {
  //     if (isNew) {
  //       toast.success(translate('leagueApp.stadium.create.success'));
  //       dispatch(clearMsgUpdate(updateSuccess));
  //       handleReset();
  //     } else {
  //       toast.success(translate('leagueApp.stadium.update.success'));
  //       dispatch(clearMsgUpdate(updateSuccess));
  //       handleReset();
  //     }
  //     // sortEntities();
  //   }
  // }, [updateSuccess]);

  useEffect(() => {
    if (!entityId) return;
    if (stadiumEntity && stadiumEntity.id) {
      form.setFieldsValue({
        ...stadiumEntity,
      });
      setEntitySave({ ...stadiumEntity });
      setShowDialog(true);
    }
  }, [stadiumEntity, entityId]);

  /** save action **/
  const handleValidSubmit = e => {
    let data: any = {};
    if (!isNew && stadiumEntity.id) {
      data = {
        ...stadiumEntity,
      };
    }
    data.name = form.getFieldValue('name');
    data.address = form.getFieldValue('address');
    data.total = form.getFieldValue('total');
    if (!isNew && data.id) {
      dispatch(updateEntity(data)).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          toast.success(translate('leagueApp.stadium.updated.success'));
          setShowDialog(false);
          updateFilter();
        } else if (res.meta.requestStatus === 'rejected') {
          toast.error(translate('leagueApp.stadium.updated.fail'));
          setShowDialog(true);
        }
      });
    } else {
      dispatch(createEntity(data)).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          toast.success(translate('leagueApp.stadium.created.success'));
          setShowDialog(false);
          updateFilter();
        } else if (res.meta.requestStatus === 'rejected') {
          toast.error(translate('leagueApp.stadium.created.fail'));
          setShowDialog(true);
        }
      });
    }
  };

  const [form] = Form.useForm();

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };

  /** new **/
  const handleReset = () => {
    setEntitySave({
      name: '',
      address: '',
      total: null,
    });
    form.setFieldsValue({
      name: '',
      address: '',
      total: null,
    });

    setEntityId(null);
  };

  /** update **/
  const handleUpdate = id => {
    dispatch(getEntity(id));
  };

  function closeDialog() {
    setShowDialog(false);
    setEntityId(false);
  }

  return (
    <React.Fragment>
      <Modal
        visible={showDialog}
        onCancel={closeDialog}
        className="popup-create-stadium"
        title={
          isNew ? <Translate contentKey="leagueApp.stadium.create.dialog" /> : <h4 style={{ fontWeight: 'bold' }}>{stadiumEntity?.name}</h4>
        }
        wrapClassName="management-wrapper-modal"
        footer={null}
      >
        <Form
          form={form}
          onFinishFailed={values => {
            handleFocusFieldFail(values);
          }}
          scrollToFirstError
          name="control-hooks"
          onFinish={handleValidSubmit}
          layout="vertical"
          requiredMark={'optional'}
        >
          <Form.Item
            name="name"
            label={<h6>{translate('leagueApp.stadium.popup.name')}</h6>}
            rules={[
              {
                required: true,
                message: translate('leagueApp.stadium.name.required'),
              },
            ]}
          >
            <TextField placeholder={translate('leagueApp.stadium.name.placeholder')} />
          </Form.Item>
          <Form.Item
            name="address"
            label={<h6>{translate('leagueApp.stadium.popup.address')}</h6>}
            rules={[
              {
                required: true,
                message: translate('leagueApp.stadium.address.required'),
              },
            ]}
          >
            <TextField placeholder={translate('leagueApp.stadium.address.placeholder')} prefix={<LocationIcon />} />
          </Form.Item>

          <Form.Item
            name="total"
            label={<h6>{translate('leagueApp.stadium.popup.total')}</h6>}
            rules={[
              {
                required: true,
                message: translate('leagueApp.stadium.total.required'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || 0 < value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(translate('leagueApp.stadium.maxTotal')));
                },
              }),
            ]}
          >
            <NumberField negative={true} placeholder={translate('leagueApp.stadium.total.placeholder')} prefix={<CapacityIcon />} />
          </Form.Item>
          <div className="management-modal-footer">
            <Button key="back" onClick={closeDialog}>
              <Translate contentKey="register.form.close" />
            </Button>
            <Button key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }}>
              <Translate contentKey="settings.form.button" />
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default StadiumAddDialog;
