import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IPlayerClub, defaultValue } from 'app/shared/model/player-club.model';

const initialState: EntityState<IPlayerClub> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  deleteSuccess: false,
  createSuccess: false,
};

const apiUrl = 'api/player-clubs';
const apiDeleteUrl = 'api/player-clubs/deleteMap';
const apiImageUrl = 'api/file';
const apiSearchUrl = 'api/players/search';
// Actions

export const getEntities = createAsyncThunk('playerClub/fetch_entity_list', async ({ page, size, sort, clubId, ...res }: IQueryParams) => {
  const requestUrl = `${apiSearchUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : '?'}`;
  return axios.post<IPlayerClub[]>(requestUrl, {...res, clubId });
});

// export const getEntities = createAsyncThunk(
//   'player-clubs/get',
//   async (playerClub: any) => {
//     return axios.get<any>(`${apiUrl}/${playerClub.id}`);
//   },
//   { serializeError: serializeAxiosError }
// );
export const getEntity = createAsyncThunk(
  'playerClub/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IPlayerClub>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'playerClub/create_entity',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<IPlayerClub>(apiUrl, entity);
    thunkAPI.dispatch(getEntities({ sort: 'asc', clubId: result?.data[0]?.clubId}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getAvatarPlayer = createAsyncThunk('club/leagueAttend', async ({ fileName, uploadType }: any) => {
  const requestUrl = `${apiImageUrl}/get-file-minio${fileName ? `?fileName=${fileName}&uploadType=${uploadType}` : ''}`;
  return axios.get<any>(requestUrl);
});

export const updateEntity = createAsyncThunk(
  'playerClub/update_entity',
  async (entity: IPlayerClub, thunkAPI) => {
    const result = await axios.put<IPlayerClub>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'playerClub/partial_update_entity',
  async (entity: IPlayerClub, thunkAPI) => {
    const result = await axios.patch<IPlayerClub>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'playerClub/delete_entity',
  async ({ playerId, clubId }: any, thunkAPI) => {
    const requestUrl = `${apiUrl}/deleteMap?playerId=${playerId}&clubId=${clubId}`;
    const result = await axios.post<IPlayerClub>(requestUrl);
    // thunkAPI.dispatch(getEntities({id: clubId}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const PlayerClubSlice = createEntitySlice({
  name: 'playerClub',
  initialState,
  reducers: {
    clearMsgUpdate: (state, action) => void (state.updateSuccess = null),
    clearMsgDelete: (state, action) => void (state.deleteSuccess = null),
    clearMsgCreate: (state, action) => void (state.createSuccess = null),
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.deleteSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.createSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, clearMsgCreate, clearMsgDelete, clearMsgUpdate } = PlayerClubSlice.actions;

// Reducer
export default PlayerClubSlice.reducer;
