import React, { useMemo } from 'react';
import { Table, Tag, Tooltip } from 'antd';
import { translate } from 'react-jhipster';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useAppSelector } from 'app/config/store';
import { get, isNumber } from 'lodash';
import { actionRecord } from 'app/shared/util/constant';
import { IMatch } from 'app/shared/model/match.model';
import styled from 'styled-components';
import { ISheetRecord, MatchRecordState } from '../../match-record.reducer';
import { AWAY, OWNER } from 'app/entities/penalty-shoot-out/constant.penalty-shoot-out';
import { IMatchTime } from 'app/shared/model/match-time.model';

const getRowSpanValue = (index: number, length: number, numberOfMainPlayer: number) => {
  if (index === 0) {
    return <div>{translate('matchOver.recordTableData.main')}</div>;
  }

  if (index === numberOfMainPlayer) {
    return <div>{translate('matchOver.recordTableData.sub')}</div>;
  }
  if (index > numberOfMainPlayer) {
    return <div>{translate('matchOver.recordTableData.main')}</div>;
  }
  if (index > 0) {
    return <div>{translate('matchOver.recordTableData.main')}</div>;
  }
};

const getRowSpan = (index: number, length: number, numberOfMainPlayer: number) => {
  if (index === 0) {
    return numberOfMainPlayer;
  }

  if (index === numberOfMainPlayer) {
    return length - numberOfMainPlayer;
  }
  return 0;
};

const ArrayPosition = ['', 'GK', 'DF', 'MF', 'FW'];

const BanCard = styled.div`
  opacity: ${props => (props.banType ? '1' : '0')};

  height: 100%;
  width: 60%;
  margin: auto;
  background-color: ${props => (props.banType > 2 ? `red` : `yellow`)};
  border: 1px solid black;
  box-shadow: ${props => props.banType > 1 && props.banType < 4 && `yellow -5px -8px, black -5px -8px 0px 0.5px`};
`;

const SheetRecord = ({ onClickEvent }) => {
  const matchBasicInfoEntity: IMatch = useAppSelector(state => state.match.entity);
  const { sheetRecordHomeTeam, sheetRecordAwayTeam, ownerLineUp, awayLineUp }: MatchRecordState = useAppSelector(
    state => state.matchRecord
  );
  const matchTimes: IMatchTime = useAppSelector(state => state.matchTime?.entity);

  const items = React.useMemo(() => {
    if (sheetRecordHomeTeam?.length > sheetRecordAwayTeam?.length) return sheetRecordHomeTeam;
    return sheetRecordAwayTeam;
  }, [sheetRecordAwayTeam, sheetRecordHomeTeam]);

  const handleClickEvent = React.useCallback(
    (action, index, player: ISheetRecord, type) => {
      if (index >= matchBasicInfoEntity?.numberOfPlayers || player.ban || player.warning > 1) {
        return;
      }

      onClickEvent(
        action,
        (type === OWNER ? ownerLineUp : awayLineUp).find(item => item.playerId === player.playerId)
      );
    },
    [ownerLineUp, awayLineUp, matchTimes]
  );

  const columns = [
    {
      title: matchBasicInfoEntity.ownerClubName,
      width: 60,
      dataIndex: 'home',
      key: 'home',
      children: [
        {
          title: translate('matchOver.recordTableData.gk'),
          width: '33px',
          dataIndex: 'gk',
          align: 'center',
          key: 'gk',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.goalKick')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.GOAL_KICK, index, get(sheetRecordHomeTeam, index), OWNER)}
                className={`w-100 h-100  ${
                  get(sheetRecordHomeTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordHomeTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordHomeTeam, `${index}.goalKick`) || ''}
              </div>
            </Tooltip>
          ),
        },
        {
          title: translate('matchOver.recordTableData.ckfk'),
          width: 33,
          dataIndex: 'ckfk',
          key: 'ckfk',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.cornerKick')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.CORNER_KICK, index, get(sheetRecordHomeTeam, index), OWNER)}
                className={`w-100 h-100  ${
                  get(sheetRecordHomeTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordHomeTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordHomeTeam, `${index}.cornerKick`) || ''}
              </div>
            </Tooltip>
          ),
        },
        {
          title: translate('matchOver.recordTableData.fk'),
          width: 33,
          align: 'center',

          dataIndex: 'fk',
          key: 'fk',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.freeKick')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.FREE_KICK, index, get(sheetRecordHomeTeam, index), OWNER)}
                className={`w-100 h-100  ${
                  get(sheetRecordHomeTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordHomeTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordHomeTeam, `${index}.freeKick`) || ''}
              </div>
            </Tooltip>
          ),
        },
        {
          title: translate('matchOver.recordTableData.pk'),
          width: 33,
          dataIndex: 'pk',
          key: 'pk',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.penaltyKick')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.PENALTY_KICK, index, get(sheetRecordHomeTeam, index), OWNER)}
                className={`w-100 h-100  ${
                  get(sheetRecordHomeTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordHomeTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordHomeTeam, `${index}.penaltyKick`) || ''}
              </div>
            </Tooltip>
          ),
        },
        {
          title: translate('matchOver.recordTableData.st'),
          width: 33,
          dataIndex: 'st',
          key: 'st',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.shooting')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.SHOOTING, index, get(sheetRecordHomeTeam, index), OWNER)}
                className={`w-100 h-100  ${
                  get(sheetRecordHomeTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordHomeTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordHomeTeam, `${index}.shoot`) || ''}
              </div>
            </Tooltip>
          ),
        },

        {
          title: translate('matchOver.recordTableData.fc'),
          width: 33,
          dataIndex: 'fc',
          key: 'fc',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('recordRoom.lookupPlayer.foul')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.FOUL, index, get(sheetRecordHomeTeam, index), OWNER)}
                className={`w-100 h-100  ${
                  get(sheetRecordHomeTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordHomeTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordHomeTeam, `${index}.foul`) || ''}
              </div>
            </Tooltip>
          ),
        },
        {
          title: translate('matchOver.recordTableData.fs'),
          width: 33,
          dataIndex: 'fs',
          key: 'fs',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('recordRoom.lookupPlayer.bloodFoul')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.FOUL_SUFFERED_OWNER, index, get(sheetRecordHomeTeam, index), OWNER)}
                className={`w-100 h-100  ${
                  get(sheetRecordHomeTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordHomeTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordHomeTeam, `${index}.beFouled`) || ''}
              </div>
            </Tooltip>
          ),
        },
        {
          title: translate('matchOver.recordTableData.of'),
          width: 33,
          dataIndex: 'offSide',
          key: 'offSide',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.offSite')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.OFFSIDE, index, get(sheetRecordHomeTeam, index), OWNER)}
                className={`w-100 h-100 ${
                  get(sheetRecordHomeTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordHomeTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordHomeTeam, `${index}.offSide`) || ''}
              </div>
            </Tooltip>
          ),
        },
        {
          title: translate('matchOver.recordTableData.score'),
          width: 33,
          dataIndex: 'allScore',
          key: 'allScore',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('matchOver.recordTableData.score')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.SCORE, index, get(sheetRecordHomeTeam, index), OWNER)}
                className={`w-100 h-100  ${
                  get(sheetRecordHomeTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordHomeTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordHomeTeam, `${index}.allScore`) || ''}
              </div>
            </Tooltip>
          ),
        },

        {
          title: translate('matchOver.recordTableData.foul'),
          width: 33,
          dataIndex: 'foul',
          key: 'foul',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.yellowCard')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.WARNING, index, get(sheetRecordHomeTeam, index), OWNER)}
                className={`w-100 h-100 d-flex justify-content-around ${
                  get(sheetRecordHomeTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordHomeTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordHomeTeam, `${index}.warning`) ? (
                  <Tag color="gold" className="m-0">
                    {get(sheetRecordHomeTeam, `${index}.warning`)}
                  </Tag>
                ) : (
                  ''
                )}
                {get(sheetRecordHomeTeam, `${index}.ban`) ? (
                  <Tag color="error" className="m-0">
                    {get(sheetRecordHomeTeam, `${index}.ban`)}
                  </Tag>
                ) : (
                  ''
                )}
              </div>
            </Tooltip>
          ),
        },

        {
          title: translate('matchOver.recordTableData.assist'),
          width: 33,
          dataIndex: 'supportScore',
          key: 'supportScore',
          align: 'center' as any,
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.assist')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.ASSIST, index, get(sheetRecordHomeTeam, index), OWNER)}
                className={`w-100 h-100  ${
                  get(sheetRecordHomeTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordHomeTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordHomeTeam, `${index}.supportScore`) || ''}
              </div>
            </Tooltip>
          ),
        },

        {
          title: translate('matchOver.recordTableData.change'),
          width: 33,
          dataIndex: 'change',
          key: 'change',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.changePlayer')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.CHANGE_OWNER, index, get(sheetRecordHomeTeam, index), OWNER)}
                className={`w-100 h-100  ${
                  get(sheetRecordHomeTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordHomeTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                <>
                  {get(sheetRecordHomeTeam, `${index}.inOut`) === 1 && <CaretUpOutlined />}
                  {get(sheetRecordHomeTeam, `${index}.inOut`) === 2 || get(sheetRecordHomeTeam, `${index}.inOut`) === 0 ? (
                    <CaretDownOutlined />
                  ) : (
                    ''
                  )}
                </>
              </div>
            </Tooltip>
          ),
        },
        {
          title: translate('matchOver.recordTableData.playerName'),
          width: 75,
          dataIndex: 'playerName',
          key: 'playerName',
          render(_, record, index) {
            return (
              <Tooltip title={get(sheetRecordHomeTeam, `${index}.playerDTO.koName`)} color={'blue'}>
                <span className="line-clamp-1">{get(sheetRecordHomeTeam, `${index}.playerDTO.koName`)}</span>
              </Tooltip>
            );
          },
        },
        {
          title: translate('matchOver.recordTableData.location'),
          width: 55,
          dataIndex: 'location',
          key: 'location',
          align: 'center',
          render: (_, record, index) => (
            <span style={{ whiteSpace: 'nowrap' }}>
              {get(ArrayPosition, get(sheetRecordHomeTeam, `${index}.positionType`)) || translate('common.sub')}
            </span>
          ),
        },
        {
          title: translate('matchOver.recordTableData.number'),
          width: 33,
          dataIndex: 'number',
          align: 'center',
          key: 'number',
          render: (_, record, index) => get(sheetRecordHomeTeam, `${index}.playerDTO.uniformNumber`) || '',
        },
      ],
    },
    {
      key: 'type',
      width: 5,
      dataIndex: 'type',
      align: 'center' as any,
      render(value, row, index) {
        return getRowSpanValue(index, items.length, matchBasicInfoEntity?.numberOfPlayers);
      },
      onCell(_, index) {
        return {
          rowSpan: getRowSpan(index, items.length, matchBasicInfoEntity?.numberOfPlayers),
        };
      },
    },
    {
      title: matchBasicInfoEntity.awayClubName,
      width: 60,
      dataIndex: 'away',
      key: 'away',
      children: [
        {
          title: translate('matchOver.recordTableData.number'),
          width: 33,
          dataIndex: 'number',
          align: 'center',

          key: 'number',
          render: (_, record, index) => get(sheetRecordAwayTeam, `${index}.playerDTO.uniformNumber`),
        },
        {
          title: translate('matchOver.recordTableData.location'),
          width: 55,
          dataIndex: 'location',
          key: 'location',
          align: 'center',
          render: (_, record, index) => (
            <span style={{ whiteSpace: 'nowrap' }}>
              {get(ArrayPosition, get(sheetRecordAwayTeam, `${index}.positionType`)) || translate('common.sub')}
            </span>
          ),
        },

        {
          title: translate('matchOver.recordTableData.playerName'),
          width: 75,
          dataIndex: 'playerName',
          key: 'playerName',
          render(_, record, index) {
            return (
              <Tooltip title={get(sheetRecordAwayTeam, `${index}.playerDTO.koName`)} color={'blue'}>
                <span className="line-clamp-1">{get(sheetRecordAwayTeam, `${index}.playerDTO.koName`)}</span>
              </Tooltip>
            );
          },
        },
        {
          title: translate('matchOver.recordTableData.gk'),
          width: '33px',
          dataIndex: 'gk',
          align: 'center',
          key: 'gk',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.goalKick')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.GOAL_KICK, index, get(sheetRecordAwayTeam, index), AWAY)}
                className={`w-100 h-100  ${
                  get(sheetRecordAwayTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordAwayTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordAwayTeam, `${index}.goalKick`) || ''}
              </div>
            </Tooltip>
          ),
        },
        {
          title: translate('matchOver.recordTableData.ckfk'),
          width: 33,
          dataIndex: 'ckfk',
          key: 'ckfk',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.cornerKick')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.CORNER_KICK, index, get(sheetRecordAwayTeam, index), AWAY)}
                className={`w-100 h-100  ${
                  get(sheetRecordAwayTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordAwayTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordAwayTeam, `${index}.cornerKick`) || ''}
              </div>
            </Tooltip>
          ),
        },
        {
          title: translate('matchOver.recordTableData.fk'),
          width: 33,
          align: 'center',

          dataIndex: 'fk',
          key: 'fk',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.freeKick')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.FREE_KICK, index, get(sheetRecordAwayTeam, index), AWAY)}
                className={`w-100 h-100  ${
                  get(sheetRecordAwayTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordAwayTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordAwayTeam, `${index}.freeKick`) || ''}
              </div>
            </Tooltip>
          ),
        },
        {
          title: translate('matchOver.recordTableData.pk'),
          width: 33,
          dataIndex: 'pk',
          key: 'pk',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.penaltyKick')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.PENALTY_KICK, index, get(sheetRecordAwayTeam, index), AWAY)}
                className={`w-100 h-100  ${
                  get(sheetRecordAwayTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordAwayTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordAwayTeam, `${index}.penaltyKick`) || ''}
              </div>
            </Tooltip>
          ),
        },
        {
          title: translate('matchOver.recordTableData.st'),
          width: 33,
          dataIndex: 'st',
          key: 'st',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.shooting')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.SHOOTING, index, get(sheetRecordAwayTeam, index), AWAY)}
                className={`w-100 h-100  ${
                  get(sheetRecordAwayTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordAwayTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordAwayTeam, `${index}.shoot`) || ''}
              </div>
            </Tooltip>
          ),
        },

        {
          title: translate('matchOver.recordTableData.fc'),
          width: 33,
          dataIndex: 'fc',
          key: 'fc',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('recordRoom.lookupPlayer.foul')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.FOUL, index, get(sheetRecordAwayTeam, index), AWAY)}
                className={`w-100 h-100  ${
                  get(sheetRecordAwayTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordAwayTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordAwayTeam, `${index}.foul`) || ''}
              </div>
            </Tooltip>
          ),
        },
        {
          title: translate('matchOver.recordTableData.fs'),
          width: 33,
          dataIndex: 'fs',
          key: 'fs',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('recordRoom.lookupPlayer.bloodFoul')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.FOUL_SUFFERED_AWAY, index, get(sheetRecordAwayTeam, index), AWAY)}
                className={`w-100 h-100  ${
                  get(sheetRecordAwayTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordAwayTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordAwayTeam, `${index}.beFouled`) || ''}
              </div>
            </Tooltip>
          ),
        },
        {
          title: translate('matchOver.recordTableData.of'),
          width: 33,
          dataIndex: 'offSide',
          key: 'offSide',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.offSite')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.OFFSIDE, index, get(sheetRecordAwayTeam, index), AWAY)}
                className={`w-100 h-100 ${
                  get(sheetRecordAwayTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordAwayTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordAwayTeam, `${index}.offSide`) || ''}
              </div>
            </Tooltip>
          ),
        },
        {
          title: translate('matchOver.recordTableData.score'),
          width: 33,
          dataIndex: 'allScore',
          key: 'allScore',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('matchOver.recordTableData.score')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.SCORE, index, get(sheetRecordAwayTeam, index), AWAY)}
                className={`w-100 h-100  ${
                  get(sheetRecordAwayTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordAwayTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordAwayTeam, `${index}.allScore`) || ''}
              </div>
            </Tooltip>
          ),
        },

        {
          title: translate('matchOver.recordTableData.foul'),
          width: 33,
          dataIndex: 'foul',
          key: 'foul',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.yellowCard')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.WARNING, index, get(sheetRecordAwayTeam, index), AWAY)}
                className={`w-100 h-100 d-flex justify-content-around ${
                  get(sheetRecordAwayTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordAwayTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordAwayTeam, `${index}.warning`) ? (
                  <Tag color="gold" className="m-0">
                    {get(sheetRecordAwayTeam, `${index}.warning`)}
                  </Tag>
                ) : (
                  ''
                )}
                {get(sheetRecordAwayTeam, `${index}.ban`) ? (
                  <Tag color="error" className="m-0">
                    {get(sheetRecordAwayTeam, `${index}.ban`)}
                  </Tag>
                ) : (
                  ''
                )}
              </div>
            </Tooltip>
          ),
        },

        {
          title: translate('matchOver.recordTableData.assist'),
          width: 33,
          dataIndex: 'supportScore',
          key: 'supportScore',
          align: 'center' as any,

          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.assist')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.ASSIST, index, get(sheetRecordAwayTeam, index), AWAY)}
                className={`w-100 h-100  ${
                  get(sheetRecordAwayTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordAwayTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                {get(sheetRecordAwayTeam, `${index}.supportScore`) || ''}
              </div>
            </Tooltip>
          ),
        },

        {
          title: translate('matchOver.recordTableData.change'),
          width: 33,
          dataIndex: 'change',
          key: 'change',
          align: 'center',
          render: (_, record, index) => (
            <Tooltip title={translate('leagueApp.matchRecord.changePlayer')} color={'blue'}>
              <div
                onClick={() => handleClickEvent(actionRecord.CHANGE_AWAY, index, get(sheetRecordAwayTeam, index), AWAY)}
                className={`w-100 h-100  ${
                  get(sheetRecordAwayTeam, `${index}.warning`) > 1 ||
                  get(sheetRecordAwayTeam, `${index}.ban`) ||
                  index >= matchBasicInfoEntity?.numberOfPlayers
                    ? 'cursor-not-allowed'
                    : ''
                }`}
              >
                <>
                  {!!get(sheetRecordAwayTeam, `${index}.inOut`) && <CaretUpOutlined />}
                  {get(sheetRecordAwayTeam, `${index}.inOut`) === 0 ? <CaretDownOutlined /> : ''}
                </>
              </div>
            </Tooltip>
          ),
        },
      ],
    },
  ];
  return (
    <Table
      bordered
      pagination={false}
      dataSource={items}
      columns={columns}
      className={`event-sheet event-sheet-${matchBasicInfoEntity?.numberOfPlayers}`}
    />
  );
};

export default React.memo(SheetRecord);
