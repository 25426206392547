import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { Translate, getSortState, translate } from 'react-jhipster';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
// import { createNewClub } from '../../main.reducer';
import { DatePicker } from 'antd';
import ModalCreatePlayer from './modal-add-player';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import axios from 'axios';
import PlayerAcademic from '../player-academic/player-academic';
import { createEntity } from './player.reducer';
import { values } from 'lodash';
const CreateNewPlayer = (props: RouteComponentProps<any>) => {
  const [formCreate, setFormCreate] = useState({
    name: '',
    position: '',
    nationally: '',
  });
  const [formPlayer, setFormPlayer] = useState({
    koName: '',
    engName: '',
    uniformName: '',
    position: '',
    nationally: '',
    team: '',
    shirt: '',
    dateOfBirth: '',
    height: '',
    weight: '',
  });
  const [file, setFile] = useState<File>(null);
  const [image, setImage] = useState('');
  const dispatch = useAppDispatch();
  const imgName = useAppSelector(state => state.dashboardReducer.dataUploadFile);
  const [dataNation, setDataNation] = useState([]);
  const [dataPositions, setDataPositions] = useState([]);
  const [getAcademc, setGetAcademc] = useState(null);
  const idPlayer = useAppSelector(state => state.player.entity);
  useEffect(() => {
    return () => {
      URL.revokeObjectURL(image);
    };
  }, [image]);

  const handleCreateClub = () => {
    const body = {
      avatar: imgName,
      koName: formPlayer.koName,
      uniformName: formPlayer.uniformName,
      enName: formPlayer.engName,
      position: formPlayer.position,
      nationally: formPlayer.nationally,
      clubId: +formPlayer.team,
      uniformNumber: formPlayer.shirt,
      birthday: formPlayer.dateOfBirth,
      height: +formPlayer.height,
      weight: +formPlayer.weight,
      status: 1,
    };
    dispatch(createEntity(body));
  };
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );
  useEffect(() => {
    handleNationally();
    handlePositon();
  }, []);
  const [tabActive, setTabActive] = useState(1);
  const listTab = [
    { value: 1, component: <h1>Tab 1</h1>, title: 'Lên sân thi đấu' },
    { value: 2, component: <h1>Tab 2</h1>, title: 'Giải đấu' },
    { value: 3, component: <h1>Tab 3</h1>, title: 'Năng lực' },
    { value: 4, component: <PlayerAcademic getAcademc={getAcademc} setGetAcademc={setGetAcademc} id={idPlayer?.id} />, title: 'Học lực' },
  ];
  const handleNationally = async () => {
    const dataNationally = await axios.get<any>('api/utils/countries');
    const arrNation = [];
    dataNationally.data.map(a =>
      arrNation.push({
        value: a.id,
        label: a.name,
      })
    );
    setDataNation(arrNation);
  };

  const handlePositon = async () => {
    const dataPosition = await axios.get<any>('api/utils/positionPlayer');
    const arrPosition = [];
    dataPosition.data.map(a =>
      arrPosition.push({
        label: a,
      })
    );
    setDataPositions(arrPosition);
  };

  return (
    <div>
      <ModalCreatePlayer
        formCreate={formCreate}
        setFormCreate={setFormCreate}
        file={file}
        setFile={setFile}
        setImage={setImage}
        formPlayer={formPlayer}
        setFormPlayer={setFormPlayer}
      />
      <div className="club-info" style={{ border: '1px solid #ccc', padding: '12px 12px', height: '100vh' }}>
        <div>
          <div>
            <div className="position-relative">
              <div className="d-flex align-items-center justify-content-between payment">
                <h5 className="mb-0">
                  {/* <Translate contentKey="contactInfor.newaddress">Thêm mới địa chỉ</Translate> */}
                  {formPlayer.koName}
                </h5>
                <Button className="button-add" onClick={handleCreateClub}>
                  <Translate contentKey="clubmng.save" />
                </Button>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '16px' }}>
            <div className="d-flex">
              <div style={{ width: '50%' }} className="div-1">
                <div>
                  <div className="span-width">
                    <Translate contentKey="leagueApp.player.koName" />
                  </div>
                  <div className="input-width">
                    <Input
                      placeholder="인천 유나이티드"
                      value={formPlayer.koName}
                      onChange={e => setFormPlayer({ ...formPlayer, koName: e.target.value })}
                    />
                  </div>
                </div>
                <div>
                  <div className="span-width">
                    <span>
                      <Translate contentKey="leagueApp.player.position" />
                    </span>
                  </div>
                  <div className="input-width">
                    <div style={{ width: '30%' }}>
                      <Input
                        type="select"
                        value={formPlayer.position}
                        onChange={e => setFormPlayer({ ...formPlayer, position: e.target.value })}
                      >
                        {dataPositions.map(postion => (
                          <option value={postion.value} key={postion.value}>
                            {translate(`leagueApp.playerAgent.${postion.label}`)}
                          </option>
                        ))}
                      </Input>
                    </div>
                    <div className="d-flex" style={{ width: '70%' }}>
                      <div style={{ width: '20%', marginLeft: '20px' }}>
                        <span>
                          <Translate contentKey="leagueApp.player.nationality" />
                        </span>
                      </div>
                      <div style={{ width: '80%' }}>
                        <Input
                          type="select"
                          placeholder="주 경기장 선택"
                          value={formPlayer.nationally}
                          onChange={e => setFormPlayer({ ...formPlayer, nationally: e.target.value })}
                        >
                          {dataNation.map(nation => (
                            <option value={nation.value} key={nation.value}>
                              {nation.label}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="span-width">
                    <span>
                      <Translate contentKey="leagueApp.player.clubId" />
                    </span>
                  </div>
                  <div className="input-width">
                    <Input
                      placeholder="주소 입력"
                      value={formPlayer.team}
                      onChange={e => setFormPlayer({ ...formPlayer, team: e.target.value })}
                    />
                  </div>
                </div>
                <div>
                  <div className="span-width">
                    <span>
                      <Translate contentKey="leagueApp.player.shirt" />
                    </span>
                  </div>
                  <div className="input-width">
                    <Input
                      placeholder="홈페이지 입력"
                      value={formPlayer.shirt}
                      onChange={e => setFormPlayer({ ...formPlayer, shirt: e.target.value })}
                    />
                  </div>
                </div>
                <div>
                  <div className="span-width">
                    <span>
                      <Translate contentKey="leagueApp.player.birthday" />
                    </span>
                  </div>
                  <div className="input-width">
                    <Input
                      placeholder="구단소개를 입력해주세요"
                      value={formPlayer.dateOfBirth}
                      onChange={e => setFormPlayer({ ...formPlayer, dateOfBirth: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: '30%', marginRight: '12px' }}>
                <div className="d-flex" style={{ marginBottom: '16px' }}>
                  <div className="span-width">
                    <span>
                      <Translate contentKey="leagueApp.player.enName" />
                    </span>
                  </div>
                  <div className="input-width">
                    <Input
                      placeholder="인천"
                      value={formPlayer.engName}
                      onChange={e => setFormPlayer({ ...formPlayer, engName: e.target.value })}
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="span-width">
                    <span>
                      <Translate contentKey="leagueApp.player.height" />
                    </span>
                  </div>
                  <div className="input-width">
                    <Input
                      placeholder="김인천"
                      value={formPlayer.height}
                      onChange={e => setFormPlayer({ ...formPlayer, height: e.target.value })}
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="span-width">
                    <span>
                      <Translate contentKey="leagueApp.player.weight" />
                    </span>
                  </div>
                  <div className="input-width">
                    <Input
                      placeholder="김인천"
                      value={formPlayer.weight}
                      onChange={e => setFormPlayer({ ...formPlayer, weight: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: '20%' }} className="d-flex justify-content-center">
                <div
                  style={{ width: '70%', height: '70%', border: '1px solid #ccc' }}
                  className="d-flex justify-content-center align-items-center"
                >
                  {image ? <img src={image} style={{ width: '100%', height: '100%' }} alt="logo" /> : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ border: '1px solid #ccc', padding: '12px 12px', height: '100vh', marginTop: '12px' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {listTab.map(tab => (
            <Button
              key={tab.value}
              style={{
                border: '1px solid #ccc',
                height: '45px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: ' 200px',
              }}
              onClick={() => setTabActive(tab.value)}
            >
              {tab.title}
            </Button>
          ))}{' '}
        </div>
        <div>{listTab.map(tab => tab.value === tabActive && tab.component)}</div>
      </div>
    </div>
  );
};

export default CreateNewPlayer;
