export const OWNER = 'ownerPlayer';
export const AWAY = 'awayPlayer';
export const newShootRecord = { ownerPlayer: null, awayPlayer: null, ownerGoal: null, awayGoal: null };
export const defaultCountShoot = 5;

export const OWNER_CLUB_VALUE = 1;
export const AWAY_CLUB_VALUE = 2;

export const CHANGE_VALUE = 0;
export const SAVE_VALUE = 1;

export const convertListPlayerToOptions = (listPlayer = []) => {
  const listResult = [];
  listPlayer.forEach(owner => {
    if (owner.banCard <= 1) {
      listResult.push({
        value: owner.matchPlayerFieldDTO?.playerId,
        label: `${owner.matchPlayerFieldDTO?.playerDTOS?.uniformNumber || ''}. ${owner.matchPlayerFieldDTO?.playerDTOS?.koName}`,
      });
    }
  });
  return listResult;
};

export const getListPlayerOption = (listPlayerChoosed = [], listPlayerOfClub = [], index) => {
  let newListChoosed = listPlayerChoosed;
  // Nếu số cầu thủ đã chọn >= số cầu thủ của đội, list đã chọn = Array.slice((index bắt đầu lượt) x (index kết thúc lượt))
  if (newListChoosed.length >= listPlayerOfClub.length) {
    const turnOfIndex = Math.floor(index / listPlayerOfClub.length);
    newListChoosed = newListChoosed.slice(turnOfIndex * listPlayerOfClub.length, (turnOfIndex + 1) * listPlayerOfClub.length);
  }
  const arrayFilterToOption = listPlayerOfClub.filter(player => !newListChoosed.includes(player.value));
  return arrayFilterToOption;
};

export interface ITurnShoot {
  clubId: number;
  goal: number;
  playerId: number;
  turnNo: number;
}

export interface IMatchPenaltyShootOut {
  ownerBall: number;
  matchId: number;
  matchPenantyPlayerRecordDTOS: ITurnShoot[];
}
