import React, { useEffect, useState } from 'react';
import { log, translate, Translate } from 'react-jhipster';

import { Avatar, Button, Col, Dropdown, Form, Menu, Row, Select, Tabs, Tooltip, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Filter from 'app/modules/filter';
import MultiFilter from 'app/modules/MultiSearch';
import Table from 'app/modules/table';
import { useFilter } from 'app/shared/hooks/useFilter';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import dayjs from 'dayjs';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { IFormLeagueStand } from 'app/shared/model/record-room.model';
import { getStandard } from '../record-room/recordRoom.reducer';
import { resetValueRanking, getMonth, getLeague, getSchedule, getYear, matchCenterSlice } from './match-center.reducer';
import { findLast, findLastIndex, first, get, groupBy } from 'lodash';
import { formatDateMonthDay, formatHour } from 'app/shared/util/date-utils';
import { INTERMINABLE } from 'app/shared/util/constant/favorite.constant';
import { APPROVAL_TYPE } from 'app/config/constants';
import './style.scss';

export interface ScheduleFilter {
  lsId?: number;
  year?: string;
  leagueId?: number;
  month?: number;
  zoneId?: number;
}

export const ScheduleResult = () => {
  const [form] = Form.useForm();
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const listYear = useAppSelector(state => state.matchCenterReducer.listYear);
  const listLeague = useAppSelector(state => state.matchCenterReducer.listLeague);
  const listSchedule = useAppSelector(state => state.matchCenterReducer.listSchedule);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const lsId = Form.useWatch('lsId', form);
  const leagueId = Form.useWatch('leagueId', form);
  const yearForm = Form.useWatch('year', form);
  const { push } = useHistory();
  const zoneIdForm = -new Date().getTimezoneOffset() / 60;
  const { actions } = matchCenterSlice;

  const dataStandard: IFormLeagueStand[] = useAppSelector(state => state.recordRoomReducer.dataStandard)?.content;
  const [monthArr, setMonthArr] = useState([
    { value: 1, label: translate('matchCenter.month.jan'), disabled: false },
    { value: 2, label: translate('matchCenter.month.feb'), disabled: false },
    { value: 3, label: translate('matchCenter.month.march'), disabled: false },
    { value: 4, label: translate('matchCenter.month.apr'), disabled: false },
    { value: 5, label: translate('matchCenter.month.may'), disabled: false },
    { value: 6, label: translate('matchCenter.month.jun'), disabled: false },
    { value: 7, label: translate('matchCenter.month.jul'), disabled: false },
    { value: 8, label: translate('matchCenter.month.aug'), disabled: false },
    { value: 9, label: translate('matchCenter.month.sept'), disabled: false },
    { value: 10, label: translate('matchCenter.month.oct'), disabled: false },
    { value: 11, label: translate('matchCenter.month.nov'), disabled: false },
    { value: 12, label: translate('matchCenter.month.dec'), disabled: false },
  ]);

  const [activeTab, setActiveTab] = useState<number>(0);
  const groupDate = React.useMemo(() => {
    const list = listSchedule.map(item => ({ ...item, group: formatDateMonthDay(item.startTime) }));

    return groupBy(list, 'group');
  }, [listSchedule, locale]);

  const lists = Object.keys(groupDate).reduce((result, item, index) => {
    return result.concat([{ group: item, column: 8 }, ...groupDate[item].map(schedule => ({ ...schedule, column: 1 }))]);
  }, []);

  useEffect(() => {
    dispatch(
      getStandard({
        page: 0,
        size: 1000,
        body: {},
      })
    ).then(res => {
      const defaultStandard: { id: number } = first(get(res.payload, 'data.content'));

      if (res.meta.requestStatus === 'fulfilled' && defaultStandard) {
        form.setFieldsValue({ lsId: defaultStandard.id });
        dispatch(getLeague(defaultStandard.id)).then(leagueRes => {
          const defaultLeague: { id: number } = first(get(leagueRes.payload, 'data'));
          if (leagueRes.meta.requestStatus === 'fulfilled' && defaultLeague) {
            form.setFieldsValue({ leagueId: defaultLeague.id });
            dispatch(getYear({ leagueId: defaultLeague.id, zoneId: zoneIdForm })).then(yearRes => {
              const defaultYear: number = first(get(yearRes.payload, 'data'));
              if (yearRes.meta.requestStatus === 'fulfilled' && defaultYear) {
                form.setFieldsValue({ year: defaultYear });
                form.submit();
              }
            });
          }
        });
      }
    });
    dispatch(actions.resetListSchedule());
  }, []);

  const handleChangeStandard = (value: number) => {
    if (value) {
      form.setFieldsValue({
        leagueId: undefined,
        year: undefined,
      });
    }
    dispatch(getLeague(value));
    dispatch(resetValueRanking());
  };

  const handleGetYears = (value: number) => {
    if (value) {
      form.setFieldsValue({
        year: undefined,
      });
    }
    dispatch(getYear({ leagueId: value, zoneId: zoneIdForm }));
  };

  const handleSubmit = (data: ScheduleFilter) => {
    dispatch(getMonth({ ...data, zoneId: zoneIdForm })).then(res => {
      const months: number[] = get(res.payload, 'data');
      const currentMonth = dayjs().get('month') + 1;
      res.meta.requestStatus === 'fulfilled' && setMonthArr(pre => pre.map(item => ({ ...item, disabled: !months.includes(item.value) })));
      if (months.includes(currentMonth)) {
        dispatch(getSchedule({ leagueId, year: yearForm, month: currentMonth, zoneId: zoneIdForm }));
        setActiveTab(currentMonth);
      } else if (!months.length) {
        setActiveTab(NaN);
        dispatch(actions.resetListSchedule());
      } else {
        const moreNearest = months.find(item => item > currentMonth);
        const lessNearest = findLast(months, item => item < currentMonth);
        const activeMonth =
          !lessNearest || Math.abs(moreNearest - currentMonth) > Math.abs(moreNearest - currentMonth) ? moreNearest : lessNearest;

        dispatch(getSchedule({ leagueId, year: yearForm, month: activeMonth || moreNearest || lessNearest, zoneId: zoneIdForm }));
        setActiveTab(activeMonth);
      }
    });
  };

  const columns = [
    {
      dataIndex: 'group',
      key: 'group',
      width: '100px',
      render: (value, item) => item.column > 1 && <div className="pt-4">{value}</div>,
    },
    {
      dataIndex: 'ownerClubName',
      key: 'ownerClubName',
      width: '200px',
      render: (value, item) => (
        <div className="text-right" style={{ width: '200px', height: '26px' }}>
          {value}
        </div>
      ),
    },
    {
      dataIndex: 'ownerAvatar',
      key: 'ownerAvatar',
      width: '70px',
      align: 'center' as any,
      render: (value, item) => item.column === 1 && <Avatar size={50} shape="square" src={value} />,
    },
    {
      dataIndex: 'position',
      key: 'position',
      align: 'center' as any,
      width: '70px',
      render: (value, item) => (
        <div style={{ width: '100px' }} className="mx-auto">
          {item.statusMatch > 1 ? `${item.ownerGoal} : ${item.awayGoal}` : formatHour(item.startTime)}
        </div>
      ),
    },
    {
      dataIndex: 'awayAvatar',
      key: 'awayAvatar',
      align: 'center' as any,
      width: '70px',
      render: (value, item) => item.column === 1 && <Avatar size={50} shape="square" src={value} />,
    },
    {
      dataIndex: 'awayClubName',
      key: 'awayClubName',
      width: '260px',
      render: (value, item) => <div style={{ width: '260px', height: '26px' }}>{value}</div>,
    },
    {
      dataIndex: 'stadiumName',
      key: 'stadiumName',
      render: (value, item) => (
        <div style={{ width: '300px' }} className=" neutral-3">
          {value}
        </div>
      ),
    },
    {
      dataIndex: 'action',
      key: 'action',
      align: 'right' as any,
      render: (value, item) =>
        item.statusMatch > 1 ? (
          <div>
            <Button
              onClick={e => {
                push(`/real-time-recording/${item.matchId}/record-table`);
                e.stopPropagation();
              }}
              disabled={item.approval !== APPROVAL_TYPE.ACCEPTED}
              className="rounded"
            >
              <Translate contentKey="dashboard.matchRecords" />
            </Button>
          </div>
        ) : null,
    },
  ];

  return (
    <div className="management-layout">
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <h2 id="player-heading" data-cy="PlayerHeading" className="heading-20-bold neutral-1">
          <Translate contentKey="matchCenter.scheduleResult" />
        </h2>
      </div>
      <Form
        form={form}
        scrollToFirstError
        name="control-hooks"
        onFinishFailed={values => handleFocusFieldFail(values)}
        onFinish={handleSubmit}
      >
        <div>
          <Row gutter={[32, 32]}>
            <Col xl={6} lg={12} xs={24}>
              <Form.Item name="lsId" label={<Translate contentKey="recordRoom.lookupLeague.standardPrize">Standard Prize</Translate>}>
                <Select
                  placeholder={translate('recordRoom.placeholder.standardPrize')}
                  style={{ width: '100%' }}
                  onChange={handleChangeStandard}
                  className="custom-prefix-select"
                  suffixIcon={<SelectArrowDown />}
                >
                  {dataStandard?.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={8} lg={12} xs={24}>
              <Form.Item name="leagueId" label={<Translate contentKey="recordRoom.lookupLeague.leagueName">League Name</Translate>}>
                <Select
                  placeholder={translate('recordRoom.placeholder.leagueName')}
                  disabled={!lsId}
                  style={{ width: '100%' }}
                  onChange={handleGetYears}
                  className="custom-prefix-select"
                  suffixIcon={<SelectArrowDown />}
                >
                  {listLeague?.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={6} xs={0}></Col>
            <Col xl={4} lg={12} xs={24}>
              <Form.Item name="year" label={<Translate contentKey="recordRoom.lookupLeague.year">Year</Translate>}>
                <Select
                  placeholder={translate('recordRoom.placeholder.year')}
                  disabled={!leagueId}
                  style={{ width: '100%' }}
                  className="custom-prefix-select"
                  suffixIcon={<SelectArrowDown />}
                  onChange={() => form.submit()}
                >
                  {listYear?.map((item, index) => (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
      <Tabs
        onChange={value => {
          dispatch(getSchedule({ leagueId, year: yearForm, month: value, zoneId: zoneIdForm }));
          setActiveTab(Number(value));
        }}
        tabPosition="top"
        activeKey={activeTab?.toString()}
      >
        {monthArr.map((item, index) => (
          <Tabs.TabPane tab={item?.label} key={item?.value} disabled={item.disabled} />
        ))}
      </Tabs>

      <Table
        columns={columns}
        dataSource={lists}
        filter={{ size: INTERMINABLE }}
        scroll={{
          x: '900px',
        }}
        showHeader={false}
      />
    </div>
  );
};
