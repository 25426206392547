export const TAB_TYPE = {
  RECORD_TABLE: 'RECORD_TABLE',
  SCORE_AND_MOM: 'SCORE_AND_MOM',
  GENERAL_INFO: 'GENERAL_INFO',
};

export const LIST_URL = {
  RECORD_TABLE: 'record-table',
  SCORE_AND_MOM: 'score-and-mom',
  GENERAL_INFO: 'general-info',
};

export const OWNER_RECORD = 'ownerRecord'
export const AWAY_RECORD = 'awayRecord'
