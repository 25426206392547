import React, { useState, useEffect } from 'react';
import { translate } from 'react-jhipster';

import { Card, Col, Form, Modal, Radio, Row, Space, Button } from 'antd';
import ListItem from 'app/modules/pick-player/components/list-item';
import { PickItem } from 'app/modules/pick-item';
import { EditOutlined } from '@ant-design/icons';
import PickPosition from 'app/modules/pick-position';
import PickPlayer from 'app/modules/pick-player';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, getListCategoryValue } from './player-foul-record.reducer';
import { IPlayerInMatch } from 'app/shared/model/player.model';
import { first, get } from 'lodash';
import { getPosition } from 'app/modules/line-up/components/list-item';
import MiniMapPosition from 'app/modules/mini-map-position';
import { sendWSActivity } from 'app/config/websocket-middleware';
import { urlWs } from 'app/modules/event-list-record/constant.event-list';
import { actionRecord } from 'app/shared/util/constant';
import { IPlayerFoulRecord } from 'app/shared/model/player-foul-record.model';
import RecordPlayerInfo from 'app/modules/record-player-info';
import { toast } from 'react-toastify';

interface Props {
  open: boolean;
  onCancel: () => void;
  value?: string | null;
  onEditPlayer: () => void;
  onEditPosition: () => void;
  id?: number;
  awayAvatars: any;
  ownerAvatars: any;
  processTime: any;
}
export const PlayerFoulRecord = ({ processTime, onCancel, open, onEditPlayer, onEditPosition, id, awayAvatars, ownerAvatars }: Props) => {
  const form = Form.useFormInstance();
  const [defaultForm, setDefaultForm] = useState<IPlayerFoulRecord>();
  const playerInForm: IPlayerInMatch = Form.useWatch('player', form);

  const classify = [
    { value: 1, label: translate('leagueApp.playerRecord.bloodFoul') },
    { value: 0, label: translate('leagueApp.playerRecord.foul') },
  ];
  const pkList = [
    { value: 1, label: translate('leagueApp.playerFoulRecord.noPK') },
    { value: 0, label: translate('leagueApp.playerFoulRecord.PK') },
  ];

  const ownerLineUp: IPlayerInMatch[] = useAppSelector(state => state.matchRecord?.ownerLineUp) || [];
  const awayLineUp: IPlayerInMatch[] = useAppSelector(state => state.matchRecord?.awayLineUp) || [];
  const dispatch = useAppDispatch();

  const locale = useAppSelector(state => state.locale.currentLocale);

  const { faultTypeList } = useAppSelector(state => state.playerFoulReducer?.categoryList);
  const requiredRule = { required: true, message: '' };

  React.useEffect(() => {
    dispatch(getListCategoryValue(locale));
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(getEntity(id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        const data = get(res.payload, 'data');
        setDefaultForm(data);
        form.setFieldsValue({ ...data });
      }
    });
  }, [id]);

  const handleSubmit = data => {
    if (!data.detailLocation) {
      toast.error(translate('scoreRecord.message.supportForm'));
      return;
    }
    if (!id) {
      sendWSActivity(
        `/record/player-foul/create/${data.player?.matchPlayerFieldDTO.matchId}/${data.player?.matchPlayerFieldDTO.clubId}/${data.player?.matchPlayerFieldDTO.type}`,
        {
          ...data,
          playerId: data.player.playerId,
          matchId: data.player.matchPlayerFieldDTO.matchId,
          player: undefined,
          type: Number(data.type),
          reasonFoul: Number(data.reasonFoul),
          processDate: processTime?.process,
          roundType: processTime?.roundType,
        }
      );
    } else {
      sendWSActivity(urlWs(actionRecord.FOUL, 'edit', data.player.matchPlayerFieldDTO.matchId, id), {
        ...defaultForm,
        ...data,
        id,
        playerDTO: { ...defaultForm.playerDTO, base64Avt: null },
        playerId: data.player.playerId,
        matchId: data.player.matchPlayerFieldDTO.matchId,
        player: undefined,
        type: Number(data.type),
        reasonFoul: Number(data.reasonFoul),
      });
    }
    toast.success(translate('common.saveSuccess'));
    onCancel();
  };

  return (
    <Modal
      title={!id ? translate('leagueApp.playerBanRecord.registerFoul') : translate('leagueApp.playerBanRecord.editFoul')}
      className="register-record"
      visible={open}
      width={1000}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      zIndex={1}
      footer={[
        <Button key="close" onClick={() => onCancel()}>
          <span>{translate('freeCornerKick.close')}</span>
        </Button>,
        <Button key="save" type="primary" onClick={() => form.submit()}>
          <span>{translate('freeCornerKick.save')}</span>
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        style={{ width: 936, overflow: 'auto' }}
        onFinishFailed={() => {
          toast.error(translate('common.pleaseChooseEnoughData'));
        }}
      >
        <Row gutter={32} justify="center" style={{ width: 950 }} wrap={false}>
          <Col>
            <RecordPlayerInfo
              avatar={
                // playerInForm?.matchPlayerFieldDTO?.playerDTOS?.avatar
                get(awayAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  ? get(awayAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  : get(ownerAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  ? get(ownerAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  : undefined
              }
              prefix={playerInForm?.positionType}
              onEditPlayer={() => {
                onEditPlayer();
              }}
              onEditPosition={() => {
                onEditPosition();
              }}
              player={playerInForm}
              positionMap={form.getFieldValue('detailLocation')}
            />
          </Col>
          <Col>
            <Row wrap={false}>
              {/* <Col className="first-header">
                <PickItem
                  name="type"
                  list={classify}
                  getValue={item => item.value}
                  getLabel={item => item.label}
                  title={translate('leagueApp.playerBanRecord.classify.classify')}
                  rules={[requiredRule]}
                />
              </Col> */}
              <Col>
                <PickItem
                  name="reasonFoul"
                  list={faultTypeList || []}
                  getValue={item => Number(item.code)}
                  getLabel={item => item.name}
                  title={translate('leagueApp.playerFoulRecord.mistakeReason')}
                  // rules={[requiredRule]}
                />
              </Col>
              <Col style={{ width: 300 }}>
                <PickItem
                  name="playerFoulId"
                  list={
                    playerInForm?.matchPlayerFieldDTO.clubId !== first(ownerLineUp)?.matchPlayerFieldDTO.clubId
                      ? ownerLineUp.filter(item => !(item.banCard > 1))
                      : awayLineUp.filter(item => !(item.banCard > 1))
                  }
                  getValue={item => item.matchPlayerFieldDTO.playerDTOS.id}
                  getLabel={(item: IPlayerInMatch) =>
                    `${getPosition(item.positionType)} ${
                      item.matchPlayerFieldDTO.playerDTOS.uniformNumber ? item.matchPlayerFieldDTO.playerDTOS.uniformNumber : ''
                    } ${item.matchPlayerFieldDTO.playerDTOS.koName}`
                  }
                  secondColumn={
                    playerInForm?.matchPlayerFieldDTO.clubId !== first(ownerLineUp)?.matchPlayerFieldDTO.clubId
                      ? ownerLineUp.filter(item => !(item.banCard > 1)).length > 10
                      : awayLineUp.filter(item => !(item.banCard > 1)).length > 10
                  }
                  title={translate('leagueApp.playerFoulRecord.bloodFoulPlayer')}
                  // rules={[requiredRule]}
                />
              </Col>

              <Col className="last-header">
                <PickItem
                  name="pk"
                  list={pkList}
                  getValue={item => item.value}
                  getLabel={item => item.label}
                  title={translate('leagueApp.playerFoulRecord.pk')}
                  // rules={[requiredRule]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PlayerFoulRecord;
