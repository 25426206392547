import React from 'react';
import { DropdownItem } from 'reactstrap';
import { Dropdown, Menu, MenuProps, Select } from 'antd';
import { NavDropdown } from './menu-components';
import { locales, languages } from 'app/config/translation';
import { Option } from 'antd/lib/mentions';
import { MailOutlined } from '@ant-design/icons';
import { EnglandFlagIcon, KoreanFlagIcon } from 'app/shared/util/appIcon';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  theme?: 'light' | 'dark'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    theme,
  } as MenuItem;
}

export const LocaleMenu = ({ currentLocale, onClick }: { currentLocale: string; onClick: (event: any) => void }) => {
  const onClick1 = ({ item, key }) => {
    onClick(key);
  };
  const items: MenuItem[] = [
    getItem(
      currentLocale === 'en' ? <img src="content/images/englandFlag.png" /> : <img src="content/images/vietNamFlag.png" />,
      'sub1',
      null,
      [
        getItem(
          <>
            <img src="content/images/englandFlag.png" />
            <span style={{ marginLeft: 16 }}>English</span>
          </>,
          'en'
        ),
      
        getItem(
          <>
            <img src="content/images/vietNamFlag.png" />
            <span style={{ marginLeft: 16 }}>Việt Nam</span>
          </>,
          'vi'
        ),
      ]
    ),
  ];

  return Object.keys(languages).length > 1 ? (
    <>
      {/* <Menu onClick={onClick1} activeKey={currentLocale} className="menu-language" style={{ width: 50 }} mode="vertical" items={items} /> */}
      <Menu
        onClick={onClick1}
        className="menu-language"
        style={{ width: 50 }}
        mode="vertical"
        items={items}
        defaultSelectedKeys={[currentLocale]}
      />
    </>
  ) : null;
};
