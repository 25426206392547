import React, { useEffect } from 'react';
import { Switch, useLocation, Redirect, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import { useAppDispatch } from './config/store';
import Footer from './shared/layout/footer/footer';
import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import EntitiesRoutes from 'app/entities/routes';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import { sendActivity } from 'app/config/websocket-middleware';
import FindID from 'app/modules/account/findID/init/find-id';
import FindIDFinish from './modules/account/findID/finish/find-id-finish';
import DashBoard from './modules/dashboard/dashboard';
import TourNament from './modules/components/dashboar/manager/tourament/tournament';
import Club from './modules/components/dashboar/manager/club/club';
import Match from './modules/components/dashboar/manager/match';
import FootBaller from './modules/components/dashboar/manager/footballer';
import Stadium from './modules/components/dashboar/manager/stadium';
import CreateNewClub from './modules/components/dashboar/manager/club/CreateNewClub';
import UserManagement from './modules/administration/user-management/user-management';
import { Storage } from 'react-jhipster';
import UserManagementDetailDialog from './modules/administration/user-management/user-management-detail-dialog';
import AccountManagement from './modules/administration/account-management';
import AccountChangeInfo from './modules/administration/account-management/account-change-info';
import AccountChangePassword from './modules/administration/account-management/account-change-password';
import AccountLogout from './modules/administration/account-management/account-logout';
import Notification from './entities/notifications';
import { MatchScheduleDetail } from './modules/dashboard/components/match-schedule-detail/match-schedule-detail';

const loading = <div>loading ...</div>;

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => loading,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

const manager = [
  { id: 1, path: '/main/manager-tournament', component: TourNament },
  { id: 6, path: '/main/manager-club/create-club', component: CreateNewClub },
  { id: 2, path: '/main/manager-club', component: Club },
  { id: 3, path: '/main/manager-match', component: Match },
  { id: 4, path: '/main/manager-footballer', component: FootBaller },
  { id: 5, path: '/main/manager-stadium', component: Stadium },
];

const account = [
  { id: 1, path: '/account-management', component: AccountManagement },
  { id: 2, path: '/account-change-infomation', component: AccountChangeInfo },
  { id: 3, path: '/account-change-password', component: AccountChangePassword },
  { id: 4, path: '/account-logout', component: AccountLogout },
];

const Routes = ({ isAuthenticated, token }) => {
  const location = useLocation();

  return (
    <div className={`view-routes ${isAuthenticated ? 'has-footer' : ''}`}>
      <Switch>
        <ErrorBoundaryRoute path="/login" component={Login} />
        <ErrorBoundaryRoute path="/logout" component={Logout} />
        {/* <ErrorBoundaryRoute path="/account/register" component={Register} /> */}
        <ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
        <ErrorBoundaryRoute path="/account/reset/request" component={PasswordResetInit} />
        <ErrorBoundaryRoute path="/account/findid/finish" component={FindIDFinish} />
        <ErrorBoundaryRoute path="/account/findid/request" component={FindID} />
        <ErrorBoundaryRoute path="/account/reset/finish" component={PasswordResetFinish} />
        {manager.map(a => (
          <PrivateRoute key={a.id} path={a.path} component={a.component} />
        ))}
        {account.map(a => (
          <PrivateRoute key={a.id} path={a.path} component={a.component} />
        ))}
        <PrivateRoute path="/main" component={DashBoard} />
        <PrivateRoute path="/match-schedule-detail" component={MatchScheduleDetail} />
        <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute path="/account" component={Account} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]} />
        <PrivateRoute path="/user-management" component={UserManagement} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute path="/user-management-detail/:id/:params" component={UserManagementDetailDialog} />
        <PrivateRoute path="/sportat-center/notification" component={Notification} />
        <PrivateRoute path="/" component={EntitiesRoutes} />
        <ErrorBoundaryRoute component={PageNotFound} />
      </Switch>
      {isAuthenticated && <Footer />}
    </div>
  );
};

export default Routes;
