import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Col, Form, Row, Select, Space, Typography } from 'antd';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import { Translate, translate } from 'react-jhipster';
import TextField from 'app/modules/lm-input';
import './style.scss';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { AUTHORITIES, FILE_TYPE, NOTICE_CATEGORY } from 'app/config/constants';
import axios from 'axios';
import RichTextEditor from 'app/modules/rich-text-editor';
import { BsCaretLeft } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { updateEntity, getEntity, uploadAttachFile, deleteEntity, downloadAttachFileEntity } from './guide.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IUnitStandard } from 'app/shared/model/unit-standard.model';
import { Pageable } from 'app/shared/reducers/reducer.utils';
import { IAttachFile, INotice } from 'app/shared/model/notice.model';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import { downloadFileFromRes } from 'app/shared/util/helper';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { IGuide } from 'app/shared/model/guide.model';

const CreateNotice = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const detail: IGuide = useAppSelector(state => state.guide.entity);

  const { id } = useParams<{ id: string }>();
  const { authorities, id: userId } = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(authorities, [AUTHORITIES.ADMIN]);
  const isLeagueMaster = hasAnyAuthority(authorities, [AUTHORITIES.LEAGUE_MASTER]);

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(getEntity(id)).then(res => {
      form.setFieldsValue(res.payload);
    });
  }, [id]);

  const history = useHistory();

  const handleBack = e => {
    history.goBack();
  };

  const downloadAttachFile = (item: IAttachFile) => {
    dispatch(downloadAttachFileEntity(item.id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        downloadFileFromRes({ blob: res.payload, fileName: item.name });
      }
    });
  };

  const [modalDelete, setModalDelete] = useState(false);

  const confirmDelete = useCallback(() => {
    if (!detail?.id) return;
    dispatch(deleteEntity(detail?.id)).then(res => res.meta.requestStatus === 'fulfilled' && history.push('../../guide'));
  }, [detail?.id]);

  return (
    <>
      <Card className="mb-3">
        <div className="border-bottom d-flex justify-content-between align-items-center" style={{ paddingBottom: 30, marginBottom: 32 }}>
          <div className="d-flex align-items-center auto-wrap">
            <Button className="rounded management-header-add-button" icon={<BsCaretLeft />} onClick={handleBack} type="primary">
              <Translate contentKey="entity.action.back" />
            </Button>
            <div className="management-layout-divider"></div>
            <p className="h4 mb-0">
              {' '}
              {detail?.title && detail?.title.length > 50 ? detail?.title.substring(0, 50) + '...' : detail?.title}
            </p>
          </div>

          {(isAdmin || detail?.createdBy === userId) && (
            <div className="d-flex align-items-center auto-wrap">
              <Button
                onClick={e => {
                  history.push('./update');
                  e.stopPropagation();
                }}
                className="rounded mx-2"
              >
                <Translate contentKey="entity.action.edit" />
              </Button>
              <Button
                onClick={e => {
                  setModalDelete(true);
                }}
                className="rounded"
                type="primary"
                danger
              >
                <Translate contentKey="entity.action.delete2">Delete</Translate>
              </Button>
            </div>
          )}
        </div>
        <Row gutter={[24, 12]}>
          <Col flex="none">
            <Typography.Text className="title-time">{convertDateTimeFromServer(detail?.createdDate)}</Typography.Text>
          </Col>
          <Col xs={24} className="ql-container ql-editor">
            <div dangerouslySetInnerHTML={{ __html: detail.content }}></div>
          </Col>
          {(detail?.attachmentDTOS && detail?.attachmentDTOS?.length !== 0) && (
            <Col xs={24}>
              <p className="file-attached">
                {' '}
                <Translate contentKey="leagueApp.notice.fileAttach" />
              </p>
              <Space size={'middle'} direction="vertical">
                {detail?.attachmentDTOS?.map((item: IAttachFile) =>
                  item.typeFile === FILE_TYPE.DOCUMENT ? (
                    <span onClick={() => downloadAttachFile(item)} style={{ color: '#0062AE' }} className="hot-link-club" key={item.id}>
                      {item.name}
                    </span>
                  ) : (
                    <img
                      style={{ width: 90, height: 90, objectFit: 'contain' }}
                      className="image-attach"
                      key={item.id}
                      src={item.url}
                      title={item.name}
                      onClick={() => window.open(item.url)}
                    />
                  )
                )}
              </Space>
            </Col>
          )}
        </Row>
      </Card>
      <Card className="mb-3 next-previous-notice">
        <Space direction="vertical">
          <Space size={'middle'}>
            <Typography>{translate('leagueApp.notice.next')}</Typography>
            {detail?.nextGuideDTO ? (
              <span className="hot-link-club" onClick={() => history.push(`../${detail?.nextGuideDTO?.id}/detail`)}>
                {detail?.nextGuideDTO?.title}
              </span>
            ) : (
              <Typography.Text type="secondary">{translate('leagueApp.notice.noNextNotice')}</Typography.Text>
            )}
          </Space>
          <Space size={'middle'}>
            <Typography>{translate('leagueApp.notice.previous')}</Typography>
            {detail?.previousGuideDTO ? (
              <span className="hot-link-club" onClick={() => history.push(`../${detail?.previousGuideDTO?.id}/detail`)}>
                {detail?.previousGuideDTO?.title}
              </span>
            ) : (
              <Typography.Text type="secondary">{translate('leagueApp.notice.noPreviousNotice')}</Typography.Text>
            )}
          </Space>
          <Button
            className="rounded management-header-add-button"
            icon={<BsCaretLeft />}
            onClick={() => history.push('/sportat-center/guide')}
            type="primary"
          >
            <Translate contentKey="entity.action.backToList" />
          </Button>
        </Space>
        {modalDelete && (
          <ConfirmationDialog
            open={modalDelete}
            message={<h6>{translate('leagueApp.notice.delete.question')}</h6>}
            onClose={() => setModalDelete(false)}
            onConfirm={() => confirmDelete()}
          />
        )}
      </Card>
    </>
  );
};

export default CreateNotice;
