import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Col, Input, Select, Tag, Tooltip } from 'antd';
import { formatDate, formatHour, formatDate2, formatYear } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { LeagueAttendListFilter } from './league-attend.type';
import { getEntities } from './league-attend.reducer';
import { getEntity } from '../club/club.reducer';

import dayjs from 'dayjs';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import Filter from 'app/modules/filter';
import { Link, useHistory, useParams } from 'react-router-dom';
import './style.scss';
import { path } from '../routes';
import MultiFilter from 'app/modules/MultiSearch';
import { TYPE_OF_OPTION } from 'app/shared/util/constant';
import { isRole } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export const LeagueAttend = (props: any) => {
  const dispatch = useAppDispatch();
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
    type: 'leagueName',
  };
  const leagueAttend = useAppSelector(state => state.LeagueAttend.entities);
  const loading = useAppSelector(state => state.LeagueAttend.loading);
  const param = useParams<{ id: string; leagueId: string; params: string }>();
  const id = +param.id;
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const isTeamManager = isRole(authorities, [AUTHORITIES.TEAM_MANAGER]);
  const { push } = useHistory();
  // useEffect(() => {
  //   dispatch(getEntity(props.match.params.id));
  // }, []);
  const ActionButton = ({ leagueId }: { leagueId: number }) => {
    return (
      <div className={'column-action-button'}>
        <Button
          onClick={e => {
            push(path.leagueAttendDetail.replace(':id', id.toString()).replace(':leagueId', leagueId.toString()));
            e.stopPropagation();
          }}
          className="rounded"
        >
          <Translate contentKey="leagueApp.match.details" />
        </Button>
      </div>
    );
  };
  const handleFetchData = React.useCallback((params: LeagueAttendListFilter) => {
    dispatch(getEntities({ ...params, id }));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const handleClickDetail = data => {
    push(path.leagueDetail.replace(':id', data));
  };

  const columns = [
    {
      title: '',
      dataIndex: 'base64Avt',
      key: 'base64Avt',
      render: (value, record) => <Avatar shape="square" src={value} size="large" />,
    },
    {
      title: <Translate contentKey="leagueApp.playerClub.issueYear" />,
      dataIndex: 'year',
      key: 'year',
      render: (value, record) => formatYear(value),
    },
    {
      title: <Translate contentKey="leagueApp.playerClub.league" />,
      dataIndex: 'leagueNameStandart',
      key: 'leagueNameStandart',
    },
    {
      title: <Translate contentKey="leagueApp.playerClub.leagueName" />,
      dataIndex: 'leagueName',
      key: 'leagueName',
      render: (value, record) => (
        <>
          {/* <Avatar src={record?.urlImageLeagueStandard} shape="square" size="large" style={{ marginRight: '12px' }} /> */}
          <span onClick={() => handleClickDetail(record?.leagueId)} className="hot-link-club">
            {value}
          </span>
        </>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.playerClub.organizator" />,
      dataIndex: 'unitStandardName',
      key: 'unitStandardName',
    },
    {
      title: <Translate contentKey="leagueApp.joinedLeague.owner" />,
      dataIndex: 'owner',
      key: 'owner',
    },
    {
      title: <Translate contentKey="leagueApp.playerClub.startDate" />,
      dataIndex: 'startDate',
      key: 'startDate',
      render: (value, record) => formatDate2(value),
    },
    {
      title: <Translate contentKey="leagueApp.playerClub.endDate" />,
      dataIndex: 'endDate',
      key: 'endDate',
      render: (value, record) => formatDate2(value),
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'leagueId',
      key: 'leagueId',
      render: (value, record) => <ActionButton leagueId={value} />,
    },
  ];

  const options = [
    {
      value: 'year',
      label: translate('leagueApp.playerClub.issueYear'),
      type: TYPE_OF_OPTION.YEAR,
    },
    {
      value: 'leagueNameStandart',
      label: translate('leagueApp.playerClub.league'),
    },
    {
      value: 'leagueName',
      label: translate('leagueApp.playerClub.leagueName'),
    },
    {
      value: 'unitStandardName',
      label: translate('leagueApp.playerClub.organizator'),
    },
    {
      value: 'owner',
      label: translate('leagueApp.joinedLeague.owner'),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center management-layout-header" style={{ paddingTop: 30 }}>
        <div className="d-flex align-items-center">
          <h2 id="player-heading" data-cy="PlayerHeading" className="heading-20-bold neutral-1">
            <Translate contentKey="leagueApp.tab.leagueAttend"></Translate>
          </h2>
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <Filter filter={filter} onFilter={onFilterToQueryString} multi options={options} />
        </Col>
      </div>
      <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
      <div className="table-responsive">
        <Table
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
          totalElements={leagueAttend.totalElements || 0}
          columns={columns}
          dataSource={leagueAttend.content}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default LeagueAttend;
