import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row, Select } from 'antd';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import { Translate, translate } from 'react-jhipster';
import TextField from 'app/modules/lm-input';
import AttachFile from 'app/entities/attach-file';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { NOTICE_CATEGORY, QUESTION_CATEGORY } from 'app/config/constants';
import axios from 'axios';
import RichTextEditor from 'app/modules/rich-text-editor';
import { BsCaretLeft } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Pageable } from 'app/shared/reducers/reducer.utils';
import { IAttachFile, INotice } from 'app/shared/model/notice.model';
import { IQuestionAnswer } from 'app/shared/model/question-answer.model';
import { createEntity, getEntity } from './question-answer.reducer';
import { handleValidateMessageLanguageChanged } from 'app/shared/util/validate';
import './style.scss';
import { toast } from 'react-toastify';
import { uploadAttachFile } from './answer.reducer';

const Answer = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const files = Form.useWatch('attachmentDTOS', form);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const loading = useAppSelector(state => state.answerReducer.loading);
  const { id } = useParams<{ id: string }>();
  const handleBack = e => {
    history.goBack();
  };
  const handleSubmit = (data: IQuestionAnswer) => {
    dispatch(uploadAttachFile(data.attachmentDTOS)).then(res => {
      res.meta.requestStatus === 'fulfilled' &&
        dispatch(createEntity({ ...data, parentId: id, status: 0, attachmentDTOS: res.payload as IAttachFile[] }))
          .then(response => {
            if (response.meta.requestStatus === 'fulfilled') {
              history.push('../../question-and-answer');
              toast.success(translate('leagueApp.questionAnswer.answerSuccess'));
            }
          })
          .catch(err => {
            toast.error(translate('leagueApp.questionAnswer.answerFail'));
          });
    });
  };

  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
  }, [locale]);
  return (
    <Form
      form={form}
      onFinishFailed={values => handleFocusFieldFail(values)}
      initialValues={{ attachmentDTOS: [], title: '' }}
      scrollToFirstError
      name="control-hooks"
      layout="vertical"
      requiredMark={'optional'}
      onFinish={handleSubmit}
    >
      <Card className="mb-3">
        <div className="border-bottom d-flex justify-content-between align-items-center answer-content">
          <div className="d-flex align-items-center auto-wrap">
            <Button className="rounded management-header-add-button" icon={<BsCaretLeft />} onClick={handleBack} type="primary">
              <Translate contentKey="entity.action.back" />
            </Button>
            <div className="management-layout-divider"></div>
            <p className="h4 mb-0">
              {' '}
              <Translate contentKey="leagueApp.questionAnswer.registerAnswer" />
            </p>
          </div>

          <Button loading={loading} htmlType="submit" type="primary">
            <Translate contentKey="common.save" />
          </Button>
        </div>
        <Row gutter={[24, 12]}>
          <Col xs={24}>
            <Form.Item
              label={<h6>{translate('leagueApp.notice.content')}</h6>}
              name="content"
              rules={[{ required: true, message: translate('leagueApp.notice.required.content') }]}
            >
              <RichTextEditor />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Form.Item name="attachmentDTOS">
        <AttachFile
          files={files}
          onChange={fileList => {
            form.setFieldsValue(pre => ({ ...pre, attachmentDTOS: fileList }));
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default Answer;
