export interface ILeagueAttend {
    id?: number;
    avatar?: string | null;
    name?: string | null;
    shortName?: string | null;
    issueYear?: string | null;
    managerId?: number | null;
    managerName?: string | null;
    website?: string | null;
    description?: string | null;
    address?: string | null;
    stadiumId?: number | null;
    startDate?: string | null;
    endDate?: string | null;
    leagueNameStandart?: string | null;
    leagueName?: string | null;
    fullName?: string | null;
    year?: string | null;
    owner?: string | null;
  }
  
  export const defaultValueAttend: Readonly<ILeagueAttend> = {};
  

