import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-jhipster';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import { createNewClub } from '../../main.reducer';
import ModalCreateClub from './modalCreateClub';

const CreateNewClub = (props: RouteComponentProps<any>) => {
  const [formCreate, setFormCreate] = useState({
    nameClub: '',
    acronym: '',
    yearCreate: '',
  });
  const [formClub, setFormClub] = useState({
    name: '',
    year: '',
    stadium: '',
    address: '',
    website: '',
    description: '',
    shortName: '',
    nameManager: '',
  });
  const [file, setFile] = useState<File>(null);
  const [image, setImage] = useState('');
  const dispatch = useAppDispatch();
  const imgName = useAppSelector(state => state.dashboardReducer.dataUploadFile);
  useEffect(() => {
    return () => {
      URL.revokeObjectURL(image);
    };
  }, [image]);

  const handleCreateClub = () => {
    const body = {
      avatar: imgName,
      name: formClub.name,
      shortName: formClub.shortName,
      issueYear: formClub.year,
      website: formClub.website,
      description: formClub.description,
      address: formClub.address,
    };
    dispatch(createNewClub(body));
  };

  return (
    <div>
      <ModalCreateClub
        formCreate={formCreate}
        setFormCreate={setFormCreate}
        file={file}
        setFile={setFile}
        setImage={setImage}
        formClub={formClub}
        setFormClub={setFormClub}
      />
      <div className="club-info" style={{ border: '1px solid #ccc', padding: '12px 12px', height: '100vh' }}>
        <div>
          <div>
            <div className="position-relative">
              <div className="d-flex align-items-center justify-content-between payment">
                <h5 className="mb-0">{/* <Translate contentKey="contactInfor.newaddress">Thêm mới địa chỉ</Translate> */}Incheon United</h5>
                <Button className="button-add" onClick={handleCreateClub}>
                  <Translate contentKey="clubmng.save" />
                </Button>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '16px' }}>
            <div className="d-flex">
              <div style={{ width: '50%' }} className="div-1">
                <div>
                  <div className="span-width">
                    <Translate contentKey="clubmng.name" />
                  </div>
                  <div className="input-width">
                    <Input
                      placeholder="인천 유나이티드"
                      value={formClub.name}
                      onChange={e => setFormClub({ ...formClub, name: e.target.value })}
                    />
                  </div>
                </div>
                <div>
                  <div className="span-width">
                    <span>* 창단년도</span>
                  </div>
                  <div className="input-width">
                    <div style={{ width: '30%' }}>
                      <Input value={formClub.year} onChange={e => setFormClub({ ...formClub, year: e.target.value })} />
                    </div>
                    <div className="d-flex" style={{ width: '70%' }}>
                      <div style={{ width: '20%', marginLeft: '20px' }}>
                        <span>주 경기장</span>
                      </div>
                      <div style={{ width: '80%' }}>
                        <Input
                          placeholder="🔎 주 경기장 선택"
                          value={formClub.stadium}
                          onChange={e => setFormClub({ ...formClub, stadium: e.target.value })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="span-width">
                    <span>주소</span>
                  </div>
                  <div className="input-width">
                    <Input
                      placeholder="주소 입력"
                      value={formClub.address}
                      onChange={e => setFormClub({ ...formClub, address: e.target.value })}
                    />
                  </div>
                </div>
                <div>
                  <div className="span-width">
                    <span>홈페이지</span>
                  </div>
                  <div className="input-width">
                    <Input
                      placeholder="홈페이지 입력"
                      value={formClub.website}
                      onChange={e => setFormClub({ ...formClub, website: e.target.value })}
                    />
                  </div>
                </div>
                <div>
                  <div className="span-width">
                    <span>구단소개</span>
                  </div>
                  <div className="input-width">
                    <Input
                      placeholder="구단소개를 입력해주세요"
                      value={formClub.description}
                      onChange={e => setFormClub({ ...formClub, description: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: '30%', marginRight: '12px' }}>
                <div className="d-flex" style={{ marginBottom: '16px' }}>
                  <div className="span-width">
                    <span>* 약칭</span>
                  </div>
                  <div className="input-width">
                    <Input
                      placeholder="인천"
                      value={formClub.shortName}
                      onChange={e => setFormClub({ ...formClub, shortName: e.target.value })}
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="span-width">
                    <span>구단관리자</span>
                  </div>
                  <div className="input-width">
                    <Input
                      placeholder="김인천"
                      value={formClub.nameManager}
                      onChange={e => setFormClub({ ...formClub, nameManager: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: '20%' }} className="d-flex justify-content-center">
                <div
                  style={{ width: '70%', height: '70%', border: '1px solid #ccc' }}
                  className="d-flex justify-content-center align-items-center"
                >
                  {image ? <img src={image} style={{ width: '100%', height: '100%' }} alt="logo" /> : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ border: '1px solid #ccc', padding: '12px 12px', height: '100vh', marginTop: '12px' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            style={{
              border: '1px solid #ccc',
              height: '45px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: ' 200px',
            }}
          >
            Giải đấu tham dự
          </Button>
          <Button
            style={{
              border: '1px solid #ccc',
              height: '45px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: ' 200px',
            }}
          >
            Cầu thủ
          </Button>
          <Button
            style={{
              border: '1px solid #ccc',
              height: '45px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: ' 200px',
            }}
          >
            Đồng phục
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateNewClub;
