import React, { memo, useCallback, useState } from 'react';
import { Storage, translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppSelector } from 'app/config/store';
import { Row, Col, Tag, Select, Form, Button, Typography } from 'antd';
import { get, isNumber, omit } from 'lodash';
import { FilterParams } from 'app/shared/model/filter.model';
import { Filter } from 'app/shared/util/appIcon';
import { TYPE_OF_OPTION } from 'app/shared/util/constant';
import { formatDate } from 'app/shared/util/date-utils';
import axios from 'axios';
import { CloseOutlined } from '@ant-design/icons';

interface Props {
  filter: FilterParams;
  onFilter: (filter: FilterParams) => void;
  options: { value: string; label: string; type?: string }[];
  ignore?: string[];
}

const MultiFilterCustom = ({ filter, onFilter, options, ignore }: Props) => {
  const data = omit(filter, ['sort', 'type', 'page', 'size'].concat(ignore));
  const classify = [
    { value: 1, label: translate('leagueApp.leagueStandard.league') },
    { value: 2, label: translate('leagueApp.leagueStandard.cup') },
  ];

  const status = [
    { value: 1, label: translate('leagueApp.match.preparing') },
    { value: 2, label: translate('leagueApp.match.ready') },
    { value: 3, label: translate('leagueApp.match.recording') },
    { value: 4, label: translate('leagueApp.match.statusMatch.finish') },
    { value: 5, label: translate('leagueApp.match.statusMatch.waitApprove') },
    { value: 6, label: translate('leagueApp.match.statusMatch.complete') },
  ];

  const { position, role, rating, roleSearch, noticeCategory, category } = useAppSelector(state => state.dashboard.filterList);

  const specialData = { position, role, rating, roleSearch, classify, status, noticeCategory, category };

  const getLabel = useCallback(
    (key: string, value: any) => {
      if (Object.keys(specialData).includes(key)) {
        // eslint-disable-next-line eqeqeq
        if (key === 'roleSearch') {
          return translate(`author.${value}`);
        }
        return get(specialData, key)?.find(item => item.value === value)?.label;
      }
      return value;
    },
    [specialData]
  );
  if (Object.keys(data).length === 0) {
    return null;
  }
  return (
    <div className="multi-search">
      <Row align="middle" gutter={12}>
        <Col flex="none" className="pl-2">
          <div className="filter-icon">
            <Filter />
          </div>
        </Col>
        <Col flex="auto">
          {Object.keys(data).map(item => {
            const activeOption = options.find(option => option.value === item);
            return (
              <Tag
                key={item}
                closable
                color="#ECEFF4"
                style={{ padding: '10px 16px', margin: '0 10px 0 0', display: 'inline-flex' }}
                closeIcon={<CloseOutlined size={22} style={{ color: '#292C38' }} />}
                onClose={() => onFilter({ ...filter, [item]: '' })}
              >
                <span className="filter-text">
                  {activeOption?.label} <span className="mx-2">|</span>
                  {activeOption?.type === TYPE_OF_OPTION.DATE || activeOption?.type === TYPE_OF_OPTION.DATEGMT
                    ? formatDate(get(data, item))
                    : getLabel(item, get(data, item))}
                </span>
              </Tag>
            );
          })}
        </Col>
        <Col flex="none" className="ml-auto">
          <Button className="rounded" danger onClick={() => onFilter({})}>
            <Translate contentKey="home.clear" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default MultiFilterCustom;
