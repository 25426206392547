import React, { useCallback, useEffect, useState } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Button, Row, Tabs } from 'antd';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import LineUp from 'app/modules/line-up';
import { IPlayerInMatch } from 'app/shared/model/player.model';
import { toast } from 'react-toastify';
import { getPlayerAvatar, getMainPlayerFieldSquad, saveMatch, resetLineUp } from 'app/entities/match-record/match-record.reducer';
import { get } from 'lodash';
import Modal from 'antd/lib/modal/Modal';
import { path } from 'app/entities/routes';
import { pathLink } from '../..';
import { setSave } from 'app/entities/match/match.reducer';

export const MatchPreparationPlan = ({ match }) => {
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const ownerLineUp = useAppSelector(state => state.matchRecord?.ownerLineUp) || [];
  const awayLineUp = useAppSelector(state => state.matchRecord?.awayLineUp) || [];
  const { awayAvatars, ownerAvatars } = useAppSelector(state => state.matchRecord);
  const [submit, setSubmit] = React.useState(false);
  const alreadySave = useAppSelector(state => state.match.save);
  const matchBasicInfoEntity = useAppSelector(state => state.match.entity);

  const handleSubmit = React.useCallback((owner: IPlayerInMatch[], away: IPlayerInMatch[]) => {
    if (owner.length === 0 || away.length === 0) {
      return;
    }
    if (owner.filter(item => item.positionType === 1).length !== 1 || away.filter(item => item.positionType === 1).length !== 1) {
      toast.error(translate('leagueApp.matchRecord.errorGKMessage'));
      setSubmit(false);
      return;
    }

    // to remove base 64 avatar
    const data = owner.concat(away).map(item => ({
      ...item,
      matchPlayerFieldDTO: { ...item.matchPlayerFieldDTO, playerDTOS: { ...item.matchPlayerFieldDTO.playerDTOS, avatar: null } },
    }));
    dispatch(saveMatch(data))
      .then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          toast.success(translate('common.saveSuccess'));
        }
      })
      .catch(err => toast.error('saveFailure'))
      .finally(() => {
        dispatch(setSave(true));
      });
    setSubmit(false);
  }, []);

  const [alertModal, setAlertModal] = useState(false);

  const handleReset = React.useCallback(() => {
    dispatch(
      resetLineUp({
        matchId: matchBasicInfoEntity.id,
        awayClubId: matchBasicInfoEntity.awayClubId,
        ownerClubId: matchBasicInfoEntity.ownerClubId,
      })
    ).finally(() => {
      dispatch(setSave(true));
    });
  }, [matchBasicInfoEntity]);

  React.useEffect(() => {
    if (!matchBasicInfoEntity || !matchBasicInfoEntity.id) return;
    dispatch(
      getMainPlayerFieldSquad({
        matchId: matchBasicInfoEntity.id,
        awayClubId: matchBasicInfoEntity.awayClubId,
        ownerClubId: matchBasicInfoEntity.ownerClubId,
      })
    ).then(res => {
      if (get(res.payload, '0').length === 0 || get(res.payload, '1').length === 0) {
        setAlertModal(true);
      }
    });
    dispatch(
      getPlayerAvatar({
        matchId: matchBasicInfoEntity.id,
        awayClubId: matchBasicInfoEntity.awayClubId,
        ownerClubId: matchBasicInfoEntity.ownerClubId,
      })
    );
    dispatch(setSave(true));
    return () => {
      dispatch(setSave(true));
    };
  }, [matchBasicInfoEntity]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center record-layout-header">
        <p className="heading-20-bold neutral-1 mb-0">
          <Translate contentKey="common.plan" />
        </p>
        <div>
          <Button onClick={() => handleReset()} className="mx-2">
            <Translate contentKey="common.autoSelect" />
          </Button>

          <Button onClick={() => setSubmit(true)}>
            <Translate contentKey="common.save" />
          </Button>
        </div>
      </div>
      <div style={{ width: '100%', overflow: 'auto' }}>
        <LineUp
          ownerLineUp={ownerLineUp}
          awayLineUp={awayLineUp}
          onSubmit={handleSubmit}
          canChange={true}
          onChange={(owner: IPlayerInMatch[], away: IPlayerInMatch[], clubId: number) => {
            if (alreadySave) dispatch(setSave(false));
          }}
          haveSubPlayers={false}
          submit={submit}
          awayAvatars={awayAvatars}
          ownerAvatars={ownerAvatars}
        />
      </div>
      <Modal visible={alertModal} footer={null} width={360} centered closeIcon={<div />}>
        <p className="text-center h5 mb-3">
          <Translate contentKey="leagueApp.matchBasicInfo.alertTitle" />
        </p>
        <p className="text-center mb-4">
          <Translate contentKey="leagueApp.matchBasicInfo.alertMessage" />
        </p>
        <Button
          block
          type="primary"
          onClick={() => push(path.matchPreparation.replace(':id', match.params.id).replace(':params', pathLink.squad))}
        >
          <Translate contentKey="leagueApp.matchBasicInfo.goToLineUp" />
        </Button>
      </Modal>
    </div>
  );
};
