import React from 'react';
import { Switch } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CategoryData from './category-data';
import CategoryDataTrans from './category-data-trans';
import UnitStandard from './unit-standard';
import LeagueStandard from './league-standard';
import Club from './club';
import Player from './player';
import Monitor from './monitor';
import Stadium from './stadium';
import League from './league';
import Match from './match';
import Coach from './coach';
import PlayerAgent from './player-agent';
import { ClubUpdate } from './club/club-update';
import { MatchPreparation } from './match/MatchPreparation';
import { PlayerUpdate } from './player/player-update';
import { LeagueUpdate } from './league/league-update';
import LeagueDetail from './league/league-detail';
import { MatchRecord } from './match-record/match-record';
import PrivateRouteComponent from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import LookupLeague from './record-room/lookup-league';
import LookupTeam from './record-room/lookup-team';
import LookupPlayer from './record-room/lookup-player';
import AttachFile from './attach-file';
import Notice from './notice/notice';
import CreateNotice from './notice/notice-create';
import DetailNotice from './notice/notice-detail';
import UpdateNotice from './notice/notice-update';
import CreateGuide from './guide/guide-create';
import DetailGuide from './guide/guide-detail';
import UpdateGuide from './guide/guide-update';
import { QuestionAndAnswer } from './question-and-answer';
import CreateQA from './question-and-answer/question-and-answer-create';
import DetailQuestion from './question-and-answer/question-answer-detail';
import QuestionUpdate from './question-and-answer/question-and-answer-update';
import Answer from './question-and-answer/answer';
import Guide from './guide/guide';
import DetailAnswer from './question-and-answer/answer-detail';
import AnswerUpdate from './question-and-answer/answer-update';
import { MatchCenter } from './match-center';
import Staff from './staff/staff';
import ClubManagement from './club-management/club-management';
import LeagueAttendDetail from './league-attend/league-attend-detail';
// import PlayerAcademic from './player-academic';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export const path = {
  // từ sau mọi người khai báo url ở đây
  match: '/match',
  matchPreparation: '/match-preparetion/:id/:params',
  player: '/player',
  playerDetail: '/player-detail/:id/:params',
  club: '/club',
  leagueAttendDetail: '/club-detail/:id/league-attend/:leagueId/:params',
  clubDetail: '/club-detail/:id/:params',
  unitStandard: '/unit-standard',
  leagueStandard: '/league-standard',
  monitor: '/monitor',
  stadium: '/stadium',
  league: '/league',
  leagueAttendDetail1: '/league-detail/:leagueId/league-club/:id/:params',
  leagueDetail: '/league-detail/:id/:params',
  recordMatch: '/real-time-recording/:id/:params',
  lookupLeague: '/lookup-league',
  lookupTeam: '/lookup-team',
  lookupPlayer: '/lookup-player',
  notice: '/sportat-center/notice',
  createNotice: '/sportat-center/notice/create',
  updateNotice: '/sportat-center/notice/:id/update',
  detailNotice: '/sportat-center/notice/:id/detail',
  guide: '/sportat-center/guide',
  createGuide: '/sportat-center/guide/create',
  updateGuide: '/sportat-center/guide/:id/update',
  detailGuide: '/sportat-center/guide/:id/detail',
  qAnda: '/sportat-center/question-and-answer',
  createQuestion: '/sportat-center/question-and-answer/create',
  detailQuestion: '/sportat-center/question-and-answer/:id/detail',
  updateQuestion: '/sportat-center/question-and-answer/:id/update',
  answerQuestion: '/sportat-center/question-and-answer/:id/answer',
  detailAnswer: '/sportat-center/question-and-answer/:id/detail-answer',
  answerUpdate: '/sportat-center/question-and-answer/:id/update-answer',
  matchCenter: '/match-center/:params',
  coach: '/coach',
  playerAgent: '/player-agent',
  staff: '/staff',
  clubManagement: '/club-management',
};

export default ({ match }) => {
  return (
    <div>
      <Switch>
        {/* prettier-ignore */}
        <PrivateRouteComponent path={`${match.url}category-data`} component={CategoryData}  hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRouteComponent
          path={`${match.url}category-data-trans`}
          component={CategoryDataTrans}
          hasAnyAuthorities={[AUTHORITIES.ADMIN]}
        />
        <PrivateRouteComponent path={path.unitStandard} component={UnitStandard} hasAnyAuthorities={[]} />
        <PrivateRouteComponent path={path.leagueStandard} component={LeagueStandard} hasAnyAuthorities={[]} />
        <PrivateRouteComponent
          path={path.club}
          component={Club}
          hasAnyAuthorities={[AUTHORITIES.TEAM_MANAGER, AUTHORITIES.LEAGUE_MASTER]}
        />
        <PrivateRouteComponent
          path={path.leagueAttendDetail}
          component={LeagueAttendDetail}
          hasAnyAuthorities={[AUTHORITIES.TEAM_MANAGER, AUTHORITIES.ADMIN]}
        />
        <PrivateRouteComponent
          path={path.clubDetail}
          component={ClubUpdate}
          hasAnyAuthorities={[AUTHORITIES.TEAM_MANAGER, AUTHORITIES.LEAGUE_MASTER]}
        />
        <PrivateRouteComponent
          path={path.player}
          component={Player}
          hasAnyAuthorities={[AUTHORITIES.LEAGUE_MASTER, AUTHORITIES.TEAM_MANAGER]}
        />
        <PrivateRouteComponent
          path={path.playerDetail}
          component={PlayerUpdate}
          hasAnyAuthorities={[AUTHORITIES.LEAGUE_MASTER, AUTHORITIES.TEAM_MANAGER]}
        />
        <PrivateRouteComponent
          path={path.createNotice}
          component={CreateNotice}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.LEAGUE_MASTER]}
        />
        <PrivateRouteComponent path={path.detailNotice} component={DetailNotice} hasAnyAuthorities={[]} />
        <PrivateRouteComponent
          path={path.updateNotice}
          component={UpdateNotice}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.LEAGUE_MASTER]}
        />
        <PrivateRouteComponent path={path.createGuide} component={CreateGuide} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRouteComponent path={path.detailGuide} component={DetailGuide} hasAnyAuthorities={[]} />
        <PrivateRouteComponent path={path.updateGuide} component={UpdateGuide} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRouteComponent path={path.notice} component={Notice} hasAnyAuthorities={[]} />
        <PrivateRouteComponent path={path.guide} component={Guide} hasAnyAuthorities={[]} />

        <PrivateRouteComponent path={path.monitor} component={Monitor} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.LEAGUE_MASTER]} />
        <PrivateRouteComponent path={path.stadium} component={Stadium} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.LEAGUE_MASTER]} />
        <PrivateRouteComponent path={path.league} component={League} hasAnyAuthorities={[AUTHORITIES.LEAGUE_MASTER]} />
        <PrivateRouteComponent path={path.leagueAttendDetail1} component={LeagueAttendDetail} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRouteComponent path={path.leagueDetail} component={LeagueUpdate} hasAnyAuthorities={[AUTHORITIES.LEAGUE_MASTER]} />
        <PrivateRouteComponent path={path.match} component={Match} hasAnyAuthorities={[AUTHORITIES.RECORDER, AUTHORITIES.LEAGUE_MASTER]} />
        <PrivateRouteComponent path={path.matchPreparation} component={MatchPreparation} hasAnyAuthorities={[AUTHORITIES.RECORDER]} />
        <PrivateRouteComponent
          path={path.recordMatch}
          component={MatchRecord}
          hasAnyAuthorities={[AUTHORITIES.RECORDER, AUTHORITIES.LEAGUE_MASTER]}
        />
        <PrivateRouteComponent path={path.lookupLeague} component={LookupLeague} />
        <PrivateRouteComponent path={path.lookupTeam} component={LookupTeam} />
        <PrivateRouteComponent path={path.lookupPlayer} component={LookupPlayer} />

        <PrivateRouteComponent path={path.matchCenter} component={MatchCenter} />

        <PrivateRouteComponent path={path.answerUpdate} component={AnswerUpdate} hasAnyAuthorities={[]} />
        <PrivateRouteComponent path={path.detailAnswer} component={DetailAnswer} hasAnyAuthorities={[]} />
        <PrivateRouteComponent path={path.answerQuestion} component={Answer} hasAnyAuthorities={[]} />
        <PrivateRouteComponent path={path.updateQuestion} component={QuestionUpdate} hasAnyAuthorities={[]} />
        <PrivateRouteComponent path={path.detailQuestion} component={DetailQuestion} hasAnyAuthorities={[]} />
        <PrivateRouteComponent path={path.createQuestion} component={CreateQA} hasAnyAuthorities={[]} />
        <PrivateRouteComponent path={path.qAnda} component={QuestionAndAnswer} hasAnyAuthorities={[]} />

        <PrivateRouteComponent path={path.guide} component={Guide} hasAnyAuthorities={[]} />
        <PrivateRouteComponent
          path={path.coach}
          component={Coach}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.LEAGUE_MASTER, AUTHORITIES.TEAM_MANAGER]}
        />
        <PrivateRouteComponent
          path={path.playerAgent}
          component={PlayerAgent}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.LEAGUE_MASTER, AUTHORITIES.TEAM_MANAGER]}
        />

        <PrivateRouteComponent
          path={path.staff}
          component={Staff}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.LEAGUE_MASTER, AUTHORITIES.TEAM_MANAGER]}
        />
        <PrivateRouteComponent
          path={path.clubManagement}
          component={ClubManagement}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.LEAGUE_MASTER, AUTHORITIES.TEAM_MANAGER]}
        />

        {/* <ErrorBoundaryRoute path={`${match.url}player-academic`} component={PlayerAcademic} /> */}
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </Switch>
    </div>
  );
};
