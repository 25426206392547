import React from 'react';
import { Button, Col, Form, Modal, Radio, Row } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import TextField from 'app/modules/lm-input';
import { ICRUDWhitelist, IWhiteList } from 'app/shared/model/user.model';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import { useEffect } from 'react';
import { log, translate, Translate } from 'react-jhipster';
import { createWhitelistEntity, updateWhitelistEntity } from './user-management.reducer';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { regexIP } from 'app/shared/util/regex';
import { pick } from 'lodash';

type Props = {
  title: any;
  isOpen: boolean;
  closeDialog: (updateList?: boolean) => void;
  allowedIP?: IWhiteList;
};

const ipTypes = {
  FIXED_IP: 1,
  IP_RANGE: 2,
};

const INIT_FORM_VALUES = {
  name: '',
  type: ipTypes.FIXED_IP,
  ipFrom: '',
  ipTo: '',
};

function AllowIPEditModal(props: Props) {
  const {
    closeDialog,
    isOpen,
    allowedIP,
    title: { title },
  } = props;

  const ipTypeOptions = [
    {
      label: translate('userManagement.detail.allowIP.required.typeFixed'),
      value: ipTypes.FIXED_IP,
    },
    {
      label: translate('userManagement.detail.allowIP.required.typeRange'),
      value: ipTypes.IP_RANGE,
    },
  ];
  const { id: userId } = useParams<{ id: string; params: string }>();
  const [form] = Form.useForm();
  const ipTypeSelected = Form.useWatch('type', form);

  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(state => state.userManagement);

  const onCloseModal = (updateList?: boolean) => {
    closeDialog(updateList);
    form.resetFields();
  };

  const onFinish = (values: IWhiteList) => {
    const params: ICRUDWhitelist = {
      whitelist: {
        ...allowedIP,
        ...values,
        userId,
      },
    };

    if (allowedIP) {
      params['whitelistId'] = allowedIP.id;
    }

    if (allowedIP) {
      dispatch(updateWhitelistEntity(params)).then((res: any) => {
        if (res.payload?.status === 200) {
          closeDialog(true);
        }
      });
    } else {
      dispatch(createWhitelistEntity(params)).then((res: any) => {
        if (res.payload?.status === 200) {
          closeDialog(true);
          form.resetFields();
        }
      });
    }
  };

  useEffect(() => {
    if (isOpen && allowedIP) {
      const newInitValue: IWhiteList = pick(allowedIP, ['ipFrom', 'ipTo', 'name', 'type']);
      form.setFieldsValue(newInitValue);
    }
  }, [isOpen, allowedIP]);

  return (
    <>
      <Modal
        visible={isOpen}
        onCancel={() => onCloseModal()}
        width={800}
        title={title}
        wrapClassName="management-wrapper-modal"
        footer={null}
      >
        <Form
          form={form}
          name="control-hooks"
          initialValues={INIT_FORM_VALUES}
          onFinishFailed={values => handleFocusFieldFail(values)}
          scrollToFirstError
          onFinish={onFinish}
          layout="vertical"
          requiredMark={'optional'}
        >
          <Row gutter={[24, 24]}>
            <Col xs={24} md={24}>
              <Form.Item
                name="name"
                label={<h6>{translate('userManagement.detail.allowIP.required.name')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('userManagement.detail.allowIP.required.nameMessage'),
                  },
                ]}
              >
                <TextField maxLength={50} placeholder={translate('userManagement.detail.allowIP.placeholder.name')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="type"
                label={<h6>{translate('userManagement.detail.allowIP.required.type')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('userManagement.detail.allowIP.required.typeMessage'),
                  },
                ]}
              >
                <Radio.Group options={ipTypeOptions}></Radio.Group>
              </Form.Item>
            </Col>
            {ipTypeSelected === ipTypes.FIXED_IP && (
              <Col xs={24} md={12}>
                <Form.Item
                  name="ipFrom"
                  label={<h6>{translate('userManagement.detail.allowIP.required.ip')}</h6>}
                  rules={[
                    {
                      required: true,
                      message: translate('userManagement.detail.allowIP.required.ipMessage'),
                    },
                    {
                      pattern: new RegExp(regexIP),
                      message: translate('userManagement.detail.allowIP.invalid.ip'),
                    },
                  ]}
                >
                  <TextField
                    maxLength={50}
                    placeholder={translate('userManagement.detail.allowIP.placeholder.ip')}
                    // onChange={onIPFromChange}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          {ipTypeSelected === ipTypes.IP_RANGE && (
            <Row gutter={[24, 24]}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="ipFrom"
                  label={<h6>{translate('userManagement.detail.allowIP.required.fromIP')}</h6>}
                  rules={[
                    {
                      required: true,
                      message: translate('userManagement.detail.allowIP.required.fromIPMessage'),
                    },
                    {
                      pattern: new RegExp(regexIP),
                      message: translate('userManagement.detail.allowIP.invalid.ip'),
                    },
                  ]}
                >
                  <TextField maxLength={50} placeholder={translate('userManagement.detail.allowIP.placeholder.ip')} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="ipTo"
                  label={<h6>{translate('userManagement.detail.allowIP.required.toIP')}</h6>}
                  rules={[
                    {
                      required: true,
                      message: translate('userManagement.detail.allowIP.required.toIPMessage'),
                    },
                    {
                      pattern: new RegExp(regexIP),
                      message: translate('userManagement.detail.allowIP.invalid.ip'),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value) {
                          const ipToValue = value.split('.');
                          ipToValue.splice(-1);

                          const ipFromValue = getFieldValue('ipFrom');
                          const ipTailFromValue = Number(ipFromValue.split('.').splice(-1).join(''));
                          const ipTailToValue = Number(value.split('.').splice(-1).join(''));

                          if (ipFromValue.includes(ipToValue.join('.')) && ipTailFromValue < ipTailToValue) {
                            return Promise.resolve();
                          } else if (ipFromValue.includes(ipToValue.join('.')) && ipTailFromValue >= ipTailToValue) {
                            return Promise.reject(translate('userManagement.detail.allowIP.invalid.tailIP'));
                          } else {
                            return Promise.reject(translate('userManagement.detail.allowIP.invalid.match'));
                          }
                        } else {
                          return Promise.reject();
                        }
                      },
                    }),
                  ]}
                >
                  <TextField maxLength={50} placeholder={translate('userManagement.detail.allowIP.placeholder.ip')} />
                </Form.Item>
              </Col>
            </Row>
          )}

          <div className="management-modal-footer">
            <Button key="back" onClick={() => onCloseModal()}>
              <Translate contentKey="register.form.close" />
            </Button>
            <Button key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }} disabled={loading}>
              <Translate contentKey="settings.form.button" />
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default AllowIPEditModal;
