import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Col, Input, Select, Tag } from 'antd';
import { formatDate2, formatHour, formatYear } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { LeagueListFilter } from './league.type';
import {
  getEntities,
  getEntity,
  clearMsgDelete,
  deleteEntity,
  clearMsgUpdate,
  clearMsgCreate,
  updateEntity,
  addFavourite,
  deleteFavourite,
} from './league.reducer';
import { MatchStatusContained } from 'app/modules/status-contained';
import dayjs from 'dayjs';
import LeagueDeleteDialog from './league-delete-dialog';
import LeagueAddDialog from './league-add-dialog';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import ModalCreateLeague from './league-add-dialog';
import { toast } from 'react-toastify';
import { path } from '../routes';
import { Link, useHistory } from 'react-router-dom';
import Filter from 'app/modules/filter';
import { PlusIcon, StarActive, StarInactive } from 'app/shared/util/appIcon';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';
import LeagueStandardCreateDialog from '../league-standard/league-standard-create-dialog';
import './style.scss';
import { IsFavorite } from 'app/shared/util/constant/favorite.constant';
import { TYPE_OF_OPTION } from 'app/shared/util/constant';
import MultiFilter from 'app/modules/MultiSearch';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
export const League = (props: any) => {
  const dispatch = useAppDispatch();
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [leagueStandardId, setLeagueStandardId] = useState();
  const [type, setType] = useState('');
  const account = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
    type: 'name',
  };
  const deleteSuccess = useAppSelector(state => state.league.deleteSuccess);
  // const createdSuccess = useAppSelector(state => state.player.createPlayerSuccess);
  const links = useAppSelector(state => state.player.links);
  const leagueList = useAppSelector(state => state.league.entities);
  const loading = useAppSelector(state => state.league.loading);
  const createSuccess = useAppSelector(state => state.league.createSuccess);

  const { push } = useHistory();
  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.league.delete.success'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (createSuccess) {
      toast.success(translate('leagueApp.league.created.success'));
      dispatch(clearMsgCreate(createSuccess));
      handleFetchData({ ...filter, page: 0 });
    }
  }, [createSuccess]);

  const handleDeleteModal = (user: any) => {
    setUserClicked(user);
    setModalDelete(true);
  };

  const handleFetchData = React.useCallback((params: LeagueListFilter) => {
    dispatch(getEntities(params));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const ActionButton = ({ id }: { id: number }) => {
    return (
      <div className={isAdmin && 'column-action-button'}>
        <Button
          onClick={e => {
            push(path.leagueDetail.replace(':id', id.toString()));
            e.stopPropagation();
          }}
          className="rounded"
        >
          <Translate contentKey="leagueApp.match.details" />
        </Button>
        {isAdmin && (
          <Button
            onClick={e => {
              handleDeleteModal(id);
              e.stopPropagation();
            }}
            className="rounded"
            type="primary"
            danger
          >
            <Translate contentKey="entity.action.delete2">Delete</Translate>
          </Button>
        )}
      </div>
    );
  };

  const columns = [
    {
      dataIndex: 'favourite',
      key: 'favourite',
      render: (value, record) => (
        <button
          style={{ background: 'transparent', width: '40px', border: 'none' }}
          onClick={e => {
            dispatch(value === IsFavorite.TRUE ? deleteFavourite(record?.id) : addFavourite({ leagueId: record?.id })).then(res => {
              if (res.meta.requestStatus === 'fulfilled') {
                dispatch(getEntities(filter));
              }
            });

            e.stopPropagation();
          }}
        >
          {value === IsFavorite.TRUE ? <StarActive /> : <StarInactive />}
        </button>
      ),
    },
    {
      title: '',
      dataIndex: 'base64LeagueStandard',
      key: 'base64LeagueStandard',
      width: 50,
      render: (value, record) => <Avatar shape="square" src={value} size="large" />,
    },
    {
      title: <Translate contentKey="leagueApp.league.leagueStandardCode" />,
      dataIndex: 'leagueStandardName',
      key: 'leagueStandardName',
      // render: (value, record) => (
      //   <span className="detail-click" onClick={() => handleDetailStandardLeague(record?.leagueStandardId)}>
      //     {value}
      //   </span>
      // ),
    },
    {
      title: <Translate contentKey="leagueApp.league.name" />,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <Translate contentKey="leagueApp.league.issueYear" />,
      dataIndex: 'issueYear',
      key: 'issueYear',
      render: (value, record) => formatYear(value),
    },
    {
      title: <Translate contentKey="leagueApp.league.startDate" />,
      dataIndex: 'startDate',
      key: 'startDate',
      render: (value, record) => formatDate2(value),
      align: 'center' as any,
    },
    {
      title: <Translate contentKey="leagueApp.league.endDate" />,
      dataIndex: 'endDate',
      key: 'endDate',
      render: (value, record) => formatDate2(value),
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 230,
      render: (value, record) => <ActionButton id={value} />,
      align: 'center' as any,
    },
  ];

  const options = [
    {
      value: 'name',
      label: translate('leagueApp.league.name'),
    },
    {
      value: 'leagueStandardName',
      label: translate('leagueApp.league.leagueStandardCode'),
    },
    {
      value: 'issueYear',
      label: translate('leagueApp.league.issueYear'),
      type: TYPE_OF_OPTION.YEAR,
    },
    // {
    //   value: 'startDate',
    //   label: translate('leagueApp.league.startDate'),
    //   type: TYPE_OF_OPTION.DATE,
    // },
    // {
    //   value: 'endDate',
    //   label: translate('leagueApp.league.endDate'),
    //   type: TYPE_OF_OPTION.DATE,
    // },
  ];

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const confirmDelete = () => {
    dispatch(deleteEntity(userClicked)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        updateFilter();
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const updateFilter = () => {
    onFilterToQueryString({
      page: 0,
      size: 10,
      sort: 'id,asc',
      type: 'name',
    });
    setReset(!reset);
  };
  return (
    <div className="management-layout">
      {detailModal && (
        <LeagueStandardCreateDialog
          showDialog={detailModal}
          setShowDialog={b => setDetailModal(b)}
          setEntityId={setLeagueStandardId}
          type={type}
          entityId={leagueStandardId}
        />
      )}
      {isOpen && <LeagueAddDialog isOpen={isOpen} setIsOpen={b => setIsOpen(b)} updateFilter={updateFilter} />}
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading">
            <Translate contentKey="leagueApp.league.home.title">Leagues</Translate>
          </h2>
          <div className="management-layout-divider"></div>
          <Button className="rounded management-header-add-button" onClick={handleOpenModal} icon={<PlusIcon />} type="primary">
            <Translate contentKey="clubmng.add" />
          </Button>
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <Filter filter={filter} onFilter={onFilterToQueryString} options={options} multi reset={reset} />
        </Col>
      </div>
      <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
      <div className="table-responsive">
        <Table
          onRow={r => ({
            onClick: e => push(path.leagueDetail.replace(':id', r?.id.toString())),
          })}
          columns={columns}
          dataSource={leagueList.content}
          loading={loading}
          filter={filter}
          totalElements={leagueList.totalElements || 0}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('leagueApp.league.delete.question')}</h6>}
          onClose={() => setModalDelete(false)}
          onConfirm={() => confirmDelete()}
        />
      )}
    </div>
  );
};

export default League;
