import { Droppable } from 'react-beautiful-dnd';
import React from 'react';
import styled from 'styled-components';
import ListItem from './list-item';
import { IPlayerInMatch } from 'app/shared/model/player.model';
import { get } from 'lodash';

const DroppableStyles = styled.div`
  padding: 5px;
  border-radius: 6px;
  height: 100%;
  [data-rbd-droppable-context-id] {
    min-height: 120px;
    min-width: 100px;
  }
  + div {
    height: 100%;
    display: flex;
  }
`;

const NewLineUp = styled.div`
  display: grid;
  align-items: center;
  height: 100%;
  grid-gap: 20px;
`;

const DraggableElement = ({ prefix, elements, onClickPlayer, avatars, timeOfRound, timeOfRoundExtra, draggable }: any) => (
  <DroppableStyles>
    <NewLineUp className="h-100">
      <Droppable droppableId={`${prefix}`}>
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {elements.map((item: IPlayerInMatch, index: number) => (
              <ListItem
                draggable={draggable}
                onClickPlayer={(player: IPlayerInMatch) => onClickPlayer && onClickPlayer(player)}
                key={item.matchPlayerFieldDTO.playerDTOS?.id.toString()}
                prefix={prefix}
                item={item}
                index={index}
                timeOfRound={timeOfRound}
                timeOfRoundExtra={timeOfRoundExtra}
                avatar={
                  get(avatars, item.matchPlayerFieldDTO.playerDTOS?.id) ? get(avatars, item.matchPlayerFieldDTO.playerDTOS?.id) : undefined
                }
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </NewLineUp>
  </DroppableStyles>
);

export default DraggableElement;
