import SockJS from 'sockjs-client';

import Stomp from 'webstomp-client';
import { Observable } from 'rxjs';
import { Storage } from 'react-jhipster';

import { websocketActivityMessage } from 'app/modules/administration/administration.reducer';
import { getAccount, logoutSession } from 'app/shared/reducers/authentication';
import { debounce } from 'lodash';

let stompClient = null;

let subscriber = null;
let connection: Promise<any>;
let connectedPromise: any = null;
let listener: Observable<any>;
let listenerObserver: any;
let alreadyConnectedOnce = false;
let timeReconnect = 1000;

const createConnection = (): Promise<any> => new Promise(resolve => (connectedPromise = resolve));

const createListener = (): Observable<any> =>
  new Observable(observer => {
    listenerObserver = observer;
  });

export const sendActivity = (page: string) => {
  connection?.then(() => {
    stompClient?.send(
      '/topic/activity', // destination
      JSON.stringify({ page }), // body
      {} // header
    );
  });
};

export const sendWSActivity = (url: string, page: any) => {
  connection?.then(() => {
    stompClient?.send(
      url, // destination
      JSON.stringify(page), // body
      {} // header
    );
  });
};

export const subscribe = () => {
  connection.then(() => {
    subscriber = stompClient.subscribe('/topic/tracker', data => {
      listenerObserver.next(JSON.parse(data.body));
    });
  });
};

export const connect = (changeConnectStatus: (connectStatus: boolean) => void) => {
  if (connectedPromise !== null || alreadyConnectedOnce) {
    // the connection is already being established
    return;
  }
  connection = createConnection();
  listener = createListener();
  // building absolute path so that websocket doesn't fail when deploying with a context path
  // const loc = '192.168.2.68:8084';
  const loc = window.location;
  const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

  const headers = {};
  const local = loc.host === 'localhost:9000' ? '192.168.2.68:8084' : loc.host;
  // const local ='localhost:8080';

  let url = '//' + local + baseHref + '/websocket/tracker';
  // let url = '//' + loc + baseHref + '/websocket/tracker';
  const authToken = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');

  if (authToken) {
    url += '?access_token=' + authToken;
  }
  const socket = new SockJS(url);
  stompClient = Stomp.over(socket, { protocols: ['v12.stomp'] });
  function heartbeat() {
    if (!socket) return;
    if (socket.readyState !== 1) {
      return;
    }

    setTimeout(heartbeat, 1000);
  }
  stompClient.connect(
    headers,
    () => {
      connectedPromise('success');
      connectedPromise = null;
      sendActivity(window.location.pathname);
      alreadyConnectedOnce = true;
      heartbeat();
      changeConnectStatus(true);
    },
    () => {
      disconnect();
      const reconnect = debounce(() => {
        connectedPromise = null;
        alreadyConnectedOnce = false;
        connect(changeConnectStatus);
        if (timeReconnect < 4000) {
          timeReconnect = timeReconnect * 2;
        } else {
          timeReconnect = 5000;
        }
        changeConnectStatus(false);
      }, timeReconnect);
      reconnect();
    }
  );
};

export const disconnect = () => {
  if (stompClient !== null) {
    if (stompClient.connected) {
      stompClient.disconnect();
    }
    stompClient = null;
  }
  alreadyConnectedOnce = false;
};

const receive = () => listener;

export const unsubscribe = () => {
  if (subscriber !== null) {
    subscriber.unsubscribe();
  }
  listener = createListener();
};

export default store => next => action => {
  // if (getAccount.fulfilled.match(action)) {
  //   connect();
  //   const isAdmin = action.payload.data.authorities.includes('ROLE_ADMIN');
  //   if (!alreadyConnectedOnce && isAdmin) {
  //     subscribe();
  //     receive().subscribe(activity => {
  //       return store.dispatch(websocketActivityMessage(activity));
  //     });
  //   }
  // } else if (getAccount.rejected.match(action) || action.type === logoutSession().type) {
  //   unsubscribe();
  //   disconnect();
  // }
  return next(action);
};

export const subscribePlayerBan = (id: number, listPlayer) => {
  connection.then(() => {
    subscriber = stompClient.subscribe(`/topic/match/${id}`, data => {
      listPlayer(data.body);
      // listenerObserver.next(JSON.parse(data.body));
      return JSON.parse(data.body);
    });
  });
};

export const subscribeList = (url: string, setPlayers) => {
  connection.then(() => {
    subscriber = stompClient.subscribe(url, data => {
      setPlayers(JSON.parse(data.body));
      return JSON.parse(data.body);
    });
  });
};

export const subscribeTimeMatch = (id: number, dispatchMatchTime) => {
  connection.then(() => {
    subscriber = stompClient.subscribe(`/topic/match-time/${id}`, data => {
      dispatchMatchTime(JSON.parse(data.body));
      // listenerObserver.next(JSON.parse(data.body));
      return JSON.parse(data.body);
    });
  });
};

export const subscribeTimeMatchFinish = (id: number, dispatchMatchInfo) => {
  connection.then(() => {
    subscriber = stompClient.subscribe(`/topic/match-info/${id}`, data => {
      dispatchMatchInfo(JSON.parse(data.body));
      // listenerObserver.next(JSON.parse(data.body));
      return JSON.parse(data.body);
    });
  });
};

export const subscribeTopic = (url, dispatch) => {
  connection.then(() => {
    subscriber = stompClient.subscribe(url, data => {
      dispatch(JSON.parse(data.body));
      // listenerObserver.next(JSON.parse(data.body));
      return JSON.parse(data.body);
    });
  });
};

export const sendRecord = (url: string, data: any) => {
  connection?.then(() => {
    stompClient?.send(
      url, // destination
      JSON.stringify(data), // body
      {} // header
    );
  });
};
export const subscribePlayerPenalty = (id: number) => {
  connection.then(() => {
    subscriber = stompClient.subscribe(`/topic/player-penalty/${id}`, data => {
      return JSON.parse(data.body);
    });
  });
};
export const subscribePlayerShooting = (id: number) => {
  connection.then(() => {
    subscriber = stompClient.subscribe(`/topic/player-shoot/${id}`, data => {
      return JSON.parse(data.body);
    });
  });
};

export const subscribePlayerShoot = (id: number) => {
  connection.then(() => {
    subscriber = stompClient.subscribe(`/topic/player-shoot/${id}`, data => {
      return JSON.parse(data.body);
    });
  });
};

export const subscribeGoalKick = (id: number) => {
  connection.then(() => {
    subscriber = stompClient.subscribe(`/topic/player-goal/${id}`, data => {
      return JSON.parse(data.body);
    });
  });
};

export const subscribePenaltyShootOut = (matchId: number, setData) => {
  connection.then(() => {
    subscriber = stompClient.subscribe(`/topic/penalty-shoot-out/${matchId}`, data => {
      setData(JSON.parse(data.body));
      return JSON.parse(data.body);
    });
  });
};

export const subscribeListEvent = (url: string, setData) => {
  connection.then(() => {
    subscriber = stompClient.subscribe(`/${url}`, data => {
      setData(JSON.parse(data.body));
      return JSON.parse(data.body);
    });
  });
};

export const subscribeEndMatch = (matchId: number, setData) => {
  connection.then(() => {
    subscriber = stompClient.subscribe(`/topic/after-match/${matchId}`, data => {
      setData(JSON.parse(data.body));
      return JSON.parse(data.body);
    });
  });
};
