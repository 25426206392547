import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'antd';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity, clearMsgDelete } from './unit-standard.reducer';
import { toast } from 'react-toastify';

export const UnitStandardDeleteDialog = (props: any) => {
  const { modalDelete, setModalDelete, userClicked } = props;
  const [loadModal, setLoadModal] = useState(false);
  const dispatch = useAppDispatch();

  // const id = props.match.params.id;

  // useEffect(() => {
  //   // dispatch(getEntity(props.match.params.id));
  //   setLoadModal(true);
  // }, []);

  const unitStandardEntity = useAppSelector(state => state.unitStandard.entity);
  const deleteSuccess = useAppSelector(state => state.unitStandard.deleteSuccess);

  const handleClose = () => {
    // props.history.push('/unit-standard' + props.location.search);
    setModalDelete(false);
  };

  useEffect(() => {
    if (deleteSuccess && loadModal) {
      handleClose();
      toast.success(translate('leagueApp.unitStandard.delete.success'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);

  const confirmDelete = () => {
    const obj = {
      unitStandardId: userClicked,
    };
    dispatch(deleteEntity(obj));
    setModalDelete(false);
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="unitStandardDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="leagueApp.unitStandard.delete.question">
        <Translate contentKey="leagueApp.unitStandard.delete.question">Are you sure you want to delete this UnitStandard?</Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-unitStandard" data-cy="entityConfirmDeleteButton" type="primary" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete2">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UnitStandardDeleteDialog;
