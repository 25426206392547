import React from 'react';
import { Button, Checkbox, Col, Form, Input, Row, Tooltip } from 'antd';
import ToastDialog from 'app/shared/util/toastDialog/ToastDialog';
import './index.scss';
import { useAppSelector } from 'app/config/store';
import { translate } from 'react-jhipster';
import { ChervonLeft, ChervonRightGray } from 'app/shared/util/appIcon';
import { LockLoginIcon } from 'app/shared/util/authIcon';
import { useRequest } from 'ahooks';
import { changePasswordRequest } from './account.service';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

type Props = any;

const AccountChangePassword = (props: Props) => {
  const account = useAppSelector(state => state.authentication.account);

  const history = useHistory();
  const [form] = Form.useForm();
  const requestChangePass = useRequest(changePasswordRequest, {
    manual: true,
    onSuccess(res: any) {
      toast.success(translate('common.saveSuccess'));
      history.push('/account-management');
    },
    onError(rej: any) {
      if (rej.response.data.status === 400) {
        form.setFields([
          {
            name: ['password'],
            errors: [translate('account.message.current_password_incorrect')],
          },
        ]);
      }
    },
  });

  const [confirmDialog, setConfirmDialog] = React.useState<boolean>(false);
  const [toastElement, setToastElement] = React.useState<React.ReactElement>(null);

  const handleSubmit = value => {
    const submitValue = {
      idUser: account.id,
      ...value,
    };
    requestChangePass.run(submitValue);
  };

  return (
    <Row align="middle" justify="center" style={{ flexGrow: 1 }}>
      <Col xl={8} lg={12} md={16} xs={24}>
        <div>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <div className="account-card">
                <div className="account-card-header header-has-border">
                  <p className="header-title heading-24-bold neutral-1">{translate('account.title.change_password')}</p>
                  <p className="header-subtitle heading-16-medium neutral-3">{translate('account.subtitle.change_password')}</p>
                </div>
                <div className="account-card-body">
                  <Form
                    form={form}
                    name="control-hooks"
                    layout="vertical"
                    onFinish={value => {
                      handleSubmit(value);
                    }}
                    requiredMark={'optional'}
                  >
                    <Row gutter={[12, 12]}>
                      <Col span={24}>
                        <Form.Item
                          name="password"
                          label={translate('account.form.current_password')}
                          rules={[
                            {
                              required: true,
                              message: translate('account.required.current_password'),
                            },
                          ]}
                        >
                          <Input.Password prefix={<LockLoginIcon />} placeholder={translate('global.form.password.placeholder')} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="newPass"
                          label={translate('account.form.new_password')}
                          rules={[
                            {
                              required: true,
                              message: translate('account.required.new_password'),
                            },
                            { max: 16, message: translate('global.messages.validate.newpassword.maxlength') },
                            { min: 8, message: translate('global.messages.validate.newpassword.minlength') },
                            {
                              required: true,
                              // pattern: new RegExp(/^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-~`_+=:;"',.?/])/),
                              // pattern: new RegExp(/^(?=.*?[A-Za-z])(?=.*?[0-9])([#?!@$%^&*,.<>()_-])/),
                              pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/),
                              message: translate('global.messages.validate.newpassword.specialCharacter'),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value.match(/^(?=.*[~`+=:;"',.?/|{}[\]<>])/)) {
                                  return Promise.reject(new Error(translate('global.messages.validate.newpassword.allowCharacter')));
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input.Password prefix={<LockLoginIcon />} placeholder={translate('register.password.placeholder')} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="confirmPassword"
                          label={translate('account.form.confirm_password')}
                          dependencies={['newPass']}
                          rules={[
                            {
                              required: true,
                              message: translate('account.required.confirm_password'),
                            },
                            { max: 16, message: translate('global.messages.validate.newpassword.maxlength') },
                            { min: 8, message: translate('global.messages.validate.newpassword.minlength') },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue('newPass') === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error(translate('global.messages.error.dontmatch')));
                              },
                            }),
                          ]}
                        >
                          <Input.Password prefix={<LockLoginIcon />} placeholder={translate('global.form.confirmpassword.placeholder')} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="w-100"
                          style={{ height: 56 }}
                          loading={requestChangePass.loading}
                        >
                          {translate('account.button.save')}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
          <ToastDialog
            open={confirmDialog}
            onOk={() => {
              setConfirmDialog(false);
            }}
            content={toastElement}
          />
        </div>
      </Col>
    </Row>
  );
};

export default AccountChangePassword;
