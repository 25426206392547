import './login.scss';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Col, Row, Tooltip } from 'antd';
import { hideSideBar } from 'app/shared/reducers/application-profile';
import { AuthenticationSlice, clearMsg, login } from 'app/shared/reducers/authentication';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Storage, Translate, translate, ValidatedField } from 'react-jhipster';
import { Link, Redirect, Route, useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { message, Form, Input, Button } from 'antd';
import { MailLoginIcon, LockLoginIcon, EyeShowLoginIcon, EyeHideLoginIcon, LogoLogin } from 'app/shared/util/authIcon';
import TextField from 'app/modules/lm-input';

// import { Form, Input, Button, Checkbox } from 'antd';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { toast } from 'react-toastify';
import { height } from '@fortawesome/free-solid-svg-icons/faCogs';
import ToastDialog from 'app/shared/util/toastDialog/ToastDialog';
import dayjs from 'dayjs';
import { is } from 'immer/dist/internal';
import { disconnect } from 'app/config/websocket-middleware';
import { ModalPolicy } from 'app/entities/privacy-policy/policy-model';
import { ModalPrivacy } from 'app/entities/privacy-policy/privacy-modal';
import { VFF, VFFLogo } from 'app/shared/util/sidebarIcon';

export interface ILoginModalProps {
  loginError: boolean;
  handleLogin: (username: string, password: string, rememberMe: boolean) => void;
  handleClose: () => void;
}

export const Login = (props: ILoginModalProps) => {
  const dispatch = useAppDispatch();
  const [confirmDialog, setConfirmDialog] = React.useState<boolean>(false);
  const [toastElement, setToastElement] = React.useState<React.ReactElement>(null);
  const { actions } = AuthenticationSlice;
  const [openPolicy, setOpenPolicy] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const account = useAppSelector(state => state.authentication.account);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const AUTH_TOKEN_KEY = 'jhi-authenticationToken';
  const token = Storage.local.get(AUTH_TOKEN_KEY) || Storage.session.get(AUTH_TOKEN_KEY);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated === true) {
      history.push('/main');
      return;
    }
    if (token) {
      actions.changeAuthenticate();
      return;
    }
  }, [isAuthenticated, token]);

  const logins = ({ username, password, rememberMe }) => {
    dispatch(login(username, password, onLoginSuccess, onFail, rememberMe));
  };

  const errorMesssage = useAppSelector(state => state.authentication.loginError);
  const errorMsg = useAppSelector(state => state.authentication.errorMessage);

  // useEffect(() => {
  //   if (errorMesssage) {
  //     setToastElement(translate(errorMesssage));
  //     // dispatch(clearMsg(errorMesssage));
  //   }
  // }, [errorMesssage]);

  // useEffect(() => {
  //   if (errorMsg === 'Request failed with status code 400') {
  //     setToastElement(translate('testing));
  //   }
  // }, [errorMsg]);

  const { loginError } = props;

  const handleLoginSubmit = values => {
    const valueSubmit = {
      ...values,
      rememberMe: !!values.rememberMe,
    };
    logins(valueSubmit);
  };

  const onFinishFail = () => {
    setToastElement(
      <>
        <Translate contentKey="login.messages.error.validate"></Translate>
      </>
    );
  };

  // React.useEffect(() => {
  //   if (loginError) {
  //     setToastElement(
  //       <>
  //         <Translate contentKey="login.messages.error.authentication"></Translate>
  //         <div>
  //           <strong>Failed to sign in!</strong>
  //         </div>
  //         <div>Please check your credentials and try again.</div>
  //       </>
  //     );
  //   }
  // }, [loginError]);

  const onFail = () => {
    setToastElement(
      <>
        <Translate contentKey="login.messages.error.authentication"></Translate>
      </>
    );
  };
  React.useEffect(() => {
    if (toastElement) setConfirmDialog(true);
    else setConfirmDialog(false);
  }, [toastElement]);

  const onLoginSuccess = () => {
    history.push('/main');
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '120px 30px 30px 30px',
        minHeight: '100vh',
      }}
    >
      <Form
        className="login-form"
        layout="vertical"
        onFinish={value => handleLoginSubmit(value)}
        // onFinishFailed={() => onFinishFail()}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
        requiredMark={'optional'}
      >
        {/* {loginError ? (
          <Alert color="danger" data-cy="loginError">
            <Translate contentKey="login.messages.error.authentication">
              <strong>Failed to sign in!</strong> Please check your credentials and try again.
            </Translate>
          </Alert>
        ) : null} */}

        <Row style={{ width: '100%' }} align="middle" justify="center">
          <VFF width={150} height={50} fill={'#f0f2f5'} />
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item
              name="username"
              label={translate('global.form.email.label')}
              rules={[
                { required: true, message: translate('login.required.email') },
                {
                  type: 'email',
                  message: translate('login.required.emailInvalid'),
                },
              ]}
              data-cy="username"
            >
              <TextField
                placeholder={translate('global.form.email.placeholder')}
                prefix={<MailLoginIcon></MailLoginIcon>}
                // isTouched={touchedFields.username}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="password"
              label={translate('global.form.password.label')}
              rules={[{ required: true, message: translate('login.required.password') }]}
              style={{ width: '100%', marginBottom: 0 }}
              data-cy="password"
            >
              <Input.Password
                placeholder={translate('global.form.password.placeholder')}
                prefix={<LockLoginIcon />}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="rememberMe"
          style={{ width: '100%', marginBottom: 0 }}
          valuePropName="checked"
          data-cy="rememberMe"
          className="login-checkbox"
        >
          <Checkbox>{translate('login.form.rememberme')}</Checkbox>
        </Form.Item>
        <Row style={{ width: '100%' }}>
          <Button style={{ width: '100%' }} htmlType="submit" data-cy="submit" type="primary" className="auth-button">
            <Translate contentKey="login.form.button">Sign in</Translate>
          </Button>
        </Row>
        <Row style={{ width: '100%', marginTop: 32 }}>
          <Col span={12} style={{ paddingRight: 16 }}>
            <Button style={{ width: '100%' }} className="btn-white auth-button">
              <Link to="/account/findid/request">
                <Translate contentKey="login.password.forgot">Find ID</Translate>
              </Link>
            </Button>
          </Col>
          <Col span={12} style={{ paddingLeft: 16 }}>
            <Button style={{ width: '100%' }} className="auth-button">
              <Link to="/account/reset/request">
                <Translate contentKey="global.messages.info.register.noaccount">Change password</Translate>
              </Link>
            </Button>
          </Col>
          {/* <div style={{ marginTop: '20px', width: '340px', display: 'flex', justifyContent: 'space-between' }}>
      </div>
      <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'row' }}>
        <Link to="/account/register">
          <Translate contentKey="global.messages.info.register.link">Register a new account</Translate>
          <Tooltip title={<Translate contentKey="global.messages.info.register.tooltip"></Translate>}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Link>
      </div> */}
        </Row>
        {/* <Row style={{ width: '100%', marginTop: 32 }} align="middle" justify="center">
          <Link to="/account/register">
            <Translate contentKey="global.messages.info.register.link">Register a new account</Translate>
          </Link>
          <Tooltip
            overlayStyle={{ maxWidth: 280 }}
            placement="rightBottom"
            title={<Translate contentKey="global.messages.info.register.tooltip"></Translate>}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Row> */}
      </Form>
      <ToastDialog
        open={confirmDialog}
        onOk={() => {
          setToastElement(null);
          setConfirmDialog(false);
        }}
        content={toastElement}
        buttonText={translate('confirmDialog.confirmToast')}
      />
      <div style={{ marginTop: '20px', flexGrow: 1, display: 'flex', alignItems: 'end' }}>
        <Translate contentKey="footer.copyright" />
      </div>
      <div>
        <span
          onClick={() => {
            setOpenPolicy(true);
          }}
          className="privacy"
          style={{ marginRight: 40 }}
        >
          {translate('footer.privacyLogin')}
        </span>
        <span onClick={() => setOpenPrivacy(true)} className="privacy">
          {translate('footer.termLogin')}
        </span>
      </div>
      {openPrivacy && <ModalPrivacy openPrivacy={openPrivacy} setOpenPrivacy={setOpenPrivacy} />}
      {openPolicy && <ModalPolicy openPolicy={openPolicy} setOpenPolicy={setOpenPolicy} />}
    </div>
  );
};

export default Login;
