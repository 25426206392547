import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Form, Input, Upload, Button, Modal, Radio, Select, Row, Col, ConfigProvider, Empty } from 'antd';
import './popup.scss';
import { toast } from 'react-toastify';
import { createEntity, getEntity, reset, updateEntity, clearMsgUpdate, clearMsgCreate } from './league-standard.reducer';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getBase64, getImage, upLoadImg, UploadType } from 'app/shared/reducers/reducer.utils';
import type { RadioChangeEvent } from 'antd';
import TextField from 'app/modules/lm-input';
import { SearchIcon, SelectArrowDown } from 'app/shared/util/appIcon';
import { handleFocusFieldFail, validateSizeImage } from 'app/shared/util/entity-utils';
import { resetValue } from '../record-room/recordRoom.reducer';
export const LeagueStandardCreateDialog = (props: any) => {
  const { showDialog, setShowDialog, entityId, setEntityId, updateFilter, type } = props;
  const dispatch = useAppDispatch();
  const updateSuccess = useAppSelector(state => state.leagueStandard.updateSuccess);
  const createSuccess = useAppSelector(state => state.leagueStandard.createSuccess);
  const leagueStandardEntity = useAppSelector(state => state.leagueStandard.entity);
  const [previewImg, setPreviewImg] = useState('');
  const [file, setFile] = useState<File>(null);
  const [disable, setDisable] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [disableView, setDisableView] = useState(false);

  const [entitySave, setEntitySave] = useState({
    avatar: '',
    name: '',
    classify: '',
    unitStandardName: '',
    managerName: '',
    description: '',
  });

  useEffect(() => {
    getAllUnit();
  }, []);

  useEffect(() => {
    if (type === 'view') {
      setDisableView(true);
    } else {
      setDisableView(false);
    }
  }, [type]);

  useEffect(() => {
    if (leagueStandardEntity?.unitStandardId) {
      getUsers(leagueStandardEntity?.unitStandardId);
    }
  }, [leagueStandardEntity?.unitStandardId]);
  const [dataId, setDataId] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const [listUnit, setListUnit] = useState([]);
  const [listUser, setListUser] = useState([]);
  const [resetSelect, setResetSelect] = useState(null);
  const [classify, setClassify] = useState([
    {
      value: 1,
      label: translate('leagueApp.leagueStandard.league'),
    },
    {
      value: 2,
      label: translate('leagueApp.leagueStandard.cup'),
    },
  ]);

  useEffect(() => {
    if (updateSuccess) {
      setShowDialog(false);
      setEntityId(null);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createSuccess) {
      setShowDialog(false);
      setEntityId(null);
    }
  }, [createSuccess]);

  const getAllUnit = async () => {
    const result = await axios.get('api/unit-standards/search');
    const arr = result.data.content.map(v => {
      return {
        value: v.id,
        label: v.name,
      };
    });
    setListUnit(arr);
  };

  async function getUsers(id: any) {
    const resultUsers = await axios.get(`api/league-standards/users/${id}`);
    const arr = resultUsers.data.map(v => {
      return {
        value: v.id,
        label: v.fullName,
      };
    });
    setListUser(arr);
  }

  useEffect(() => {
    const c = !entityId;
    setIsNew(c);
    if (!c) {
      handleUpdate(entityId);
    }
  }, [entityId]);

  useEffect(() => {
    if (!entityId) return;
    if (leagueStandardEntity && leagueStandardEntity.id) {
      form.setFieldsValue({
        ...leagueStandardEntity,
        unitStandardId: leagueStandardEntity?.unitStandardId,
        managerId: leagueStandardEntity?.managerId,
        unitStandardName: leagueStandardEntity?.unitStandardName,
        managerName: leagueStandardEntity?.managerName,
      });
      setEntitySave({ ...leagueStandardEntity });
      if (leagueStandardEntity.avatar) {
        getImage(leagueStandardEntity.avatar, UploadType.AVATAR_LEAGUE_STANDARD).then(result => {
          setPreviewImg('data:image/png;base64,' + result.data);
        });
      } else {
        setPreviewImg(null);
        setFile(null);
      }
      if (leagueStandardEntity?.unitStandardId) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    }
  }, [leagueStandardEntity, entityId]);

  function saveData(data: any, oldAvatar) {
    if (!isNew && leagueStandardEntity.id) {
      data = {
        ...leagueStandardEntity,
      };
    }
    data.name = form.getFieldValue('name');
    data.classify = form.getFieldValue('classify');
    data.managerId = form.getFieldValue('managerId');
    data.unitStandardId = form.getFieldValue('unitStandardId');
    data.description = form.getFieldValue('description');
    data.avatar = oldAvatar;
    if (!isNew && leagueStandardEntity.id) {
      dispatch(updateEntity(data));
      updateFilter();
      setDisabledSubmit(false);
    } else {
      dispatch(createEntity(data));
      updateFilter();
      setDisabledSubmit(false);
    }
  }

  /** save action **/
  const handleValidSubmit = e => {
    /** save image **/
    setDisabledSubmit(true);
    const data: any = {};
    let oldAvatar = leagueStandardEntity.avatar;
    if (file) {
      /** neu co file thi upload **/
      const bodyImg = new FormData();
      bodyImg.append('file', file);
      bodyImg.append('uploadType', 'AVATAR_LEAGUE_STANDARD');
      upLoadImg(bodyImg).then(result => {
        oldAvatar = result.data;
        saveData(data, oldAvatar);
        setShowDialog(false);
      });
    } else {
      saveData(data, oldAvatar);
      setShowDialog(false);
    }
  };

  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };

  const handleUpdate = id => {
    dispatch(getEntity(id));
  };
  function closeDialog() {
    setEntityId(null);
    setShowDialog(false);
  }

  const handlePreviewAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileImg = e.target.files[0];
    if (validateSizeImage(fileImg, e)) {
      setFile(fileImg);
      getBase64(fileImg, result => {
        setPreviewImg(result);
      });
    }
  };

  return (
    <React.Fragment>
      <Modal
        visible={showDialog}
        onCancel={() => closeDialog()}
        title={
          isNew ? (
            <Translate contentKey="leagueApp.leagueStandard.home.createLabel" />
          ) : type === 'view' ? (
            <Translate contentKey="leagueApp.leagueStandard.view.dialog" />
          ) : (
            <Translate contentKey="leagueApp.leagueStandard.updated.dialog" />
          )
        }
        wrapClassName="management-wrapper-modal"
        footer={null}
        width={900}
      >
        <Form
          form={form}
          onFinishFailed={values => handleFocusFieldFail(values)}
          scrollToFirstError
          name="control-hooks"
          onFinish={handleValidSubmit}
          layout="vertical"
          requiredMark={'optional'}
        >
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item
                name="avatar"
                label={<h6>{translate('leagueApp.leagueStandard.logo')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.leagueStandard.required.avatar'),
                  },
                ]}
              >
                <div>
                  <div className="input-width">
                    <label htmlFor="uploadImage" className="upload-file">
                      {previewImg ? <img src={previewImg} alt="logo" style={{ width: '100%', height: '100%' }} /> : <span>+ TẢI ẢNH</span>}
                    </label>
                    <span style={{ fontSize: '12px' }} className="primary-color">
                      <Translate contentKey="leagueApp.club.home.uploadimg" />
                    </span>
                    <Input
                      disabled={disableView}
                      id="uploadImage"
                      hidden
                      type="file"
                      accept="image/*"
                      value={null}
                      onChange={handlePreviewAvatar}
                    />
                  </div>
                </div>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="name"
                label={<h6>{translate('leagueApp.leagueStandard.popup.name')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.leagueStandard.required.name'),
                  },
                ]}
              >
                <TextField disabled={disableView} placeholder={translate('leagueApp.leagueStandard.popup.placeholderName')} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="classify"
                label={<h6>{translate('leagueApp.leagueStandard.popup.classify')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.leagueStandard.required.classify'),
                  },
                ]}
              >
                <Radio.Group disabled={disableView} options={classify}></Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="unitStandardId"
                label={<h6>{translate('leagueApp.leagueStandard.popup.unitStandard')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.leagueStandard.required.unitStandard'),
                  },
                ]}
              >
                  <Select
                    disabled={disable || disableView}
                    placeholder={translate('leagueApp.leagueStandard.popup.placeholderUnitStandard')}
                    className="custom-prefix-select"
                    allowClear
                    suffixIcon={<SearchIcon />}
                    showSearch
                    onChange={value => {
                      getUsers(value);
                      setResetSelect(null);
                      form.setFieldsValue({ managerId: null });
                    }}
                    options={listUnit}
                    filterOption={(inputValue: string, option?: any) => {
                      return (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase());
                    }}
                  ></Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="managerId"
                label={<h6>{translate('leagueApp.leagueStandard.popup.manager')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.leagueStandard.required.manager'),
                  },
                ]}
              >
                  <Select
                    disabled={disableView}
                    placeholder={translate('leagueApp.leagueStandard.popup.placeholderManager')}
                    className="custom-prefix-select"
                    allowClear
                    suffixIcon={<SearchIcon />}
                    showSearch
                    value={resetSelect}
                    options={listUser}
                    filterOption={(inputValue: string, option?: any) => {
                      return (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase());
                    }}
                  >
                    {/* {listUser.map((user, index) => (
                    <Select.Option value={user.value} key={index}>
                      {user.label}
                    </Select.Option>
                  ))} */}
                  </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="description"
                label={<h6>{translate('leagueApp.leagueStandard.popup.description')}</h6>}
                // rules={[
                //   {
                //     required: true,
                //     message: translate('leagueApp.leagueStandard.required.description'),
                //   },
                // ]}
              >
                <Input.TextArea
                  disabled={disableView}
                  placeholder={translate('leagueApp.leagueStandard.popup.placeholderDescription')}
                  maxLength={499}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="management-modal-footer">
                <Button key="back" onClick={() => closeDialog()}>
                  <Translate contentKey="register.form.close" />
                </Button>
                {type === 'view' ? (
                  ''
                ) : (
                  <Button key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }} disabled={disabledSubmit}>
                    <Translate contentKey="settings.form.button" />
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default LeagueStandardCreateDialog;
