import React, { useEffect } from 'react';
import './mini-map.scss';
import { HiLocationMarker } from 'react-icons/hi';
import { LIST_POSITION } from './constan.mini-map';

const MiniMapPosition = ({ positionValue = '' }) => (
  <>
    <div className="wrapper-mini-map">
      <div className="campo">
        <div className="semi1"></div>
        <div className="semi2"></div>
        <div className="divisoria"></div>
        <div className="interior"></div>
        <div className="penalty"></div>
        {LIST_POSITION.map(position => position.value === positionValue && (
          <div key={position.className} className={position.className}>
            <HiLocationMarker color="blue" />
          </div>
        ))}
      </div>
    </div>
  </>
);

export default React.memo(MiniMapPosition);
