import React from 'react';
import { translate, Translate } from 'react-jhipster';

interface Props {
  playerType: number;
}

export const PlayerTypeContainer = ({ playerType }: Props) => {
  if (playerType === null) {
    return <div></div>;
  }
  if (playerType === 0) {
    return (
      <div>
        <p>{translate('leagueApp.clubUniform.detail.onTheField')}</p>
      </div>
    );
  }
  if (playerType === 1) {
    return (
      <div>
        <p>{translate('leagueApp.clubUniform.detail.goalKeeper')}</p>
      </div>
    );
  }
};
