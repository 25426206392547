import dayjs from 'dayjs';
import { IAttachFile } from './notice.model';

export interface IGuide {
  id?: number;
  category?: string | null;
  title?: string | null;
  content?: string | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  attachmentDTOS?: IAttachFile[];
  nextGuideDTO?: IGuide;
  previousGuideDTO?: IGuide;
}

export const defaultValue: Readonly<IGuide> = {};
