import { Avatar, Button, Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from 'app/entities/match-basic-info/match-basic-info.reducer';
import { getPlayers, saveSquad } from 'app/entities/match/match.reducer';
import { IDefaultMatch, IFormPlayerFields } from 'app/shared/model/match.model';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { useParams, useHistory } from 'react-router-dom';
import { ModalSquad } from './modal-squad';
import './squad.scss';
import { path } from '../../../../routes';
import ExportFileMathDialog from 'app/entities/match/export-file-math-dialog';
import { downloadCSVSquad } from './export-file.reducer';

export const MatchPreparationSquad = (props: any) => {
  const { id } = useParams<{ id: string }>();
  const [isOpenRegister, setIsOpenRegister] = useState(false);
  const [typeEdit, setTypeEdit] = useState('');
  const [isOpenEditPlayerOwner, setIsOpenEditPlayerOwner] = useState(false);
  const [isOpenEditSubOwner, setIsOpenEditSubOwner] = useState(false);
  const [isOpenEditPlayerAway, setIsOpenEditPlayerAway] = useState(false);
  const [isOpenEditSubAway, setIsOpenEditSubAway] = useState(false);
  const [isOwners, setIsOwners] = useState(false);
  const [number, setNumber] = useState(null);
  const matchInfoEntity = useAppSelector(state => state.match.entity);
  const matchBasicInfoEntity: IDefaultMatch = useAppSelector(state => state.matchBasicInfoReducer.entity);
  const playerFreeOwner = useAppSelector(state => state.match.playerFreeOwner);
  const playerFreeAway = useAppSelector(state => state.match.playerFreeAway);
  const playerFieldsOwner = useAppSelector(state => state.match.playerFieldsOwner);
  const playerReserveOwner = useAppSelector(state => state.match.playerReserveOwner);
  const playerFieldsAway = useAppSelector(state => state.match.playerFieldsAway);
  const playerReserveAway = useAppSelector(state => state.match.playerReserveAway);
  const [createModal, setCreateModal] = useState(false);
  const { idPlayerClub } = props;

  const dispatch = useAppDispatch();
  const handleClickSubmit = async (payload: { matchId: number; lang: any; zoneId: string; futsal: any }) => {
    await dispatch(downloadCSVSquad(payload));
    setCreateModal(false);
  };
  useEffect(() => {
    if (matchInfoEntity?.ownerClubId && id) {
      dispatch(getEntity(id));
      dispatch(
        getPlayers({
          ownerClubId: matchInfoEntity?.ownerClubId,
          id,
        })
      );
    }
  }, [id, matchInfoEntity?.ownerClubId]);
  useEffect(() => {
    if (id && matchInfoEntity?.awayClubId) {
      dispatch(
        getPlayers({
          awayClubId: matchInfoEntity?.awayClubId,
          id,
        })
      );
    }
  }, [matchInfoEntity?.awayClubId, id]);
  const columns: ColumnsType<IFormPlayerFields> = [
    {
      title: <Translate contentKey="leagueApp.match.number">Số áo</Translate>,
      dataIndex: 'uniformNumber',
      key: 'uniformNumber',
      render: (_, { playerDTOS }) => <span>{playerDTOS?.uniformNumber}</span>,
    },
    {
      title: <Translate contentKey="leagueApp.match.position">Vị trí</Translate>,
      dataIndex: 'position',
      key: 'position',
      render: (_, { playerDTOS }) => <span>{translate(`leagueApp.playerAgent.${playerDTOS?.position}`)}</span>,
    },
    {
      title: <Translate contentKey="leagueApp.match.name">Tên</Translate>,
      dataIndex: 'koName',
      key: 'koName',
      render: (_, { playerDTOS }) => <span>{playerDTOS?.koName}</span>,
    },
  ];
  const handleSubmit = body => {
    dispatch(saveSquad(body));
  };

  const { push } = useHistory();
  const handleModalCreate = () => {
    setCreateModal(true);
  };
  const handleClickDetail = data => {
    push(path.clubDetail.replace(':id', data));
  };
  return (
    <div className="squad">
      <div className="d-flex justify-content-between align-items-center record-layout-header">
        <p className="heading-20-bold neutral-1 mb-0">
          <Translate contentKey="common.squad" />
        </p>
        <Button onClick={handleModalCreate} className="heading-20-bold neutral-1 mb-0">
          <Translate contentKey="common.exportFile" />
        </Button>
        {createModal ? (
          <ExportFileMathDialog
            createModal={createModal}
            setCreateModal={setCreateModal}
            matchId={props.match.params.id}
            handleClickSubmit={handleClickSubmit}
          />
        ) : (
          ''
        )}
      </div>
      <Row gutter={[100, 32]}>
        <Col xl={12} xs={24}>
          <div className="title-info">
            <div className="avatar">
              <Avatar size={80} src={matchInfoEntity?.ownerBase64Avt} />
            </div>
            <div className="title">
              <div>
                <Translate contentKey="leagueApp.match.squad.owner">Owner</Translate>
                <span className="hot-link-club" onClick={e => handleClickDetail(matchInfoEntity?.ownerClubId)}>
                  {matchInfoEntity?.ownerClubName}
                </span>
                <span style={{ fontWeight: 500, fontSize: 16, color: '#292C38' }}>{matchBasicInfoEntity?.coachOwnerName}</span>
              </div>
            </div>
          </div>
          <div className="title-edit">
            <Translate contentKey="leagueApp.match.playerSelection">Player Selection</Translate>
            <Button
              style={{ width: 80 }}
              disabled={playerFieldsOwner.length ? false : true}
              onClick={() => {
                setIsOpenEditPlayerOwner(true);
                setIsOwners(true);
                setNumber(1);
              }}
            >
              <Translate contentKey="leagueApp.match.edit">Edit</Translate>
            </Button>
          </div>
          <div className={`box-register ${!playerFieldsOwner.length ? 'has-button' : ''}`}>
            {!playerFieldsOwner.length && (
              <>
                <Translate contentKey="leagueApp.match.squad.registerPlayer">Please register player list</Translate>
                <Button
                  style={{ marginTop: 12 }}
                  onClick={() => {
                    setIsOpenRegister(true);
                    setIsOwners(true);
                    setNumber(1);
                    setTypeEdit('editPlayer');
                  }}
                >
                  <Translate contentKey="leagueApp.match.squad.register">Register</Translate>
                </Button>
              </>
            )}
            {playerFieldsOwner.length ? <Table columns={columns} pagination={false} dataSource={playerFieldsOwner} /> : null}
          </div>
          <div className="title-edit">
            <Translate contentKey="leagueApp.match.substitute">Substitute</Translate>
            <Button
              style={{ width: 80 }}
              disabled={playerReserveOwner.length ? false : true}
              onClick={() => {
                setIsOpenEditSubOwner(true);
                setNumber(0);
                setIsOwners(true);
              }}
            >
              <Translate contentKey="leagueApp.match.edit">Edit</Translate>
            </Button>
          </div>
          <div className={`box-register ${!playerReserveOwner.length ? 'has-button' : ''}`}>
            {!playerReserveOwner.length && (
              <>
                <Translate contentKey="leagueApp.match.squad.registerSub">Please register player list</Translate>
                <Button
                  style={{ marginTop: 12 }}
                  onClick={() => {
                    setIsOwners(true);
                    setIsOpenRegister(true);
                    setNumber(0);
                    setTypeEdit('editSub');
                  }}
                >
                  <Translate contentKey="leagueApp.match.squad.register">Register</Translate>
                </Button>
              </>
            )}
            {playerReserveOwner.length ? <Table columns={columns} pagination={false} dataSource={playerReserveOwner} /> : null}
          </div>
        </Col>
        <Col xl={12} xs={24}>
          <div className="title-info">
            <div className="avatar">
              <Avatar size={80} src={matchInfoEntity?.awayBase64Avt} />
            </div>
            <div className="title">
              <div>
                <Translate contentKey="leagueApp.match.squad.away">Away</Translate>
                <span className="hot-link-club" onClick={e => handleClickDetail(matchInfoEntity?.awayClubId)}>
                  {matchInfoEntity?.awayClubName}
                </span>
                <span style={{ fontWeight: 500, fontSize: 16, color: '#292C38' }}>{matchBasicInfoEntity?.coachAwayName}</span>
              </div>
            </div>
          </div>
          <div className="title-edit">
            <Translate contentKey="leagueApp.match.playerSelection">Player Selection</Translate>
            <Button
              onClick={() => {
                setIsOpenEditPlayerAway(true);
                setIsOwners(false);
                setNumber(1);
              }}
              disabled={playerFieldsAway.length ? false : true}
              style={{ width: 80 }}
            >
              <Translate contentKey="leagueApp.match.edit">Edit</Translate>
            </Button>
          </div>
          <div className={`box-register ${!playerFieldsAway.length ? 'has-button' : ''}`}>
            {!playerFieldsAway.length && (
              <>
                <Translate contentKey="leagueApp.match.squad.registerPlayer">Please register player list</Translate>
                <Button
                  style={{ marginTop: 12 }}
                  onClick={() => {
                    setIsOpenRegister(true);
                    setIsOwners(false);
                    setNumber(1);
                    setTypeEdit('editPlayer');
                  }}
                >
                  <Translate contentKey="leagueApp.match.squad.register">Register</Translate>
                </Button>
              </>
            )}
            {playerFieldsAway.length ? <Table columns={columns} pagination={false} dataSource={playerFieldsAway} /> : null}
          </div>
          <div className="title-edit">
            <Translate contentKey="leagueApp.match.substitute">Substitute</Translate>
            <Button
              style={{ width: 80 }}
              disabled={playerReserveAway.length ? false : true}
              onClick={() => {
                setIsOpenEditSubAway(true);
                setIsOwners(false);
                setNumber(0);
              }}
            >
              <Translate contentKey="leagueApp.match.edit">Edit</Translate>
            </Button>
          </div>
          <div className={`box-register ${!playerReserveAway.length ? 'has-button' : ''}`}>
            {!playerReserveAway.length && (
              <>
                <Translate contentKey="leagueApp.match.squad.registerSub">Please register player list</Translate>
                <Button
                  style={{ marginTop: 12 }}
                  onClick={() => {
                    setIsOwners(false);
                    setIsOpenRegister(true);
                    setNumber(0);
                    setTypeEdit('editSub');
                  }}
                >
                  <Translate contentKey="leagueApp.match.squad.register">Register</Translate>
                </Button>
              </>
            )}
            {playerReserveAway.length ? <Table columns={columns} pagination={false} dataSource={playerReserveAway} /> : null}
          </div>
        </Col>
      </Row>
      {isOpenRegister && (
        <ModalSquad
          isOpen={isOpenRegister}
          setIsOpen={setIsOpenRegister}
          clubId={isOwners ? matchInfoEntity?.ownerClubId : matchInfoEntity?.awayClubId}
          rightData={[]}
          leftData={isOwners ? playerFreeOwner : playerFreeAway}
          isOwner={isOwners}
          type={number}
          typeEdit={typeEdit}
          onSubmit={handleSubmit}
          processTime={null}
        />
      )}
      {isOpenEditPlayerOwner && (
        <ModalSquad
          isOpen={isOpenEditPlayerOwner}
          setIsOpen={setIsOpenEditPlayerOwner}
          clubId={matchInfoEntity?.ownerClubId}
          rightData={playerFieldsOwner}
          leftData={playerFreeOwner}
          isOwner={isOwners}
          typeEdit="editPlayer"
          type={number}
          onSubmit={handleSubmit}
          processTime={null}
        />
      )}
      {isOpenEditSubOwner && (
        <ModalSquad
          isOpen={isOpenEditSubOwner}
          setIsOpen={setIsOpenEditSubOwner}
          clubId={matchInfoEntity?.ownerClubId}
          rightData={playerReserveOwner}
          leftData={playerFreeOwner}
          isOwner={isOwners}
          typeEdit="editSub"
          type={number}
          onSubmit={handleSubmit}
          processTime={null}
        />
      )}
      {isOpenEditPlayerAway && (
        <ModalSquad
          isOpen={isOpenEditPlayerAway}
          setIsOpen={setIsOpenEditPlayerAway}
          clubId={matchInfoEntity?.awayClubId}
          rightData={playerFieldsAway}
          leftData={playerFreeAway}
          isOwner={isOwners}
          typeEdit="editPlayer"
          type={number}
          onSubmit={handleSubmit}
          processTime={null}
        />
      )}
      {isOpenEditSubAway && (
        <ModalSquad
          isOpen={isOpenEditSubAway}
          setIsOpen={setIsOpenEditSubAway}
          clubId={matchInfoEntity?.awayClubId}
          rightData={playerReserveAway}
          leftData={playerFreeAway}
          isOwner={isOwners}
          typeEdit="editSub"
          type={number}
          onSubmit={handleSubmit}
          processTime={null}
        />
      )}
    </div>
  );
};
