import { Button, Col, Form, Modal, Row,Input } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import TextField from 'app/modules/lm-input';
import { createEntity, getEntity, updateEntity,clearMsgUpdate } from './player-agent.reducer';
import { handleFocusFieldFail, validateSizeImage } from 'app/shared/util/entity-utils';
import SearchModal from 'app/modules/seatch-modal';
import { IClub } from 'app/shared/model/club.model';
import axios from 'axios';
import { first } from 'lodash';
import { IPlayerAgent } from 'app/shared/model/player-agent.model';
import dayjs from 'dayjs';
import { getBase64 } from 'app/shared/reducers/reducer.utils';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import 'antd/dist/antd.css';
import { AUTHORITIES } from 'app/config/constants';


export const PlayerAgentAddDialog = (props: any,{ match }) => {
  const { showDialog, setShowDialog, entityId, setEntityId, licenses, updateFilter } = props;
  const dispatch = useAppDispatch();

  const updateSuccess = useAppSelector(state => state.playerAgentReducer.updateSuccess);
  const createSuccess = useAppSelector(state => state.playerAgentReducer.createSuccess);
  const playerAgentEntity = useAppSelector(state => state.playerAgentReducer.entity);
  const loading = useAppSelector(state => state.playerAgentReducer.loading);
  const [searchClubModal, setSearchClubModal] = useState(false);
  const [previewImg, setPreviewImg] = useState('');
  const [clubs, setClubs] = React.useState<IClub[]>([]);
  const [dataNation, setDataNation] = useState([]);
  const [file, setFile] = useState<File>(null);
  const options = [
    {
      value: 'name',
      label: translate('leagueApp.leagueClub.detail.title'),
    },
  ];

  const columns = [
    {
      title: <Translate contentKey="leagueApp.leagueClub.detail.title" />,
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const [isNew, setIsNew] = useState(true);

  const [entitySave, setEntitySave] = useState({
    name: '',
    phone: null,
    homeAddress: null,
    email: null,
    fax: null,
    clubName: '',
  } as IPlayerAgent);
  const { authorities, unitStandardId } = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(authorities, [AUTHORITIES.ADMIN]);

  const [searchTeamManager, setSearchTeamManager] = useState(false);
  const optionClubManager = [
    {
      value: 'name',
      label: translate('leagueApp.unitStandard.name'),
    },
  ];
  const [teamManager, setTeamManager] = useState([]);
  const columnClubManager = [
    {
      title: <Translate contentKey="leagueApp.unitStandard.name" />,
      dataIndex: 'fullName',
      key: 'fullName',
    },
  ];
  const [form] = Form.useForm();
  const managementId = Form.useWatch('managementId', form);

  const getClubManager = async (searchName?: string) => {
    try {
      const fetchClubs = await axios.get(`api/staffs/assign-club-for-staff?searchName=${searchName || ''}`);
      setTeamManager(fetchClubs?.data);
    } catch (error) {
      setTeamManager([]);
    }
  };
  useEffect(() => {
    const c = !entityId;
    setIsNew(c);
    if (!c) {
      handleUpdate(entityId);
    }
  }, [entityId]);

  useEffect(() => {
    if (updateSuccess) {
      setEntityId(null);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createSuccess) {
      setEntityId(null);
    }
  }, [createSuccess]);

  useEffect(() => {
    if (!entityId) return;
    if (playerAgentEntity && playerAgentEntity.id) {
      form.setFieldsValue({
        ...playerAgentEntity,
        birthday: playerAgentEntity && playerAgentEntity.birthday ? dayjs(playerAgentEntity.birthday) : null,
      });

      setEntitySave({ ...playerAgentEntity,clubName: 'managementName' });
    }
  }, [playerAgentEntity, entityId]);

  const getClubs = async (clubName?: string) => {
    try {
      const fetchClubs = await axios.get<any>(`api/assign-club-for-player?searchName=${clubName || ''}`);
      setClubs(fetchClubs.data);
    } catch (error) {
      setClubs([]);
    }
  };

  function saveData(data: any) {
    if (playerAgentEntity.id) {
      data = {
        ...playerAgentEntity,
      };
    }
  }
  /** save action **/
  const handleValidSubmit = e => {
    let data: IPlayerAgent = {};
    if (!isNew && playerAgentEntity.id) {
      data = {
        ...playerAgentEntity,
      };
    }
    data.name = form.getFieldValue('name');
    data.phone = form.getFieldValue('phone');
    data.email = form.getFieldValue('email');
    data.homeAddress = form.getFieldValue('homeAddress');
    data.fax = form.getFieldValue('fax');
    data.userId = form.getFieldValue('userId');
    if (!isNew && data.id) {
      dispatch(updateEntity(data));
      updateFilter();
      saveData(data);
    } else {
      dispatch(createEntity(data));
      updateFilter();
      saveData(data);
    }
  };

  const handlePreviewAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileImg = e.target.files[0];
    if (validateSizeImage(fileImg, e)) {
      setFile(fileImg);
      getBase64(fileImg, result => {
        setPreviewImg(result);
      });
    }
  };

  const clubId = Form.useWatch('clubId', form);


  /** new **/
  const handleReset = () => {
    setEntitySave({
        name: '',
        phone: null,
        homeAddress: null,
        email: null,
        fax: null,
    });
    // form.resetFields();
    form.setFieldsValue({
        name: '',
        phone: null,
        homeAddress: null,
        email: null,
        fax: null,
    });
    // dispatch(reset);
    // setShowDialog(true);
    setEntityId(null);
  };

  /** update **/
  const handleUpdate = id => {
    dispatch(getEntity(id));
  };

  function closeDialog() {
    setShowDialog(false);
    setEntityId(null);
  }
  const locale = useAppSelector(state => state.locale.currentLocale);
  const handleNationally = async () => {
    const dataNationally = await axios.get<any>(`api/utils/countries?lang=${locale}`);
    const arrNation = [];
    dataNationally.data.map(a =>
      arrNation.push({
        value: a.name,
        label: a.name,
      })
    );
    setDataNation(arrNation);
  };

  useEffect(() => {
    handleNationally();
  }, []);


 

  return (
    <React.Fragment>
      <Modal
        visible={showDialog}
        onCancel={() => closeDialog()}
        title={
          isNew ? <Translate contentKey="leagueApp.playerAgent.create.title" /> : <Translate contentKey="leagueApp.playerAgent.update.dialog" />
        }
        wrapClassName="management-wrapper-modal"
        footer={null}
        width={900}
      >
        <Form
          form={form}
          initialValues={{ ...entitySave }}
          onFinishFailed={values => handleFocusFieldFail(values)}
          scrollToFirstError
          name="control-hooks"
          onFinish={handleValidSubmit}
          layout="vertical"
          requiredMark={'optional'}
        >
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.playerAgent.create.name')}</h6>}
                name="name"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.playerAgent.required.name'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.playerAgent.placeholder.name')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.playerAgent.create.phone')}</h6>}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.playerAgent.required.phone'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.playerAgent.placeholder.phone')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.playerAgent.create.email')}</h6>}
                name="email"
                rules={[
                  { required: true, message: translate('global.messages.validate.email.required') },
                  {
                    type: 'email',
                    message: translate('global.messages.validate.email.invalid'),
                  },
                ]}
              >  
                <TextField placeholder={translate('leagueApp.playerAgent.placeholder.email')} />
              </Form.Item>
            </Col>
            {isAdmin && (
              <Col span={12}>
                <Form.Item
                  name="clubName"
                  label={<h6>* {translate('leagueApp.playerAgent.clubId')}</h6>}
                  rules={[
                    {
                      required: true,
                      message: translate('leagueApp.playerAgent.required.clubId'),
                    },
                  ]}
                >
                  <Input
                    placeholder={translate('leagueApp.playerAgent.placeholder.clubId')}
                    suffix={
                      <Button onClick={() => setSearchTeamManager(true)} className="position-absolute" style={{ right: '8px' }}>
                        <Translate contentKey="leagueApp.league.choose" />
                      </Button>
                    }
                    readOnly
                  ></Input>
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item
                label={<h6>{translate('leagueApp.playerAgent.create.homeAddress')}</h6>}
                name="homeAddress"
              >
                <TextField placeholder={translate('leagueApp.playerAgent.placeholder.homeAddress')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<h6>{translate('leagueApp.playerAgent.create.fax')}</h6>}
                name="fax"
              >
                <TextField placeholder={translate('leagueApp.playerAgent.placeholder.fax')} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="management-modal-footer">
                <Button
                  color="secondary"
                  onClick={() => {
                    closeDialog();
                  }}
                >
                  <Translate contentKey="register.form.close" />
                </Button>
                <Button loading={loading} key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }}>
                  <Translate contentKey="settings.form.button" />
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        {searchTeamManager && (
          <SearchModal
            onClose={() => {
              setSearchTeamManager(false);
            }}
            options={optionClubManager}
            open={searchTeamManager}
            list={teamManager}
            columns={columnClubManager}
            onFetchData={(data: { name?: string }) => getClubManager(data?.name)}
            multiple={false}
            onAccept={(selected: any[]) => {
              form.setFieldsValue({ managementId: selected.map(item => item?.id), 
              userId: first(selected)?.id,
              clubName: first(selected)?.fullName,
              });
              setSearchTeamManager(false);
            }}
            title={translate('leagueApp.playerAgent.clubId')}
            subTitle={translate('leagueApp.playerAgent.placeholder.clubId')}
            selected={managementId}
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

export default PlayerAgentAddDialog;
