import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Input, Table } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { Translate, Storage } from 'react-jhipster';

const TourNament = (props: RouteComponentProps<any>) => {
  const [searchTourament, setSearchTourament] = useState('');
  const handleOpenCreateClub = () => {
    props.history.push(`${props.location.pathname}/create-tourament`);
  };

  return (
    <div style={{ border: '1px solid #ccc', padding: '12px 12px' }}>
      <div>
        <div className="d-flex justify-content-between border-bottom">
          <div className="d-flex align-item-start">
            <span>Quản lý giải đấu</span>
            <Button className="button-add" onClick={handleOpenCreateClub}>
              <FontAwesomeIcon icon={faPlus} className="right-4" />
              <Translate contentKey="clubmng.add" />
            </Button>
          </div>
          <div className="d-flex same-btn">
            <Button>
              <Translate contentKey="clubmng.combobox" />
            </Button>
            <div className="d-flex relative">
              <FontAwesomeIcon icon={faSearch} className="absolute" />
              <Input placeholder="검색어를 입력해주세요" value={searchTourament} onChange={e => setSearchTourament(e.target.value)} />
            </div>
            <Button>
              <Translate contentKey="clubmng.search" />
            </Button>
          </div>
        </div>
        <div className="mt-4">
          <div>
            <Table borderless>
              <thead>
                <tr>
                  <th></th>
                  <th>기준대회</th>
                  <th>대회명</th>
                  <th>개최년도</th>
                  <th>대회시작일자</th>
                  <th>대회종료일자</th>
                  <th>작업</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <div>
                      <img alt="logo" />
                    </div>
                  </th>
                  <td>K리그 1</td>
                  <td>2022 하나원큐 K리그 1</td>
                  <td>2022</td>
                  <td>2022.03.01</td>
                  <td>2022.12.01</td>
                  <td>
                    <Button className="same-btn">상세보기</Button>
                    <Button className="same-btn">삭제</Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourNament;
