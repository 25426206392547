import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { Button, Col, ConfigProvider, Empty, Modal } from 'antd';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity, createEntity, clearMsgUpdate } from './league-club.reducer';
import { getEntities } from './list-league-club-free.reducer';
import axios from 'axios';
import { TableRowSelection } from 'antd/es/table/interface';
import Transfer, { TransferItem } from 'antd/es/transfer';
// import Table from 'app/modules/table';
import { Table } from 'antd';
import { toast } from 'react-toastify';
import { useFilter } from 'app/shared/hooks/useFilter';
import { ClubListFilter } from '../club/club.type';
import Filter from 'app/modules/filter';
import { FilterParams } from 'app/shared/model/filter.model';

export const LeagueClubCreateDialog = (props: any) => {
  const { createModal, setCreateModal, idClub } = props;
  const dispatch = useAppDispatch();
  const [freeClub, setfreeClub] = useState([]);
  const [data, setData] = useState([{}]);
  const loading = useAppSelector(state => state.LeagueClub?.loading) || false;
  const param = useParams<{ id: string; params: string }>();
  const handleClose = () => {
    setCreateModal(false);
  };
  const defaultFilter: FilterParams = {
    page: 0,
    size: 1000,
    sort: 'id,asc',
    type: 'name',
  };
  const getId = useParams<{ id: string; params: string }>();
  const handleFetchData = React.useCallback((params: ClubListFilter) => {
    dispatch(getEntities({ ...params, leagueId: +getId.id }));
  }, []);

  const [filter2, setFilter2] = React.useState(defaultFilter);

  useEffect(() => {
    handleFetchData(filter2);
  }, [filter2]);

  const handleCreateClub = (name: any) => {
    const body = data.map((item: any, index) => ({ clubId: item.id, leagueId: item.leagueId }));
    if (body[0]?.clubId === undefined || body.length === 0) {
      toast.warn(translate('leagueApp.leagueClub.required'));
      return;
    } else {
      dispatch(createEntity(body));
      setCreateModal(false);
    }
  };

  const rowSelection = {
    onChange(selectedPlayers: React.Key[], selectedRows) {
      setData(selectedRows);
    },
  };

  const options = [
    {
      value: 'name',
      label: translate('leagueApp.leagueClub.detail.title1'),
    },
  ];

  const columns = [
    {
      title: <Translate contentKey="leagueApp.leagueClub.detail.title1" />,
      dataIndex: 'name',
      key: 'name',
    },
  ];
  const GetClubList = useAppSelector(state => state.getFreeClubMultiSelect?.entities);
  return (
    // <Modal isOpen toggle={handleClose}>
    //   <ModalHeader toggle={handleClose} data-cy="clubUniformDeleteDialogHeading">
    //     <Translate contentKey="leagueApp.leagueClub.home.createLabel"></Translate>
    //   </ModalHeader>
    //   <ModalBody id="leagueApp.clubUniform.delete.question">
    //     <Translate contentKey="leagueApp.leagueClub.home.createTitle"></Translate>
    //     <div className="table-responsive">
    //       <Table
    //         rowSelection={rowSelection}
    //         columns={columns}
    //         dataSource={GetClubList.map((item, index) => ({ ...item, leagueId: +param.id }))}
    //         key="value"
    //         loading={loading}
    //         filter={filter2}
    //         onChange={params => setFilter2({ ...filter2, ...params })}
    //         totalElements={GetClubList.totalElements}
    //       />
    //     </div>
    //   </ModalBody>
    //   <ModalFooter>
    //     <Col xl={10} lg={16} md={20} sm={24}>
    //       <Filter filter={filter2} onFilter={filterParams => setFilter2(filterParams)} options={options} />
    //     </Col>
    //     <Button color="secondary" onClick={handleClose}>
    //       <Translate contentKey="entity.action.cancel"></Translate>
    //     </Button>
    //     <Button id="jhi-confirm-delete-clubUniform" color="primary" onClick={handleCreateClub}>
    //       &nbsp;
    //       <Translate contentKey="entity.action.save"></Translate>
    //     </Button>
    //   </ModalFooter>
    // </Modal>

    <Modal
      visible={createModal}
      onCancel={handleClose}
      footer={null}
      width={900}
      wrapClassName="management-wrapper-modal"
      title={<Translate contentKey="leagueApp.leagueClub.home.createOrEditLabel1"></Translate>}
    >
      <Translate contentKey="leagueApp.leagueClub.home.createTitle1"></Translate>
      <div className="table-responsive">
        <ConfigProvider renderEmpty={() => <Empty description={translate('common.noDataTable')} />}>
          <Table
            bordered
            pagination={false}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={GetClubList.map((item, index) => ({ ...item, leagueId: +param.id, key: item.id }))}
            key="id"
            loading={loading}
            // filter={filter2}
            // onChange={params => setFilter2({ ...filter2, ...params })}
            scroll={{
              x: 'calc(400px + 50%)',
              y: 240,
            }}
          />
        </ConfigProvider>
      </div>
      <Col style={{ marginBottom: 24 }}>
        <Filter filter={filter2} onFilter={filterParams => setFilter2(filterParams)} options={options} />
      </Col>
      <Col span={24}>
        <div className="management-modal-footer">
          <Button color="secondary" onClick={handleClose}>
            <Translate contentKey="entity.action.cancel"></Translate>
          </Button>
          <Button id="jhi-confirm-delete-clubUniform" type="primary" onClick={handleCreateClub} style={{ marginLeft: 16 }}>
            <Translate contentKey="entity.action.apply"></Translate>
          </Button>
        </div>
      </Col>
    </Modal>
  );
};

export default LeagueClubCreateDialog;
