import { Button, Col, Collapse, Menu, Radio, RadioChangeEvent, Row, Timeline, Spin, Space } from 'antd';
import { actionRecord, editBeforeMatchAction, MatchStatus } from 'app/shared/util/constant';
import React, { useState, useEffect } from 'react';
import { Translate, translate } from 'react-jhipster';
import { grey } from '@ant-design/colors';
import { IMatchTime } from 'app/shared/model/match-time.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ArrowLeftOutlined, ArrowRightOutlined, ClockCircleOutlined, RedoOutlined, UnorderedListOutlined } from '@ant-design/icons';
import './index.scss';
import { ChervonDown, ChervonRight, ReloadIcon } from 'app/shared/util/appIcon';
import { TimeLineMatch } from '../time-line';
import { changeStartMatch } from '../../match-record.reducer';
import { sendWSActivity, subscribeList, unsubscribe } from 'app/config/websocket-middleware';
import { Swap } from 'app/shared/util/constant/swap.constant';
import { urlSubscribe } from 'app/modules/event-list-record';
import { IPlayerInMatch } from 'app/shared/model/player.model';
import { APPROVAL_TYPE } from 'app/config/constants';

interface Props {
  onChange: (key: string) => void;
  setModal: (key: string, e: any) => void;
  id: string | number;
  modalTypeEventList: string;
  mode: { action: any; id?: number; create?: boolean; player?: IPlayerInMatch };
  isEndMatch?: boolean;
  permissionRecord?: boolean;
  isHaftTime?: boolean;
}

const MenuRecord = ({ onChange, setModal, id, modalTypeEventList, mode, isEndMatch, permissionRecord, isHaftTime }: Props) => {
  const matchTimes: IMatchTime = useAppSelector(state => state.matchTime?.entity);
  const matchBasicInfoEntity = useAppSelector(state => state.match.entity);
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [activeKey, setActiveKey] = useState('');
  const onShowLoading = () => {
    setIsNewRecord(true);
    setTimeout(() => {
      setIsNewRecord(false);
    }, 1000);
  };
  const connectSuccess = useAppSelector(state => state.matchRecord?.connectSuccess);

  useEffect(() => {
    if (connectSuccess) {
      onShowLoading();
      subscribeList(urlSubscribe(actionRecord.SUBSTITUTION) + id, () => onShowLoading());
      subscribeList(urlSubscribe(actionRecord.GOAL_KICK) + id, () => onShowLoading());
      subscribeList(urlSubscribe(actionRecord.FREE_KICK) + id, () => onShowLoading());
      subscribeList(urlSubscribe(actionRecord.PENALTY_KICK) + id, () => onShowLoading());
      subscribeList(urlSubscribe(actionRecord.SHOOTING) + id, () => onShowLoading());
      subscribeList(urlSubscribe(actionRecord.SCORE) + id, () => onShowLoading());
      subscribeList(urlSubscribe(actionRecord.FOUL) + id, () => onShowLoading());
      subscribeList(urlSubscribe(actionRecord.OFFSIDE) + id, () => onShowLoading());
      subscribeList(urlSubscribe(actionRecord.WARNING) + id, () => onShowLoading());
      subscribeList(urlSubscribe(actionRecord.PENALTY_SHOOT_OUT) + id, () => onShowLoading());
    }

    return unsubscribe;
  }, [connectSuccess]);

  const handleChangeRadio = (e: RadioChangeEvent) => {
    if (matchBasicInfoEntity?.status === MatchStatus.FINISH && matchBasicInfoEntity?.approval !== APPROVAL_TYPE.ACCEPTED) {
      return;
    }
    isHaftTime && sendWSActivity(`/record/match-record/list-match/${id}/${e.target.value}`, {});
    permissionRecord &&
      matchTimes.startTime1 &&
      matchBasicInfoEntity.status === MatchStatus.FINISH &&
      sendWSActivity(`/record/match-record/list-match/${id}/${e.target.value}`, {});
  };

  const disableMatchRecordPanel = () => {
    // if (isEndMatch) {
    //   return true;
    // }
    // if (
    //   (matchTimes.startTime1 && !matchTimes.endTime1) ||
    //   (matchTimes.startTime2 && !matchTimes.endTime2) ||
    //   (matchTimes.startSubTime1 && !matchTimes.endSubTime1) ||
    //   (matchTimes.startSubTime2 && !matchTimes.endSubTime2)
    // ) {
    //   return true;
    // }
    return true;
  };
  const checkDisablePenaltyShootOut = () => {
    if (isEndMatch) {
      return false;
    }
    if (matchTimes.endTime2 && !matchTimes.startSubTime1) {
      return false;
    }
    if (matchTimes.endSubTime2) {
      return false;
    }
    return true;
  };

  // useEffect(() => {
  //   if (isEndMatch) {
  //     setActiveKey('2');
  //   }
  // }, [isEndMatch]);

  const toggleActive = (key: string) => {
    if (activeKey === key) {
      setActiveKey('');
    } else {
      setActiveKey(key);
    }
  };
  const matchRecordMenuList = [
    // {
    //   disabled: !disableMatchRecordPanel(),
    //   label: <Translate contentKey="leagueApp.matchRecord.goalKick1" />,
    //   key: actionRecord.GOAL_KICK,
    // },
    // {
    //   disabled: !disableMatchRecordPanel(),
    //   label: <Translate contentKey="leagueApp.matchRecord.cornerKick" />,
    //   key: actionRecord.CORNER_KICK,
    // },
    // {
    //   disabled: !disableMatchRecordPanel(),
    //   label: <Translate contentKey="leagueApp.matchRecord.freeKick" />,
    //   key: actionRecord.FREE_KICK,
    // },
    // {
    //   disabled: !disableMatchRecordPanel(),
    //   label: <Translate contentKey="leagueApp.matchRecord.penaltyKick" />,
    //   key: actionRecord.PENALTY_KICK,
    // },
    {
      disabled: !disableMatchRecordPanel(),
      label: <Translate contentKey="leagueApp.matchRecord.shooting1" />,
      key: actionRecord.SHOOTING,
    },
    // {
    //   disabled: !disableMatchRecordPanel(),
    //   label: <Translate contentKey="leagueApp.matchRecord.assist" />,
    //   key: actionRecord.ASSIST,
    // },
    // {
    //   disabled: !disableMatchRecordPanel(),
    //   label: <Translate contentKey="leagueApp.matchRecord.foul" />,
    //   key: actionRecord.FOUL,
    // },
    // {
    //   disabled: !disableMatchRecordPanel(),
    //   label: <Translate contentKey="leagueApp.matchRecord.offSite" />,
    //   key: actionRecord.OFFSIDE,
    // },
    {
      disabled: !disableMatchRecordPanel(),
      label: <Translate contentKey="leagueApp.matchRecord.yellowCard" />,
      key: actionRecord.WARNING,
    },
    {
      disabled: checkDisablePenaltyShootOut(),
      label: <Translate contentKey="leagueApp.matchRecord.penaltyShootOut" />,
      key: actionRecord.PENALTY_SHOOT_OUT,
    },
  ];

  const beforeMatchMenuList = [
    {
      disabled: false,
      label: <Translate contentKey="leagueApp.matchRecord.editBasicInfo" />,
      key: editBeforeMatchAction.EDIT_BASIC_INFO,
    },
    {
      key: editBeforeMatchAction.EDIT_FIELD_INFO,
      label: translate('leagueApp.matchRecord.editFieldInfo'),
      disabled: !!matchTimes.startTime1,
      items: [
        {
          key: editBeforeMatchAction.EDIT_FIELD_OWNER_INFO,
          label: <Translate contentKey="leagueApp.matchRecord.editSubOwnerPlayer" />,
          disabled: false,
        },
        {
          key: editBeforeMatchAction.EDIT_FIELD_AWAY_INFO,
          label: <Translate contentKey="leagueApp.matchRecord.editFieldAwayPlayer" />,
          disabled: false,
        },
      ],
    },
    {
      key: editBeforeMatchAction.EDIT_SUBSTITUTE_PLAYER,
      label: translate('leagueApp.matchRecord.editSubPlayer'),
      disabled: !!matchTimes.startTime1,
      items: [
        {
          key: editBeforeMatchAction.EDIT_SUBSTITUTE_OWNER_PLAYER,
          label: <Translate contentKey="leagueApp.matchRecord.editSubOwnerPlayer" />,
          disabled: !!matchTimes.startTime1,
        },
        {
          key: editBeforeMatchAction.EDIT_SUBSTITUTE_AWAY_PLAYER,
          label: <Translate contentKey="leagueApp.matchRecord.editSubAwayPlayer" />,
          disabled: !!matchTimes.startTime1,
        },
      ],
    },
    {
      key: editBeforeMatchAction.FORMATION_BEFORE_MATCH,
      label: <Translate contentKey="leagueApp.matchRecord.formation" />,
      disabled: !!matchTimes.startTime1,
    },
  ];

  return (
    <>
      <Collapse
        accordion
        className="custom-collapse"
        expandIcon={props => {
          return props.isActive ? <ChervonDown /> : <ChervonRight />;
        }}
        activeKey={[activeKey]}
        onChange={e => toggleActive(e as string)}
      >
        {/* {!isEndMatch && ( */}
        {(!activeKey || activeKey === '1' || !matchTimes.startTime1) && (
          <Collapse.Panel header={<span>{translate('leagueApp.match.matchInformation')}</span>} key="1" className="match-record-collapsed">
            <Menu onClick={e => onChange(e.key)} mode="vertical" className="match-record-menu">
              {/* {!matchTimes.startTime1 && matchBasicInfoEntity.status !== MatchStatus.FINISH && (
              <>
                <p className="font-weight-bold" style={{ color: grey[8] }}>
                  <Translate contentKey="leagueApp.matchRecord.editPreMatchInfo" />
                </p>
                {beforeMatchMenuList.map(item => {
                  return item.items ? (
                    <Menu.SubMenu
                      className={`w-100 py-0 mt-2 menu-item-custom ${
                        item.items.map(submenu => submenu.key).includes(mode.action) ? 'menu-item-custom-active' : ''
                      }`}
                      key={item.key}
                      disabled={item.disabled}
                      title={item.label}
                    >
                      {item.items.map(submenu => (
                        <Menu.Item className="w-100 mt-2" key={submenu.key}>
                          {submenu.label}
                        </Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  ) : (
                    <Menu.Item
                      className={`w-100 py-0 mt-2 menu-item-custom ${
                        mode.action === item.key || modalTypeEventList === item.key ? 'menu-item-custom-active' : ''
                      }`}
                      key={item.key}
                      disabled={item.disabled}
                    >
                      {item.label}
                    </Menu.Item>
                  );
                })}
              </>
            )} */}

              <p className="mb-2 mt-2 font-weight-bold" style={{ color: grey[8] }}>
                <Translate contentKey="main.incoming.record" />
              </p>
              <Menu.Item
                className={`w-100 py-0 mt-2 menu-item-custom ${
                  mode.action === editBeforeMatchAction.RATIO || modalTypeEventList === editBeforeMatchAction.RATIO
                    ? 'menu-item-custom-active'
                    : ''
                }`}
                disabled={!matchTimes?.startTime1}
                key={editBeforeMatchAction.RATIO}
              >
                <Translate contentKey="leagueApp.matchRecord.ratio" />
              </Menu.Item>
              <Menu.Item
                className={`w-100 py-0 mt-2 menu-item-custom ${
                  mode.action === editBeforeMatchAction.GOAL_SITUATION || modalTypeEventList === editBeforeMatchAction.GOAL_SITUATION
                    ? 'menu-item-custom-active'
                    : ''
                }`}
                disabled={!matchTimes?.startTime1}
                key={editBeforeMatchAction.GOAL_SITUATION}
              >
                <Translate contentKey="leagueApp.matchRecord.goalSituation" />
              </Menu.Item>
              {matchBasicInfoEntity?.approval !== APPROVAL_TYPE.ACCEPTED && (
                <>
                  <p className="mb-3 font-weight-bold" style={{ color: grey[8] }}>
                    <Translate contentKey="leagueApp.matchRecord.startMatchInfo" />
                  </p>
                  <Menu.Item
                    className={`w-100 py-0 mt-2 menu-item-custom`}
                    key={editBeforeMatchAction.REFRESH}
                    disabled={!isHaftTime}
                    onClick={() => sendWSActivity(`/record/match-record/reset-direction-match/${id}`, {})}
                  >
                    <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', alignItems: 'center', height: '100%' }}>
                      <div className="icon-right-panel">
                        <ReloadIcon />
                      </div>
                      <Translate contentKey="leagueApp.matchRecord.refresh" />
                    </div>
                  </Menu.Item>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '100%',
                      marginTop: 24,
                    }}
                    className="arrow-custom-radio"
                  >
                    <Radio.Group
                      onChange={handleChangeRadio}
                      value={matchBasicInfoEntity?.attackDirection}
                      style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Radio.Button
                        value={Swap.LEFT}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 110 }}
                      >
                        <ArrowLeftOutlined />
                      </Radio.Button>
                      <Radio.Button
                        value={Swap.RIGHT}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 110 }}
                      >
                        <ArrowRightOutlined />
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                </>
              )}
            </Menu>
          </Collapse.Panel>
        )}
        {/* )} */}

        {(!activeKey || activeKey === '2') && !!matchTimes.startTime1 && (
          <Collapse.Panel
            header={
              <span>
                {translate('main.incoming.record')}
                {isNewRecord ? <Spin /> : ''}
              </span>
            }
            key="2"
            disabled={!matchTimes.startTime1 && !isEndMatch}
            className="match-record-collapsed"
          >
            <Menu
              onClick={e => onChange(e.key)}
              mode="vertical"
              className="match-record-menu"
              selectedKeys={modalTypeEventList ? modalTypeEventList : mode?.action}
            >
              <Menu.SubMenu
                disabled={!disableMatchRecordPanel()}
                className={`w-100 py-0 mt-2 change-player  menu-item-custom ${
                  [actionRecord.CHANGE_OWNER, actionRecord.CHANGE_AWAY].includes(mode.action) ? 'menu-item-custom-active' : ''
                }`}
                onTitleClick={e => isEndMatch && setModal(actionRecord.SUBSTITUTION, e)}
                title={
                  <>
                    <Translate contentKey="leagueApp.matchRecord.changePlayer" />
                    <Button
                      disabled={!disableMatchRecordPanel()}
                      type="default"
                      className="btn-icon-menu"
                      onClick={e => setModal(actionRecord.SUBSTITUTION, e)}
                      icon={<UnorderedListOutlined />}
                    />
                  </>
                }
              >
                {matchBasicInfoEntity.status !== MatchStatus.FINISH && (
                  <>
                    <Menu.Item key={actionRecord.CHANGE_OWNER}>
                      <span>
                        {matchBasicInfoEntity?.ownerClubName} {translate('leagueApp.matchRecord.changePlayerOwner')}
                      </span>
                    </Menu.Item>
                    <Menu.Item key={actionRecord.CHANGE_AWAY}>
                      <span>
                        {matchBasicInfoEntity?.awayClubName} {translate('leagueApp.matchRecord.changePlayerAway')}
                      </span>
                    </Menu.Item>
                  </>
                )}
              </Menu.SubMenu>
              {matchRecordMenuList.map(item => (
                <Menu.Item
                  disabled={item.disabled}
                  className={`w-100 py-0 mt-2 menu-item-custom ${
                    mode.action === item.key || modalTypeEventList === item.key ? 'menu-item-custom-active' : ''
                  }`}
                  key={item.key}
                >
                  <div className={`w-100 d-flex align-items-center justify-content-center`}>
                    {item.label}
                    <Button
                      disabled={item.disabled}
                      type="default"
                      className="btn-icon-menu"
                      onClick={e => {
                        setModal(item.key, e);
                        e.stopPropagation();
                      }}
                      icon={<UnorderedListOutlined />}
                    />
                  </div>
                </Menu.Item>
              ))}
            </Menu>
          </Collapse.Panel>
        )}
        {/* {!isEndMatch && ( */}
        {(!activeKey || activeKey === '3') && !!matchTimes.startTime1 && (
          <Collapse.Panel
            header={<span>{translate('leagueApp.matchRecord.timeLine.timeLine')}</span>}
            key="3"
            disabled={!matchTimes.startTime1}
            className="match-record-collapsed time-line-collapse"
          >
            <TimeLineMatch />
          </Collapse.Panel>
        )}
        {/* )} */}
      </Collapse>
    </>
  );
};

export default React.memo(MenuRecord);
