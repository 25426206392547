import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
// import Table from 'app/modules/table';
import { Table } from 'antd';
import { Avatar, Button, Col, Input, Select, Tag } from 'antd';
import { formatDate, formatHour } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { path } from '../routes';
import { PlusIcon } from 'app/shared/util/appIcon';
import { ConfirmationDialog } from 'app/shared/util/confirmationDialog';
import axios from 'axios';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import Filter from 'app/modules/filter';
import { getEntities } from './player-tourament.reducer';
import { PlayerTouramenttFilter } from './player-tourament.type';

export const PlayerTourament = () => {
  const dispatch = useAppDispatch();
  const defaultFilter = {
    page: 0,
    size: 10,
    // sort: 'id,asc',
  };
  const playerTouramentList = useAppSelector(state => state.PlayerTourament.entities);
  const loading = useAppSelector(state => state.PlayerTourament.loading);
  const param = useParams<{ id: string; params: string }>();
  const playerEntity = useAppSelector(state => state.player.entity);

  const handleFetchData = React.useCallback((params: PlayerTouramenttFilter) => {
    dispatch(getEntities({ ...params, players: [+param.id], clubId: +params.clubId || playerEntity?.clubId }));
  }, []);

  useEffect(() => {
    onFilterToQueryString({ ...filter, players: [+param.id], clubId: playerEntity?.clubId });
  }, [playerEntity]);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const handleConvertData = (value: number) => {
    // const a = Math.floor(value / 1000 / 60).toString();
    const a = Math.floor(value / 60).toString();
    return a;
  };

  const columns = [
    {
      title: <Translate contentKey="leagueApp.tab.league" />,
      dataIndex: 'leagueName',
      key: 'leagueName',
      width: 200,
    },
    {
      title: <Translate contentKey="leagueApp.playerRecord.played" />,
      dataIndex: 'totalMatch',
      key: 'totalMatch',
      width: 200,
    },
    {
      title: <Translate contentKey="leagueApp.playerRecord.minutesPlayed" />,
      dataIndex: 'processDate',
      key: 'processDate',
      width: 200,
      render: (value, record) => <span>{handleConvertData(value)}</span>,
    },
    {
      title: <Translate contentKey="leagueApp.playerRecord.goal" />,
      dataIndex: 'allScore',
      key: 'allScore',
      width: 200,
    },
    {
      title: <Translate contentKey="leagueApp.playerRecord.assist" />,
      dataIndex: 'supportScore',
      key: 'supportScore',
      width: 200,
      render: (value, record) => <span>{!value ? <span>0</span> : value}</span>,
    },
    {
      title: <Translate contentKey="leagueApp.playerRecord.foul" />,
      dataIndex: 'foul',
      key: 'foul',
      width: 200,
      render: (value, record) => <span>{!value ? <span>0</span> : value}</span>,
    },
    {
      title: <Translate contentKey="leagueApp.playerRecord.bloodFoul" />,
      dataIndex: 'beFouled',
      key: 'beFouled',
      width: 200,
      render: (value, record) => <span>{!value ? <span>0</span> : value}</span>,
    },
    {
      title: <Translate contentKey="recordRoom.lookupPlayer.warning">warning</Translate>,
      dataIndex: 'warning',
      key: 'warning',
      width: 200,
      render: (value, record) => <span>{record.warning + record.ban}</span>,
    },
    {
      title: <Translate contentKey="leagueApp.playerRecord.kicked" />,
      dataIndex: 'chased',
      key: 'chased',
      width: 200,
      render: (value, record) => <span>{!value ? <span>0</span> : value}</span>,
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center management-layout-header" style={{ paddingTop: 30 }}>
        <div className="d-flex align-items-center auto-wrap">
          <h3 id="player-heading" data-cy="MatchHeading" className="heading-20-bold neutral-1">
            <Translate contentKey="leagueApp.tab.league"></Translate>
          </h3>
        </div>
      </div>
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={playerTouramentList}
          // totalElements={null}
          loading={loading}
          // filter={filter}
          pagination={false}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
          scroll={{
            x: 'calc(700px + 50%)',
          }}
        />
      </div>
    </div>
  );
};

export default PlayerTourament;
