import dayjs from 'dayjs';

export interface IPlayerCornerKickRecord {
  id?: number;
  playerId?: number | null;
  detailLocation?: string | null;
  matchId?: number | null;
  clubId?: number | null;
  processDate?: number | null;
  roundType?: number | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
}

export const defaultValue: Readonly<IPlayerCornerKickRecord> = {};
