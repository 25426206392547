import React, { useState } from 'react';
import { Avatar, Card, Col, Image, Row } from 'antd';
import Meta from 'antd/es/card/Meta';
import GreetingCard from 'app/modules/dashboard/components/greeting-card/greeting-card';
import NotificationCard from 'app/modules/dashboard/components/notification-card/noti-card';
import NextMatchCard from 'app/modules/dashboard/components/next-match-card/next-match-card';
import TeamRankingCard from 'app/modules/dashboard/components/team-ranking-card/team-ranking-card';
import GoalRankingCard from 'app/modules/dashboard/components/goal-ranking-card/goal-ranking-card';
import AssistRankingCard from 'app/modules/dashboard/components/assist-ranking-card/assist-ranking-card';
import MatchMasterData from 'app/modules/dashboard/components/match-master-data-card/match-master-data';
import './dashboard.scss';
import MatchRecord from './components/match-record/match-record';
import ArchiveCard from './components/archive-card/archive-card';
import { useAppSelector } from 'app/config/store';
import { FavoriteTable } from './components/favorite-card';
import { LeagueSelectCard } from './components/league-select-card';
import axios from 'axios';
import { useFilter } from 'app/shared/hooks/useFilter';
import { useHistory, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { MatchSchedule } from 'app/modules/dashboard/components/match-schedule/match-schedule';
import dayjs from 'dayjs';
import { QuestionCard } from './components/new-user-dashboard/question-card';
import { WelcomeCard } from './components/new-user-dashboard/welcome-card';
import { useRequest } from 'ahooks';
import { loginHistory } from './dashboard.service';
import { NoticeCard } from './components/notice-card/notice-card';

export const Dashboard = () => {
  const account = useAppSelector(state => state.authentication.account);
  const foundRecorder = account?.authorities.find(function (element) {
    return element === 'ROLE_RECORDER';
  });
  const foundTeamManager = account?.authorities.find(function (element) {
    return element === 'ROLE_TEAM_MANAGER';
  });
  const foundLeagueMaster = account?.authorities.find(function (element) {
    return element === 'ROLE_LEAGUE_MASTER';
  });
  const [logHis, setLogHis] = useState(false);
  const request = useRequest(loginHistory, {
    manual: true,
    onSuccess(res) {
      setLogHis(res?.data);
    },
    onError(err) {},
  });
  const [selectedLeague, setSelectedLeague] = React.useState(null);
  const [listLeague, setListLeague] = React.useState(null);
  const { push } = useHistory();
  const date = dayjs(new Date()).startOf('date').toISOString();

  function onSelectedLeague(id) {
    setSelectedLeague(id);
    const values = {
      selectedLeague: id,
    };
    push(
      {
        pathname: location.pathname,
        search: `?${querystring.stringify(
          {
            ...values,
          },
          { arrayFormat: 'bracket', skipNull: true, skipEmptyString: true }
        )}`,
      },
      { replace: true }
    );
  }

  async function loadLeague() {
    const url = `api/dashboard/list-league?now=${date}`;
    return await axios.get(url);
  }

  async function onloadLeague() {
    try {
      const leagueResult = await loadLeague();
      if (leagueResult && leagueResult.data.length) {
        const selectedLeagueParam = new URLSearchParams(location.search).get('selectedLeague');
        if (!selectedLeagueParam) {
          onSelectedLeague(leagueResult.data[0].id);
        } else {
          onSelectedLeague(Number(selectedLeagueParam));
        }
        setListLeague(leagueResult.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  React.useEffect(() => {
    void onloadLeague();
  }, []);

  React.useEffect(() => {
    if (account?.id) {
      request.run();
    }
  }, [account?.id]);

  return (
    <>
      {logHis === true && !listLeague ? (
        <Row gutter={[32, 32]} align={'stretch'} className="dasboard-container" style={{ rowGap: 16 }}>
          <Row gutter={[32, 32]} align={'stretch'} style={{ marginRight: 0, marginLeft: 0 }}>
            <Col style={{ display: 'flex' }} xs={24} sm={24} md={12} lg={8} xl={16}>
              <WelcomeCard />
            </Col>
            <Col style={{ display: 'flex' }} xs={24} sm={24} md={12} lg={8} xl={8}>
              <NoticeCard></NoticeCard>
            </Col>
            <Col style={{ display: 'flex' }} span={24}>
              <QuestionCard />
            </Col>
            <Col style={{ display: 'flex' }} xs={24} sm={24} md={12} lg={8} xl={12}></Col>
          </Row>
        </Row>
      ) : (
        <Row gutter={[32, 32]} align={'stretch'} className="dasboard-container" style={{ rowGap: 16 }}>
          <Row gutter={[32, 32]} align={'stretch'} style={{ marginRight: 0, marginLeft: 0, rowGap: 16 }}>
            {listLeague &&
              listLeague.map((e, index) => (
                <Col style={{ display: 'flex', minWidth: '350px' }} xs={24} sm={24} md={12} lg={4} xl={4} key={e.id + index}>
                  <div className={selectedLeague === e.id ? 'card_league _active' : 'card_league'} onClick={() => onSelectedLeague(e.id)}>
                    <Avatar shape="square" size={53} src={e?.base64LeagueStandard} />
                    <div className="_name">{e?.name}</div>
                  </div>
                </Col>
              ))}
          </Row>
          <Row gutter={[32, 32]} align={'stretch'} style={{ marginRight: 0, marginLeft: 0 }}>
            <Col style={{ display: 'flex' }} xs={24} sm={24} md={12} lg={8} xl={8}>
              <NextMatchCard idLeague={selectedLeague}></NextMatchCard>
            </Col>
            <Col style={{ display: 'flex' }} xs={24} sm={24} md={12} lg={8} xl={8}>
              {/* <GreetingCard></GreetingCard> */}
              <MatchSchedule idLeague={selectedLeague}></MatchSchedule>
            </Col>
            <Col style={{ display: 'flex' }} xs={24} sm={24} md={12} lg={8} xl={8}>
              {/* <NotificationCard></NotificationCard> */}
              <NoticeCard></NoticeCard>
            </Col>
            <Col style={{ display: 'flex' }} xs={24} sm={24} md={12} lg={8} xl={8}>
              <TeamRankingCard idLeague={selectedLeague}></TeamRankingCard>
            </Col>
            <Col style={{ display: 'flex' }} xs={24} sm={24} md={12} lg={8} xl={8}>
              <GoalRankingCard idLeague={selectedLeague}></GoalRankingCard>
            </Col>
            <Col style={{ display: 'flex' }} xs={24} sm={24} md={12} lg={8} xl={8}>
              <AssistRankingCard idLeague={selectedLeague}></AssistRankingCard>
            </Col>
            {/* {foundRecorder ? (
  ''
) : (
  <Col style={{ display: 'flex' }} span={24}>
    <MatchMasterData></MatchMasterData>
  </Col>
)} */}
            <Col style={{ display: 'flex' }} span={24}>
              <FavoriteTable></FavoriteTable>
            </Col>
          </Row>
        </Row>
      )}

      {/* <Row gutter={[32, 32]} align={'stretch'} className="dasboard-container" style={{ marginTop: 32 }}>
        {foundTeamManager || foundLeagueMaster ? (
          <Col style={{ display: 'flex' }} sm={24} md={24} lg={12} xl={24}>
            <ArchiveCard />
          </Col>
        ) : (
          <>
            <Col style={{ display: 'flex' }} sm={24} md={24} lg={12} xl={8}>
              <MatchRecord />
            </Col>
            <Col style={{ display: 'flex' }} sm={24} md={24} lg={12} xl={16}>
              <ArchiveCard />
            </Col>
          </>
        )}
      </Row> */}
    </>
  );
};

export default Dashboard;
