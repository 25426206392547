import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Col, Input, Select, Tag } from 'antd';
import { formatDate, formatDate2, formatHour, formatYear } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { LeagueMonitorListFilter } from './league-monitor.type';
import { clearMsgDelete, clearMsgUpdate, deleteEntity, getEntities, getEntity } from './league-monitor.reducer';
import { MatchStatusContained } from 'app/modules/status-contained';
import dayjs from 'dayjs';
import LeagueMonitorDeleteDialog from './league-monitor-delete-dialog';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
// import { toast } from 'react-toastify';
import { path } from '../routes';
import { useHistory, useParams } from 'react-router-dom';
import Filter from 'app/modules/filter';
import LeagueMonitorCreateDialog from './league-monitor-modal-add';
import { toast } from 'react-toastify';
import { ConfirmationDialog } from 'app/shared/util/confirmationDialog';
import { PlusIcon } from 'app/shared/util/appIcon';
import MultiFilter from 'app/modules/MultiSearch';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
export const LeagueMonitor = (props: any) => {
  const dispatch = useAppDispatch();
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [reset, setReset] = useState(false);
  const account = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
    type: 'name',
  };
  const deleteSuccess = useAppSelector(state => state.LeagueMonitor?.deleteSuccess);
  const createdSuccess = useAppSelector(state => state.LeagueMonitor?.updateSuccess);
  const leagueMonitorList = useAppSelector(state => state.LeagueMonitor.entities);
  const loading = useAppSelector(state => state.LeagueMonitor.loading);
  const { push } = useHistory();
  const param = useParams<{ id: string; params: string }>();
  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.leagueMonitor.delete.success'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (createdSuccess) {
      toast.success(translate('leagueApp.leagueMonitor.created.success'));
      dispatch(clearMsgUpdate(createdSuccess));
      updateFilter();
      handleFetchData({ ...filter, page: 0 });
    }
  }, [createdSuccess]);

  const handleDeleteModal = (user: any) => {
    setUserClicked(user);
    setModalDelete(true);
  };

  const handleDelete = () => {
    dispatch(deleteEntity(userClicked)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        updateFilter();
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const handleFetchData = React.useCallback((params: LeagueMonitorListFilter) => {
    dispatch(getEntities({ ...params, leagueId: +param.id }));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const ActionButton = ({ id }: { id: number }) => {
    return (
      <div>
        <Button disabled={!isAdmin} onClick={() => handleDeleteModal(id)} type="primary" danger className="rounded">
          <Translate contentKey="entity.action.delete2">Delete</Translate>
        </Button>
      </div>
    );
  };

  const [columns, setColumns] = useState([
    {
      title: <Translate contentKey="leagueApp.leagueMonitor.monitorId" />,
      dataIndex: 'name',
      key: 'name',
      align: 'start' as any,
    },
    // {
    //   title: <Translate contentKey="userManagement.email" />,
    //   dataIndex: 'email',
    //   key: 'email',
    // },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 200,
      render: (value, record) => <ActionButton id={value} />,
      align: 'center' as any,
    },
  ]);

  useEffect(() => {
    if (!isAdmin)
      setColumns([
        {
          title: <Translate contentKey="leagueApp.leagueMonitor.monitorId" />,
          dataIndex: 'name',
          key: 'name',
          align: 'start' as any,
        },
        // {
        //   title: <Translate contentKey="userManagement.email" />,
        //   dataIndex: 'email',
        //   key: 'email',
        // },
      ]);
  }, [isAdmin]);

  const options = [
    {
      value: 'name',
      label: translate('leagueApp.leagueMonitor.monitorId'),
    },
  ];

  const handleOpenModal = () => {
    setCreateModal(true);
  };

  const updateFilter = () => {
    onFilterToQueryString({
      page: 0,
      size: 10,
      sort: 'id,asc',
      type: 'name',
    });
    setReset(!reset);
  };
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center management-layout-header" style={{ paddingTop: 30 }}>
        <div className="d-flex align-items-center">
          <h2 id="player-heading" data-cy="PlayerHeading" className="heading-20-bold neutral-1">
            <Translate contentKey="leagueApp.leagueMonitor.home.title">Leagues</Translate>
          </h2>
          <div className="management-layout-divider"></div>
          <Button className="rounded management-header-add-button" onClick={handleOpenModal} icon={<PlusIcon />} type="primary">
            <Translate contentKey="leagueApp.leagueMonitor.home.createButton"></Translate>
          </Button>
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <Filter filter={filter} onFilter={onFilterToQueryString} multi options={options} />
        </Col>
      </div>
      <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={leagueMonitorList?.content || []}
          totalElements={leagueMonitorList?.totalElements || []}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('leagueApp.leagueMonitor.delete.question')}</h6>}
          onClose={() => setModalDelete(false)}
          onConfirm={() => handleDelete()}
        />
      )}
      {createModal ? <LeagueMonitorCreateDialog createModal={createModal} setCreateModal={setCreateModal} /> : ''}
    </div>
  );
};

export default LeagueMonitor;
