import React from 'react';
import { ConfigProvider, Empty, Table as AntTable, TablePaginationConfig, TableProps, Tag } from 'antd';
import { get } from 'lodash';
import { FilterParams } from 'app/shared/model/filter.model';
import { getSortDefault, setSort } from 'app/shared/util/entity-utils';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { INTERMINABLE } from 'app/shared/util/constant/favorite.constant';
import { translate } from 'react-jhipster';
// import {TableInterface}

interface Props<T> extends Omit<TableProps<T>, 'onChange'> {
  dataSource: T[];
  key?: string;
  filter?: FilterParams;
  totalElements?: number;
  onChange?: (filter: FilterParams) => void;
}

function CustomTable<T extends Record<string, unknown>>({
  size = 'middle',
  key = 'id',
  scroll = {
    x: 'calc(700px + 50%)',
  },
  filter,
  columns,
  onChange,
  totalElements = 0,
  ...props
}: Props<T>) {

  const pagination = { current: (filter?.page || 0) + 1, pageSize: filter?.size || 10, total: totalElements, showSizeChanger: true };
  const handleChange: TableProps<T>['onChange'] = (page, filters, sorter, extra) => {
    onChange({ ...filter, page: page.current - 1, size: page.pageSize, sort: setSort(sorter as any) });
  };

  return (
    <ConfigProvider
    renderEmpty={() => <Empty description={translate('common.noDataTable')} />}
    >
      <AntTable
        {...props}
        pagination={filter?.size === INTERMINABLE ? false : pagination}
        onChange={handleChange}
        size={size}
        columns={columns?.map((item, index) => ({ ...item, sortOrder: getSortDefault(item.key, filter?.sort) }))}
        scroll={scroll}
        dataSource={props.dataSource?.map((item, index) => ({ ...item, key: get(item, key) || index }))}
      />
    </ConfigProvider>
  );
}

export default React.memo(CustomTable);
