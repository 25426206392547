import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { Modal } from 'antd';
import { Button, Col } from 'antd';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { getEntity, deleteEntity, createEntity } from './league-monitor.reducer';
import { getEntities } from './get-list-monitor-free.reducer';
import axios from 'axios';
// import Table from '../../modules/table';
import { Table } from 'antd';
import { toast } from 'react-toastify';
import { useFilter } from 'app/shared/hooks/useFilter';
import { LeagueMonitorListFilter } from './league-monitor.type';
import Filter from 'app/modules/filter';
import { FilterParams } from 'app/shared/model/filter.model';

export const LeagueMonitorCreateDialog = (props: any) => {
  const { createModal, setCreateModal, idClub } = props;
  const [loadModal, setLoadModal] = useState(false);
  const dispatch = useAppDispatch();
  const [dataPositions, setDataPositions] = useState([]);
  const [freeMonitor, setfreeMonitor] = useState([]);
  const [data, setData] = useState([{}]);

  const [formCreate, setFormCreate] = useState({
    position: '',
    player: '',
  });
  const clubUniformEntity = useAppSelector(state => state.clubUniform?.entity);
  const monitorList = useAppSelector(state => state.assignMonitorMultiSelect.entities);
  const loading = useAppSelector(state => state.PlayerClub?.loading) || false;
  const param = useParams<{ id: string; params: string }>();
  const createSuccess = useAppSelector(state => state.PlayerClub.createSuccess);
  const handleClose = () => {
    setCreateModal(false);
  };

  const defaultFilter: FilterParams = {
    page: 0,
    size: 1000,
    sort: 'id,asc',
    type: 'name',
  };
  const handleFetchData = React.useCallback((params: LeagueMonitorListFilter) => {
    dispatch(getEntities({ ...params, leagueId: +param.id }));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const [filter2, setFilter2] = React.useState(defaultFilter);

  useEffect(() => {
    handleFetchData(filter2);
  }, [filter2]);
  const options = [
    {
      value: 'name',
      label: translate('leagueApp.leagueMonitor.home.title'),
    },
  ];

  const handleCreateClub = (name: any) => {
    const body = data.map((item: any, index) => ({ monitorId: item.id, leagueId: item.leagueId }));
    if (body[0]?.monitorId === undefined || body?.length === 0) {
      toast.warn(translate('leagueApp.leagueMonitor.required'));
      return;
    } else {
      dispatch(createEntity(body));
      setCreateModal(false);
    }
  };

  const rowSelection = {
    onChange(selectedPlayers: React.Key[], selectedRows) {
      setData(selectedRows);
    },
  };

  const columns = [
    {
      title: <Translate contentKey="leagueApp.leagueMonitor.home.title" />,
      dataIndex: 'name',
      key: 'name',
    },
    // {
    //   title: <Translate contentKey="userManagement.email" />,
    //   dataIndex: 'email',
    //   key: 'email',
    // },
  ];
  return (
    <Modal
      visible={createModal}
      onCancel={handleClose}
      footer={null}
      width={900}
      wrapClassName="management-wrapper-modal"
      title={<Translate contentKey="leagueApp.match.leagueMatch.chooseMonitor.title" />}
    >
      <Translate contentKey="leagueApp.match.leagueMatch.chooseMonitor.question" />
      <div className="table-responsive">
        <Table
          bordered
          pagination={false}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={monitorList.map((item, index) => ({ ...item, leagueId: +param.id, key: item.id }))}
          // totalElements={monitorList.totalElements || 0}
          key="monitorId"
          loading={loading}
          // filter={filter2}
          // onChange={params => setFilter2({ ...filter2, ...params })}
          scroll={{
            x: 'calc(400px + 50%)',
            y: 240,
          }}
        />
      </div>
      <Col style={{ marginBottom: 24 }}>
        <Filter filter={filter2} onFilter={filterParams => setFilter2(filterParams)} options={options} />
      </Col>
      <Col span={24}>
        <div className="management-modal-footer">
          <Button color="secondary" onClick={handleClose}>
            <Translate contentKey="entity.action.cancel"></Translate>
          </Button>
          <Button id="jhi-confirm-delete-clubUniform" type="primary" onClick={handleCreateClub} style={{ marginLeft: 16 }}>
            <Translate contentKey="entity.action.apply"></Translate>
          </Button>
        </div>
      </Col>
    </Modal>
  );
};

export default LeagueMonitorCreateDialog;
