import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlayerLeagueCreateDialog from './player-league-add-dialog';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Col, Grid, Input, Row, Select, Tag } from 'antd';
import { formatDate, formatHour, formatDate2, formatYear } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import {
  clearMsgCreate,
  clearMsgDelete,
  deleteEntity,
  getEntities,
  getEntity,
  getEntitiesFreePlayerLeague,
} from '../player-league/player-league.reducer';

import dayjs from 'dayjs';
import PlayerClubDeleteDialog from '../player-club/player-club-delete-dialog';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Filter from 'app/modules/filter';
import { PlusIcon } from 'app/shared/util/appIcon';
import { ConfirmationDialog } from 'app/shared/util/confirmationDialog';
import { width } from '@fortawesome/free-solid-svg-icons/faCogs';
import { path } from '../routes';
import MultiFilter from 'app/modules/MultiSearch';
import { hasAnyAuthority, isRole } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { PlayerLeagueListFilter } from './player-league.type';

export const PlayerLeague = (props: any) => {
  const { idPlayerClub } = props;
  const dispatch = useAppDispatch();
  const param = useParams<{ id: string; params: string; leagueId: string }>();
  const authorities = useAppSelector(state => state.authentication.account.authorities);

  const defaultFilter = {
    page: 0,
    size: 1000,
    sort: 'id,asc',
    type: 'koName',
  };
  const isTeamManager = isRole(authorities, [AUTHORITIES.TEAM_MANAGER]);
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [reset, setReset] = useState(false);
  const handleDenied = (user: any) => {
    setModalDelete(true);
    setUserClicked(user);
  };
  const updateFilter = () => {
    onFilterToQueryString({
      page: 0,
      size: 10,
      sort: 'id,asc',
      type: 'name',
    });
    setReset(!reset);
  };

  const handleDelete = () => {
    dispatch(deleteEntity(userClicked)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        updateFilter();
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const { push } = useHistory();
  const playerLeagueList = useAppSelector(state => state.playerLeague?.entities);
  const loading = useAppSelector(state => state.playerLeague?.loading) || false;
  const [createModal, setCreateModal] = useState(false);
  const league = useAppSelector(state => state.league?.entity);
  const currentDate = new Date().toISOString();
  const selectedLeague = league.expirationDate;
  const handleFetchData = React.useCallback((params: PlayerLeagueListFilter) => {
    dispatch(getEntities({ ...params, clubId: +param.id, leagueId: +param.leagueId }));
  }, []);
  const deleteSuccess = useAppSelector(state => state.playerLeague.deleteSuccess);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const account = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);

  const foundTeamManager = account?.authorities.find(function (element) {
    return element === 'ROLE_TEAM_MANAGER';
  });

  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.playerLeague.delete.success'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);
  useEffect(() => {
    if (selectedLeague < currentDate === true) {
      toast.error(translate('leagueApp.playerLeague.alert'));
    }
  }, [selectedLeague < currentDate]);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const ActionButton = ({ id, onClickDetail }: { id: number; onClickDetail: () => void }) => {
    return (
      <div className={isAdmin && 'column-action-button'}>
        <Button style={{ marginRight: '12px' }} onClick={() => onClickDetail()} className="rounded" disabled={selectedLeague < currentDate}>
          <Translate contentKey="leagueApp.playerLeague.detail.detail" />
        </Button>

        {(isAdmin || isTeamManager) && (
          <Button onClick={() => handleDenied(id)} type="primary" danger className="rounded" disabled={selectedLeague < currentDate}>
            <Translate contentKey="entity.action.delete2" />
          </Button>
        )}
      </div>
    );
  };

  const createSuccess = useAppSelector(state => state.playerLeague.createSuccess);
  useEffect(() => {
    if (createSuccess) {
      toast.success(translate('leagueApp.playerLeague.create.success'));
      dispatch(clearMsgCreate(createSuccess));
    }
  }, [createSuccess]);

  const columns = [
    {
      title: '',
      dataIndex: 'base64Avt',
      key: 'base64Avt',
      render: (value, record) => <Avatar shape="square" src={value} size="large" />,
    },
    {
      title: <Translate contentKey="leagueApp.playerLeague.name" />,
      dataIndex: 'koName',
      key: 'koName',
      render: (value, record) => (
        <span className="hot-link-club" onClick={() => push(path.playerDetail.replace(':id', record?.id))}>
          {value}
        </span>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.playerLeague.uniformNumber" />,
      dataIndex: 'uniformNumber',
      key: 'uniformNumber',
    },
    {
      title: <Translate contentKey="leagueApp.playerLeague.position" />,
      dataIndex: 'position',
      key: 'position',
      render: (_, record) => <span>{translate(`leagueApp.playerAgent.${record?.position}`)}</span>,
    },
    // {
    //   title: <Translate contentKey="leagueApp.playerLeague.enName" />,
    //   dataIndex: 'enName',
    //   key: 'enName',
    // },
    {
      title: <Translate contentKey="leagueApp.playerLeague.national" />,
      dataIndex: 'nationality',
      key: 'nationality',
      render: (_, record) => <>{locale === 'ko' ? <span>{record?.nationalityKorea}</span> : <span>{record?.nationality}</span>}</>,
    },
    {
      title: <Translate contentKey="leagueApp.playerLeague.birthDay" />,
      dataIndex: 'birthday',
      key: 'birthday',
      render: (value, record) => formatDate(value),
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'playerId',
      key: 'playerId',
      width: 260,
      render: (value, record) => <ActionButton id={record.id} onClickDetail={() => push(path.playerDetail.replace(':id', value))} />,
      align: 'center' as any,
    },
  ];
  const handleModalCreate = () => {
    setCreateModal(true);
  };

  const handleDeleteItem = () => {
    const obj = {
      playerId: userClicked,
      clubId: +param.id,
    };
    dispatch(deleteEntity(obj)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        updateFilter();
        handleFetchData({ ...filter, page: 0 });
      }
    });
    dispatch(getEntities({ clubId: +param.id, leagueId: +param.leagueId }));
    setModalDelete(false);
  };

  const options = [
    {
      value: 'koName',
      label: translate('leagueApp.playerLeague.name'),
    },
    {
      value: 'uniformNumber',
      label: translate('leagueApp.playerLeague.uniformNumber'),
    },
    {
      value: 'position',
      label: translate('leagueApp.playerLeague.position'),
    },
    {
      value: 'enName',
      label: translate('leagueApp.playerLeague.enName'),
    },
    {
      value: 'nationality',
      label: translate('leagueApp.playerLeague.national'),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center management-layout-header" style={{ paddingTop: 30 }}>
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading" className="heading-20-bold neutral-1">
            <Translate contentKey="leagueApp.playerLeague.home.title"></Translate>
          </h2>
          <>
            <div className="management-layout-divider"></div>
            <Button
              className="rounded management-header-add-button"
              onClick={handleModalCreate}
              icon={<PlusIcon />}
              type="primary"
              disabled={selectedLeague < currentDate === true}
            >
              <Translate contentKey="leagueApp.playerLeague.home.create"></Translate>
            </Button>
          </>
        </div>
        {/* <Col xl={10} lg={16} md={20} sm={24}>
          <Filter filter={filter} onFilter={onFilterToQueryString} multi options={options} />
        </Col> */}
      </div>
      <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
      <div className="table-responsive">
        <Table
          totalElements={playerLeagueList.totalElements}
          columns={columns}
          dataSource={playerLeagueList.content}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('leagueApp.playerLeague.delete.question')}</h6>}
          onClose={() => setModalDelete(false)}
          onConfirm={() => handleDeleteItem()}
        />
      )}
      {createModal ? <PlayerLeagueCreateDialog createModal={createModal} setCreateModal={setCreateModal} idClub={idPlayerClub} /> : ''}
    </div>
  );
};

export default PlayerLeague;
