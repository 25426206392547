import React from 'react';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import UserManagement from './user-management';
import AccountManagement from './account-management';
import Logs from './logs/logs';
import Health from './health/health';
import Metrics from './metrics/metrics';
import Configuration from './configuration/configuration';
import Docs from './docs/docs';
import Tracker from './tracker/tracker';
import UserManagementDetailDialog from './user-management/user-management-detail-dialog';
import { Switch } from 'react-router-dom';

export const path = {
  userManagement: '/user-management',
  userManagementDetail: '/user-management-detail/:id/:params',
  accountManagement: '/account-management',
};

const Routes = ({ match }) => {
  return (
    <div>
      <Switch>
        <ErrorBoundaryRoute path={path.userManagement} component={UserManagement} />
        <ErrorBoundaryRoute path={path.accountManagement} component={AccountManagement} />
        {/* <ErrorBoundaryRoute path={path.userManagementDetail} component={UserManagementDetailDialog} /> */}
        <ErrorBoundaryRoute exact path={`${match.url}/tracker`} component={Tracker} />
        <ErrorBoundaryRoute exact path={`${match.url}/health`} component={Health} />
        <ErrorBoundaryRoute exact path={`${match.url}/metrics`} component={Metrics} />
        <ErrorBoundaryRoute exact path={`${match.url}/configuration`} component={Configuration} />
        <ErrorBoundaryRoute exact path={`${match.url}/logs`} component={Logs} />
        <ErrorBoundaryRoute exact path={`${match.url}/docs`} component={Docs} />
      </Switch>
    </div>
  );
};

export default Routes;
