import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity } from './stadium.reducer';

export const StadiumDeleteDialog = (props: any) => {
  const { modalDelete, userClicked, setModalDelete } = props;
  const [loadModal, setLoadModal] = useState(false);
  const dispatch = useAppDispatch();



  const deleteSuccess = useAppSelector(state => state.stadium.deleteSuccess);

  const handleClose = () => {

    setModalDelete(false);
  };

  useEffect(() => {
    if (deleteSuccess && loadModal) {
      handleClose();
      setLoadModal(false);
    }
  }, [deleteSuccess]);

  const confirmDelete = () => {
    const obj = {
      stadiumId: userClicked,
    };
    dispatch(deleteEntity(obj));
    setModalDelete(false);
  };

  return (
    <Modal
      visible
      onCancel={handleClose}
      closeIcon={<></>}
      footer={null}
      wrapClassName='management-confirm-modal'
      width={400}
    >
      <Row align="middle" justify="center">
        <Col><Translate contentKey="leagueApp.stadium.delete.question">Are you sure you want to delete this Stadium?</Translate></Col>
      </Row>
      <Row align="middle" justify="center" style={{ marginTop: 40, width: '100%' }}>
        <Col span={12} style={{ paddingRight: 12 }}>
          <Button color="secondary" onClick={handleClose} style={{ width: '100%' }}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
        </Col>
        <Col span={12} style={{ paddingLeft: 12 }}>
          <Button id="jhi-confirm-delete-stadium" data-cy="entityConfirmDeleteButton" type='primary' onClick={confirmDelete} style={{ width: '100%' }}>
            <Translate contentKey="confirmDialog.defaultConfirm"></Translate>
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default StadiumDeleteDialog;
