import React from 'react';
import { Avatar, Card, Col, Row, Skeleton } from 'antd';
import { ChervonLeft } from 'app/shared/util/appIcon';
import { Link, useHistory } from 'react-router-dom';
import { translate } from 'react-jhipster';
import dayjs from 'dayjs';
import './style.scss';

export const QuestionCard = props => {
  const history = useHistory();
  const navigate = (route: string): void => {
    history.push(route);
  };
  return (
    <Card>
      <div className="list-notifications" style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '50%', height: '100%', borderRight: '1px solid #c8cfdb' }}>
          <div style={{ height: '80%', display: 'flex', flexDirection: 'column' }}>
            <span className="title-new-user">{translate('dashboard.questions')}</span>
            <span className="title-describe">{translate('dashboard.respond')}</span>
            <Avatar size={100} shape="square" src="content/images/question.png"/>
          </div>
          <div className="direct-link">
            <Link to={'/sportat-center/question-and-answer'} className="primary-color">
              <span>{translate('dashboard.contactUs')}</span>
            </Link>
          </div>
        </div>
        <div style={{ width: '50%', height: '100%' }}>
          <div style={{ height: '80%', display: 'flex', flexDirection: 'column', paddingLeft: 32 }}>
            <span className="title-new-user">{translate('dashboard.guideQuestion')}</span>
            <span className="title-describe">{translate('dashboard.guide')}</span>
            <Avatar size={100} shape="square"  src="content/images/book.png"/>
          </div>
          <div className="direct-link">
            <Link to={'/sportat-center/guide'} className="primary-color">
              <span>{translate('dashboard.guideLink')}</span>
            </Link>
          </div>
        </div>
      </div>
    </Card>
  );
};
