import { ICategoryData } from 'app/shared/model/category-data.model';
import { CATEGORY_DATA_CODE } from 'app/shared/util/category-data-code.constant';
import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IAssistGoalRecord, defaultValue } from 'app/shared/model/assist-goal-record.model';
import { IPlayerGoalRecord } from 'app/shared/model/player-goal-record.model';
import { get } from 'lodash';

interface AssistGoalState extends EntityState<IAssistGoalRecord> {
  list: {
    supportTypes: ICategoryData[];
    goalList: IPlayerGoalRecord[];
  };
}

const initialState: AssistGoalState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  list: {
    supportTypes: [],
    goalList: [],
  },
};

const apiUrl = 'api/assist-goal-records';

// Actions

export const getEntities = createAsyncThunk('assistGoalRecord/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IAssistGoalRecord[]>(requestUrl);
});

export const getListCategoryValue = createAsyncThunk('playerGoalRecord/getList', async ({ locale, id }: { locale: string; id: string }) => {
  const url = (cate: string) => `/api/category-data-by-code?code=${cate}&lang=${locale}`;
  const list = [`/api/player-goal-records/get-list?matchId=${id}`, url(CATEGORY_DATA_CODE.SUPPORT_TYPE)];
  const result = await axios.all(list.map(item => axios.get(item)));
  return result;
});

export const getEntity = createAsyncThunk(
  'assistGoalRecord/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IAssistGoalRecord>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'assistGoalRecord/create_entity',
  async (entity: IAssistGoalRecord, thunkAPI) => {
    const result = await axios.post<IAssistGoalRecord>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'assistGoalRecord/update_entity',
  async (entity: IAssistGoalRecord, thunkAPI) => {
    const result = await axios.put<IAssistGoalRecord>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'assistGoalRecord/partial_update_entity',
  async (entity: IAssistGoalRecord, thunkAPI) => {
    const result = await axios.patch<IAssistGoalRecord>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'assistGoalRecord/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IAssistGoalRecord>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const AssistGoalRecordSlice = createSlice({
  name: 'assistGoalRecord',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isFulfilled(getListCategoryValue), (state, action) => {
        state.list = {
          goalList: get(action.payload, '0.data'),
          supportTypes: get(action.payload, '1.data.dataTrans'),
        };
      });
  },
});

// Reducer
export default AssistGoalRecordSlice.reducer;
