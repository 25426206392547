import React from 'react';
import { Card, Col, Row, Skeleton } from 'antd';
import { ChervonLeft } from 'app/shared/util/appIcon';
import { Link, useHistory } from 'react-router-dom';
import { translate } from 'react-jhipster';
import dayjs from 'dayjs';
import { LogoLogin } from 'app/shared/util/authIcon';
import { useAppSelector } from 'app/config/store';

export const WelcomeCard = props => {
  const history = useHistory();
  const account = useAppSelector(state => state.authentication.account);

  const foundLeagueMaster = account?.authorities.find(function (element) {
    return element === 'ROLE_LEAGUE_MASTER';
  });
  const navigate = (route: string): void => {
    history.push(route);
  };
  return (
    <Card>
      <div className="list-notifications" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <LogoLogin />
        <span style={{ fontWeight: 600, fontSize: 40, paddingTop: 15 }}>{translate('dashboard.welcome')}</span>
        {foundLeagueMaster ? (
          <>
            <span style={{ fontSize: 26, paddingTop: 20 }}>{translate('dashboard.creatingLeague')}</span>
            <span className="hot-link-club" style={{ fontSize: 26 }} onClick={() => history.push('/league')}>
              {translate('dashboard.goToLeague')}
            </span>
          </>
        ) : (
          <>
            <span style={{ fontSize: 26, paddingTop: 20 }}>{translate('dashboard.noMatchClubManager')}</span>
            <span style={{ fontSize: 26 }}>{translate('dashboard.contactLeagueManage')}</span>
          </>
        )}
      </div>
    </Card>
  );
};
