import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ILeagueRecorder, defaultValue } from 'app/shared/model/league-recorder.model';

const initialState: EntityState<ILeagueRecorder> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  deleteSuccess: false
};

const apiUrl = 'api/league-recorders';
const apiRecorder = 'api/league-recorders/getRecoders';
const apiGetRecorder = 'api/recorder/list-recorder-league'
const apiCreateRecorder = 'api/league-recorders/save-all'


// Actions

export const getEntities = createAsyncThunk('leagueRecorder/fetch_entity_list', async ({ leagueId, unitStandardId, page, size, sort, ...res }: IQueryParams) => {
  const requestUrl = `${apiGetRecorder}${sort ? `?page=${page}&size=${size}&sort=${sort}` : '?'}`;
  return axios.post<ILeagueRecorder[]>(requestUrl, {...res, leagueId, unitStandardId});
});

export const getEntity = createAsyncThunk(
  'leagueRecorder/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ILeagueRecorder>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'leagueRecorder/create_entity',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<ILeagueRecorder>(apiCreateRecorder, entity);
    thunkAPI.dispatch(getEntities({ leagueId: result?.data[0]?.leagueId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'leagueRecorder/update_entity',
  async (entity: ILeagueRecorder, thunkAPI) => {
    const result = await axios.put<ILeagueRecorder>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'leagueRecorder/partial_update_entity',
  async (entity: ILeagueRecorder, thunkAPI) => {
    const result = await axios.patch<ILeagueRecorder>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'leagueRecorder/delete_entity',
  async (deleteRecorder: any, thunkAPI) => {
    const requestUrl = `${apiUrl}/${deleteRecorder}`;
    const result = await axios.delete<ILeagueRecorder>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const LeagueRecorderSlice = createEntitySlice({
  name: 'leagueRecorder',
  initialState,
  reducers: {
    clearMsgUpdate: (state, action) => void (state.updateSuccess = null),
    clearMsgDelete: (state, action) => void (state.deleteSuccess = null),
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })

      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.deleteSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, clearMsgDelete, clearMsgUpdate } = LeagueRecorderSlice.actions;

// Reducer
export default LeagueRecorderSlice.reducer;
