import React, { useState, useEffect } from 'react';
import { Avatar, Button, Card, Col, Form, Input, Row, Select, Tabs } from 'antd';
import { Switch, useHistory, useParams } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import { LIST_URL } from './constant.match-over';
import RecordTable from './RecordTable';
import ScoreAndMom from './ScoreAndMom';
import GeneralInformation from './GeneralInformation';
import { Translate, translate } from 'react-jhipster';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { setIsEditValue } from './match-over.reducer';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';
import './index.scss';

const MatchOver = ({ match }) => {
  const history = useHistory();
  const isValueChange = useAppSelector(state => state.MatchOver.isEditValue);
  const [openConfirm, setOpenConfirm] = useState({ isOpen: false, path: '' });
  const dispatch = useAppDispatch();
  const listTab = [
    {
      label: translate('matchOver.recordTable'),
      url: LIST_URL.RECORD_TABLE,
      value: LIST_URL.RECORD_TABLE,
      component: RecordTable,
    },
    {
      label: translate('matchOver.scoreAndMom'),
      url: LIST_URL.SCORE_AND_MOM,
      value: LIST_URL.SCORE_AND_MOM,
      component: ScoreAndMom,
    },
    {
      label: translate('matchOver.generalInfo'),
      url: LIST_URL.GENERAL_INFO,
      value: LIST_URL.GENERAL_INFO,
      component: GeneralInformation,
    },
  ];
  const { params } = useParams<{ id: string; params: string }>();

  useEffect(() => {
    if (params === 'match-record') {
      history.push(LIST_URL.RECORD_TABLE);
    }
  }, []);

  const onTabChange = (key: string) => {
    if (isValueChange) {
      setOpenConfirm({ isOpen: true, path: key });
      return;
    } else {
      dispatch(setIsEditValue(false));
      history.push(key);
    }
  };

  const onConfirmChangeTab = () => {
    dispatch(setIsEditValue(false));
    setOpenConfirm({ isOpen: false, path: '' });
    history.push(openConfirm.path);
  };

  return (
    <>
      <Tabs onChange={onTabChange} type="card" activeKey={params} style={{ marginRight: 24 }}>
        {listTab.map(tab => (
          <Tabs.TabPane tab={<span>{tab.label}</span>} key={tab.url} />
        ))}
      </Tabs>
      <Card className="card-after-tabs padding-card">
        <Switch>
          {listTab.map(tab => (
            <ErrorBoundaryRoute key={tab.value} path={`/real-time-recording/:id/${tab.url}`} component={tab.component} />
          ))}
        </Switch>
      </Card>
      <ConfirmationDialog
        onClose={() => setOpenConfirm({ isOpen: false, path: '' })}
        open={openConfirm.isOpen}
        onConfirm={onConfirmChangeTab}
        message={
          <Translate contentKey="leagueApp.match.notSave">You haven not saved the value yet. Do you want to change the page?</Translate>
        }
      />
    </>
  );
};

export default MatchOver;
