import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Col, Input, Select, Tag } from 'antd';
import { formatDate, formatHour } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { ClubUniformListFilter } from './club-uniform.type';
import { clearMsgCreate, clearMsgDelete, clearMsgUpdate, deleteEntity, getEntities, getEntity } from './club-uniform.reducer';
import { MatchStatusContained } from 'app/modules/status-contained';
import dayjs from 'dayjs';
import Filter from 'app/modules/filter';
import ClubUniformDeleteDialog from './club-uniform-delete-dialog';
import { faPlus, faSearch, faUserGear } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import UniformCreateModal from './modal-club-uniform-create';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { UniformStatusContainer } from './uniform-status-container';
import { ShirtTypeContainer } from './shirt-type-container';
import { PlayerTypeContainer } from './player-type-container';
import UniformDetailModal from './club-uniform-detail';
import { IClubUniform } from 'app/shared/model/club-uniform.model';
import Color from 'app/modules/color';
import { PlusIcon } from 'app/shared/util/appIcon';
import { ConfirmationDialog } from 'app/shared/util/confirmationDialog';
import { hasAnyAuthority, isRole } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
export const ClubUniform = (props: any) => {
  const dispatch = useAppDispatch();
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [dataaa, setDataaa] = useState([]);
  const [uniformUpdate, setUniformUpdate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
  };
  const deleteSuccess = useAppSelector(state => state.PlayerUniform.deleteSuccess);
  const clubUniformEntity = useAppSelector(state => state.clubUniform?.entity);
  const links = useAppSelector(state => state.player.links);
  const clubUniformList = useAppSelector(state => state.PlayerUniform?.entities) || [];
  const loading = useAppSelector(state => state.PlayerUniform?.loading) || false;
  const param = useParams<{ id: string; params: string }>();
  const updateSuccess = useAppSelector(state => state.PlayerUniform.updateSuccess);
  const createSuccess = useAppSelector(state => state.PlayerUniform.createSuccess);
  const account = useAppSelector(state => state.authentication.account);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const isTeamManager = isRole(authorities, [AUTHORITIES.TEAM_MANAGER]);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);
  const league = useAppSelector(state => state.league?.entity);
  const currentDate = new Date().toISOString();
  const selectedLeague = league.expirationDate;
  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.clubUniform.deleted.success'));
      dispatch(clearMsgDelete(deleteSuccess));
      handleFetchData({ ...filter, page: 0 });
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      toast.success(translate('leagueApp.clubUniform.updated.success'));
      dispatch(clearMsgUpdate(updateSuccess));
      handleFetchData({ ...filter, page: 0 });
    }
  }, [updateSuccess]);
  useEffect(() => {
    if (createSuccess) {
      toast.success(translate('leagueApp.clubUniform.created.success'));
      dispatch(clearMsgCreate(createSuccess));
      handleFetchData({ ...filter, page: 0 });
    }
  }, [createSuccess]);

  const handleDeleteModal = (user: any) => {
    setUserClicked(user);
    setModalDelete(true);
  };

  const handleDelete = () => {
    dispatch(deleteEntity(userClicked)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        updateFilter();
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const handleEditModal = (user: any) => {
    setIsOpen(true);
    setUniformUpdate(user);
  };

  const handleFetchData = React.useCallback((params: ClubUniformListFilter) => {
    dispatch(getEntities({ ...params, clubId: +param.id }));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const ActionButton = ({ id }: { id: number }) => {
    return (
      <div className={isAdmin && 'column-action-button'}>
        <Button onClick={() => handleEditModal(id)} className="rounded" disabled={selectedLeague < currentDate}>
          <Translate contentKey="leagueApp.match.details" />
        </Button>
        {isAdmin && (
          <Button onClick={() => handleDeleteModal(id)} className="rounded" danger type="primary">
            <Translate contentKey="entity.action.delete2">Delete</Translate>
          </Button>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: <Translate contentKey="leagueApp.clubUniform.type" />,
      dataIndex: 'type',
      key: 'type',
      render: (value, record) => <ShirtTypeContainer type={value} />,
    },
    {
      title: <Translate contentKey="leagueApp.clubUniform.playerType" />,
      dataIndex: 'playerType',
      key: 'playerType',
      render: (value, record) => <PlayerTypeContainer playerType={value} />,
    },
    {
      title: <Translate contentKey="leagueApp.clubUniform.shirtColor" />,
      dataIndex: 'shirtColorText',
      key: 'shirtColorText',
      render: (value, record, index) =>
        record.shirtColorText2 ? `${record.shirtColorText} / ${record.shirtColorText2} ` : `${record.shirtColorText} `,
    },
    {
      title: <Translate contentKey="leagueApp.clubUniform.shortColor" />,
      dataIndex: 'shortColorText',
      key: 'shortColorText',
      render: (value, record, index) =>
        record.shortColorText2 ? `${record.shortColorText} / ${record.shortColorText2} ` : `${record.shortColorText} `,
    },
    {
      title: <Translate contentKey="leagueApp.clubUniform.sockColor" />,
      dataIndex: 'sockColorText',
      key: 'sockColorText',
      render: (value, record, index) =>
        record.sockColorText2 ? `${record.sockColorText} / ${record.sockColorText2} ` : `${record.sockColorText} `,
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 230,
      render: (value, record) => <ActionButton id={value} />,
      align: 'center' as any,
    },
  ];

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const updateFilter = () => {
    onFilterToQueryString({
      page: 0,
      size: 10,
      sort: 'id,asc',
    });
    setReset(!reset);
  };

  return (
    <div>
      {isOpen && (
        <UniformCreateModal
          isOpen={isOpen}
          setIsOpen={b => setIsOpen(b)}
          uniformUpdate={uniformUpdate}
          setUniformUpdate={setUniformUpdate}
          updateFilter={updateFilter}
        />
      )}
      <div className="d-flex justify-content-between align-items-center management-layout-header" style={{ paddingTop: 30 }}>
        <div className="d-flex align-items-center">
          <h2 id="player-heading" data-cy="PlayerHeading" className="heading-20-bold neutral-1">
            <Translate contentKey="leagueApp.clubUniform.home.title">Club Uniforms</Translate>
          </h2>
          <>
            <div className="management-layout-divider"></div>
            <Button
              className="rounded management-header-add-button"
              onClick={handleOpenModal}
              icon={<PlusIcon />}
              type="primary"
              disabled={selectedLeague < currentDate}
            >
              <Translate contentKey="clubmng.add" />
            </Button>
          </>
        </div>
      </div>
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={clubUniformList.content || []}
          totalElements={clubUniformList.totalElements}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('leagueApp.clubUniform.deleted.question')}</h6>}
          onClose={() => setModalDelete(false)}
          onConfirm={() => handleDelete()}
          customTextConfirm={translate('leagueApp.clubUniform.deleted.button')}
        />
      )}
    </div>
  );
};

export default ClubUniform;
