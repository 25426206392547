import React, { useState, useEffect } from 'react';
import { translate, Translate } from 'react-jhipster';

import { Col, Form, Row, Space, Button, Select, ConfigProvider, Empty } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from 'app/config/store';
import StatusGoal from './status-goal';
import {
  OWNER,
  AWAY,
  newShootRecord,
  defaultCountShoot,
  convertListPlayerToOptions,
  getListPlayerOption,
  OWNER_CLUB_VALUE,
  AWAY_CLUB_VALUE,
} from './constant.penalty-shoot-out';
import { SearchIcon, SelectArrowDown } from 'app/shared/util/appIcon';

export const ListRecordPenalty = ({ form, sendChangeRecord }) => {
  const [listFullOptionOwnerPlayer, setListFullOptionOwnerPlayer] = useState([]);
  const [listFullOptionAwayPlayer, setListFullOptionAwayPlayer] = useState([]);
  const [listOptionOwnerPlayer, setListOptionOwnerPlayer] = useState([]);
  const [listOptionAwayPlayer, setListOptionAwayPlayer] = useState([]);
  const ownerLineUp = useAppSelector(state => state.matchRecord?.ownerLineUp) || [];
  const awayLineUp = useAppSelector(state => state.matchRecord?.awayLineUp) || [];
  const { Option } = Select;
  const getListPlayerChoose = (typeClub, index) => {
    const listShoot = form.getFieldValue('countShoot');
    const listPlayerChoosed = listShoot.map(shoot => shoot[typeClub]);
    const listPlayerOfClub = typeClub === OWNER ? listFullOptionOwnerPlayer : listFullOptionAwayPlayer;
    const newListOption = getListPlayerOption(listPlayerChoosed, listPlayerOfClub, index);
    if (typeClub === OWNER) {
      setListOptionOwnerPlayer(newListOption);
    } else {
      setListOptionAwayPlayer(newListOption);
    }
  };

  const checkValidate = (index: number) => {
    const listShoot = form.getFieldValue('countShoot');
    for (let i = index; i < listShoot.length + 1; i++) {
      const valueShootAfter = listShoot[i + 1];
      if (valueShootAfter?.ownerPlayer || valueShootAfter?.awayPlayer) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setListFullOptionOwnerPlayer(convertListPlayerToOptions(ownerLineUp));
    setListFullOptionAwayPlayer(convertListPlayerToOptions(awayLineUp));
    setListOptionOwnerPlayer(convertListPlayerToOptions(ownerLineUp));
    setListOptionAwayPlayer(convertListPlayerToOptions(awayLineUp));
  }, []);

  const requiredRule = [{ required: true, message: '' }];

  return (
    <Form.List name="countShoot">
      {(fields, { add }, { errors }) => (
        <>
          {fields.map((field, index) => {
            return (
              <Row key={field.key} style={{ alignItems: 'center' }} className="penalty-shoot-out-row">
                <Col span={4}>
                  <Translate contentKey="penaltyShootOutRecord.turn">Turn</Translate>
                  &nbsp; {index + 1}
                </Col>
                <Col span={6} style={{ display: 'flex' }}>
                  <Form.Item rules={checkValidate(index) && requiredRule} name={[field.name, OWNER]}>
                      <Select
                        onFocus={() => getListPlayerChoose(OWNER, index)}
                        style={{ width: 225 }}
                        allowClear
                        className="custom-prefix-select"
                        suffixIcon={<SelectArrowDown />}
                        showSearch
                        onChange={() => sendChangeRecord()}
                        placeholder={translate('common.select_player')}
                      >
                        {listOptionOwnerPlayer.map(player => (
                          <Option key={player.value} value={player.value}>
                            {player.label}
                          </Option>
                        ))}
                      </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <StatusGoal
                    field={field}
                    form={form}
                    index={index}
                    clubType={OWNER}
                    nameDetail="ownerGoal"
                    sendChangeRecord={sendChangeRecord}
                    errors={errors}
                  />
                </Col>
                <Col span={6} style={{ display: 'flex' }}>
                  <Form.Item rules={checkValidate(index) && requiredRule} name={[field.name, AWAY]}>
                      <Select
                        onFocus={() => getListPlayerChoose(AWAY, index)}
                        allowClear
                        style={{ width: 225 }}
                        className="custom-prefix-select"
                        suffixIcon={<SelectArrowDown />}
                        showSearch
                        onChange={() => sendChangeRecord()}
                        placeholder={translate('common.select_player')}
                      >
                        {listOptionAwayPlayer.map(player => (
                          <Option key={player.value} value={player.value}>
                            {player.label}
                          </Option>
                        ))}
                      </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <StatusGoal
                    field={field}
                    form={form}
                    index={index}
                    clubType={AWAY}
                    errors={errors}
                    nameDetail="awayGoal"
                    sendChangeRecord={sendChangeRecord}
                  />
                </Col>
              </Row>
            );
          })}
          <Row>
            <Col offset={4}>
              <a className="caption-16-medium add-penalty-kick" onClick={() => add(newShootRecord)}>
                <Translate contentKey="penaltyShootOutRecord.addNextTurn">+ Add next turn</Translate>
              </a>
            </Col>
          </Row>
        </>
      )}
    </Form.List>
  );
};

export default ListRecordPenalty;
