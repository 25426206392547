import { FormInstance } from 'antd';
import dayjs from 'dayjs';
import { translate } from 'react-jhipster';
import { regexHHMMSS } from './regex';

// validate match rate
export const handleConvertToSecond = (value: string) => {
  if (!value || !value.includes(':')) {
    return;
  }
  const newValue = value.split(':');
  return Number(newValue[0]) * 60 * 60 + Number(newValue[1]) * 60 + Number(newValue[2]);
};
export const checkValueSecond = ({ value, valueFirstInput, otherValueInput, messageValue }) => {
  if (otherValueInput) {
    if (handleConvertToSecond(value) > handleConvertToSecond(otherValueInput)) {
      return Promise.reject(messageValue);
    }
  }
  if (value && !valueFirstInput) {
    return Promise.resolve();
  }
  if (!value) {
    return Promise.resolve();
  } else {
    if (handleConvertToSecond(value) < handleConvertToSecond(valueFirstInput)) {
      return Promise.reject(translate('leagueApp.matchStatus.message1'));
    } else {
      return Promise.resolve();
    }
  }
};

export const checkValueFirst = (value, valueSecondInput) => {
  if (!value) {
    return Promise.resolve();
  } else {
    if (valueSecondInput && handleConvertToSecond(value) > handleConvertToSecond(valueSecondInput)) {
      return Promise.reject(translate('leagueApp.matchStatus.message3'));
    } else {
      return Promise.resolve();
    }
  }
};
export const validateMaxFirstInput = (input1: string, input2: string) => {
  const seconds = 45 * 60;
  if (!input1) {
    return Promise.resolve();
  }
  if (input1 && regexHHMMSS.test(input1)) {
    if (input1.includes(':') && input2) {
      const a = handleConvertToSecond(input1);
      const b = handleConvertToSecond(input2);
      if (b - a < seconds) {
        return Promise.reject(translate('leagueApp.matchStatus.message8'));
      } else {
        return Promise.resolve();
      }
    }
    return Promise.resolve();
  } else {
    return Promise.reject(translate('leagueApp.matchStatus.messageValidateHHMMSS'));
  }
};

export const validateMin = (value: string, min: string, valueLabel: string, valueMin, length = 0) => {
  if (!value || !min) {
    return Promise.resolve();
  }

  // old validate match status
  // const inValid = dayjs(value).isBefore(dayjs(min).add(length, 'minute'), 'second');

  const inValid = dayjs(value).isBefore(dayjs(min), 'second');
  if (inValid) {
    return Promise.reject(
      translate('common.valueMustGreaterThanMin', {
        value: valueLabel,
        min: valueMin,
        minute: length,
      })
    );
  }
  return Promise.resolve();
};

export const validateMaxSecondInput = (input1: string, input2: string) => {
  const seconds = 45 * 60;
  if (!input2) {
    return Promise.resolve();
  }
  if (input2 && regexHHMMSS.test(input2)) {
    if (input2.includes(':') && input1) {
      const a = handleConvertToSecond(input1);
      const b = handleConvertToSecond(input2);
      if (b - a < seconds) {
        return Promise.reject(translate('leagueApp.matchStatus.message8'));
      } else {
        return Promise.resolve();
      }
    }
    return Promise.resolve();
  } else {
    return Promise.reject(translate('leagueApp.matchStatus.messageValidateHHMMSS'));
  }
};
export const validate = (_, value) => {
  if (!value) {
    return Promise.resolve();
  } else {
    if (regexHHMMSS.test(value) || value === '') {
      return Promise.resolve();
    } else {
      return Promise.reject(translate('leagueApp.matchStatus.messageValidateHHMMSS'));
    }
  }
};

export const validateFirstInput = (value, endInput, messageValue) => {
  if (value && !endInput) {
    return Promise.resolve();
  }
  if (!value) {
    return Promise.resolve();
  } else {
    if (endInput && handleConvertToSecond(value) < handleConvertToSecond(endInput)) {
      return Promise.reject(messageValue);
    } else {
      return Promise.resolve();
    }
  }
};

export const validateImg = () => ({
  message: translate('leagueApp.league.required.lessRecorder'),

  validator(_, value) {
    if (!value) {
      return Promise.resolve();
    }
    const arr = value.split(`, `);

    if (arr && arr.length <= 3) {
      return Promise.resolve();
    }
    return Promise.reject(translate('leagueApp.league.required.lessRecorder'));
  },
});

export const handleValidateMessageLanguageChanged = (formInstance: FormInstance) => {
  if (formInstance) {
    const errors = formInstance.getFieldsError();
    if (errors?.length > 0) {
      for (let i = 0; i < errors.length; i++) {
        if (errors[i]?.errors?.length > 0) {
          formInstance.validateFields();
          return;
        }
      }
    }
  }
};
