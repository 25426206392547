import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IFormGetLeagueRecord } from 'app/shared/model/record-room.model';
import { INTERMINABLE } from 'app/shared/util/constant/favorite.constant';
import axios from 'axios';
import dayjs from 'dayjs';

const initState = {
  dataStandard: [],
  dataYear: [],
  dataLeagueName: [],
  dataListLeagueRecord: [],
  dataListTeam: [],
  dataListTeamRecord: [],
  dataListTeamPlayerRecord: [],
  dataListPlayerRecord: [],
  dataListPlayer: [],
};

export const getStandard = createAsyncThunk(
  'recordRoom/getStandard',
  async ({ page, size, body }: { page: number; size: number; body: Record<string, unknown> }) => {
    const result = await axios.post(`/api/league-standards/search?page=${page}&size=${size}`, body);
    return result;
  }
);

export const getLeagueName = createAsyncThunk('recordRoom/getLeagueName', async (body: IFormGetLeagueRecord) => {
  const result = await axios.get(`/api/leagues/find-league-by-year?lsId=${body.lsId}&year=${body.year}`);
  return result;
});

export const getYear = createAsyncThunk('recordRoom/getYear', async () => {
  const result = await axios.get('/api/leagues/year');
  const newData = result.data.map(item => {
    return dayjs(item).format('YYYY');
  });
  const convertData = [...new Set(newData)];
  return convertData;
});

// get list league record
export const getListLeagueRecord = createAsyncThunk(
  'recordRoom/getListLeagueRecord',
  async ({ leagueId, sort }: { leagueId: number; sort?: string }) => {
    const result = await axios.get(`/api/report/list-league-sort/${leagueId}`, {
      params: {
        sort,
        page: 0,
        size: INTERMINABLE,
      },
    });
    const newData = result.data.map((item, index, array) => {
      return {
        ...item,
        position: sort === 'position,asc' ? index + 1 : array.length - index,
      };
    });
    return newData;
  }
);

// get list team record
interface IFormParams {
  leagueId: number | string;
  clubId: number | string;
}
export const getListTeamRecord = createAsyncThunk('recordRoom/getListTeamRecord', async (params: IFormParams) => {
  const result = axios.get(`/api/report/detail-club?leagueId=${params.leagueId}&clubId=${params.clubId}`);
  return result;
});

//  get list team

export const getListTeam = createAsyncThunk('recordRoom/getListTeam', async (params: string | number) => {
  const result = axios.get(`/api/clubs/find-all-club-by-league/${params}`);
  return result;
});

// get list player record

interface IFormGetPlayerRecord {
  players: Array<number>;
  clubId: number;
  leagueId: number;
  orderDetail: number;
}
// export const getListPlayerRecord = createAsyncThunk('recordRoom/getListPlayerRecord', async (params: IFormGetPlayerRecord) => {
//   const result = axios.get(`/api/report-player/record?clubId=${params.clubId}&playerId=${params.playerId}`);
//   return result;
// });

export const getListPlayerRecord = createAsyncThunk('recordRoom/getListPlayerRecord', async (body: IFormGetPlayerRecord) => {
  const result = axios.post(`/api/report-player/record`, body);
  return result;
});

// get list team player record
export const getListTeamPlayer = createAsyncThunk('recordRoom/getListTeamPlayer', async () => {
  const result = axios.get('/api/clubs/find-all-by-login');
  return result;
});

// get list player in player record

export const getListPlayer = createAsyncThunk('recordRoom/getListPlayer', async (clubId: number) => {
  const result = axios.post(`/api/player-clubs/getplayer?page=0&size=1000&clubId=${clubId}`, {});
  return result;
});

const recordRoomSlice = createSlice({
  name: 'recordRoom',
  initialState: initState,
  reducers: {
    resetValue(state) {
      state.dataLeagueName = [];
      state.dataListLeagueRecord = [];
      state.dataListTeamRecord = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getStandard.fulfilled, (state, action) => {
        state.dataStandard = action.payload.data;
      })
      .addCase(getLeagueName.fulfilled, (state, action) => {
        state.dataLeagueName = action.payload.data;
      })
      .addCase(getLeagueName.pending, state => {
        state.dataListTeam = [];
        state.dataListLeagueRecord = [];
      })
      .addCase(getYear.fulfilled, (state, action) => {
        state.dataYear = action.payload;
      })
      .addCase(getListLeagueRecord.fulfilled, (state, action) => {
        state.dataListLeagueRecord = action.payload;
      })
      .addCase(getListTeam.fulfilled, (state, action) => {
        state.dataListTeam = action.payload.data;
      })
      .addCase(getListTeamRecord.fulfilled, (state, action) => {
        state.dataListTeamRecord = action.payload.data;
      })
      .addCase(getListPlayerRecord.fulfilled, (state, action) => {
        state.dataListPlayerRecord = action.payload.data;
      })
      .addCase(getListTeamPlayer.fulfilled, (state, action) => {
        state.dataListTeamPlayerRecord = action.payload.data;
      })
      .addCase(getListTeamPlayer.pending, (state, action) => {
        state.dataListPlayerRecord = [];
        state.dataListPlayer = [];
      })
      .addCase(getListPlayer.fulfilled, (state, action) => {
        state.dataListPlayer = action.payload.data;
      });
  },
});

const recordRoomReducer = recordRoomSlice.reducer;
export const { resetValue } = recordRoomSlice.actions;
export default recordRoomReducer;
