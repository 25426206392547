import React, { useState, useEffect } from 'react';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount, translate } from 'react-jhipster';

import { Card, Col, Form, Modal, Radio, Row, Space, Button } from 'antd';
import ListItem from 'app/modules/pick-player/components/list-item';
import { EditOutlined } from '@ant-design/icons';
import PickPosition from 'app/modules/pick-position';
import PickPlayer from 'app/modules/pick-player';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import MiniMapPosition from 'app/modules/mini-map-position';
import KickTypeInfo from './KickTypeInfo';
import ScoreInfo from './ScoreInfo';
import { get, set } from 'lodash';
import { KICK_TYPE_INFO, SCORE_INFO } from './constant.free-corner-record';
import { sendRecord } from 'app/config/websocket-middleware';
import { fetchDetailEventRecord } from './player-free-corner-kick.reducer';
import { urlWs } from 'app/modules/event-list-record/constant.event-list';
import { actionRecord } from 'app/shared/util/constant';
import RecordPlayerInfo from 'app/modules/record-player-info';
import { toast } from 'react-toastify';
import { PositionInMatch } from 'app/shared/util/constant';

const CORNER_KICK_VALUE = 2;
const LIST_LOCATION_CORNER_KICK = [
  PositionInMatch.NEAR_CONNER_R_OWNER,
  PositionInMatch.NEARCONNER_L_AWAY,
  PositionInMatch.NEAR_CONNER_R_AWAY,
  PositionInMatch.NEAR_CONNER_L_OWNER,
];

interface Props {
  open: boolean;
  onCancel: () => void;
  onEditPosition: () => void;
  onEditPlayer: () => void;
  form?: any;
  id?: number;
  awayAvatars: any;
  ownerAvatars: any;
  processTime: any;
}

export const PlayerFreeCornerKickRecord = ({
  onCancel,
  open,
  onEditPlayer,
  onEditPosition,
  form,
  id,
  awayAvatars,
  ownerAvatars,
  processTime,
}: Props) => {
  const [pageChoose, setPageChoose] = React.useState(KICK_TYPE_INFO);
  const [listFooterModal, setListFooterModal] = React.useState([
    <Button key="close" onClick={() => onCancel()}>
      <span>{translate('freeCornerKick.close')}</span>
    </Button>,
    <Button key="save" type="primary" onClick={() => form.submit()}>
      <span>{translate('freeCornerKick.save')}</span>
    </Button>,
  ]);
  const scoreType = Form.useWatch('score');
  const playerInForm = Form.useWatch('player', form);
  const dispatch = useAppDispatch();
  const [dataDefault, setDataDefault] = useState(null);
  const locale = useAppSelector(state => state.locale.currentLocale);

  // React.useEffect(() => {
  //   if (pageChoose === KICK_TYPE_INFO) {
  //     setListFooterModal([
  //       <Button key="close" onClick={() => onCancel()}>
  //         <span>{translate('freeCornerKick.close')}</span>
  //       </Button>,
  //       <Button key="next" type="primary" onClick={() => form.submit()}>
  //         <span>{translate('freeCornerKick.next')}</span>
  //       </Button>,
  //     ]);
  //   }
  //   if (pageChoose === SCORE_INFO) {
  //     setListFooterModal([
  //       <Button key="close" onClick={() => onCancel()}>
  //         <span>{translate('freeCornerKick.close')}</span>
  //       </Button>,
  //       <Button key="next" onClick={() => setPageChoose(KICK_TYPE_INFO)}>
  //         <span>{translate('freeCornerKick.back')}</span>
  //       </Button>,
  //       <Button key="save" type="primary" onClick={() => form.submit()}>
  //         <span>{translate('freeCornerKick.save')}</span>
  //       </Button>,
  //     ]);
  //   }
  // }, [pageChoose, scoreType]);

  useEffect(() => {
    if (id) {
      dispatch(fetchDetailEventRecord(`api/player-kick-records/${id}`)).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          const data = get(res.payload, 'data');
          setDataDefault(data);
          form.setFieldsValue({
            ...data,
            goalForm: data?.playerGoalRecordDTO?.goalForm,
            shootDirectionKick: data?.playerGoalRecordDTO?.shootDirection,
            distance: data?.playerGoalRecordDTO?.distance,
          });
        }
      });
    }
  }, []);

  const onSubmitForm = data => {
    // if (!data.detailLocation) {
    //   toast.error(translate('scoreRecord.message.supportForm'));
    //   return;
    // }
    // if (data.type === CORNER_KICK_VALUE && !LIST_LOCATION_CORNER_KICK.includes(data.detailLocation)) {
    //   toast.error(translate('scoreRecord.support.choosePosition'));
    //   return;
    // }
    // if (pageChoose === KICK_TYPE_INFO) {
    //   setPageChoose(SCORE_INFO);
    //   return;
    // }
    if (id) {
      sendRecord(urlWs(actionRecord.FREE_KICK, 'edit', data.player?.matchPlayerFieldDTO.matchId, id), {
        ...dataDefault,
        ...data,
        id,
        matchId: data.player.matchPlayerFieldDTO.matchId,
        playerId: data.player.matchPlayerFieldDTO.playerDTOS?.id,
        playerGoalRecordDTO:
          data?.goalType === 1
            ? {
                ...dataDefault.playerGoalRecordDTO,
                shootDirection: data?.shootDirectionKick,
                goalForm: data?.goalForm,
                distance: data?.distance,
              }
            : {},
        player: undefined,
        detailLocation: data.detailLocation,
      });
    } else {
      sendRecord(
        `/record/player-kick/create/${data.player?.matchPlayerFieldDTO.matchId}/${data.player?.matchPlayerFieldDTO.clubId}/${data.player?.matchPlayerFieldDTO.type}`,
        {
          matchId: data.player.matchPlayerFieldDTO.matchId,
          playerId: data.player.matchPlayerFieldDTO.playerDTOS?.id,
          player: undefined,
          detailLocation: data.detailLocation,
          processDate: processTime?.process,
          roundType: processTime?.roundType,
          reasonFail: data?.reasonFail,
          playerGoalRecordDTO:
            data?.goalType === 1
              ? {
                  shootDirection: data?.shootDirectionKick,
                  goalForm: data?.goalForm,
                  distance: data?.distance,
                }
              : {},
          shootType: data?.shootType,
          goalType: data?.goalType,
          type: data?.type,
        }
      );
    }
    toast.success(translate('common.saveSuccess'));
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title={!id ? translate('freeCornerKick.title') : translate('freeCornerKick.titleEdit')}
      className="register-record"
      visible={open}
      width={locale === 'ko' ? 922 : 1200}
      onCancel={onCancel}
      onOk={() => form.submit()}
      footer={listFooterModal}
      zIndex={1}
    >
      <Form
        form={form}
        onFinish={onSubmitForm}
        onFinishFailed={e => {
          toast.error(translate('scoreRecord.message.supportForm'));
        }}
        style={{ width: locale === 'ko' ? 858 : 1081, overflow: 'auto' }}
      >
        <Row gutter={32} justify="start" style={{ width: locale === 'ko' ? 874 : 1084 }} wrap={false}>
          <Col>
            <RecordPlayerInfo
              avatar={
                // playerInForm?.matchPlayerFieldDTO?.playerDTOS?.avatar
                get(awayAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  ? get(awayAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  : get(ownerAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  ? get(ownerAvatars, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
                  : undefined
              }
              prefix={playerInForm?.positionType}
              onEditPlayer={() => {
                onEditPlayer();
              }}
              onEditPosition={() => {
                onEditPosition();
              }}
              player={playerInForm}
              positionMap={form.getFieldValue('detailLocation')}
            />
          </Col>
          <Col>
            <KickTypeInfo style={pageChoose !== KICK_TYPE_INFO ? { display: 'none' } : ''} />
            <ScoreInfo pageChoose={pageChoose} style={pageChoose !== SCORE_INFO ? { display: 'none' } : ''} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PlayerFreeCornerKickRecord;
