import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NOTICE_CATEGORY } from 'app/config/constants';
// import { urlLocal } from 'app/config/constants';
import { AppThunk } from 'app/config/store';
import { IDataCate } from 'app/entities/player-free-corner-kick-record/player-free-corner-kick.reducer';
import { IFormCreateNewClub, IFormDataClub } from 'app/shared/model/manager-club.model';
import { CATEGORY_DATA_CODE } from 'app/shared/util/category-data-code.constant';
import axios from 'axios';
import { get, pick } from 'lodash';
import { translate } from 'react-jhipster';

const initState = {
  dataClub: {} as IFormDataClub,
  dataUploadFile: null,
  filterList: {
    position: [],
    role: [],
    rating: [],
    roleSearch: [],
    noticeCategory: [],
    category: [],
  },
  searchRole: [],
};

const urlLocal = 'api';

export const getDataSearch = createAsyncThunk('dashboard/getDataSearch', (valueSearch: string) => {
  return axios.get<any>(`${urlLocal}/clubs/search?search=${valueSearch}&page=0&size=5`);
});

export const getMonitorSearch = createAsyncThunk('dashboard/getMonitorSearch', (body: any) => {
  return axios.post<any>(`${urlLocal}/GetAll-monitors?page=0&size=20`, body);
});

export const createNewClub = createAsyncThunk('dashboard/createNewClub', async (value: IFormCreateNewClub) => {
  return axios.post(`${urlLocal}/clubs`, value);
});

export const upLoadImg = createAsyncThunk('dashboard/upLoadImg', async (body: FormData) => {
  return axios.post<any>(`${urlLocal}/file/upload-file`, body);
});

export const getFilterOptions = createAsyncThunk(
  'dashboard/getFilterOptions',
  async ({ keys, language }: { keys: string[]; language: string }) => {
    const getUrl = code => `api/category-data-by-code?code=${code}&lang=${language}`;

    const objPromise = {
      position: axios.get(getUrl(CATEGORY_DATA_CODE.POSITION_PLAYER)),
      role: axios.get(getUrl(CATEGORY_DATA_CODE.MONITOR_ROLE)),
      rating: axios.get(getUrl(CATEGORY_DATA_CODE.MONITOR_RATING)),
      roleSearch: axios.get('api/author'),
      category: axios.get(getUrl(CATEGORY_DATA_CODE.QA_TYPE)),
    };

    const result = await axios.all<IDataCate>([
      axios.get(getUrl(CATEGORY_DATA_CODE.POSITION_PLAYER)).then(res => {
        return res.data?.dataTrans.map(a => ({
          value: a.code,
          label: a.name,
        }));
      }),
      axios.get(getUrl(CATEGORY_DATA_CODE.MONITOR_ROLE)).then(res => {
        return res.data?.dataTrans.map(a => ({
          value: a.code,
          label: a.name,
        }));
      }),
      axios.get(getUrl(CATEGORY_DATA_CODE.MONITOR_RATING)).then(res => {
        return res.data?.dataTrans.map(a => ({
          value: a.code,
          label: a.name,
        }));
      }),
      axios.get(getUrl(CATEGORY_DATA_CODE.QA_TYPE)).then(res => {
        return res.data?.dataTrans.map(a => ({
          value: a.code,
          label: a.name,
        }));
      }),
      axios.get('/api/author/search').then(res => {
        return res.data.map(a => ({
          value: a.value,
          label: translate(`author.${a.name}`),
          name: a.name,
        }));
      }),
    ]);
    return result;
  }
);

export const getAuthor = createAsyncThunk('api/getAuthor', async ({ keys, language }: { keys: string[]; language: string }) => {
  const getUrl = await axios.get('api/author');
  const newData = getUrl.data.map(item => {
    return {
      label: item.name,
      value: item.code,
    };
  });
  return newData;
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initState,
  reducers: {
    reset() {
      return initState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAuthor.fulfilled, (state, action) => {
        state.searchRole = action.payload;
      })
      .addCase(getDataSearch.fulfilled, (state, action) => {
        state.dataClub = action.payload.data;
      })
      .addCase(upLoadImg.fulfilled, (state, action) => {
        state.dataUploadFile = action.payload.data;
      })
      .addCase(getFilterOptions.fulfilled, (state, action) => {
        state.filterList = {
          noticeCategory: [
            { value: NOTICE_CATEGORY.SYSTEM, label: translate('leagueApp.notice.noticeCategoryArr.system') },
            { value: NOTICE_CATEGORY.ORGANIZATION, label: translate('leagueApp.notice.noticeCategoryArr.organization') },
          ],
          position: get(action.payload, '0'),
          role: get(action.payload, '1'),
          rating: get(action.payload, '2'),
          category: get(action.payload, '3'),
          roleSearch: get(action.payload, '4'),
        };
      });
  },
});

const dashboardReducer = dashboardSlice.reducer;
export default dashboardReducer;
export const { reset } = dashboardSlice.actions;
