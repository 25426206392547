import { Button, Modal } from 'antd';
import React from 'react';
import { Translate } from 'react-jhipster';

interface IFormProps {
  title: React.ReactElement;
  children: React.ReactElement;
  isOpen: boolean;
  handleClose: () => void;
  form: string;
  width?: number;
  className?: string;
  disable?: boolean
}
const ModalChange = ({ title, isOpen, handleClose, children, form, width, className, disable }: IFormProps) => {
  return (
    <Modal
      title={title}
      visible={isOpen}
      onCancel={handleClose}
      width={width}
      className={className}
      footer={[
        <Button key="back" onClick={handleClose}>
          <Translate contentKey="leagueApp.matchRecord.close" />
        </Button>,
        <Button disabled={disable} form={form} key="submit" type="primary" htmlType="submit">
          <Translate contentKey="leagueApp.matchRecord.save" />
        </Button>,
      ]}
    >
      {children}
    </Modal>
  );
};
export default ModalChange;
