import React, { useState, useEffect } from 'react';
import { Button, Card, Col, ConfigProvider, Empty, Form, Row, Select } from 'antd';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import { Translate, translate } from 'react-jhipster';
import TextField from 'app/modules/lm-input';
import AttachFile from 'app/entities/attach-file';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { AUTHORITIES, NOTICE_CATEGORY } from 'app/config/constants';
import axios from 'axios';
import RichTextEditor from 'app/modules/rich-text-editor';
import { BsCaretLeft } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { updateEntity, getEntity, uploadAttachFile } from './notice.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IUnitStandard } from 'app/shared/model/unit-standard.model';
import { Pageable } from 'app/shared/reducers/reducer.utils';
import { IAttachFile, INotice } from 'app/shared/model/notice.model';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

const Update = () => {
  const [form] = Form.useForm();
  const [dataUnit, setDataUnit] = useState([]);
  const dispatch = useAppDispatch();
  const detail = useAppSelector(state => state.notice.entity);
  const account = useAppSelector(state => state.authentication.account);
  const { loading } = useAppSelector(state => state.notice);
  const handleValidSubmit = (data: INotice) => {
    dispatch(uploadAttachFile(data.attachmentDTOS)).then(res =>
      dispatch(updateEntity({ ...detail, ...data, attachmentDTOS: res.payload as IAttachFile[] })).then(
        response => response.meta.requestStatus === 'fulfilled' && history.push('../../notice')
      )
    );
  };
  const foundLeagueMaster = account?.authorities.find(function (element) {
    return element === 'ROLE_LEAGUE_MASTER';
  });
  const files = Form.useWatch('attachmentDTOS', form);
  const noticeCategory = Form.useWatch('noticeCategory', form);
  const { id } = useParams<{ id: string }>();
  const handleUnit = async () => {
    const dataUnits = await axios.get<Pageable<IUnitStandard>>(`api/unit-standards/search`);
    const arrUnit = dataUnits.data?.content?.map(a => ({
      value: a.id,
      label: a.name,
    }));
    setDataUnit(arrUnit || []);
  };
  useEffect(() => {
    handleUnit();
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(getEntity(id)).then(res => {
      form.setFieldsValue(res.payload);
    });
  }, [id]);
  const { authorities } = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(authorities, [AUTHORITIES.ADMIN]);
  const history = useHistory();

  const handleBack = e => {
    history.goBack();
  };

  return (
    <Form
      form={form}
      onFinishFailed={values => handleFocusFieldFail(values)}
      scrollToFirstError
      name="control-hooks"
      initialValues={{ attachmentDTOS: [], category: foundLeagueMaster ? NOTICE_CATEGORY.ORGANIZATION : '', title: '', content: '' }}
      onFinish={handleValidSubmit}
      layout="vertical"
      requiredMark={'optional'}
    >
      <Card className="mb-3">
        <div className="border-bottom d-flex justify-content-between align-items-center" style={{ paddingBottom: 30, marginBottom: 32 }}>
          <div className="d-flex align-items-center auto-wrap">
            <Button className="rounded management-header-add-button" icon={<BsCaretLeft />} onClick={handleBack} type="primary">
              <Translate contentKey="entity.action.back" />
            </Button>
            <div className="management-layout-divider"></div>
            <p className="h4 mb-0">
              {' '}
              <Translate contentKey="leagueApp.notice.update" />
            </p>
          </div>

          <Button loading={loading} htmlType="submit" type="primary">
            <Translate contentKey="common.save" />
          </Button>
        </div>
        <Row gutter={[24, 12]}>
          <Col xl={6} lg={12} xs={24}>
            <Form.Item
              label={<h6>*{translate('leagueApp.notice.noticeCategory')}</h6>}
              name="noticeCategory"
              rules={[{ required: true, message: translate('leagueApp.notice.required.category') }]}
            >
                <Select
                  disabled={foundLeagueMaster}
                  placeholder={translate('leagueApp.playerAcademic.required.type')}
                  allowClear
                  className="custom-prefix-select"
                  suffixIcon={<SelectArrowDown />}
                >
                  {Object.values(NOTICE_CATEGORY).map((category, index) => (
                    <Select.Option value={category} key={index}>
                      {translate(`leagueApp.notice.noticeCategoryArr.${category}`)}
                    </Select.Option>
                  ))}
                </Select>
            </Form.Item>
          </Col>
          {noticeCategory === NOTICE_CATEGORY.ORGANIZATION && (
            <Col xl={6} lg={12} xs={24}>
              <Form.Item
                label={<h6>*{translate('leagueApp.notice.unitStandard')}</h6>}
                name="unitStandardId"
                rules={[
                  {
                    required: noticeCategory === NOTICE_CATEGORY.ORGANIZATION,
                    message: translate('leagueApp.notice.required.organization'),
                  },
                ]}
              >
                  <Select
                    placeholder={translate('register.unit.placeholder')}
                    className="custom-prefix-select"
                    allowClear
                    disabled={!isAdmin}
                    showSearch
                    filterOption={(input, option) => (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                    suffixIcon={<SelectArrowDown />}
                  >
                    {dataUnit.map(roles => (
                      <Select.Option value={roles.value} key={roles.value}>
                        {roles.label}
                      </Select.Option>
                    ))}
                  </Select>
              </Form.Item>
            </Col>
          )}
          <Col xs={24}>
            <Form.Item
              label={<h6>*{translate('leagueApp.notice.title')}</h6>}
              name="title"
              rules={[{ required: true, message: translate('leagueApp.notice.required.title') }]}
            >
              <TextField />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={<h6>*{translate('leagueApp.notice.content')}</h6>}
              name="content"
              rules={[{ required: true, message: translate('leagueApp.notice.required.content') }]}
            >
              <RichTextEditor />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Form.Item name="attachmentDTOS">
        <AttachFile
          files={files}
          onChange={fileList => {
            form.setFieldsValue(pre => ({ ...pre, attachmentDTOS: fileList }));
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default Update;
