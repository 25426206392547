import { Avatar, Button, Col, Form, Input, List, Row, Select, Space } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, resetEntity } from 'app/entities/match-basic-info/match-basic-info.reducer';
import { createMatch, getListTV, getWeather, setSave, updateMatch } from 'app/entities/match/match.reducer';
import Color, { COLOR } from 'app/modules/color';
import NumberField from 'app/modules/lm-input-number';
import { IClubUniform } from 'app/shared/model/club-uniform.model';
import { IDefaultMatch, IFormPostMatch } from 'app/shared/model/match.model';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { boardcast } from 'app/shared/util/boardcast.constant';
import { handleFocusFieldFail, uniformColor } from 'app/shared/util/entity-utils';
import { WEATHER } from 'app/shared/util/weather.constant';
import axios from 'axios';
import querystring from 'query-string';
import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sliceLocale } from 'app/shared/util/entity-utils';
import './index.scss';
import { handleValidateMessageLanguageChanged } from 'app/shared/util/validate';
import { path } from '../../../../routes';

export const MatchPreparationInformation = () => {
  const { Option } = Select;
  const { id } = useParams<{ id: string }>();
  const [clubUniform1, setClubUniform1] = useState<IClubUniform | null>(null);
  const [clubUniform2, setClubUniform2] = useState<IClubUniform | null>(null);
  const [clubUniform3, setClubUniform3] = useState<IClubUniform | null>(null);
  const [clubUniform4, setClubUniform4] = useState<IClubUniform | null>(null);
  const [ownerUniforms, setOwnerUniforms] = useState<IClubUniform[]>([]);
  const [awayUniforms, setAwayUniforms] = useState<IClubUniform[]>([]);
  const [ownerGKUniforms, setOwnerGKUniforms] = useState<IClubUniform[]>([]);
  const [awayGKUniforms, setAwayGKUniforms] = useState<IClubUniform[]>([]);
  const location = useLocation();
  const [disable, setDisable] = useState(false);
  const [directValue, setDirectValue] = useState('');
  const [defaultValue, setDefaultValue] = useState<string[]>([]);
  const [show, setShow] = useState(false);
  const [form] = Form.useForm();
  const matchInfoEntity = useAppSelector(state => state.match.entity);
  const broastcast = Form.useWatch('lstBoarCast', form);
  const matchBasicInfoEntity: IDefaultMatch = useAppSelector(state => state.matchBasicInfoReducer.entity);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const listWeather = useAppSelector(state => state.match.listWeather);
  const listTV = useAppSelector(state => state.match.listTV);
  const getType = useAppSelector(state => state.match.type);
  const history = useHistory();
  const { push } = useHistory();
  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
  }, [locale]);

  const handleClickDetail = data => {
    push(path.clubDetail.replace(':id', data));
  };

  const getUniforms = async (clubId, playerType, isOwner) => {
    const params = querystring.stringify({ clubId, playerType });
    const result = await axios.get(`/api/club-uniforms/find-club-uniforms-by-player-type?` + params);
    if (playerType === 0 && isOwner) {
      setOwnerUniforms(
        result.data
        // .map(item => {
        //   if (item.type === 1) {
        //     return item;
        //   }
        // })
        // .filter(ele => !!ele)
      );
    }
    if (playerType === 1 && isOwner) {
      setOwnerGKUniforms(
        result.data
        // .map(item => {
        //   if (item.type === 1) {
        //     return item;
        //   }
        // })
        // .filter(ele => !!ele)
      );
    }
    if (playerType === 0 && !isOwner) {
      setAwayUniforms(
        result.data
        // .map(item => {
        //   if (item.type === 2) {
        //     return item;
        //   }
        // })
        // .filter(ele => !!ele)
      );
    }
    if (playerType === 1 && !isOwner) {
      setAwayGKUniforms(
        result.data
        // .map(item => {
        //   if (item.type === 2) {
        //     return item;
        //   }
        // })
        // .filter(ele => !!ele)
      );
    }
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (getType) {
      history.replace(`?type=${getType}`);
    }
  }, [getType]);
  const getParams = new URLSearchParams(location.search).get('type');
  useEffect(() => {
    if (!id) {
      return;
    }
    form.resetFields();
    dispatch(getEntity(id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        const newData: any = res.payload;
        // setClubUniform1(ownerUniforms?.filter(item => item?.id === newData.data?.ownerClubUniformId)[0]);
        // setClubUniform2(ownerGKUniforms?.filter(item => item?.id === newData.data.ownerClubUniformGkId)[0]);
        // setClubUniform3(awayUniforms?.filter(item => item?.id === newData.data.awayClubUniformId)[0]);
        // setClubUniform4(awayGKUniforms?.filter(item => item?.id === newData.data.awayClubUniformGkId)[0]);
        // setDefaultValue(newData.data?.lstBoarCast);
        form.setFieldsValue({
          ownerClubUniformId: newData.data.ownerClubUniformId,
          ownerClubGKUniformId: newData.data.ownerClubUniformGkId,
          awayClubUniformId: newData.data.awayClubUniformId,
          awayClubGKUniformId: newData.data.awayClubUniformGkId,
          lstBoarCast: newData.data.lstBoarCast,
          weather: newData.data.weather,
          spectator: newData.data.audienceTotal,
          temperature: newData.data.temperature,
          humidity: newData.data.humidity,
        });
      }
    });
    return () => {
      dispatch(resetEntity());
      dispatch(setSave(true));
    };
  }, []);
  useEffect(() => {
    Promise.all([dispatch(getWeather(locale)), dispatch(getListTV(locale))]);
  }, [locale]);

  useEffect(() => {
    setClubUniform1(ownerUniforms?.filter(item => item?.id === matchBasicInfoEntity?.ownerClubUniformId)[0]);
    setClubUniform2(ownerGKUniforms?.filter(item => item?.id === matchBasicInfoEntity?.ownerClubUniformGkId)[0]);
    setClubUniform3(awayUniforms?.filter(item => item?.id === matchBasicInfoEntity?.awayClubUniformId)[0]);
    setClubUniform4(awayGKUniforms?.filter(item => item?.id === matchBasicInfoEntity?.awayClubUniformGkId)[0]);
    setDefaultValue(matchBasicInfoEntity?.lstBoarCast);
  }, [ownerGKUniforms, ownerUniforms, awayGKUniforms, awayUniforms, matchBasicInfoEntity]);

  useEffect(() => {
    if (!matchInfoEntity || !matchInfoEntity.id) {
      return;
    }
    Promise.all([
      getUniforms(matchInfoEntity.ownerClubId, 0, true),
      getUniforms(matchInfoEntity.ownerClubId, 1, true),
      getUniforms(matchInfoEntity.awayClubId, 0, false),
      getUniforms(matchInfoEntity.awayClubId, 1, false),
    ]);
  }, [matchInfoEntity]);
  const handleSubmit = data => {
    const body: IFormPostMatch = {
      matchId: id,
      audienceTotal: data.spectator,
      weather: data.weather,
      temperature: data.temperature,
      humidity: data.humidity,
      ownerClubId: matchInfoEntity?.ownerClubId,
      ownerClubUniformId: data.ownerClubUniformId,
      ownerClubUniformGkId: data.ownerClubGKUniformId,
      awayClubId: matchInfoEntity?.awayClubId,
      awayClubUniformId: data.awayClubUniformId,
      awayClubUniformGkId: data.awayClubGKUniformId,
      createdDate: new Date(),
      lstBoarCast: data.lstBoarCast,
    };
    if (matchBasicInfoEntity?.id === 0 || !matchBasicInfoEntity?.id) {
      dispatch(createMatch(body)).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(getEntity(id));
        }
      });
    } else {
      body.id = matchBasicInfoEntity?.id;
      dispatch(updateMatch(body));
    }
  };
  const uniformList = (data: IClubUniform) => {
    return [
      {
        label: translate('leagueApp.match.shirt'),
        value: data?.shirtColorText2 ? ` ${data?.shirtColorText} / ${data?.shirtColorText2} ` : data?.shirtColorText,
      },
      {
        label: translate('leagueApp.match.short'),
        value: data?.shortColorText2 ? ` ${data?.shortColorText} / ${data?.shortColorText2} ` : data?.shortColorText,
      },
      {
        label: translate('leagueApp.match.sock'),
        value: data?.sockColorText2 ? ` ${data?.sockColorText} / ${data?.sockColorText2} ` : data?.sockColorText,
      },
    ];
  };
  const handleChange = (value: number, uniformArr: IClubUniform[], setUniform: React.Dispatch<React.SetStateAction<IClubUniform>>) => {
    if (!value) {
      return setUniform({});
    }
    const newArr = uniformArr.filter(item => item.id === value);
    setUniform(newArr[0]);
  };
  const handleChangeBoarCast = (value: string) => {
    dispatch(setSave(false));
    if (defaultValue.includes(value)) {
      toast.error(translate('leagueApp.match.tvSelected'));
      return;
    }
    if (value === 'Direct Input' || value === '직접 입력') {
      setShow(true);
      return;
    } else {
      setShow(false);
    }
    const newArr = defaultValue.filter(item => item !== value);
    setDefaultValue([...newArr, value]);
  };
  const handleAdd = () => {
    if (directValue) {
      if (defaultValue.includes(directValue)) {
        toast.error(translate('leagueApp.match.tvSelected'));
        return;
      }
      setDefaultValue([...defaultValue, directValue.toString().trim()]);
      setDirectValue('');
    }
  };
  const handleChangeListCast = (value: string[]) => {
    setDefaultValue(value);
  };

  useEffect(() => {
    if (defaultValue?.length) {
      form.setFieldsValue({
        lstBoarCast: defaultValue,
      });
    }
  }, [defaultValue?.length]);

  const handleChangeDirectInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.startsWith(' ')) {
      return;
    }
    setDirectValue(e.target.value);
  };

  return (
    <Form
      form={form}
      onFinishFailed={values => handleFocusFieldFail(values, 'general')}
      scrollToFirstError
      onFinish={handleSubmit}
      layout="vertical"
      name="general"
      requiredMark={'optional'}
    >
      <div className="d-flex justify-content-between align-items-center record-layout-header">
        <p className="heading-20-bold neutral-1 mb-0">
          <Translate contentKey="common.general" />
        </p>
        {!disable && (
          <Button htmlType="submit">
            <Translate contentKey="common.save" />
          </Button>
        )}
      </div>
      <p className="title-18-bold neutral-1 mb-3">
        <Translate contentKey="leagueApp.match.matchInformation" />
      </p>
      <Row gutter={[32, 32]} className="mb-3">
        <Col xl={6} lg={12} xs={24}>
          <Form.Item
            label={<h6>* {translate('leagueApp.match.spectator')}</h6>}
            name="spectator"
            rules={[
              {
                required: true,
                message: translate('leagueApp.match.missingValue'),
              },
            ]}
          >
            <NumberField placeholder={translate('leagueApp.match.placeholderSpectator')} negative={true} min={-1} disabled={disable} />
          </Form.Item>
        </Col>
        <Col xl={6} lg={12} xs={24}>
          <Form.Item
            label={<h6>* {translate('leagueApp.match.weather')}</h6>}
            name="weather"
            rules={[
              {
                required: true,
                message: translate('leagueApp.match.missingValue'),
              },
            ]}
          >
            <Select
              placeholder={translate('leagueApp.match.selectWeather')}
              disabled={disable}
              className="custom-prefix-select"
              allowClear
              suffixIcon={<SelectArrowDown />}
            >
              {listWeather?.dataTrans?.map(item => (
                <Select.Option value={item.code} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={6} lg={12} xs={24}>
          <Form.Item
            label={<h6>* {translate('leagueApp.match.temperature')}</h6>}
            name="temperature"
            rules={[
              {
                required: true,
                message: translate('leagueApp.match.missingValue'),
              },
            ]}
          >
            <NumberField placeholder={translate('leagueApp.match.enterTemperature')} negative={false} min={-9999999} disabled={disable} />
          </Form.Item>
        </Col>
        <Col xl={6} lg={12} xs={24}>
          <Form.Item
            label={<h6>* {translate('leagueApp.match.humidity')}</h6>}
            name="humidity"
            rules={[
              {
                required: true,
                message: translate('leagueApp.match.missingValue'),
              },
            ]}
          >
            <NumberField placeholder={translate('leagueApp.match.enterhumidity')} disabled={disable} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[32, 0]}>
        <Col span={24}>
          <p className=" mb-3 title-18-bold neutral-1">
            <Translate contentKey="leagueApp.match.clubInformation" />
          </p>
        </Col>
        <Col lg={12} xs={24} style={{ borderRight: '1px solid #ECEFF4' }}>
          <Row className="py-2" gutter={[32, 32]}>
            <Col xs={12} className="d-flex">
              <div className="title-info">
                <div className="avatar">
                  <Avatar size={80} src={matchInfoEntity?.ownerBase64Avt} />
                </div>
                <div className="title">
                  <div>
                    <Translate contentKey="leagueApp.match.squad.owner">Owner</Translate>
                    <span className="hot-link-club" onClick={e => handleClickDetail(matchInfoEntity?.ownerClubId)}>
                      {matchInfoEntity?.ownerClubName}
                    </span>
                    <span style={{ fontWeight: 500, fontSize: 16, color: '#292C38' }}>{matchBasicInfoEntity?.coachOwnerName}</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}></Col>
            <Col lg={12} xs={24}>
              <Form.Item
                label={<h6>* {translate('leagueApp.match.uniform')}</h6>}
                name="ownerClubUniformId"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.match.missingValue'),
                  },
                ]}
              >
                <Select
                  disabled={disable}
                  onChange={value => handleChange(value, ownerUniforms, setClubUniform1)}
                  className="custom-prefix-select"
                  allowClear
                  suffixIcon={<SelectArrowDown />}
                  placeholder={translate('common.chooseUniform')}
                  optionFilterProp="children"
                >
                  {ownerUniforms.map((item, index) => (
                    <Select.Option value={item?.id} key={item.id}>
                      {/* <Space direction="horizontal"> */}
                      <span>{translate('leagueApp.match.shirt')}:</span>
                      {item?.shirtColorText}
                      {'/'}
                      {item?.shirtColorText2}
                      {' , '}
                      <span>{translate('leagueApp.match.short')}:</span>
                      {item?.shortColorText} {'/'}
                      {item?.shortColorText2}
                      {' , '}
                      <span>{translate('leagueApp.match.sock')}:</span>
                      {item?.sockColorText} {'/'}
                      {item?.sockColorText2}
                      {/* </Space> */}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <List
                itemLayout="horizontal"
                dataSource={uniformList(clubUniform1)}
                renderItem={item => {
                  return (
                    <List.Item>
                      <Row gutter={[12, 12]} className="w-100" align="middle">
                        <Col xs={6}>{item.label} </Col>
                        <Col xs={18}> {item.value}</Col>
                      </Row>
                    </List.Item>
                  );
                }}
              />
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                label={<h6>* {translate('leagueApp.match.uniformGK')}</h6>}
                name="ownerClubGKUniformId"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.match.missingValue'),
                  },
                ]}
              >
                <Select
                  disabled={disable}
                  className="custom-prefix-select"
                  allowClear
                  suffixIcon={<SelectArrowDown />}
                  onChange={value => handleChange(value, ownerGKUniforms, setClubUniform2)}
                  placeholder={translate('common.chooseUniformGK')}
                  optionFilterProp="children"
                >
                  {ownerGKUniforms.map((item, index) => (
                    <Select.Option value={item?.id} key={item.id}>
                      {/* <Space direction="horizontal"> */}
                      <span>{translate('leagueApp.match.shirt')}:</span>
                      {item?.shirtColorText}
                      {'/'}
                      {item?.shirtColorText2}
                      {' , '}
                      <span>{translate('leagueApp.match.short')}:</span>
                      {item?.shortColorText} {'/'}
                      {item?.shortColorText2}
                      {' , '}
                      <span>{translate('leagueApp.match.sock')}:</span>
                      {item?.sockColorText} {'/'}
                      {item?.sockColorText2}
                      {/* </Space> */}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <List
                itemLayout="horizontal"
                dataSource={uniformList(clubUniform2)}
                renderItem={item => (
                  <List.Item>
                    <Row gutter={[12, 12]} className="w-100" align="middle">
                      <Col xs={6}>{item.label} </Col>

                      <Col xs={18}> {item.value}</Col>
                    </Row>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Col>
        <Col lg={12} xs={24}>
          <Row className="py-2" gutter={[32, 32]}>
            <Col xs={12} className="d-flex">
              <div className="title-info">
                <div className="avatar">
                  <Avatar size={80} src={matchInfoEntity?.awayBase64Avt} />
                </div>
                <div className="title">
                  <div>
                    <Translate contentKey="leagueApp.match.squad.away">Guest</Translate>
                    <span className="hot-link-club" onClick={e => handleClickDetail(matchInfoEntity?.awayClubId)}>
                      {matchInfoEntity?.awayClubName}
                    </span>
                    <span style={{ fontWeight: 500, fontSize: 16, color: '#292C38' }}>{matchBasicInfoEntity?.coachAwayName}</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}></Col>
            <Col lg={12} xs={24}>
              <Form.Item
                label={<h6>* {translate('leagueApp.match.awayUniform')}</h6>}
                name="awayClubUniformId"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.match.missingValue'),
                  },
                ]}
              >
                <Select
                  className="custom-prefix-select"
                  allowClear
                  suffixIcon={<SelectArrowDown />}
                  disabled={disable}
                  onChange={value => handleChange(value, awayUniforms, setClubUniform3)}
                  placeholder={translate('common.chooseUniform')}
                >
                  {awayUniforms.map((item, index) => (
                    <Select.Option value={item?.id} key={item.id}>
                      {/* <Space direction="horizontal"> */}
                      <span>{translate('leagueApp.match.shirt')}:</span>
                      {item?.shirtColorText}
                      {'/'}
                      {item?.shirtColorText2}
                      {' , '}
                      <span>{translate('leagueApp.match.short')}:</span>
                      {item?.shortColorText} {'/'}
                      {item?.shortColorText2}
                      {' , '}
                      <span>{translate('leagueApp.match.sock')}:</span>
                      {item?.sockColorText} {'/'}
                      {item?.sockColorText2}
                      {/* </Space> */}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <List
                itemLayout="horizontal"
                dataSource={uniformList(clubUniform3)}
                renderItem={item => (
                  <List.Item>
                    <Row gutter={[12, 12]} className="w-100" align="middle">
                      <Col xs={6}>{item.label} </Col>

                      <Col xs={18}> {item.value}</Col>
                    </Row>
                  </List.Item>
                )}
              />
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                label={<h6>* {translate('leagueApp.match.awayUniformGK')}</h6>}
                name="awayClubGKUniformId"
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.match.missingValue'),
                  },
                ]}
              >
                <Select
                  className="custom-prefix-select"
                  allowClear
                  suffixIcon={<SelectArrowDown />}
                  disabled={disable}
                  onChange={value => handleChange(value, awayGKUniforms, setClubUniform4)}
                  placeholder={translate('common.chooseUniformGK')}
                >
                  {awayGKUniforms.map((item, index) => (
                    <Select.Option value={item?.id} key={item.id}>
                      {/* <Space direction="horizontal"> */}
                      <span>{translate('leagueApp.match.shirt')}:</span>
                      {item?.shirtColorText}
                      {'/'}
                      {item?.shirtColorText2}
                      {' , '}
                      <span>{translate('leagueApp.match.short')}:</span>
                      {item?.shortColorText} {'/'}
                      {item?.shortColorText2}
                      {' , '}
                      <span>{translate('leagueApp.match.sock')}:</span>
                      {item?.sockColorText} {'/'}
                      {item?.sockColorText2}
                      {/* </Space> */}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <List
                itemLayout="horizontal"
                dataSource={uniformList(clubUniform4)}
                renderItem={item => (
                  <List.Item>
                    <Row gutter={[12, 12]} className="w-100" align="middle">
                      <Col xs={6}>{item.label} :</Col>
                      <Col xs={18}> {item.value}</Col>
                    </Row>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <p className="py-3 title-18-bold neutral-1">
          <Translate contentKey="leagueApp.match.broadcastingStationInformation" />
        </p>
        <Row style={{ marginBottom: 12 }}>{/* <Translate contentKey="leagueApp.match.station" /> */}</Row>
        {!disable && (
          <Row gutter={[16, 16]}>
            <Col lg={6} xs={24}>
              <Form.Item label={translate('leagueApp.match.station')}>
                <Select
                  style={{ width: '100%' }}
                  onChange={handleChangeBoarCast}
                  className="custom-prefix-select"
                  suffixIcon={<SelectArrowDown />}
                  placeholder={translate('leagueApp.match.placeholderStation')}
                >
                  {listTV?.map(item => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {show && (
              <>
                <Col lg={6} xs={24} style={{ paddingLeft: '37px' }}>
                  <Form.Item label={translate('leagueApp.match.enter')}>
                    <Input
                      style={{ width: '100%' }}
                      disabled={disable}
                      placeholder={translate('leagueApp.match.directInput')}
                      value={directValue}
                      onChange={handleChangeDirectInput}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center', marginTop: '11px' }}>
                  <Button onClick={handleAdd} style={{ width: 112, height: '56px' }}>
                    <Translate contentKey="leagueApp.match.add" />
                  </Button>
                </Col>
              </>
            )}
          </Row>
        )}
        <Form.Item
          name="lstBoarCast"
          style={{ marginTop: '32px' }}
          label={'* ' + translate('leagueApp.match.stationList')}
          rules={[
            {
              required: true,
              message: translate('leagueApp.match.missingValue'),
            },
          ]}
        >
          <Select
            disabled={disable}
            mode="multiple"
            onChange={handleChangeListCast}
            onInputKeyDown={e => e.preventDefault()}
            className="special-select"
            open={false}
            showSearch={false}
          />
        </Form.Item>
      </div>
    </Form>
  );
};
