import dayjs from 'dayjs';

export interface IStaff {
  id?: number;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
  status?: number | null;
  avatar?: string;
  name?: string;
  birthday?: string;
  birthPlace?: string;
  idNumber?: string;
  nationality?: string | null;
  email?: string;
  phone?: string;
  userId?: number | null;
  position?: string;
  degree?: string | null;
  fileDegree?: string | null;
  homeAddress?: string | null;
  fileCommitment?: string;
  fileContract?: string;
  fileWorkLicense?: string;
  fileITC?: string | null;
  base64Avatar?: string | null;
  base64FileDegree?: string | null;
  base64FileCommitment?: string | null;
  base64FileContract?: string | null;
  base64FileWorkLicense?: string | null;
  base64FileITC?: string | null;
  clubName?: string | null;
}

export const defaultValue: Readonly<IStaff> = {};
