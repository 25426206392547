import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'antd';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity, getEntities, clearMsgDelete } from './player.reducer';
import { toast } from 'react-toastify';

export const PlayerDeleteDialog = (props: any) => {
  const { modalDelete, userClicked, setModalDelete } = props;
  const [loadModal, setLoadModal] = useState(false);
  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(getEntity(props.match.params.id));
  //   setLoadModal(true);
  // }, []);

  const playerEntity = useAppSelector(state => state.player.entity);
  const deleteSuccess = useAppSelector(state => state.player.deleteSuccess);

  const handleClose = () => {
    // props.history.push('/player' + props.location.search);
    setModalDelete(false);
  };
  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.club.delete.deleteSuccess'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);
  // useEffect(() => {
  //   if (updateSuccess && loadModal) {
  //     handleClose();
  //     setLoadModal(false);
  //   }
  // }, [updateSuccess]);

  const confirmDelete = () => {
    const obj = {
      playerId: userClicked,
    };
    dispatch(deleteEntity(obj));
    setModalDelete(false);
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="playerDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="leagueApp.player.delete.question">
        <Translate contentKey="leagueApp.player.delete.question"></Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-player" data-cy="entityConfirmDeleteButton" color="danger" onClick={props.onSubmit}>
          <Translate contentKey="entity.action.delete2">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PlayerDeleteDialog;
