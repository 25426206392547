export enum PLAYER_IN_MATCH {
  MAIN = 1,
  SUB = 0,
}
export enum MatchStatus {
  FINISH = 3,
}

export enum MATCH_RECORD_VIEW {
  FIELD = 'FIELD',
  SHEET = 'SHEET',
}

export enum SHOOT_TYPE {
  ON_TARGET = 1,
  NORMAL = 2,
}

export enum SHOOT_RESULT {
  SUCCESS = 1,
  FAIL = 0,
}
