import React from 'react';
import { translate, Translate } from 'react-jhipster';

interface Props {
  rating: string;
}

export const RatingTypeContainer = ({ rating }: Props) => {
  if ( rating === null || rating === '') {
    return <div></div>;
  }
  if (rating === "0") {
    return (
      <div>
        <p>{translate('leagueApp.monitor.monitorRating.international')}</p>
      </div>
    );
  }
  if (rating === "1") {
    return (
      <div>
        <p>{translate('leagueApp.monitor.monitorRating.level1')}</p>
      </div>
    );
  }
  if (rating === "2") {
    return (
      <div>
        <p>{translate('leagueApp.monitor.monitorRating.level2')}</p>
      </div>
    );
  }
  if (rating === "3") {
    return (
      <div>
        <p>{translate('leagueApp.monitor.monitorRating.level3')}</p>
      </div>
    );
  }
  if (rating === "4") {
    return (
      <div>
        <p>{translate('leagueApp.monitor.monitorRating.level4')}</p>
      </div>
    );
  }
  if (rating === "5") {
    return (
      <div>
        <p>{translate('leagueApp.monitor.monitorRating.level5')}</p>
      </div>
    );
  }
};
