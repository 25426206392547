import { Form, Input, Button, Modal, Row, Col, Select, TimePicker, ConfigProvider, Empty } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import DatePicker from 'app/modules/date-picker';
import NumberField from 'app/modules/lm-input-number';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import TextField from 'app/modules/lm-input';
import GetLeagueStandardModal from './get-league-standard-modal';
import { clearMsgCreate, clearMsgUpdate, createEntity } from './league.reducer';
import { handleValidateMessageLanguageChanged } from 'app/shared/util/validate';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { MATCH_TIME, NUMBER_PLAYERS, OVERTIME } from 'app/config/constants';
import locale from 'antd/es/date-picker/locale/de_DE';
import vi_VN from 'antd/lib/locale/vi_VN';

export const LeagueAddDialog = (props: any) => {
  const { isOpen, setIsOpen, entityId, setEntityId, updateFilter } = props;
  const dispatch = useAppDispatch();
  const [leagueStandardModal, setLeagueStandardModal] = useState(null);
  const createSuccess = useAppSelector(state => state.league.createSuccess);
  const [testEffect, setTestEffect] = useState(null);
  const [array, setArray] = useState([]);
  const [isNew, setIsNew] = useState(true);
  const [createModal, setCreateModal] = useState(false);
  const [subPlayer, setSubPlayer] = useState('9');
  const [entitySave, setEntitySave] = useState({
    leagueStandardName: '',
    name: '',
    manager: '',
    owner: '',
    issueYear: '',
    startDate: '',
    endDate: '',
    expirationDate: '',
    email: '',
    timeOfRound: null,
    timeOfRoundExtra: null,
    numberOfPlayers: null,
    subPlayerMax: null,
  });
  const localeWeb = useAppSelector(state => state.locale.currentLocale);

  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
  }, [localeWeb]);

  useEffect(() => {
    if (createSuccess) {
      handleReset();
    }
  }, [createSuccess]);

  /** save action **/
  const handleValidSubmit = e => {
    const data: any = {};
    data.leagueStandardId = form.getFieldValue('leagueStandardId');
    data.name = form.getFieldValue('name');
    data.manager = form.getFieldValue('manager');
    data.owner = form.getFieldValue('owner');
    data.email = form.getFieldValue('email');
    data.issueYear = dayjs(form.getFieldValue('issueYear')).format('YYYY');
    data.startDate = dayjs(form.getFieldValue('startDate')).format('YYYY-MM-DD');
    data.endDate = dayjs(form.getFieldValue('endDate')).format('YYYY-MM-DD');
    data.expirationDate = dayjs(form.getFieldValue('expirationDate'));
    // data.timeOfRound = form.getFieldValue('timeOfRound');
    // data.timeOfRoundExtra = form.getFieldValue('timeOfRoundExtra');
    data.numberOfPlayers = form.getFieldValue('numberOfPlayers');
    data.subPlayerMax = form.getFieldValue('subPlayerMax');
    dispatch(createEntity(data));
    updateFilter();
    setIsOpen(false);
  };

  const [form] = Form.useForm();

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };

  /** new **/
  const handleReset = () => {
    setEntitySave({
      leagueStandardName: '',
      name: '',
      manager: '',
      owner: '',
      issueYear: '',
      startDate: '',
      endDate: '',
      expirationDate: '',
      email: '',
      timeOfRound: null,
      timeOfRoundExtra: null,
      numberOfPlayers: null,
      subPlayerMax: null,
    });
    form.setFieldsValue({
      leagueStandardId: '',
      leagueStandardName: '',
      name: '',
      manager: '',
      owner: '',
      issueYear: '',
      startDate: '',
      endDate: '',
      expirationDate: '',
      email: '',
      timeOfRound: null,
      timeOfRoundExtra: null,
      numberOfPlayers: null,
      subPlayerMax: null,
    });
    setLeagueStandardModal(null);
  };

  function closeDialog() {
    handleReset();
    setIsOpen(false);
    setEntityId(false);
  }

  const handleOpenGetLeagueStandardModal = () => {
    setCreateModal(true);
    setLeagueStandardModal('');
  };
  if (leagueStandardModal) {
    const objChoosing: any = {
      leagueStandardId: Number,
      leagueStandardName: '',
      unitStandardName: '',
    };
    const unique = leagueStandardModal.map(a => {
      (objChoosing.leagueStandardName = a.name), (objChoosing.unitStandardName = a.unitStandardName), (objChoosing.leagueStandardId = a.id);
    });
    form.setFieldsValue({
      leagueStandardId: objChoosing.leagueStandardId,
      leagueStandardName: objChoosing.leagueStandardName,
      manager: objChoosing.unitStandardName,
    });
  }

  return (
    <React.Fragment>
      {createModal && (
        <GetLeagueStandardModal createModal={createModal} setCreateModal={setCreateModal} setLeagueStandardModal={setLeagueStandardModal} />
      )}

      <Modal
        visible={isOpen}
        onCancel={closeDialog}
        width={800}
        title={<Translate contentKey="leagueApp.league.home.createLabel" />}
        wrapClassName="management-wrapper-modal"
        footer={null}
      >
        <Form
          form={form}
          onFinishFailed={values => handleFocusFieldFail(values)}
          scrollToFirstError
          name="control-hooks"
          onFinish={handleValidSubmit}
          layout="vertical"
          requiredMark={'optional'}
        >
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="leagueStandardName"
                label={<span>{translate('leagueApp.league.detail.leagueStandardCode')}</span>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.league.required.leagueStandard'),
                  },
                ]}
              >
                <Input
                  suffix={
                    <Button className="position-absolute" style={{ right: '8px' }} onClick={handleOpenGetLeagueStandardModal}>
                      <Translate contentKey="leagueApp.league.choose" />
                    </Button>
                  }
                  placeholder={translate('leagueApp.league.placeholder.standardLeague')}
                  disabled
                ></Input>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="email"
                label={<span>{translate('leagueApp.league.detail.email')}</span>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.league.required.email'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.league.placeholder.email')} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Row gutter={[32, 0]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="manager"
                    label={<span>{translate('leagueApp.league.detail.manager')}</span>}
                    rules={[
                      {
                        required: true,
                        message: translate('leagueApp.league.required.chairMan'),
                      },
                    ]}
                  >
                    <TextField disabled placeholder={translate('leagueApp.league.placeholder.host')} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="name"
                    label={<span>{translate('leagueApp.league.detail.name')}</span>}
                    rules={[
                      {
                        required: true,
                        message: translate('leagueApp.league.required.name'),
                      },
                    ]}
                  >
                    <TextField placeholder={translate('leagueApp.league.placeholder.name')} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="owner"
                    label={<span>{translate('leagueApp.league.detail.owner')}</span>}
                    rules={[
                      {
                        required: true,
                        message: translate('leagueApp.league.required.owner'),
                      },
                    ]}
                  >
                    <TextField placeholder={translate('leagueApp.league.placeholder.owner')}></TextField>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="issueYear"
                    label={<span>{translate('leagueApp.league.detail.issueYear')}</span>}
                    rules={[
                      {
                        required: true,
                        message: translate('leagueApp.league.required.issueYear'),
                      },
                    ]}
                  >
                    <DatePicker
                      suffixIcon={null}
                      picker="year"
                      style={{ width: '100%' }}
                      placeholder={translate('leagueApp.league.placeholder.issueYear')}
                      format="YYYY"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="startDate"
                    label={<span>{translate('leagueApp.league.detail.startDate')}</span>}
                    rules={[
                      {
                        required: true,
                        message: translate('leagueApp.league.required.startDate'),
                      },
                    ]}
                  >
                    <DatePicker
                      locale={{
                        ...locale,
                        lang: {
                          ...locale.lang,
                          today: translate('entity.action.today'),
                        },
                      }}
                      style={{ width: '100%' }}
                      placeholder={translate('leagueApp.league.placeholder.matchDate')}
                      format="YYYY-MM-DD"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="endDate"
                    label={<span>{translate('leagueApp.league.detail.endDate')}</span>}
                    rules={[
                      {
                        required: true,
                        message: translate('leagueApp.league.required.endDate'),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('startDate') <= value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(translate('leagueApp.league.required.compileDate')));
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      locale={{
                        ...locale,
                        lang: {
                          ...locale.lang,
                          today: translate('entity.action.today'),
                        },
                      }}
                      style={{ width: '100%' }}
                      placeholder={translate('leagueApp.league.placeholder.matchDate')}
                      format="YYYY-MM-DD"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="expirationDate"
                    label={<span>{translate('leagueApp.league.detail.expirationDate')}</span>}
                    rules={[
                      {
                        required: true,
                        message: translate('leagueApp.league.required.expirationDate'),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('startDate') >= value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(translate('leagueApp.league.required.compileDate1')));
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      locale={{
                        ...locale,
                        lang: {
                          ...locale.lang,
                          today: translate('entity.action.today'),
                        },
                      }}
                      style={{ width: '100%' }}
                      placeholder={translate('leagueApp.league.placeholder.matchDate')}
                      format="YYYY-MM-DD"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="numberOfPlayers"
                    rules={[{ required: true, message: translate('leagueApp.league.required.numberPlayer') }]}
                    label={translate('leagueApp.league.numberPlayer')}
                  >
                    <Select
                      options={NUMBER_PLAYERS}
                      suffixIcon={<SelectArrowDown />}
                      allowClear
                      className="custom-prefix-select"
                      placeholder={translate('leagueApp.league.placeholder.numberPlayer')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="subPlayerMax" label={translate('leagueApp.league.numberReservePlayer')}>
                    <TextField defaultValue={subPlayer} placeholder={translate('leagueApp.league.placeholder.numberReservePlayer')} />
                  </Form.Item>
                </Col>

                {/* <Col xs={24} md={12}>
                  <Form.Item
                    name="timeOfRound"
                    rules={[{ required: true, message: translate('leagueApp.league.required.timeOneHalf') }]}
                    label={<span>{translate('leagueApp.league.timeOneHalf')}</span>}
                  >
                    <Select
                      options={MATCH_TIME}
                      suffixIcon={<SelectArrowDown />}
                      allowClear
                      className="custom-prefix-select"
                      placeholder={translate('leagueApp.league.placeholder.timeOneHalf')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="timeOfRoundExtra"
                    rules={[{ required: true, message: translate('leagueApp.league.required.timeExtraHalf') }]}
                    label={translate('leagueApp.league.timeExtraHalf')}
                  >
                    <Select
                      suffixIcon={<SelectArrowDown />}
                      allowClear
                      className="custom-prefix-select"
                      placeholder={translate('leagueApp.league.placeholder.timeExtraHalf')}
                    >
                      <Select.Option value={1}>{translate('leagueApp.league.noOverTime')}</Select.Option>
                      {OVERTIME.map(item => {
                        return (
                          <Select.Option key={item.value} value={item.value}>
                            {item.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col> */}
              </Row>
            </Col>
          </Row>

          <div className="management-modal-footer">
            <Button key="back" onClick={closeDialog}>
              <Translate contentKey="register.form.close" />
            </Button>
            <Button key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }}>
              <Translate contentKey="settings.form.button" />
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default LeagueAddDialog;
