import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './player.reducer';

export const PlayerDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const playerEntity = useAppSelector(state => state.player.entity);
  return (
    <div className="club-info" style={{ border: '1px solid #ccc', padding: '12px 12px', height: '100vh' }}>
      <div>
        <div>
          <div className="position-relative">
            <div className="d-flex align-items-center justify-content-between payment">
              <h5 className="mb-0">
                {/* <Translate contentKey="contactInfor.newaddress">Thêm mới địa chỉ</Translate> */}
                {/* {formPlayer.koName} */}
                aaaaa
              </h5>
              <Button className="button-add">
                <Translate contentKey="clubmng.save" />
              </Button>
            </div>
          </div>
        </div>
        <h2 data-cy="playerDetailsHeading">
          <Translate contentKey="leagueApp.player.detail.title">Player</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{playerEntity.id}</dd>
          <dt>
            <span id="avatar">
              <Translate contentKey="leagueApp.player.avatar">Avatar</Translate>
            </span>
            {/* <UncontrolledTooltip target="avatar">
              <Translate contentKey="leagueApp.player.help.avatar" />
            </UncontrolledTooltip> */}
          </dt>
          <dd>{playerEntity.avatar}</dd>
          <dt>
            <span id="koName">
              <Translate contentKey="leagueApp.player.koName">Ko Name</Translate>
            </span>
          </dt>
          <dd>{playerEntity.koName}</dd>
          <dt>
            <span id="enName">
              <Translate contentKey="leagueApp.player.enName">En Name</Translate>
            </span>
          </dt>
          <dd>{playerEntity.enName}</dd>
          <dt>
            <span id="uniformName">
              <Translate contentKey="leagueApp.player.uniformName">Uniform Name</Translate>
            </span>
          </dt>
          <dd>{playerEntity.uniformName}</dd>
          <dt>
            <span id="position">
              <Translate contentKey="leagueApp.player.position">Position</Translate>
            </span>
          </dt>
          <dd>{playerEntity.position}</dd>
          <dt>
            <span id="nationality">
              <Translate contentKey="leagueApp.player.nationality">Nationality</Translate>
            </span>
          </dt>
          <dd>{playerEntity.nationality}</dd>
          <dt>
            <span id="clubId">
              <Translate contentKey="leagueApp.player.clubId">Club Id</Translate>
            </span>
            <UncontrolledTooltip target="clubId">
              <Translate contentKey="leagueApp.player.help.clubId" />
            </UncontrolledTooltip>
          </dt>
          <dd>{playerEntity.clubId}</dd>
          <dt>
            <span id="uniformNumber">
              <Translate contentKey="leagueApp.player.uniformNumber">Uniform Number</Translate>
            </span>
          </dt>
          <dd>{playerEntity.uniformNumber}</dd>
          <dt>
            <span id="birthday">
              <Translate contentKey="leagueApp.player.birthday">Birthday</Translate>
            </span>
            <UncontrolledTooltip target="birthday">
              <Translate contentKey="leagueApp.player.help.birthday" />
            </UncontrolledTooltip>
          </dt>
          <dd>{playerEntity.birthday}</dd>
          <dt>
            <span id="height">
              <Translate contentKey="leagueApp.player.height">Height</Translate>
            </span>
          </dt>
          <dd>{playerEntity.height}</dd>
          <dt>
            <span id="weight">
              <Translate contentKey="leagueApp.player.weight">Weight</Translate>
            </span>
          </dt>
          <dd>{playerEntity.weight}</dd>
        </dl>
        <Button tag={Link} to="/player" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/player/${playerEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </div>
    </div>
  );
};

export default PlayerDetail;
