export const LIST_CATE = {
  SUPPORT_KICK: 'SUPPORT_KICK',
  SCORE_FORM_KICK: 'SCORE_FORM_KICK',
  SHOOT_DIRECTION_KICK: 'SHOOT_DIRECTION_KICK',
  TYPE_SHOOT_KICK: 'TYPE_SHOOT_KICK',
  TYPE_FAIL_KICK: 'TYPE_FAIL_KICK',
  SHOOTING: 'SHOOTING',
  SCORE_RESULT: 'SCORE_RESULT',
  SCORE_METHOD: 'SCORE_METHOD',
};

export const convertResponseCate = (data = []) => data.map(item => ({ value: +item.code, label: item.name }));

export const KICK_TYPE_INFO = 'KICK_TYPE_INFO';
export const SCORE_INFO = 'SCORE_INFO';

export const getLanguage = () => {
  const language = localStorage.getItem('locale') || sessionStorage.getItem('locale') || '"en"';
  return JSON.parse(language);
};
