import React, { useMemo, useState } from 'react';
import { Redirect, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Button, Card, Form, Segmented } from 'antd';

import { getEntity, MatchSlice } from '../match/match.reducer';
import LineUp from 'app/modules/line-up';
import { IPlayerInMatch } from 'app/shared/model/player.model';
import {
  actionRecord,
  editBeforeMatchAction,
  MatchStatus,
  MatchType,
  MATCH_RECORD_VIEW,
  PositionInMatch,
  SHOOT_RESULT,
  SHOOT_TYPE,
} from 'app/shared/util/constant';
import {
  getPlayerAvatar,
  getPlayerFieldSquad,
  MatchRecordSlice,
  saveMatch,
  getDetailGoalKickRecord,
  getDetailOffsideRecord,
  getTimeLine,
  getTime,
  ISheetRecord,
  fetchSheetRecord,
} from './match-record.reducer';
import { createSelector } from '@reduxjs/toolkit';
import { debounce, get, isNull } from 'lodash';
import { toast } from 'react-toastify';
import PickPosition from 'app/modules/pick-position';
import PickPlayer from 'app/modules/pick-player';
import {
  sendRecord,
  sendWSActivity,
  subscribePlayerBan,
  subscribeTimeMatch,
  subscribeTimeMatchFinish,
  subscribeTopic,
  unsubscribe,
} from 'app/config/websocket-middleware';
import MenuRecord from './components/menu';
import PlayerBanRecord from '../player-ban-record/player-ban-record';
import PlayerFreeCornerKickRecord from '../player-free-corner-kick-record';
import PlayerFoulRecord from '../player-foul-record/player-foul-record';

import MatchTime from '../match-time/match-time';
import { MatchTimeSlice, recordMatchTime } from '../match-time/match-time.reducer';
import PlayerPenaltyKick from '../player-penalty-kick';
import PlayerShootingKick from '../shooting-kick';
import ScoreRecord from '../score-record';
import { IMatchTime } from 'app/shared/model/match-time.model';
import PenaltyShootOut from '../penalty-shoot-out';
import { IMatch } from 'app/shared/model/match.model';
import ChangePlayer from './components/change/change-player';
import MatchRate from './components/change/match-rate';
import EventListRecord from 'app/modules/event-list-record';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';
import { urlWs } from 'app/modules/event-list-record/constant.event-list';
import { IPlayerBanRecord } from 'app/shared/model/player-ban-record.model';
import './index.scss';
import { Swap } from 'app/shared/util/constant/swap.constant';
import MatchOver from '../match-over';
import PlayerCorner from '../player-corner-kick-record/player-corner-kick-record';
import PlayerAssist from '../assist-goal-record/assist-goal-record';
import { APPROVAL_TYPE, AUTHORITIES } from 'app/config/constants';
import { MatchOverSlice } from '../match-over/match-over.reducer';
import GoalSituation from '../goal-situation';
import SheetRecord from './components/sheet-record';
import HeaderMatch from './components/header-match';

// --- In this mode, open choose player ---
const listChooseModeAndPickPlayer = [
  actionRecord.FREE_KICK.toString(),
  actionRecord.SHOOTING,
  actionRecord.SCORE,
  actionRecord.PENALTY_KICK,
  actionRecord.GOAL_KICK,
  actionRecord.ASSIST,
  actionRecord.FREE_KICK,
  actionRecord.OFFSIDE,
  actionRecord.FOUL,
  actionRecord.WARNING,
  actionRecord.CORNER_KICK,
];

const GKPosition = 1;
const MaxGKInLineUp = 1;
const mainTeam = 1;
const subTeam = 0;

export const MatchRecord = ({ match }) => {
  const dispatch = useAppDispatch();
  const { actions } = MatchRecordSlice;
  const { actions: matchTimeActions } = MatchTimeSlice;
  const { actions: matchActions } = MatchSlice;
  const [isReject, setIsReject] = useState(false);
  const { actions: MatchOverSliceActions } = MatchOverSlice;
  const matchInfo = useAppSelector(state => state.match.entity);

  const [form] = Form.useForm();

  const [notSaveYet, setNotSaveYet] = useState({
    notSave: false,
    alert: false,
  });

  // modalTypeEventList is state of event list
  const [modalTypeEventList, setModalTypeEventList] = useState('');

  const [view, setView] = useState<MATCH_RECORD_VIEW>(MATCH_RECORD_VIEW.FIELD);

  // mode is state of add event and update event
  const [mode, setMode] = useState<{
    action: string;
    id?: number;
    create?: boolean;
    player?: IPlayerInMatch;
  }>({ action: '' });

  const [submit, setSubmit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [visiblePickPlayerModal, setVisiblePickPlayerModal] = React.useState(false);

  const [timeCreate, setTimeCreate] = useState<{
    process: number;
    roundType: number;
  }>();

  const [detailValue, setDetailValue] = useState<IPlayerBanRecord>();
  const history = useHistory();
  const param = useParams<{ id: string }>();
  const playerInForm = Form.useWatch('player', form);

  const { ownerLineUp, awayLineUp, subOwnerLineUp, subAwayLineUp, awayAvatars, ownerAvatars } = useAppSelector(state => state.matchRecord);
  const connectSuccess = useAppSelector(state => state.matchRecord?.connectSuccess);
  const matchBasicInfoEntity = useAppSelector(state => state.match.entity);
  const matchTimes: IMatchTime = useAppSelector(state => state.matchTime?.entity);
  const account = useAppSelector(state => state.authentication.account);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const isAdmin = useMemo(() => {
    return account?.authorities.some(item => item === AUTHORITIES.ADMIN);
  }, [account]);

  const isRecorder = useMemo(() => {
    return account?.authorities.some(item => item === AUTHORITIES.RECORDER);
  }, [account]);

  const isLeagueMaster = useMemo(() => {
    return account?.authorities.some(item => item === AUTHORITIES.LEAGUE_MASTER);
  }, [account]);

  const permissionRecord = useMemo(() => {
    if (matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED) return false;
    if (isAdmin) return true;
    if (isLeagueMaster && !isAdmin && matchBasicInfoEntity.approval !== APPROVAL_TYPE.WAIT_ACCEPT) return false;
    if (isRecorder && !isAdmin && matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT) return false;
    return true;
  }, [matchBasicInfoEntity.approval, isAdmin, isLeagueMaster, isRecorder, matchBasicInfoEntity?.status]);

  const isHaftTime = React.useMemo(() => {
    if (!matchTimes) return true;
    const condition1 = matchTimes.endTime1 && !matchTimes.startTime2;
    const condition2 = matchTimes.endTime2 && !matchTimes.startSubTime1;

    const condition3 = matchTimes.endSubTime1 && !matchTimes.startSubTime2;

    const condition4 = !!matchTimes.endSubTime2 || !matchTimes.startTime1;

    return Boolean(condition1) || Boolean(condition2) || Boolean(condition3) || Boolean(condition4);
  }, [matchTimes]);

  const canStart = React.useMemo(() => {
    return (
      ownerLineUp?.filter(item => item.positionType === GKPosition).length === GKPosition &&
      awayLineUp?.filter(item => item.positionType === GKPosition).length === GKPosition
    );
  }, [ownerLineUp, awayLineUp]);

  const playerAvatar = get(playerInForm?.matchPlayerFieldDTO?.playerDTOS?.avatar, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
    ? get(playerInForm?.matchPlayerFieldDTO?.playerDTOS?.avatar, playerInForm?.matchPlayerFieldDTO?.playerDTOS?.id)
    : undefined;

  React.useEffect(() => {
    const section = document.querySelector('#main');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  React.useEffect(() => {
    if (!match.params.id) {
      return;
    }
    dispatch(getEntity(match.params.id));
    dispatch(getTimeLine(match.params.id));
  }, [match.params.id]);

  React.useEffect(() => {
    if (!matchBasicInfoEntity || !matchBasicInfoEntity.id || Number(match.params.id) !== matchBasicInfoEntity.id) return;
    dispatch(recordMatchTime({ matchId: match.params.id, type: MatchType.INFO }));
    dispatch(
      getPlayerFieldSquad({
        matchId: matchBasicInfoEntity.id,
        awayClubId: matchBasicInfoEntity.awayClubId,
        ownerClubId: matchBasicInfoEntity.ownerClubId,
      })
    );
    dispatch(
      getPlayerAvatar({
        matchId: matchBasicInfoEntity.id,
        awayClubId: matchBasicInfoEntity.awayClubId,
        ownerClubId: matchBasicInfoEntity.ownerClubId,
      })
    );
    dispatch(
      fetchSheetRecord({
        matchId: matchBasicInfoEntity.id,
        awayClubId: matchBasicInfoEntity.awayClubId,
        ownerClubId: matchBasicInfoEntity.ownerClubId,
      })
    );
  }, [matchBasicInfoEntity?.id]);

  React.useEffect(() => {
    const subscribe = () => {
      subscribePlayerBan(match.params.id, (listPlayer: string) => dispatch(actions.updateRecord(JSON.parse(listPlayer))));
      subscribeTimeMatch(match.params.id, (matchTime: IMatchTime) => dispatch(matchTimeActions.setMatchTime(matchTime)));
      subscribeTimeMatchFinish(match.params.id, (matchTime: IMatch) => dispatch(matchActions.setMatchInfo(matchTime)));
      subscribeTopic(`/topic/time-line/match/${match.params.id}`, (timeLine: string) => dispatch(actions.updateTimeLine(timeLine)));
      subscribeTopic(`/topic/message/${match.params.id}`, (response: { type: string; message: string }) =>
        dispatch(actions.notification(response))
      );
      subscribeTopic(`/topic/after-match/${match.params.id}`, (timeLine: string) =>
        dispatch(MatchOverSliceActions.subcribeRecordTable(timeLine))
      );
      subscribeTopic(`/topic/sheet-record/${match.params.id}`, (response: unknown) =>
        dispatch(actions.updateSheetRecord(response as ISheetRecord[]))
      );
    };
    connectSuccess && subscribe();
  }, [match.params.id, connectSuccess]);

  const onClick = (key: string) => {
    if (key === actionRecord.RATIO || key === editBeforeMatchAction.GOAL_SITUATION) {
      setMode({ action: key });
      return;
    }
    if (!permissionRecord) {
      ![editBeforeMatchAction.REFRESH, actionRecord.PENALTY_SHOOT_OUT].includes(key as editBeforeMatchAction) && setModalTypeEventList(key);
      actionRecord.PENALTY_SHOOT_OUT === key && setMode({ action: actionRecord.PENALTY_SHOOT_OUT });
      return;
    }
    if (notSaveYet.notSave) {
      setNotSaveYet({ alert: true, notSave: true });
      return;
    }
    if (
      [actionRecord.CHANGE_OWNER, actionRecord.CHANGE_AWAY].includes(key as actionRecord) &&
      matchBasicInfoEntity.status === MatchStatus.FINISH
    ) {
      return;
    }
    if (matchBasicInfoEntity.status === MatchStatus.FINISH && key === actionRecord.PENALTY_SHOOT_OUT) {
      setMode({ action: actionRecord.PENALTY_SHOOT_OUT });
      return;
    }
    if (
      isHaftTime &&
      ![
        actionRecord.CHANGE_OWNER,
        actionRecord.CHANGE_AWAY,
        actionRecord.PENALTY_SHOOT_OUT,
        actionRecord.EDIT_BASIC_INFO,
        actionRecord.EDIT_FIELD_AWAY_INFO,
        actionRecord.EDIT_FIELD_OWNER_INFO,
        actionRecord.EDIT_FORMATION,
        actionRecord.EDIT_SUBSTITUTE_AWAY_PLAYER,
        actionRecord.EDIT_SUBSTITUTE_OWNER_PLAYER,
        editBeforeMatchAction.REFRESH,
      ].includes(key as actionRecord)
    ) {
      setModalTypeEventList(key);
      return;
    }
    dispatch(getTime(param?.id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        const newData: any = res.payload;
        setTimeCreate({
          process: newData?.data?.processDate,
          roundType: newData?.data?.roundType,
        });
      }
    });

    setMode({ action: key });

    if (listChooseModeAndPickPlayer.includes(key)) {
      onClickModeAndPickPlayer(key);
      return;
    }

    // if (key === editBeforeMatchAction.ARROWLEFT) {
    // }
  };

  const positionInForm = Form.useWatch('detailLocation', form);

  const handleSubmitLineUp = React.useCallback((owner: IPlayerInMatch[], away: IPlayerInMatch[]) => {
    if (
      owner.filter(item => item.positionType === GKPosition).length !== MaxGKInLineUp ||
      away.filter(item => item.positionType === GKPosition).length !== MaxGKInLineUp
    ) {
      toast.error(translate('leagueApp.matchRecord.errorGKMessage'));
      setSubmit(false);
      return;
    }
    // to remove base 64 avatar
    const data = owner.concat(away).map(item => ({
      ...item,
      matchPlayerFieldDTO: {
        ...item.matchPlayerFieldDTO,
        playerDTOS: { ...item.matchPlayerFieldDTO.playerDTOS, avatar: null },
      },
    }));
    dispatch(saveMatch(data))
      .then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(actions.updateRecord(owner));
          dispatch(actions.updateRecord(away));
          toast.success(translate('common.saveSuccess'));
        }
      })
      .catch(err => toast.error(err?.response?.data?.messages && err?.response?.data?.messages[0]));
    setNotSaveYet({ notSave: false, alert: false });
    setMode({ action: '' });
    setSubmit(false);
  }, []);

  const handleChangeLineUp = React.useCallback(
    (owner: IPlayerInMatch[], away: IPlayerInMatch[], clubId: number) => {
      if (mode.action === editBeforeMatchAction.FORMATION_BEFORE_MATCH && !notSaveYet.notSave) {
        setNotSaveYet({ notSave: true, alert: false });
      }
      if (!matchTimes.startTime1 || matchBasicInfoEntity.status === MatchStatus.FINISH) {
        return;
      }
      // to remove base 64 avatar
      const data = owner.concat(away).map(item => ({
        ...item,
        matchPlayerFieldDTO: {
          ...item.matchPlayerFieldDTO,
          playerDTOS: { ...item.matchPlayerFieldDTO.playerDTOS, avatar: null },
        },
      }));
      sendWSActivity(`/record/match-player-field-squads/save-in-record/${match.params.id}/${clubId}`, data);
    },
    [match.params, matchTimes, mode, notSaveYet]
  );

  const onClickModeAndPickPlayer = key => {
    setMode({ action: key });
    setVisiblePickPlayerModal(true);
  };

  const handleClickPlayer = React.useCallback(
    player => {
      form.setFieldsValue({ player });
      setVisiblePickPlayerModal(false);

      const isOwnerPlayer = matchBasicInfoEntity.ownerClubId === player.matchPlayerFieldDTO.clubId;
      const direction = matchBasicInfoEntity.direction === Swap.LEFT;

      if (mode.action === actionRecord.PENALTY_KICK || modalTypeEventList === actionRecord.PENALTY_KICK) {
        form.setFieldsValue({
          detailLocation:
            (isOwnerPlayer && direction) || (!isOwnerPlayer && !direction)
              ? PositionInMatch.CENTER_PA_AWAY
              : PositionInMatch.CENTER_PA_OWNER,
        });

        setMode(pre => ({ action: actionRecord.PENALTY_KICK, create: !pre.id, id: pre.id }));
        return;
      }

      if (mode.create || mode.id) {
        return;
      }
      if (mode.action === actionRecord.GOAL_KICK || modalTypeEventList === actionRecord.GOAL_KICK) {
        form.submit();
        return;
      }

      setOpen(true);
    },
    [positionInForm, mode, matchBasicInfoEntity]
  );

  const handleClickEventSheetRecord = React.useCallback(
    (action: actionRecord, player: IPlayerInMatch) => {
      const isOwnerPlayer = matchBasicInfoEntity.ownerClubId === player.matchPlayerFieldDTO.clubId;
      const direction = matchBasicInfoEntity.direction === Swap.LEFT;
      console.log(33, action, player);

      dispatch(getTime(param?.id)).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          const newData: any = res.payload;
          setTimeCreate({
            process: newData?.data?.processDate,
            roundType: newData?.data?.roundType,
          });
        }
      });
      if (
        !permissionRecord ||
        !matchTimes.startTime1 ||
        player.banCard > 1 ||
        (isHaftTime && ![actionRecord.CHANGE_OWNER, actionRecord.CHANGE_AWAY, actionRecord.PENALTY_SHOOT_OUT].includes(action))
      ) {
        return;
      }

      if (action === actionRecord.FOUL_SUFFERED_AWAY || action === actionRecord.FOUL_SUFFERED_OWNER) {
        form.setFieldsValue({ playerFoulId: player.playerId, playerFSClubId: player.matchPlayerFieldDTO.clubId });
        setVisiblePickPlayerModal(true);
        setMode({ action: action === actionRecord.FOUL_SUFFERED_AWAY ? actionRecord.FOUL_AWAY : actionRecord.FOUL_OWNER });
        return;
      }
      form.setFieldsValue({ player });
      if ([actionRecord.CHANGE_AWAY, actionRecord.CHANGE_OWNER].includes(action)) {
        setMode({ action });
        return;
      }
      if (action === actionRecord.PENALTY_KICK) {
        form.setFieldsValue({
          detailLocation:
            (isOwnerPlayer && direction) || (!isOwnerPlayer && !direction)
              ? PositionInMatch.CENTER_PA_AWAY
              : PositionInMatch.CENTER_PA_OWNER,
        });
        setMode({ action: actionRecord.PENALTY_KICK, create: true });
        return;
      }
      setOpen(true);

      if (action === actionRecord.SCORE) {
        form.setFieldsValue({ shootType: SHOOT_TYPE.ON_TARGET, goalType: SHOOT_RESULT.SUCCESS });
        setMode({ action: actionRecord.SHOOTING });
        return;
      }
      setMode({ action });
    },
    [matchBasicInfoEntity, param?.id, permissionRecord, matchTimes.startTime1, isHaftTime]
  );

  const handleClickPosition = React.useCallback(() => {
    if (
      [actionRecord.OFFSIDE, actionRecord.GOAL_KICK].includes(mode.action as actionRecord) ||
      [actionRecord.OFFSIDE].includes(modalTypeEventList as actionRecord) ||
      (actionRecord.CORNER_KICK === mode.action && !modalTypeEventList)
    ) {
      form.submit();
      return;
    }
    if (!modalTypeEventList && !mode.create) {
      setMode(pre => ({ ...pre, create: true }));
      return;
    }
    // setMode(pre => ({ action: pre.action }));
  }, [mode, modalTypeEventList]);

  const closeModalPickOption = () => {
    setMode({ action: '' });
    form.resetFields();
  };

  const onFinish = data => {
    // if (modalTypeEventList === actionRecord.GOAL_KICK) {
    //   sendWSActivity(`/record/player-goal-kick/edit/${match.params.id}/${mode.player.id}`, {
    //     ...mode.player,
    //     ...data,
    //     processDate: timeCreate.process,
    //     roundType: timeCreate.roundType,
    //   });
    //   setModalTypeEventList('');
    //   form.resetFields();
    //   toast.success(translate('common.saveSuccess'));
    //   return;
    // }
    if (modalTypeEventList === actionRecord.OFFSIDE) {
      sendWSActivity(urlWs(actionRecord.OFFSIDE, 'edit', match.params.id, mode.id), {
        ...detailValue,
        ...data,
        playerDTO: { ...detailValue.playerDTO, base64Avt: null },
        player: undefined,
        playerId: data.player.matchPlayerFieldDTO.playerDTOS?.id,
        id: mode.id,
        processDate: timeCreate.process,
        roundType: timeCreate.roundType,
      });
      setMode(pre => ({ ...pre, id: undefined }));
      toast.success(translate('common.saveSuccess'));
      form.resetFields();
      return;
    }
    if (mode.action === actionRecord.OFFSIDE) {
      sendRecord(`/record/player-offsides/create/${data.player?.matchPlayerFieldDTO.matchId}/${data.player?.matchPlayerFieldDTO.clubId}`, {
        ...data,
        playerId: data.player.matchPlayerFieldDTO.playerDTOS?.id,
        matchId: data.player.matchPlayerFieldDTO.matchId,
        player: undefined,
        processDate: timeCreate.process,
        roundType: timeCreate.roundType,
      });
    }
    if (mode.action === actionRecord.GOAL_KICK) {
      sendRecord(
        `/record/player-goal-kick/create/${data.player?.matchPlayerFieldDTO.matchId}/${data.player?.matchPlayerFieldDTO.clubId}/${data.player?.matchPlayerFieldDTO.type}`,
        {
          ...data,
          playerId: data.player.matchPlayerFieldDTO.playerDTOS?.id,
          matchId: data.player.matchPlayerFieldDTO.matchId,
          player: undefined,
          processDate: timeCreate.process,
          roundType: timeCreate.roundType,
        }
      );
    }
    if (mode.action === actionRecord.CORNER_KICK) {
      sendRecord(`/record/player-conner/create/${data.player?.matchPlayerFieldDTO.matchId}`, {
        ...data,
        playerId: data.player.matchPlayerFieldDTO.playerDTOS?.id,
        matchId: data.player.matchPlayerFieldDTO.matchId,
        player: undefined,
        processDate: timeCreate.process,
        roundType: timeCreate.roundType,
      });
    }
    setMode({ action: '' });
    toast.success(translate('common.saveSuccess'));
    form.resetFields();
  };

  const onUpdateRecord = (id, playerSelected) => {
    const allPlayer = [...ownerLineUp, ...awayLineUp, ...subOwnerLineUp, ...subAwayLineUp];
    const player = allPlayer.find(p => p.playerId === playerSelected.playerId);
    // const allAvatar = { ...awayAvatars, ...ownerAvatars };
    // const avatar = get(allAvatar, player.playerId) ? get(allAvatar, player.matchPlayerFieldDTO.playerDTOS?.id) : undefined;
    form.setFieldsValue({
      player,
    });

    if (modalTypeEventList === actionRecord.GOAL_KICK) {
      setOpen(true);
      dispatch(getDetailGoalKickRecord(id)).then(result => {
        if (result.meta.requestStatus === 'fulfilled') {
          form.setFieldsValue(get(result.payload, 'data'));
          setMode(prev => ({
            action: modalTypeEventList,
            id,
            player: get(result.payload, 'data'),
          }));
        }
      });
      return;
    }
    if (modalTypeEventList === actionRecord.OFFSIDE) {
      setOpen(true);
      dispatch(getDetailOffsideRecord(id)).then(result => {
        if (result.meta.requestStatus === 'fulfilled') {
          setDetailValue(get(result.payload, 'data'));
          form.setFieldsValue(get(result.payload, 'data'));
        }
      });
    }
    setMode({ action: modalTypeEventList, id });
  };

  const handSubmitChangePlayer = data => {
    sendWSActivity(`/record/player-inout/create/${data[0]?.matchId}/${data[0]?.clubId}`, data);
    setMode({ action: '' });
    toast.success(translate('leagueApp.changePlayerMatch.changeSuccess'));
  };

  const requestApprovalButton = useMemo(() => {
    if (!matchBasicInfoEntity.approval) {
      return null;
    }
    if (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_REQUEST && (isRecorder || isAdmin)) {
      return (
        <Button
          style={{ marginBottom: 32 }}
          onClick={() => {
            sendWSActivity(`/record/match-time/request-approval-match/${matchBasicInfoEntity.id}`, {});
          }}
        >
          {translate('leagueApp.matchStatus.requestApproval')}
        </Button>
      );
    }
    if (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && isRecorder) {
      return (
        <Button
          style={{ marginBottom: 32 }}
          onClick={() => {
            sendWSActivity(`/record/match-time/cancel-approval-request/${matchBasicInfoEntity.id}`, {});
          }}
        >
          {translate('leagueApp.matchStatus.cancelApproval')}
        </Button>
      );
    }
    if (matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_ACCEPT && (isLeagueMaster || isAdmin)) {
      return (
        <div className="column-action-button">
          <Button
            style={{ marginBottom: 32, marginRight: '7.5px' }}
            className="rounded"
            type="primary"
            block
            onClick={() => {
              sendWSActivity(`/record/match-time/approve-match/${matchBasicInfoEntity.id}`, {});
              toast.success(translate('common.saveSuccess'));
            }}
          >
            {translate('entity.action.accept')}
          </Button>
          <Button
            style={{ marginBottom: 32, marginLeft: '7.5px' }}
            className="rounded"
            type="primary"
            danger
            block
            onClick={() => {
              sendWSActivity(`/record/match-time/cancel-approval-request/${matchBasicInfoEntity.id}`, {});
              toast.success(translate('common.saveSuccess'));
              setIsReject(true);
            }}
          >
            {translate('entity.action.reject')}
          </Button>
        </div>
      );
    }
    if (matchBasicInfoEntity.approval === APPROVAL_TYPE.ACCEPTED && (isLeagueMaster || isAdmin)) {
      return (
        <Button
          style={{ marginBottom: 32 }}
          onClick={() => {
            sendWSActivity(`/record/match-time/finish-match/${matchInfo.id}`, {});
          }}
        >
          {translate('leagueApp.matchStatus.undo')}
        </Button>
      );
    }
    return null;
  }, [matchBasicInfoEntity.approval, isRecorder, matchBasicInfoEntity.id, locale]);

  if (isLeagueMaster && !isAdmin && matchBasicInfoEntity.approval === APPROVAL_TYPE.WAIT_REQUEST) {
    if (isReject) {
      return <Redirect to="/match" />;
    }
    return (
      <div className="insufficient-authority">
        <div className="alert alert-danger">
          <Translate contentKey="error.http.403">You are not authorized to access this page.</Translate>
        </div>
      </div>
    );
  }

  return (
    <Form form={form} onFinish={onFinish} name="control-hooks" initialValues={{ remember: true }}>
      <HeaderMatch matchInfo={matchBasicInfoEntity} />
      <Card style={{ overflow: 'auto' }} className="realtime-lineup">
        <div
          className={
            matchBasicInfoEntity.status === MatchStatus.FINISH &&
            (history.location.pathname?.includes('score-and-mom') || history.location.pathname?.includes('general-info'))
              ? ''
              : 'main-realtime-lineup'
          }
        >
          {mode.action === editBeforeMatchAction.FORMATION_BEFORE_MATCH && (
            <div className="justify-content-end d-flex main-realtime-lineup-button">
              <Button type="primary" onClick={() => setSubmit(true)}>
                <Translate contentKey="common.save" />
              </Button>
            </div>
          )}

          {matchBasicInfoEntity.status === MatchStatus.FINISH ? (
            <MatchOver match={match} />
          ) : (
            <div>
              <div className="w-100 d-flex justify-content-end mb-3">
                <Segmented
                  value={view}
                  options={Object.values(MATCH_RECORD_VIEW).map(item => ({ value: item, label: translate(`matchRecordView.${item}`) }))}
                  onChange={value => setView(value as MATCH_RECORD_VIEW)}
                  className="ml-auto"
                />
              </div>

              {view === MATCH_RECORD_VIEW.FIELD ? (
                <LineUp
                  ownerLineUp={ownerLineUp}
                  canChange={!!matchTimes.startTime1 || mode.action === editBeforeMatchAction.FORMATION_BEFORE_MATCH}
                  awayLineUp={awayLineUp}
                  onSubmit={handleSubmitLineUp}
                  submit={submit}
                  event={mode}
                  swap={matchBasicInfoEntity.direction === Swap.RIGHT}
                  onChange={handleChangeLineUp}
                  ownerSubPlayers={subOwnerLineUp}
                  awaySubPlayers={subAwayLineUp}
                  awayAvatars={awayAvatars}
                  ownerAvatars={ownerAvatars}
                  haveSubPlayers={true}
                  timeOfRound={matchBasicInfoEntity?.timeOfRound}
                  timeOfRoundExtra={matchBasicInfoEntity?.timeOfRoundExtra}
                  draggable={false}
                />
              ) : (
                <SheetRecord onClickEvent={(action, player) => handleClickEventSheetRecord(action, player)} />
              )}
            </div>
          )}
        </div>
        {!(history.location.pathname?.includes('score-and-mom') || history.location.pathname?.includes('general-info')) && (
          <div
            className={matchBasicInfoEntity.status === MatchStatus.FINISH ? 'common-realtime-lineup-endmatch' : 'common-realtime-lineup'}
            style={{
              marginTop: matchBasicInfoEntity?.approval === APPROVAL_TYPE.ACCEPTED ? 44 : 0,
            }}
          >
            {requestApprovalButton}
            <MenuRecord
              onChange={onClick}
              setModal={(key: string, e) => {
                if (key !== actionRecord.PENALTY_SHOOT_OUT) setModalTypeEventList(key);
                else {
                  setMode({ action: actionRecord.PENALTY_SHOOT_OUT });
                }
                e.stopPropagation();
              }}
              id={match.params.id}
              permissionRecord={permissionRecord}
              modalTypeEventList={modalTypeEventList}
              mode={mode}
              isHaftTime={isHaftTime}
              isEndMatch={!!(matchBasicInfoEntity.status === MatchStatus.FINISH)}
            />
            <MatchTime
              canStart={canStart}
              canChange={permissionRecord}
              isEndMatch={!!(matchBasicInfoEntity.status === MatchStatus.FINISH)}
            />
          </div>
        )}

        {mode.action === actionRecord.FREE_KICK && (mode.create || !!mode.id) && (
          <PlayerFreeCornerKickRecord
            open={true}
            onCancel={() => {
              closeModalPickOption();
            }}
            onEditPosition={() => setOpen(true)}
            onEditPlayer={() => setVisiblePickPlayerModal(true)}
            awayAvatars={awayAvatars}
            ownerAvatars={ownerAvatars}
            form={form}
            id={mode.id}
            processTime={timeCreate}
          />
        )}
        {mode.action === actionRecord.PENALTY_KICK && (mode.create || !!mode.id) && (
          <PlayerPenaltyKick
            open={true}
            onCancel={() => {
              closeModalPickOption();
            }}
            onEditPosition={() => setOpen(true)}
            onEditPlayer={() => setVisiblePickPlayerModal(true)}
            awayAvatars={awayAvatars}
            ownerAvatars={ownerAvatars}
            form={form}
            id={mode.id}
            processTime={timeCreate}
          />
        )}
        {mode.action === actionRecord.SHOOTING && (mode.create || !!mode.id) && (
          <PlayerShootingKick
            open={true}
            onCancel={() => {
              closeModalPickOption();
            }}
            awayAvatars={awayAvatars}
            ownerAvatars={ownerAvatars}
            onEditPosition={() => setOpen(true)}
            onEditPlayer={() => setVisiblePickPlayerModal(true)}
            form={form}
            playerAvatar={playerAvatar}
            id={mode.id}
            processTime={timeCreate}
          />
        )}
        {mode.action === actionRecord.SCORE && (mode.create || !!mode.id) && (
          <ScoreRecord
            open={true}
            onCancel={() => {
              closeModalPickOption();
            }}
            awayAvatars={awayAvatars}
            ownerAvatars={ownerAvatars}
            onEditPosition={() => setOpen(true)}
            onEditPlayer={() => setVisiblePickPlayerModal(true)}
            form={form}
            id={mode.id}
            processTime={timeCreate}
          />
        )}
        {mode.action === actionRecord.WARNING && (mode.create || !!mode.id) && (
          <PlayerBanRecord
            open={true}
            onCancel={() => {
              closeModalPickOption();
            }}
            id={mode.id}
            awayAvatars={awayAvatars}
            ownerAvatars={ownerAvatars}
            onEditPlayer={() => setVisiblePickPlayerModal(true)}
            onEditPosition={() => setOpen(true)}
            processTime={timeCreate}
          />
        )}
        {[actionRecord.FOUL, actionRecord.FOUL_AWAY, actionRecord.FOUL_OWNER].includes(mode.action as any) && (mode.create || !!mode.id) && (
          <PlayerFoulRecord
            open={true}
            onCancel={() => {
              closeModalPickOption();
            }}
            id={mode.id}
            awayAvatars={awayAvatars}
            ownerAvatars={ownerAvatars}
            onEditPlayer={() => setVisiblePickPlayerModal(true)}
            onEditPosition={() => setOpen(true)}
            processTime={timeCreate}
          />
        )}
        {mode.action === actionRecord.ASSIST && (mode.create || !!mode.id) && (
          <PlayerAssist
            open={true}
            onCancel={() => {
              closeModalPickOption();
            }}
            id={mode.id}
            awayAvatars={awayAvatars}
            ownerAvatars={ownerAvatars}
            onEditPlayer={() => setVisiblePickPlayerModal(true)}
            onEditPosition={() => setOpen(true)}
            processTime={timeCreate}
          />
        )}
        {mode.action === actionRecord.CORNER_KICK && (mode.create || !!mode.id) && (
          <PlayerCorner
            open={true}
            onCancel={() => closeModalPickOption()}
            id={mode.id}
            onEditPlayer={() => setVisiblePickPlayerModal(true)}
            onEditPosition={() => setOpen(true)}
            awayAvatars={awayAvatars}
            ownerAvatars={ownerAvatars}
          />
        )}
        {mode.action === actionRecord.PENALTY_SHOOT_OUT && (
          <PenaltyShootOut
            open={true}
            onCancel={() => {
              setMode({ action: '' });
            }}
            processTime={timeCreate}
            canChange={permissionRecord}
          />
        )}

        {mode.action === actionRecord.CHANGE_OWNER && (
          <ChangePlayer
            isOpen={true}
            setIsOpen={() => setMode({ action: '' })}
            ownerClubId={matchBasicInfoEntity?.ownerClubId}
            typeEdit={'change_player'}
            isOwner={true}
            id={match.params.id}
            customLabel={true}
            onSubmit={handSubmitChangePlayer}
            processTime={timeCreate}
            defaultPlayerId={playerInForm?.playerId}
          />
        )}
        {mode.action === actionRecord.CHANGE_AWAY && (
          <ChangePlayer
            isOpen={true}
            setIsOpen={() => setMode({ action: '' })}
            awayClubId={matchBasicInfoEntity?.awayClubId}
            typeEdit={'change_player'}
            isOwner={false}
            id={match.params.id}
            customLabel={true}
            onSubmit={handSubmitChangePlayer}
            processTime={timeCreate}
            defaultPlayerId={playerInForm?.playerId}
          />
        )}
        {mode.action === editBeforeMatchAction.RATIO && (
          <MatchRate
            isOpen={true}
            setIsOpen={() => setMode({ action: '' })}
            dataMatchInfo={matchBasicInfoEntity}
            id={match.params.id}
            awayClubId={matchBasicInfoEntity?.awayClubId}
            ownerClubId={matchBasicInfoEntity?.ownerClubId}
          />
        )}
        {mode.action === editBeforeMatchAction.GOAL_SITUATION && (
          <GoalSituation onCancel={() => setMode({ action: '' })} matchId={match.params.id} canEdit={permissionRecord} />
        )}

        {Boolean(modalTypeEventList) && (
          <EventListRecord
            onCancel={() =>
              setModalTypeEventList(pre => {
                unsubscribe();
                return '';
              })
            }
            canEdit={permissionRecord}
            open={Boolean(modalTypeEventList)}
            modalEventList={modalTypeEventList}
            onUpdate={onUpdateRecord}
            isEndMatch={!permissionRecord}
          />
        )}

        {/* <PlayerShootingKickRecord /> */}

        <Form.Item name="player" className={visiblePickPlayerModal ? '' : 'd-none'}>
          <PickPlayer
            open={visiblePickPlayerModal}
            setOpen={() => {
              if (!mode.id && !mode.create) {
                setMode({ action: '' });
              }
              setVisiblePickPlayerModal(false);
            }}
            onClick={handleClickPlayer}
            ownerPlayers={ownerLineUp}
            awayPlayers={awayLineUp}
            awayAvatars={awayAvatars}
            ownerAvatars={ownerAvatars}
            swap={matchBasicInfoEntity.direction === Swap.RIGHT}
            mode={mode.action}
          />
        </Form.Item>
        <Form.Item name="detailLocation" className={open ? '' : 'd-none'}>
          <PickPosition
            open={open}
            setOpen={() => {
              if (!mode.id && !mode.create) {
                setMode({ action: '' });
              }
              setOpen(false);
            }}
            onSubmit={detailLocation => {
              form.setFieldsValue({ detailLocation });
              handleClickPosition();
              setOpen(false);
            }}
            type={mode.action || modalTypeEventList}
            value={form.getFieldValue('detailLocation')}
            corner={mode.action === actionRecord.CORNER_KICK || modalTypeEventList === actionRecord.CORNER_KICK}
          />
        </Form.Item>
      </Card>
      <ConfirmationDialog
        open={notSaveYet.alert}
        title={translate('leagueApp.matchStatus.titleAlertForgetSave')}
        message={<p>{translate('leagueApp.matchStatus.saveQuestion')}</p>}
        onConfirm={() => {
          setSubmit(true);
        }}
        onClose={() => {
          setNotSaveYet({ notSave: false, alert: false });
          dispatch(actions.updateRecord(awayLineUp));
          dispatch(actions.updateRecord(ownerLineUp));
        }}
      />
    </Form>
  );
};

// tinh nang dang tat

// {mode.action === actionRecord.EDIT_BASIC_INFO && (
//   <ChangeInformation id={match.params.id} isOpen={true} setIsOpen={() => setMode({ action: '' })} />
// )}
// {mode.action === editBeforeMatchAction.EDIT_FIELD_OWNER_INFO && (
//   <ChangePlayer
//     isOwner={true}
//     number={mainTeam}
//     id={match.params.id}
//     isOpen={true}
//     setIsOpen={() => setMode({ action: '' })}
//     ownerClubId={matchBasicInfoEntity?.ownerClubId}
//     typeEdit={'editPlayer'}
//     onSubmit={handleSubmitForm}
//     processTime={timeCreate}
//   />
// )}
// {mode.action === editBeforeMatchAction.EDIT_FIELD_AWAY_INFO && (
//   <ChangePlayer
//     isOwner={false}
//     number={mainTeam}
//     id={match.params.id}
//     isOpen={true}
//     setIsOpen={() => setMode({ action: '' })}
//     awayClubId={matchBasicInfoEntity?.awayClubId}
//     typeEdit={'editPlayer'}
//     onSubmit={handleSubmitForm}
//     processTime={timeCreate}
//   />
// )}
// {mode.action === editBeforeMatchAction.EDIT_SUBSTITUTE_OWNER_PLAYER && (
//   <ChangePlayer
//     isOwner={true}
//     number={subTeam}
//     id={match.params.id}
//     isOpen={true}
//     setIsOpen={() => setMode({ action: '' })}
//     ownerClubId={matchBasicInfoEntity?.ownerClubId}
//     typeEdit={'editSub'}
//     onSubmit={handleSubmitForm}
//     processTime={timeCreate}
//   />
// )}
// {mode.action === editBeforeMatchAction.EDIT_SUBSTITUTE_AWAY_PLAYER && (
//   <ChangePlayer
//     isOwner={false}
//     number={subTeam}
//     id={match.params.id}
//     isOpen={true}
//     setIsOpen={() => setMode({ action: '' })}
//     awayClubId={matchBasicInfoEntity?.awayClubId}
//     typeEdit={'editSub'}
//     onSubmit={handleSubmitForm}
//     processTime={timeCreate}
//   />
// )}
