import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CategoryDataTrans from './category-data-trans';
import CategoryDataTransDetail from './category-data-trans-detail';
import CategoryDataTransUpdate from './category-data-trans-update';
import CategoryDataTransDeleteDialog from './category-data-trans-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CategoryDataTransUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CategoryDataTransUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CategoryDataTransDetail} />
      <ErrorBoundaryRoute path={match.url} component={CategoryDataTrans} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={CategoryDataTransDeleteDialog} />
  </>
);

export default Routes;
