import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IUnitStandard, defaultValue } from 'app/shared/model/unit-standard.model';

const initialState: EntityState<IUnitStandard> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  deleteSuccess: false,
  createSuccess: false,
};

const apiUrl = 'api/unit-standards';
const apiGetUrl = 'api/unit-standards/search';
const apiSearchUrl = 'api/search-unit-standards';
// Actions

export const getEntities = createAsyncThunk('unitStandard/fetch_entity_list', async ({ page, size, sort, ...res }: IQueryParams) => {
  const requestUrl = `${apiSearchUrl}${sort ? `?page=${page}&size=${size}` : '?'}`;
  return axios.post<IUnitStandard[]>(requestUrl, res);
});

export const getEntity = createAsyncThunk(
  'unitStandard/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IUnitStandard>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'unitStandard/create_entity',
  async (entity: IUnitStandard, thunkAPI) => {
    const result = await axios.post<IUnitStandard>(apiUrl, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'unitStandard/update_entity',
  async (entity: IUnitStandard, thunkAPI) => {
    const result = await axios.put<IUnitStandard>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'unitStandard/partial_update_entity',
  async (entity: IUnitStandard, thunkAPI) => {
    const result = await axios.patch<IUnitStandard>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'unitStandard/delete_entity',
  async (deleteUnitStandard: any, thunkAPI) => {
    const requestUrl = `${apiUrl}/${deleteUnitStandard?.unitStandardId}`;
    const result = await axios.delete<IUnitStandard>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const UnitStandardSlice = createEntitySlice({
  name: 'unitStandard',
  initialState,
  reducers: {
    clearMsgUpdate: (state, action) => void (state.updateSuccess = null),
    clearMsgDelete: (state, action) => void (state.deleteSuccess = null),
    clearMsgCreate: (state, action) => void(state.createSuccess = null)
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.deleteSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })

      .addMatcher(isFulfilled(updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.createSuccess = true;
        state.entity = {};
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, clearMsgUpdate, clearMsgDelete, clearMsgCreate } = UnitStandardSlice.actions;

// Reducer
export default UnitStandardSlice.reducer;
