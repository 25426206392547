import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Col, Input, Select, Tag, Tooltip } from 'antd';
import { formatDate, formatHour, formatYear } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { ClubListFilter } from './club.type';
import {
  getAvatarClub,
  getEntities,
  getEntity,
  clearMsgDelete,
  deleteEntity,
  clearMsgCreate,
  addFavourite,
  deleteFavourite,
} from './club.reducer';
import MultiFilter from 'app/modules/MultiSearch';
import ModalCreateClub from './modal-add-club';
import { toast } from 'react-toastify';
import Filter from 'app/modules/filter';
import { Link, useHistory } from 'react-router-dom';
import { path } from '../routes';
import { PlusIcon, StarActive, StarInactive } from 'app/shared/util/appIcon';
import { ConfirmationDialog } from 'app/shared/util/confirmationDialog';
import { IsFavorite } from 'app/shared/util/constant/favorite.constant';
import './style.scss';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority, isRole } from 'app/shared/auth/private-route';
export const Club = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
    type: 'name',
  };
  const deleteSuccess = useAppSelector(state => state.club.deleteSuccess);
  const clubList = useAppSelector(state => state.club.entities);
  const loading = useAppSelector(state => state.club.loading);
  const createSuccess = useAppSelector(state => state.club.createSuccess);
  const account = useAppSelector(state => state.authentication.account);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const isTeamManager = isRole(authorities, [AUTHORITIES.TEAM_MANAGER]);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);
  useEffect(() => {
    if (createSuccess) {
      toast.success(translate('leagueApp.club.created'));
      dispatch(clearMsgCreate(createSuccess));
      handleFetchData({ ...filter, page: 0 });
    }
  }, [createSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.club.delete.deleteSuccess'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);

  const handleDeleteModal = (user: any) => {
    setUserClicked(user);
    setModalDelete(true);
  };

  const handleFetchData = React.useCallback((params: ClubListFilter) => {
    dispatch(getEntities(params));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const ActionButton = ({ id }: { id: number }) => {
    return (
      <div className={isAdmin && 'column-action-button'}>
        <Button
          onClick={e => {
            push(path.clubDetail.replace(':id', id.toString()));
            e.stopPropagation();
          }}
          className="rounded"
        >
          <Translate contentKey="leagueApp.match.details" />
        </Button>
        {isAdmin && (
          <Button
            onClick={e => {
              handleDeleteModal(id);
              e.stopPropagation();
            }}
            className="rounded"
            danger
            type="primary"
          >
            <Translate contentKey="entity.action.delete2">Delete</Translate>
          </Button>
        )}
      </div>
    );
  };

  const columns = [
    {
      dataIndex: 'favourite',
      key: 'favourite',
      render: (value, record) => (
        <button
          style={{ background: 'transparent', width: '40px', border: 'none' }}
          onClick={e => {
            value === IsFavorite.TRUE
              ? dispatch(deleteFavourite(record?.id)).then(res => {
                  if (res.meta.requestStatus === 'fulfilled') {
                    dispatch(getEntities(filter));
                  }
                })
              : dispatch(addFavourite({ clubId: record?.id })).then(res => {
                  if (res.meta.requestStatus === 'fulfilled') {
                    dispatch(getEntities(filter));
                  }
                });
            e.stopPropagation();
          }}
        >
          {value === IsFavorite.TRUE ? <StarActive /> : <StarInactive />}
        </button>
      ),
      width: 70,
    },
    {
      title: '',
      dataIndex: 'base64Avt',
      key: 'base64Avt',
      width: 50,
      render: (value, record) => <Avatar shape="square" src={value} size="large" />,
    },
    {
      title: <Translate contentKey="leagueApp.club.detail.team" />,
      dataIndex: 'name',
      key: 'name',
      ellipsis: { showTitle: false },
      render: (value, record) => (
        <>
          <Tooltip placement="bottomRight" title={value}>
            {value}
          </Tooltip>
        </>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.club.shortName" />,
      dataIndex: 'shortName',
      key: 'shortName',
    },
    {
      title: <Translate contentKey="leagueApp.club.issueYear" />,
      dataIndex: 'issueYear',
      key: 'issueYear',
      render: (value, record) => formatYear(value),
    },
    {
      title: <Translate contentKey="leagueApp.club.detail.title1" />,
      dataIndex: 'teamManagerName',
      key: 'teamManagerName',
      ellipsis: { showTitle: false },
      render: (value, record) => (
        <Tooltip placement="bottomRight" title={record?.clubManagementDTOList.map(item => item?.userDTO?.fullName).join(', ')}>
          {record?.clubManagementDTOList.map(item => item?.userDTO?.fullName).join(', ')}
        </Tooltip>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.notice.noticeCategoryArr.organization" />,
      dataIndex: 'unitStandardName',
      key: 'unitStandardName',
      ellipsis: { showTitle: false },
      render: (value, record) => (
        <>
          <Tooltip placement="bottomRight" title={value}>
            {value}
          </Tooltip>
        </>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 230,
      render: (value, record) => <ActionButton id={value} />,
      align: 'center' as any,
    },
  ];

  const options = [
    {
      value: 'name',
      label: translate('leagueApp.club.name'),
    },
    {
      value: 'fullName',
      label: translate('leagueApp.club.detail.team'),
    },
  ];

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleDelete = () => {
    dispatch(deleteEntity(userClicked)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        updateFilter();
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const updateFilter = () => {
    onFilterToQueryString({
      page: 0,
      size: 10,
      sort: 'id,asc',
      type: 'name',
    });
    setReset(!reset);
  };

  return (
    <div className="management-layout">
      {isOpen && <ModalCreateClub isOpen={isOpen} setIsOpen={setIsOpen} updateFilter={updateFilter} />}
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading">
            <Translate contentKey="leagueApp.club.home.title">Clubs</Translate>
          </h2>
          {isTeamManager ? (
            ''
          ) : (
            <>
              <div className="management-layout-divider"></div>
              <Button className="rounded management-header-add-button" onClick={handleOpenModal} icon={<PlusIcon />} type="primary">
                <Translate contentKey="clubmng.add" />
              </Button>
            </>
          )}
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <Filter filter={filter} onFilter={onFilterToQueryString} options={options} multi reset={reset} />
        </Col>
      </div>
      <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
      <div className="table-responsive">
        <Table
          onRow={r => ({
            onClick: e => push(path.clubDetail.replace(':id', r?.id.toString())),
          })}
          columns={columns}
          dataSource={clubList?.content}
          totalElements={clubList?.totalElements}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>

      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('leagueApp.club.delete.question')}</h6>}
          onClose={() => setModalDelete(false)}
          onConfirm={() => handleDelete()}
        />
      )}
    </div>
  );
};

export default Club;
