import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import AccountManagement from './account-management';

const Routes = ({ match }) => {
  return (
    <>
      <Switch>
        {/* <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UserManagementUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:login/edit`} component={UserManagementUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/detail`} component={UserManagementDetailDialog} /> */}
        <ErrorBoundaryRoute path={match.url} component={AccountManagement} />
      </Switch>
    </>
  );
};

export default Routes;
