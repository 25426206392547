import { Button, Col, Modal, Radio, Row } from 'antd';
import { IPlayerInMatch } from 'app/shared/model/player.model';
import React from 'react';
import StadiumImage from 'app/assets/stadium.png';
import DraggableElement from './components/dragable-element';
import { translate } from 'react-jhipster';
import { actionRecord } from 'app/shared/util/constant';

interface Props {
  open: boolean;
  setOpen: () => void;
  onClick: (player: IPlayerInMatch) => void;
  awayPlayers?: IPlayerInMatch[];
  ownerPlayers?: IPlayerInMatch[];
  ownerAvatars?: Record<string, string>;
  awayAvatars?: Record<string, string>;
  swap?: boolean;
  mode?: string;
}

const defaultLineup = { 1: [], 2: [], 3: [], 4: [] };

const PickPlayer = ({ open, setOpen, onClick, ownerPlayers, awayPlayers, ownerAvatars, awayAvatars, swap, mode }: Props) => {
  const [elements, setElements] = React.useState<Record<number, IPlayerInMatch[]>>(defaultLineup);
  const [elementsS, setElementsS] = React.useState<Record<number, IPlayerInMatch[]>>(defaultLineup);
  const lists = swap ? [1, 2, 3, 4].reverse() : [1, 2, 3, 4];
  const listsS = swap ? [1, 2, 3, 4] : [1, 2, 3, 4].reverse();

  const getInitValue = (listPlayer: IPlayerInMatch[]): Record<number, IPlayerInMatch[]> => {
    return lists.reduce(
      (acc, listKey) => ({
        ...acc,
        [listKey]: listPlayer.filter(item => item.positionType === listKey).sort((a, b) => a.positionOrder - b.positionOrder),
      }),
      {}
    );
  };
  React.useEffect(() => {
    setElementsS(getInitValue(awayPlayers));
  }, [awayPlayers]);

  React.useEffect(() => {
    setElements(getInitValue(ownerPlayers));
  }, [ownerPlayers]);

  return (
    <Modal
      className="pick-position"
      // style={{ zIndex: 1 }}
      visible={open}
      width={1600}
      closeIcon={null}
      onCancel={setOpen}
      onOk={setOpen}
      footer={null}
      // zIndex={2}
    >
      <div className="container-lineup" style={{ overflow: 'auto', marginBottom: '32px' }}>
        <Row
          gutter={[12, 12]}
          align="middle"
          style={{
            background: `url(${StadiumImage}) no-repeat`,
            backgroundSize: '1200px auto',
            backgroundPosition: 'center',
            width: 1200,
            minHeight: '650px',
            margin: '0 auto',
          }}
        >
          <Col xs={12} style={{ order: 2 }}>
            <Row gutter={[6, 6]} justify="space-between">
              {lists.map(listKey => (
                <Col xs={6} key={listKey}>
                  <DraggableElement
                    onClickPlayer={item => onClick(item)}
                    elements={elements[listKey]}
                    key={listKey.toString()}
                    prefix={listKey}
                    avatars={ownerAvatars}
                    notAllow={mode === actionRecord.FOUL_OWNER}
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={12} style={{ order: swap ? 1 : 3 }}>
            <Row gutter={[6, 6]} justify="space-between">
              {listsS.map(listKey => (
                <Col xs={6} key={listKey}>
                  <DraggableElement
                    onClickPlayer={item => onClick(item)}
                    elements={elementsS[listKey]}
                    key={listKey}
                    prefix={listKey}
                    avatars={awayAvatars}
                    notAllow={mode === actionRecord.FOUL_AWAY}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row justify="center" style={{ width: 1200, position: 'relative', bottom: 0 }}>
          <Col>
            <Button>{translate('recordRoom.lookupTeam.select_player')}</Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
export default PickPlayer;
