export enum actionRecord {
  SUBSTITUTION = 'SUBSTITUTION',
  CHANGE_OWNER = 'CHANGE_OWNER',
  CHANGE_AWAY = 'CHANGE_AWAY',
  GOAL_KICK = 'GOAL_KICK',
  FREE_KICK = 'FREE_KICK',
  PENALTY_KICK = 'PENALTY_KICK',
  SHOOTING = 'SHOOTING',
  SCORE = 'SCORE',
  FOUL = 'FOUL',
  FOUL_AWAY = 'FOUL_AWAY',
  FOUL_OWNER = 'FOUL_OWNER',
  FOUL_SUFFERED = 'FOUL_SUFFERED',
  FOUL_SUFFERED_AWAY = 'FOUL_SUFFERED_AWAY',
  FOUL_SUFFERED_OWNER = 'FOUL_SUFFERED_OWNER',

  OFFSIDE = 'OFFSIDE',
  WARNING = 'BAN',
  PENALTY_SHOOT_OUT = 'PENALTY_SHOOT_OUT',
  EDIT_BASIC_INFO = 'EDIT_BASIC_INFO',
  EDIT_FIELD_OWNER_INFO = 'EDIT_FIELD_OWNER_INFO',
  EDIT_FIELD_AWAY_INFO = 'EDIT_FIELD_AWAY_INFO',
  EDIT_SUBSTITUTE_PLAYER = 'EDIT_SUBSTITUTE_PLAYER',
  EDIT_SUBSTITUTE_OWNER_PLAYER = 'EDIT_SUBSTITUTE_OWNER_PLAYER',
  EDIT_SUBSTITUTE_AWAY_PLAYER = 'EDIT_SUBSTITUTE_AWAY_PLAYER',
  EDIT_FORMATION = 'FORMATION_BEFORE_MATCH',
  RATIO = 'RATIO',
  CORNER_KICK = 'CORNER_KICK',
  ASSIST = 'ASSIST',
}

export enum editBeforeMatchAction {
  EDIT_BASIC_INFO = 'EDIT_BASIC_INFO',
  EDIT_FIELD_INFO = 'EDIT_FIELD_INFO',
  EDIT_FIELD_OWNER_INFO = 'EDIT_FIELD_OWNER_INFO',
  EDIT_FIELD_AWAY_INFO = 'EDIT_FIELD_AWAY_INFO',
  EDIT_SUBSTITUTE_PLAYER = 'EDIT_SUBSTITUTE_PLAYER',
  EDIT_SUBSTITUTE_OWNER_PLAYER = 'EDIT_SUBSTITUTE_OWNER_PLAYER',
  EDIT_SUBSTITUTE_AWAY_PLAYER = 'EDIT_SUBSTITUTE_AWAY_PLAYER',
  EDIT_FORMATION = 'EDIT_FORMATION',
  FORMATION_BEFORE_MATCH = 'FORMATION_BEFORE_MATCH',
  RATIO = 'RATIO',
  REFRESH = 'REFRESH',
  ARROWLEFT = 'ARROWLEFT',
  ARROWRIGHT = 'ARROWRIGHT',
  GOAL_SITUATION = 'GOAL_SITUATION',
}
