import { Button, Card, Col, Row } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { debounce, isBuffer } from 'lodash';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { endMatch, recordMatchTime } from './match-time.reducer';
import { useParams } from 'react-router-dom';
import { MatchType } from 'app/shared/util/constant/match-type.constant';
import { IMatchTime } from 'app/shared/model/match-time.model';
import { translate } from 'react-jhipster';
import { IMatch } from 'app/shared/model/match.model';
import { MatchStatus } from 'app/shared/util/constant';
import { sendWSActivity } from 'app/config/websocket-middleware';
import ChangeStatusMatch from '../match-record/components/change/change-status-match';
import { PlayIcon, SettingIcon } from 'app/shared/util/appIcon';
import { PauseIcon } from '../../shared/util/appIcon/index';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { IPlayerInMatch } from 'app/shared/model/player.model';
import { toast } from 'react-toastify';

dayjs.extend(utc);
dayjs.extend(timezone);

const minuteInMatch = (minute: number) => {
  return minute * 60;
};

const MAX_TIME_DELAY = 3;

const mathSecondFromTo = (from: string, to: string, now, subtractFrom = 0) => {
  return dayjs(now).unix() - (dayjs(to).unix() - dayjs(from).subtract(subtractFrom, 'minutes').unix());
};

interface Props {
  matchInfo: IMatch;
}

const labelMatchTime = (matchTimes: IMatchTime) => {
  if (matchTimes?.startSubTime2) {
    return translate('leagueApp.matchTime.2ndExtra');
  }
  if (matchTimes?.startSubTime1) {
    return translate('leagueApp.matchTime.1stExtra');
  }
  if (matchTimes?.startTime2) {
    return translate('leagueApp.matchTime.2nd');
  }
  return translate('leagueApp.matchTime.1st');
};

const MatchTime = ({ canStart, isEndMatch = false, canChange }: { canStart?: boolean; canChange?: boolean; isEndMatch?: boolean }) => {
  const params = useParams<{ id: string }>();
  const [isOpenChangeStatusMatch, setIsOpenChangeStatusMatch] = useState(false);
  const matchTimes: IMatchTime = useAppSelector(state => state.matchTime?.entity);
  const matchInfo = useAppSelector(state => state.match.entity);
  const [realTime, setRealtime] = React.useState({ current: dayjs(matchTimes.now).format('A HH:mm:ss'), count: 0 });

  const firstHalfTime = matchInfo?.timeOfRound;
  const secondHalfTime = 2 * matchInfo?.timeOfRound;
  const extraFirstTime = 2 * matchInfo?.timeOfRound + matchInfo?.timeOfRoundExtra;
  const extraSecondHalfTime = 2 * matchInfo?.timeOfRound + 2 * matchInfo?.timeOfRoundExtra;

  React.useEffect(() => {
    setRealtime(pre => ({ current: dayjs(matchTimes.now).format('A HH:mm:ss'), count: 0 }));
    setClock(pre => (startTime ? dayjs(matchTimes.now).unix() - startTime : undefined));
  }, [matchTimes.now]);
  const startTime = React.useMemo(() => {
    if (!matchTimes) {
      return 0;
    }

    // phan hien thi thoi gian cu vi du: hiep 2: 64:00
    // if (matchTimes.endSubTime2) {
    //   return mathSecondFromTo(matchTimes.startSubTime2, matchTimes.endSubTime2, matchTimes.now, extraFirstTime);
    // }
    // if (matchTimes.startSubTime2) return dayjs(matchTimes.startSubTime2).subtract(extraFirstTime, 'minutes').unix();
    // if (matchTimes.endSubTime1) return mathSecondFromTo(matchTimes.startSubTime1, matchTimes.endSubTime1, matchTimes.now, secondHalfTime);
    // if (matchTimes.startSubTime1) return dayjs(matchTimes.startSubTime1).subtract(secondHalfTime, 'minutes').unix();
    // if (matchTimes.endTime2 && matchInfo.status !== MatchStatus.FINISH)
    //   return mathSecondFromTo(matchTimes.startTime2, matchTimes.endTime2, matchTimes.now, firstHalfTime);
    // if (matchInfo.status === MatchStatus.FINISH)
    //   return mathSecondFromTo(matchTimes.startTime2, matchTimes.endTime2, matchTimes.now, firstHalfTime);
    // if (matchTimes.startTime2) return dayjs(matchTimes.startTime2).subtract(firstHalfTime, 'minutes').unix();
    // if (matchTimes.endTime1) return mathSecondFromTo(matchTimes.startTime1, matchTimes.endTime1, matchTimes.now);

    // phan hien thi thoi gian moi vi du: hiep 2: 14:00
    if (matchTimes.endSubTime2) {
      return mathSecondFromTo(matchTimes.startSubTime2, matchTimes.endSubTime2, matchTimes.now);
    }
    if (matchTimes.startSubTime2) return dayjs(matchTimes.startSubTime2).unix();
    if (matchTimes.endSubTime1) return mathSecondFromTo(matchTimes.startSubTime1, matchTimes.endSubTime1, matchTimes.now);
    if (matchTimes.startSubTime1) return dayjs(matchTimes.startSubTime1).unix();
    if (matchTimes.endTime2 && matchInfo.status !== MatchStatus.FINISH)
      return mathSecondFromTo(matchTimes.startTime2, matchTimes.endTime2, matchTimes.now);
    if (matchInfo.status === MatchStatus.FINISH) return mathSecondFromTo(matchTimes.startTime2, matchTimes.endTime2, matchTimes.now);
    if (matchTimes.startTime2) return dayjs(matchTimes.startTime2).unix();
    if (matchTimes.endTime1) return mathSecondFromTo(matchTimes.startTime1, matchTimes.endTime1, matchTimes.now);
    if (matchTimes.startTime1) return dayjs(matchTimes.startTime1).unix();
    return 0;
  }, [matchTimes, matchInfo]);

  const stopClock = React.useMemo(() => {
    if (matchInfo.status === MatchStatus.FINISH || !matchTimes) return true;
    const condition1 = matchTimes.endTime1 && !matchTimes.startTime2;
    const condition2 = matchTimes.endTime2 && !matchTimes.startSubTime1;

    const condition3 = matchTimes.endSubTime1 && !matchTimes.startSubTime2;

    const condition4 = !!matchTimes.endSubTime2 || !matchTimes.startTime1;

    return Boolean(condition1) || Boolean(condition2) || Boolean(condition3) || Boolean(condition4);
  }, [matchTimes, matchInfo]);

  const [clock, setClock] = React.useState<number>();
  const dispatch = useAppDispatch();

  React.useLayoutEffect(() => {
    if (!stopClock) {
      const timerID = debounce(() => {
        setClock(pre => pre + 1);
      }, 1000);
      timerID();
      return () => timerID.cancel();
    }
  }, [clock]);

  const matchTime = React.useMemo(() => {
    const newClock = clock || 0;

    // if (matchTimes.startSubTime2 && newClock >= minuteInMatch(extraSecondHalfTime)) return <span>{extraSecondHalfTime}:00</span>;
    // if (!matchTimes.startSubTime2 && matchTimes.startSubTime1 && newClock >= minuteInMatch(extraFirstTime))
    //   return <span>{extraFirstTime}:00</span>;
    // if (!matchTimes.startSubTime1 && matchTimes.startTime2 && newClock >= minuteInMatch(secondHalfTime))
    //   return <span>{secondHalfTime}:00</span>;
    // if (!matchTimes.startTime2 && matchTimes.startTime1 && newClock >= minuteInMatch(firstHalfTime)) return <span>{firstHalfTime}:00</span>;
    return (
      <span>
        {Math.floor(newClock / 60) < 10 ? '0' + Math.floor(newClock / 60) : Math.floor(newClock / 60)}:
        {newClock - Math.floor(newClock / 60) * 60 < 10
          ? '0' + (newClock - Math.floor(newClock / 60) * 60)
          : newClock - Math.floor(newClock / 60) * 60}
      </span>
    );
  }, [clock]);

  // const extraTime = React.useMemo(() => {
  //   const newClock = clock || 0;
  //   const officialTime = () => {
  //     if (matchTimes.startSubTime2) return minuteInMatch(extraSecondHalfTime);
  //     if (matchTimes.startSubTime1) return minuteInMatch(extraFirstTime);
  //     if (matchTimes.startTime2) return minuteInMatch(secondHalfTime);
  //     if (matchTimes.startTime1) return minuteInMatch(firstHalfTime);
  //     return newClock;
  //   };
  //   const extra = newClock - officialTime();
  //   if (extra > 0)
  //     return (
  //       <span>
  //         + {Math.floor(extra / 60) < 10 ? '0' + Math.floor(extra / 60) : Math.floor(extra / 60)}:
  //         {extra - Math.floor(extra / 60) * 60 < 10 ? '0' + (extra - Math.floor(extra / 60) * 60) : extra - Math.floor(extra / 60) * 60}
  //       </span>
  //     );
  // }, [clock, matchTimes]);

  React.useLayoutEffect(() => {
    const time = debounce(
      () => setRealtime(pre => ({ current: dayjs(pre.current, 'A HH:mm:ss').add(1, 'second').format('A HH:mm:ss'), count: pre.count + 1 })),
      1000
    );
    time();
    if (matchTimes && dayjs().diff(dayjs(matchTimes.localTime), 's') - realTime.count > MAX_TIME_DELAY) {
      recordTime(MatchType.INFO);
    }
    return () => time.cancel();
  }, [realTime]);

  const recordTime = React.useCallback(
    (type: string) => {
      if (!params || !params.id) {
        return;
      }
      if (!canStart && type === MatchType.START_FIRST) {
        toast.error(translate('leagueApp.matchRecord.errorGKMessage'));
        return;
      }
      sendWSActivity(`/record/match-time/setTime/${params.id}/${type}`, {});
      // dispatch(recordMatchTime({ matchId: params.id, type }));
    },
    [params, canStart]
  );

  const handleEndMatch = () => {
    sendWSActivity(`/record/match-time/finish-match/${matchInfo.id}`, {});
  };
  const matchTimeButton = React.useMemo(() => {
    if (matchInfo.status === MatchStatus.FINISH) {
      return (
        <Button disabled type="primary" block onClick={() => handleEndMatch()}>
          {translate('common.finish')}
        </Button>
      );
    }
    if (matchTimes?.endSubTime2) {
      return (
        <Button type="primary" block onClick={() => handleEndMatch()}>
          {translate('common.finish')}
        </Button>
      );
    }
    if (matchTimes?.startSubTime2) {
      return (
        <Button
          type="primary"
          icon={<PauseIcon />}
          // disabled={clock < minuteInMatch(extraSecondHalfTime)}
          block
          onClick={() => recordTime(MatchType.END_SUB_SECOND)}
        >
          {translate('leagueApp.matchTime.end2ndExtra')}
        </Button>
      );
    }
    if (matchTimes?.endSubTime1) {
      return (
        <Button type="primary" block onClick={() => recordTime(MatchType.START_SUB_SECOND)} icon={<PlayIcon />}>
          {translate('leagueApp.matchTime.start2nd')}
        </Button>
      );
    }
    if (matchTimes?.startSubTime1) {
      return (
        <Button
          type="primary"
          // disabled={clock < minuteInMatch(extraFirstTime)}
          icon={<PauseIcon />}
          block
          onClick={() => recordTime(MatchType.END_SUB_FIRST)}
        >
          {translate('leagueApp.matchTime.end1stExtra')}
        </Button>
      );
    }
    if (matchTimes?.endTime2) {
      return (
        <Row className="flex-grow-1">
          <Col span={24}>
            <Button type="primary" block onClick={() => handleEndMatch()} style={{ width: '100%' }}>
              {translate('common.finish')}
            </Button>
          </Col>
          <Col span={24} style={{ marginTop: 12 }}>
            <Button type="primary" block icon={<PlayIcon />} onClick={() => recordTime(MatchType.START_SUB_FIRST)}>
              {translate('leagueApp.matchTime.start1stExtra')}
            </Button>
          </Col>
        </Row>
      );
    }

    if (matchTimes?.startTime2) {
      return (
        <Button
          type="primary"
          icon={<PauseIcon />}
          // disabled={clock < minuteInMatch(secondHalfTime)}
          block
          onClick={() => recordTime(MatchType.END_SECOND)}
        >
          {translate('leagueApp.matchTime.end2nd')}
        </Button>
      );
    }
    if (matchTimes?.endTime1) {
      return (
        <Button type="primary" block icon={<PlayIcon />} onClick={() => recordTime(MatchType.START_SECOND)}>
          {translate('leagueApp.matchTime.start2nd')}
        </Button>
      );
    }
    if (matchTimes?.startTime1) {
      return (
        <Button
          type="primary"
          icon={<PauseIcon />}
          // disabled={clock < minuteInMatch(firstHalfTime)}
          block
          onClick={() => {
            recordTime(MatchType.END_FIRST);
          }}
        >
          {translate('leagueApp.matchTime.end1st')}
        </Button>
      );
    }
    return (
      <Button
        type="primary"
        block
        icon={<PlayIcon />}
        onClick={() => {
          recordTime(MatchType.START_FIRST);
        }}
      >
        {translate('leagueApp.matchTime.start1st')}
      </Button>
    );
  }, [matchTimes, recordTime, clock, matchInfo]);

  const handleOpenStatusMatch = () => {
    if (!matchTimes?.startTime1) return;
    setIsOpenChangeStatusMatch(true);
  };
  return (
    <Card>
      <Row style={{ padding: '16px 32px' }}>
        <Col span={24}>
          <Row gutter={[12, 12]}>
            <Col xs={12}>
              <div className="record-time">
                <p className="title">{translate('leagueApp.matchTime.time')}</p>
                <p>{realTime.current}</p>
              </div>
            </Col>
            <Col xs={12}>
              <div className="record-time">
                <p className="title">{labelMatchTime(matchTimes)}</p>
                <p>
                  {matchTime}
                  {/* {extraTime} */}
                </p>
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={24} className="match-time-point">
          <p style={{ width: '80%' }} className="h6 mb-0">
            {matchInfo?.ownerClubName}
          </p>
          {matchInfo?.ownerGoal}
        </Col>
        <Col xs={24} className="match-time-point">
          <p style={{ width: '80%' }} className="h6 mb-0">
            {matchInfo?.awayClubName}
          </p>
          {matchInfo?.awayGoal}
        </Col>
        {/* {!isEndMatch && ( */}
        <Col
          xs={24}
          style={{
            display: 'flex',
            marginTop: 12,
          }}
        >
          {matchTimeButton}
          {matchTimes?.startTime1 && (
            <div
              onClick={handleOpenStatusMatch}
              style={{ cursor: 'pointer', background: '#0062AE', padding: '8px 10px', borderRadius: '8px', marginLeft: '8px', height: 44 }}
            >
              <SettingIcon />
            </div>
          )}
        </Col>
        {/* )} */}
      </Row>
      {isOpenChangeStatusMatch && (
        <ChangeStatusMatch canChange={canChange} isOpen={isOpenChangeStatusMatch} setIsOpen={setIsOpenChangeStatusMatch} id={params.id} />
      )}
    </Card>
  );
};

export default React.memo(MatchTime);
