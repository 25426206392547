import { useRequest } from 'ahooks';
import { Col, ConfigProvider, Empty, Form, Row, Select, Skeleton } from 'antd';
import { useAppSelector } from 'app/config/store';
import { listLeague, notificationRequest } from 'app/modules/dashboard/dashboard.service';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import dayjs from 'dayjs';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { useLocation } from 'react-router-dom';
import './index.scss';

const Notification = props => {
  const account = useAppSelector(state => state.authentication.account);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const [data, setData] = React.useState([]);
  const [listDataLeague, setListDataLeague] = React.useState(null);
  const location: any = useLocation();
  const date = dayjs(new Date()).startOf('date').toISOString();
  const [form] = Form.useForm();
  const watchLeague = Form.useWatch('league', form);

  const request = useRequest(notificationRequest, {
    manual: true,
    onSuccess(res) {
      setData(res.data);
    },
  });

  const callListLeague = useRequest(listLeague, {
    manual: true,
    onSuccess(res) {
      setListDataLeague(res.data);
    },
  });

  React.useEffect(() => {
    if (watchLeague || location?.state?.idLeague) {
      request.run({
        userId: account.id,
        lang: locale,
        leagueId: location?.state?.idLeague || watchLeague,
      });
    }
  }, [locale, watchLeague]);

  React.useEffect(() => {
    callListLeague.run({
      now: date,
    });
  }, []);

  React.useEffect(() => {
    if (listDataLeague && !location?.state?.idLeague) {
      form.setFieldsValue({ league: listDataLeague[0]?.id });
    } else if (location?.state?.idLeague) {
      form.setFieldsValue({ league: location?.state?.idLeague });
    }
  }, [listDataLeague, location?.state?.idLeague]);

  return (
    <div className="management-layout">
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading">
            <Translate contentKey="dashboard.notification"></Translate>
          </h2>
        </div>
      </div>
      <Form form={form} onFinishFailed={values => handleFocusFieldFail(values)} scrollToFirstError name="control-hooks">
        <Row gutter={[32, 32]}>
          <Col xl={12} lg={12} xs={24}>
            <Form.Item name="league" label={<h6>{translate('leagueApp.league.tournament')}</h6>}>
              <Select style={{ width: '100%' }} className="custom-prefix-select" suffixIcon={<SelectArrowDown />}>
                {listDataLeague?.map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="management-body">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space', marginBottom: 16 }}>
          {/* <img height={48} src={'https://brandlogos.net/wp-content/uploads/2014/11/fc-bayern-munich-logo.png'} /> */}
          <span style={{ flexGrow: 1, background: '#f2f8ff', paddingLeft: 10 }} className="heading-20-bold ">
            <Translate contentKey="dashboard.notification_message"></Translate>
          </span>
          <div style={{ textAlign: 'right', width: 90, background: '#f2f8ff', paddingRight: 10 }} className="heading-20-bold ">
            <Translate contentKey="dashboard.notification_time"></Translate>
          </div>
        </div>
        <ul className="list-notifications-management">
          {request.loading || !data || data?.length === 0 ? (
            <Skeleton active />
          ) : (
            data.map((noti, i) => {
              return (
                <li key={i}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space' }}>
                    {/* <img height={48} src={'https://brandlogos.net/wp-content/uploads/2014/11/fc-bayern-munich-logo.png'} /> */}
                    <span style={{ flexGrow: 1 }}>{noti.message}</span>
                    <div style={{ textAlign: 'right', width: 90 }}>
                      <p className="neutral-3" style={{ fontSize: 12, fontWeight: '500', lineHeight: '20px' }}>
                        {dayjs(noti.date).format('YYYY-MM-DD')}
                      </p>
                    </div>
                  </div>
                </li>
              );
            })
          )}
        </ul>
      </div>
    </div>
  );
};

export default Notification;
