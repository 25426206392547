import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Col, Input, Select, Tag } from 'antd';
import { formatDate, formatHour } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { StadiumListFilter } from './stadiumd.type';
import { clearMsgCreate, clearMsgDelete, clearMsgUpdate, deleteEntity, getEntities, getEntity } from './stadium.reducer';
import { MatchStatusContained } from 'app/modules/status-contained';
import dayjs from 'dayjs';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import StadiumAddDialog from './stadium-add-dialog';
import { toast } from 'react-toastify';
import StadiumDeleteDialog from './stadium-delete-dialog';
import Filter from 'app/modules/filter';
import { PlusIcon } from 'app/shared/util/appIcon';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';
import MultiFilter from 'app/modules/MultiSearch';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
export const Stadium = (props: any) => {
  const dispatch = useAppDispatch();
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const account = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
    type: 'name',
  };

  const stadiumList = useAppSelector(state => state.stadium.entities);
  const loading = useAppSelector(state => state.stadium.loading);
  const deleteSuccess = useAppSelector(state => state.stadium.deleteSuccess);
  const createSuccess = useAppSelector(state => state.stadium.createSuccess);
  const updateSuccess = useAppSelector(state => state.stadium.updateSuccess);

  useEffect(() => {
    if (createSuccess) {
      handleFetchData({ ...filter, page: 0 });
    }
  }, [createSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      handleFetchData({ ...filter, page: 0 });
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.stadium.delete.deleteSuccess'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);

  const handleDeleteModal = (user: any) => {
    setUserClicked(user);
    setModalDelete(true);
  };

  const handleFetchData = React.useCallback((params: StadiumListFilter) => {
    dispatch(getEntities(params));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const ActionButton = ({ id }: { id: number }) => {
    return (
      <div className={isAdmin && 'column-action-button'}>
        <Button
          onClick={e => {
            handleUpdate(id);
            setShowDialog(true);
          }}
          className="rounded"
        >
          <Translate contentKey="entity.action.edit" />
        </Button>
        {isAdmin && (
          <Button onClick={() => handleDeleteModal(id)} className="rounded" danger type="primary">
            <Translate contentKey="entity.action.delete2">Delete</Translate>
          </Button>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: <Translate contentKey="leagueApp.stadium.name"></Translate>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <Translate contentKey="leagueApp.stadium.address"></Translate>,
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: <Translate contentKey="leagueApp.stadium.total"></Translate>,
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 230,
      render: (value, record) => <ActionButton id={value} />,
      align: 'center' as any,
    },
  ];

  const [showDialog, setShowDialog] = useState(false);
  const [idUpdate, setIdUpdate] = useState('');

  const handleOpenModal = () => {
    setIdUpdate(null);
    setShowDialog(true);
  };

  const handleDeleteItem = () => {
    const obj = {
      stadiumId: userClicked,
    };
    dispatch(deleteEntity(obj)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        updateFilter();
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const options = [
    {
      value: 'name',
      label: translate('leagueApp.stadium.name'),
    },
    // {
    //   value: 'address',
    //   label: translate('leagueApp.stadium.address'),
    // },
    // {
    //   value: 'total',
    //   label: translate('leagueApp.stadium.total'),
    // },
  ];

  const handleUpdate = id => {
    setIdUpdate(id);
  };

  const updateFilter = () => {
    onFilterToQueryString({
      page: 0,
      size: 10,
      sort: 'id,asc',
      type: 'name',
    });
    setReset(!reset);
  };

  return (
    <div className="management-layout">
      {showDialog && (
        <StadiumAddDialog
          entityId={idUpdate}
          setEntityId={setIdUpdate}
          showDialog={showDialog}
          setShowDialog={b => setShowDialog(b)}
          updateFilter={updateFilter}
        />
      )}
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading">
            <Translate contentKey="leagueApp.stadium.home.title">Stadiums</Translate>
          </h2>
          <div className="management-layout-divider"></div>
          <Button className="rounded management-header-add-button" onClick={handleOpenModal} icon={<PlusIcon />} type="primary">
            <Translate contentKey="clubmng.add" />
          </Button>
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <Filter filter={filter} onFilter={onFilterToQueryString} options={options} multi reset={reset} />
        </Col>
      </div>
      <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={stadiumList.content}
          totalElements={stadiumList.totalElements}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
      {/* {modalDelete ? <StadiumDeleteDialog modalDelete={modalDelete} setModalDelete={setModalDelete} userClicked={userClicked} /> : ''} */}
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('leagueApp.stadium.delete.question')}</h6>}
          onClose={() => setModalDelete(false)}
          onConfirm={() => handleDeleteItem()}
        />
      )}
    </div>
  );
};

export default Stadium;
