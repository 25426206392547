import React from 'react';
import { translate, Translate } from 'react-jhipster';

interface Props {
  rank: number;
}

export const RankContainer = ({ rank }: Props) => {
  if (rank === 0 || rank === null) {
    return <div></div>;
  }
  if (rank === 1) {
    return (
      <div>
        <p>{translate('leagueApp.playerAcademic.render.graduate')}</p>
      </div>
    );
  }
  if (rank === 2) {
    return (
      <div>
        <p>{translate('leagueApp.playerAcademic.render.undergraduate')}</p>
      </div>
    );
  }
  if (rank === 3) {
    return (
      <div>
        <p>{translate('leagueApp.playerAcademic.render.completion')}</p>
      </div>
    );
  }
  if (rank === 4) {
    return (
      <div>
        <p>{translate('leagueApp.playerAcademic.render.average')}</p>
      </div>
    );
  }
};
