import { translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import { getBodyAndParams } from './../../shared/util/helper';
import axios from 'axios';
import { AsyncThunkPayloadCreator, createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError, Pageable, UploadType } from 'app/shared/reducers/reducer.utils';

import { IQuestionAnswer, defaultValue } from 'app/shared/model/question-answer.model';
import { IAttachFile } from 'app/shared/model/notice.model';
import { QuestionAnswerParams } from 'app/shared/model/filter.model';

interface QuestionAnswerState extends EntityState<IQuestionAnswer> {
  pageable?: Pageable<IQuestionAnswer>;
}

const initialState: QuestionAnswerState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/question-answers';
const apiAnswers = 'api/answers';

export const searchEntities = createAsyncThunk(
  'question_answers/search_entity',
  async (filter: QuestionAnswerParams) => {
    const { body, params } = getBodyAndParams(filter);
    const result = await axios.post<Pageable<IQuestionAnswer>>(`${apiUrl}/search`, body, { params });
    return result.data;
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'question_answers/create_entity',
  async (entity: IQuestionAnswer) => {
    return axios.post<IQuestionAnswer>(apiUrl, entity);
  },

  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'question_answers/update_entity',
  async (entity: IQuestionAnswer, thunkAPI) => {
    return axios.put<IQuestionAnswer>(`${apiUrl}/${entity.id}`, entity);
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'question_answers/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IQuestionAnswer>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<
  IQuestionAnswer,
  string | number,
  {
    rejectValue: PaymentValidationErrors;
  }
>(
  'question_answers/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.get<IQuestionAnswer>(requestUrl);
    return result.data;
  },
  { serializeError: serializeAxiosError }
);

export const uploadAttachFile = createAsyncThunk(
  'question_answers/upload_attach_files',
  async (files: IAttachFile[]) => {
    const result = await axios.all<IAttachFile>(
      files?.map(item => {
        if (item.file) {
          const data = new FormData();
          data.append('file', item.file);
          data.append('uploadType', UploadType.ATTACHMENT);
          return axios
            .post('/api/file/upload-file', data, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(res => ({ ...item, nameFileServer: res.data, url: undefined }));
        }
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(item);
          }, 300);
        });
      })
    );
    return result || [];
  },
  { serializeError: serializeAxiosError }
);

export const QuestionAnswersSlice = createSlice({
  name: 'question_answers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
      })
      .addCase(createEntity.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateEntity.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.loading = false;
      })
      .addCase(uploadAttachFile.pending, (state, action) => {
        state.loading = true;
      })
      .addMatcher(isFulfilled(searchEntities), (state, action) => {
        state.pageable = action.payload;
        state.loading = false;
      })
      .addMatcher(isPending(getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
        state.loading = true;
      });
  },
});

export default QuestionAnswersSlice.reducer;
