import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { Translate, TextFormat, JhiPagination, JhiItemCount, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Storage } from 'react-jhipster';
import { translate } from 'react-jhipster';
import ModalDenied from './user-management-delete-dialog';
import ModaAccept from './user-management-accept-dialog';
import ModalDetail from './user-management-detail-dialog';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getUsersAsAdmin, updateUser, getUsersInformation, ResViewDetail, deniedUser, reset, disableUser } from './user-management.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IUser } from 'app/shared/model/user.model';
import { act } from 'react-dom/test-utils';
import moment from 'moment';
import { toast } from 'react-toastify';
import { UserListFilter } from './userManagement.type';
import { useFilter } from 'app/shared/hooks/useFilter';
import { path } from '../index';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Col, Button, Row, Switch } from 'antd';
import Filter from 'app/modules/filter';
import Table from 'app/modules/table';
import { convertDateTimeFromServer, formatDate2, formatTime } from 'app/shared/util/date-utils';
import { ConfirmationDialog } from 'app/shared/util/confirmationDialog';
import { PlusIcon } from 'app/shared/util/appIcon';
import { AuthoritiesValuesTypeContainer } from './role-type';
import { UserManagementAddDialog } from './user-management-add-dialog';
import FilterCustom from 'app/modules/filter-custom';
import MultiFilterCustom from 'app/modules/MultiSearchCustom';

export const UserManagement = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [userAccept, setUserAccept] = useState(null);
  const [modalAccept, setModalAccept] = useState(false);
  const [resets, setResets] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
    type: 'fullName',
  };
  const deleteSuccess = useAppSelector(state => state.userManagement?.deleteSuccess);
  const createdSuccess = useAppSelector(state => state.userManagement?.createSuccess);
  const users = useAppSelector(state => state.userManagement.users);
  const loading = useAppSelector(state => state.club.loading);
  const handleDeleteModal = (user: any) => {
    setUserClicked(user);
    setModalDelete(true);
  };

  const handleFetchData = React.useCallback((params: UserListFilter) => {
    params.searchClub = false;
    dispatch(getUsersAsAdmin(params));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const handleDenied = (user: any) => {
    setUserClicked(user);
    setModalDelete(true);
  };

  const handleDelete = () => {
    const obj = {
      userId: userClicked,
      status: 0,
    };
    dispatch(deniedUser(obj)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        updateFilter();
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const handleModalAccept = (user: any) => {
    setUserAccept(user);
    setModalAccept(true);
  };

  const handleAccept = () => {
    const obj = {
      userId: userAccept,
      status: 1,
    };
    dispatch(deniedUser(obj)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalAccept(false);
  };

  const ActionButton = ({ status, id }: { status: number; id: number }) => {
    if (status === 1) {
      return (
        <Button
          color="info"
          className="w-100 py-1 font-weight-bolder"
          onClick={e => {
            e.stopPropagation();
            push(path.userManagementDetail.replace(':id', id.toString()));
          }}
        >
          <Translate contentKey="userManagement.viewDetail" />
        </Button>
      );
    } else {
      return (
        <React.Fragment>
          <Row>
            <Col span={12}>
              <Button onClick={() => handleModalAccept(id)}>
                <Translate contentKey="userManagement.accept" />
              </Button>
            </Col>
            <Col span={12}>
              <Button onClick={() => handleDenied(id)} type="primary" danger className="rounded">
                <Translate contentKey="userManagement.deny" />
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  };

  const columns = [
    {
      title: <Translate contentKey="global.field.name" />,
      dataIndex: 'fullName',
      key: 'fullName',
      width: 200,
    },
    {
      title: <Translate contentKey="userManagement.unit" />,
      dataIndex: 'unitStandardName',
      key: 'unitStandardName',
      width: 200,
    },
    {
      title: <Translate contentKey="userManagement.email" />,
      dataIndex: 'login',
      key: 'login',
      width: 200,
    },
    {
      title: <Translate contentKey="userManagement.phoneNumber" />,
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 200,
    },
    {
      title: <Translate contentKey="userManagement.role" />,
      dataIndex: 'authoritiesValues',
      key: 'authoritiesValues',
      width: 200,
      render: (value, record) => <AuthoritiesValuesTypeContainer authoritiesValues={value} />,
    },
    {
      title: <Translate contentKey="userManagement.createdDate" />,
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: 200,
      render: (value, record) => convertDateTimeFromServer(value),
    },
    {
      title: <Translate contentKey="userManagement.status" />,
      dataIndex: 'activated',
      key: 'activated',
      width: 200,
      render: (value, record) => (
        <>
          <Switch
            disabled={record?.authoritiesValues === 'Admin' ? true : false}
            defaultChecked={value === true ? true : false}
            onClick={() => {
              dispatch(disableUser({ id: record?.id, status: value === false ? 2 : null }))
                .then(res => {
                  if (value === true) {
                    toast.success(translate('userManagement.deactiveSuccess'));
                  } else {
                    toast.success(translate('userManagement.activeSuccess'));
                  }
                  handleFetchData({ ...filter });
                })
                .catch(err => {
                  if (value === true) {
                    toast.success(translate('userManagement.deactivateFail'));
                  } else {
                    toast.success(translate('userManagement.activeFail'));
                  }
                });
            }}
          />
          <span style={{ marginLeft: 8 }}>
            {value === true ? translate('userManagement.active') : translate('userManagement.deactivate')}
          </span>
        </>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 240,
      render: (value, record) => <ActionButton status={record.status} id={value} />,
      align: 'center' as any,
    },
  ];

  const options = [
    {
      value: 'fullName',
      label: translate('global.field.name'),
    },
    {
      value: 'unitStandardName',
      label: translate('userManagement.unit'),
    },
    // {
    //   value: 'login',
    //   label: translate('userManagement.email'),
    // },
    {
      value: 'roleSearch',
      label: translate('userManagement.role'),
    },
  ];

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const updateFilter = () => {
    onFilterToQueryString({
      page: 0,
      size: 10,
      sort: 'id,asc',
      // type: 'name',
    });
    setResets(!resets);
  };

  return (
    <div className="management-layout">
      {isOpen && (
        <UserManagementAddDialog
          isOpen={isOpen}
          setIsOpen={b => setIsOpen(b)}
          updateFilter={() => {
            handleFetchData({ ...filter });
          }}
        />
      )}
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading">
            <Translate contentKey="userManagement.home.title"></Translate>
          </h2>
          <div className="management-layout-divider"></div>
          <Button className="rounded management-header-add-button" onClick={handleOpenModal} icon={<PlusIcon />} type="primary">
            <Translate contentKey="clubmng.add" />
          </Button>
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <FilterCustom filter={filter} onFilter={onFilterToQueryString} multi options={options} ignoreClubManagement={true} />
        </Col>
      </div>
      <MultiFilterCustom filter={filter} onFilter={onFilterToQueryString} options={options} ignore={['searchClub']} />
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={users.content}
          totalElements={users.totalElements}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('userManagement.delete.question')}</h6>}
          customTextConfirm={<Translate contentKey="userManagement.deny" />}
          customTextReject={<Translate contentKey="userManagement.close" />}
          onClose={() => setModalDelete(false)}
          onConfirm={() => handleDelete()}
        />
      )}
      {modalAccept && (
        <ConfirmationDialog
          open={modalAccept}
          message={<h6>{translate('userManagement.accepted.question')}</h6>}
          customTextConfirm={<Translate contentKey="userManagement.accept" />}
          customTextReject={<Translate contentKey="userManagement.close" />}
          onClose={() => setModalAccept(false)}
          onConfirm={() => handleAccept()}
        />
      )}
    </div>
  );
};

export default UserManagement;
