import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ILeagueStandard, defaultValue } from 'app/shared/model/league-standard.model';
import { IClub } from 'app/shared/model/club.model';

const initialState: EntityState<IClub> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  deleteSuccess: false,
  links: null,
  dataUploadFile: null,
};

const apiUrl = 'api/clubs/find-all-club-by-league';

// Actions

export const getEntities = createAsyncThunk('leagueStandard/fetch_entity_list', async ({ leagueId, ...res }: IQueryParams) => {
  const requestUrl = `${apiUrl}/${leagueId}`;
  return axios.get<IClub[]>(requestUrl);
});

export const GetClubModal = createEntitySlice({
  name: 'leagueStandard',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = GetClubModal.actions;

// Reducer
const getClubMultiSelect = GetClubModal.reducer;
export default getClubMultiSelect;
