import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row, Select } from 'antd';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';
import { Translate, translate } from 'react-jhipster';
import TextField from 'app/modules/lm-input';
import AttachFile from 'app/entities/attach-file';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { Pageable } from 'app/shared/reducers/reducer.utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IQuestionAnswer } from 'app/shared/model/question-answer.model';
import { useHistory, useParams } from 'react-router-dom';
import { BsCaretLeft } from 'react-icons/bs';
import RichTextEditor from 'app/modules/rich-text-editor';
import { IAttachFile } from 'app/shared/model/notice.model';
import { updateEntity } from './question-answer.reducer';
import { answerEntity, uploadAttachFile } from './answer.reducer';
import { toast } from 'react-toastify';

const AnswerUpdate = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const history = useHistory();
  const files = Form.useWatch('attachmentDTOS', form);
  const detailAnswer: IQuestionAnswer = useAppSelector(state => state.answerReducer.entity);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const loading = useAppSelector(state => state.questionAnswerReducer.loading);
  const handleBack = e => {
    history.goBack();
  };
  const handleSubmit = (data: IQuestionAnswer) => {
    dispatch(uploadAttachFile(data?.attachmentDTOS)).then(res => {
        dispatch(updateEntity({ ...detailAnswer, ...data, attachmentDTOS: res.payload as IAttachFile[] })).then(response => {
          if (response.meta.requestStatus === 'fulfilled') {
            history.push('../../question-and-answer');
            toast.success(translate('leagueApp.questionAnswer.updateAnswerSuccess'))
          }
        });
    });
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(answerEntity(id)).then(res => {
      form.setFieldsValue(res.payload);
    });
  }, [id]);

  return (
    <Form
      form={form}
      scrollToFirstError
      name="control-hooks"
      layout="vertical"
      requiredMark={'optional'}
      onFinishFailed={values => handleFocusFieldFail(values)}
      onFinish={handleSubmit}
    >
      <Card className="mb-3">
        <div className="border-bottom d-flex justify-content-between align-items-center" style={{ paddingBottom: 30, marginBottom: 32 }}>
          <div className="d-flex align-items-center auto-wrap">
            <Button className="rounded management-header-add-button" icon={<BsCaretLeft />} onClick={handleBack} type="primary">
              <Translate contentKey="entity.action.back" />
            </Button>
            <div className="management-layout-divider"></div>
            <p className="h4 mb-0">
              {' '}
              <Translate contentKey="leagueApp.questionAnswer.updateAnswer" />
            </p>
          </div>
          <Button loading={loading} htmlType="submit" type="primary">
            <Translate contentKey="common.save" />
          </Button>
        </div>
        <Row gutter={[24, 12]}>
          <Col xs={24}>
            <Form.Item
              label={<h6>{translate('leagueApp.notice.content')}</h6>}
              name="content"
              rules={[{ required: true, message: translate('leagueApp.notice.required.content') }]}
            >
              <RichTextEditor />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Form.Item name="attachmentDTOS">
        <AttachFile
          files={files}
          onChange={fileList => {
            form.setFieldsValue(pre => ({ ...pre, attachmentDTOS: fileList }));
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default AnswerUpdate;
