import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { INTERMINABLE } from 'app/shared/util/constant/favorite.constant';
import { ScheduleFilter } from './schedule-results';

const initState = {
  listClubRanking: [],
  listPlayerRanking: [],
  listLeague: [],
  loading: false,
  listMonth: [],
  listSchedule: [],
  listYear: [],
};

export const getClubRanking = createAsyncThunk(
  'match-center/club-ranking',
  async ({ leagueId, sort }: { leagueId: string; sort?: string }) => {
    const result = await axios.get(`/api/report/list-league-sort/${leagueId}`, {
      params: {
        page: 0,
        size: INTERMINABLE,
        sort,
      },
    });
    const resData = result.data.map((item, index) => {
      return {
        ...item,
        position: index + 1,
      };
    });
    return resData;
  }
);

export const getPlayerRanking = createAsyncThunk(
  'match-center/player-ranking',
  async ({ leagueId, sort, size }: { leagueId: string; sort?: string; size?: number }) => {
    const result = await axios.get(`/api/matchCenter/play-ranking`, {
      params: {
        leagueId,
        page: 0,
        size,
        sort,
      },
    });
    const resData = result.data.map((item, index) => {
      return {
        ...item,
        position: index + 1,
      };
    });
    return resData;
  }
);

export const getSchedule = createAsyncThunk('match-center/match-center', async ({ leagueId, year, month, zoneId }: any) => {
  const result = await axios.get(`/api/matchCenter/schedule?leagueId=${leagueId}&year=${year}&month=${month}&zoneId=${zoneId}`);
  return result;
});

export const getLeague = createAsyncThunk('match-center/get-league', async (leagueStandardId: number) => {
  const result = await axios.get(`/api/matchCenter/leagues?leagueStandardId=${leagueStandardId}`);
  return result;
});

export const getYear = createAsyncThunk('match-center/get-year', async ({ leagueId, zoneId }: { leagueId: number; zoneId: number }) => {
  const result = await axios.get(`api/matchCenter/league-year?leagueId=${leagueId}&zoneId=${zoneId}`);
  return result;
});

export const getMonth = createAsyncThunk('match-center/get-month', async (params: ScheduleFilter) => {
  const result = await axios.get(`api/matchCenter/league-month`, { params });
  return result;
});

export const matchCenterSlice = createSlice({
  name: 'matchCenter',
  initialState: initState,
  reducers: {
    resetValueRanking(state) {
      state.listClubRanking = [];
      state.listPlayerRanking = [];
    },
    resetListSchedule(state) {
      state.listSchedule = [];
      state.loading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getClubRanking.fulfilled, (state, action) => {
        state.listClubRanking = action.payload;
        state.loading = false;
      })
      .addCase(getPlayerRanking.fulfilled, (state, action) => {
        state.listPlayerRanking = action.payload;
        state.loading = false;
      })
      .addCase(getLeague.fulfilled, (state, action) => {
        state.listLeague = action.payload.data;
      })
      .addCase(getMonth.fulfilled, (state, action) => {
        console.log(action.payload.data);
        state.listMonth = action.payload.data;
      })
      .addCase(getYear.fulfilled, (state, action) => {
        state.listYear = action.payload.data;
      })
      .addCase(getSchedule.fulfilled, (state, action) => {
        state.listSchedule = action.payload.data;
        state.loading = false;
      })
      .addCase(getPlayerRanking.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getClubRanking.pending, (state, action) => {
        state.loading = true;
      });
  },
});

const matchCenterReducer = matchCenterSlice.reducer;
export const { resetValueRanking } = matchCenterSlice.actions;
export default matchCenterReducer;
