import './index.scss';
import { Button, Card, Input, message, Row, Tag, Typography, Upload } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { ACCEPT_TYPE_IMAGE, FILE_TYPE } from 'app/config/constants';
import { InsertFileIcon, PlusIcon } from 'app/shared/util/appIcon';
import React, { useCallback, useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { validateSizeFile, validateSizeImage } from 'app/shared/util/entity-utils';
import { IAttachFile } from 'app/shared/model/notice.model';
import { CloseOutlined, PlusSquareOutlined } from '@ant-design/icons';

interface Props {
  files: IAttachFile[];
  onChange: (files: IAttachFile[]) => void;
}
const AttachFile = ({ files, onChange }: Props) => {
  const [insertIndex, setInsertIndex] = useState<any>(0);
  const [indexFile, setIndexFile] = useState<any>(0);

  useEffect(() => {
    if (files && !!files.length) {
      files.map((item, index) => setIndexFile(index));
    }
  }, [files]);

  const handleChangeFile = useCallback(
    e => {
      const file = e.target.files[0];
      const isImage = ACCEPT_TYPE_IMAGE.includes(e.target.files[0].type);
      if (validateSizeFile(file, e)) {
        const arr: IAttachFile[] = [...(files || [])];
        arr.splice(insertIndex, 0, {
          url: isImage ? URL.createObjectURL(file) : null,
          file,
          typeFile: isImage ? FILE_TYPE.IMAGE : FILE_TYPE.DOCUMENT,
          name: file.name,
        });
        onChange(arr);
      }
    },
    [insertIndex, files]
  );

  const handleRemoveFile = useCallback(
    index => {
      onChange(files.filter((item, fileIndex) => fileIndex !== index));
    },
    [files]
  );

  const handleDragEnter = e => {
    const file = e.dataTransfer.files[0];
    const isImage = ACCEPT_TYPE_IMAGE.includes(file.type);
    if (validateSizeImage(file, e)) {
      const arr: IAttachFile[] = [...(files || [])];
      arr.splice(files?.length || 0, 0, {
        url: isImage ? URL.createObjectURL(file) : null,
        file,
        typeFile: isImage ? FILE_TYPE.IMAGE : FILE_TYPE.DOCUMENT,
        name: file.name,
      });
      onChange(arr);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const dragOverHandler = e => {
    // onChange({
    //   ...e,
    //   target: {
    //     files: e.dataTransfer.files,
    //   },
    // });
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Card onDrop={handleDragEnter} onDragOver={dragOverHandler}>
      <Input id="uploadImage" value={null} hidden type="file" onChange={handleChangeFile} />
      <div className={files && !!files.length ? 'upload-container' : 'text-center'}>
        {files && !!files.length ? (
          files?.map((item, index) => (
            <div key={index} className="image-wrapper">
              {item.typeFile === FILE_TYPE.IMAGE ? (
                <div className="image-wrapper__container">
                  <img
                    style={{ width: 90, height: 90, objectFit: 'contain' }}
                    className="image-wrapper__container-attach"
                    src={item.url}
                    title={item.name}
                    onClick={() => window.open(item.url)}
                  />
                  <p
                    onClick={e => {
                      handleRemoveFile(index);
                      e.stopPropagation();
                    }}
                    className="image-wrapper__container-close"
                    style={{ padding: 6 }}
                  >
                    <CloseOutlined size={1} />
                  </p>
                </div>
              ) : (
                <div className="image-wrapper__container">
                  <Tag
                    closable
                    color="#DBE9FF"
                    style={{ padding: '10px 16px', margin: '0 10px 0 0', display: 'inline-flex', color: '#0062AE', fontSize: 14 }}
                    closeIcon={
                      <CloseOutlined size={22} style={{ color: '#0062AE', display: 'flex', alignItems: 'center', fontSize: 16 }} />
                    }
                    key={index}
                    onClose={e => {
                      handleRemoveFile(index);
                      e.stopPropagation();
                    }}
                  >
                    {item.name}
                  </Tag>
                </div>
              )}
              {/* <div className="upload-actions">
              <label
                htmlFor="uploadImage"
                onClick={() => {
                  setInsertIndex(index + 1);
                }}
                className="ant-btn ant-btn-default rounded mx-2"
              >
                <Translate contentKey="entity.action.insert" />
              </label>
              <Button
                onClick={e => {
                  handleRemoveFile(index);
                }}
                className="rounded mx-2"
                type="primary"
                danger
              >
                <Translate contentKey="entity.action.delete2">Delete</Translate>
              </Button>
            </div> */}
            </div>
          ))
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Typography style={{ marginBottom: 12 }}>{translate('leagueApp.notice.attachNote')}</Typography>
            <label
              htmlFor="uploadImage"
              onClick={() => {
                setInsertIndex(0);
              }}
            >
              <p className="upload-attach-file">
                <PlusIcon /> {translate('leagueApp.notice.attachButton')}
              </p>
            </label>
          </div>
        )}
        <div className="label-insert">
          {files && !!files.length && (
            <label
              htmlFor="uploadImage"
              onClick={() => {
                setInsertIndex(indexFile + 1);
              }}
              className="label-insert__create"
            >
              <InsertFileIcon />
            </label>
          )}
        </div>
      </div>
    </Card>
  );
};

export default AttachFile;
