import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Divider, Row, Skeleton } from 'antd';
import './next-match-card.scss';
import { AudioOutlined, CalendarOutlined, EditOutlined } from '@ant-design/icons';
import { ChervonLeft } from 'app/shared/util/appIcon';
import { useRequest } from 'ahooks';
import { nextMatchRequest, nextMatchRequestV2 } from '../../dashboard.service';
import { useAppSelector } from 'app/config/store';
import dayjs from 'dayjs';
import { translate, Storage } from 'react-jhipster';
import { Link } from 'react-router-dom';

export const NextMatchCard = props => {
  const { idLeague } = props;
  const [data, setData] = React.useState(null);
  const [href, setHref] = useState('');
  const [href1, setHref1] = useState('');
  const role = Storage.session.get('roles');
  const account = useAppSelector(state => state.authentication.account);
  // const date = dayjs.utc(new Date()).startOf('date').format();
  const date = dayjs(new Date()).startOf('date').toISOString();

  const request = useRequest(nextMatchRequestV2, {
    manual: true,
    onSuccess(res) {
      setData(res.data);
    },
    onError(err) {},
  });

  React.useEffect(() => {
    if (idLeague) {
      request.run({
        leagueId: idLeague,
        now: date,
      });
    }
  }, [idLeague]);

  const leagueImg = 'https://upload.wikimedia.org/wikipedia/vi/6/6b/UEFA_CHAMPIONS_LEAGUE.png';

  const found = account?.authorities.find(function (element) {
    return element === 'ROLE_ADMIN';
  });

  const foundRecorder = account?.authorities.find(function (element) {
    return element === 'ROLE_RECORDER';
  });

  const foundLeagueMaster = account?.authorities.find(function (element) {
    return element === 'ROLE_LEAGUE_MASTER';
  });

  return (
    <Card
      title={translate('dashboard.upcomingMatches')}
      extra={
        <div>
          {/* <Link to={href} className="primary-color">
            <span>{translate('dashboard.enterInfor')}</span> <ChervonLeft />
          </Link>{' '}
          <Link to={href1} className="primary-color" style={{ marginLeft: 16 }}>
            <span>{translate('dashboard.matchRecords')}</span> <ChervonLeft />
          </Link>{' '} */}
          {(found && data?.flag === 0 && data?.status === 1 && dayjs(data?.startDate).startOf('day').isAfter(dayjs().startOf('day'))) ||
          (foundRecorder &&
            data?.flag === 0 &&
            data?.status === 1 &&
            dayjs(data?.startDate).startOf('day').isAfter(dayjs().startOf('day'))) ? (
            ''
          ) : (data?.status === 1 && data?.flag === 1 && found) || (foundRecorder && data?.status === 1 && data?.flag === 1) ? (
            <Link to={`/real-time-recording/${data?.id}/match-record`} className="primary-color">
              <span>{translate('dashboard.matchRecords')}</span> <ChervonLeft />
            </Link>
          ) : (found && data?.status === 2) || (foundRecorder && data?.status === 2) ? (
            <Link to={`/real-time-recording/${data?.id}/match-record`} className="primary-color">
              <span>{translate('dashboard.matchRecords')}</span> <ChervonLeft />
            </Link>
          ) : (found && data?.flag === 0 && data?.status === 1) || (foundRecorder && data?.flag === 0 && data?.status === 1) ? (
            <Link to={`/match-preparetion/${data?.id}/general`} className="primary-color">
              <span>{translate('dashboard.enterInfor')}</span> <ChervonLeft />
            </Link>
          ) : (
            ''
          )}
        </div>
      }
    >
      {request.loading && <Skeleton active />}

      {!request.loading && data && (
        <Row gutter={16}>
          <Col span={24}>
            <div className={'match-wrap'}>
              <div className={'team-wrap'}>
                <div style={{ width: 120, height: 120 }}>
                  <img width={'120'} height={'120'} style={{ objectFit: 'cover' }} src={data?.ownerBase64Avt} />
                </div>
                <div className="teamName">{data?.ownerClubName || translate('leagueApp.match.homeClub')}</div>
              </div>
              <div style={{ marginTop: 50 }}>vs</div>
              <div className={'team-wrap'}>
                <div style={{ width: 120, height: 120 }}>
                  <img width={'120'} height={'120'} style={{ objectFit: 'cover' }} src={data?.awayBase64Avt} />
                </div>
                <div className="teamName">{data?.awayClubName || translate('leagueApp.match.awayClub')}</div>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div>
              <Row gutter={[12, 12]} style={{ textAlign: 'center', marginTop: 20 }}>
                <Col span={24}>
                  <span className="match-name">{data?.leagueName || translate('dashboard.league')}</span>
                </Col>
                <Col span={24}>
                  <span className="match-time">
                    {data?.startDate ? dayjs(data?.startDate).format('YYYY.MM.DD (dddd) HH:mm') : translate('dashboard.matchTime')}
                  </span>
                </Col>
                <Col span={24}>
                  <span className="match-address">{data?.stadiumName || translate('dashboard.stadium')}</span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}

      {!request.loading && !data && (
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {translate('dashboard.noData')}
        </div>
      )}
    </Card>
  );
};

export default NextMatchCard;
