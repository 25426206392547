import React from 'react';
import { Avatar, Checkbox, Col, Form, Row, Tooltip } from 'antd';
import ToastDialog from 'app/shared/util/toastDialog/ToastDialog';
import './index.scss';
import { useAppSelector } from 'app/config/store';
import { Storage, translate } from 'react-jhipster';
import { ChervonLeft, ChervonRightGray, DashboardRoleIcon } from 'app/shared/util/appIcon';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useRequest } from 'ahooks';
import { getCurrentUser } from './account.service';
import { toast } from 'react-toastify';
import { ROLE_NAME } from 'app/config/constants';
import { UserOutlined } from '@ant-design/icons';

type Props = any;

const AUTH_TOKEN_KEY = 'jhi-authenticationToken';
const ROLES_KEY = 'roles';
const LOGIN_KEY = 'login';
const USER_ID = 'userId';
const LANGUAGE = 'locale';
const AccountManagement = (props: Props) => {
  const history = useHistory();
  const [account, setAccount] = React.useState(useAppSelector(state => state.authentication.account));
  const [confirmDialog, setConfirmDialog] = React.useState<boolean>(false);
  const [toastElement, setToastElement] = React.useState<React.ReactElement>(null);
  const [previewImg, setPreviewImg] = React.useState('');
  const [dataRole, useDataRole] = React.useState([]);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const found = account?.authorities.find(function (element) {
    return element === 'ROLE_ADMIN';
  });

  const navigate = (route: string): void => {
    history.push(route);
  };
  const handleLogout = () => {
    if (Storage.local.get(AUTH_TOKEN_KEY)) {
      Storage.local.remove(AUTH_TOKEN_KEY);
    }
    if (Storage.session.get(AUTH_TOKEN_KEY)) {
      Storage.session.remove(AUTH_TOKEN_KEY);
    }
    if (Storage.session.get(ROLES_KEY)) {
      Storage.session.remove(ROLES_KEY);
    }
    if (Storage.session.get(LOGIN_KEY)) {
      Storage.session.remove(LOGIN_KEY);
    }
    if (Storage.session.get(USER_ID)) {
      Storage.session.remove(USER_ID);
    }
    if (Storage.local.get(LANGUAGE)) {
      Storage.local.set(LANGUAGE, 'ko');
    }

    window.location.reload();

    // history.push('/logout');
  };

  const requestGetAccount = useRequest(getCurrentUser, {
    manual: true,
    onSuccess(res) {
      setPreviewImg(res.data?.imageUrlBase64Avt);
      setAccount(res.data);
    },
    onError() {
      toast.error(translate('error.internalServerError'));
    },
  });

  React.useEffect(() => {
    handleRole();
    requestGetAccount.run();
  }, []);

  const handleRole = async () => {
    const data = await axios.get<any>('api/author');
    const arr = [];
    data.data.map(x => {
      arr.push({
        value: x.name,
        label: x.value,
      });
    });
    useDataRole(arr);
  };
  return (
    <Row align="middle" justify="center" style={{ flexGrow: 1 }}>
      <Col xl={8} lg={12} md={16} xs={24}>
        <div>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <div className="account-card account-info">
                <div className="account-info-avatar">
                  {previewImg ? (
                    <Avatar shape="square" size={160} src={previewImg} />
                  ) : (
                    <Avatar shape="square" size={160} icon={<UserOutlined />} />
                  )}
                </div>
                <div className="account-info-description">
                  <p className="heading-20-bold neutral-1">
                    {translate('account.title.hello')}
                    {account.fullName || ''}
                    {locale === 'ko' && '님'}
                  </p>
                  <p className="title-18-medium neutral-2">
                    <Avatar size={24} src={account?.imageUrlUnitStandard} />
                    <span style={{ marginLeft: 12 }}>{account?.unitStandardName || 'Unit standard'}</span>
                  </p>
                  <p className="title-18-medium neutral-2">
                    {' '}
                    {dataRole.length ? (
                      <>
                        {account.authorities?.map(item => ROLE_NAME.find(i => i.value === item)?.label)[0] && <DashboardRoleIcon />}
                        <span style={{ marginLeft: 12, lineHeight: '24px' }}>
                          {account.authorities
                            ?.map(item => ROLE_NAME.find(i => i.value === item)?.label)
                            ?.map(item => translate(item))
                            .join(', ')}
                        </span>
                      </>
                    ) : (
                      ''
                    )}
                  </p>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="account-card">
                <div className="account-card-header">
                  <p className="header-title heading-24-bold neutral-1">{translate('account.title.infomation')}</p>
                  <p className="header-subtitle heading-16-medium neutral-3">{translate('account.subtitle.infomation')}</p>
                </div>
                <div className="account-card-body">
                  <div
                    className="account-card-body-button"
                    onClick={() => {
                      navigate('/account-change-infomation');
                    }}
                  >
                    <span className="heading-16-semibold neutral-1">{translate('account.button.change_user_infomation')}</span>
                    <ChervonRightGray />
                  </div>
                  <div
                    className="account-card-body-button"
                    onClick={() => {
                      navigate('/account-change-password');
                    }}
                  >
                    <span className="heading-16-semibold neutral-1">{translate('account.button.change_password')}</span>
                    <ChervonRightGray />
                  </div>
                  <div
                    className="account-card-body-button"
                    onClick={() => {
                      if (found) {
                        toast.warn(translate('account.required.logout_membership_admin'));
                        return;
                      } else {
                        navigate('/account-logout');
                      }
                    }}
                  >
                    <span className="heading-16-semibold neutral-1">{translate('account.button.logout_membership')}</span>
                    <ChervonRightGray />
                  </div>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="account-card">
                <div
                  className="account-card-body-button"
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  <span className="heading-16-semibold neutral-1">{translate('account.button.logout')}</span>
                  <ChervonRightGray />
                </div>
              </div>
            </Col>
          </Row>
          <ToastDialog
            open={confirmDialog}
            onOk={() => {
              setConfirmDialog(false);
            }}
            content={toastElement}
          />
        </div>
      </Col>
    </Row>
  );
};

export default AccountManagement;
