import { Button, Form, Input, Modal, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';

// import { IMonitor } from 'app/shared/model/monitor.model';
import TextField from 'app/modules/lm-input';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import { toast } from 'react-toastify';
import { clearMsgUpdate, createEntity, getEntity, updateEntity } from './monitor.reducer';
import './monitor.scss';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';

export const MonitorAddDialog = (props: any) => {
  const { showDialog, setShowDialog, entityId, setEntityId, dataRole, dataRating, updateFilter } = props;
  const dispatch = useAppDispatch();

  const updateSuccess = useAppSelector(state => state.monitor.updateSuccess);
  const createSuccess = useAppSelector(state => state.monitor.createSuccess);
  const monitorEntity = useAppSelector(state => state.monitor.entity);

  const [isNew, setIsNew] = useState(true);

  const [entitySave, setEntitySave] = useState({
    name: '',
    role: null,
    rating: null,
  });

  useEffect(() => {
    const c = !entityId;
    setIsNew(c);
    if (!c) {
      handleUpdate(entityId);
    }
  }, [entityId]);

  useEffect(() => {
    if (updateSuccess) {
      setEntityId(null);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createSuccess) {
      setEntityId(null);
    }
  }, [createSuccess]);

  useEffect(() => {
    if (!entityId) return;
    if (monitorEntity && monitorEntity.id) {
      form.setFieldsValue({
        ...monitorEntity,
        role: +monitorEntity?.role,
        rating: monitorEntity?.rating ? +monitorEntity?.rating : null,
      });
      setEntitySave({ ...monitorEntity });
    }
  }, [monitorEntity, entityId]);

  /** save action **/
  const handleValidSubmit = e => {
    let data: any = {};
    if (!isNew && monitorEntity.id) {
      data = {
        ...monitorEntity,
      };
    }
    data.name = form.getFieldValue('name');
    data.rating = form.getFieldValue('rating');
    data.role = form.getFieldValue('role');
    data.email = form.getFieldValue('email');
    if (!isNew && data.id) {
      dispatch(updateEntity(data));
      updateFilter();
    } else {
      dispatch(createEntity(data));
      updateFilter();
    }
  };

  const [form] = Form.useForm();

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };

  const onChangeRole = e => {
    entitySave.role = e;
    // if(e !== 'REFEREE') {
    entitySave.rating = null;
    form.setFieldsValue({
      rating: null,
    });
    // }
    setEntitySave({ ...entitySave });
  };

  /** new **/
  const handleReset = () => {
    setEntitySave({
      name: '',
      role: null,
      rating: null,
    });
    // form.resetFields();
    form.setFieldsValue({
      name: '',
      role: null,
      rating: null,
    });
    // dispatch(reset);
    // setShowDialog(true);
    setEntityId(null);
  };

  /** update **/
  const handleUpdate = id => {
    dispatch(getEntity(id));
  };

  function closeDialog() {
    setShowDialog(false);
    setEntityId(null);
  }

  return (
    <React.Fragment>
      <Modal
        visible={showDialog}
        onCancel={() => closeDialog()}
        className="popup-create-stadium"
        title={
          isNew ? <Translate contentKey="leagueApp.monitor.home.createLabel" /> : <Translate contentKey="leagueApp.monitor.update.dialog" />
        }
        wrapClassName="management-wrapper-modal"
        footer={null}
      >
        <Form
          form={form}
          initialValues={{ ...entitySave }}
          onFinishFailed={values => handleFocusFieldFail(values)}
          scrollToFirstError
          name="control-hooks"
          onFinish={handleValidSubmit}
          layout="vertical"
          requiredMark={'optional'}
        >
          <Form.Item
            name="name"
            label={<h6>{translate('leagueApp.monitor.name')}</h6>}
            rules={[
              {
                required: true,
                message: translate('leagueApp.monitor.name.required'),
              },
            ]}
          >
            <TextField placeholder={translate('leagueApp.monitor.placeholder.monitorname')} />
          </Form.Item>
          {/* <Form.Item
            name="email"
            label={'* ' + translate('global.form.email.label')}
            rules={[
              {
                type: 'email',
                message: translate('userManagement.required.emailInvalid'),
              },
              { required: true, message: translate('userManagement.required.email') },
            ]}
          >
            <TextField placeholder={translate('global.form.email.label')} />
          </Form.Item> */}
          <Form.Item
            name="role"
            label={<h6>{translate('leagueApp.monitor.role')}</h6>}
            rules={[
              {
                required: true,
                message: translate('leagueApp.monitor.role.required'),
              },
            ]}
          >
            <Select
              placeholder={translate('global.choose.function')}
              allowClear
              onChange={onChangeRole}
              className="custom-prefix-select"
              suffixIcon={<SelectArrowDown />}
            >
              {dataRole.map((roles, index) => (
                <Select.Option value={roles.value} key={index}>
                  {roles.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="rating"
            label={<h6>{translate('leagueApp.monitor.rating2')}</h6>}
            rules={[
              {
                required: entitySave.role === '1' || entitySave.role === 1,
                message: translate('leagueApp.monitor.rating.required'),
              },
            ]}
          >
            <Select
              placeholder={
                entitySave.role === '1'
                  ? translate('global.choose.rating')
                  : entitySave.role === 1
                  ? translate('global.choose.rating')
                  : entitySave.role === null
                  ? translate('global.choose.rating')
                  : entitySave.role === ''
                  ? translate('global.choose.rating')
                  : ''
              }
              className="custom-prefix-select"
              allowClear
              suffixIcon={<SelectArrowDown />}
              disabled={!(entitySave.role === 1 || entitySave.role === '1')}
            >
              {dataRating?.map((rate, index) => (
                <Select.Option value={rate.value} key={index}>
                  {rate.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="management-modal-footer">
            <Button
              color="secondary"
              onClick={() => {
                closeDialog();
              }}
            >
              <Translate contentKey="register.form.close" />
            </Button>
            <Button key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }}>
              <Translate contentKey="settings.form.button" />
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default MonitorAddDialog;
