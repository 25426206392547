import { Col, Form, Radio, Row, Space } from 'antd';
import React from 'react';
import { translate } from 'react-jhipster';
import './player-ban-record.scss';

interface Props<T> {
  title?: string;
  list: T[];
  getValue: (T) => React.Key;
  getLabel: (T) => string;
  name: string;
  rules?: any[];
  secondColumn?: boolean;
}

export function PickItem<T>({ title, list = [], getValue, getLabel, name, rules = [], secondColumn }: Props<T>) {
  return (
    <Form.Item rules={rules} name={name}>
      <Radio.Group buttonStyle="solid" className="w-100">
        <Row>
          <Col xs={secondColumn ? 12 : 24}>
            <div className="column">
              <Space direction="vertical" className="w-100">
                <Radio.Button className="d-block header-list" key={-1} value={null}>
                  <span className="neutral-1 body-14-bold">{title}</span>
                </Radio.Button>
                {list
                  .filter((_, index) => index < 10)
                  .map(item => (
                    <Radio.Button className="d-block container-list" key={getValue(item)} value={getValue(item)}>
                      <span className="neutral-2 caption-12-medium">{getLabel(item)}</span>
                    </Radio.Button>
                  ))}
              </Space>
            </div>
          </Col>
          {secondColumn && (
            <Col xs={12}>
              <div className="column">
                <Space direction="vertical" className="w-100">
                  <Radio.Button className="d-block header-list" key={-1} value={null}></Radio.Button>
                  {list
                    .filter((_, index) => index >= 10)
                    .map(item => (
                      <Radio.Button className="d-block container-list" key={getValue(item)} value={getValue(item)}>
                        <span className="neutral-2 caption-12-medium">{getLabel(item)}</span>
                      </Radio.Button>
                    ))}
                </Space>
              </div>
            </Col>
          )}
        </Row>
      </Radio.Group>
    </Form.Item>
  );
}
