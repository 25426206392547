import React from 'react';
import { Button, Form, Modal, Row, Table } from 'antd';
import { translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getGoalList, uploadGoalSituation } from '../match-record/match-record.reducer';
import { IPlayerGoalRecord } from 'app/shared/model/player-goal-record.model';
import { EditableCell, EditableRow } from './edittable';

interface Props {
  onCancel: () => void;
  matchId: string;
  canEdit?: boolean;
}

const GoalSituation = ({ matchId, onCancel, canEdit }: Props) => {
  const renderTime = (roundType, process) => {
    return translate(`leagueApp.matchStatus.${roundType}`) + ' ' + Math.ceil(process / 60) + "'";
  };
  const [form] = Form.useForm();
  const goalList = useAppSelector(state => state.matchRecord.goalList);
  const list = Form.useWatch('data', form);
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const handleSubmit = React.useCallback(data => {
    dispatch(uploadGoalSituation(data.data)).then(res => res.meta.requestStatus === 'fulfilled' && onCancel());
  }, []);

  React.useEffect(() => {
    form.setFieldsValue({ data: goalList });
  }, [goalList]);

  const columns = [
    {
      title: translate('eventList.time'),
      dataIndex: 'time',
      key: 'time',
      render: (text, record, index) => renderTime(record.roundType, record.processDate),
    },
    {
      title: translate('matchOver.recordTableData.score'),
      dataIndex: 'playerDTO',
      key: 'playerDTO',
      render: (text, record, index) => (text?.uniformNumber || '') + ' ' + (text?.koName || ''),
    },
    {
      title: translate('matchOver.recordTableData.assist'),
      dataIndex: 'playerAssist',
      key: 'playerAssist',
      render: (text, record, index) => (text?.uniformNumber || '') + ' ' + (text?.koName || ''),
    },
    {
      title: translate('leagueApp.matchRecord.situation'),
      dataIndex: 'goalSituation',
      key: 'goalSituation',
      editable: true,
      onCell: (record: any) => ({
        record,
        editable: true,
        dataIndex: 'goalSituation',
        title: 'goalSituation',
        onChange: handleOnChange,
      }),
      width: '480px',
    },
  ];

  const handleOnChange = (row: any) => {
    const newGoalList = list.map(item => (item.id === row.id ? row : item));

    form.setFieldsValue({ data: newGoalList });
  };

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getGoalList(matchId));
  }, [matchId]);

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Modal
        title={translate('leagueApp.matchRecord.goalSituation')}
        className="register-record"
        visible={true}
        width={1200}
        onCancel={() => onCancel()}
        onOk={() => form.submit()}
        zIndex={1}
        footer={[
          <Button key="close" onClick={() => onCancel()}>
            <span>{translate('freeCornerKick.close')}</span>
          </Button>,
          <Button key="save" type="primary" disabled={!canEdit} onClick={() => form.submit()}>
            <span>{translate('freeCornerKick.save')}</span>
          </Button>,
        ]}
      >
        <Form.Item name="data">
          <Table
            style={{ pointerEvents: canEdit ? 'auto' : 'none' }}
            components={components}
            bordered
            dataSource={list}
            rowClassName={() => 'editable-row'}
            columns={columns}
            pagination={false}
            className="record-table"
            scroll={{
              x: 'calc(700px)',
            }}
          />
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default GoalSituation;
