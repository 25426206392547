import dayjs from 'dayjs';
import { FilterParams } from './filter.model';

export interface IParamsFilterMatch extends FilterParams {
  matchRound?: string;
  startDate?: string;
  ownerClubName?: string;
  awayClubName?: string;
  stadiumName?: string;
  recorderName?: string;
  leagueId?: number;
}

export interface IMatch {
  id?: number;
  leagueId?: number | null;
  matchRound?: number | null;
  startDate?: string | null;
  ownerClubId?: number | null;
  awayClubId?: number | null;
  stadiumId?: number | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  status?: number;
  ownerClubName?: string;
  awayClubName?: string;
  numberOfPlayers?: number;
}

export interface IDefaultMatch {
  audienceTotal: number;
  awayAvatar: string | null;
  awayClub: string | null;
  awayClubId: number;
  awayClubUniformGkId: number | null;
  awayClubUniformId: number | null;
  countMatch: number;
  createdBy: string | null;
  createdDate: string | null;
  humidity: number;
  id: number;
  lastModifiedBy: string | null;
  lastModifiedDate: string | null;
  leagueAvatar: string | null;
  leagueName: string | null;
  lstBoarCast: string[];
  matchId: number;
  ownerAvatar: string | null;
  ownerClub: string | null;
  ownerClubId: number;
  ownerClubUniformGkId: number | null;
  ownerClubUniformId: number | null;
  stadiumName: string | null;
  statusMatch: number;
  temperature: number;
  weather: string | null;
  leagueId: number;
  coachOwnerName: string;
  coachAwayName: string;
  ownerClubManager: string;
  awayClubManager: string;
  numberOfPlayers?: number;
}

export interface IFormPostMatch {
  matchId?: number | string;
  audienceTotal?: number | null;
  weather?: number | null;
  temperature?: number | null;
  humidity?: number | null;
  ownerClubId?: number | null;
  ownerClubUniformId?: number | null;
  ownerClubUniformGkId?: number | null;
  awayClubId?: number | null;
  awayClubUniformId?: number | null;
  awayClubUniformGkId?: number | null;
  createdDate?: Date;
  lstBoarCast?: string[];
  id?: number | string;
}

export interface IFormPlayerFields {
  clubId: number;
  id: number;
  matchId: number;
  orderNumber: number;
  originType: null;
  playerDTOS: IFormPlayerDTO;
  playerId: number;
  playerOrderDTOS: null;
  type: number;
}

export interface IFormPlayerDTO {
  avatar: string;
  base64Avt: null;
  birthday: string;
  clubId: number;
  clubName: string;
  createdBy: string;
  createdDate: string;
  enName: string;
  height: number;
  id: number;
  koName: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  name: null;
  nationality: string;
  position: string;
  status: number;
  uniformName: string;
  uniformNumber: number;
  weight: number;
}

export interface IFormReferee {
  createdBy: string;
  createdDate: string;
  id: number;
  email?: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  leagueId: string | number | null;
  name: string;
  rating: string | number | null;
  role: string;
  status: number;
}

export interface IFormDetailReferee {
  avarId?: number | string;
  evaluateId?: number | string;
  id?: number | string | null;
  mainMatchMonitor?: number | string;
  mainMonitorId?: number | string;
  matchId?: number | string;
  recordName?: string | number;
  sub1MonitorId?: number | string;
  sub2MonitorId?: number | string;
  sub3MonitorId?: number | string;
  tsgId?: number | string;
  varId?: number | string;
  mainMonitorName?: string;
  sub1MonitorName?: string;
  sub2MonitorName?: string;
  sub3MonitorName?: string;
  varName?: string;
  avarName?: string;
  tsgName?: string;
  mainMatchMonitorName?: string;
  evaluateName?: string;
}

export interface IDownloadTemplateMatch {
  templateType: string;
  matchIds?: number[];
  fileName: string;
  lang?: string;
  zoneId: string;
}
export interface IExportTemplateMatch {
  type: string;
  fileName?: string;
  lang: string;
  zoneId: string;
  filter: IParamsFilterMatch;
}

export interface IUploadTemplateMatch {
  leagueId: string;
  file: File;
  lang?: string;
  zoneId: string;
}

export interface IErrorUploadTemplateMatch {
  field: string;
  errorCodeImport: string;
}

export interface IItemUploadedMatchError {
  awayClubName?: string;
  errorImportDTOS?: IErrorUploadTemplateMatch[];
  matchDate?: string;
  matchIds?: string;
  matchRound?: string;
  matchTime?: string;
  number?: string;
  ownerClubName?: string;
  recorderLogin?: string;
  rowId?: string;
  stadiumName?: string;
}

export interface IFormCreateMatchs {
  matchs: IItemUploadedMatchError[];
  leagueId: number;
  zoneId: string;
  lang: string;
}

export const defaultValue: Readonly<IMatch> = {};
