import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Avatar, Button, Card, Col, Form, Input, Radio, Row, Select, Tabs, TimePicker } from 'antd';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import { ScheduleResult } from './schedule-results';
import { ClubRanking } from './club-ranking';
import { PlayerRanking } from './player-ranking';

export const MatchCenter = ({ match }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const param = useParams<{ id: string; params: string }>();
  const onChange = (path: string) => {
    push(path);
  };
  const pathLink = {
    scheduleResult: 'schedule-result',
    clubRaking: 'club-ranking',
    playerRanking: 'player-ranking',
  };
  const container = [
    {
      label: <Translate contentKey="matchCenter.scheduleResult" />,
      path: match.path.replace(':params', pathLink.scheduleResult),
      param: pathLink.scheduleResult,
    },
    {
      label: <Translate contentKey="matchCenter.teamRanking" />,
      path: match.path.replace(':params', pathLink.clubRaking),
      param: pathLink.clubRaking,
    },
    {
      label: <Translate contentKey="matchCenter.playerRanking" />,
      path: match.path.replace(':params', pathLink.playerRanking),
      param: pathLink.playerRanking,
    },
  ];
  return (
    <div>
      <Tabs onChange={onChange} type="card" activeKey={param.params}>
        {container.map((item, index) => (
          <Tabs.TabPane tab={item.label} key={item.param} />
        ))}
      </Tabs>

      <Card className="card-after-tabs">
        <Switch>
          <ErrorBoundaryRoute exact path={match.path.replace(':params', pathLink.scheduleResult)} component={ScheduleResult} />
          <ErrorBoundaryRoute exact path={match.path.replace(':params', pathLink.clubRaking)} component={ClubRanking} />
          <ErrorBoundaryRoute exact path={match.path.replace(':params', pathLink.playerRanking)} component={PlayerRanking} />
          <Redirect to={match.path.replace(':params', pathLink.scheduleResult)} />
        </Switch>
      </Card>
    </div>
  );
};
