import React, { useEffect } from 'react';
import { Translate, translate, ValidatedField, ValidatedForm, isEmail } from 'react-jhipster';
import { toast } from 'react-toastify';
import { Checkbox, Col, Row, Tooltip } from 'antd';
import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';

import { handlePasswordResetInit, reset } from '../password-reset.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CallLoginIcon, MailLoginIcon, PersonLoginIcon } from 'app/shared/util/authIcon';
import ToastDialog from 'app/shared/util/toastDialog/ToastDialog';
import { ModalPrivacy } from 'app/entities/privacy-policy/privacy-modal';
import { ModalPolicy } from 'app/entities/privacy-policy/policy-model';

export const PasswordResetInit = () => {
  const dispatch = useAppDispatch();

  const [confirmDialog, setConfirmDialog] = React.useState<boolean>(false);
  const [toastElement, setToastElement] = React.useState<React.ReactElement>(null);
  const [successElement, setSuccessElement] = React.useState<React.ReactElement>(null);
  const accountEntity = useAppSelector(state => state.passwordReset?.data);
  const [openPolicy, setOpenPolicy] = React.useState(false);
  const [openPrivacy, setOpenPrivacy] = React.useState(false);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );
  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });

  const handleBack = e => {
    history.push('/login');
  };

  const handleRegisters = ({ login, phoneNumber, fullName, codePublic }) => {
    dispatch(handlePasswordResetInit({ login, phoneNumber, fullName, codePublic }));
  };
  const history = useHistory();

  const handleValidSubmit = e => {
    handleRegisters(e);
  };
  const successMessage = useAppSelector(state => state.passwordReset?.successMessageInit);
  const resetPasswordFailInit = useAppSelector(state => state.passwordReset?.resetPasswordFailInit);
  const resetPasswordFailure = useAppSelector(state => state.passwordReset?.resetPasswordFailure);

  useEffect(() => {
    if (successMessage) {
      setToastElement(translate(successMessage));
      setSuccessElement(translate('global.common.continue'));
      setConfirmDialog(true);
    } else if (resetPasswordFailure) {
      // setToastElement(translate(resetPasswordFailInit));
      setToastElement(translate('reset.request.messages.fail'));
      setSuccessElement(null);
      setConfirmDialog(true);
    }
  }, [successMessage, resetPasswordFailInit]);

  const onFinishFail = () => {
    setToastElement(
      <>
        <Translate contentKey="login.messages.error.validate"></Translate>
      </>
    );
  };

  React.useEffect(() => {
    if (toastElement) setConfirmDialog(true);
    else setConfirmDialog(false);
  }, [toastElement]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '120px 30px 30px 30px',
        minHeight: '100vh',
      }}
    >
      <Form
        className="login-form"
        layout="vertical"
        onFinish={value => handleValidSubmit(value)}
        // onFinishFailed={() => onFinishFail()}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
        requiredMark={'optional'}
      >
        {/* {loginError ? (
        <Alert color="danger" data-cy="loginError">
          <Translate contentKey="login.messages.error.authentication">
            <strong>Failed to sign in!</strong> Please check your credentials and try again.
          </Translate>
        </Alert>
      ) : null} */}

        <Row style={{ width: '100%' }} align="middle" justify="center">
          <Col span={24}>
            <h1 style={{ fontWeight: 'bold', fontSize: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Translate contentKey="reset.request.title"></Translate>
            </h1>
          </Col>
          <Col span={24}>
            <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="neutral-3">
              <Translate contentKey="reset.request.messages.info">Enter the email address you used to register</Translate>
            </p>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item
              name="login"
              label={translate('reset.finish.email')}
              rules={[
                { required: true, message: translate('reset.request.required.email') },
                {
                  type: 'email',
                  message: translate('reset.request.required.emailInvalid'),
                },
              ]}
              data-cy="login"
            >
              <Input
                placeholder={translate('global.form.email.placeholder')}
                prefix={<MailLoginIcon />}
                // isTouched={touchedFields.username}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="fullName"
              label={translate('reset.finish.name')}
              rules={[{ required: true, message: translate('global.messages.validate.name.required') }]}
              data-cy="nameResetPassword"
            >
              <Input
                placeholder={translate('global.form.name.placeholder')}
                prefix={<PersonLoginIcon />}
                // isTouched={touchedFields.username}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="phoneNumber"
              label={translate('reset.finish.phoneNumber')}
              rules={[
                { required: true, message: translate('global.messages.validate.phonenumber.required') },
                {
                  pattern: /^[0-9]/,
                  message: translate('register.messages.validate.phonenumber.pattern'),
                },
              ]}
              style={{ marginBottom: 32 }}
              data-cy="password"
            >
              <Input
                placeholder={translate('global.form.phonenumber.placeholder')}
                style={{ width: '100%' }}
                prefix={<CallLoginIcon />}
                // iconRender={visible => (visible ? <EyeShowLoginIcon /> : <EyeHideLoginIcon />)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Button style={{ width: '100%' }} htmlType="submit" data-cy="submit" type="primary" className="auth-button">
            <Translate contentKey="reset.request.form.button">Reset password</Translate>
          </Button>
        </Row>
      </Form>
      <ToastDialog
        open={confirmDialog}
        onOk={() => {
          setConfirmDialog(false);
          if (successElement) {
            history.push('/account/reset/finish/');
          }
        }}
        buttonText={translate('confirmDialog.confirmToast')}
        content={toastElement}
      />
      <div style={{ marginTop: '20px', flexGrow: 1, display: 'flex', alignItems: 'end' }}>
        <Translate contentKey="footer.copyright" />
      </div>
      <div>
        <span
          onClick={() => {
            setOpenPolicy(true);
          }}
          className="privacy"
          style={{ marginRight: 40 }}
        >
          {translate('footer.privacyLogin')}
        </span>
        <span onClick={() => setOpenPrivacy(true)} className="privacy">
          {translate('footer.termLogin')}
        </span>
      </div>
      {openPrivacy && <ModalPrivacy openPrivacy={openPrivacy} setOpenPrivacy={setOpenPrivacy} />}
      {openPolicy && <ModalPolicy openPolicy={openPolicy} setOpenPolicy={setOpenPolicy} />}
    </div>
  );
};

export default PasswordResetInit;
