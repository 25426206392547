// convert milliseconds to mm:ss
export const handleConvertData = (value: number) => {
  let a = Math.floor(value / 1000 / 60).toString();
  let b = Math.floor((value / 1000) % 60).toString();
  if (a.length === 1) {
    a = '0' + a;
  }
  if (b.length === 1) {
    b = '0' + b;
  }
  return a + ':' + b;
};

// convert seconds to mm:ss

export const handleConvert = (value: number) => {
  let a = Math.floor(value / 60).toString();
  let b = Math.floor(value % 60).toString();
  if (a.length === 1) {
    a = '0' + a;
  }
  if (b.length === 1) {
    b = '0' + b;
  }
  return a + ':' + b;
};
