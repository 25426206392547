export interface ICategoryDataTrans {
  id?: number;
  categoryDataId?: number | null;
  code?: string | null;
  name?: string | null;
  lang?: string | null;
  status?: number | null;
}

export const defaultValue: Readonly<ICategoryDataTrans> = {};
