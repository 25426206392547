import React, { useState, useEffect } from 'react';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount, translate } from 'react-jhipster';

import { Card, Col, Form, Modal, Radio, Row, Space, Button } from 'antd';
import { PickItem } from 'app/modules/pick-item';
import PickPosition from 'app/modules/pick-position';
import MiniMapPosition from 'app/modules/mini-map-position';
import { EditOutlined } from '@ant-design/icons';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { fetchListSupportKick } from 'app/entities/player-free-corner-kick-record/player-free-corner-kick.reducer';
import { EditIcon } from 'app/shared/util/appIcon';

const SUPPORTSCORE_INFO = 'SUPPORTSCORE_INFO';
const BAN_MAX_VALUE = 1; // --- banCard value > 1 ,the player is banned ----

export const PlayerFreeCornerKickRecord = ({ form, pageChoose, ...props }) => {
  const [visiblePickPositionModal, setVisiblePickPositionModal] = React.useState(false);
  const [listSupportPlayer, setListSupportPlayer] = React.useState([]);
  const ownerLineUp = useAppSelector(state => state.matchRecord?.ownerLineUp) || [];
  const awayLineUp = useAppSelector(state => state.matchRecord?.awayLineUp) || [];
  const listSupportKick = useAppSelector(state => state.PlayerFreeCornerKick.listSupportKick) || [];
  const playerInForm = Form.useWatch('player', form);
  const dispatch = useAppDispatch();
  const locale = useAppSelector(state => state.locale.currentLocale);

  const findPlayerClub = player => {
    const ownerLineUpClub = ownerLineUp.length ? ownerLineUp[0].matchPlayerFieldDTO.clubId : null;
    const awayLineUpClub = awayLineUp.length ? awayLineUp[0].matchPlayerFieldDTO.clubId : null;
    const supportPlayers = [];
    if (player) {
      const clubId = player.matchPlayerFieldDTO.clubId;
      if (clubId === ownerLineUpClub) {
        ownerLineUp.forEach(owner => {
          if (owner.banCard <= BAN_MAX_VALUE) {
            supportPlayers.push({
              value: owner.matchPlayerFieldDTO?.playerId,
              label: `${owner.matchPlayerFieldDTO?.playerDTOS?.koName} ${
                owner.matchPlayerFieldDTO?.playerDTOS?.uniformNumber ? '- ' + owner.matchPlayerFieldDTO?.playerDTOS?.uniformNumber : ''
              }`,
            });
          }
        });
      }
      if (clubId === awayLineUpClub) {
        awayLineUp.forEach(owner => {
          if (owner.banCard <= BAN_MAX_VALUE) {
            supportPlayers.push({
              value: owner.matchPlayerFieldDTO?.playerId,
              label: `${owner.matchPlayerFieldDTO?.playerDTOS?.koName} ${
                owner.matchPlayerFieldDTO?.playerDTOS?.uniformNumber ? '- ' + owner.matchPlayerFieldDTO?.playerDTOS?.uniformNumber : ''
              }`,
            });
          }
        });
      }
    }
    const newListSupport = supportPlayers.filter(supporter => supporter.value !== player.playerId);
    setListSupportPlayer(newListSupport);
  };

  useEffect(() => {
    findPlayerClub(playerInForm);
  }, [playerInForm, ownerLineUp, awayLineUp]);

  useEffect(() => {
    dispatch(fetchListSupportKick(locale));
  }, []);

  const confirmList = [
    { value: 1, label: translate('freeCornerKick.supportConfirm.yes') },
    { value: 2, label: translate('freeCornerKick.supportConfirm.no') },
  ];

  const requiredRule = { required: true, message: '' };

  return (
    <>
      <Row {...props} wrap={false}>
        <Col className="first-header">
          <PickItem
            name="playerSupportId"
            list={listSupportPlayer}
            getValue={item => item.value}
            getLabel={item => item.label}
            title={translate('freeCornerKick.supportPlayer')}
            rules={pageChoose === SUPPORTSCORE_INFO && [requiredRule]}
          />
        </Col>
        <Col>
          <div
            className="header-list neutral-1 body-14-bold"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 44 }}
          >
            <Translate contentKey="scoreRecord.supportPosition" />
            <div
              onClick={() => {
                setVisiblePickPositionModal(true);
              }}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              <EditIcon />
              <p className="primary-color" style={{ marginLeft: 8 }}>
                {translate('freeCornerKick.select')}
              </p>
            </div>
          </div>
          <Card style={{ marginTop: 22, width: 268 }}>
            <MiniMapPosition positionValue={form.getFieldValue('detailLocationSupport')} />
          </Card>
        </Col>
        <Col>
          <PickItem
            name="supportType"
            list={listSupportKick}
            getValue={item => item.value}
            getLabel={item => item.label}
            title={translate('scoreRecord.support.title')}
            rules={pageChoose === SUPPORTSCORE_INFO && [requiredRule]}
            secondColumn={true}
          />
        </Col>
        <Col className="last-header">
          <PickItem
            name="supportConfirm"
            list={confirmList}
            getValue={item => item.value}
            getLabel={item => item.label}
            title={translate('freeCornerKick.supportConfirm.title')}
            rules={pageChoose === SUPPORTSCORE_INFO && [requiredRule]}
          />
        </Col>
      </Row>
      {visiblePickPositionModal && (
        <PickPosition
          open={visiblePickPositionModal}
          setOpen={() => setVisiblePickPositionModal(false)}
          onSubmit={detailLocationSupport => {
            form.setFieldsValue({ detailLocationSupport });
            setVisiblePickPositionModal(false);
          }}
          value={form.getFieldValue('detailLocationSupport')}
        />
      )}
    </>
  );
};

export default PlayerFreeCornerKickRecord;
