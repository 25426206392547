import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import TextField from 'app/modules/lm-input';
import { createEntity, getEntity, updateEntity } from './staff.reducer';
import { getBase64, getImage, upLoadImg, UploadType } from 'app/shared/reducers/reducer.utils';
import NumberField from 'app/modules/lm-input-number';
import { handleValidateMessageLanguageChanged } from 'app/shared/util/validate';

import SearchModalCustom from 'app/modules/seatch-modal';
import { IStaff } from 'app/shared/model/staff.model';
import axios from 'axios';
import { first } from 'lodash';
import DatePicker from 'app/modules/date-picker';
import { SelectArrowDown } from 'app/shared/util/appIcon';
import dayjs from 'dayjs';
// import { ROLE_COACH } from 'app/shared/util/constant/role.constant';
import { handleFocusFieldFail, validateSizeImage } from 'app/shared/util/entity-utils';
import { hasAnyAuthority, isRole } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import locale from 'antd/es/date-picker/locale/de_DE';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';

import 'antd/dist/antd.css';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { message, Upload } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import { ROLE_STAFF } from 'app/shared/util/constant/role-staffs.constant';
export const StaffAddDialog = (props: any) => {
  const { showDialog, setShowDialog, entityId, setIsOpen, setEntityId, licenses, updateFilter, dataClubId } = props;
  const dispatch = useAppDispatch();
  const updateSuccess = useAppSelector(state => state.staffReducer.updateSuccess);
  const createSuccess = useAppSelector(state => state.staffReducer.createSuccess);
  const staffEntity = useAppSelector(state => state.staffReducer.entity);
  const loading = useAppSelector(state => state.staffReducer.loading);
  const [searchClubModal, setSearchClubModal] = useState(false);
  const [staffs, setStaffs] = React.useState<IStaff[]>([]);
  const [dataNation, setDataNation] = useState([]);
  const { authorities, unitStandardId } = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(authorities, [AUTHORITIES.ADMIN]);
  const organizations = useAppSelector(state => state.player.organizations);
  const [disabled, setDisabled] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileListCommitment, setFileListCommitment] = useState<UploadFile[]>([]);
  const [fileListDegree, setFileListDegree] = useState<UploadFile[]>([]);
  const [fileListContract, setFileListContract] = useState<UploadFile[]>([]);
  const [fileListWorkLicense, setFileListWorkLicense] = useState<UploadFile[]>([]);
  const [fileListITC, setFileListITC] = useState<UploadFile[]>([]);

  const propsedCommitment: UploadProps = {
    onRemove(filed) {
      const index = fileListCommitment.indexOf(filed);
      const newFileListC = fileListCommitment.slice();
      newFileListC.splice(index, 1);
      setFileListCommitment(newFileListC);
    },
    beforeUpload(filed) {
      if (filed.size / 1024 / 1024 > 10) {
        message.error(<>{translate('leagueApp.staff.error.title')}</>);
      } else {
        let newFileListC = [...fileListCommitment, filed];
        newFileListC = newFileListC.slice(-1);
        setFileListCommitment(newFileListC);
      }
      return false;
    },
  };

  const propsedDegree: UploadProps = {
    onRemove(filed) {
      const index = fileListDegree.indexOf(filed);
      const newFileListC = fileListDegree.slice();
      newFileListC.splice(index, 1);
      setFileListDegree(newFileListC);
    },
    beforeUpload(filed) {
      if (filed.size / 1024 / 1024 > 10) {
        message.error(<>{translate('leagueApp.staff.error.title')}</>);
      } else {
        let newFileListC = [...fileListDegree, filed];
        newFileListC = newFileListC.slice(-1);
        setFileListDegree(newFileListC);
      }
      return false;
    },
  };

  const propsedContract: UploadProps = {
    onRemove(filed) {
      const index = fileListContract.indexOf(filed);
      const newFileListC = fileListContract.slice();
      newFileListC.splice(index, 1);
      setFileListContract(newFileListC);
    },
    beforeUpload(filed) {
      if (filed.size / 1024 / 1024 > 10) {
        message.error(<>{translate('leagueApp.staff.error.title')}</>);
      } else {
        let newFileListC = [...fileListContract, filed];
        newFileListC = newFileListC.slice(-1);
        setFileListContract(newFileListC);
      }
      return false;
    },
  };

  const propsedWorkLicense: UploadProps = {
    onRemove(filed) {
      const index = fileListWorkLicense.indexOf(filed);
      const newFileListC = fileListWorkLicense.slice();
      newFileListC.splice(index, 1);
      setFileListWorkLicense(newFileListC);
    },
    beforeUpload(filed) {
      if (filed.size / 1024 / 1024 > 10) {
        message.error(<>{translate('leagueApp.staff.error.title')}</>);
      } else {
        let newFileListC = [...fileListWorkLicense, filed];
        newFileListC = newFileListC.slice(-1);
        setFileListWorkLicense(newFileListC);
      }
      return false;
    },
  };
  const propsedITC: UploadProps = {
    onRemove(filed) {
      const index = fileListITC.indexOf(filed);
      const newFileListC = fileListITC.slice();
      newFileListC.splice(index, 1);
      setFileListITC(newFileListC);
    },
    beforeUpload(filed) {
      if (filed.size / 1024 / 1024 > 10) {
        message.error(<>{translate('leagueApp.staff.error.title')}</>);
      } else {
        let newFileListC = [...fileListITC, filed];
        newFileListC = newFileListC.slice(-1);
        setFileListITC(newFileListC);
      }
      return false;
    },
  };
  const options = [
    {
      value: 'name',
      label: translate('leagueApp.leagueClub.detail.title'),
    },
  ];
  const [previewImg, setPreviewImg] = useState('');
  const account = useAppSelector(state => state.authentication.account);
  const foundTeamManager = account?.authorities.find(function (element) {
    return element === 'ROLE_TEAM_MANAGER';
  });
  const [searchTeamManager, setSearchTeamManager] = useState(false);
  const optionClubManager = [
    {
      value: 'name',
      label: translate('leagueApp.unitStandard.name'),
    },
  ];
  const columnClubManager = [
    {
      title: <Translate contentKey="leagueApp.unitStandard.name" />,
      dataIndex: 'fullName',
      key: 'fullName',
    },
  ];
  const [teamManager, setTeamManager] = useState([]);

  const columns = [
    {
      title: <Translate contentKey="leagueApp.leagueClub.detail.title" />,
      dataIndex: 'name',
      key: 'name',
    },
  ];
  const playerEntity = useAppSelector(state => state.player.entity);

  const [isNew, setIsNew] = useState(true);
  const roles = [
    { value: ROLE_STAFF.DEPUTY_DELEGATION + '', label: translate('leagueApp.staff.role.deputyDelegation') },
    { value: ROLE_STAFF.MANAING_DIRECTOR + '', label: translate('leagueApp.staff.role.managingDirector') },
    { value: ROLE_STAFF.TEAM_MANAGER + '', label: translate('leagueApp.staff.role.teamManager') },
  ];
  const [entitySave, setEntitySave] = useState({} as IStaff);

  useEffect(() => {
    const c = !entityId;
    setIsNew(c);
    if (!c) {
      handleUpdate(entityId);
    }
  }, [entityId]);

  useEffect(() => {
    if (updateSuccess) {
      setEntityId(null);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createSuccess) {
      setEntityId(null);
    }
  }, [createSuccess]);

  useEffect(() => {
    if (!entityId) return;
    if (staffEntity && staffEntity.id) {
      form.setFieldsValue({
        ...staffEntity,
        birthday: staffEntity && staffEntity.birthday ? dayjs(staffEntity.birthday) : null,
      });
      if (staffEntity.base64FileDegree) {
        setFileListDegree([{ ...fileList[0], url: staffEntity.base64FileDegree, name: staffEntity.fileDegree, status: 'done' }]);
      }

      if (staffEntity.base64FileCommitment) {
        setFileListCommitment([
          { ...fileListCommitment[0], url: staffEntity.base64FileCommitment, name: staffEntity.fileCommitment, status: 'done' },
        ]);
      }
      if (staffEntity.base64FileContract) {
        setFileListContract([
          { ...fileListContract[0], url: staffEntity.base64FileContract, name: staffEntity.fileContract, status: 'done' },
        ]);
      }
      if (staffEntity.base64FileWorkLicense) {
        setFileListWorkLicense([
          {
            ...fileListWorkLicense[0],
            url: staffEntity.base64FileWorkLicense,
            name: staffEntity.fileWorkLicense,
            status: 'done',
          },
        ]);
      }
      if (staffEntity.base64FileITC) {
        setFileListITC([{ ...fileListITC[0], url: staffEntity.base64FileITC, name: staffEntity.fileITC, status: 'done' }]);
      }
      setEntitySave({ ...staffEntity });
    }
  }, [staffEntity, entityId]);

  const getClubs = async (clubName?: string) => {
    try {
      const fetchClubs = await axios.get<any>(`api/assign-club-for-player?searchName=${clubName || ''}`);
      setStaffs(fetchClubs.data);
    } catch (error) {
      setStaffs([]);
    }
  };

  /** save action **/
  const handleValidSubmit = async e => {
    let data: IStaff = {};

    if (!isNew && staffEntity.id) {
      data = {
        ...staffEntity,
      };
    }
    if (file) {
      const bodyImg = new FormData();
      bodyImg.append('file', file);
      bodyImg.append('uploadType', 'AVATAR_STAFF');
      const result = await upLoadImg(bodyImg);
      data.avatar = result.data;
    }

    if (fileListCommitment.length > 0 && !!fileListCommitment[0].size) {
      const bodyImg = new FormData();
      bodyImg.append('file', fileListCommitment[0] as RcFile);
      bodyImg.append('uploadType', 'COMMITMENT_STAFF');
      const result = await upLoadImg(bodyImg);
      data.fileCommitment = result.data;
    }

    if (fileListDegree.length > 0 && !!fileListDegree[0].size) {
      const bodyImg = new FormData();
      bodyImg.append('file', fileListDegree[0] as RcFile);
      bodyImg.append('uploadType', 'DEGREE_STAFF');
      const result = await upLoadImg(bodyImg);
      data.fileDegree = result.data;
    }
    if (fileListWorkLicense.length > 0 && !!fileListWorkLicense[0].size) {
      const bodyImg = new FormData();
      bodyImg.append('file', fileListWorkLicense[0] as RcFile);
      bodyImg.append('uploadType', 'WORK_LICENSE_STAFF');
      const result = await upLoadImg(bodyImg);
      data.fileWorkLicense = result.data;
    }
    if (fileListITC.length > 0 && !!fileListITC[0].size) {
      const bodyImg = new FormData();
      bodyImg.append('file', fileListITC[0] as RcFile);
      bodyImg.append('uploadType', 'ITC_STAFF');
      const result = await upLoadImg(bodyImg);
      data.fileITC = result.data;
    }

    if (fileListContract.length > 0 && !!fileListContract[0].size) {
      const bodyImg = new FormData();
      bodyImg.append('file', fileListContract[0] as RcFile);
      bodyImg.append('uploadType', 'CONTRACT_STAFF');
      const result = await upLoadImg(bodyImg);
      data.fileContract = result.data;
    }
    data.name = form.getFieldValue('name');
    data.birthday = form.getFieldValue('birthday');
    data.birthPlace = form.getFieldValue('birthPlace');
    data.nationality = form.getFieldValue('nationality');
    data.position = form.getFieldValue('position');
    data.idNumber = form.getFieldValue('idNumber');
    data.email = form.getFieldValue('email');
    data.phone = form.getFieldValue('phone');
    data.degree = form.getFieldValue('degree');
    data.homeAddress = form.getFieldValue('homeAddress');
    data.userId = form.getFieldValue('userId');
    if (!isNew && data.id) {
      dispatch(updateEntity(data));
      updateFilter();
    } else {
      dispatch(createEntity(data));
      updateFilter();
    }
  };

  const [form] = Form.useForm();
  const [file, setFile] = useState<File>(null);

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };
  function saveData(data: any, oldAvatar) {
    data.name = form.getFieldValue('name');
    data.birthday = form.getFieldValue('birthday');
    data.birthPlace = form.getFieldValue('birthPlace');
    data.nationality = form.getFieldValue('nationality');
    data.position = form.getFieldValue('position');
    data.idNumber = form.getFieldValue('idNumber');
    data.email = form.getFieldValue('email');
    data.phone = form.getFieldValue('phone');
    data.position = form.getFieldValue('position');
    data.degree = form.getFieldValue('degree');
    data.homeAddress = form.getFieldValue('homeAddress');
    data.fileDegree = form.getFieldValue('fileDegree');
    data.fileContract = form.getFieldValue('fileContract');
    data.fileCommitment = form.getFieldValue('fileCommitment');
    data.fileWorkLicense = form.getFieldValue('fileWorkLicense');
    data.fileITC = form.getFieldValue('fileITC');
    data.base64FileDegree = form.getFieldValue('base64FileDegree');
    data.base64FileContract = form.getFieldValue('base64FileContract');
    data.base64FileCommitment = form.getFieldValue('base64FileCommitment');
    data.base64FileWorkLicense = form.getFieldValue('base64FileWorkLicense');
    data.base64FileITC = form.getFieldValue('base64FileITC');
    data.userId = form.getFieldValue('userId');
    data.clubName = form.getFieldValue('clubName');

    dispatch(createEntity(data)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        setConfirmModal(false);
        setIsOpen(false);
        handleReset();
      }
      setDisabled(false);
    });
  }

  const handleCreateClub = () => {
    setDisabled(true);
    const data: any = {};
    let oldAvatar = playerEntity.avatar;
    if (file) {
      const bodyImg = new FormData();
      bodyImg.append('file', file);
      bodyImg.append('uploadType', 'AVATAR_PLAYER');
      upLoadImg(bodyImg).then(result => {
        oldAvatar = result.data;
        saveData(data, oldAvatar);
        updateFilter();
      });
    } else {
      saveData(data, oldAvatar);
      updateFilter();
    }
  };
  const handlePreviewAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileImg = e.target.files[0];
    if (validateSizeImage(fileImg, e)) {
      setFile(fileImg);
      getBase64(fileImg, result => {
        setPreviewImg(result);
      });
    }
  };
  const handleCloseModal = () => {
    handleReset();
    setIsOpen(false);
  };
  /** new **/
  const handleReset = () => {
    setEntitySave({});
    // form.resetFields();
    form.setFieldsValue({});
    // dispatch(reset);
    // setShowDialog(true);
    setEntityId(null);
  };

  /** update **/
  const handleUpdate = id => {
    dispatch(getEntity(id));
  };

  function closeDialog() {
    setShowDialog(false);
    setEntityId(null);
  }
  const localeWeb = useAppSelector(state => state.locale.currentLocale);

  const getClubManager = async (searchName?: string) => {
    try {
      const fetchClubs = await axios.get(`api/staffs/assign-club-for-staff?searchName=${searchName || ''}`);
      setTeamManager(fetchClubs?.data);
    } catch (error) {
      setTeamManager([]);
    }
  };
  const managementId = Form.useWatch('managementId', form);

  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
    handleNationally({ lang: localeWeb });
  }, [localeWeb]);
  const handleNationally = async ({ lang }: any) => {
    const dataNationally = await axios.get<any>(`api/utils/countries?lang=${lang}`);
    const arrNation = [];
    dataNationally.data.map(a =>
      arrNation.push({
        value: a.name,
        label: a.name,
      })
    );
    setDataNation(arrNation);
  };
  return (
    <React.Fragment>
      <Modal
        visible={showDialog}
        onCancel={() => closeDialog()}
        title={
          isNew ? <Translate contentKey="leagueApp.staff.home.createLabel" /> : <Translate contentKey="leagueApp.staff.update.dialog" />
        }
        wrapClassName="management-wrapper-modal"
        footer={null}
        width={900}
      >
        <Form
          form={form}
          initialValues={{ ...entitySave }}
          onFinishFailed={values => handleFocusFieldFail(values)}
          scrollToFirstError
          name="control-hooks"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onFinish={handleValidSubmit}
          layout="vertical"
          requiredMark={'optional'}
        >
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item
                name="avatar"
                label={<h6>* {translate('leagueApp.staff.avatar')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.staff.required.avatar'),
                  },
                ]}
              >
                <div>
                  <div className="input-width">
                    <label htmlFor="uploadImage" className="upload-file">
                      {previewImg ? (
                        <img src={previewImg} alt="logo" style={{ width: '100%', height: '100%' }} />
                      ) : entityId && staffEntity && staffEntity.id && !!staffEntity.base64Avatar ? (
                        <img src={staffEntity.base64Avatar} alt="logo" style={{ width: '100%', height: '100%' }} />
                      ) : (
                        <span>+ {translate('leagueApp.staff.image')}</span>
                      )}
                    </label>
                    <span style={{ fontSize: '12px' }} className="primary-color">
                      <Translate contentKey="leagueApp.coach.home.uploadimg" />
                    </span>

                    <Input id="uploadImage" value={null} type="file" hidden accept="image/*" onChange={handlePreviewAvatar} />
                  </div>
                </div>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="name"
                label={<h6>* {translate('leagueApp.staff.name')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.staff.required.name'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.staff.placeholder.name')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.staff.birthday')}</h6>}
                name="birthday"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || new Date() >= value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(translate('leagueApp.staff.required.birthday')));
                    },
                  }),
                  {
                    required: true,
                    message: translate('leagueApp.staff.required.birthdayRequired'),
                  },
                ]}
              >
                <DatePicker
                  locale={{
                    ...locale,
                    lang: {
                      ...locale.lang,
                      today: translate('entity.action.today'),
                    },
                  }}
                  style={{ width: '100%' }}
                  placeholder={translate('leagueApp.staff.placeholder.birthday')}
                  format="YYYY-MM-DD"
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="birthPlace"
                label={<h6>* {translate('leagueApp.staff.birthPlace')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.staff.required.birthPlace'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.staff.placeholder.birthPlace')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="idNumber"
                label={<h6>* {translate('leagueApp.staff.idNumber')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.staff.required.idNumber'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.staff.placeholder.idNumber')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="nationality"
                label={<h6>* {translate('leagueApp.staff.nationality')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.staff.required.nationality'),
                  },
                ]}
              >
                <Select
                  placeholder={translate('leagueApp.staff.placeholder.nationality')}
                  className="custom-prefix-select"
                  allowClear
                  suffixIcon={<SelectArrowDown />}
                  showSearch
                  options={dataNation}
                  filterOption={(inputValue: string, option?: any) => {
                    return (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase());
                  }}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="position"
                label={<h6>* {translate('leagueApp.staff.position')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.staff.required.position'),
                  },
                ]}
              >
                <Select
                  placeholder={translate('leagueApp.staff.placeholder.position')}
                  className="custom-prefix-select"
                  allowClear
                  suffixIcon={<SelectArrowDown />}
                >
                  {roles?.map((role, index) => (
                    <Select.Option value={role.value} key={index}>
                      {role.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="phone"
                label={<h6>* {translate('leagueApp.staff.phone')}</h6>}
                rules={[
                  { required: true, message: translate('global.messages.validate.phonenumber.required') },
                  {
                    pattern: /^[0-9]/,
                    message: translate('register.messages.validate.phonenumber.pattern'),
                  },
                ]}
                style={{ marginBottom: 32 }}
              >
                <NumberField negative={true} placeholder={translate('leagueApp.staff.placeholder.phone')} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.staff.email')}</h6>}
                name="email"
                rules={[
                  { required: true, message: translate('global.messages.validate.email.required') },
                  {
                    type: 'email',
                    message: translate('global.messages.validate.email.invalid'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.staff.placeholder.email')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="homeAddress" label={<h6>{translate('leagueApp.staff.homeAddress')}</h6>}>
                <TextField placeholder={translate('leagueApp.staff.placeholder.homeAddress')} />
              </Form.Item>
            </Col>
            {isAdmin && (
              <Col span={12}>
                <Form.Item
                  name="clubName"
                  label={<h6>* {translate('leagueApp.staff.clubId')}</h6>}
                  rules={[
                    {
                      required: true,
                      message: translate('leagueApp.staff.required.clubId'),
                    },
                  ]}
                >
                  <Input
                    placeholder={translate('leagueApp.staff.placeholder.clubId')}
                    suffix={
                      <Button onClick={() => setSearchTeamManager(true)} className="position-absolute" style={{ right: '8px' }}>
                        <Translate contentKey="leagueApp.league.choose" />
                      </Button>
                    }
                    readOnly
                    disabled
                  ></Input>
                </Form.Item>
              </Col>
            )}

            <Col span={12}>
              <Form.Item name="degree" label={<h6>{translate('leagueApp.staff.degree')}</h6>}>
                <TextField placeholder={translate('leagueApp.staff.placeholder.degree')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<h6>{translate('leagueApp.staff.fileDegree')}</h6>} name="fileDegree">
                <Upload {...propsedDegree} fileList={fileListDegree}>
                  <div className="btn_file">
                    <Button
                      style={{
                        display: 'flex',
                        width: '100%',
                        padding: '14px',
                        height: '100%',
                        backgroundColor: 'transparent',
                        color: '#D9D9D9',
                        fontWeight: '300',
                        justifyContent: 'left',
                      }}
                      icon={<UploadOutlined />}
                    >
                      {' '}
                      {translate('leagueApp.staff.placeholder.fileDegree')}
                    </Button>
                  </div>
                </Upload>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.staff.fileCommitment')}</h6>}
                name="fileCommitment"
                rules={[{ required: true, message: translate('leagueApp.staff.required.fileCommitment') }]}
              >
                <Upload {...propsedCommitment} fileList={fileListCommitment}>
                  <div className="btn_file">
                    <Button
                      style={{
                        display: 'flex',
                        width: '100%',
                        padding: '14px',
                        height: '100%',
                        backgroundColor: 'transparent',
                        color: '#D9D9D9',
                        fontWeight: '300',
                        justifyContent: 'left',
                      }}
                      icon={<UploadOutlined />}
                    >
                      {' '}
                      {translate('leagueApp.staff.placeholder.fileCommitment')}
                    </Button>
                  </div>
                </Upload>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.staff.fileContract')}</h6>}
                name="fileContract"
                rules={[{ required: true, message: translate('leagueApp.staff.required.fileContract') }]}
              >
                <Upload {...propsedContract} fileList={fileListContract}>
                  <div className="btn_file">
                    <Button
                      style={{
                        display: 'flex',
                        width: '100%',
                        padding: '14px',
                        height: '100%',
                        backgroundColor: 'transparent',
                        color: '#D9D9D9',
                        fontWeight: '300',
                        justifyContent: 'left',
                      }}
                      icon={<UploadOutlined />}
                    >
                      {' '}
                      {translate('leagueApp.staff.placeholder.fileContract')}
                    </Button>
                  </div>
                </Upload>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<h6>* {translate('leagueApp.staff.fileWorkLicense')}</h6>}
                name="fileWorkLicense"
                rules={[{ required: true, message: translate('leagueApp.staff.required.fileWorkLicense') }]}
              >
                <Upload {...propsedWorkLicense} fileList={fileListWorkLicense}>
                  <div className="btn_file">
                    <Button
                      style={{
                        display: 'flex',
                        width: '100%',
                        padding: '14px',
                        height: '100%',
                        backgroundColor: 'transparent',
                        color: '#D9D9D9',
                        fontWeight: '300',
                        justifyContent: 'left',
                      }}
                      icon={<UploadOutlined />}
                    >
                      {' '}
                      {translate('leagueApp.staff.placeholder.fileWorkLicense')}
                    </Button>
                  </div>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<h6>{translate('leagueApp.staff.fileITC')}</h6>} name="fileITC">
                <Upload {...propsedITC} fileList={fileListITC}>
                  <div className="btn_file">
                    <Button
                      style={{
                        display: 'flex',
                        width: '100%',
                        padding: '14px',
                        height: '100%',
                        backgroundColor: 'transparent',
                        color: '#D9D9D9',
                        fontWeight: '300',
                        justifyContent: 'left',
                      }}
                      icon={<UploadOutlined />}
                    >
                      {' '}
                      {translate('leagueApp.staff.placeholder.fileITC')}
                    </Button>
                  </div>
                </Upload>
              </Form.Item>
            </Col>

            <Col span={24}>
              <div className="management-modal-footer">
                <Button key="back" onClick={closeDialog}>
                  <Translate contentKey="register.form.close" />
                </Button>
                <Button loading={loading} key="submit" htmlType="submit" type="primary" style={{ marginLeft: 12 }} disabled={disabled}>
                  <Translate contentKey="settings.form.button" />
                </Button>
              </div>
            </Col>
            <Form.Item name="userId">
              <Input type={'hidden'}></Input>
            </Form.Item>
          </Row>
        </Form>
        {searchTeamManager && (
          <SearchModalCustom
            onClose={() => {
              setSearchTeamManager(false);
            }}
            options={optionClubManager}
            open={searchTeamManager}
            list={teamManager}
            columns={columnClubManager}
            onFetchData={(data: { name?: string }) => getClubManager(data?.name)}
            multiple={false}
            onAccept={(selected: any[]) => {
              form.setFieldsValue({
                managementId: selected.map(item => item?.id),
                userId: first(selected)?.id,
                clubName: first(selected)?.fullName,
              });
              setSearchTeamManager(false);
            }}
            title={translate('leagueApp.staff.clubId')}
            subTitle={translate('leagueApp.staff.placeholder.clubId')}
            selected={managementId}
          />
        )}
        {confirmModal && (
          <ConfirmationDialog
            open={confirmModal}
            message={<h6>{translate('leagueApp.player.duplicate')}</h6>}
            onClose={() => setConfirmModal(false)}
            onConfirm={() => handleCreateClub()}
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

export default StaffAddDialog;
