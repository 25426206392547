import { actionRecord } from 'app/shared/util/constant';
import { translate } from 'react-jhipster';

export const dataFake = [
  {
    serial: 1,
    half: 'Full time',
    time: '00:00',
    team: 'Incheon',
    no: 9,
    player: 'C.Thu 9',
  },
];

export interface IMatchListEventRecord {
  ownerBall: number;
  matchId: number;
  matchPenantyPlayerRecordDTOS: [];
}

export const apiFetchListRecord = {
  SUBSTITUTION: '',
  WARNING: '',
  OFFSIDE: '',
  FREE_KICK: 'api/player-kick-records/get-list',
  GOAL_KICK: '',
  PENALTY_KICK: '',
  SHOOTING: '',
  FOUL: '',
  FORMATION: '',
  PENALTY_SHOOT_OUT: '',
  SCORE: '',
};

export const listUrlSubscribe = {
  SUBSTITUTION: '',
  WARNING: '',
  OFFSIDE: '',
  FREE_KICK: '',
  GOAL_KICK: '',
  PENALTY_KICK: '',
  SHOOTING: '',
  FOUL: '',
  FORMATION: '',
  PENALTY_SHOOT_OUT: '',
  SCORE: '',
};

export const urlWs = (mode: string, action: 'delete' | 'edit', matchId: number, playerId: number) => {
  switch (mode) {
    case actionRecord.SUBSTITUTION:
      return `/record/player-inout/${action}/${matchId}/${playerId}`;
    case actionRecord.GOAL_KICK:
      return `/record/player-goal-kick/${action}/${matchId}/${playerId}`;
    case actionRecord.FREE_KICK:
      return `/record/player-kick/${action}/${matchId}/${playerId}`;
    case actionRecord.PENALTY_KICK:
      return `/record/player-penalty/${action}/${matchId}/${playerId}`;
    case actionRecord.SHOOTING:
      return `/record/player-shoot/${action}/${matchId}/${playerId}`;
    case actionRecord.SCORE:
      return `/record/player-goal/${action}/${matchId}/${playerId}`;
    case actionRecord.FOUL:
      return `/record/player-foul/${action}/${matchId}/${playerId}`;
    case actionRecord.OFFSIDE:
      return `/record/player-offside/${action}/${matchId}/${playerId}`;
    case actionRecord.WARNING:
      return `/record/player-ban/${action}/${matchId}/${playerId}`;
    case actionRecord.PENALTY_SHOOT_OUT:
      return `/record/player-penalty/${action}/${matchId}/${playerId}`;
    case actionRecord.CORNER_KICK:
      return `/record/player-conner/${action}/${matchId}/${playerId}`;
    case actionRecord.ASSIST:
      return `/record/assist-goal-record/${action}/${matchId}/${playerId}`;
    default:
      return '';
  }
};

export const patternMatchTime = /(\d+):([0-5][0-9])$/;

export const HALF_VALUE = {
  FIRST: 1,
  SECOND: 2,
  EXTRA_FIRST: 3,
  EXTRA_SECOND: 4,
  MIN_START_MINUTE_FIRST: 0,
  MIN_START_MINUTE_SECOND: 45,
  MIN_START_MINUTE_EXTRA_FIRST: 90,
  MIN_START_MINUTE_EXTRA_SECOND: 105,
};

export const SECOND_OF_MINUTE = 60;

export const renderTime = (processDate = 0, roundType = 0, icon = ':', timeOfRound = 45, timeOfRoundExtra = 15) => {
  const second = (): number => {
    // if (roundType === 2) {
    //   return processDate + timeOfRound * SECOND_OF_MINUTE;
    // }
    // if (roundType === 3) {
    //   return processDate + timeOfRound * 2 * SECOND_OF_MINUTE;
    // }
    // if (roundType === 4) {
    //   return processDate + (timeOfRound * 2 + timeOfRoundExtra) * SECOND_OF_MINUTE;
    // }
    return processDate;
  };
  const secondValue = `${second() % SECOND_OF_MINUTE}`.length < 2 ? `0${second() % SECOND_OF_MINUTE}` : second() % SECOND_OF_MINUTE;
  return `${
    Math.floor(second() / SECOND_OF_MINUTE) < 9 ? '0' + Math.floor(second() / SECOND_OF_MINUTE) : Math.floor(second() / SECOND_OF_MINUTE)
  }${icon}${secondValue}`;
  // return `${Math.floor(second() / SECOND_OF_MINUTE)}:${secondValue}`;
};

export const rulesMin = (formData, timeOfRound, timeOfRoundExtra) => {
  const minute = 0;
  // if (formData.roundType === HALF_VALUE.FIRST) {
  //   minute = 0;
  // }
  // if (formData.roundType === HALF_VALUE.SECOND) {
  //   minute = timeOfRound;
  // }
  // if (formData.roundType === HALF_VALUE.EXTRA_FIRST) {
  //   minute = 2 * timeOfRound;
  // }
  // if (formData.roundType === HALF_VALUE.EXTRA_SECOND) {
  //   minute = 2 * timeOfRound + timeOfRoundExtra;
  // }
  return validateMin(formData.time, minute, formData.roundType, timeOfRound, timeOfRoundExtra);
};

export const validateMin = (value, minute, roundType, timeOfRound, timeOfRoundExtra) => {
  if (!value) {
    return Promise.reject(translate('eventList.messagePatten'));
  } else {
    if (patternMatchTime.test(value)) {
      if (handleConvertValue(value) < minute * SECOND_OF_MINUTE) {
        if (roundType === HALF_VALUE.SECOND) {
          return Promise.reject(translate('eventList.minMinute', { minute: timeOfRound }));
        }
        if (roundType === HALF_VALUE.EXTRA_FIRST) {
          return Promise.reject(translate('eventList.minMinute', { minute: timeOfRound * 2 }));
        }
        if (roundType === HALF_VALUE.EXTRA_SECOND) {
          return Promise.reject(translate('eventList.minMinute', { minute: timeOfRound * 2 + timeOfRoundExtra }));
        }
        return Promise.reject(translate(`leagueApp.matchRate.${minute}`));
      }
      return Promise.resolve();
    } else {
      return Promise.reject(translate('eventList.messagePatten'));
    }
  }
};

const handleConvertValue = (value: string) => {
  if (value && value?.includes(':')) {
    return Number(value.split(':')[0]) * SECOND_OF_MINUTE + Number(value.split(':')[1]);
  }
};

export const caculateProcessDate = (roundType = 1, time = '') => {
  const secondValue = handleConvertValue(time);
  if (roundType === HALF_VALUE.SECOND) {
    return secondValue - HALF_VALUE.MIN_START_MINUTE_SECOND * SECOND_OF_MINUTE;
  }
  if (roundType === HALF_VALUE.EXTRA_FIRST) {
    return secondValue - HALF_VALUE.MIN_START_MINUTE_EXTRA_FIRST * SECOND_OF_MINUTE;
  }
  if (roundType === HALF_VALUE.EXTRA_SECOND) {
    return secondValue - HALF_VALUE.MIN_START_MINUTE_EXTRA_SECOND * SECOND_OF_MINUTE;
  }
  return secondValue;
};

export const convertToNewDataTable = (data = []) => {
  return data.map(row => ({ ...row, time: renderTime(row.processDate, row.roundType) }));
};

export const checkMinTime = (form, record, timeOfRound, timeOfRoundExtra) => {
  const formData = form.getFieldsValue();
  return { validator: () => rulesMin({ ...record, ...formData }, timeOfRound, timeOfRoundExtra) };
};
