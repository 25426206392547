export interface IClubOfficer {
  id?: number;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
  status?: number | null;
  staffId?: any;
  league?: string;
  leagueId?: string;
  clubId?: string;
  teamId?: string;
  userId?: string;
  role?: string | null;
  fileCommitment?: string | null;
  base64FileCommitment?: string | null;
  fileListCommitment?: string | null;
  notEdit?: boolean | null | false;
}

export const defaultValue: Readonly<IClubOfficer> = {};
