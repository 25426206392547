import categoryData from 'app/entities/category-data/category-data.reducer';
import categoryDataTrans from 'app/entities/category-data-trans/category-data-trans.reducer';
import club from 'app/entities/club/club.reducer';
import monitor from 'app/entities/monitor/monitor.reducer';
import player from 'app/entities/player/player.reducer';
import stadium from 'app/entities/stadium/stadium.reducer';
import leagueStandard from 'app/entities/league-standard/league-standard.reducer';
import unitStandard from 'app/entities/unit-standard/unit-standard.reducer';
import league from 'app/entities/league/league.reducer';
import PlayerAcademic from 'app/entities/player-academic/player-academic.reducer';
import PlayerUniform from 'app/entities/club-uniform/club-uniform.reducer';
import match from 'app/entities/match/match.reducer';
import leagueRecorder from 'app/entities/league-recorder/league-recorder.reducer';
import PlayerClub from 'app/entities/player-club/player-club.reducer';
import LeagueAttend from 'app/entities/league-attend/league-attend.reducer';
import matchRecord from 'app/entities/match-record//match-record.reducer';

import LeagueMonitor from 'app/entities/league-monitor/league-monitor.reducer';
import LeagueClub from 'app/entities/league-club/league-club.reducer';

import getLeagueStandardMultiSelect from './league/get-league-standard.reducer';
import matchBasicInfoReducer from './match-basic-info/match-basic-info.reducer';
import LeagueMatch from './league-match/league-match.reducer';
import getClubMultiSelect from './league-match/get-club-modal-reducer';
import assignRecorderMultiSelect from './league-recorder/get-free-recoder.reducer';
import PlayerTalent from 'app/entities/player-talent/player-talent.reducer';
import PlayerTourament from 'app/entities/player-tourament/player-tourament.reducer';
import PlayerOnMatch from 'app/entities/player-on-match/player-on-match.reducer';
import PlayerFreeCornerKick from 'app/entities/player-free-corner-kick-record/player-free-corner-kick.reducer';
import matchTime from './match-time/match-time.reducer';
import playerFoulReducer from './player-foul-record/player-foul-record.reducer';
import getFreeClubMultiSelect from './league-club/list-league-club-free.reducer';
import assignMonitorMultiSelect from './league-monitor/get-list-monitor-free.reducer';
import FreePlayerClub from './player-club/get-free-player.reducer';
import playerLeague from './player-league/player-league.reducer'
import PenaltyShootOut from './penalty-shoot-out/penalty-shoot-out.reducer';
import recordRoomReducer from './record-room/recordRoom.reducer';
import MatchOver from './match-over/match-over.reducer';
import playerBan from './player-ban-record/player-ban-record.reducer';
import favoriteTournament from 'app/modules/dashboard/components/favorite-card/tourament-favorite/tournament-favorite.reducer';
import favouriteClub from 'app/modules/dashboard/components/favorite-card/club-favorite/favourite-club.reducer';
import favoritePlayer from 'app/modules/dashboard/components/favorite-card/player-favorite/player-favourite.reducer';
import favoriteMatch from 'app/modules/dashboard/components/favorite-card/match-favorite/match-favourite.reducer';
import dashboardReducer from 'app/modules/components/dashboar/main.reducer';
import playerCornerKickRecordReducer from './player-corner-kick-record/player-corner-kick-record.reducer';
import assistGoalRecordReducer from './assist-goal-record/assist-goal-record.reducer';
import noticeReducer from './notice/notice.reducer';
import guideReducer from './guide/guide.reducer';
import answerReducer from './question-and-answer/answer.reducer';
import questionAnswerReducer from './question-and-answer/question-answer.reducer';
import matchCenterReducer from './match-center/match-center.reducer';
import coachReducer from './coach/coach.reducer';
import playerAgentReducer from './player-agent/player-agent.reducer';
import staffReducer from './staff/staff.reducer';
import clubOfficerReducer from './club-officer/club-officer.reducer';
import userManagementReducer from './club-management/club-management.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  playerLeague,
  FreePlayerClub,
  assignMonitorMultiSelect,
  getFreeClubMultiSelect,
  PlayerTourament,
  PlayerTalent,
  PlayerOnMatch,
  assignRecorderMultiSelect,
  getClubMultiSelect,
  LeagueMatch,
  getLeagueStandardMultiSelect,
  LeagueClub,
  LeagueMonitor,
  LeagueAttend,
  leagueRecorder,
  match,
  PlayerUniform,
  categoryData,
  categoryDataTrans,
  unitStandard,
  club,
  monitor,
  PlayerAcademic,
  player,
  stadium,
  leagueStandard,
  league,
  PlayerClub,
  matchRecord,
  matchBasicInfoReducer,
  PlayerFreeCornerKick,
  matchTime,
  playerFoulReducer,
  PenaltyShootOut,
  recordRoomReducer,
  MatchOver,
  playerBan,
  favoriteTournament,
  favouriteClub,
  favoritePlayer,
  favoriteMatch,
  dashboard: dashboardReducer,
  playerCornerKickRecord: playerCornerKickRecordReducer,
  assistGoalRecord: assistGoalRecordReducer,
  notice: noticeReducer,
  guide: guideReducer,
  questionAnswerReducer,
  answerReducer,
  matchCenterReducer,
  coachReducer,
  playerAgentReducer,
  staffReducer,
  clubOfficerReducer,
  userManagementReducer,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
