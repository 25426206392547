import { Input, InputProps } from 'antd';
import React from 'react';

const InputTime = ({ ...props }: InputProps) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    return !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight'].includes(e.key);
  };
  return (
    <Input
      {...props}
      onKeyDown={e => {
        if (handleKeyDown(e)) {
          e.preventDefault();
        }
      }}
    />
  );
};

export default InputTime;
