import { FilterParams } from './filter.model';

export interface IUser {
  id?: any;
  login?: string;
  fullName?: string;
  activated?: boolean;
  langKey?: string;
  createdBy?: string;
  createdDate?: Date | null;
  lastModifiedBy?: string;
  lastModifiedDate?: Date | null;
  password?: string;
  unitCode?: string;
  phoneNumber?: string;
  authorities?: Array<string>;
  unitStandardId?: number;
  unitStandardName?: string;
  authorityDTOS?: Array<any>;
  searchClub?: boolean;
}

export const defaultValue: Readonly<IUser> = {
  id: '',
  login: '',
  fullName: '',
  activated: true,
  langKey: '',
  authorities: [],
  createdBy: '',
  createdDate: null,
  lastModifiedBy: '',
  lastModifiedDate: null,
  password: '',
  unitCode: '',
  phoneNumber: '',
  unitStandardId: null,
};

export interface IParamsFilterWhitelist extends FilterParams {
  userId?: string;
}

export interface ICRUDWhitelist {
  userId?: string;
  whitelistId?: string;
  whitelist?: IWhiteList;
}
export interface IWhiteList {
  id?: string;
  userId?: string;
  name?: string;
  ipFrom?: string;
  ipTo?: string;
  type?: string;
  createdDate?: string;
  createdBy?: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
}

export interface IParamsFilterHistories extends FilterParams {
  userId?: string;
}

export interface IHistory {
  id: string;
  userId: string;
  date: string;
  ip: string;
  nation: string;
  device: string;
}
