import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Col, Input, Select, Tag } from 'antd';
import { formatDate, formatHour } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { MonitorListFilter } from './monitor.type';
import { clearMsgCreate, clearMsgDelete, clearMsgUpdate, deleteEntity, getEntities, getEntity } from './monitor.reducer';
import { MatchStatusContained } from 'app/modules/status-contained';
import dayjs from 'dayjs';
import MonitorAddDialog from './monitor-add-dialog';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import MonitorDeleteDialog from './monitor-delete-dialog';
import { ICategoryData } from 'app/shared/model/category-data.model';
import Filter from 'app/modules/filter';
import { RatingTypeContainer } from './rating-type';
import { RoleTypeContainer } from './role-type';
import { PlusIcon } from 'app/shared/util/appIcon';
import { CATEGORY_DATA_CODE } from 'app/shared/util/category-data-code.constant';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';
import MultiFilter from 'app/modules/MultiSearch';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export const Monitor = (props: any) => {
  const dispatch = useAppDispatch();
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [dataRating, setDataRating] = useState([]);
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
    type: 'name',
  };
  const [idUpdate, setIdUpdate] = useState('');
  const links = useAppSelector(state => state.player.links);
  const monitorList = useAppSelector(state => state.monitor.entities);
  const loading = useAppSelector(state => state.monitor.loading);
  const deleteSuccess = useAppSelector(state => state.monitor.deleteSuccess);
  const updateSuccess = useAppSelector(state => state.monitor.updateSuccess);
  const createSuccess = useAppSelector(state => state.monitor.createSuccess);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const [reset, setReset] = useState(false);
  const account = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);
  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.monitor.delete.deleteSuccess'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (createSuccess) {
      toast.success(translate('leagueApp.monitor.created.success'));
      dispatch(clearMsgCreate(createSuccess));
      setShowDialog(false);
      handleFetchData({ ...filter, page: 0 });
    }
  }, [createSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      toast.success(translate('leagueApp.monitor.updated.success'));
      dispatch(clearMsgUpdate(updateSuccess));
      setShowDialog(false);
      handleFetchData({ ...filter, page: 0 });
    }
  }, [updateSuccess]);

  const handleDeleteModal = (user: any) => {
    setUserClicked(user);
    setModalDelete(true);
  };

  const handleDelete = () => {
    dispatch(deleteEntity(userClicked)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        updateFilter();
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const getLabel = (arr, key) => {
    if (arr && arr.length > 0) {
      const c = arr.find(x => x.value === key);
      if (c) {
        return c.label;
      }
    }
    return '';
  };

  const handleFetchData = React.useCallback((params: MonitorListFilter) => {
    dispatch(getEntities(params));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const ActionButton = ({ id }: { id: number }) => {
    return (
      <div className={isAdmin && 'column-action-button'}>
        <Button onClick={() => handleUpdate(id)} className="rounded">
          <Translate contentKey="entity.action.edit" />
        </Button>
        {isAdmin && (
          <Button onClick={() => handleDeleteModal(id)} className="rounded" danger type="primary">
            <Translate contentKey="entity.action.delete2">Delete</Translate>
          </Button>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: <Translate contentKey="leagueApp.monitor.name2"></Translate>,
      dataIndex: 'name',
      key: 'name',
    },
    // {
    //   title: <Translate contentKey="userManagement.email" />,
    //   dataIndex: 'email',
    //   key: 'email',
    // },
    {
      title: <Translate contentKey="leagueApp.monitor.role2"></Translate>,
      dataIndex: 'role',
      key: 'role',
      render: (value, record) => <RoleTypeContainer role={value} />,
    },
    {
      title: <Translate contentKey="leagueApp.monitor.rating2"></Translate>,
      dataIndex: 'rating',
      key: 'rating',
      render: (value, record) => <RatingTypeContainer rating={value} />,
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 230,
      render: (value, record) => <ActionButton id={value} />,
      align: 'center' as any,
    },
  ];

  const options = [
    {
      value: 'name',
      label: translate('leagueApp.monitor.name2'),
    },
    {
      value: 'role',
      label: translate('leagueApp.monitor.role2'),
    },
    {
      value: 'rating',
      label: translate('leagueApp.monitor.rating2'),
    },
  ];

  const [dataRole, useDataRole] = useState([]);

  useEffect(() => {
    handleRating({ code: CATEGORY_DATA_CODE.MONITOR_RATING, lang: locale });
    handleRole({ code: CATEGORY_DATA_CODE.MONITOR_ROLE, lang: locale });
  }, []);

  const handleRating = async ({ code, lang }: any) => {
    const data = await axios.get<ICategoryData>(`api/category-data-by-code?code=${code}&lang=${lang}`);
    const arr = data.data.dataTrans.map(v => {
      return {
        value: Number(v.code),
        label: v.name,
      };
    });
    setDataRating(arr);
  };

  const handleRole = async ({ code, lang }: any) => {
    const data = await axios.get<any>(`api/category-data-by-code?code=${code}&lang=${lang}`);
    const arr = data.data.dataTrans.map(v => {
      return {
        value: Number(v.code),
        label: v.name,
      };
    });
    useDataRole(arr);
  };

  //   /** new **/
  const handleOpenModal = () => {
    setIdUpdate(null);
    setShowDialog(true);
  };

  //   /** update **/
  const handleUpdate = id => {
    setIdUpdate(id);
  };

  React.useEffect(() => {
    if (idUpdate) {
      setShowDialog(true);
    }
  }, [idUpdate]);

  const updateFilter = () => {
    onFilterToQueryString({
      page: 0,
      size: 10,
      sort: 'id,asc',
      type: 'name',
    });
    setReset(!reset);
  };

  return (
    <div className="management-layout">
      {showDialog && (
        <MonitorAddDialog
          entityId={idUpdate}
          setEntityId={setIdUpdate}
          showDialog={showDialog}
          setShowDialog={b => setShowDialog(b)}
          dataRating={dataRating}
          dataRole={dataRole}
          updateFilter={updateFilter}
        />
      )}
      <div className="d-flex justify-content-between align-items-center management-layout-header">
        <div className="d-flex align-items-center auto-wrap">
          <h2 id="player-heading" data-cy="PlayerHeading">
            <Translate contentKey="leagueApp.monitor.home.title"></Translate>
          </h2>
          <div className="management-layout-divider"></div>
          <Button className="rounded management-header-add-button" onClick={handleOpenModal} icon={<PlusIcon />} type="primary">
            <Translate contentKey="clubmng.add" />
          </Button>
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <Filter filter={filter} onFilter={onFilterToQueryString} options={options} multi reset={reset} />
        </Col>
      </div>
      <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={monitorList?.content}
          totalElements={monitorList?.totalElements}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
      {/* {modalDelete ? (
        <MonitorDeleteDialog
          modalDelete={modalDelete}
          setModalDelete={setModalDelete}
          userClicked={userClicked}
          // idClub={id}
          // onSearch={onSearch}
          // itemSelected={itemSelected}
        />
      ) : (
        ''
      )} */}
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('leagueApp.monitor.delete.question')}</h6>}
          onClose={() => setModalDelete(false)}
          onConfirm={() => handleDelete()}
        />
      )}
    </div>
  );
};

export default Monitor;
