import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { Button, Col, ConfigProvider, Empty, Modal } from 'antd';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../..//config/store';
import axios from 'axios';
// import Table from '../../modules/table';
import { Table } from 'antd';
import { toast } from 'react-toastify';
import { getEntities } from '../league-club/league-club.reducer';
import { useFilter } from 'app/shared/hooks/useFilter';
import Filter from 'app/modules/filter';
import { ClubListFilter } from '../club/club.type';
import { FilterParams } from 'app/shared/model/filter.model';

export const GetClubModal = (props: any) => {
  const {
    getClubModal,
    setGetClubModal,
    createOwnerClub,
    setCreateOwnerClub,
    createAwayClub,
    setCreateAwayClub,
    changeClub,
    setChangeClub,
  } = props;
  const dispatch = useAppDispatch();

  const [filterClub, setFilterClub] = useState([]);
  const [data, setData] = useState([]);

  const [formCreate, setFormCreate] = useState({
    position: '',
    player: '',
  });
  const defaultFilter: FilterParams = {
    page: 0,
    size: 1000,
    sort: 'id,asc',
    type: 'name',
  };

  const getId = useParams<{ id: string; params: string }>();
  const handleFetchData = React.useCallback((params: ClubListFilter) => {
    dispatch(getEntities({ ...params, leagueId: +getId.id }));
  }, []);

  const [filter2, setFilter2] = React.useState(defaultFilter);

  useEffect(() => {
    handleFetchData(filter2);
  }, [filter2]);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });
  const options = [
    {
      value: 'name',
      label: translate('leagueApp.leagueClub.detail.title1'),
    },
    {
      value: 'managerName',
      label: translate('leagueApp.leagueClub.managerName1'),
    },
  ];
  const loading = useAppSelector(state => state.PlayerClub?.loading) || false;
  const param = useParams<{ id: string; params: string }>();
  const GetClubList = useAppSelector(state => state.LeagueClub?.entities);
  const handleClose = () => {
    setGetClubModal(false);
  };
  useEffect(() => {
    if (GetClubList) {
      if (changeClub === 'OWNERCLUB') {
        const newArr = GetClubList?.content?.filter(item => !createAwayClub?.find(ele => ele.id === item.id));
        setData(newArr);
      } else {
        const newArr = GetClubList?.content?.filter(item => !createOwnerClub?.find(ele => ele.id === item.id));
        setData(newArr);
      }
    }
  }, [GetClubList]);

  const handleCreateClub = (name: any) => {
    if (changeClub === 'OWNERCLUB') {
      setGetClubModal(false);
    } else if (changeClub === 'AWAYCLUB') {
      const newArr = GetClubList.content.filter(item => !filterClub.find(ele => ele === item.id));
      setData(newArr);
      setGetClubModal(false);
    }
  };

  const rowSelection = {
    onChange(selectedPlayers: React.Key[], selectedRows) {
      if (changeClub === 'OWNERCLUB') {
        setCreateOwnerClub(selectedRows);
        setFilterClub(state => [...state, selectedRows[0].id]);
      } else if (changeClub === 'AWAYCLUB') {
        setCreateAwayClub(selectedRows);
        setFilterClub(state => [...state, selectedRows[0].id]);
      }
    },
    defaultSelectedRowKeys: changeClub === 'OWNERCLUB' ? createOwnerClub?.map(i => i.id) : createAwayClub?.map(i => i.id),
    // defaultSelectedRowKey: createAwayClub?.map(i=> i.id)
  };

  const columns = [
    {
      title: <Translate contentKey="leagueApp.club.name1" />,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <Translate contentKey="leagueApp.club.managerId1" />,
      dataIndex: 'managerName',
      key: 'managerName',
    },
  ];

  return (
    <Modal
      visible={getClubModal}
      onCancel={handleClose}
      footer={null}
      width={900}
      wrapClassName="management-wrapper-modal"
      title={
        changeClub === 'OWNERCLUB' ? (
          <Translate contentKey="leagueApp.match.leagueMatch.chooseOwnerClub.title" />
        ) : (
          <Translate contentKey="leagueApp.match.leagueMatch.chooseAwayClub.title" />
        )
      }
    >
      {changeClub === 'OWNERCLUB' ? (
        <Translate contentKey="leagueApp.match.leagueMatch.chooseOwnerClub.question" />
      ) : (
        <Translate contentKey="leagueApp.match.leagueMatch.chooseAwayClub.question" />
      )}
      <div className="table-responsive">
        <ConfigProvider renderEmpty={() => <Empty description={translate('common.noDataTable')} />}>
          <Table
            bordered
            pagination={false}
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
            columns={columns}
            dataSource={data?.map((item, index) => ({ ...item, key: item.id }))}
            // totalElements={GetClubList.totalElements || 0}
            key="id"
            loading={loading}
            // filter={filter2}
            scroll={{
              x: 'calc(400px + 50%)',
              y: 240,
            }}
            // onChange={params => setFilter2({ ...filter2, ...params })}
          />
        </ConfigProvider>
      </div>
      <Col>
        <Filter filter={filter2} onFilter={filterParams => setFilter2(filterParams)} options={options} />
      </Col>
      <Col span={24} style={{ marginTop: 24 }}>
        <div className="management-modal-footer">
          <Button color="secondary" onClick={handleClose}>
            <Translate contentKey="entity.action.cancel"></Translate>
          </Button>
          <Button id="jhi-confirm-delete-clubUniform" type="primary" style={{ marginLeft: 12 }} onClick={handleCreateClub}>
            <Translate contentKey="entity.action.apply"></Translate>
          </Button>
        </div>
      </Col>
    </Modal>
  );
};

export default GetClubModal;
