export interface ILeagueStandard {
  id?: number;
  code?: string | null;
  name?: string | null;
  manager?: string | null;
  avatar?: string | null;
  classify?: number | null;
  standardTournament?: Array<''> | null;
}

export const defaultValue: Readonly<ILeagueStandard> = {};
