import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { Avatar, Button, Col, Dropdown, Menu } from 'antd';
import { AUTHORITIES, FILE_TYPE } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Filter from 'app/modules/filter';
import MultiFilter from 'app/modules/MultiSearch';
import Table from 'app/modules/table';
import { hasAnyAuthority, isRole } from 'app/shared/auth/private-route';
import { useFilter } from 'app/shared/hooks/useFilter';
import { IFileTemplatePlayer, IParamsFilterPlayer, IPlayer } from 'app/shared/model/player.model';
import { PlusIcon, SelectArrowDown, SelectArrowUp, StarActive, StarInactive } from 'app/shared/util/appIcon';
import { ConfirmationDialog } from 'app/shared/util/confirmationDialog';
import { IsFavorite } from 'app/shared/util/constant/favorite.constant';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { path } from '../routes';
import ModalCreatePlayer from './modal-add-player';
import PlayerUploadDialog from './player-upload-modal';
import {
  addFavourite,
  clearMsgCreate,
  clearMsgDelete,
  deleteEntity,
  deleteFavourite,
  exportAttachFilePlayersEntity,
  getEntities,
} from './player.reducer';
import { PlayerListFilter } from './player.type';
import './style.scss';
import { pick } from 'lodash';

export const Player = (props: any) => {
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'createdDate,asc',
    type: 'koName',
  };

  const dispatch = useAppDispatch();
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [ids, setIds] = useState<React.Key[]>([]);
  const [idUpdate, setIdUpdate] = useState('');

  const deleteSuccess = useAppSelector(state => state.player.deleteSuccess);
  const links = useAppSelector(state => state.player.links);
  const exporting = useAppSelector(state => state.player.exporting);
  const playerList = useAppSelector(state => state.player.entities);
  const loading = useAppSelector(state => state.player.loading);
  const createdSuccess = useAppSelector(state => state.player.createSuccess);

  const locale = useAppSelector(state => state.locale.currentLocale);
  const account = useAppSelector(state => state.authentication.account);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const isTeamManager = isRole(authorities, [AUTHORITIES.TEAM_MANAGER]);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);

  const { push } = useHistory();

  const onSelectChange = (newIds: React.Key[]) => {
    setIds(newIds);
  };

  const rowSelection = {
    selectedRowKeys: ids,
    onChange: onSelectChange,
  };

  const handleDeleteModal = (user: any) => {
    setUserClicked(user);
    setModalDelete(true);
  };

  const handleRowClick = (selectedId: number) => {
    let newIds = [...ids];
    if (newIds.includes(selectedId)) {
      newIds = newIds.filter((idItem: number) => idItem !== selectedId);
    } else {
      newIds.push(selectedId);
    }

    setIds(newIds);
  };

  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.player.delete.success'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (createdSuccess) {
      toast.success(translate('leagueApp.player.created.success'));
      dispatch(clearMsgCreate(createdSuccess));
      handleFetchData({ ...filter, page: 0 });
    }
  }, [createdSuccess]);

  const handleDelete = () => {
    dispatch(deleteEntity(userClicked)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        updateFilter();
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };
  const handleOpenUpload = () => {
    setShowUpload(true);
  };

  const handleFetchData = React.useCallback((params: PlayerListFilter) => {
    dispatch(getEntities(params));
  }, []);

  const { filter, onFilterToQueryString }: { filter: IParamsFilterPlayer; onFilterToQueryString: (values: IParamsFilterPlayer) => void } =
    useFilter({
      defaultFilter,
      onFetchData: handleFetchData,
    });

  const ActionButton = ({ id, notEdit }: { id: number; notEdit: boolean }) => {
    return (
      <div className={(isAdmin || isTeamManager) && 'column-action-button'}>
        <Button
          onClick={e => {
            push(path.playerDetail.replace(':id', id.toString()));
            e.stopPropagation();
          }}
          className="rounded"
        >
          <Translate contentKey="leagueApp.match.details" />
        </Button>
        {(isAdmin || isTeamManager) && (
          <Button
            onClick={e => {
              handleDeleteModal(id);
              e.stopPropagation();
            }}
            className="rounded"
            danger
            type="primary"
            disabled={!!notEdit && isTeamManager}
          >
            <Translate contentKey="entity.action.delete2">Delete</Translate>
          </Button>
        )}
      </div>
    );
  };

  const columns = [
    {
      dataIndex: 'favourite',
      key: 'favourite',
      render: (value, record) => (
        <button
          style={{ background: 'transparent', width: '40px', border: 'none' }}
          onClick={e => {
            value === IsFavorite.TRUE
              ? dispatch(deleteFavourite(record?.id)).then(res => {
                  if (res.meta.requestStatus === 'fulfilled') {
                    dispatch(getEntities(filter));
                  }
                })
              : dispatch(addFavourite({ playerId: record?.id })).then(res => {
                  if (res.meta.requestStatus === 'fulfilled') {
                    dispatch(getEntities(filter));
                  }
                });
            e.stopPropagation();
          }}
        >
          {value === IsFavorite.TRUE ? <StarActive /> : <StarInactive />}
        </button>
      ),
    },
    {
      title: '',
      dataIndex: 'base64Avt',
      key: 'base64Avt',
      width: 60,
      render: (value, record) => <Avatar shape="square" src={value} size="large" />,
    },
    {
      title: <Translate contentKey="leagueApp.player.koName" />,
      dataIndex: 'koName',
      key: 'koName',
    },
    {
      title: <Translate contentKey="leagueApp.player.teamId" />,
      dataIndex: 'clubName',
      key: 'clubName',
    },
    {
      title: <Translate contentKey="leagueApp.player.position" />,
      dataIndex: 'position',
      key: 'position',
      render: (_, record) => <span>{translate(`leagueApp.playerAgent.${record.position}`)}</span>,
    },
    {
      title: <Translate contentKey="leagueApp.player.nationality" />,
      dataIndex: 'nationality',
      key: 'nationality',
      render: (_, record) => <>{locale === 'ko' ? <span>{record?.nationalityKorea}</span> : <span>{record?.nationality}</span>}</>,
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 230,
      render: (value, record) => <ActionButton id={value} notEdit={record?.notEdit} />,
      align: 'center' as any,
    },
  ];

  const options = [
    {
      value: 'koName',
      label: translate('leagueApp.player.koName'),
    },
    {
      value: 'clubName',
      label: translate('leagueApp.player.teamId'),
    },
    {
      value: 'position',
      label: translate('leagueApp.playerClub.position'),
    },
    {
      value: 'nationality',
      label: translate('leagueApp.playerClub.national'),
    },
  ];

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const updateFilter = () => {
    onFilterToQueryString({
      page: 0,
      size: 10,
      sort: 'createdDate,asc',
      type: 'koName',
    });
    setReset(!reset);
  };

  const handleExportFile = value => {
    const currentFilter: IParamsFilterPlayer = pick(filter, ['clubName', 'koName', 'nationality', 'position', 'type']);

    const requestParams: IFileTemplatePlayer = {
      type: value,
      lang: locale,
      fileName: value === FILE_TYPE.EXCEL ? 'players.xlsx' : 'players.csv',
      zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      filter: currentFilter,
    };

    dispatch(exportAttachFilePlayersEntity(requestParams));
  };

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <p
              className="w-100 h-100 d-flex justify-content-center align-items-center mb-0"
              onClick={() => handleExportFile(FILE_TYPE.EXCEL)}
            >
              {translate('leagueApp.player.upload.typeExcel')}
            </p>
          ),
        },
        {
          key: '2',
          label: (
            <p
              className="w-100 h-100 d-flex justify-content-center align-items-center mb-0"
              onClick={() => handleExportFile(FILE_TYPE.CSV)}
            >
              {translate('leagueApp.player.upload.typeCSV')}
            </p>
          ),
        },
      ]}
    />
  );

  return (
    <>
      <ModalCreatePlayer entityId={idUpdate} setEntityId={setIdUpdate} isOpen={isOpen} setIsOpen={setIsOpen} updateFilter={updateFilter} />

      <PlayerUploadDialog
        isOpen={showUpload}
        onClose={showUploadList => {
          showUploadList && handleFetchData(filter);
          setShowUpload(false);
        }}
        ids={ids}
      />

      <div className="management-layout">
        <div className="d-flex justify-content-between align-items-center management-layout-header">
          <div className="d-flex align-items-center auto-wrap">
            <h2 id="player-heading" data-cy="PlayerHeading">
              <Translate contentKey="leagueApp.player.home.title"></Translate>
            </h2>

            {isTeamManager ? (
              <>
                <div className="management-layout-divider"></div>
                <Button className="rounded management-header-add-button" onClick={handleOpenModal} icon={<PlusIcon />} type="primary">
                  <Translate contentKey="clubmng.add" />
                </Button>
              </>
            ) : (
              <>
                <div className="management-layout-divider"></div>
                <Button className="rounded management-header-add-button" onClick={handleOpenModal} icon={<PlusIcon />} type="primary">
                  <Translate contentKey="clubmng.add" />
                </Button>
                <Button className="rounded ms-4" icon={<SelectArrowUp />} onClick={handleOpenUpload}>
                  <Translate contentKey="clubmng.upload" />
                </Button>
                <Dropdown overlay={menu}>
                  <Button className="rounded ms-4" icon={<SelectArrowDown />} loading={exporting}>
                    <Translate contentKey="clubmng.export" />
                  </Button>
                </Dropdown>
              </>
            )}
          </div>
          <Col xl={10} lg={16} md={20} sm={24}>
            <Filter filter={filter} onFilter={onFilterToQueryString} options={options} multi reset={reset} />
          </Col>
        </div>
        <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
        <div className="table-responsive">
          <Table
            onRow={(record: IPlayer, rowIndex) => ({
              onClick() {
                handleRowClick(record.id);
              },
            })}
            rowKey={'id'}
            rowSelection={!isTeamManager && rowSelection}
            columns={columns}
            dataSource={playerList.content}
            totalElements={playerList.totalElements}
            loading={loading}
            filter={filter}
            onChange={params => onFilterToQueryString({ ...filter, ...params })}
          />
        </div>
        {modalDelete && (
          <ConfirmationDialog
            open={modalDelete}
            message={<h6>{translate('leagueApp.player.delete.question')}</h6>}
            onClose={() => setModalDelete(false)}
            onConfirm={() => handleDelete()}
          />
        )}
      </div>
    </>
  );
};

export default Player;
