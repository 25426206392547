import React from 'react';
import { Avatar, Button, Card, Col, Row, Skeleton } from 'antd';
import './notice-card.scss';
import Tag from 'antd/es/tag';
import { EditOutlined, EllipsisOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { ChervonLeft } from 'app/shared/util/appIcon';
import dayjs from 'dayjs';
import { translate, Translate } from 'react-jhipster';
import { useRequest } from 'ahooks';
import { useAppSelector } from 'app/config/store';
import { Link, useHistory } from 'react-router-dom';
import { listNotice } from '../../dashboard.service';
import { path } from 'app/entities/routes';

export const NoticeCard = props => {
  const account = useAppSelector(state => state.authentication.account);
  const [data, setData] = React.useState(null);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const { push } = useHistory();

  const request = useRequest(listNotice, {
    manual: true,
    onSuccess(res) {
      setData(res.data);
    },
  });
  React.useEffect(() => {
    request.run({
      page: 0,
      size: 6,
      type: 'type'
    });
  }, []);


  return (
    <Card
      title={translate('sidebar.notice')}
      className="dashboard-notifications"
      extra={
        <Link to={{ pathname: '/sportat-center/notice' }}>
          <span>{translate('entity.action.detail')}</span> <ChervonLeft />
        </Link>
      }
    >
      <ul
        className="list-notifications"
        style={{ marginTop: '10px' }}
        // style={{
        //   display: 'flex',
        //   flexDirection: 'column',
        //   justifyContent: 'space-evenly',
        // }}
      >
        {request.loading || !data || data.length === 0 ? (
          // <Skeleton active />
          <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {translate('dashboard.noData')}
          </div>
        ) : (
          data?.content.map((noti, i) => {
            return (
              <li key={i} style={{ padding: '8px 0px' }}>
                <div className="hot-link-club" style={{ display: 'flex', justifyContent: 'space' }} onClick={() => push(path.detailNotice.replace(':id', noti?.id))}>
                  <span style={{ flexGrow: 1 }}>{noti.title}</span>
                  <div style={{ textAlign: 'right', width: 130 }}>
                    <p className="neutral-3" style={{ fontSize: 12, fontWeight: '500', lineHeight: '20px' }}>
                      {dayjs(noti.createdDate).format('YYYY-MM-DD')}
                    </p>
                  </div>
                </div>
              </li>
            );
          })
        )}
      </ul>
    </Card>
  );
};
