import dayjs from 'dayjs';

export interface IMatchBasicInfo {
  id?: number;
  matchId?: number | null;
  audienceTotal?: number | null;
  weather?: string | null;
  temperature?: number | null;
  humidity?: number | null;
  ownerClubId?: number | null;
  ownerClubUniformId?: number | null;
  ownerClubUniformGkId?: number | null;
  awayClubId?: number | null;
  awayClubUniformId?: number | null;
  awayClubUniformGkId?: number | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
}

export const defaultValue: Readonly<IMatchBasicInfo> = {};
