import axios from "axios";


const apiUrl = 'api/account';
const apiFile = 'api/file'
const apiUser = 'api/user-management'

export const getCurrentUser = () => {
    return axios.get(apiUrl)
}

export const changePasswordRequest = (payload: any) => {
    const url = apiUser + '/update-password';
    return axios.post(url, payload)
}

export const cancelUser = (payload: any) => {
    const url = apiUser + '/cancel';
    return axios.get(url, {
        params: {
            password: payload
        }
    })
}

export const accuracyOwnerRequest = (payload: any) => {
    const url = apiUrl + '/update-account';
    return axios.put(url, payload)
}

export const uploadImage = (payload) => {
    const url = apiFile + '/upload-file?uploadType=OTHER';
    return axios({
        method: "post",
        url,
        data: payload,
        headers: { "Content-Type": "multipart/form-data" },
    })
}