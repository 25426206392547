import { ConfigProvider, Empty, Layout, Menu, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, useHistory, useLocation } from 'react-router-dom';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css';
import { faBook, faFileExcel, faFileUpload, faSave } from '@fortawesome/free-solid-svg-icons';
import { AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import AppRoutes from 'app/routes';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getProfile } from 'app/shared/reducers/application-profile';
import { getSession } from 'app/shared/reducers/authentication';
import { setLocale } from 'app/shared/reducers/locale';
import { Storage, translate } from 'react-jhipster';
import Sidebar from './shared/layout/sidebar/sidebar';
// import Routes from './entities/club';
// import Routes from './entities/club';
import { Content } from 'antd/lib/layout/layout';
import { ChartIcon, DashboardIcon, FolderIcon, SettingIcon, SportatCenterIcon, VideoIcon } from './shared/util/sidebarIcon';
import 'app/config/dayjs.ts';
import 'react-toastify/dist/ReactToastify.css';
import './custom-theme.less';
import './app.scss';
import './style/_table.scss';
import './main.less';
import 'react-quill/dist/quill.snow.css';

import { LocaleMenu } from './shared/layout/menus';
import vi_VN from 'antd/lib/locale/vi_VN';
import en_US from 'antd/lib/locale/en_US';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
}

export const App = (props: IHeaderProps) => {
  const dispatch = useAppDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  type MenuItem = Required<MenuProps>['items'][number];
  const handleLocaleChange = value => {
    Storage.local.set('locale', value);
    dispatch(setLocale(value));
  };
  const items: MenuItem[] = [
    getItem(
      <div>
        <div>
          <DashboardIcon />
        </div>
        <div>{translate('sidebar.matchInformation')}</div>
      </div>,
      '1',
      [AUTHORITIES.LEAGUE_MASTER, AUTHORITIES.TEAM_MANAGER],
      <></>,
      [
        getItem(translate('sidebar.leagueManagement'), '/league', [AUTHORITIES.LEAGUE_MASTER]),
        getItem(translate('sidebar.teamManagement1'), '/club', [AUTHORITIES.TEAM_MANAGER, AUTHORITIES.LEAGUE_MASTER]),
        getItem(translate('sidebar.playerManagement'), '/player', [AUTHORITIES.LEAGUE_MASTER, AUTHORITIES.TEAM_MANAGER]),
        getItem(translate('sidebar.monitorManagement'), '/monitor', [AUTHORITIES.ADMIN, AUTHORITIES.LEAGUE_MASTER]),
        getItem(translate('sidebar.stadiumManagement'), '/stadium', [AUTHORITIES.ADMIN, AUTHORITIES.LEAGUE_MASTER]),
        getItem(translate('sidebar.coachManagement'), '/coach', [AUTHORITIES.ADMIN, AUTHORITIES.LEAGUE_MASTER, AUTHORITIES.TEAM_MANAGER]),
        getItem(translate('sidebar.playerAgentManagement'), '/player-agent', [
          AUTHORITIES.ADMIN,
          AUTHORITIES.LEAGUE_MASTER,
          AUTHORITIES.TEAM_MANAGER,
        ]),
        getItem(translate('sidebar.staffManagement'), '/staff', [AUTHORITIES.ADMIN, AUTHORITIES.LEAGUE_MASTER, AUTHORITIES.TEAM_MANAGER]),
      ]
    ),
    getItem(
      <div>
        <div>
          <VideoIcon />
        </div>
        <div>{translate('sidebar.matchRecording')}</div>
      </div>,
      '2',
      [AUTHORITIES.RECORDER, AUTHORITIES.LEAGUE_MASTER],
      '',
      [getItem(translate('sidebar.matchRecording'), '/match-center/:params', []), getItem(translate('sidebar.matchList'), '/match', [])]
    ),
    getItem(
      <div>
        <div>
          <ChartIcon />
        </div>
        <div>{translate('sidebar.recordRoom')}</div>
      </div>,
      '3',
      [],
      '',
      [
        getItem(translate('sidebar.lookupLeague'), '/lookup-league', []),
        getItem(translate('sidebar.teamRecord'), '/lookup-team', []),
        getItem(translate('sidebar.playerRecord'), '/lookup-player', []),
      ]
    ),
    getItem(
      <div>
        <div>
          <FolderIcon />
        </div>
        <div>{translate('sidebar.standardInfo')}</div>
      </div>,
      '4',
      [AUTHORITIES.ADMIN],
      '',
      [
        getItem(translate('sidebar.leagueStandard'), '/league-standard', []),
        getItem(translate('sidebar.unitStandard'), '/unit-standard', []),
      ]
    ),
    // getItem('Standard Information Management', '6', <FolderIcon />, []),
    getItem(
      <div>
        <div>
          <SettingIcon />
        </div>
        <div>{translate('sidebar.systemManagement')}</div>
      </div>,
      '5',
      [AUTHORITIES.ADMIN],
      '',
      [
        getItem(translate('sidebar.userManagement'), '/user-management', []),
        getItem(translate('sidebar.clubManagement'), '/club-management', []),
      ]
    ),
    getItem(
      <div>
        <div>
          <SportatCenterIcon />
        </div>
        <div>{translate('sidebar.sportatCenter')}</div>
      </div>,
      '6',
      [],
      '',
      [
        getItem(translate('sidebar.notice'), '/sportat-center/notice', [AUTHORITIES.ADMIN, AUTHORITIES.LEAGUE_MASTER]),
        getItem(translate('sidebar.questionAndAnswer'), '/sportat-center/question-and-answer', []),
        getItem(translate('sidebar.guide'), '/sportat-center/guide', []),
        getItem(translate('sidebar.notification'), '/sportat-center/notification', []),
      ]
    ),
    // getItem(
    //   <div>
    //     <div>
    //       <SettingIcon />
    //     </div>
    //     <div>System</div>
    //   </div>,
    //   '6',
    //   '',
    //   [ <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange}></LocaleMenu>]
    // ),
  ];

  function getItem(label: React.ReactNode, key: React.Key, roles: string[], icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      roles,
    } as MenuItem;
  }

  const handleMenuClick: MenuProps['onClick'] = e => {};

  const AUTH_TOKEN_KEY = 'jhi-authenticationToken';
  const ROLES_KEY = 'roles';
  const LOGIN_KEY = 'login';
  const USER_ID = 'userId';

  const handleLogout = () => {
    if (Storage.local.get(AUTH_TOKEN_KEY)) {
      Storage.local.remove(AUTH_TOKEN_KEY);
    }
    if (Storage.session.get(AUTH_TOKEN_KEY)) {
      Storage.session.remove(AUTH_TOKEN_KEY);
    }
    if (Storage.session.get(ROLES_KEY)) {
      Storage.session.remove(ROLES_KEY);
    }
    if (Storage.session.get(LOGIN_KEY)) {
      Storage.session.remove(LOGIN_KEY);
    }
    if (Storage.session.get(USER_ID)) {
      Storage.session.remove(USER_ID);
    }

    // history.push('/logout');
    window.location.reload();
    // history.push('/login');
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          key: '1',
          label: <span>My Account</span>,
        },
        {
          key: '2',
          label: (
            <a onClick={handleLogout}>
              <span>Log out</span>
            </a>
          ),
        },
      ]}
    />
  );

  const localeChange = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          key: '1',
        },
      ]}
    ></Menu>
  );

  // const getCustomerId = useAppSelector(state => state.customerInfo.customerId);

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
    const language = localStorage.getItem('locale');

    language && dispatch(setLocale(JSON.parse(language)));
  }, []);

  // const getLanguages = async () => {
  //   const listLanguage = ['en', 'ko'];
  //   for (let i = 0; i < listLanguage.length; i++) {
  //     if (!Object.keys(TranslatorContext.context.translations).includes(listLanguage[i])) {
  //       const response1 = await axios.get(`i18n/${listLanguage[i]}.json?_=${I18N_HASH}`, { baseURL: '' });
  //       TranslatorContext.registerTranslations(listLanguage[i], response1.data);
  //     }
  //   }
  // };
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);
  const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');

  useEffect(() => {
    if (currentLocale === 'ko') {
      document.body.classList.add('korea-theme');
    } else {
      document.body.classList.remove('korea-theme');
    }
  }, [currentLocale]);

  return (
    <Router basename={baseHref}>
      <ConfigProvider
        locale={currentLocale === 'vi' ? vi_VN : en_US}
        renderEmpty={() => <Empty description={translate('common.noDataTable')} />}
      >
        <Layout hasSider={true} style={{ height: '100%' }}>
          {isAuthenticated ? (
            <Sidebar routes={items} handleLocaleChange={handleLocaleChange} currentLocale={currentLocale}></Sidebar>
          ) : null}

          <Layout className="site-layout">
            <Content id="main" className={` ${isAuthenticated ? 'auth-site-layout-background' : 'site-layout-background'}`}>
              <ToastContainer
                position={toast.POSITION.TOP_RIGHT}
                className="toastify-container"
                toastClassName="toastify-toast"
                transition={Zoom}
              />
              <AppRoutes isAuthenticated={isAuthenticated} token={token} />
            </Content>
          </Layout>
        </Layout>
      </ConfigProvider>
    </Router>
  );
};

export default App;
