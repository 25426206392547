import { translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import { getBodyAndParams } from './../../shared/util/helper';
import axios from 'axios';
import { AsyncThunkPayloadCreator, createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError, Pageable, UploadType } from 'app/shared/reducers/reducer.utils';

import { IQuestionAnswer, defaultValue } from 'app/shared/model/question-answer.model';
import { IAttachFile } from 'app/shared/model/notice.model';
import { QuestionAnswerParams } from 'app/shared/model/filter.model';

interface AnswerState extends EntityState<IQuestionAnswer> {
  pageable?: Pageable<IQuestionAnswer>;
}

const initialState: AnswerState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiAnswers = 'api/answers';

export const answerEntity = createAsyncThunk<
  IQuestionAnswer,
  string | number,
  { 
    rejectValue: PaymentValidationErrors;
  }
>(
  'answer_entity/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiAnswers}?questionId=${id}`;
    const result = await axios.get<IQuestionAnswer>(requestUrl);
    return result.data;
  },
  { serializeError: serializeAxiosError }
);

export const uploadAttachFile = createAsyncThunk(
  'answer/upload_attach_files',
  async (files: IAttachFile[]) => {
    const result = await axios.all<IAttachFile>(
      files?.map(item => {
        if (item.file) {
          const data = new FormData();
          data.append('file', item.file);
          data.append('uploadType', UploadType.ATTACHMENT);
          return axios
            .post('/api/file/upload-file', data, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(res => ({ ...item, nameFileServer: res.data, url: undefined }));
        }
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(item);
          }, 300);
        });
      })
    );
    return result || [];
  },
  { serializeError: serializeAxiosError }
);

export const AnswerSlice = createSlice({
  name: 'answers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(answerEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
      })
      .addCase(uploadAttachFile.pending, (state, action) => {
        state.loading = true;
      })
      .addMatcher(isPending(answerEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isRejected(answerEntity), (state, action) => {
        toast.error(action.error.message);
        state.entity = null;
      });
  },
});

export default AnswerSlice.reducer;
