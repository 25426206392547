import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IFavouriteMatch, defaultValueAttend } from 'app/shared/model/favorite-match';

const initialState: EntityState<IFavouriteMatch> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValueAttend,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/matches/user-match-favourite';

// Actions

export const getEntities = createAsyncThunk('favouriteMatch/fetch_entity_list', async ({ page, size, sort, ...res }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : '?'}`;
  return axios.post<IFavouriteMatch[]>(requestUrl, { ...res });
});

export const getEntity = createAsyncThunk(
  'favouriteMatch/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IFavouriteMatch>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const FavoriteMatch = createEntitySlice({
  name: 'favouriteMatch',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })

      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = FavoriteMatch.actions;

// Reducer
export default FavoriteMatch.reducer;
