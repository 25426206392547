import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'app/config/store';
import React, { useState } from 'react';
import { Button, Container, Input, Modal } from 'reactstrap';
import { upLoadImg } from '../../main.reducer';
interface IFormPropsCreateClub {
  formCreate: {
    nameClub: string;
    acronym: string;
    yearCreate: string;
  };
  setFormCreate: React.Dispatch<
    React.SetStateAction<{
      nameClub: string;
      acronym: string;
      yearCreate: string;
    }>
  >;
  file: File;
  setFile: React.Dispatch<React.SetStateAction<File>>;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  formClub: {
    name: string;
    year: string;
    stadium: string;
    address: string;
    website: string;
    description: string;
    shortName: string;
    nameManager: string;
  };
  setFormClub: React.Dispatch<
    React.SetStateAction<{
      name: string;
      year: string;
      stadium: string;
      address: string;
      website: string;
      description: string;
      shortName: string;
      nameManager: string;
    }>
  >;
}

const ModalCreateClub = ({ formCreate, setFormCreate, file, setFile, setImage, formClub, setFormClub }: IFormPropsCreateClub) => {
  const [isOpen, setIsOpen] = useState(true);
  const [previewImg, setPreviewImg] = useState('');
  const dispatch = useAppDispatch();
  const handleCloseModal = () => {
    setIsOpen(false);
  };
  const handleSave = () => {
    setImage(previewImg);
    setFormClub({
      ...formClub,
      name: formCreate.nameClub,
      year: formCreate.yearCreate,
      shortName: formCreate.acronym,
    });
    setIsOpen(false);
    const bodyImg = new FormData();
    bodyImg.append('file', file);
    bodyImg.append('uploadType', 'AVATAR_CLUB');
    dispatch(upLoadImg(bodyImg));
  };
  const handlePreviewAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileImg = e.target.files[0];
    const img = URL.createObjectURL(fileImg);
    setPreviewImg(img);
    setFile(fileImg);
  };
  return (
    <div>
      <Modal isOpen={isOpen} toggle={handleCloseModal}>
        <Container>
          <div className="create-club">
            <div>
              <div className="position-relative">
                <div className="d-flex align-items-center justify-content-between payment">
                  <h5 className="mb-0">{/* <Translate contentKey="contactInfor.newaddress">Thêm mới địa chỉ</Translate> */}구단 생성</h5>
                  <FontAwesomeIcon onClick={handleCloseModal} style={{ cursor: 'pointer' }} className="icon-close" icon={faClose} />
                </div>
              </div>
            </div>
            <div className="club-body">
              <div className="file">
                <div className="span-width">
                  <span>구단엠블럼</span>
                </div>
                <div className="input-width">
                  <label htmlFor="uploadImage" className="upload-file">
                    {file ? <img src={previewImg} alt="logo" style={{ width: '100%', height: '100%' }} /> : <span>+ TẢI ẢNH</span>}
                  </label>
                  <span style={{ fontSize: '12px' }}>* 1:1 비율의 png, jpg 파일을 업로드해주세요</span>
                  <Input id="uploadImage" hidden type="file" accept="image/*" onChange={handlePreviewAvatar} />
                </div>
              </div>
              <div className="file">
                <div className="span-width">
                  <span>* 구단명</span>
                </div>
                <div className="input-width">
                  <Input
                    placeholder="전체 구단명 입력"
                    value={formCreate.nameClub}
                    onChange={e => setFormCreate({ ...formCreate, nameClub: e.target.value })}
                  />
                </div>
              </div>
              <div className="file">
                <div className="span-width">
                  <span>* 구단명</span>
                </div>
                <div className="input-width year-create">
                  <div style={{ width: '40%' }}>
                    <Input
                      placeholder="전체 구단명 입력"
                      value={formCreate.acronym}
                      onChange={e => setFormCreate({ ...formCreate, acronym: e.target.value })}
                    />
                  </div>
                  <div className="d-flex" style={{ marginLeft: '40px', width: '70%' }}>
                    <span style={{ width: '50%' }}>* 창단년도</span>
                    <Input value={formCreate.yearCreate} onChange={e => setFormCreate({ ...formCreate, yearCreate: e.target.value })} />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '12px' }} className="d-flex justify-content-end">
              <div>
                <Button className="button-add" onClick={handleCloseModal}>
                  닫기
                </Button>
                <Button className="button-add" onClick={handleSave}>
                  저장
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </Modal>
    </div>
  );
};

export default ModalCreateClub;
