import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Col, Tag } from 'antd';
import { formatDate, formatHour } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { LeagueRecorderFilter } from './league-recorder.type';
import { clearMsgDelete, clearMsgUpdate, deleteEntity, getEntities, getEntity } from './league-recorder.reducer';
import { MatchStatusContained } from 'app/modules/status-contained';
import dayjs from 'dayjs';
import LeagueRecorderDeleteDialog from './league-recorder-delete-dialog';
import LeagueRecorderCreateDialog from './create-league-recorder';
import { useParams } from 'react-router-dom';
import Filter from 'app/modules/filter';
import { toast } from 'react-toastify';
import { ConfirmationDialog } from 'app/shared/util/confirmationDialog';
import { PlusIcon } from 'app/shared/util/appIcon';
import MultiFilter from 'app/modules/MultiSearch';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
export const LeagueRecorder = (props: any) => {
  const { entity } = props;
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [viewDetail, setViewDetail] = useState(null);
  const [reset, setReset] = useState(false);
  const deleteSuccess = useAppSelector(state => state.leagueRecorder.deleteSuccess);
  const createSuccess = useAppSelector(state => state.leagueRecorder.updateSuccess);
  const leagueEntity = useAppSelector(state => state.league.entity);
  const account = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
    type: 'fullName',
  };
  const param = useParams<{ id: string; params: string }>();
  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.leagueRecorder.delete.success'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (createSuccess) {
      toast.success(translate('leagueApp.leagueRecorder.created.success'));
      dispatch(clearMsgUpdate(createSuccess));
      updateFilter();
      handleFetchData({ ...filter, page: 0 });
    }
  }, [createSuccess]);
  const handleFetchData = React.useCallback(
    (params: LeagueRecorderFilter) => {
      dispatch(
        getEntities({
          ...params,
          leagueId: +param.id,
          unitStandardId: +params.unitStandardId || leagueEntity?.leagueStandardDTO?.unitStandardId,
        })
      );
    },
    [leagueEntity]
  );

  // useEffect(() => {
  //   onFilterToQueryString({ ...filter, leagueId: +param.id, unitStandardId: leagueEntity?.leagueStandardDTO?.unitStandardId });
  // }, [leagueEntity]);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const handleDenied = (user: any) => {
    setModalDelete(true);
    setUserClicked(user);
  };

  const handleDelete = () => {
    dispatch(deleteEntity(userClicked)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        updateFilter();
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const handleViewDetail = (user: any) => {
    setModalUpdate(true);
  };

  const ActionButton = ({ lrId }: { lrId: number }) => {
    return (
      <div>
        <Button onClick={() => handleDenied(lrId)} type="primary" danger className="rounded">
          <Translate contentKey="entity.action.delete2" />
        </Button>
      </div>
    );
  };

  const leagueRecorderList = useAppSelector(state => state.leagueRecorder.entities);
  const loading = useAppSelector(state => state.leagueRecorder.loading);

  const [columns, setColumns] = useState([
    {
      title: <Translate contentKey="leagueApp.leagueRecorder.recorderId"></Translate>,
      dataIndex: 'fullName',
      key: 'fullName',
      align: 'start' as any,
      width: 400,
    },
    {
      title: <Translate contentKey="userManagement.email" />,
      dataIndex: 'email',
      key: 'email',
      width: 400,
    },
    {
      title: <Translate contentKey="leagueApp.leagueRecorder.leagueId"></Translate>,
      dataIndex: 'unitStandardName',
      key: 'unitStandardName',
      align: 'left' as any,
      width: 300,
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'lrId',
      key: 'lrId',
      width: 200,
      render: (value, record) => <ActionButton lrId={value} />,
      align: 'center' as any,
    },
  ]);

  useEffect(() => {
    if (!isAdmin) {
      setColumns([
        {
          title: <Translate contentKey="leagueApp.leagueRecorder.recorderId"></Translate>,
          dataIndex: 'fullName',
          key: 'fullName',
          align: 'start' as any,
          width: 400,
        },
        {
          title: <Translate contentKey="userManagement.email" />,
          dataIndex: 'email',
          key: 'email',
          width: 400,
        },
        {
          title: <Translate contentKey="leagueApp.leagueRecorder.leagueId"></Translate>,
          dataIndex: 'unitStandardName',
          key: 'unitStandardName',
          align: 'left' as any,
          width: 300,
        },
      ]);
    }
  }, [isAdmin]);

  const options = [
    {
      value: 'fullName',
      label: translate('leagueApp.leagueRecorder.recorderId'),
    },
  ];

  const updateFilter = () => {
    onFilterToQueryString({
      page: 0,
      size: 10,
      sort: 'id,asc',
      type: 'fullName',
    });
    setReset(!reset);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center management-layout-header" style={{ paddingTop: 30 }}>
        <div className="d-flex align-items-center">
          <h2 id="player-heading" data-cy="PlayerHeading" className="heading-20-bold neutral-1">
            <Translate contentKey="leagueApp.leagueRecorder.home.title" />
          </h2>
          <div className="management-layout-divider"></div>
          <Button className="rounded management-header-add-button" onClick={handleViewDetail} icon={<PlusIcon />} type="primary">
            <Translate contentKey="leagueApp.leagueRecorder.home.createLabel"></Translate>
          </Button>
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <Filter filter={filter} onFilter={onFilterToQueryString} multi options={options} />
        </Col>
      </div>
      <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={leagueRecorderList.content}
          loading={loading}
          filter={filter}
          totalElements={leagueRecorderList.totalElements || 0}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('leagueApp.leagueRecorder.delete.question')}</h6>}
          onClose={() => setModalDelete(false)}
          onConfirm={() => handleDelete()}
        />
      )}
      {modalUpdate ? <LeagueRecorderCreateDialog modalUpdate={modalUpdate} setModalUpdate={setModalUpdate} viewDetail={viewDetail} /> : ''}
    </div>
  );
};

export default LeagueRecorder;
