import React, { useContext, useEffect, useRef, useState } from 'react';
import type { InputRef } from 'antd';
import { Button, Form, Input, Popconfirm, Table } from 'antd';
import type { FormInstance } from 'antd/es/form';
import TextField from 'app/modules/lm-input';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface IPlayerGoalRecord {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof IPlayerGoalRecord;
  record: IPlayerGoalRecord;
  onChange: (record: IPlayerGoalRecord) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({ title, editable, children, dataIndex, record, onChange, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <TextField
          ref={inputRef}
          onBlurCapture={e => {
            onChange({ ...record, [dataIndex]: e.target.value?.trim() });
          }}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap w-100 h-100"
        style={{ paddingRight: 24 }}
        onClick={e => {
          setEditing(true);
          form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <td
      {...restProps}
      onClick={e => {
        if (editable) {
          setEditing(true);
          form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        }
      }}
      onBlur={e => {
        if (editable) {
          setEditing(false);
        }
      }}
    >
      {childNode}
    </td>
  );
};

type EditableTableProps = Parameters<typeof Table>[0];
