import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { translate } from 'react-jhipster';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { changeLogLevel } from 'app/modules/administration/administration.reducer';
import { Action } from 'rxjs/internal/scheduler/Action';

const initialState = {
  loading: false,
  registrationSuccess: false,
  registrationFailure: false,
  errorMessage: null,
  successMessage: null,
  data: null,
  role: null,
  unit: null,
};

export type RegisterState = Readonly<typeof initialState>;

// Actions
interface IFormRegister {
  login: string;
  password: string;
  langKey?: string;
  fullName: string;
  authorities: Array<string>;
  phoneNumber: string;
  unitCode?: string;
  unitStandardId: string;
}

export const handleRegister = createAsyncThunk(
  'api/register',
  async (data: IFormRegister) => {
    return axios.post<any>('api/register', data);
  },
  { serializeError: serializeAxiosError }
);

// export const handleRole = createAsyncThunk(
//   'api/author',
//   async () => {
//     const data = await axios.get<any>('api/author');
//     // data
//     //   .then(res => res)
//     //   .catch(err => {
//     //     if (err.response.status === 403) {
//     //       toast.error(translate('error.http.403'));
//     //     }
//     //   });
//     return data;
//   },
//   { serializeError: serializeAxiosError }
// );

export const handleUnit = createAsyncThunk(
  'api/getAll-unit-standards',
  async () => {
    return axios.get<any>('api/getAll-unit-standards');
  },
  { serializeError: serializeAxiosError }
);

export const RegisterSlice = createSlice({
  name: 'register',
  initialState: initialState as RegisterState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(handleRegister.pending, state => {
        state.loading = true;
        state.errorMessage = null
      })
      .addCase(handleRegister.rejected, (state, action) => ({
        ...initialState,
        registrationFailure: true,
        errorMessage: 'register.messages.error.userexists',
      }))
      .addCase(handleRegister.fulfilled, () => ({
        ...initialState,
        registrationSuccess: true,
        successMessage: 'register.messages.success',
      }))

  },
});

export const { reset } = RegisterSlice.actions;

// Reducer
export default RegisterSlice.reducer;
