import axios from 'axios';
import { createAsyncThunk, EntityState, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, serializeAxiosError, ISearchObjectParams } from 'app/shared/reducers/reducer.utils';
import { IStadium, defaultValue } from 'app/shared/model/stadium.model';
import { IMonitor } from 'app/shared/model/monitor.model';
import { toast } from 'react-toastify';
import { translate } from 'react-jhipster';

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  successMessage: null,
  deleteSuccess: false,
  createSuccess: false,
};

const apiUrl = 'api/stadiums';
const apiSearchUrl = 'api/stadiums/search';
// Actions

export const getEntities = createAsyncThunk('stadium/fetch_entity_list', async ({ page, size, sort, ...res }: IQueryParams) => {
  const requestUrl = `${apiSearchUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}`;
  return axios.post<IStadium[]>(requestUrl, res);
});

export const getEntity = createAsyncThunk(
  'stadium/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IStadium>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'stadium/create_entity',
  async (entity: IStadium, thunkAPI) => {
    const result = await axios.post<IStadium>(apiUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const updateEntity = createAsyncThunk(
  'stadium/update_entity',
  async (entity: IStadium, thunkAPI) => {
    const result = await axios
      .put<IStadium>(`${apiUrl}/${entity.id}`, cleanEntity(entity))
      // .then(res => toast.success(translate('leagueApp.stadium.updated.success')))
      // .catch(err => toast.error(translate('leagueApp.stadium.updated.fail')));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'stadium/partial_update_entity',
  async (entity: IStadium, thunkAPI) => {
    const result = await axios.patch<IStadium>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'stadium/delete_entity',
  async (deleteStadium: any, thunkAPI) => {
    const requestUrl = `${apiUrl}/${deleteStadium?.stadiumId}`;
    const result = await axios.delete<IStadium>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

/** search */

export const searchEntities = createAsyncThunk('stadium/search_entity_list', async ({ page, size, sort, payload }: ISearchObjectParams) => {
  const requestUrl = `${apiSearchUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.post<IMonitor[]>(requestUrl, payload);
});
// slice

export const StadiumSlice = createEntitySlice({
  name: 'stadium',
  initialState,
  reducers: {
    clearMsgUpdate: (state, action) => void (state.updateSuccess = null),
    clearMsgDelete: (state, action) => void (state.deleteSuccess = null),
    clearMsgCreate: (state, action) => void (state.createSuccess = null),
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.entity = {};
        state.deleteSuccess = true;
      })

      /* .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })*/
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          entity: {},
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.createSuccess = true;
        state.entity = {};
      })
      .addMatcher(isRejected(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.createSuccess = false;
        state.entity = {};
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, clearMsgDelete, clearMsgUpdate, clearMsgCreate } = StadiumSlice.actions;

// Reducer
export default StadiumSlice.reducer;
