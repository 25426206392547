import dayjs from 'dayjs';

export interface IStadium {
  id?: number;
  name?: string | null;
  address?: string | null;
  total?: number | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
}

export const defaultValue: Readonly<IStadium> = {};
