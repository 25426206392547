import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getPlayerBeforeMatch, getPlayers } from 'app/entities/match/match.reducer';
import { ModalSquad } from 'app/entities/match/MatchPreparation/container/squad/modal-squad';
import { IDefaultMatch } from 'app/shared/model/match.model';
import React, { useEffect, useState } from 'react';
// import { getPlayers } from '../match/match.reducer';
// import { ModalSquad } from '../match/MatchPreparation/container/squad/modal-squad';
interface IFormProps {
  id?: string | number;
  ownerClubId?: string | number;
  awayClubId?: string | number;
  isOwner?: boolean;
  number?: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  typeEdit: string;
  onSubmit?: (body: any) => void;
  customLabel?: boolean;
  processTime: any;
  defaultPlayerId?: number;
}

const ChangePlayer = ({
  processTime,
  id,
  ownerClubId,
  awayClubId,
  isOwner,
  number,
  isOpen,
  setIsOpen,
  typeEdit,
  onSubmit,
  customLabel,
  defaultPlayerId,
}: IFormProps) => {
  const dispatch = useAppDispatch();
  const playerFreeOwner = useAppSelector(state => state.match.playerFreeOwner);
  const playerFreeAway = useAppSelector(state => state.match.playerFreeAway);
  const playerFieldsOwner = useAppSelector(state => state.match.playerFieldsOwner);
  const playerReserveOwner = useAppSelector(state => state.match.playerReserveOwner);
  const playerFieldsAway = useAppSelector(state => state.match.playerFieldsAway);
  const playerReserveAway = useAppSelector(state => state.match.playerReserveAway);
  useEffect(() => {
    if (ownerClubId && id && typeEdit !== 'change_player') {
      dispatch(
        getPlayers({
          ownerClubId,
          id,
        })
      );
    }
    if (ownerClubId && id && typeEdit === 'change_player') {
      dispatch(
        getPlayerBeforeMatch({
          ownerClubId,
          id,
        })
      );
    }
  }, [id, ownerClubId, typeEdit]);
  useEffect(() => {
    if (id && awayClubId && typeEdit !== 'change_player') {
      dispatch(
        getPlayers({
          awayClubId,
          id,
        })
      );
    }
    if (awayClubId && id && typeEdit === 'change_player') {
      dispatch(
        getPlayerBeforeMatch({
          awayClubId,
          id,
        })
      );
    }
  }, [awayClubId, id, typeEdit]);
  return (
    <>
      {isOpen && typeEdit !== 'change_player' && (
        <ModalSquad
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          clubId={ownerClubId || awayClubId}
          leftData={isOwner ? playerFreeOwner : playerFreeAway}
          rightData={number === 1 ? (isOwner ? playerFieldsOwner : playerFieldsAway) : isOwner ? playerReserveOwner : playerReserveAway}
          isOwner={isOwner}
          typeEdit={typeEdit}
          type={number}
          matchId={id}
          onSubmit={onSubmit}
          processTime={processTime}
          defaultPlayerId={defaultPlayerId}
        />
      )}
      {isOpen && typeEdit === 'change_player' && (
        <ModalSquad
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          leftData={isOwner ? playerFieldsOwner : playerFieldsAway}
          rightData={isOwner ? playerReserveOwner : playerReserveAway}
          isOwner={isOwner}
          customLabel={customLabel}
          clubId={ownerClubId || awayClubId}
          matchId={id}
          typeEdit={typeEdit}
          onSubmit={onSubmit}
          processTime={processTime}
          defaultPlayerId={defaultPlayerId}
        />
      )}
    </>
  );
};

export default ChangePlayer;
