import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'antd';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity } from './club.reducer';

export const ClubDeleteDialog = (props: any) => {
  const { modalDelete, userClicked, setModalDelete } = props;
  const [loadModal, setLoadModal] = useState(false);
  const dispatch = useAppDispatch();



  const clubEntity = useAppSelector(state => state.club.entity);
  const updateSuccess = useAppSelector(state => state.club.updateSuccess);

  const handleClose = () => {
    setModalDelete(false);
  };



  const confirmDelete = () => {
    const obj = {
      clubId: userClicked,
    };
    dispatch(deleteEntity(obj));
    setModalDelete(false);
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="clubDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="leagueApp.club.delete.question">
        <Translate contentKey="leagueApp.club.delete.question">Are you sure you want to delete this Club?</Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-club" data-cy="entityConfirmDeleteButton" color="danger" onClick={props.onSubmit}>
          <Translate contentKey="entity.action.delete2">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ClubDeleteDialog;
