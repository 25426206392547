import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Avatar, Card, Col, Form, Row, Tabs } from 'antd';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import { MatchPreparationInformation } from './container/general';
import { MatchPreparationPlan } from './container/plan';
import { MatchPreparationSquad } from './container/squad';
import { getEntity, setSave } from '../match.reducer';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import { Referee } from './container/referee';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';
import { FormChangeInfo } from 'rc-field-form/es/FormContext';

export const pathLink = {
  general: 'general',
  squad: 'squad',
  plan: 'plan',
  referee: 'referee',
};

export const MatchPreparation = ({ match }) => {
  const [newState, setNewState] = useState({
    open: false,
    path: 'general',
  });
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const contentType = 'image/png';
  const matchList = useAppSelector(state => state.match?.entities);
  const loading = useAppSelector(state => state.match?.loading);
  const totalItems = useAppSelector(state => state.match?.totalItems);
  const matchInfoEntity = useAppSelector(state => state.match.entity);
  const save = useAppSelector(state => state.match.save);

  const { push } = useHistory();
  const onChange = (path: string) => {
    if (!save) {
      setNewState({
        open: true,
        path,
      });
    } else {
      push(path);
    }
  };
  const handleConfirm = () => {
    push(newState.path);
    setNewState({
      open: false,
      path,
    });
    dispatch(setSave(true));
  };

  const { path, url } = useRouteMatch();
  const param = useParams<{ id: string; params: string }>();

  React.useEffect(() => {
    if (!match.params.id) {
      return;
    }
    const section = document.querySelector('#main');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    dispatch(getEntity(match.params.id));
  }, []);
  const matchBasicInfoEntity = useAppSelector(state => state.match.entity);

  const container = [
    {
      label: <Translate contentKey="common.general" />,
      path: match.path.replace(':id', param.id).replace(':params', pathLink.general),
      param: pathLink.general,
    },
    {
      label: <Translate contentKey="common.squad" />,
      path: match.path.replace(':id', param.id).replace(':params', pathLink.squad),
      param: pathLink.squad,
    },
    {
      label: <Translate contentKey="common.plan" />,
      path: match.path.replace(':id', param.id).replace(':params', pathLink.plan),
      param: pathLink.plan,
    },
    {
      label: <Translate contentKey="common.referee" />,
      path: match.path.replace(':id', param.id).replace(':params', pathLink.referee),
      param: pathLink.referee,
    },
  ];

  const handleFormChange = (name: string, info: FormChangeInfo) => {
    if (name === 'general') {
      if (info.changedFields.length) {
        if (!save) {
          return;
        }
        dispatch(setSave(false));
      }
    }
    if (name === 'referee') {
      if (info.changedFields.length) {
        if (!save) {
          return;
        }
        dispatch(setSave(false));
      }
    }
  };
  return (
    <div className="match-preparetion">
      <Card style={{ marginBottom: 32 }}>
        <Row gutter={[0, 32]} className="justify-content-between align-items-center">
          <Col lg={8} xl={6} xs={24} className="d-flex align-items-center">
            <Avatar src={matchInfoEntity?.lsBase64Avt} />
            <p className="mb-0 px-4 neutral-3">{matchBasicInfoEntity?.leagueName}</p>
            <p className="h5 mb-0 neutral-1">
              {matchInfoEntity?.matchRound || 1} 
            </p>
          </Col>
          <Col flex="auto" className="d-flex justify-content-center align-items-center">
            <p className="h5 mb-0 neutral-1">{matchBasicInfoEntity?.ownerClubName}</p>
            <span className="px-4 neutral-3">{translate('leagueApp.match.vs')}</span>
            <p className="h5 mb-0 neutral-1">{matchBasicInfoEntity?.awayClubName}</p>
          </Col>
          <Col lg={8} xl={7} xs={24} className="d-flex align-items-center" style={{ justifyContent: 'flex-end' }}>
            <p className="h6 mb-0 neutral-1">{convertDateTimeFromServer(matchBasicInfoEntity?.startDate)}</p>
            <p className="mb-0 neutral-3" style={{ marginLeft: 20 }}>
              {matchBasicInfoEntity?.stadiumName}
            </p>
          </Col>
        </Row>
      </Card>
      <Tabs onChange={onChange} type="card" activeKey={param.params}>
        {container.map((item, index) => (
          <Tabs.TabPane tab={item.label} key={item.param} />
        ))}
      </Tabs>
      <Card className="card-after-tabs">
        <ConfirmationDialog
          onClose={() =>
            setNewState({
              open: false,
              path,
            })
          }
          open={newState.open}
          onConfirm={handleConfirm}
          message={
            <Translate contentKey="leagueApp.match.notSave">You haven not saved the value yet. Do you want to change the page?</Translate>
          }
        />
        <Form.Provider onFormChange={handleFormChange}>
          <Switch>
            <ErrorBoundaryRoute exact path={match.path.replace(':params', pathLink.general)} component={MatchPreparationInformation} />
            <ErrorBoundaryRoute path={match.path.replace(':params', pathLink.plan)} component={MatchPreparationPlan} />
            <ErrorBoundaryRoute path={match.path.replace(':params', pathLink.squad)} component={MatchPreparationSquad} />
            <ErrorBoundaryRoute path={match.path.replace(':params', pathLink.referee)} component={Referee} />
            <Redirect to={match.path.replace(':id', param.id).replace(':params', pathLink.general)} />
          </Switch>
        </Form.Provider>
      </Card>
    </div>
  );
};
