import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Avatar, Button, Card, Col, Form, Input, Row, Select, Tabs } from 'antd';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import { PlayerLeague } from '../player-league/player-league';
import { ClubUniform } from '../club-uniform/club-uniform';
import { ClubOfficer } from '../club-officer/club-officer';
import { clearMsgUpdate, getAvatarClub, getEntity, updateEntity } from '../club/club.reducer';
import { getEntity as getLeagueEntity } from '../league/league.reducer';

import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import NumberField from 'app/modules/lm-input-number';
import TextField from 'app/modules/lm-input';
import { getBase64, getImage, upLoadImg, UploadType } from 'app/shared/reducers/reducer.utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { SearchIcon, SelectArrowDown } from 'app/shared/util/appIcon';
import { handleFocusFieldFail, validateSizeImage } from 'app/shared/util/entity-utils';
import DatePicker from 'app/modules/date-picker';
import dayjs from 'dayjs';
import { HomeOutlined } from '@ant-design/icons';
import { handleValidateMessageLanguageChanged } from 'app/shared/util/validate';
import { BsCaretLeft } from 'react-icons/bs';
import { ColumnsType } from 'antd/lib/table';
import SearchModal from 'app/modules/seatch-modal';
import { first } from 'lodash';
import { hasAnyAuthority, isRole } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { ICoach } from 'app/shared/model/coach.model';

export const LeagueAttendDetail = ({ match }) => {
  const dispatch = useAppDispatch();
  const clubEntity = useAppSelector(state => state.club.entity);
  const clubList = useAppSelector(state => state.club.entities);
  const loading = useAppSelector(state => state.club.loading);
  const totalItems = useAppSelector(state => state.match?.totalItems);
  const links = useAppSelector(state => state.club.links);
  const updateSuccess = useAppSelector(state => state.club.updateSuccess);
  const [searchUnitStandard, setSearchUnitStandard] = useState(false);
  const [searchTeamManager, setSearchTeamManager] = useState(false);
  const [searchCoach, setSearchCoach] = useState(false);
  const [previewImg, setPreviewImg] = useState('');
  const [file, setFile] = useState<File>(null);
  const { push } = useHistory();
  const [form] = Form.useForm();
  const [stadium, setStadium] = useState([]);
  const [dataCoach, setDataCoach] = useState([]);
  const history = useHistory();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const account = useAppSelector(state => state.authentication.account);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const isLeagueMaster = isRole(authorities, [AUTHORITIES.LEAGUE_MASTER]);
  const isTeamManager = isRole(authorities, [AUTHORITIES.TEAM_MANAGER]);
  const [searchCoachModal, setSearchCoachModal] = useState(false);
  const [coachs, setCoachs] = React.useState<ICoach[]>([]);
  const coach = Form.useWatch('coaches', form);
  const leagueEntity = useAppSelector(state => state.league.entity);

  const getCoachs = async (coachName?: string) => {
    try {
      const fetchCoachs = await axios.get<any>(`api/coaches?searchName=${coachName || ''}`);
      setCoachs(fetchCoachs.data);
    } catch (error) {
      setCoachs([]);
    }
  };

  const [entitySave, setEntitySave] = useState({
    name: '',
    issueYear: '',
    address: '',
    website: '',
    description: '',
    shortName: '',
    fullName: '',
    stadiumId: '',
    coachName: '',
  });

  useEffect(() => {
    handleValidateMessageLanguageChanged(form);
  }, [locale]);

  useEffect(() => {
    const section = document.querySelector('#main');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      toast.success(translate('leagueApp.club.updated.success'));
      dispatch(clearMsgUpdate(updateSuccess));
      dispatch(getEntity(match.params.id));
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (clubEntity && clubEntity.id) {
      form.setFieldsValue({
        ...clubEntity,
        issueYear: clubEntity.issueYear ? dayjs(clubEntity.issueYear) : null,
        managementName: clubEntity?.clubManagementDTOList && clubEntity?.clubManagementDTOList.map(item => item.userDTO?.fullName),
        managementId: clubEntity?.clubManagementDTOList && clubEntity?.clubManagementDTOList.map(item => item.userDTO?.id),
      });
      setEntitySave({ ...clubEntity });
      if (clubEntity.avatar) {
        getImage(clubEntity.avatar, UploadType.AVATAR_CLUB).then(result => {
          setPreviewImg('data:image/png;base64,' + result.data);
        });
      } else {
        setPreviewImg(null);
        setFile(null);
      }
    }
  }, [clubEntity]);

  function saveData(data: any, oldAvatar) {
    if (clubEntity.id) {
      data = {
        ...clubEntity,
      };
    }
    const submitData = {
      id: data.id,
      clubManagementDTOList: form.getFieldValue('managementId').map(item => {
        return {
          clubId: data.id,
          managementId: item,
        };
      }),
      avatar: oldAvatar,
      name: form.getFieldValue('name'),
      issueYear: form.getFieldValue('issueYear'),
      address: form.getFieldValue('address'),
      website: form.getFieldValue('website'),
      description: form.getFieldValue('description'),
      shortName: form.getFieldValue('shortName'),
      fullName: form.getFieldValue('fullName'),
      stadiumId: form.getFieldValue('stadiumId'),
      coachName: form.getFieldValue('coachName'),
      unitStandardId: form.getFieldValue('unitStandardId'),
      managerId: form.getFieldValue('managerId'),
    };
    if (data.id) {
      dispatch(updateEntity(submitData));
    }
  }

  useEffect(() => {
    handleStadium({ page: 0, size: 1000 });
  }, []);

  const handleStadium = async ({ page, size }: any) => {
    const dataStadium = await axios.post<any>(`api/stadiums/search?page=${page}&size=${size}`, {});
    const arrStadium = [];
    dataStadium.data.content.map(a =>
      arrStadium.push({
        value: a.id,
        label: a.name,
      })
    );
    setStadium(arrStadium);
  };

  const handleValidSubmit = e => {
    const data: any = {};
    let oldAvatar = clubEntity.avatar;
    if (file) {
      const bodyImg = new FormData();
      bodyImg.append('file', file);
      bodyImg.append('uploadType', 'AVATAR_CLUB');
      upLoadImg(bodyImg).then(result => {
        oldAvatar = result.data;
        saveData(data, oldAvatar);
      });
    } else {
      saveData(data, oldAvatar);
    }
  };

  const handlePreviewAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileImg = e.target.files[0];
    if (validateSizeImage(fileImg, e)) {
      setFile(fileImg);
      getBase64(fileImg, result => {
        setPreviewImg(result);
      });
    }
  };

  const onChange = (path: string) => {
    push(path);
  };

  const pathLink = {
    player: 'player-league',
    uniform: 'uniform-club',
    clubOfficer: 'club-officer',
  };

  const { path, url } = useRouteMatch();
  const param = useParams<{ leagueId: string; params: string }>();
  const unitStandardData = Form.useWatch('unitStandardId', form);
  const [teamManager, setTeamManager] = useState([]);
  const [unitStandard, setUnitStandard] = useState([]);
  const managementId = Form.useWatch('managementId', form);
  const getClubManager = async (searchName?: string) => {
    try {
      const fetchClubs = await axios.get(
        `api/clubs/get-list-assign-club-management?unitStandardId=${unitStandardData}&searchName=${searchName || ''}`
      );
      setTeamManager(fetchClubs?.data);
    } catch (error) {
      setTeamManager([]);
    }
  };

  const getUnitStandard = async (unitName?: string) => {
    try {
      const fetchUnitStandard = await axios.post(`api/search-unit-standards?page=0size=1000`, {
        name: unitName || '',
      });
      setUnitStandard(fetchUnitStandard?.data?.content);
    } catch (error) {
      setUnitStandard([]);
    }
  };

  const optionsUnit = [
    {
      value: 'name',
      label: translate('leagueApp.unitStandard.name'),
    },
  ];

  const optionClubManager = [
    {
      value: 'name',
      label: translate('leagueApp.unitStandard.name'),
    },
  ];

  const optionCoachs = [
    {
      value: 'name',
      label: translate('leagueApp.unitStandard.name'),
    },
  ];

  const columnCoachs = [
    {
      title: <Translate contentKey="leagueApp.unitStandard.name" />,
      dataIndex: 'coachName',
      key: 'coachName',
    },
  ];

  const columnClubManager = [
    {
      title: <Translate contentKey="leagueApp.unitStandard.name" />,
      dataIndex: 'fullName',
      key: 'fullName',
    },
  ];

  const columnsUnit: ColumnsType<any> = [
    {
      title: <Translate contentKey="leagueApp.unitStandard.name">Name</Translate>,
      dataIndex: 'name',
      key: 'name',
    },
  ];
  React.useEffect(() => {
    if (!match.params.id) {
      return;
    }
    dispatch(getEntity(match.params.id));
  }, []);
  React.useEffect(() => {
    if (!match.params.leagueId) {
      return;
    }
    dispatch(getLeagueEntity(match.params.leagueId));
  }, []);
  const container = [
    {
      label: <Translate contentKey="leagueApp.tab.player" />,
      path: match.path.replace(':leagueId', param.leagueId).replace(':params', pathLink.player),
      param: pathLink.player,
    },
    {
      label: <Translate contentKey="leagueApp.tab.uniform" />,
      path: match.path.replace(':leagueId', param.leagueId).replace(':params', pathLink.uniform),
      param: pathLink.uniform,
    },
    {
      label: <Translate contentKey="leagueApp.tab.clubOfficer" />,
      path: match.path.replace(':leagueId', param.leagueId).replace(':params', pathLink.clubOfficer),
      param: pathLink.clubOfficer,
    },
  ];
  const location = useLocation();

  const handleBack = e => {
    history.goBack();
  };

  return (
    <div>
      <Card className="mb-3">
        <Form
          form={form}
          onFinishFailed={values => handleFocusFieldFail(values)}
          scrollToFirstError
          name="control-hooks"
          onFinish={handleValidSubmit}
          layout="vertical"
          requiredMark={'optional'}
          initialValues={{
            ...entitySave,
          }}
        >
          <div className="border-bottom d-flex justify-content-between align-items-center" style={{ paddingBottom: 30, marginBottom: 32 }}>
            <div className="d-flex align-items-center auto-wrap">
              <Button className="rounded management-header-add-button" icon={<BsCaretLeft />} onClick={handleBack} type="primary">
                <Translate contentKey="entity.action.back" />
              </Button>
              <div className="management-layout-divider"></div>
              <p className="h4 mb-0">{clubEntity.name}</p>
              <div className="management-layout-divider"></div>
              <p className="h4 mb-0">{leagueEntity?.name}</p>
            </div>
            {/* <Button htmlType="submit" type="primary">
              <Translate contentKey="common.save" />
            </Button> */}
          </div>
          {/* <Row gutter={[24, 12]}>
            <Col xl={12} lg={12} xs={24}>
              <Form.Item
                label={<h6>{translate('leagueApp.club.table.clubName1')}</h6>}
                name="name"
                rules={[{ required: true, message: translate('leagueApp.club.required.name') }]}
              >
                <TextField placeholder={translate('leagueApp.club.placeholder.clubNamePlaceholder')}></TextField>
              </Form.Item>
              <Row gutter={[12, 12]}>
                <Col xl={12} lg={12} xs={24}>
                  <Form.Item
                    label={<h6>{translate('leagueApp.club.table.issueYear')}</h6>}
                    rules={[{ required: true, message: translate('leagueApp.club.required.issueYear') }]}
                    name="issueYear"
                  >
                    <DatePicker
                      picker="year"
                      style={{ width: '100%' }}
                      format="YYYY"
                      allowClear
                      placeholder={translate('leagueApp.club.placeholder.issueYear')}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} xs={24}>
                  <Form.Item label={<h6>{translate('leagueApp.club.mainStadium')}</h6>} name="stadiumId">
                    <Select
                      placeholder={translate('leagueApp.club.placeholder.stadiumPlaceholder')}
                      allowClear
                      className="custom-prefix-select"
                      showSearch
                      suffixIcon={<SearchIcon />}
                      options={stadium}
                      filterOption={(inputValue: string, option?: any) => {
                        return (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase());
                      }}
                    >
                      {stadium.map((nations, index) => (
                        <Select.Option value={nations.value} key={index}>
                          {nations.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label={<h6>{translate('leagueApp.club.address')}</h6>} name="address">
                <TextField placeholder={translate('leagueApp.club.placeholder.address')}></TextField>
              </Form.Item>
              <Form.Item
                label={<h6>{translate('leagueApp.club.website')}</h6>}
                name="website"
                rules={[
                  {
                    type: 'url',
                    message: translate('leagueApp.club.required.website'),
                  },
                ]}
              >
                <TextField placeholder={translate('leagueApp.club.placeholder.website')}></TextField>
              </Form.Item>
              <Form.Item label={<h6>{translate('leagueApp.club.description1')}</h6>} name="description">
                <Input.TextArea placeholder={translate('leagueApp.club.placeholder.description')}></Input.TextArea>
              </Form.Item>
            </Col>
            <Col xl={6} lg={12} xs={24} className="lg-vertical-divider">
              <Form.Item
                label={<h6>{translate('leagueApp.club.table.shortName')}</h6>}
                rules={[{ required: true, message: translate('leagueApp.club.required.shortName') }]}
                name="shortName"
              >
                <TextField placeholder={translate('leagueApp.club.placeholder.shortNamePlaceholder')}></TextField>
              </Form.Item>

              <Form.Item name="coachName" label={<h6>{translate('leagueApp.club.coach')}</h6>}>
                <Input
                  placeholder={translate('leagueApp.club.placeholder.coachName')}
                  suffix={
                    <Button onClick={() => setSearchCoachModal(true)} className="position-absolute" style={{ right: '8px' }}>
                      <Translate contentKey="leagueApp.league.choose" />
                    </Button>
                  }
                  readOnly
                  className="position-relative"
                ></Input>
              </Form.Item>

              <Form.Item label={<h6>{translate('leagueApp.club.managerId')}</h6>} name="fullName">
                <Input disabled></Input>
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.club.required.organization'),
                  },
                ]}
                label={<h6>* {translate('leagueApp.notice.noticeCategoryArr.organization')}</h6>}
                name="unitStandardName"
              >
                <Input
                  readOnly
                  suffix={
                    <Button
                      disabled={isLeagueMaster || isTeamManager ? true : false}
                      onClick={() => setSearchUnitStandard(true)}
                      className="position-absolute"
                      style={{ right: '8px' }}
                    >
                      <Translate contentKey="leagueApp.league.choose" />
                    </Button>
                  }
                ></Input>
              </Form.Item>
              <Form.Item
                name="managementName"
                label={<h6>* {translate('leagueApp.club.detail.title1')}</h6>}
                rules={[
                  {
                    required: true,
                    message: translate('leagueApp.required.clb'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('leagueApp.club.placeholder.teamManagement')}
                  suffix={
                    <Button
                      disabled={!unitStandardData || isTeamManager ? true : false}
                      onClick={() => {
                        setSearchTeamManager(true);
                      }}
                      className="position-absolute"
                      style={{ right: '8px' }}
                    >
                      <Translate contentKey="leagueApp.league.choose" />
                    </Button>
                  }
                  readOnly
                ></Input>
              </Form.Item>
              <Form.Item name="coachId">
                <Input type={'hidden'}></Input>
              </Form.Item>
              <Form.Item name="unitStandardId">
                <Input type={'hidden'}></Input>
              </Form.Item>
              <Form.Item name="managementId">
                <Input type={'hidden'}></Input>
              </Form.Item>
            </Col>
            <Col xl={6} lg={12} xs={24}>
              <Form.Item name="avatar">
                <div style={{ width: '100%', objectFit: 'fill' }} className="d-flex justify-content-center">
                  <label htmlFor="uploadImage" className="upload-file" style={{ width: '100%', padding: '50% 0' }}>
                    {previewImg ? (
                      <img
                        src={previewImg}
                        alt="logo"
                        style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: 0 }}
                      />
                    ) : (
                      <span>+ UPLOAD</span>
                    )}
                  </label>
                  <Input id="uploadImage" value={null} hidden type="file" accept="image/*" onChange={handlePreviewAvatar} />
                </div>
              </Form.Item>
            </Col>
          </Row> */}
          {searchUnitStandard && (
            <SearchModal
              onClose={() => {
                setSearchUnitStandard(false);
              }}
              options={optionsUnit}
              open={searchUnitStandard}
              list={unitStandard}
              columns={columnsUnit}
              onFetchData={(data: { name?: string }) => getUnitStandard(data?.name)}
              title={translate('leagueApp.unitStandard.home.title')}
              subTitle={translate('leagueApp.unitStandard.chooseUnit')}
              multiple={false}
              onAccept={(selected: any[]) => {
                form.setFieldsValue({
                  unitStandardName: first(selected)?.name,
                  unitStandardId: first(selected)?.id,
                  managementId: [],
                  managementName: [],
                });
                setSearchUnitStandard(false);
              }}
              selected={[unitStandardData]}
            />
          )}
          {searchTeamManager && (
            <SearchModal
              onClose={() => {
                setSearchTeamManager(false);
              }}
              options={optionClubManager}
              open={searchTeamManager}
              list={teamManager}
              columns={columnClubManager}
              onFetchData={(data: { name?: string }) => getClubManager(data?.name)}
              multiple={false}
              selected={managementId}
              onAccept={(selected: any[]) => {
                form.setFieldsValue({ managementId: selected.map(item => item?.id), managementName: selected.map(item => item?.fullName) });
                setSearchTeamManager(false);
              }}
              title={translate('sidebar.teamManagement2')}
              subTitle={translate('leagueApp.player.chooseTeamManager1')}
            />
          )}

          {searchCoachModal && (
            <SearchModal
              onClose={() => {
                setSearchCoachModal(false);
              }}
              open={searchCoachModal}
              list={coachs}
              columns={columnCoachs}
              options={optionCoachs}
              onFetchData={(data: { name?: string }) => getCoachs(data?.name)}
              title={translate('leagueApp.club.home.hlv')}
              subTitle={translate('leagueApp.club.home.hlv')}
              multiple={false}
              onAccept={(selected: any[]) => {
                form.setFieldsValue({ coachName: first(selected)?.coachName, coachId: first(selected)?.id });
                setSearchCoachModal(false);
              }}
              selected={[coach]}
            />
          )}
        </Form>
      </Card>
      <Tabs onChange={onChange} type="card" activeKey={param.params}>
        {container.map((item, index) => (
          <Tabs.TabPane tab={item.label} key={item.param} />
        ))}
      </Tabs>
      <Card className="card-after-tabs">
        <Switch>
          <ErrorBoundaryRoute exact path={match.path.replace(':params', pathLink.player)} component={PlayerLeague} />
          <ErrorBoundaryRoute path={match.path.replace(':params', pathLink.uniform)} component={ClubUniform} />
          <ErrorBoundaryRoute path={match.path.replace(':params', pathLink.clubOfficer)} component={ClubOfficer} />
          <Redirect to={match.path.replace(':leagueId', param.leagueId).replace(':params', pathLink.player)} />
        </Switch>
      </Card>
      {/* <LeagueAttend id={idClub.id} /> */}
    </div>
  );
};
export default LeagueAttendDetail;
