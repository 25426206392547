import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Radio } from 'antd';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
// import { getEntity, deleteEntity, createEntity, clearMsgCreate } from './player-club.reducer';
// import { getEntities } from './get-free-player.reducer';
// import Table from 'app/modules/table';
import { FilterParams } from 'app/shared/model/filter.model';
import { handleFocusFieldFail } from 'app/shared/util/entity-utils';

export const ExportFileMathDialog = (props: any) => {
  const [futsal, setFutsal] = useState(true);
  const { createModal, setCreateModal } = props;
  const { handleClickSubmit } = props;
  const { type } = props;
  const handleClose = () => {
    setCreateModal(false);
  };
  const locale = useAppSelector(state => state.locale.currentLocale);
  const [classify, setClassify] = useState([
    {
      value: true,
      label: translate('leagueApp.leagueStandard.futsal'),
    },
    {
      value: false,
      label: translate('leagueApp.leagueStandard.11'),
    },
  ]);

  const [disableView, setDisableView] = useState(false);
  useEffect(() => {
    if (type === 'view') {
      setDisableView(true);
    } else {
      setDisableView(false);
    }
  }, [type]);
  const [form] = Form.useForm();

  return (
    <Modal
      visible={createModal}
      onCancel={handleClose}
      footer={null}
      width={900}
      wrapClassName="management-wrapper-modal"
      title={<Translate contentKey="leagueApp.playerClub.choose1" />}
    >
      <Form
        form={form}
        onFinishFailed={values => handleFocusFieldFail(values)}
        scrollToFirstError
        name="control-hooks"
        layout="vertical"
        requiredMark={'optional'}
      >
        <Translate contentKey="leagueApp.playerClub.question1" />
        <Col span={24}>
          <Form.Item
            name="classify"
            label={<h6>{translate('leagueApp.leagueStandard.popup.classify')}</h6>}
            rules={[
              {
                required: true,
                message: translate('leagueApp.leagueStandard.required.classify'),
              },
            ]}
          >
            <Radio.Group disabled={disableView} options={classify} defaultValue={futsal} onChange={e=>setFutsal(e.target.value)}></Radio.Group>
          </Form.Item>
        </Col>

        <Col span={24}>
          <div className="management-modal-footer">
            <Button color="secondary" onClick={handleClose}>
              <Translate contentKey="entity.action.cancel"></Translate>
            </Button>
            <Button
              id="jhi-confirm-delete-clubUniform"
              type="primary"
              style={{ marginLeft: 12 }}
              onClick={() => {
                handleClickSubmit({
                  matchId: props.matchId,
                  lang: locale,
                  zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  futsal,
                });
              }}
            >
              <Translate contentKey="entity.action.save1"></Translate>
            </Button>
          </div>
        </Col>
      </Form>
    </Modal>
  );
};

export default ExportFileMathDialog;
