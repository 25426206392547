import { Droppable } from 'react-beautiful-dnd';
import React from 'react';
import styled from 'styled-components';
import ListItem from './list-item';
import { IPlayerInMatch } from 'app/shared/model/player.model';
import { get } from 'lodash';

const DroppableStyles = styled.div`
  padding: 5px;
  border-radius: 6px;
  height: 100%;
  [data-rbd-droppable-context-id] {
    min-height: 120px;
    min-width: 100px;
  }
  + div {
    height: 100%;
    display: flex;
  }
`;

const NewLineUp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const DraggableElement = ({ prefix, elements, onClickPlayer, avatars, notAllow }: any) => (
  <DroppableStyles>
    <NewLineUp className="h-100">
      {elements.map((item: IPlayerInMatch, index: number) => (
        <ListItem
          onClickPlayer={(player: IPlayerInMatch) => onClickPlayer && onClickPlayer(player)}
          key={item.matchPlayerFieldDTO.playerDTOS.id.toString()}
          prefix={prefix}
          item={item}
          index={index}
          notAllow={notAllow}
          avatar={get(avatars, item.matchPlayerFieldDTO.playerDTOS.id) ? get(avatars, item.matchPlayerFieldDTO.playerDTOS.id) : undefined}
        />
      ))}
    </NewLineUp>
  </DroppableStyles>
);

export default DraggableElement;
