import React, { useEffect, useState, memo } from 'react';
import { Form, Input } from 'antd';
import { Label } from 'reactstrap';
import { AiFillCheckSquare, AiFillCloseSquare } from 'react-icons/ai';
import { BsSquare } from 'react-icons/bs';
import { OWNER_CLUB_VALUE, AWAY_CLUB_VALUE, OWNER, AWAY } from './constant.penalty-shoot-out';
import { isNull } from 'lodash';
import { CheckboxFalse, CheckboxNone, CheckboxTrue } from 'app/shared/util/appIcon';

export const StatusGoal = ({ field, form, index, nameDetail, clubType, sendChangeRecord, errors }) => {
  const countShootFieldValue = Form.useWatch(['countShoot', field.name], form);
  const countShootValue = Form.useWatch('countShoot', form);
  const valueScore = form.getFieldValue(['countShoot', index, nameDetail]);
  const [isDisabled, setIsDisables] = useState(false);

  const getValueShootByIndex = () => {
    const playerShootValue = countShootFieldValue ? countShootFieldValue[clubType] : null;
    return playerShootValue;
  };

  const isBeforeTurn = () => {
    if (index === 0) {
      return true;
    } else {
      const countShootBeforeValue = form.getFieldValue(['countShoot', index - 1]);
      if (countShootBeforeValue?.ownerGoal !== null && countShootBeforeValue?.awayGoal !== null) {
        return true;
      }
      return false;
    }
  };

  const requiredRule = getValueShootByIndex() && [{ required: true, message: '' }];
  const idField = `${nameDetail}-${index}`;

  const onChangePlayer = key => {
    const playerShoot = form.getFieldValue(['countShoot', index, clubType]);
    
    if (!playerShoot) {
      setIsDisables(true);
      const fields = form.getFieldsValue();
      const { countShoot } = fields;
      const newObj = {};
      newObj[nameDetail] = null;
      Object.assign(countShoot[key], newObj);
      form.setFieldsValue({ countShoot });
    }
  };

  useEffect(() => {
    setIsDisables(!isBeforeTurn())
  }, [countShootValue])

  useEffect(() => {
    onChangePlayer(index);
  }, [countShootFieldValue]);

  return (
    <>
      <Form.Item name={[field.name, nameDetail]} valuePropName="checked" rules={requiredRule}>
        <Input disabled={isDisabled} style={{ display: 'none' }} id={idField} type="checkbox" onChange={() => sendChangeRecord(index)} />
      </Form.Item>
      <Label style={{ textAlign: 'center', width: 150, position: 'absolute', top: 6 }} className="checkbox-penalty" for={idField}>
        {valueScore === true && <CheckboxTrue />}
        {valueScore === false && <CheckboxFalse />}
        {valueScore === null && <CheckboxNone />}
      </Label>
    </>
  );
};

export default StatusGoal;
