import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table, Badge } from 'reactstrap';
import { Translate, TextFormat, JhiPagination, JhiItemCount, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Storage } from 'react-jhipster';
import { translate } from 'react-jhipster';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
// import { getUsersAsAdmin, updateUser, getUsersInformation, ResViewDetail } from './user-management.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IUser } from 'app/shared/model/user.model';
import { act } from 'react-dom/test-utils';
import moment from 'moment';
export const PlayingTime = (props: RouteComponentProps<any>) => {
  const dispatch = useAppDispatch();
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAccept, setModalAccept] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [userAccept, setUserAccept] = useState(null);
  const [viewDetail, setViewDetail] = useState(null);
  const [modalDetail, setModalDetail] = useState(false);
  const [pagination, setPagination] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );
  const [propsdata, setPropsData] = useState('');


  useEffect(() => {
    getUsersFromProps();
  }, []);

  const handleSyncList = () => {
    window.location.reload();
  };

  const getUsersFromProps = () => {
    const endURL = `?page=${pagination.activePage},${pagination.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

 
  useEffect(() => {
    getUsersFromProps();
  }, [pagination.activePage, pagination.order, pagination.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sortParam = params.get(SORT);
    if (page && sortParam) {
      const sortSplit = sortParam.split(',');
      setPagination({
        ...pagination,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () =>
    setPagination({
      ...pagination,
      order: pagination.order === ASC ? DESC : ASC,
      sort: p,
    });

  const handlePagination = currentPage =>
    setPagination({
      ...pagination,
      activePage: currentPage,
    });

  const { match } = props;
  const account = useAppSelector(state => state.authentication.account);
  const users = useAppSelector(state => state.userManagement.users);
  const totalItems = useAppSelector(state => state.userManagement.totalItems);
  const loading = useAppSelector(state => state.userManagement.loading);

  return (
    <div>
      <h2 id="user-management-page-heading" data-cy="userManagementPageHeading">
        <Translate contentKey="userManagement.home.title">Users</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            {/* <Translate contentKey="leagueApp.leagueStandard.home.refreshListLabel">Refresh List</Translate> */}
          </Button>
          {/* <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity">
            <FontAwesomeIcon icon="plus" /> <Translate contentKey="userManagement.home.createLabel">Create a new user</Translate>
          </Link> */}
        </div>
      </h2>
      <Table striped>
        <thead>
          <tr>
            <th>No</th>
            <th>
              <Translate contentKey="global.field.name">User Name</Translate>
            </th>
            <th>
              <Translate contentKey="userManagement.unit">Unit</Translate>
            </th>
            <th>
              <Translate contentKey="userManagement.email">ID (Email)</Translate>
            </th>
            <th>
              <Translate contentKey="userManagement.phoneNumber">Phone Number</Translate>
            </th>
            <th>
              <Translate contentKey="userManagement.role">Role</Translate>
            </th>
            <th>
              <Translate contentKey="userManagement.createdDate">Created Date</Translate>
            </th>

            <th>
              <Translate contentKey="userManagement.action">Action</Translate>
            </th>
          </tr>
        </thead>
        <tbody>
          {users?.map((eachUser, i) => {
            return (
              <tr key={eachUser.id}>
                <td>{i + 1 + pagination.itemsPerPage * (pagination.activePage - 1)}</td>
                <td>{eachUser.fullName}</td>
                <td>{eachUser.unitStandardName}</td>
                <td>{eachUser.login}</td>
                <td>{eachUser.phoneNumber}</td>
                <td>{eachUser.authorityDTOS ? eachUser.authorityDTOS.map(v => v.authorityValue).join(', ') : ''}</td>
                <td>{eachUser.createdDate ? moment(eachUser.createdDate).format('YYYY-MM-DD HH:mm:ss') : ''}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {totalItems ? (
        <div className={users?.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={pagination.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={pagination.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PlayingTime;
