import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Row, Tooltip } from 'antd';
import ToastDialog from 'app/shared/util/toastDialog/ToastDialog';
import './index.scss';
import { useAppSelector } from 'app/config/store';
import { Storage, translate } from 'react-jhipster';
import { ChervonLeft, ChervonRightGray } from 'app/shared/util/appIcon';
import { LockLoginIcon } from 'app/shared/util/authIcon';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { cancelUser } from './account.service';
import { toast } from 'react-toastify';
import ConfirmationDialog from 'app/shared/util/confirmationDialog';

type Props = any;

const AccountLogout = (props: Props) => {
  const account = useAppSelector(state => state.authentication.account);
  const ROLES_KEY = 'roles';
  const LOGIN_KEY = 'login';
  const USER_ID = 'userId';
  const AUTH_TOKEN_KEY = 'jhi-authenticationToken';
  const history = useHistory();
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(false);
  const requestCancel = useRequest(cancelUser, {
    manual: true,
    onSuccess(res: any) {
      toast.success(translate('common.saveSuccess'));
      if (Storage.local.get(AUTH_TOKEN_KEY)) {
        Storage.local.remove(AUTH_TOKEN_KEY);
      }
      if (Storage.session.get(AUTH_TOKEN_KEY)) {
        Storage.session.remove(AUTH_TOKEN_KEY);
      }
      if (Storage.session.get(ROLES_KEY)) {
        Storage.session.remove(ROLES_KEY);
      }
      if (Storage.session.get(LOGIN_KEY)) {
        Storage.session.remove(LOGIN_KEY);
      }
      if (Storage.session.get(USER_ID)) {
        Storage.session.remove(USER_ID);
      }
      window.location.reload();
    },
    onError(rej: any) {
      if (rej.response.data.status === 400) {
        form.setFields([
          {
            name: ['password'],
            errors: [translate('account.message.current_password_incorrect')],
          },
        ]);
      }
    },
  });

  const found = account?.authorities.find(function (element) {
    return element === 'ROLE_ADMIN';
  });

  useEffect(() => {
    if (found) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  });


  const [confirmDialog, setConfirmDialog] = React.useState<boolean>(false);
  const [data, setData] = React.useState<string>('');

  const handleSubmit = value => {
    setData(value.password);
    setConfirmDialog(true);
  };

  const onOk = () => {
    requestCancel.run(data);
    setConfirmDialog(false);
  };

  return (
    <Row align="middle" justify="center" style={{ flexGrow: 1 }}>
      <Col xl={8} lg={12} md={16} xs={24}>
        <div>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <div className="account-card">
                <div className="account-card-header header-has-border">
                  <p className="header-title heading-24-bold neutral-1">{translate('account.title.logout_membership')}</p>
                  <p className="header-subtitle heading-16-medium neutral-3">{translate('account.subtitle.logout_membership')}</p>
                </div>
                <div className="account-card-body">
                  <Form
                    form={form}
                    onFinish={value => {
                      handleSubmit(value);
                    }}
                    name="control-hooks"
                    layout="vertical"
                    requiredMark={'optional'}
                  >
                    <Row gutter={[12, 12]}>
                      <Col span={24}>
                        <Form.Item
                          name="password"
                          label={translate('account.form.current_password')}
                          rules={[
                            {
                              required: true,
                              message: translate('account.required.current_password'),
                            },
                          ]}
                        >
                          <Input.Password prefix={<LockLoginIcon />} placeholder={translate('global.form.password.placeholder')} disabled={disable}/>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Button type="primary" htmlType="submit" className="w-100" style={{ height: 56 }} disabled={disable}>
                          {translate('account.button.logout')}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {
          <ConfirmationDialog
            open={confirmDialog}
            title={translate('account.caution')}
            message={translate('account.caution.message')}
            onConfirm={() => {
              onOk();
            }}
            onClose={() => {
              setConfirmDialog(false);
            }}
          />
        }
      </Col>
    </Row>
  );
};

export default AccountLogout;
