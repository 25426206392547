import { first, get, isNumber } from 'lodash';
import pick from 'lodash/pick';
import { IPaginationBaseState, translate, Storage } from 'react-jhipster';
import { toast } from 'react-toastify';
import { ASC, DESC } from './pagination.constants';

/**
 * Removes fields with an 'id' field that equals ''.
 * This function was created to prevent entities to be sent to
 * the server with an empty id and thus resulting in a 500.
 *
 * @param entity Object to clean.
 */
export const cleanEntity = entity => {
  const keysToKeep = Object.keys(entity).filter(k => !(entity[k] instanceof Object) || (entity[k]['id'] !== '' && entity[k]['id'] !== -1));

  return pick(entity, keysToKeep);
};

/**
 * Simply map a list of element to a list a object with the element as id.
 *
 * @param idList Elements to map.
 * @returns The list of objects with mapped ids.
 */
export const mapIdList = (idList: ReadonlyArray<any>) => idList.filter((id: any) => id !== '').map((id: any) => ({ id }));

export const overridePaginationStateWithQueryParams = (paginationBaseState: IPaginationBaseState, locationSearch: string) => {
  const params = new URLSearchParams(locationSearch);
  const page = params.get('page');
  const sort = params.get('sort');
  if (page && sort) {
    const sortSplit = sort.split(',');
    paginationBaseState.activePage = +page;
    paginationBaseState.sort = sortSplit[0];
    paginationBaseState.order = sortSplit[1];
  }
  return paginationBaseState;
};

export const getSortDefault = (key, sort) => {
  if (!sort || !get(sort.split(','), '1')) {
    return undefined;
  }
  if (key === get(sort.split(','), '0')) {
    if (get(sort.split(','), '1') === ASC) {
      return 'ascend';
    }
    return 'descend';
  }
  return undefined;
};

export const setSort = ({ field, order }: { field: string; order: string }) => {
  if (!order) {
    return field;
  }
  if (order === 'ascend') return [field, ASC].join(',');
  if (order === 'descend') return [field, DESC].join(',');

  return field;
};

export const uniformColor = (color, colorSecondary) => {
  if (isNumber(color) && isNumber(colorSecondary)) {
    return [translate(`color.${color}`), translate(`color.${colorSecondary}`)].join('/ ');
  }
  if (isNumber(color)) {
    return translate(`color.${color}`);
  }
  if (isNumber(colorSecondary)) {
    return translate(`color.${colorSecondary}`);
  }
  return '';
};

export const validateSizeImage = (file: File, e?: React.ChangeEvent<HTMLInputElement>) => {
  if (file?.size > 1024000) {
    toast.error(translate('common.maxSizeFileInput'));
    e?.stopPropagation();
    return false;
  }
  return true;
};

export const validateSizeFile = (file: File, e?: React.ChangeEvent<HTMLInputElement>) => {
  if (file?.size > 10485760) {
    toast.error(translate('common.maxSizeFile'));
    e?.stopPropagation();
    return false;
  }
  return true;
};

const language = localStorage.getItem('locale') || sessionStorage.getItem('locale') || 'en';
// export const locale = JSON.parse(language);
export const sliceLocale = language?.slice(1, language.length - 1);

// export const locale = Storage.local.get('locale') || 'en';

export const handleFocusFieldFail = (value: any, formName = 'control-hooks') => {
  const idFailed = `${formName}_${first(get(first(value.errorFields), 'name'))}`;
  const errorField: any = document?.getElementById(idFailed);
  errorField?.focus();
};
