import { IAttachFile } from './notice.model';
export interface IQuestionAnswer {
  id?: number;
  title?: string | null;
  content?: string | null;
  type?: number;
  status?: number;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  attachmentDTOS?: IAttachFile[];
  nextNoticeDTO?: IQuestionAnswer;
  previousNoticeDTO?: IQuestionAnswer;
  parentId?: any;
}

export const defaultValue: Readonly<IQuestionAnswer> = {};
