import dayjs from 'dayjs';

import {
  APP_DATE_FORMAT,
  APP_LOCAL_DATE2_FORMAT,
  APP_LOCAL_DATETIME_FORMAT,
  APP_LOCAL_DATE_FORMAT,
  APP_LOCAL_HOUR_FORMAT,
  APP_YEAR_FORMAT,
  APP_TIMESTAMP_FORMAT,
  APP_LOCAL_MINUTE_FORMAT,
  APP_DDMM_FORMAT,
} from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? dayjs(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? dayjs(date).toDate() : null);

export const formatDate = date => (date ? dayjs(date).format(APP_LOCAL_DATE_FORMAT) : null);

export const formatTime = date => (date ? dayjs(date).format(APP_TIMESTAMP_FORMAT) : null);

export const formatDate2 = date => (date ? dayjs(date).format(APP_LOCAL_DATE2_FORMAT) : null);

export const formatHour = date => (date ? dayjs(date).format(APP_LOCAL_HOUR_FORMAT) : null);

export const formatYear = date => (date ? dayjs(date).format(APP_YEAR_FORMAT) : null);

export const displayDefaultDateTime = () => dayjs().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const formatDateHour = date => (date ? dayjs(date).format(APP_DATE_FORMAT) : null);

export const formatMinute = date => (date ? dayjs(date).format(APP_LOCAL_MINUTE_FORMAT) : null);

export const formatDateMonthDay = date => (date ? dayjs(date).format(APP_DDMM_FORMAT) : null);
