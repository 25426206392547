import {ICategoryDataTrans} from "app/shared/model/category-data-trans.model";

export interface ICategoryData {
  id?: number;
  code?: string | null;
  name?: string | null;
  dataTrans?: ICategoryDataTrans[];
}

export const defaultValue: Readonly<ICategoryData> = {};
