import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError, ISearchObjectParams } from 'app/shared/reducers/reducer.utils';
import { IFormCreateNewClub, IFormDataClub } from 'app/shared/model/manager-club.model';
import { IPlayer, defaultValue } from 'app/shared/model/player.model';

const initialState: EntityState<IPlayer> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  links: null,
  dataUploadFile: null,
  deletePlayerSuccess: '',
  createPlayerSuccess: '',
  createPlayerFail: '',
};

const getApi = 'api/report-player/on-match';

export const getEntities = createAsyncThunk('report/player-list', async ({ page, size, sort, playerId, ...res }: IQueryParams) => {
  const requestUrl = `${getApi}/${playerId}`;
  return axios.get<IPlayer[]>(requestUrl);
});

export const PlayerOnMatchSlice = createEntitySlice({
  name: 'player',
  initialState,

  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
          createPlayerSuccess: '',
          deletePlayerSuccess: '',
        };
      })

      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = PlayerOnMatchSlice.actions;

// Reducer
export default PlayerOnMatchSlice.reducer;
