import { translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import { getBodyAndParams } from './../../shared/util/helper';
import axios from 'axios';
import { AsyncThunkPayloadCreator, createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError, Pageable, UploadType } from 'app/shared/reducers/reducer.utils';

import { GuideParams } from 'app/shared/model/filter.model';
import { IGuide, defaultValue } from 'app/shared/model/guide.model';
import { NoticeFilterParams } from 'app/shared/model/filter.model';
import { IAttachFile } from 'app/shared/model/notice.model';

interface GuideState extends EntityState<IGuide> {
  pageable?: Pageable<IGuide>;
}

const initialState: GuideState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/guides';

// Actions

export const getEntities = createAsyncThunk('guide/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IGuide[]>(requestUrl);
});

export const getEntity = createAsyncThunk<
  IGuide,
  string | number,
  {
    rejectValue: PaymentValidationErrors;
  }
>(
  'guide/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/detail?id=${id}`;
    const result = await axios.get<IGuide>(requestUrl);
    return result.data;
  },
  { serializeError: serializeAxiosError }
);
export const searchEntities = createAsyncThunk(
  'guides/search_entity',
  async (filter: GuideParams) => {
    const { body, params } = getBodyAndParams(filter);
    const result = await axios.post<Pageable<IGuide>>(`${apiUrl}/search`, body, { params });
    return result.data;
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'guides/create_entity',
  async (entity: IGuide) => {
    return axios.post<IGuide>(apiUrl, entity);
  },

  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'guides/update_entity',
  async (entity: IGuide, thunkAPI) => {
    return axios.put<IGuide>(`${apiUrl}/${entity.id}`, entity);
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'guide/partial_update_entity',
  async (entity: IGuide, thunkAPI) => {
    const result = await axios.patch<IGuide>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'guides/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IGuide>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const uploadAttachFile = createAsyncThunk(
  'guide/upload_attach_files',
  async (files: IAttachFile[]) => {
    const result = await axios.all<IAttachFile>(
      files?.map(item => {
        if (item.file) {
          const data = new FormData();
          data.append('file', item.file);
          data.append('uploadType', UploadType.ATTACHMENT);
          return axios
            .post('/api/file/upload-file', data, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(res => ({ ...item, nameFileServer: res.data, url: undefined }));
        }
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(item);
          }, 300);
        });
      })
    );
    return result || [];
  },
  { serializeError: serializeAxiosError }
);

export const downloadAttachFileEntity = createAsyncThunk(
  'guide/upload_attach_files',
  async (id: number) => {
    const result = await axios.get(`/api/attachments/downloadFile?idAttachments=${id}&folder=ATTACHMENT`, {
      responseType: 'blob',
    });
    return result.data;
  },
  {
    serializeError: serializeAxiosError,
  }
);
// slice

export const GuideSlice = createSlice({
  name: 'guide',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
      })
      .addCase(uploadAttachFile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createEntity.fulfilled, (state, action) => {
        toast.success(translate('leagueApp.guide.created'));
        state.loading = false;
      })
      .addCase(updateEntity.fulfilled, (state, action) => {
        toast.success(translate('leagueApp.guide.updated'));
        state.loading = false;
      })
      .addCase(deleteEntity.fulfilled, state => {
        toast.success(translate('leagueApp.guide.deleted'));
        state.loading = false;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
      })
      .addMatcher(isFulfilled(searchEntities), (state, action) => {
        state.pageable = action.payload;
        state.loading = false;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isRejected(createEntity, updateEntity), (state, action) => {
        toast.error(action.error.message);
      });
  },
});

// Reducer
export default GuideSlice.reducer;
