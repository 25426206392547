import dayjs from 'dayjs';

export interface IPlayerLeague {
  id?: number;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
  status?: number | null;
  leagueId?: number;
  playerId?: number;
  name?: string;
  organizationId?: number | null;
  homeAddress?: string | null;
  email?: string | null;
  idNumber?: number | null;
  avatar?: string | null;
  koName?: string | null;
  enName?: string | null;
  uniformName?: string | null;
  position?: string | null;
  nationality?: string | null;
  clubId?: number | null;
  clubName?: string | null;
  uniformNumber?: string | null;
  birthday?: string | null;
  height?: number | null;
  weight?: number | null;
  fileContract?: string | null;
  fileITC?: string | null;
  fileLicense?: string | null;
  previousClub?: string | null;
  phoneNumber?: number | null;
  playerAgentId?: number | null;
  birthplace?: string | null;
  dataUploadFile?: string | null;
  notEdit?: boolean | null | false;
  base64Avatar?: string | null;
  base64FileLicense?: string | null;
  base64FileITC?: string | null;
  base64FileContract?: string | null;
}

export const defaultValue: Readonly<IPlayerLeague> = {};
