import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IPlayerFoulRecord, defaultValue } from 'app/shared/model/player-foul-record.model';
import { ICategoryData } from 'app/shared/model/category-data.model';
import { CATEGORY_DATA_CODE } from 'app/shared/util/category-data-code.constant';
import { get } from 'lodash';

interface Type extends EntityState<IPlayerFoulRecord> {
  categoryList: {
    faultTypeList: ICategoryData[];
  };
}

const initialState: Type = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  categoryList: {
    faultTypeList: [],
  },
};

const apiUrl = 'api/player-foul-records';

// Actions

export const getEntities = createAsyncThunk('playerFoulRecord/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IPlayerFoulRecord[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'playerFoulRecord/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IPlayerFoulRecord>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'playerFoulRecord/create_entity',
  async (entity: IPlayerFoulRecord, thunkAPI) => {
    const result = await axios.post<IPlayerFoulRecord>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'playerFoulRecord/update_entity',
  async (entity: IPlayerFoulRecord, thunkAPI) => {
    const result = await axios.put<IPlayerFoulRecord>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'playerFoulRecord/partial_update_entity',
  async (entity: IPlayerFoulRecord, thunkAPI) => {
    const result = await axios.patch<IPlayerFoulRecord>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'playerFoulRecord/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IPlayerFoulRecord>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getListCategoryValue = createAsyncThunk('playerFoulRecord/getList', async (locale: string) => {
  const url = (cate: string) => `/api/category-data-by-code?code=${cate}&lang=${locale}`;
  const list = [CATEGORY_DATA_CODE.FAULT_TYPE];
  const result = await axios.all<ICategoryData>(list.map(item => axios.get(url(item))));
  return result;
});
// slice

export const PlayerFoulRecordSlice = createSlice({
  name: 'playerFoulRecord',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isFulfilled(getListCategoryValue), (state, action) => {

        state.categoryList = {
          faultTypeList: get(action.payload, '0.data.dataTrans'),
        };
      });
  },
});

// Reducer
export default PlayerFoulRecordSlice.reducer;
