import React from 'react';
import { Avatar, Card, Col, Row } from 'antd';
import { translate } from 'react-jhipster';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';

const HeaderMatch = ({ matchInfo }) => {
  return (
    <Card style={{ marginBottom: 32 }} className="match-record-header">
      <Row gutter={[0, 32]} className="justify-content-between align-items-center">
        <Col lg={8} xl={6} xs={24} className="d-flex align-items-center">
          <Avatar src={matchInfo?.lsBase64Avt} />
          <p className="mb-0 px-4 neutral-3">{matchInfo?.leagueName}</p>
          <p className="h5 mb-0 neutral-1">
            {/* {matchInfo?.matchRound || 1} {translate('leagueApp.match.r')} */}
            {matchInfo?.matchRound || 1}
          </p>
        </Col>
        <Col flex="auto" className="d-flex justify-content-center align-items-center">
          <p className="h5 mb-0 neutral-1">{matchInfo?.ownerClubName}</p>
          <span className="px-4 neutral-3">{translate('leagueApp.match.vs')}</span>
          <p className="h5 mb-0 neutral-1">{matchInfo?.awayClubName}</p>
        </Col>
        <Col lg={8} xl={7} xs={24} className="d-flex align-items-center" style={{ justifyContent: 'flex-end' }}>
          <p className="h6 mb-0 neutral-1">{convertDateTimeFromServer(matchInfo?.startDate)}</p>
          <p className="mb-0 neutral-3" style={{ marginLeft: 20 }}>
            {matchInfo?.stadiumName}
          </p>
        </Col>
      </Row>
    </Card>
  );
};

export default HeaderMatch;
