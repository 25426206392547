import React, { useEffect, useState } from 'react';
import { log, translate, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import Table from 'app/modules/table';
import { Avatar, Button, Col, Tag } from 'antd';
import { formatDate, formatHour } from 'app/shared/util/date-utils';
import { useFilter } from 'app/shared/hooks/useFilter';
import { LeagueClubFilter } from './league-club.type';
import { clearMsgDelete, clearMsgUpdate, deleteEntity, getEntities, getEntity } from './league-club.reducer';
import { MatchStatusContained } from 'app/modules/status-contained';
import dayjs from 'dayjs';
import LeagueClubdeleteDialog from './league-club-delete-dialog';
// import LeagueRecorderCreateDialog from './create-league-recorder';
import { useParams, useHistory } from 'react-router-dom';
import Filter from 'app/modules/filter';
import LeagueClubCreateDialog from './league-club-modal-add';
import { toast } from 'react-toastify';
import { ConfirmationDialog } from 'app/shared/util/confirmationDialog';
import { PlusIcon } from 'app/shared/util/appIcon';
import { path } from '../routes';
import './style.scss';
import MultiFilter from 'app/modules/MultiSearch';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export const LeagueClub = (props: any) => {
  const { idPlayer } = props;
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [userClicked, setUserClicked] = useState(null);
  const [viewDetail, setViewDetail] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const deleteSuccess = useAppSelector(state => state.LeagueClub?.deleteSuccess);
  const createSuccess = useAppSelector(state => state.LeagueClub?.updateSuccess);
  const [reset, setReset] = useState(false);
  const account = useAppSelector(state => state.authentication.account);
  const isAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);
  const defaultFilter = {
    page: 0,
    size: 10,
    sort: 'id,asc',
    type: 'name',
  };
  const param = useParams<{
    id: string;
    leagueId: string;
    params: string;
  }>();
  const leagueId = +param.id;
  const { push } = useHistory();

  const handleFetchData = React.useCallback((params: LeagueClubFilter) => {
    dispatch(getEntities({ ...params, leagueId: +param.id }));
  }, []);

  const { filter, onFilterToQueryString } = useFilter({
    defaultFilter,
    onFetchData: handleFetchData,
  });

  const handleDenied = (user: any) => {
    setModalDelete(true);
    setUserClicked(user);
  };

  const handleDelete = () => {
    dispatch(deleteEntity(userClicked)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        updateFilter();
        handleFetchData({ ...filter, page: 0 });
      }
    });
    setModalDelete(false);
  };

  const handleViewDetail = (user: any) => {
    setCreateModal(true);
  };

  const ActionButton = ({ id, lcId }: { id: number; lcId: number }) => {
    return (
      <div className={isAdmin && 'column-action-button'}>
        <Button
          onClick={e => {
            push(path.leagueAttendDetail1.replace(':leagueId', leagueId.toString()).replace(':id', id.toString()));
            e.stopPropagation();
          }}
          className="rounded"
        >
          <Translate contentKey="leagueApp.match.details" />
        </Button>
        {isAdmin && (
          <Button onClick={() => handleDenied(id)} type="primary" danger className="rounded">
            <Translate contentKey="entity.action.delete2" />
          </Button>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (deleteSuccess) {
      toast.success(translate('leagueApp.leagueClub.delete.success'));
      dispatch(clearMsgDelete(deleteSuccess));
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (createSuccess) {
      toast.success(translate('leagueApp.leagueClub.created.success'));
      dispatch(clearMsgUpdate(createSuccess));
      updateFilter();
      handleFetchData({ ...filter, page: 0 });
    }
  }, [createSuccess]);

  const leagueClubList = useAppSelector(state => state.LeagueClub?.entities) || [];
  const loading = useAppSelector(state => state.LeagueClub?.loading);

  const columns = [
    {
      title: '',
      dataIndex: 'base64Avt',
      key: 'base64Avt',
      width: 60,
      render: (value, record) => <Avatar shape="square" src={value} size="large" />,
    },
    {
      title: <Translate contentKey="leagueApp.leagueClub.detail.title1"></Translate>,
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => (
        <span className="hot-link-club" onClick={() => push(path.clubDetail.replace(':id', record?.id))}>
          {value}
        </span>
      ),
    },
    {
      title: <Translate contentKey="leagueApp.club.detail.title"></Translate>,
      dataIndex: 'managerName',
      key: 'managerName',
    },
    {
      title: <Translate contentKey="leagueApp.player.home.action" />,
      dataIndex: 'id',
      key: 'id',
      width: 230,
      render: (value, record) => <ActionButton id={value} lcId={record.lcId} />,
      align: 'center' as any,
    },
  ];

  const options = [
    {
      value: 'name',
      label: translate('leagueApp.leagueClub.detail.title1'),
    },
    {
      value: 'managerName',
      label: translate('leagueApp.club.detail.title'),
    },
  ];

  const updateFilter = () => {
    onFilterToQueryString({
      page: 0,
      size: 10,
      sort: 'id,asc',
      type: 'name',
    });
    setReset(!reset);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center management-layout-header" style={{ paddingTop: 30 }}>
        <div className="d-flex align-items-center">
          <h2 id="player-heading" data-cy="PlayerHeading" className="heading-20-bold neutral-1">
            <Translate contentKey="leagueApp.leagueClub.home.title1">League Recorders</Translate>
          </h2>
          <div className="management-layout-divider"></div>
          <Button className="rounded management-header-add-button" onClick={handleViewDetail} icon={<PlusIcon />} type="primary">
            <Translate contentKey="leagueApp.leagueClub.home.createLabel"></Translate>
          </Button>
        </div>
        <Col xl={10} lg={16} md={20} sm={24}>
          <Filter filter={filter} onFilter={onFilterToQueryString} multi options={options} />
        </Col>
      </div>
      <MultiFilter filter={filter} onFilter={onFilterToQueryString} options={options} />
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={leagueClubList?.content || []}
          totalElements={leagueClubList?.totalElements || 0}
          loading={loading}
          filter={filter}
          onChange={params => onFilterToQueryString({ ...filter, ...params })}
        />
      </div>
      {modalDelete && (
        <ConfirmationDialog
          open={modalDelete}
          message={<h6>{translate('leagueApp.leagueClub.delete.question')}</h6>}
          onClose={() => setModalDelete(false)}
          onConfirm={() => handleDelete()}
        />
      )}
      {createModal ? <LeagueClubCreateDialog createModal={createModal} setCreateModal={setCreateModal} /> : ''}
    </div>
  );
};

export default LeagueClub;
